import { createColumnHelper } from '@tanstack/react-table';
import styled from 'styled-components';

import {
  Banner,
  Box,
  Chip,
  ColorsV2,
  Flex,
  Icon,
  Table,
  Text,
  Tooltip,
  useBreakpoint,
  useThemeColors,
} from '@electricjs/arc';

import DonutGraph, { Dot } from '@/components/DonutGraph';
import TableHeader from '@/components/TableHeader';
import { DeviceIssueType, Issue } from '@/types/devices';
import { EmployeeWithAssets } from '@/types/employees';
import StyledRouterLink from '@/components/StyledRouterLink';
import { Asset, AssetDeviceReportingData } from '@/types/assets';

const DotKeyGrid = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-columns: 2rem 3fr 2fr;
  row-gap: 1rem;
  align-items: baseline;
  margin-top: 0;
`;

type VersionCount = {
  key: string;
  value: number;
  color?: string;
};

type DeviceOSVersionInfo = {
  osName: JSX.Element | string;
  eolDate: string;
  owner: JSX.Element | string;
};

type OsBlockCardProps = {
  osVersions: { [version: string]: number };
  allDeviceAssets: Asset[];
  osType: string;
  employeesWithDeviceIssues: EmployeeWithAssets[];
};

export const OsBlockTab = ({
  osVersions,
  allDeviceAssets,
  osType,
  employeesWithDeviceIssues,
}: OsBlockCardProps) => {
  const donutData: VersionCount[] = [];
  const [
    infoColor,
    warningLightColor,
    successColor,
    grayLightColor,
    primaryLightColor,
    darkGrayColor,
    infoLightColor,
    warningDarkColor,
    infoDarkColor,
    errorDarkColor,
    warningLightestColor,
    successLightColor,
    errorLightestColor,
    successDarkColor,
  ] = useThemeColors([
    ColorsV2.INFO,
    ColorsV2.WARNING_LIGHT,
    ColorsV2.SUCCESS,
    ColorsV2.GRAY_LIGHT,
    ColorsV2.PRIMARY_LIGHT,
    ColorsV2.GRAY_DARK,
    ColorsV2.INFO_LIGHT,
    ColorsV2.WARNING_DARK,
    ColorsV2.INFO_DARK,
    ColorsV2.ERROR_DARK,
    ColorsV2.WARNING_LIGHTEST,
    ColorsV2.SUCCESS_LIGHT,
    ColorsV2.ERROR_LIGHTEST,
    ColorsV2.SUCCESS_DARK,
  ]);

  const donutColors = [
    infoColor,
    warningLightColor,
    successColor,
    grayLightColor,
    primaryLightColor,
    darkGrayColor,
    infoLightColor,
    warningDarkColor,
    infoDarkColor,
    errorDarkColor,
    warningLightestColor,
    successLightColor,
    errorLightestColor,
    successDarkColor,
  ];

  const { xl, md } = useBreakpoint();

  const osDeviceAssets = allDeviceAssets.filter(
    asset => asset.device?.osType === osType
  );

  const columnHelper = createColumnHelper<DeviceOSVersionInfo>();

  const tableColumns = [
    columnHelper.accessor('osName', {
      header: () => <TableHeader title="OS name" />,
      id: 'os-name',
      minSize: 200,
      cell: data => <Text variant="body-2">{data.getValue()}</Text>,
    }),
    columnHelper.accessor('eolDate', {
      header: () => (
        <Flex gap="0.5rem">
          <TableHeader title="End of life" />
          <Tooltip
            pr="2rem"
            maxWidth="40rem"
            content="End of life date is when the OS is no longer supported, making it vulnerable to security risks."
            placement="top">
            <Icon icon="information-circle" size="small" intent="neutral" />
          </Tooltip>
        </Flex>
      ),
      id: 'eol',
      cell: data => <Text variant="body-2">{data.getValue()}</Text>,
      meta: { maxWidth: '12rem' },
    }),
    columnHelper.accessor('owner', {
      header: () => <TableHeader title="Owner" />,
      id: 'owner',
      cell: data => <Text variant="body-2">{data.getValue()}</Text>,
      meta: { width: '2rem', maxWidth: '15rem', minWidth: '10rem' },
    }),
  ];

  for (const [key, value] of Object.entries(osVersions)) {
    donutData.push({
      key,
      value,
      color: donutColors.shift(),
    });
  }

  const DevicesWithEOLIssue: DeviceOSVersionInfo[] = osDeviceAssets
    .filter(asset => {
      const deviceIssues =
        asset.device &&
        asset.device.reportingData &&
        asset.device.reportingData.issues
          ? (asset.device.reportingData.issues as Issue)
          : null;
      if (!deviceIssues) {
        return false;
      }
      return deviceIssues[DeviceIssueType.OS];
    })
    .map(asset => {
      const deviceOwner = employeesWithDeviceIssues.filter(e =>
        e.assets?.includes(asset)
      )[0];

      // We can cast here because we know based on the previous filter
      // that the device has an OS issue

      const deviceReportingData = asset.device
        ?.reportingData as AssetDeviceReportingData;

      const deviceIssues =
        deviceReportingData && (deviceReportingData.issues as Issue);

      const deviceAsset = asset.device;

      const eolDate = deviceIssues[DeviceIssueType.OS]?.extra?.eolDate;
      // If the EOL is in the past, show a red alert, otherwise show a yellow warning
      const isInThePast = (eolDate && new Date() > new Date(eolDate)) || false;

      return {
        osName: (
          <Flex vertical={!md} vAlignContent="center">
            <Text variant="body-2">
              {deviceAsset?.reportingData?.osName}{' '}
              {deviceAsset?.reportingData?.osVersion &&
                `${deviceAsset?.reportingData?.osVersion}`}
            </Text>
            <Chip
              id="eol-chip"
              variant="small"
              ml={md ? '1rem' : '0'}
              mt={md ? '0' : '0.5rem'}
              backgroundColor={
                isInThePast ? ColorsV2.ERROR_DARK : ColorsV2.WARNING_LIGHTEST
              }>
              <Flex vAlignContent="center">
                <Icon
                  icon={isInThePast ? 'alert-circle' : 'alert-triangle'}
                  color={
                    isInThePast
                      ? ColorsV2.ERROR_LIGHTEST
                      : ColorsV2.WARNING_DARKEST
                  }
                  mr="0.4rem"
                  customSize={12}
                />
                <Text
                  variant="legal"
                  color={
                    isInThePast
                      ? ColorsV2.ERROR_LIGHTEST
                      : ColorsV2.WARNING_DARKEST
                  }>
                  {isInThePast ? 'Past EOL' : 'Near EOL'}
                </Text>
              </Flex>
            </Chip>
          </Flex>
        ),
        eolDate: deviceIssues[DeviceIssueType.OS]?.extra?.eolDate || '-',
        owner: deviceOwner ? (
          <StyledRouterLink
            to={`/employees/${deviceOwner.id}`}
            id="device-owner">
            {deviceOwner.firstName} {deviceOwner.lastName}
          </StyledRouterLink>
        ) : (
          '-'
        ),
      };
    });

  return (
    <Flex vertical={!xl} width="100%">
      <Flex vertical width={xl ? '40%' : '100%'}>
        <Flex
          justifyContent="space-between"
          width="100%"
          maxWidth="20rem"
          my="2rem">
          <Text variant="body">
            <strong>{osDeviceAssets.length}</strong> devices
          </Text>
          <Text variant="body">
            <strong>{Object.keys(osVersions).length}</strong> OS versions
          </Text>
        </Flex>
        <Flex width="100%" justifyContent="left" vAlignContent="center">
          <Flex width="100%" maxWidth="12rem">
            <DonutGraph data={donutData} />
          </Flex>
          <Flex justifyContent="flex-end" width={xl ? '30rem' : '100%'}>
            <DotKeyGrid ml="3rem">
              {donutData.map(version => {
                return (
                  <>
                    <Dot
                      color={version.color || 'blue'}
                      width="0.75rem"
                      height="0.75rem"
                    />
                    <Text variant="legal" pr="0.5rem">
                      {version.key}
                    </Text>
                    <Text variant="legal">{`${version.value} (${(
                      (version.value / osDeviceAssets.length) *
                      100
                    )
                      .toFixed(1)
                      .replace(/[.,]0$/, '')}%)`}</Text>
                  </>
                );
              })}
            </DotKeyGrid>
          </Flex>
        </Flex>
      </Flex>
      <Flex width={xl ? '60%' : '100%'} mt={xl ? '1.3rem' : '3rem'}>
        {DevicesWithEOLIssue.length > 0 ? (
          <Table
            columns={tableColumns}
            data={DevicesWithEOLIssue}
            loading={false}
            variant="compact"
          />
        ) : (
          <Flex pt="2rem" width="100%" justifyContent="center">
            <Banner
              id="all-devices-up-to-date-banner"
              width="100%"
              isFlat
              stack
              intent="success"
              title="All devices have up-to-date OS"
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

import { Employee, EmployeeStatus } from '@/types/employees';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useGetLatestOffboardingByEmployeeIdQuery } from '@/redux/slices/offboardingApiSlice';

/**
 * Hook that returns true if the offboarding button should be disabled.
 * @param employee the employee to be potentially offboarded
 */
export function useShouldDisableOffboardingButton(employee: Employee): boolean {
  const organizationId = useGetOrganizationId();
  const { data: latestOffboarding, isFetching } =
    useGetLatestOffboardingByEmployeeIdQuery({
      organizationId,
      employeeId: employee.id,
    });

  // Disable while loading
  if (isFetching) {
    return true;
  }

  const isEmployeeInactive = employee.status !== EmployeeStatus.Active;

  const hasOffboarding =
    latestOffboarding !== undefined &&
    latestOffboarding.completedAt === undefined;

  const isStartDateInFuture =
    employee.startDate !== undefined &&
    new Date(employee.startDate) > new Date();

  // We're done fetching, but no offboarding: we should be able to create one if the employee is active
  return isEmployeeInactive || hasOffboarding || isStartDateInFuture;
}

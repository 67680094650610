import {
  Chip,
  ColorsV2,
  Flex,
  Text,
  Tooltip,
  useThemeColors,
} from '@electricjs/arc';

const BetaChip = () => {
  const [infoBackgroundColor, infoTextColor] = useThemeColors([
    ColorsV2.INFO_LIGHTEST,
    ColorsV2.INFO_DARKEST,
  ]);

  return (
    <Chip
      variant="small"
      id="integration-beta-chip"
      backgroundColor={infoBackgroundColor}>
      <Flex vAlignContent="center" gap="1rem">
        <Tooltip
          maxWidth="25rem"
          content="This integration is in beta, if you run into issues please reach out to product-support@electric.ai">
          <Text variant="legal" color={infoTextColor}>
            Beta
          </Text>
        </Tooltip>
      </Flex>
    </Chip>
  );
};

export default BetaChip;

import { useContext } from 'react';

import {
  Button,
  ColorsV2,
  Divider,
  Flex,
  Icon,
  Text,
  useThemeColors,
} from '@electricjs/arc';

import { ThemeContext, Themes } from '../ThemeProvider';
import formatStringForId from '@/helpers/formatStringForId';

const SwitcherOption = ({
  value = Themes.LIGHT,
  children,
}: {
  value?: string;
  children: string;
}) => {
  const { themeName, setThemeValue } = useContext(ThemeContext);
  const [buttonTextColor, buttonBorderColor] = useThemeColors([
    ColorsV2.TEXT,
    ColorsV2.TEXT,
  ]);

  {
    /* NOTE: This custom button color theming should only be used here, it's not an approved pattern for use elsewhere. */
  }
  return (
    <Button
      id={`button-theme-switcher-${formatStringForId(value)}`}
      color={buttonTextColor}
      {...(themeName === value ? { borderColor: buttonBorderColor } : {})}
      intent="primary"
      variant={themeName === value ? 'outline' : 'text'}
      onClick={() => setThemeValue(value)}>
      {children}
    </Button>
  );
};

const ThemeSwitcher = ({
  shouldHaveBottomDivider = true,
}: {
  shouldHaveBottomDivider?: boolean;
}) => {
  return (
    <>
      <Flex px="1.6rem" pb="0.8rem" alignItems="center">
        <Icon icon="palette" size="small" mr="0.8rem" color={ColorsV2.TEXT} />
        <Text variant="body-2">Theme</Text>
        <Text variant="legal" intent="info" ml="1rem" pt="0.2rem">
          BETA
        </Text>
      </Flex>
      <Flex p="0 1.2rem 1rem">
        <SwitcherOption value={Themes.LIGHT}>Light</SwitcherOption>
        <SwitcherOption value={Themes.AUTO}>System</SwitcherOption>
        <SwitcherOption value={Themes.DARK}>Dark</SwitcherOption>
      </Flex>
      {shouldHaveBottomDivider && <Divider space="2" />}
    </>
  );
};

export default ThemeSwitcher;

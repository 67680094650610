import { useFormContext, useWatch } from 'react-hook-form';

import {
  DatePickerField,
  SelectField,
  TextAreaField,
  TextInputField,
} from '@electricjs/arc';

export enum AssetFieldTypes {
  Select = 'select',
  TextInput = 'text',
  TextArea = 'textarea',
  DatePicker = 'date',
}

type AssetFormFieldProps = {
  fieldType?: AssetFieldTypes;
  options?: { value: string; label: string }[];
  name: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  label: string;
  placeholder?: string;
  isClearable?: boolean;
};

export const AssetFormField = ({
  fieldType,
  options = [],
  name,
  isDisabled,
  isLoading,
  label,
  placeholder,
  isClearable,
}: AssetFormFieldProps) => {
  const { control } = useFormContext();
  const value = useWatch({ control, name });

  switch (fieldType) {
    case AssetFieldTypes.Select:
      return (
        <SelectField
          id={`asset-select-${name}`}
          name={name}
          value={value}
          label={label}
          control={control}
          placeholder={placeholder}
          options={options}
          isLoading={isLoading}
          disabled={isLoading || isDisabled}
          isSearchable
          isClearable
        />
      );
    case AssetFieldTypes.DatePicker:
      return (
        <DatePickerField
          id={`asset-date-picker-${name}`}
          name={name}
          control={control}
          label={label}
          placeholder={placeholder}
          minDate=""
          isClearable={isClearable}
          selected={value}
          disabled={isDisabled}
        />
      );
    case AssetFieldTypes.TextArea:
      return (
        <TextAreaField
          id={`asset-textarea-${name}`}
          rows={2}
          name={name}
          control={control}
          label={label}
          placeholder={placeholder}
          disabled={isDisabled}
        />
      );
    default:
      return (
        <TextInputField
          id={`asset-input-${name}`}
          name={name}
          control={control}
          label={label}
          placeholder={placeholder}
          disabled={isDisabled}
        />
      );
  }
};

export default AssetFormField;

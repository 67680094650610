import { TagTypes, apiSlice } from '@/redux/slices/apiSlice';
import {
  HelpfulLinkArgs,
  HelpfulLinkPayload,
  HelpfulLinksDto,
} from '@/types/helpfulLinks';

export const helpfulLinkSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getHelpfulLinks: builder.query<HelpfulLinkPayload[], string>({
      query: organizationId =>
        `/v1/organizations/${organizationId}/helpful_links`,
      providesTags: [TagTypes.HelpfulLinks],
      transformResponse: (helpfulLinks: HelpfulLinksDto[]) => {
        return (
          helpfulLinks?.map(link => ({
            linkId: link.link_id,
            title: link.title,
            linkUrl: link.link_url,
            description: link.description,
          })) ?? []
        );
      },
    }),
    createHelpfulLink: builder.mutation<void, HelpfulLinkPayload>({
      query: ({ organizationId, linkUrl, title, description }) => ({
        url: `/v1/organizations/${organizationId}/helpful_links`,
        method: 'POST',
        body: {
          link_url: linkUrl,
          title,
          description,
        },
      }),
      invalidatesTags: [TagTypes.HelpfulLinks],
    }),
    deleteHelpfulLink: builder.mutation<void, HelpfulLinkArgs>({
      query: ({ organizationId, linkId }) => ({
        url: `/v1/organizations/${organizationId}/helpful_links/${linkId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [TagTypes.HelpfulLinks],
    }),
    updateHelpfulLink: builder.mutation<void, HelpfulLinkPayload>({
      query: ({ organizationId, linkId, linkUrl, title, description }) => ({
        url: `/v1/organizations/${organizationId}/helpful_links/${linkId}`,
        method: 'PUT',
        body: {
          link_url: linkUrl,
          title,
          description,
        },
      }),
      invalidatesTags: [TagTypes.HelpfulLinks],
    }),
  }),
});

export const {
  useGetHelpfulLinksQuery,
  useCreateHelpfulLinkMutation,
  useDeleteHelpfulLinkMutation,
  useUpdateHelpfulLinkMutation,
} = helpfulLinkSlice;

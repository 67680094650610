import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  type Address,
  AddressGroupField,
  Box,
  Button,
  Flex,
  NumberInputField,
  requiredAddressValidationSchema,
  TextInputField,
} from '@electricjs/arc';

import { useGlobalUI } from '@/components/GlobalUIProvider';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useCreateSiteMutation } from '@/redux/slices/assetApiSlice';
import { phoneValidationSchema } from '@common';

const GOOGLE_MAPS_API_KEY = window._env_?.VITE_GOOGLE_MAPS_PUB_API_KEY;

export type FormData = {
  name: string;
  phone?: string;
  address: Address;
};

export const newSiteSchema = yup.object().shape({
  name: yup.string().required('The name is required'),
  phone: phoneValidationSchema({ required: false }),
  address: requiredAddressValidationSchema,
});

const NewSiteForm = () => {
  const { showErrorToast, showSuccessToast } = useGlobalUI();
  const defaultValues: FormData = {
    name: '',
    phone: '',
    address: {
      streetAddress1: '',
      streetAddress2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
    },
  };

  const {
    control,
    reset,
    handleSubmit,
    formState: { isValid },
    setValue,
  } = useForm<FormData>({
    resolver: yupResolver(newSiteSchema),
    mode: 'onTouched',
    defaultValues,
  });

  const [createSite, { isLoading }] = useCreateSiteMutation();

  const organizationId = useGetOrganizationId();

  const onAddSiteClick = (data: FormData) => {
    createSite({
      name: data.name,
      organizationId: organizationId,
      address: {
        streetAddress1: data.address.streetAddress1,
        streetAddress2: data.address.streetAddress2,
        city: data.address.city,
        state: data.address.state,
        zip: data.address.zip,
        country: data.address.country,
      },
      phone: data.phone || undefined,
    })
      .unwrap()
      .catch(error => {
        console.error('Error while adding site: ', error);
        showErrorToast({
          id: 'add-site-error-toast',
          message: 'Something went wrong. Please try again.',
          stack: true,
        });
        return Promise.reject(error);
      })
      .then(() => {
        showSuccessToast({
          id: 'add-site-success-toast',
          message: 'The site was successfully added.',
        });
        reset();
      });
  };

  return (
    <Box>
      <form>
        <Flex vertical mb="2rem" width="100%">
          <Flex width="100%" columnGap="1rem">
            <TextInputField
              id="site-name-input"
              name="name"
              control={control}
              label="Name"
              placeholder="Enter site name"
              width="100%"
              required
            />
            <NumberInputField
              id="site-phone-number-input"
              name="phone"
              control={control}
              label="Phone"
              placeholder="Enter phone number"
              format="(###) ###-####" // Formats conform to our phone number format in IT Hub
              width="100%"
            />
          </Flex>
          <Flex width="100%" vAlignContent="center" columnGap="1rem">
            <AddressGroupField
              name="address"
              control={control}
              onChange={address =>
                setValue('address', address, { shouldValidate: true })
              }
              googleMapsApiKey={GOOGLE_MAPS_API_KEY}
              required
              columnGap={'1rem'}
            />
            <Button
              id="add-site-button"
              mt="2rem"
              alignSelf="flex-start"
              flexBasis="10rem"
              disabled={!isValid}
              loading={isLoading}
              onClick={handleSubmit(onAddSiteClick)}>
              Add site
            </Button>
          </Flex>
        </Flex>
      </form>
    </Box>
  );
};

export default NewSiteForm;

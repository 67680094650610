import { useRef, useEffect } from 'react';

import {
  Button,
  Checkbox,
  ColorsV2,
  FieldHelperText,
  Flex,
  Text,
  TextInput,
  useBreakpoint,
  useThemeColors,
} from '@electricjs/arc';

import { CopyButton, ExternalLink } from '@common';

import { StepContainer } from '@/components/ABMManagement/ABMComponents';
import { ABMManagementCard } from '@/components/ABMManagement/ABMComponents';
import { useABMSetup } from '@/components/ABMManagement/useABMSetup';

const LoginIntoAbmStep = () => {
  return (
    <StepContainer step={1}>
      <Text variant="label-large" mb="1rem">
        Log into Apple Business Manager
      </Text>
      <Text mb="1rem">
        Use the company&apos;s Apple ID credentials to log into Apple Business
        Manager from your browser.
      </Text>
      <ExternalLink id="abm-go-to-apple-link" href="https://business.apple.com">
        Go to Apple
      </ExternalLink>
    </StepContainer>
  );
};

const AccessProfileStep = () => {
  return (
    <StepContainer step={2}>
      <Text variant="label-large" mb="1rem">
        Access your profile
      </Text>
      <Text>
        Within Apple Business Manager, go to the lower left of the screen and
        click &quot;Preferences&quot;.
      </Text>
    </StepContainer>
  );
};

type ThirdPartyStepProps = {
  onCheck: () => void;
  error: boolean;
  isCheckboxSelected?: boolean;
};

const ThirdPartyStep = ({
  onCheck,
  error,
  isCheckboxSelected,
}: ThirdPartyStepProps) => {
  const { md } = useBreakpoint();
  const electricID = '16210800';
  const requiredColor = useThemeColors(ColorsV2.ERROR);

  return (
    <StepContainer step={3}>
      <Text variant="label-large" mb="1rem">
        Third party reseller
      </Text>
      <Flex vertical rowGap="1rem">
        <Text>Within Apple Business Manager:</Text>
        <Text> a. Click &quot;MDM Server Assignment&quot;</Text>
        <Text> b. Find &quot;Edit&quot; in the Customer Numbers section</Text>
        <Text> c. Select &quot;Reseller Number&quot; in the drop-down</Text>
        <Flex vertical={!md} alignItems={md ? 'center' : 'flex-start'}>
          <Text mr="2rem">
            {' '}
            d. Input Electric&apos;s Reseller <strong>ID: {electricID}</strong>
          </Text>
          <CopyButton
            id="abm-copy-id-button"
            variant="outline"
            textToCopy={electricID}>
            Copy ID
          </CopyButton>
        </Flex>
        <Text>
          {' '}
          e. Electric&apos;s name will populate under the input field
        </Text>
        <Text> f. Click &quot;Add&quot; to complete</Text>
      </Flex>
      <Flex columnGap="0.5rem" m="1rem 0rem">
        <Text color={requiredColor}>*</Text>
        <Text variant="label-large">Confirm third party reseller</Text>
      </Flex>
      <Checkbox
        id="needs-third-party-reseller"
        onChange={onCheck}
        disabled={isCheckboxSelected}
        checked={isCheckboxSelected}>
        Yes, Electric has been added as a third party reseller within the ABM
        service.
      </Checkbox>
      {error && (
        <FieldHelperText error errorMessage="Confirmation is required." />
      )}
    </StepContainer>
  );
};

type ProvideAbmOrgIdStepProps = {
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error: boolean;
  errorMessage: string;
  abmID?: number;
};

const ProvideAbmOrgIdStep = ({
  onInputChange,
  error,
  errorMessage,
  abmID,
}: ProvideAbmOrgIdStepProps) => {
  const requiredColor = useThemeColors(ColorsV2.ERROR);
  const inputRef = useRef<HTMLInputElement>(null);

  // Focus the input when the user edits the abm ID
  useEffect(() => {
    if (abmID) {
      inputRef?.current?.focus();
    }
  }, [abmID]);

  return (
    <StepContainer step={4}>
      <Text variant="label-large" mb="1rem">
        Provide Electric your ABM Organization ID{' '}
      </Text>
      <Text>
        Within Apple Business Manager Preferences, find &quot;Organization
        Info&quot; and copy your Organization ID # into Electric.
      </Text>
      <Flex columnGap="0.5rem" m="1rem 0rem">
        <Text color={requiredColor}>*</Text>
        <Text variant="label-large">ABM Organization ID</Text>
      </Flex>
      <TextInput
        id="abm-id-input"
        testId="input-valid-abm-id"
        placeholder="ex: 0000"
        onChange={onInputChange}
        defaultValue={abmID?.toString()}
        ref={inputRef}
      />
      {error && <FieldHelperText error errorMessage={errorMessage} />}
    </StepContainer>
  );
};

type ABMSetupFormProps = {
  onSaveAbm: () => void;
  onCancelAbm?: () => void;
  onErrorAbm: () => void;
  abmID?: number;
  isCheckboxSelected?: boolean;
};

type ABMSetupComponentProps = {
  onCancelAbm?: () => void;
  hasFormActionsButtons?: boolean;
  handleCheckbox: () => void;
  handleInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSave: () => void;
  needsValidAbmID: boolean;
  needsThirdParty: boolean;
  abmIDErrorMessage: string;
  abmID?: number;
  isCheckboxSelected?: boolean;
  needsEditAbmID?: boolean;
  isLoading?: boolean;
};

export const ABMSetupComponent = ({
  onCancelAbm,
  hasFormActionsButtons = true,
  handleCheckbox,
  handleInput,
  handleSave,
  needsValidAbmID,
  needsThirdParty,
  abmIDErrorMessage,
  abmID,
  isCheckboxSelected,
  needsEditAbmID,
  isLoading = false,
}: ABMSetupComponentProps) => {
  return (
    <>
      {/* Step 1 */}
      <LoginIntoAbmStep />

      {/* Step 2 */}
      <AccessProfileStep />

      {/* Step 3 */}
      <ThirdPartyStep
        onCheck={handleCheckbox}
        error={needsThirdParty}
        isCheckboxSelected={isCheckboxSelected}
      />

      {/* Step 4 */}
      <ProvideAbmOrgIdStep
        onInputChange={handleInput}
        error={needsValidAbmID}
        errorMessage={abmIDErrorMessage}
        abmID={abmID}
      />

      {/* form actions buttons */}
      {hasFormActionsButtons && (
        <Flex p="2rem" width="100%" justifyContent="flex-end">
          <Flex columnGap="1rem">
            <Button
              id="cancel-abm-setup-button"
              variant="text"
              onClick={onCancelAbm}
              testId="cancel-abm-setup-button">
              Cancel
            </Button>
            <Button
              id="submit-abm-setup-button"
              onClick={handleSave}
              testId="submit-abm-setup-button"
              disabled={needsThirdParty || needsValidAbmID || needsEditAbmID}
              loading={isLoading}>
              Save
            </Button>
          </Flex>
        </Flex>
      )}
    </>
  );
};

const ABMSetupForm = ({
  onCancelAbm,
  onSaveAbm,
  onErrorAbm,
  abmID,
  isCheckboxSelected,
}: ABMSetupFormProps) => {
  const {
    handleCheckbox,
    handleInput,
    handleSave,
    needsValidAbmID,
    needsThirdParty,
    abmIDErrorMessage,
    needsEditAbmID,
    isLoading,
  } = useABMSetup({
    onSaveAbm,
    onErrorAbm,
    abmOrgID: abmID?.toString(),
    isCheckboxSelected,
  });

  return (
    <ABMManagementCard>
      <ABMSetupComponent
        onCancelAbm={onCancelAbm}
        handleCheckbox={handleCheckbox}
        handleInput={handleInput}
        handleSave={handleSave}
        needsValidAbmID={needsValidAbmID}
        needsThirdParty={needsThirdParty}
        abmIDErrorMessage={abmIDErrorMessage}
        abmID={abmID}
        isCheckboxSelected={isCheckboxSelected}
        needsEditAbmID={needsEditAbmID}
        isLoading={isLoading}
      />
    </ABMManagementCard>
  );
};

export default ABMSetupForm;

import {
  EntitlementSlugEnum,
  PermissionEnum,
} from '@electricjs/core_entity-client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { lazy } from 'react';
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import PageSpinner from '@/components/PageSpinner';
import { useIntakeSurvey } from '@/hooks/useIntakeSurvey';
import { useOrganizationHasEntitlement } from '@/hooks/useOrganizationHasEntitlement';
import { useUserHasOrganizationPermission } from '@/hooks/useUserHasOrganizationPermission';
import AssetDetails from '@/pages/Assets/AssetDetails';
import Assets from '@/pages/Assets/Assets';
import NewAsset from '@/pages/Assets/NewAsset';
import DeviceOverview from '@/pages/Hardware/DeviceOverview/DeviceOverview';
import NewOnboarding from '@/pages/Onboardings/NewOnboarding';
import NewHRISOnboarding from '@/pages/Onboardings/NewHRISOnboarding';
import NewOffboarding from '@/pages/Offboardings/NewOffboarding';
import NewHRISOffboarding from '@/pages/Offboardings/NewHRISOffboarding';
import RequestSupportPage from '@/pages/SupportCenter/RequestSupportPage';
import SupportCenterHome from '@/pages/SupportCenter/SupportCenterHome';
import { useAppSelector } from '@/redux/store';
import { OrganizationUserRoleSlugs } from '@/types/users';

import { DefaultPageWrapper } from './DefaultPageWrapper';
import { ErrorPage } from './ErrorPage';
import { InternalRoutes } from './internalRoutes';
import ProtectedRoute from './ProtectedRoute';

// Page imports (lazy load)
const PageNotFound = lazy(() => import('@/pages/PageNotFound'));
const MaintenancePage = lazy(() => import('@/pages/MaintenancePage'));
const IntakeForm = lazy(() => import('@/pages/IntakeForm/IntakeForm'));

// Dashboard
const Dashboard = lazy(() => import('@/pages/Dashboard/Dashboard'));

// Device related pages
const DeviceManagement = lazy(
  () => import('@/pages/Hardware/DeviceManagement/DeviceManagement')
);

// People related pages
const Employees = lazy(() => import('@/pages/People/Employees'));
const InactiveEmployees = lazy(
  () => import('@/pages/People/InactiveEmployees')
);
const EmployeeGroups = lazy(
  () => import('@/pages/People/EmployeeGroups/EmployeeGroups')
);
const NewEmployeeGroup = lazy(
  () => import('@/pages/People/EmployeeGroups/NewEmployeeGroup')
);
const GroupDetails = lazy(
  () => import('@/pages/People/EmployeeGroups/GroupDetails')
);
const EmployeesStatusReview = lazy(
  () => import('@/pages/People/EmployeesStatusReview')
);
const EmployeeProfile = lazy(() => import('@/pages/People/EmployeeProfile'));
const OrganizationApplications = lazy(
  () => import('@/pages/Applications/OrganizationApplications')
);
const ApplicationDetails = lazy(
  () => import('@/pages/Applications/ApplicationDetails')
);

// Storefront/Orders related pages
const StorefrontPage = lazy(() => import('@/pages/Storefront/StorefrontPage'));
const OrdersListPage = lazy(() => import('@/pages/Orders/OrdersListPage'));
const OrderDetailsPage = lazy(() => import('@/pages/Orders/OrderDetailsPage'));
const ShipDevice = lazy(() => import('@/pages/Shipment/ShipDevice'));

// Organization related pages
const Settings = lazy(() => import('@/pages/Organization/Settings/Settings'));
const Plans = lazy(() => import('@/pages/Organization/Plans'));

const Discover = lazy(() => import('@/pages/Marketplace/Discover'));

// Employee center related pages
const AIChat = lazy(() => import('@/pages/AIChat/AIChat'));
const HelpfulLinks = lazy(() => import('@/pages/HelpfulLinks/HelpfulLinks'));

const OrgSwitcher = lazy(() => import('@/pages/OrgSwitcher/OrgSwitcher'));

const NotificationCenter = lazy(
  () => import('@/pages/NotificationCenter/NotificationCenter')
);

const YourItPlan = lazy(() => import('@/pages/YourItPlan/YourItPlan'));

// Security related pages
const Antivirus = lazy(
  () => import('@/pages/Security/ThreatDown/ThreatDownData')
);
const EmailSecurity = lazy(() => import('@/pages/Security/HEC/HECData'));
const BreachDashboard = lazy(
  () => import('@/pages/DataBreaches/BreachDashboard')
);

// tasks pages
const Tasks = lazy(() => import('@/pages/Tasks/Tasks'));
const TaskDetails = lazy(() => import('@/pages/Tasks/TaskDetails'));

// Integrations pages
const IntegrationsConnect = lazy(
  () => import('@/pages/Integrations/IntegrationsConnect')
);
const IntegrationsCallback = lazy(
  () => import('@/pages/Integrations/IntegrationsCallback')
);

export const ElectrolyteRoutes = () => {
  const loggedUser = useAppSelector(state => state.loggedUser);
  const allUserOrganizationRoles = loggedUser?.allUserOrganizationRoles;
  const hasEmployeeGroupsEntitlement = useOrganizationHasEntitlement(
    EntitlementSlugEnum.EmployeeGroups
  );
  const hasAntivirusEntitlement = useOrganizationHasEntitlement(
    EntitlementSlugEnum.ThreatdownSecurityDashboard
  );
  const hasEmailSecurityEntitlement = useOrganizationHasEntitlement(
    EntitlementSlugEnum.HarmonyEmailAndCollaborationSecurityDashboard
  );
  const hasGigawattEntitlement = useOrganizationHasEntitlement(
    EntitlementSlugEnum.Gigawatt
  );
  const {
    itHubMaintenancePage, // maintenance page flag for when we have scheduled maintenance
    electrolyteAssets,
    electrolyteDeviceOverview,
    electrolyteDeviceManagement,
    itHubMyOrganizationsSwitcher,
    itHubDashboard,
  } = useFlags();

  const hasElectricPermissions = !!loggedUser?.electricPermissions;
  const showOrganizationSwitcher =
    hasElectricPermissions ||
    (itHubMyOrganizationsSwitcher &&
      allUserOrganizationRoles !== null &&
      allUserOrganizationRoles !== undefined &&
      Object.keys(allUserOrganizationRoles)?.length > 1);
  const { shouldRenderIntakeForm } = useIntakeSurvey();

  const canReadAssets = useUserHasOrganizationPermission(
    PermissionEnum.Assetsread
  );
  const canCreateAssets = useUserHasOrganizationPermission(
    PermissionEnum.Assetscreate
  );
  const canUpdateAssets = useUserHasOrganizationPermission(
    PermissionEnum.Assetsupdate
  );
  const canCreateEmployees = useUserHasOrganizationPermission(
    PermissionEnum.Employeescreate
  );
  const canUpdateEmployees = useUserHasOrganizationPermission(
    PermissionEnum.Employeesupdate
  );
  const hasITPlanEntitlement = useOrganizationHasEntitlement(
    EntitlementSlugEnum.ItPlan
  );
  const hasElectricAppEntitlement = useOrganizationHasEntitlement(
    EntitlementSlugEnum.DesktopApp
  );

  const isAdminOrSupportRole = loggedUser?.organizationUserRoles?.some(
    role =>
      role.slug === OrganizationUserRoleSlugs.SUPER_ADMIN ||
      role.slug === OrganizationUserRoleSlugs.PRODUCT_SUPPORT
  );

  const maintenanceRoutes = (() => (
    <Route>
      <Route path="/maintenance" element={<MaintenancePage />} />
      <Route path="*" element={<Navigate to="/maintenance" />} />
    </Route>
  ))();

  const intakeFormRoutes = (() => (
    <Route errorElement={<ErrorPage />}>
      <Route path="/welcome" element={<IntakeForm />} />
      <Route path="*" element={<Navigate to="/welcome" />} />
    </Route>
  ))();

  const commonRoutes = (() => (
    <Route>
      <Route path="/notification-center" element={<NotificationCenter />} />
      {hasGigawattEntitlement && <Route path="/AIChat" element={<AIChat />} />}
      <Route path="/helpful-links" element={<HelpfulLinks />} />
      <Route path="/support-center" element={<SupportCenterHome />} />
      <Route path="/request-support" element={<RequestSupportPage />} />
      <Route path="/tasks" element={<Tasks />} />
      <Route path="/tasks/:requestId" element={<TaskDetails />} />

      {showOrganizationSwitcher && (
        <Route path="/switcher" element={<OrgSwitcher />} />
      )}
    </Route>
  ))();

  const hubUserRoutes = (() => (
    <Route element={<DefaultPageWrapper />} errorElement={<ErrorPage />}>
      <Route path="*" element={<Navigate to="/support-center" />} />
      {commonRoutes}
    </Route>
  ))();

  const appRoutes = (() => (
    <Route>
      {
        <Route
          path="/integrations/connect/:applicationKey"
          element={<IntegrationsConnect />}
        />
      }
      {
        <Route
          path="/integrations/oauth/callback"
          element={<IntegrationsCallback />}
        />
      }
      <Route element={<DefaultPageWrapper />} errorElement={<ErrorPage />}>
        <Route path="/welcome" element={<Navigate to="/" />} />
        <Route
          path="/"
          element={
            <Navigate
              to={
                !hasITPlanEntitlement
                  ? '/tasks'
                  : isAdminOrSupportRole
                    ? '/your-it-plan'
                    : '/support-center'
              }
            />
          }
        />
        {commonRoutes}

        {itHubDashboard && <Route path="/dashboard" element={<Dashboard />} />}
        {hasITPlanEntitlement && (
          <Route path="/your-it-plan" element={<YourItPlan />} />
        )}
        {/* Security Pages */}
        {hasAntivirusEntitlement && (
          <Route path="/security-solutions/antivirus" element={<Antivirus />} />
        )}
        {hasEmailSecurityEntitlement && (
          <Route
            path="/security-solutions/email-security"
            element={<EmailSecurity />}
          />
        )}
        <Route path="/password-breaches" element={<BreachDashboard />} />
        {/* For backwards compatibility */}
        <Route path="/employees" element={<Employees />} />
        <Route path="/active-employees" element={<Employees />} />
        <Route
          path="employees/status-review"
          element={<EmployeesStatusReview />}
        />
        <Route path="employees/:employeeId" element={<EmployeeProfile />} />
        <Route path="/inactive-employees" element={<InactiveEmployees />} />
        {hasEmployeeGroupsEntitlement && (
          <>
            <Route path="/groups" element={<EmployeeGroups />} />
            <Route path="/groups/:groupId" element={<GroupDetails />} />
            <Route path="/groups/new" element={<NewEmployeeGroup />} />
          </>
        )}
        <Route path="/applications" element={<OrganizationApplications />} />
        <>
          <Route path="/onboardings/hris/new" element={<NewHRISOnboarding />} />
          <Route
            path="/offboardings/hris/new"
            element={<NewHRISOffboarding />}
          />
        </>
        <Route
          path="/applications/:applicationSlug"
          element={<ApplicationDetails />}
        />
        {/* For backwards compatibility */}
        <Route path="/hardware" element={<StorefrontPage />} />
        <Route path="/purchase-hardware" element={<StorefrontPage />} />
        <Route path="/track-orders" element={<OrdersListPage />} />
        <Route
          path="/track-orders/:friendlyId"
          element={<OrderDetailsPage />}
        />
        <Route path="/ship-device" element={<ShipDevice />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/plans" element={<Plans />} />
        <Route path="/discover" element={<Discover />} />

        {electrolyteDeviceOverview && hasElectricAppEntitlement && (
          <Route path="/device-overview" element={<DeviceOverview />} />
        )}
        {electrolyteDeviceManagement &&
          canReadAssets &&
          hasElectricAppEntitlement && (
            <Route path="/device-management" element={<DeviceManagement />} />
          )}
        {electrolyteAssets && canReadAssets && (
          <Route path="/assets" element={<Assets />} />
        )}
        {electrolyteAssets && canCreateAssets && (
          <Route path="/assets/new" element={<NewAsset />} />
        )}
        {electrolyteAssets && canReadAssets && (
          <Route path="/assets/:assetId" element={<AssetDetails />} />
        )}
        {electrolyteAssets && canUpdateAssets && (
          <Route
            path="/assets/:assetId/edit"
            element={<AssetDetails isEditing />}
          />
        )}
        {canCreateEmployees && (
          <Route path="/onboardings/new" element={<NewOnboarding />} />
        )}
        {canUpdateEmployees && (
          <Route path="/offboardings/new" element={<NewOffboarding />} />
        )}
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Route>
  ))();

  const reRoutes = (() => (
    <Route errorElement={<ErrorPage />}>
      {/* Security pages */}
      <Route
        path="/security-solutions"
        element={<Navigate replace to="/discover" />}
      />
      <Route
        path="/security-solutions/threatdown"
        element={<Navigate replace to="/security-solutions/antivirus" />}
      />
      <Route
        path="/security-solutions/hec"
        element={<Navigate replace to="/security-solutions/email-security" />}
      />
    </Route>
  ))();

  const getRoutesByRole = () => {
    // When the user is not Admin or Product Support then return Hub users roots
    if (!isAdminOrSupportRole) {
      return hubUserRoutes;
    }

    // Roots for Admin or Product Support users
    return (
      <>
        {appRoutes}
        {reRoutes}
      </>
    );
  };

  const routesToRender = (() => {
    if (itHubMaintenancePage) {
      return maintenanceRoutes;
    }

    if (shouldRenderIntakeForm) {
      return intakeFormRoutes;
    }

    return (
      <Route>
        {getRoutesByRole()}
        <Route element={<ProtectedRoute isAllowed={hasElectricPermissions} />}>
          <Route path="/internal/*" element={<InternalRoutes />} />
        </Route>
      </Route>
    );
  })();

  const router = createBrowserRouter(createRoutesFromElements(routesToRender));

  return <RouterProvider router={router} fallbackElement={<PageSpinner />} />;
};

export default ElectrolyteRoutes;

import { Chip, ColorsV2, Flex, Text } from '@electricjs/arc';

type AssetHistoryTableHeaderProps = {
  assetHistoryCount: number;
};

const AssetHistoryTableTableHeader = ({
  assetHistoryCount,
}: AssetHistoryTableHeaderProps) => {
  return (
    <Flex
      width="100%"
      p="2.5rem"
      overflowX="hidden"
      vAlignContent="center"
      hAlignContent="between">
      <Flex vAlignContent="center">
        <Text variant="heading-2">Asset History</Text>
        <Chip
          id="asset-history-count-chip"
          ml="3"
          backgroundColor={ColorsV2.PRIMARY_LIGHTEST}
          variant="small">
          <Text variant="legal" intent="brand">
            {assetHistoryCount} {assetHistoryCount !== 1 ? 'entries' : 'entry'}
          </Text>
        </Chip>
      </Flex>
    </Flex>
  );
};

export default AssetHistoryTableTableHeader;

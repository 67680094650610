import { Flex, MenuItem, Text, Tooltip } from '@electricjs/arc';
import { useGlobalUI } from '@/components/GlobalUIProvider';
import WipeDeviceModal from '@/components/People/EmployeeProfile/WipeDeviceModal';
import { DeviceAsset } from '@/types/assets';

type WipeDeviceMenuItemProps = {
  deviceAsset: DeviceAsset;
  employeeName: string;
  isLoading: boolean;
  isProdSupport: boolean;
};

const WipeDeviceMenuItem = ({
  deviceAsset,
  employeeName,
  isLoading,
  isProdSupport,
}: WipeDeviceMenuItemProps) => {
  const { openModal, closeModal } = useGlobalUI();

  const handleWipeDevice = () => {
    if (!employeeName) return;

    openModal(
      <WipeDeviceModal
        employeeName={employeeName}
        deviceAsset={deviceAsset}
        onCompleteWipe={closeModal}
        onCancel={closeModal}
      />
    );
  };

  return (
    <Tooltip
      maxWidth="30rem"
      content="This feature is disabled for org switcher. Please contact Product Support if you need help"
      placement="left"
      disabled={!isProdSupport}>
      <Flex width="100%">
        <MenuItem
          id="wipe-device-menu-item"
          onClick={handleWipeDevice}
          disabled={isLoading || isProdSupport}>
          <Text intent="error">Wipe device</Text>
        </MenuItem>
      </Flex>
    </Tooltip>
  );
};

export default WipeDeviceMenuItem;

import styled from 'styled-components';

import {
  Box,
  Button,
  ColorsV2,
  Flex,
  OneColumnLayout,
  Pane,
  Text,
  useBreakpoint,
} from '@electricjs/arc';

import homeHeroBanner from '@/assets/home-hero-banner.png';
import homeHeroBannerMobile from '@/assets/home-hero-banner_mobile.png';
import { useTicketFlow } from '@/components/TicketFlowContext';
import { useGetLoggedUser } from '@/hooks/useGetLoggedUser';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import {
  useGetOrganizationQuery,
  useGetRequestTypeCategoriesQuery,
} from '@/redux/slices/organizationApiSlice';
import { OrganizationUserRoleSlugs } from '@/types/users';

import NotificationsContainer from './components/NotificationsContainer';
import SupportCenterCards from './components/SupportCenterCards';
import installDesktopAppImage from '@/assets/eih_data.svg';
import { useGetMyDevicesQuery } from '@/redux/slices/deviceApiSlice';
import { getWasElectricAppInstalledOnDevice } from '@/components/Assets/helpers/getEnrollment';
import { DeviceAsset } from '@/types/assets';
import { useGetEmployeeAssetsQuery } from '@/redux/slices/assetApiSlice';

const Header = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  grid-column: 1/-1;
  margin: -3rem -3rem 0;
  width: calc(100% + 6rem);
  height: 30rem;
  background: url('${homeHeroBanner}') no-repeat center / cover;

  @media (max-width: 600px) {
    background-image: url('${homeHeroBannerMobile}');
  }
`;

type HomeHeaderProps = { firstName?: string };

const HomeHeader = ({ firstName }: HomeHeaderProps) => {
  return (
    <Header>
      <Text color={ColorsV2.BRAND_PINK} variant="heading-3" mt={6}>
        Support Center
      </Text>
      <Text variant="heading-1" mt="5rem">
        {firstName ? `Hello, ${firstName}!` : 'Hello!'}
      </Text>
      <Text mt="2rem">With what do you need assistance?</Text>
    </Header>
  );
};

const SupportCenterHome = () => {
  const { ticketCreated } = useTicketFlow();

  const loggedUser = useGetLoggedUser();
  const { md } = useBreakpoint();

  const firstName = loggedUser.employeeFirstName ?? '';
  const isAdmin = !!loggedUser.organizationUserRoles?.some(
    role => role.slug === OrganizationUserRoleSlugs.SUPER_ADMIN
  );

  const organizationId = useGetOrganizationId();
  const { data: organization } = useGetOrganizationQuery(organizationId);
  const { data: myDevices } = useGetMyDevicesQuery(organizationId);

  if (!organization) {
    throw new Error('No organization selected');
  }

  const { data: requestTypeCategories, isFetching } =
    useGetRequestTypeCategoriesQuery({ organizationId: organizationId });

  const { data: assetsData } = useGetEmployeeAssetsQuery(
    {
      organizationId,
      employeeId: loggedUser.employeeId ?? '',
    },
    {
      skip: !organizationId || !loggedUser.employeeId,
    }
  );

  // Get a list of my devices without the Electric app installed
  const assets = assetsData?.results as DeviceAsset[];
  const myDevicesWithoutElectricApp = assets?.filter(
    deviceAsset =>
      deviceAsset.device?.reportingData &&
      myDevices?.some(myDevice => myDevice.id === deviceAsset.device.id) &&
      !getWasElectricAppInstalledOnDevice(deviceAsset)
  );

  // Show banner if one or more of my devices are missing the desktop app
  const showInstallDesktopAppBanner = myDevicesWithoutElectricApp?.length > 0;

  const serialNumbersWithoutApp = myDevicesWithoutElectricApp
    ?.map(device => device.serialNumber)
    .join(', ');

  const desktopAppInstallBanner = (
    <Pane
      elevated
      withPadding
      flexWrap="nowrap"
      maxWidth="125rem"
      margin="1.6rem auto 0 auto">
      <Flex
        flexWrap={md ? 'nowrap' : 'wrap'}
        gap="3rem"
        width="100%"
        padding="0 1.6rem"
        mb={0}>
        <img
          alt="manageEmployeeGroups"
          src={installDesktopAppImage}
          height={60}
        />
        <Flex flexDirection="column" width="100%">
          <Text variant="heading-3">Install Electric for Desktop</Text>
          <Text variant="body-2" maxWidth="75rem">
            Hey there! It looks like you haven’t installed Electric yet
            {myDevicesWithoutElectricApp?.length > 1 && // Only for employees with multiple devices
              serialNumbersWithoutApp && (
                <> on your device(s) with S/N: {serialNumbersWithoutApp}</>
              )}
            . Electric is your go-to hub for accessing everything you need to
            stay productive. From work applications to essential security
            functions, it’s designed to simplify your day and keep you focused
            on what matters.
            <Text variant="body-2" maxWidth="75rem">
              Click below to get started!
            </Text>
          </Text>
          <Button
            mt="2rem"
            id="download-desktop-app-button"
            iconAfter="arrow-right-circle"
            onClick={() =>
              window.open(window._env_?.VITE_DESKTOP_APP_DOWNLOAD_URL, '_blank')
            }
            variant="link">
            Download Electric for Desktop
          </Button>
        </Flex>
      </Flex>
    </Pane>
  );

  return (
    <OneColumnLayout>
      <HomeHeader firstName={firstName} />
      <Flex justifyContent="center" vertical gap="2rem" padding="4.6rem 1.6rem">
        <NotificationsContainer />
        {showInstallDesktopAppBanner && desktopAppInstallBanner}
        <SupportCenterCards
          isLoading={isFetching}
          loggedUser={loggedUser}
          isAdmin={isAdmin}
          organizationAddOns={organization.addOns ?? []}
          requestTypeCategories={requestTypeCategories ?? []}
        />
      </Flex>
      {/*
        This may look useless, but apparently it's used by Pendo? So we probably shouldn't delete it.
        https://github.com/ElectricAI/end-user-electric/pull/271/files#r1140091542
      */}
      {ticketCreated && (
        <Box
          display="flex"
          justifyContent="center"
          width="100%"
          marginTop="3rem">
          &nbsp;
        </Box>
      )}
    </OneColumnLayout>
  );
};

export default SupportCenterHome;

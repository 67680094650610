import {
  ColorsV2,
  Flex,
  getToken,
  IconButton,
  Pane,
  Text,
  useBreakpoint,
} from '@electricjs/arc';
import styled from 'styled-components';
import { NAV_HEIGHT } from '../../sizes';

import electric_logo_only_black from '@/assets/electric_logo_only_black.svg';

const StyledTopNav = styled(Pane)`
  z-index: 2;
  height: ${NAV_HEIGHT};
  min-height: ${NAV_HEIGHT};
  padding: ${props => (props.$md ? '0 2.5rem' : '0 1rem')};
  border-radius: unset;
  flex-wrap: nowrap;

  justify-content: 'flex-start';

  background: ${getToken(ColorsV2.BACKGROUND)};
  border-bottom: solid 1px ${getToken(ColorsV2.GRAY_LIGHT)};
`;

const InternalTopNavWrapper = ({
  onMobileMenuClick,
}: {
  onMobileMenuClick?: () => void;
}) => {
  const { md } = useBreakpoint();

  const mobileHamburgerMenu = !md ? (
    <>
      <IconButton
        id="topnav-hamburger-button"
        icon="menu"
        onClick={onMobileMenuClick}
        intent="neutral"
        flexGrow={0}
      />
      <Flex justifyContent="center" alignItems="center" flexGrow={2} pr="5rem">
        <img
          src={electric_logo_only_black}
          alt="Electric Internal Logo"
          style={{ marginRight: '0.8rem' }}
        />
        <Text variant="heading-3">Internal Portal</Text>
      </Flex>
    </>
  ) : null;

  return (
    <nav aria-label="Admin" style={{ width: '100%' }}>
      <StyledTopNav
        withPadding
        vertical="center"
        id="topnav"
        testId="topnav"
        $md={md}>
        {/* Mobile hamburger menu */}
        {mobileHamburgerMenu}
      </StyledTopNav>
    </nav>
  );
};

export default InternalTopNavWrapper;

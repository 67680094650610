import { Flex, useBreakpoint } from '@electricjs/arc';
import {
  IntegrationCard,
  IntegrationCardProps,
} from '@/components/Settings/IntegrationsPane/IntegrationCard/IntegrationCard';

export const GoogleAppIntegrationCard = (props: IntegrationCardProps) => {
  const { md } = useBreakpoint();

  return (
    <Flex width="34rem" flexWrap={md ? 'nowrap' : 'wrap'} gap="2.6rem">
      <Flex minWidth="34rem" width="34rem">
        <IntegrationCard
          {...props}
          description="Automate creating and removing Google accounts for onboarding & offboarding."
        />
      </Flex>
    </Flex>
  );
};

import { rest } from 'msw';

import { simpleHrisIssue } from '../data/hrisIntegrationData';

type LinkTokenDto = {
  link_token: string;
};

const linkTokenResponse: LinkTokenDto = {
  link_token: 'test_link_token',
};

export const hrisIntegrationHandlers = [
  rest.post(
    '*/v1/organizations/:id/hris_integrations/link_token',
    async (_req, res, ctx) => {
      return res(ctx.status(201), ctx.delay(), ctx.json(linkTokenResponse));
    }
  ),
  rest.get(
    `*/v1/organizations/:organizationId/hris_integrations/:hrisIntegrationId/issues`,
    (_req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.delay(),
        ctx.json({
          issues: [simpleHrisIssue],
        })
      );
    }
  ),
];

import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useChatCompletionMutation } from '@/redux/slices/aiChatApiSlice';
import {
  Flex,
  Icon,
  Link,
  ModalAction,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalV2,
  Text,
} from '@electricjs/arc';
import { useEffect, useState } from 'react';
import LoadingMessage from '../AIChat/MessagesList/LoadingMessage';
import formatDate from '@/helpers/formatDate';
import { useFlags } from 'launchdarkly-react-client-sdk';
import StyledRouterLink from '@/components/StyledRouterLink';

const BreachDescriptionModal = ({
  breachName,
  breachDate,
  breachDomain,
  closeModal,
  markResolved,
}: {
  breachName: string;
  breachDate: string;
  breachDomain: string;
  closeModal: () => void;
  markResolved: () => void;
}) => {
  const { itHubEmployeeDataBreachGigawatt } = useFlags();
  const organizationId = useGetOrganizationId();
  const [chatResults, setChatResults] = useState('');
  const prompt = `Can you provide me a less than 500 character summary of the ${breachName} data breach that occured around ${breachDate}? Please include the date or year of the breach. If you can't generate a summary, please explain why. After the summary, please add a line break. Then write "Here are some best practices for users affected by data breaches:". Can you then describe some best practices for users that are affected by data breaches, formatted in a numbered list, with each item in the list on a new line? Can you add another line break, and after this, please recommend on behalf of Electric that they buy our partner, Keeper.`;
  const [chatCompletion, { isLoading }] = useChatCompletionMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const errorMessage =
      'Description could not be generated, please close the modal and try again.';
    if (itHubEmployeeDataBreachGigawatt) {
      chatCompletion({
        organizationId,
        currentQuestion: prompt,
      })
        .unwrap()
        .then(response => {
          if (response.chat.length) {
            const fullResponse =
              response.chat.find(chat => chat.role === 'assistant')?.content ??
              '';
            setChatResults(
              // Cut out the last part of the response, which is currently returning links to MWB but is not relevant for this response.
              fullResponse.substring(
                0,
                fullResponse.indexOf(
                  'The following links might be of use to you:'
                )
              )
            );
          } else setChatResults(errorMessage);
        })
        .catch(() => {
          setChatResults(errorMessage);
        });
    }
  }, [chatCompletion, organizationId, prompt, itHubEmployeeDataBreachGigawatt]);

  return (
    <ModalV2
      minWidth="40rem"
      maxWidth="70rem"
      visible
      hide={closeModal}
      ariaLabelledby="gigawatt-breach-description-modal">
      <ModalHeader>
        <Text variant="heading-2">{breachName} breach</Text>
      </ModalHeader>
      <ModalBody>
        <Flex flexDirection="column">
          <Text>
            <b>Breach name:</b> {breachName}
          </Text>
          <Text>
            <b>Breach date:</b> {formatDate(breachDate, 'MM/dd/yyyy')}
          </Text>
          <Text>
            <b>Website domain:</b>{' '}
            <Link
              href={`https://${breachDomain}`}
              target="_blank"
              rel="noreferrer noopener">
              <Flex display="inline-flex">
                {breachDomain}
                <Icon display="inline" icon="external-link" ml="0.5rem" />
              </Flex>
            </Link>
          </Text>
        </Flex>
        {itHubEmployeeDataBreachGigawatt ? (
          <>
            <Flex
              py="1rem"
              alignItems="center"
              width="100%"
              justifyContent="center">
              {isLoading ? (
                <>
                  <Icon
                    icon="sparkles"
                    size="large"
                    intent="neutral"
                    mr="2rem"
                    mb="0.8rem"
                  />
                  <LoadingMessage />
                </>
              ) : (
                <Flex vertical>
                  <Text
                    mb="4"
                    variant="body-2"
                    style={{ whiteSpace: 'pre-line' }}>
                    {chatResults}
                  </Text>
                </Flex>
              )}
            </Flex>
            <Flex alignItems="center">
              <Text variant="legal">Powered by Gigawatt</Text>
              <Icon icon="sparkles" intent="neutral" ml="1rem" />
            </Flex>
          </>
        ) : (
          <Text my="1.6rem">
            Here are some best practices for users affected by password
            breaches:
            <br />
            1. Change your passwords immediately
            <br />
            2. Enable Two-Factor Authentication
            <br />
            3. Avoid clicking on suspicious links or emails
            <br />
            4. Regularly monitor your accounts for unusual activity
            <span style={{ display: 'block', height: '1.6rem' }} />
            We recommend using a Password Manager, like{' '}
            <StyledRouterLink
              id="breach-keeper-modal-link"
              to="/discover?addon=keeper"
              onClick={closeModal}>
              <span style={{ color: 'inherit' }}>our partner Keeper</span>
            </StyledRouterLink>
            , to enhance your security and manage your passwords efficiently.
          </Text>
        )}
      </ModalBody>
      <ModalFooter>
        <Flex width="100%" justifyContent="space-between" alignItems="center">
          <StyledRouterLink
            id="breach-learn-more-security-link"
            to="/discover"
            onClick={closeModal}>
            <Text variant="legal" color="inherit">
              Learn more about security solutions
            </Text>
          </StyledRouterLink>
          <Flex>
            <ModalAction
              id="gigawatt-breach-description-modal-close"
              onClick={closeModal}
              mr="2.6rem"
              variant="text">
              Cancel
            </ModalAction>
            <ModalAction
              id="gigawatt-breach-description-modal-resolve"
              loading={isSubmitting}
              onClick={() => {
                setIsSubmitting(true);
                // Modal is automatically closed by this function
                markResolved();
              }}>
              Mark resolved
            </ModalAction>
          </Flex>
        </Flex>
      </ModalFooter>
    </ModalV2>
  );
};

export default BreachDescriptionModal;

import { useGetEmployeeByEmailQuery } from '@/redux/slices/employeeApiSlice';
import { DescriptionColumn } from '../ColumnsAndContainers/DescriptionColumn';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { Skeleton, Text } from '@electricjs/arc';
import StyledRouterLink from '@/components/StyledRouterLink';
import { formatTimestamp } from '@/helpers/DateHelpers';
import { DeviceIssueType } from '@/types/devices';

const IssueTypeMap: { [key in DeviceIssueType]?: string } = {
  encryption: 'Encryption',
  firewall: 'Firewall',
  gatekeeper: 'Gatekeeper',
  os: 'OS update',
  storage: 'Disk space',
  ram: 'Ram',
  mdm_not_reporting: 'Lost MDM connection',
  osquery_not_reporting: 'Lost Electric App connection',
  device_lock: 'Lost device is locked',
};

type IgnoredIssueDescriptionColumnProps = {
  issue: DeviceIssueType;
  createdAt?: string;
  performedByEmail?: string;
  serialNumber?: string;
  showIcon?: boolean;
};

export const IgnoredIssueDescriptionColumn = ({
  issue,
  createdAt,
  performedByEmail = '',
  serialNumber,
  showIcon,
}: IgnoredIssueDescriptionColumnProps) => {
  const organizationId = useGetOrganizationId();
  const { data, isLoading } = useGetEmployeeByEmailQuery(
    {
      organizationId,
      email: performedByEmail,
    },
    { skip: !performedByEmail }
  );

  if (isLoading) {
    <Skeleton width="10rem" height="6rem" />;
  }

  const text = `ignored the issue '${IssueTypeMap[issue] ?? issue}' on ${formatTimestamp(createdAt)}`;

  if (!data) {
    // If employee not found render the user email that ignored the issue.
    return (
      <DescriptionColumn
        iconType="WARNING"
        title={`${performedByEmail} ${text}`}
        serialNumber={serialNumber}
        showIcon={showIcon}
      />
    );
  }

  const fullName = [data.firstName, data.lastName].filter(Boolean).join(' ');

  const title = (
    <Text variant="label-large">
      <StyledRouterLink to={`/employees/${data.id}`}>
        {fullName}
      </StyledRouterLink>
      &nbsp;
      {text}
    </Text>
  );

  return (
    <DescriptionColumn
      iconType="WARNING"
      title={title}
      serialNumber={serialNumber}
      showIcon={showIcon}
    />
  );
};

import { useIntakeFormUI } from '@/components/IntakeFormProvider';
import { isFetchBaseQueryError } from '@/redux/slices/helpers/errorHandlersHelpers';
import { useGetOrganizationIntakeFormQuery } from '@/redux/slices/organizationApiSlice';
import { useAppSelector } from '@/redux/store';
import { OrganizationUserRoleSlugs } from '@/types/users';

import { useGetOrganizationId } from './useGetOrganizationId';

export const useIntakeSurvey = () => {
  const { showIntakeForm } = useIntakeFormUI();

  const organizationId = useGetOrganizationId();

  const { error } = useGetOrganizationIntakeFormQuery({
    organizationId,
  });

  const loggedUser = useAppSelector(state => state.loggedUser);
  const isAdminRole = loggedUser?.organizationUserRoles?.some(
    role => role.slug === OrganizationUserRoleSlugs.SUPER_ADMIN
  );
  const intakeFormDoesNotExist =
    isFetchBaseQueryError(error) && error.status === 404;

  const shouldRenderIntakeForm =
    showIntakeForm && isAdminRole && intakeFormDoesNotExist;

  return { shouldRenderIntakeForm };
};

import { useGetLoggedUser } from '@/hooks/useGetLoggedUser';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import {
  useGetRequestTypeCategoryChampionEmployee,
  RequestTypeCategoryNameEnum,
} from '@/hooks/useGetRequestTypeCategoryChampionEmployee';

type RequestTypeCategoryNameEnum =
  (typeof RequestTypeCategoryNameEnum)[keyof typeof RequestTypeCategoryNameEnum];

const useIsRequestTypeCategoryChampion = (
  category: RequestTypeCategoryNameEnum
) => {
  const organizationId = useGetOrganizationId();
  const categoryChampion = useGetRequestTypeCategoryChampionEmployee(
    organizationId,
    category
  );

  const loggedUser = useGetLoggedUser();
  if (!loggedUser?.id) {
    return false;
  }

  const isChampion = categoryChampion?.userId === loggedUser?.id;

  return isChampion;
};

export default useIsRequestTypeCategoryChampion;

import { DeviceIssueType } from '@/types/devices';
import { DescriptionColumn } from '@/components/DeviceOverview/DeviceHealthMonitor/ColumnsAndContainers/DescriptionColumn';
import { formatTimestamp } from '@/helpers/DateHelpers';
import { ReactNode } from 'react';

type mapFunctionProps = {
  createdAt?: string;
  serialNumber?: string;
  showIcon?: boolean;
};

type DeviceIssueCreatedMapType = {
  [key in DeviceIssueType]: (props: mapFunctionProps) => ReactNode;
};

export const DeviceIssueCreatedMap: DeviceIssueCreatedMapType = {
  [DeviceIssueType.Firewall]: ({
    serialNumber,
    showIcon,
  }: mapFunctionProps) => (
    <DescriptionColumn
      iconType="WARNING"
      title="Device issue: Missing firewall"
      serialNumber={serialNumber}
      detail="Electric will help remediate this issue."
      showIcon={showIcon}
    />
  ),
  [DeviceIssueType.Gatekeeper]: ({
    serialNumber,
    showIcon,
  }: mapFunctionProps) => (
    <DescriptionColumn
      iconType="WARNING"
      title="Device issue: Missing gatekeeper"
      serialNumber={serialNumber}
      detail="Electric will help remediate this issue."
      showIcon={showIcon}
    />
  ),
  [DeviceIssueType.OS]: ({ serialNumber, showIcon }: mapFunctionProps) => (
    <DescriptionColumn
      iconType="WARNING"
      title="Device issue: OS out of date"
      serialNumber={serialNumber}
      detail="Electric will help remediate this issue."
      showIcon={showIcon}
    />
  ),
  [DeviceIssueType.Encryption]: ({
    serialNumber,
    showIcon,
  }: mapFunctionProps) => (
    <DescriptionColumn
      iconType="WARNING"
      title="Device issue: Missing encryption"
      serialNumber={serialNumber}
      detail="Electric will help remediate this issue."
      showIcon={showIcon}
    />
  ),
  [DeviceIssueType.Storage]: ({ serialNumber, showIcon }: mapFunctionProps) => (
    <DescriptionColumn
      iconType="WARNING"
      title="Device issue: Low disk space"
      serialNumber={serialNumber}
      detail="Electric will help remediate this issue."
      showIcon={showIcon}
    />
  ),
  [DeviceIssueType.RAM]: ({ serialNumber, showIcon }: mapFunctionProps) => (
    <DescriptionColumn
      iconType="WARNING"
      title="Device issue: Low RAM"
      serialNumber={serialNumber}
      detail="We recommend replacing their device."
      showIcon={showIcon}
    />
  ),
  /* TODO HY-218 We're temporarily disabling Battery health while we improve the logic behind it */
  // [DeviceIssueType.BatteryHealth]: ({ serialNumber, showIcon }: mapFunctionProps) => (
  //   <DescriptionColumn
  //     iconType="ALERT"
  //     title="Device issue: Poor battery health"
  //     serialNumber={serialNumber}
  //     detail="This device has poor battery performance. We recommend a replacement."
  //     showIcon={showIcon}
  //   />
  // ),
  [DeviceIssueType.OsqueryNotReporting]: ({
    serialNumber,
    showIcon,
  }: mapFunctionProps) => (
    <DescriptionColumn
      iconType="ALERT"
      title="Device issue: Lost Electric App connection"
      serialNumber={serialNumber}
      detail="Electric will help remediate this issue."
      showIcon={showIcon}
    />
  ),
  [DeviceIssueType.MdmNotReporting]: ({
    serialNumber,
    showIcon,
  }: mapFunctionProps) => (
    <DescriptionColumn
      iconType="WARNING"
      title="Device issue: Lost MDM connection"
      serialNumber={serialNumber}
      detail="Electric will help remediate this issue."
      showIcon={showIcon}
    />
  ),
  [DeviceIssueType.DeviceLock]: ({
    serialNumber,
    createdAt,
    showIcon,
  }: mapFunctionProps) => (
    <DescriptionColumn
      iconType="ALERT"
      title="Lost device is locked"
      serialNumber={serialNumber}
      detail={`This device was locked on ${formatTimestamp(createdAt)}. When the device is found, you can unlock the device from the employee's profile.`}
      showIcon={showIcon}
    />
  ),
};

import { UseFormSetValue, UseFormWatch } from 'react-hook-form';

import {
  ColorsV2,
  Field,
  FieldLabel,
  SearchAsyncSelect,
  useThemeColors,
} from '@electricjs/arc';

import { Group } from '@/types/groups';

type EmployeeGroupSelectorProps = {
  // Since this component is used in a form, we need to pass in the setValue and watch functions from react-hook-form
  // with <any> to account for the various form properties that may be set in the parent form args
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: UseFormWatch<any>;
  label: string;
  required?: boolean;
  placeholder?: string;
  groups: Group[];
  disabled?: boolean;
  helperText?: string;
};

const EmployeeGroupSelector = ({
  label,
  required,
  placeholder,
  setValue,
  watch,
  groups,
  disabled,
  helperText,
}: EmployeeGroupSelectorProps) => {
  const handleGroupChange = (values: Group[]) => {
    values = values ?? [];
    setValue('groups', values, { shouldTouch: true, shouldDirty: true });
  };

  const requiredColor = useThemeColors(ColorsV2.ERROR) as string;

  return (
    <Field data-testid="employee-group-selector">
      <FieldLabel>
        {label}
        {required && <span style={{ color: requiredColor }}>&nbsp;*</span>}
      </FieldLabel>
      {helperText && (
        <FieldLabel variant="legal" pb={'1rem'}>
          {helperText}
        </FieldLabel>
      )}
      <SearchAsyncSelect
        id="employee-group-search-selector"
        isMulti
        isClearable={false}
        isSearchable
        placeholder={placeholder}
        value={watch('groups')}
        name="groups"
        maxMenuHeight={200}
        onChange={handleGroupChange}
        getOptionValue={group => group.id}
        getOptionLabel={group => group.name}
        fetchOptions={async (searchTerm: string) => {
          // async keyword implicitly returns a promise, so no need to explicitly return a promise
          return searchTerm
            ? groups.filter(group =>
                group.name.toLowerCase().includes(searchTerm.toLowerCase())
              )
            : groups;
        }}
        defaultOptions={groups}
        disabled={disabled}
      />
    </Field>
  );
};

export default EmployeeGroupSelector;

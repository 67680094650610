import { rest } from 'msw';

import {
  mockedOnboardings,
  simpleMockedOnboardings,
  simpleOnboarding,
} from '@/mocks/data/onboardingData';
import { requests } from '@/mocks/data/requestData';

export const onboardingHandlers = [
  rest.get(
    `*/v1/organizations/:organizationId/onboardings/:onboardingId`,
    (_req, res, ctx) => {
      return simpleMockedOnboardings.length > 0
        ? res(
            ctx.status(200),
            ctx.delay(),
            ctx.json({
              results: simpleMockedOnboardings[0],
            })
          )
        : res(ctx.status(404));
    }
  ),
  rest.get(
    `*/v1/organizations/:organizationId/employees/:employeeId/onboarding`,
    (_req, res, ctx) => {
      return mockedOnboardings.length > 0
        ? res(
            ctx.status(200),
            ctx.delay(),
            ctx.json({
              results: mockedOnboardings[0],
            })
          )
        : res(ctx.status(404));
    }
  ),
  rest.get(
    `*/v1/organizations/:organizationId/onboardings/:onboardingId/requests`,
    (_req, res, ctx) => {
      return requests.length > 0
        ? res(
            ctx.status(200),
            ctx.delay(),
            ctx.json({
              results: requests,
            })
          )
        : res(ctx.status(404));
    }
  ),
  rest.post(
    `*/v1/organizations/:organizationId/onboardings`,
    async (_req, res, ctx) => {
      // add the onboarding to the mock data (mutate the data)
      mockedOnboardings.push(simpleOnboarding);

      return res(ctx.status(200), ctx.delay());
    }
  ),
];

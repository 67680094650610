import {
  ColorsV2,
  Pane,
  PaneProps,
  Skeleton,
  useBreakpoint,
  useThemeColors,
} from '@electricjs/arc';

type AssetLogRowContainerProps = {
  isLoading?: boolean;
} & Partial<PaneProps>;

export const AssetLogRowContainer = ({
  isLoading = false,
  children,
  ...otherProps
}: AssetLogRowContainerProps) => {
  const { md } = useBreakpoint();
  const [backgroundColor] = useThemeColors([ColorsV2.GRAY_LIGHTER]);

  if (isLoading) {
    return (
      <Pane mt="1.5rem" width="90%" overflowX="hidden">
        <Skeleton width="100rem" height="8rem" />
      </Pane>
    );
  }

  return (
    <Pane
      flexDirection={md ? 'row' : 'column'}
      alignItems="stretch"
      flexWrap="nowrap"
      minHeight="8rem"
      backgroundColor={backgroundColor}
      p="2rem"
      gap="2rem"
      width="100%"
      {...otherProps}>
      {children}
    </Pane>
  );
};

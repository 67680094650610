import { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import {
  ColorsV2,
  Flex,
  Icon,
  IconButton,
  IconInCircle,
  Card,
  Skeleton,
  Text,
  useThemeColors,
} from '@electricjs/arc';

import deviceSetup from '@/assets/eih_explore_hardware.svg';
import { ORDERS_PAGE_SIZE } from '@/constants/pagination';
import { Order } from '@/types/orders';

import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useLazyGetOrdersQuery } from '@/redux/slices/orderApiSlice';
import { orderStatusDisplayName } from '@/components/Orders/utils/formatters';

import { getMostRecentDeviceOrders } from './helpers/DeviceOverviewHelpers';
import StyledRouterLink from '../StyledRouterLink';

const OrderStats = ({ order }: { order: Order }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <Flex pl="2.4rem" mb="1.4rem">
      <Flex
        borderRadius="0.5rem"
        width="3.2rem"
        height="3.2rem"
        justifyContent="center"
        alignItems="center"
        backgroundColor={ColorsV2.GRAY_LIGHT}>
        <Icon icon="package" intent="neutral" size="medium" />
      </Flex>
      <Flex vertical pl="1rem">
        <Link
          id="device-order-redirect-order-page-link"
          to={`/track-orders/${order.friendlyId}`}
          onClick={e => {
            e.preventDefault();
            navigate(`/track-orders/${order.friendlyId}`, {
              state: { order, pathname },
            });
          }}>
          <Text variant="label-small">
            {orderStatusDisplayName[order.status]}
          </Text>
        </Link>
        <Text variant="body-2">
          {order.lines.length} {order.lines.length > 1 ? 'Items' : 'Item'}
        </Text>
        <Text variant="legal">{order.forEmployeeName}</Text>
      </Flex>
    </Flex>
  );
};

const DeviceOrderUpdates = () => {
  const navigate = useNavigate();
  const currentOrganizationId = useGetOrganizationId();
  const [getOrders, { data: orders, isFetching: areOrdersLoading }] =
    useLazyGetOrdersQuery();

  useEffect(() => {
    getOrders(
      {
        organizationId: currentOrganizationId,
        offset: 0,
        limit: ORDERS_PAGE_SIZE,
      },
      true
    );
  }, [currentOrganizationId, getOrders]);

  const recentOrders = getMostRecentDeviceOrders(orders?.results);
  const hasOrders = recentOrders && recentOrders?.length > 0;

  const [iconBackgroundColor] = useThemeColors([ColorsV2.GRAY_LIGHTER]);

  return (
    <Card
      id="device-order-updates"
      elevated
      flexDirection="column"
      width="100%"
      height="24rem"
      headerDivider
      minWidth="34rem"
      title={
        <Flex alignItems="center" width="100%">
          <IconInCircle
            size="3.6rem"
            icon="truck"
            iconSize="medium"
            intent="neutral"
            background={iconBackgroundColor}
          />
          {areOrdersLoading ? (
            <Flex pl="1rem" vAlignContent="center">
              <Skeleton width="10rem" height="3rem" />
            </Flex>
          ) : (
            <Flex
              width="100%"
              justifyContent="space-between"
              alignItems="center">
              <Text variant="heading-3" pl="1rem">
                {hasOrders
                  ? `${recentOrders?.length} order update${recentOrders?.length > 1 ? 's' : ''}`
                  : 'No device orders'}
              </Text>
              {hasOrders && !areOrdersLoading && (
                <IconButton
                  id="device-order-updates-icon-button"
                  alt="chevron-right"
                  icon="chevron-right"
                  intent="neutral"
                  onClick={() => navigate('/track-orders')}
                  variant="fill"
                />
              )}
            </Flex>
          )}
        </Flex>
      }
      footer={
        !hasOrders && (
          <Flex width="100%" justifyContent="center" alignItems="center">
            <StyledRouterLink
              id="device-order-updates-purchase-link"
              to="/purchase-hardware">
              <Text variant="label-small" pt="1rem" color="inherit">
                Check out our device catalog
              </Text>
            </StyledRouterLink>
          </Flex>
        )
      }>
      {areOrdersLoading ? (
        <Flex
          width="100%"
          height="100%"
          ml="2rem"
          justifyContent="left"
          vAlignContent="center">
          <Skeleton count={3} width="20rem" height="4rem" />
        </Flex>
      ) : hasOrders ? (
        <Flex vertical>
          {recentOrders?.map(order => (
            <OrderStats key={order.friendlyId} order={order} />
          ))}
        </Flex>
      ) : (
        <Flex width="100%" height="100%" vertical alignItems="center" px="1rem">
          <img alt="deviceSetup" src={deviceSetup} height="78rem" />
          <Text whiteSpace="nowrap" variant="body-2" py="1rem">
            Buy, track, and return hardware in one portal.
          </Text>
        </Flex>
      )}
    </Card>
  );
};

export default DeviceOrderUpdates;

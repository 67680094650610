import { Flex, FlexProps, Text } from '@electricjs/arc';

import notFoundIllustration from '@/assets/not_found_illustration.svg';

type PageErrorComponentProps = FlexProps & {
  errorType: string;
  title: string;
  message: string;
  imgWidth?: string;
};

export const PageErrorComponent = ({
  errorType,
  message,
  title,
  imgWidth,
  ...props
}: PageErrorComponentProps) => {
  return (
    <Flex
      display="flex"
      align-items="center"
      justifyContent="space-evenly"
      flexDirection="row"
      flexWrap="wrap"
      padding="20vh 2rem"
      gap="5rem"
      {...props}>
      <Flex flexDirection="column">
        <Text variant="label-large" intent="brand">
          {errorType}
        </Text>
        <Text variant="heading-1" mt="1rem">
          {title}
        </Text>
        <Text variant="subheading" textAlign="left" mt="1rem">
          {message}
        </Text>
      </Flex>
      <img alt="Not found" src={notFoundIllustration} width={imgWidth} />
    </Flex>
  );
};

import { Skeleton, Text } from '@electricjs/arc';

import { NotificationIcon } from '../NotificationIcon';
import { NotificationItemHeader } from '../NotificationItemHeader';
import { NotificationItemProps } from '../types';
import { useGetRequestData } from '@/hooks/useGetRequestData';
import formatDate from '@/helpers/formatDate';
import { RenewMdmCertButton } from '@/components/Tasks/Actions/RenewMdmCertButton';

const MdmCertificateAboutToExpireNotificationItem = ({
  notification,
  hideDetails,
}: NotificationItemProps) => {
  const { data: requestData, isLoading } = useGetRequestData({
    requestId: notification?.data?.request_id ?? null,
  });

  if (isLoading) return <Skeleton />;

  const title = (
    <Text fontSize={'14px'} pb={'1rem'}>
      Heads up! Your Apple Push Certificate (CSR) expires on&nbsp;
      <b>{formatDate(requestData.request?.data.expiresAt)}</b>. Renewing ensures
      that existing MDM enrollment is uninterrupted and new devices will be
      protected by MDM.
    </Text>
  );

  return (
    <NotificationItemHeader
      notification={notification}
      title={title}
      icon={
        <NotificationIcon
          isNotificationUnseen={!notification.seenAt}
          icon="alert-triangle"
          intent="warning"
        />
      }
      label="DEVICE MANAGEMENT">
      {!hideDetails ? <RenewMdmCertButton /> : null}
    </NotificationItemHeader>
  );
};

export default MdmCertificateAboutToExpireNotificationItem;

import { useEffect, useState } from 'react';

import { useGlobalUI } from '@/components/GlobalUIProvider';
import { useSendMDMEnrollInstructionsMutation } from '@/redux/slices/employeeApiSlice';
import { useAppSelector } from '@/redux/store';
import { Employee } from '@/types/employees';

import MDMInviteModal from './MDMInviteModal';
import MDMInvitePreviewModal from './MDMInvitePreviewModal';

type MDMInviteModalWrapperProps = {
  employees: Employee[];
  inviteVisible: boolean;
  previewVisible: boolean;
  setShowInviteModal: (show: boolean) => void;
  setShowPreviewModal: (show: boolean) => void;
};

const MDMInviteModalWrapper = ({
  employees,
  inviteVisible,
  previewVisible,
  setShowInviteModal,
  setShowPreviewModal,
}: MDMInviteModalWrapperProps) => {
  const { showSuccessToast, showErrorToast } = useGlobalUI();
  const [selectedEmployees, setSelectedEmployees] = useState(employees);
  const organizationId =
    useAppSelector(state => state.loggedUser?.organizationId) || '';
  const [sendMDMEnrollInstructions, { isLoading }] =
    useSendMDMEnrollInstructionsMutation();
  useEffect(() => {
    setSelectedEmployees(employees);
  }, [employees]);

  const resetSelection = () => {
    setSelectedEmployees(employees);
  };
  const handleCancel = () => {
    setTimeout(resetSelection, 500);
    setShowInviteModal(false);
    setShowPreviewModal(false);
  };
  const handleBack = () => {
    setShowInviteModal(true);
    setShowPreviewModal(false);
  };
  const handlePreview = () => {
    setShowInviteModal(false);
    setShowPreviewModal(true);
  };

  const handleSubmit = () => {
    sendMDMEnrollInstructions({
      employeeIds: selectedEmployees?.map(employee => employee.id) || [],
      organizationId,
    })
      .unwrap()
      .then(() => {
        showSuccessToast({
          id: 'mdm-enroll-instructions-sent-success-toast',
          stack: true,
          message: `Enrollment instructions have been sent to ${
            selectedEmployees?.length
          } ${
            selectedEmployees?.length && selectedEmployees?.length > 1
              ? 'people'
              : 'person'
          }`,
        });
        handleCancel();
      })
      .catch(error => {
        console.error(`Unable to send desktop app invite emails for `, error);
        showErrorToast({
          id: 'mdm-enroll-instructions-sent-error-toast',
          stack: true,
          title: 'Unable to send Emails',
          message:
            'Something went wrong while sending the emails. Please try again.',
        });
      });
  };
  return (
    <>
      <MDMInviteModal
        hide={handleCancel}
        employees={employees}
        selectedEmployees={selectedEmployees}
        setSelectedEmployees={setSelectedEmployees}
        isLoading={isLoading}
        visible={inviteVisible}
        handleCancel={handleCancel}
        handleSubmit={handlePreview}
      />
      <MDMInvitePreviewModal
        hide={handleCancel}
        visible={previewVisible}
        handleBack={handleBack}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
      />
    </>
  );
};

export default MDMInviteModalWrapper;

import { useEffect, useState } from 'react';

/**
 * Returns a debounced value.
 *
 * @param value original value
 * @param delay debounce delay
 * @copyright https://usehooks-ts.com/react-hook/use-debounce
 */
function useDebounceValue<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

export default useDebounceValue;

import * as yup from 'yup';

const phoneRegex = /^\(\d{3}\)[\s-]?\d{3}[\s-]?\d{4}$/;
const containsNumberRegex = /\d/;

type phoneValidationSchemaParams = {
  required?: boolean;
};

// If it is required, check if the phone number is valid
// If it is not required, check if the phone number is valid only if it's not empty
export const phoneValidationSchema = ({
  required = false,
}: phoneValidationSchemaParams = {}) => {
  const invalidNumberMessage = 'Enter a valid phone number';

  if (required) {
    return yup
      .string()
      .trim()
      .matches(phoneRegex, invalidNumberMessage)
      .required('Phone number is required');
  }

  return yup
    .string()
    .trim()
    .test('is-valid-phone', invalidNumberMessage, value => {
      return (
        !value || !containsNumberRegex.test(value) || phoneRegex.test(value)
      );
    });
};

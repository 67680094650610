import styled from 'styled-components';

import { Flex, Text } from '@electricjs/arc';

import { useResponsiveness } from '@common';

import formatDate from '@/helpers/formatDate';
import { useStorefront } from '../../StorefrontProvider';

const StyledText = styled(Text)`
  word-break: break-word;
  hyphens: auto;
`;

const ShippingReadOnly = () => {
  const { storefrontCheckoutForm } = useStorefront();

  const shippingName = storefrontCheckoutForm?.watch('shippingName') ?? '';
  const shippingPhone = storefrontCheckoutForm?.watch('shippingPhone') ?? '';
  const shippingAddress = storefrontCheckoutForm?.watch('shippingAddress');
  const shippingMethod = storefrontCheckoutForm?.watch('shippingMethod');
  const shippingDate = shippingMethod?.shippingDate;

  const { hasNoHorizontalSpace } = useResponsiveness();

  return (
    <Flex
      vertical={hasNoHorizontalSpace}
      columnGap="2rem"
      rowGap={hasNoHorizontalSpace ? '2rem' : '0rem'}>
      {/*  Shipping Address */}
      <Flex width={hasNoHorizontalSpace ? '100%' : '50%'}>
        <Text variant="label-large">Shipping Address</Text>
        <Flex vertical width="100%">
          <StyledText>{shippingName}</StyledText>
          <StyledText>{shippingPhone}</StyledText>
          <StyledText>{shippingAddress?.streetAddress1}</StyledText>
          <StyledText>{shippingAddress?.streetAddress2}</StyledText>
          <StyledText>
            {shippingAddress?.city}, {shippingAddress?.state}{' '}
            {shippingAddress?.zip}
          </StyledText>
        </Flex>
      </Flex>
      {/*  Shipping Method */}
      <Flex width={hasNoHorizontalSpace ? '100%' : '50%'}>
        <Text variant="label-large">Shipping Method</Text>
        <Flex vertical width="100%">
          <StyledText>{shippingMethod?.label}</StyledText>
          <StyledText>
            Ships by {formatDate(shippingDate?.toString())}
          </StyledText>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ShippingReadOnly;

import { ReactElement } from 'react';

import { Flex } from '@electricjs/arc';

import { Notification } from '@/types/notifications';

import { NotificationLabel } from './NotificationLabel';
import { NotificationTitle } from './NotificationTitle';

type NotificationItemHeaderProps = {
  notification: Notification;
  isLoading?: boolean;
  title: ReactElement;
  icon: ReactElement;
  label: string;
  children?: ReactElement | ReactElement[] | null;
};

export const NotificationItemHeader = ({
  notification,
  isLoading = false,
  title,
  children,
  icon,
  label,
}: NotificationItemHeaderProps) => {
  return (
    <Flex px="2">
      {icon}
      <Flex p="1" ml="2" width="100%" flexDirection="column">
        <NotificationLabel
          notificationType={label}
          createdAt={notification.createdAt}
        />
        <NotificationTitle loading={isLoading}>{title}</NotificationTitle>
        {children}
      </Flex>
    </Flex>
  );
};

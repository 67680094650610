import { EntitlementSlugEnum } from '@electricjs/core_entity-client';
import { omit } from 'lodash';
import { ComponentType, ReactNode } from 'react';
import styled from 'styled-components';

import { Flex } from '@electricjs/arc';

import { useOrganizationHasEntitlement } from '@/hooks/useOrganizationHasEntitlement';

import UpgradeChip from './UpgradeChip';

const TopChip = styled(UpgradeChip)`
  position: absolute;
  top: -1.5rem;
  right: 0.5rem;
`;
const BottomChip = styled(UpgradeChip)`
  position: absolute;
  bottom: -1.5rem;
  right: 0.5rem;
`;

type UpgradableProps<P> = {
  component: ComponentType<P>;
  chipPosition?: 'inline' | 'top' | 'bottom';
  children: ReactNode;
  entitlement: EntitlementSlugEnum;
  onUpgradeClick: () => void;
} & P;

/**
 * This component wraps a component and adds a chip to it if the user does not have the entitlement.
 * It also hijacks the onClick event and triggers the "onUpgradeClick" callback (that, for example, can be used to redirect the user to the plans page)
 * The component doesn't directly navigates to the plans page because the Router Provider is not defined on the modal level and also to allow other usages.
 *
 * You can use the component as you would normally use it, pass the component type
 * you intended to use as a prop to `component`, and then define the entitlement
 * that needs to be verified. You can use an optional `chipPosition` prop to
 * define where the chip should be placed.
 *
 * Example:
 * <Upgradable
 *   component={Button}
 *   entitlement={EntitlementSlugEnum.HrisIntegration}
 *   onUpgradeClick={() => navigate('/plans')}
 *   onClick={() => alert('boop')}>
 *   Click me
 * </Upgradable>
 */
const Upgradable = <P,>({
  component,
  chipPosition = 'inline',
  children,
  entitlement,
  onUpgradeClick,
  ...props
}: UpgradableProps<P>) => {
  const entitlementAvailable = useOrganizationHasEntitlement(entitlement);
  const Component = component;

  if (entitlementAvailable) {
    return <Component {...(props as P)}>{children}</Component>;
  }

  const propsWithoutOnClick = omit(props, 'onClick');
  let updatedChildren = children;

  if (chipPosition === 'inline') {
    updatedChildren = (
      <Flex
        flexWrap="nowrap"
        justifyContent="space-between"
        alignItems="center">
        <>{children}</>
        <UpgradeChip onClick={onUpgradeClick} />
      </Flex>
    );
  } else if (chipPosition === 'top') {
    updatedChildren = (
      <>
        {children}
        <TopChip onClick={onUpgradeClick} />
      </>
    );
  } else {
    updatedChildren = (
      <>
        {children}
        <BottomChip onClick={onUpgradeClick} />
      </>
    );
  }

  return (
    <Component onClick={onUpgradeClick} {...(propsWithoutOnClick as P)}>
      {updatedChildren}
    </Component>
  );
};

export default Upgradable;

import styled from 'styled-components';

import {
  ColorsV2,
  Divider,
  Flex,
  Table,
  TableContainer,
  Text,
  useThemeColors,
} from '@electricjs/arc';

import { Organization } from '@/types/organizations';
import { AssetCountTableRow } from '@/types/assets';
import { createColumnHelper } from '@tanstack/react-table';
import TableCellFlexContainer from 'common/Table/TableCellFlexContainer';
import { useGetWarehouseMetricsQuery } from '@/redux/slices/assetApiSlice';
import { skipToken } from '@reduxjs/toolkit/query';

type WarehousedDevicesTileProps = {
  organization: Organization | undefined;
  organizationLoading: boolean;
  isInGrid: boolean;
  height: string;
};

const columnHelper = createColumnHelper<AssetCountTableRow>();

const StyledText = styled(Text)`
  display: -webkit-box;
  // Ellipsis text when it reaches 2nd line
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const WarehousedDevicesTile = ({
  organization,
  organizationLoading,
  isInGrid,
  height,
}: WarehousedDevicesTileProps) => {
  const [borderColor] = useThemeColors([ColorsV2.BORDER]);

  const organizationId = organization?.id;

  const { isFetching: assetCountsLoading, data: warehousedAssetCountData } =
    useGetWarehouseMetricsQuery(
      organizationId
        ? {
            organizationId: organizationId,
          }
        : skipToken
    );

  // Dynamically change table column width
  const columnWidth = Math.round(window.innerWidth * 0.05);

  const warehousedDevicesTableColumns = [
    columnHelper.accessor('month', {
      header: () => <Text variant="body-2">Month</Text>,
      id: 'month',
      meta: {
        minWidth: isInGrid ? 0 : 300,
        width: columnWidth * 1.2,
        flex: isInGrid ? `${columnWidth * 1.2} 0 auto` : '2',
      },
      cell: ({ getValue }) => {
        return (
          <TableCellFlexContainer>
            <Text variant="label-small">{getValue()}</Text>
          </TableCellFlexContainer>
        );
      },
    }),
    columnHelper.accessor('count', {
      header: () => <Text variant="body-2">Device Count</Text>,
      id: 'device-count',
      meta: {
        minWidth: isInGrid ? 0 : 300,
        width: columnWidth * 1.5,
        flex: isInGrid ? `${columnWidth * 1.5} 0 auto` : '2',
      },
      cell: ({ getValue }) => {
        return (
          <TableCellFlexContainer>
            <Text variant="body-2">{getValue()}</Text>
          </TableCellFlexContainer>
        );
      },
    }),
  ];

  return (
    <TableContainer
      vertical
      hAlignContent="center"
      width="100%"
      alignSelf="flex-start"
      flexDirection="column"
      height={isInGrid && height}>
      <Flex justifyContent="space-between" vAlignContent="center" width="100%">
        <Flex vertical rowGap="0.5rem" p="2.5rem">
          <Text variant="heading-2">Warehoused Devices</Text>
          <StyledText>
            View the maximum number of devices stored this month, which
            determines your charges.
          </StyledText>
        </Flex>
      </Flex>
      <Divider color={borderColor} />
      <Table
        id="warehoused-devices-table"
        columns={warehousedDevicesTableColumns}
        data={warehousedAssetCountData?.assetCounts ?? []}
        loading={organizationLoading || assetCountsLoading}
      />
    </TableContainer>
  );
};

export default WarehousedDevicesTile;

import { useState } from 'react';

/**
 * usePaginationControl
 *
 * This custom hook handles frontend pagination for a given list of data.
 * It takes an array of items (`list`) and a specified `pageSize`, and provides:
 *  - `items`: The current page of items.
 *  - `hasMore`: A boolean indicating if there are more items to load.
 *  - `loadNextPage`: A function to load the next page of items.
 *
 * @param {T[]} list - The full list of items to paginate.
 * @param {number} pageSize - The number of items to display per page.
 *
 * @returns {object} - An object containing the current page of items, a boolean indicating more pages, and a function to load the next page.
 */
export function usePaginationControl<T>({
  list,
  pageSize,
}: {
  list: T[];
  pageSize: number;
}): {
  items: T[];
  hasMore: boolean;
  loadNextPage: () => void;
} {
  const [page, setPage] = useState(0);

  const offset = page * pageSize;
  const items = list.slice(0, offset + pageSize);
  const hasMore = offset + pageSize < list.length;

  const loadNextPage = () => {
    setPage(prev => prev + 1);
  };

  return { items, loadNextPage, hasMore };
}

import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Flex } from '@electricjs/arc';

import { useStorefront } from '../../StorefrontProvider';

const StyledButton = styled(Button)`
  width: 100%;
  padding: 1.5rem 0rem;
`;

const QuickActions = () => {
  const navigate = useNavigate();

  const { storefrontCheckoutForm, hasOnlyAssetsInCart, resetStorefront } =
    useStorefront();

  const employeeId = storefrontCheckoutForm?.getValues('employee')?.value;

  return (
    <Flex vertical rowGap="2rem" width="100%">
      {employeeId && (
        <StyledButton
          id="view-employee-profile-button"
          variant="outline"
          onClick={() => navigate(`/employees/${employeeId}`)}>
          View employee profile
        </StyledButton>
      )}
      {!hasOnlyAssetsInCart && (
        <StyledButton
          id="track-order-status-button"
          variant="outline"
          onClick={() => navigate('/track-orders')}>
          Track order status
        </StyledButton>
      )}
      <StyledButton
        id="start-another-order-button"
        variant="outline"
        onClick={() => resetStorefront()}>
        Start another order
      </StyledButton>
    </Flex>
  );
};

export default QuickActions;

import { useUpdateRequestStatus } from '@/hooks/useUpdateRequestStatus';
import { RequestType, RequestTypeSlugEnum } from '@/types/requests';
import {
  useThemeColors,
  Button,
  ColorsV2,
  Text,
  usePopoverContext,
} from '@electricjs/arc';
import { RequestStatusEnum } from '@electricjs/core_entity-client';
import { useNavigate } from 'react-router-dom';
import { RequestCompleted } from './RequestCompleted';
import {
  EXISTING_EMPLOYEE_ID_QUERY_PARAM,
  HRIS_OFFBOARDING_REQUEST_ID_QUERY_PARAM,
  HRIS_ONBOARDING_REQUEST_ID_QUERY_PARAM,
} from '@/constants/onboarding';
import { SelectionParamKeys } from '@/types/queryParamKeys';

type PrimaryButtonProps = {
  onClick?: () => void;
  children: string;
  loading?: boolean;
  isUpdating?: boolean;
};

const PrimaryButton = ({
  onClick,
  children,
  loading,
  isUpdating,
}: PrimaryButtonProps) => {
  const textColor = useThemeColors(ColorsV2.WHITE);

  return (
    <Button
      id="button-mark-as-done"
      minWidth="12rem"
      alignSelf="center"
      py="0.5rem"
      showSkeleton={loading}
      onClick={onClick}
      loading={isUpdating}>
      <Text variant="legal" color={textColor} hidden={isUpdating}>
        {children}
      </Text>
    </Button>
  );
};

type PrimaryActionProps = {
  status?: RequestStatusEnum;
  requestType?: RequestType;
  requestId?: string;
  loading?: boolean;
  isDropDownMenu?: boolean;
  onBehalfOf?: string;
};

export const PrimaryAction = ({
  status,
  requestType,
  requestId = '',
  loading,
  isDropDownMenu,
  onBehalfOf,
}: PrimaryActionProps) => {
  const navigate = useNavigate();
  const { toggle } = usePopoverContext();
  const { updateRequest, updateRequestLoading } = useUpdateRequestStatus({
    requestId,
  });

  if (!status || !requestType) {
    return null;
  }

  if (status !== RequestStatusEnum.InProgress && !loading) {
    return <RequestCompleted />;
  }

  const handleEquipNewHire = () => {
    if (isDropDownMenu) {
      toggle();
    }

    setTimeout(() => {
      navigate(
        `/purchase-hardware?${SelectionParamKeys.EmployeeId}=${onBehalfOf}`
      );
    }, 300);
  };

  const handleOnboardNewHire = () => {
    if (isDropDownMenu) {
      toggle();
    }

    setTimeout(() => {
      navigate(
        `/onboardings/new?${EXISTING_EMPLOYEE_ID_QUERY_PARAM}=${onBehalfOf}&${HRIS_ONBOARDING_REQUEST_ID_QUERY_PARAM}=${requestId}`
      );
    }, 300);
  };

  const handleOffboardEmployee = () => {
    if (isDropDownMenu) {
      toggle();
    }

    setTimeout(() => {
      navigate(
        `/offboardings/${onBehalfOf}?${HRIS_OFFBOARDING_REQUEST_ID_QUERY_PARAM}=${requestId}`
      );
    }, 300);
  };

  const handleMarkAsDone = async () => {
    await updateRequest({ newStatus: RequestStatusEnum.Completed });
  };

  if (requestType?.slug === RequestTypeSlugEnum.HARDWARE_PURCHASE) {
    return (
      <PrimaryButton onClick={handleEquipNewHire} loading={loading}>
        Equip new hire
      </PrimaryButton>
    );
  } else if (requestType?.slug === RequestTypeSlugEnum.HRIS_ONBOARDING) {
    return (
      <PrimaryButton onClick={handleOnboardNewHire} loading={loading}>
        Onboard
      </PrimaryButton>
    );
  } else if (requestType?.slug === RequestTypeSlugEnum.HRIS_OFFBOARDING) {
    return (
      <PrimaryButton onClick={handleOffboardEmployee} loading={loading}>
        Offboard
      </PrimaryButton>
    );
  }

  return (
    <PrimaryButton
      onClick={handleMarkAsDone}
      loading={loading}
      isUpdating={updateRequestLoading}>
      Mark as done
    </PrimaryButton>
  );
};

import { Card, Flex } from '@electricjs/arc';

import AddAssetsSection from '@/components/Assets/AddAssetsSection';

const AssetsEmptyState = () => {
  return (
    <Flex hAlignContent="center" width="100%">
      <Card id="assets-empty-state-card" elevated width="100%">
        <AddAssetsSection />
      </Card>
    </Flex>
  );
};

export default AssetsEmptyState;

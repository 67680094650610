import { generateStyledLink } from '@electricjs/arc';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

const StyledRouterLink = generateStyledLink(RouterLink);

export const StyledRouterLinkNoUnderline = styled(StyledRouterLink)`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export default StyledRouterLink;

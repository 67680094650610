import { Button, Flex, TabPanelProps, Text } from '@electricjs/arc';

type AssetLogTabProps = {
  hasMoreData?: boolean;
  hideLoadMore?: boolean;
  onLoadMore?: () => void;
  isLoadingMore?: boolean;
} & TabPanelProps;

export const AssetLogTab = ({
  onLoadMore,
  hasMoreData = false,
  hideLoadMore = false,
  isLoadingMore = false,
  children,
  ...otherProps
}: AssetLogTabProps) => {
  const showLoadMore = !hideLoadMore && !isLoadingMore;

  return (
    <Flex
      width="100%"
      maxHeight="90rem"
      overflowY="auto"
      flex="1"
      {...otherProps}
      px="1.5rem"
      pt="1rem"
      pb="4rem"
      overflowX="auto"
      flexDirection="column"
      gap="1.5rem">
      {children}
      {showLoadMore &&
        (hasMoreData ? (
          <Flex width="100%" mt="4rem" justifyContent="center">
            <Button
              id="load-more-asset-logs-button"
              variant="outline"
              onClick={onLoadMore}
              loading={isLoadingMore}>
              Load more
            </Button>
          </Flex>
        ) : (
          <Flex width="100%" justifyContent="center">
            <Text variant="body-2" mt="4rem" textAlign="center">
              All caught up! We&rsquo;ll let you know about any future device
              issues.
            </Text>
          </Flex>
        ))}
    </Flex>
  );
};

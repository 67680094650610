import styled from 'styled-components';
import {
  Card,
  ColorsV2,
  Flex,
  getToken,
  IconInCircle,
  Text,
} from '@electricjs/arc';

const NotesContainer = styled(Flex)`
  flex-grow: 1;
  height: 100%;
  min-height: 13rem;
  padding: 0.8rem;
  border: 1px solid ${getToken(ColorsV2.BORDER_LIGHT)};
  border-radius: 0.4rem;
`;

type AssetNotesCardProps = {
  value: string;
};

const AssetNotesCard = ({ value }: AssetNotesCardProps) => {
  return (
    <Card
      id="asset-notes-card"
      width="100%"
      height="auto"
      headerDivider
      title={
        <Flex alignItems="center" gap="2rem">
          <IconInCircle icon="note" intent="info" iconSize="small" />
          <Text variant="subheading">Notes</Text>
        </Flex>
      }>
      <Flex height="100%" p="3.2rem 2.4rem 2.4rem">
        <NotesContainer>
          <Text>{value}</Text>
        </NotesContainer>
      </Flex>
    </Card>
  );
};

export default AssetNotesCard;

import {
  Flex,
  TableContainer,
  Pane,
  ColorsV2,
  getToken,
  Spinner,
  KebabMenu,
} from '@electricjs/arc';
import AssetImagesGridHeader from './AssetImagesGridHeader';
import { useEffect, useState } from 'react';
import AssetsImageUploadModal from '@/components/Assets/AssetsImageUploadModal';
import AssetImagesEmptyState from './AssetImagesEmptyState';
import { CenteredSpinner } from '@common';
import {
  useDeleteAssetImageMutation,
  useGetAssetImagesQuery,
} from '@/redux/slices/assetApiSlice';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import ImageViewModal from './ImageViewModal';
import styled from 'styled-components';
import { AssetFileWithURL } from '@/types/assets';
import { AssetFileUploadStatus } from '@electricjs/core_entity-client';
import { useGlobalUI } from '@/components/GlobalUIProvider';
import ImageDeleteModal from './ImageDeleteModal';

type AssetImagesGridProps = {
  assetId: string;
};

const StyledImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const ClickableImage = styled(Pane)`
  width: 20rem;
  height: 20rem;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 1.6rem;
  flex-direction: column;
  position: relative;

  &:hover {
    box-shadow: none;
    outline: 0.2rem solid ${getToken(ColorsV2.PRIMARY_LIGHT)};
  }
`;

const StyledKebabMenuWrapper = styled.div`
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  z-index: 2;
`;

const AssetImagesGrid = ({ assetId }: AssetImagesGridProps) => {
  const organizationId = useGetOrganizationId();
  const { showSuccessToast, showErrorToast } = useGlobalUI();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<AssetFileWithURL | null>(
    null
  );
  const [isRefetchNeeded, setIsRefetchNeeded] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { data: images = [], isFetching } = useGetAssetImagesQuery(
    {
      organizationId,
      assetId,
    },
    {
      pollingInterval: isRefetchNeeded ? 2000 : 0,
    }
  );

  const [deleteAssetImage] = useDeleteAssetImageMutation();

  useEffect(() => {
    if (
      images.some(
        image =>
          image.uploadStatus === AssetFileUploadStatus.Uploading &&
          image.url === ''
      )
    ) {
      setIsRefetchNeeded(true);
    } else {
      setIsRefetchNeeded(false);
    }
  }, [images]);

  const showImagesEmptyState = !isFetching && images.length === 0;
  const showSpinner = isFetching && images.length === 0;

  const handleDeleteClick = (image: AssetFileWithURL) => {
    setSelectedImage(image);
    setIsDeleteModalOpen(true);
  };

  const handleImageClick = (image: AssetFileWithURL) => {
    setSelectedImage(image);
    setIsImageModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (!selectedImage) return;

    await deleteAssetImage({
      organizationId,
      assetId,
      assetFileId: selectedImage.fileId,
    })
      .unwrap()
      .then(() => {
        showSuccessToast({
          id: 'asset-image-delete-success-toast',
          message: 'Asset image deleted successfully.',
          stack: true,
        });
      })
      .catch(error => {
        console.error('Unable to delete image.', error);
        showErrorToast({
          id: 'asset-image-delete-error-toast',
          message: 'Something went wrong. Please try again.',
          stack: true,
        });
      });

    setIsDeleteModalOpen(false);
  };

  const sortedImages = images.slice().sort((a, b) => {
    if (a.createdAt && b.createdAt) {
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    }
    return 0;
  });

  return (
    <TableContainer flexDirection="column" width="100%">
      <AssetImagesGridHeader
        assetImagesCount={images.length}
        onOpenModal={() => setIsModalOpen(true)}
      />
      <Flex hAlignContent="center" width="100%">
        {showSpinner ? (
          <CenteredSpinner />
        ) : (
          showImagesEmptyState && (
            <AssetImagesEmptyState handleClick={() => setIsModalOpen(true)} />
          )
        )}
      </Flex>
      <Flex wrap gap="1.6rem" margin="1.6rem" width="100%" alignItems="stretch">
        {sortedImages.map((image, index) => (
          <ClickableImage
            key={index}
            id={`asset-image-${index}`}
            onClick={() => handleImageClick(image)}
            elevated>
            <StyledKebabMenuWrapper onClick={e => e.stopPropagation()}>
              <KebabMenu
                id={`asset-image-${index}-kebab-menu`}
                items={[
                  {
                    label: 'Delete',
                    onClick: () => {
                      handleDeleteClick(image);
                    },
                  },
                ]}
              />
            </StyledKebabMenuWrapper>
            {image.url === '' ? (
              <Spinner />
            ) : (
              <StyledImage src={image.url} alt="Asset" loading="lazy" />
            )}
          </ClickableImage>
        ))}
      </Flex>
      {isModalOpen && (
        <AssetsImageUploadModal
          assetId={assetId}
          onCancel={() => setIsModalOpen(false)}
          onSuccess={() => setIsModalOpen(false)}
        />
      )}
      {isImageModalOpen && selectedImage && (
        <ImageViewModal
          image={selectedImage}
          onClose={() => setIsImageModalOpen(false)}
        />
      )}
      {isDeleteModalOpen && selectedImage && (
        <ImageDeleteModal
          image={selectedImage}
          onClose={() => setIsDeleteModalOpen(false)}
          onDelete={handleConfirmDelete}
        />
      )}
    </TableContainer>
  );
};

export default AssetImagesGrid;

import { sortBy } from 'lodash';
import {
  ModalAction,
  ModalBody,
  ModalFooter,
  ModalFooterActions,
  ModalHeader,
  ModalV2,
  Text,
} from '@electricjs/arc';

import { Employee } from '@/types/employees';

import EmployeeCheckboxList from './EmployeeCheckboxList';
import { CopyButton } from '@common';

type AppInviteModalProps<GenericEmployee extends Employee> = {
  visible: boolean;
  hide: () => void;
  employees: GenericEmployee[];
  showCheckList: boolean;
  selectedEmployees: GenericEmployee[];
  setSelectedEmployees: React.Dispatch<React.SetStateAction<GenericEmployee[]>>;
  isLoading: boolean;
  handleCancel: () => void;
  handleSubmit: () => void;
  instructionsToCopy: string;
  hasMdmEntitlement?: boolean;
  includeEmail?: boolean;
  includeDevice?: boolean;
  title?: string;
  description?: string;
};

const AppInviteModal = <GenericEmployee extends Employee>({
  visible,
  hide,
  employees,
  showCheckList,
  selectedEmployees,
  setSelectedEmployees,
  isLoading,
  handleCancel,
  handleSubmit,
  hasMdmEntitlement,
  instructionsToCopy,
  includeDevice = true,
  includeEmail = false,
  title,
  description,
}: AppInviteModalProps<GenericEmployee>) => {
  const descriptionIfQS =
    'Electric can email instructions to help your employees install the Electric application, or you can copy Electric app instructions and send them to employees.';
  const descriptionIfMdm =
    'Electric can email instructions to help your employees enroll their devices in Mobile Device Management (MDM), or you can copy MDM enrollment instructions and send them to employees.';
  return (
    <ModalV2
      visible={visible}
      hide={hide}
      maxWidth={includeDevice && includeEmail ? '55%' : '59rem'}
      ariaLabelledby="app-invite">
      <ModalHeader>
        <Text variant="heading-2">{title || 'Send enrollment reminder'}</Text>
      </ModalHeader>
      <ModalBody overflowY={showCheckList ? 'scroll' : undefined}>
        <Text maxWidth="49rem">
          {description ||
            (hasMdmEntitlement ? descriptionIfMdm : descriptionIfQS)}
        </Text>
        {showCheckList && (
          <EmployeeCheckboxList
            includeDevice={includeDevice}
            includeEmail={includeEmail}
            employees={sortBy(employees, e =>
              `${e.firstName} ${e.lastName}`.toLowerCase()
            )}
            selectedEmployees={selectedEmployees}
            setSelectedEmployees={setSelectedEmployees}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <ModalFooterActions>
          <ModalAction
            id="employee-profile-invite-modal-cancel"
            variant="text"
            disabled={isLoading}
            onClick={handleCancel}>
            Cancel
          </ModalAction>
          <CopyButton
            id="app-enrollment-instructions-button"
            textToCopy={instructionsToCopy}
            disabled={selectedEmployees.length === 0 || isLoading}>
            Copy instructions
          </CopyButton>
          <ModalAction
            id="employee-profile-invite-modal-submit"
            loading={isLoading}
            disabled={selectedEmployees.length === 0}
            onClick={handleSubmit}>
            Send email
          </ModalAction>
        </ModalFooterActions>
      </ModalFooter>
    </ModalV2>
  );
};

export default AppInviteModal;

import {
  ShippingMethod,
  OrderStatus,
  OrderLineItemStatus,
  ProvisioningType,
  ProductTypeName,
} from '@electricjs/core_entity-client';

import { Address } from '@electricjs/arc';

import { AssetCartEntry, CatalogCartEntry } from '@/types/storefront';

import { PaginationParams, SortingParams } from './common';

export enum OrderType {
  AssetOnly = 'assetOnly',
  ProductOnly = 'productOnly',
  MixedCart = 'mixedCart',
}

type BasePlaceOrderArgs = {
  orderType: OrderType;
  organizationId: string;
  /** The frontend idempotent key generated to this order */
  orderIdempotentKey: string;
  selectedEmployeeId: string;
};

type ShipToInfo = {
  shippingName: string;
  shippingPhone: string;
  shippingEmails: string;
  shippingAddress: Address;
  shippingNotes?: string;
};

type AssetOnlyPlaceOrderArgs = BasePlaceOrderArgs &
  Partial<ShipToInfo> & {
    orderType: OrderType.AssetOnly;
    selectedAssets: AssetCartEntry[];
  };

type ProductOnlyPlaceOrderArgs = BasePlaceOrderArgs &
  ShipToInfo & {
    orderType: OrderType.ProductOnly;
    selectedShippingMethod: ShippingMethod;
    /** The shipping cost returned by the backend in a previous step.
     *  It is passed here to avoid billing a different amount. */
    agreedShippingCost: number;
    selectedPaymentMethodId: string;
    selectedProducts: CatalogCartEntry[];
  };

type MixedCartPlaceOrderArgs = Omit<AssetOnlyPlaceOrderArgs, 'orderType'> &
  Omit<ProductOnlyPlaceOrderArgs, 'orderType'> & {
    orderType: OrderType.MixedCart;
  };

/** The order can be "assets only" or "products only" or "assets + products" */
export type PlaceOrderArgs =
  | AssetOnlyPlaceOrderArgs
  | ProductOnlyPlaceOrderArgs
  | MixedCartPlaceOrderArgs;

export type ShippingCostArgs = {
  organizationId: string;
  selectedShippingMethod: ShippingMethod;
  selectedProducts: CatalogCartEntry[];
};

export type GetOrdersArgs = {
  organizationId: string;
} & PaginationParams;

export enum InternalOrderOrderingByOptions {
  OrderId = 'friendly_id',
  OrderDate = 'created_at',
  OrganizationName = 'organization.name',
}

export type InternalOrderFilters = {
  searchTerm: string;
  /** The start date without "time" part. Format: yyyy-MM-dd */
  startDate?: string;
  /** The end date without "time" part. Format: yyyy-MM-dd */
  endDate?: string;
  organizationName: string;
};

export type GetInternalOrdersArgs = Partial<InternalOrderFilters> &
  PaginationParams &
  SortingParams;

export type ConfirmedOrder = {
  id: string;
  friendlyId: string;
  invoiceURL?: string;
};

export type OrderLine = {
  id: string;
  status: OrderLineItemStatus;
  chargedPrice: number;
  tax?: number;
  provisioning: ProvisioningType;
  productId: string;
  productName: string;
  productTypeName: ProductTypeName;
  serialNumber?: string;
  trackingNumber?: string;
  shippingMethod?: ShippingMethod;
  refundStatus?: string;
};

export type Order = {
  id: string;
  friendlyId: string;
  createdAt: string;
  lines: OrderLine[];
  invoiceTotal?: number;
  invoicePage?: string;
  invoiceURL?: string;
  status: OrderStatus;
  forEmployeeId: string;
  forEmployeeName: string;
  recipientName: string;
  recipientPhoneNumber: string;
  emailsForNotifications: string[];
  shippingAddress: Address;
  shippingNotes?: string;
  shippingMethod?: string;
  shippingMethodCost?: number;
};

export type InternalOrder = {
  id: string;
  friendlyId: string;
  createdAt: string;
  lines: OrderLine[];
  invoiceTotal?: number;
  invoicePage?: string;
  invoiceURL?: string;
  status: OrderStatus;
  forEmployeeName: string;
  recipientName: string;
  recipientPhoneNumber: string;
  emailsForNotifications: string[];
  shippingAddress: Address;
  shippingNotes?: string;
  organizationName: string;
  requesterName: string;
  paymentMethodLastFourDigits?: string;
  paymentMethodBrandName?: string;
  amountAvailableToRefund?: number;
  amountPreviouslyRefunded?: number;
  billingAddress?: Address;
  billingName?: string;
  totalTax?: number;
  invoiceSubtotal?: number;
  orderRefundStatus?: string;
};

/**
 * Downloads a file from a given URL.
 *
 * @param {function} baseQuery - A function that performs the query to retrieve the file.
 *                               It should return a promise that resolves to an object containing the file data as a blob.
 * @param {string} url - The URL of the file to be downloaded.
 * @param {string} [fileName] - An optional name for the downloaded file.
 *                              If not provided, the file will be opened in a new tab instead of being downloaded.
 */
export async function fetchAndDownloadFile(
  baseQuery: (arg: unknown) => unknown | Promise<unknown>,
  url: string,
  fileName?: string
) {
  const result = (await baseQuery({
    url: url,
    responseHandler: (response: { blob: () => unknown }) => response.blob(),
  })) as { data: Blob };
  const blob = result.data;

  // Create a temporary URL for the blob
  const fileUrl = URL.createObjectURL(blob);

  if (fileName) {
    // If a file name is provided, create a temporary anchor element to trigger the download with the file name
    const a = document.createElement('a');
    a.href = fileUrl;
    a.download = fileName ?? 'file';

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    // Revoke the temporary URL to free up resources
    URL.revokeObjectURL(url);
  } else {
    window.open(fileUrl);
  }

  // Return an empty data object to do not save data in the redux state
  return { data: undefined };
}

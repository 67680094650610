import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {
  ColorsV2,
  Flex,
  InitialsInCircle,
  Text,
  getToken,
} from '@electricjs/arc';

type EmployeeNameColumnProps = {
  firstName: string;
  lastName: string;
  email?: string | null;
  employeeId: string;
  hideAvatar?: boolean;
};

const StyledLink = styled(Link)`
  width: 100%;
  text-decoration: none;
  color: ${getToken(ColorsV2.PRIMARY)};

  &:hover {
    text-decoration: underline;
  }

  &:active,
  visited {
    color: ${getToken(ColorsV2.PRIMARY)};
  }
`;

const ClippedText = styled(Text)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const EmployeeNameColumn = ({
  firstName,
  lastName,
  email,
  employeeId,
  hideAvatar = false,
}: EmployeeNameColumnProps) => {
  const fullName = [firstName, lastName].filter(Boolean).join(' ');

  return (
    <Flex vAlignContent="center" overflow="hidden">
      {!hideAvatar && (
        <Flex mr="4" vertical>
          {<InitialsInCircle text={`${firstName} ${lastName}`} />}
        </Flex>
      )}
      <Flex vertical overflow="hidden">
        <StyledLink to={`/employees/${employeeId}`}>
          <ClippedText variant="body-2" fontWeight="bold" title={fullName}>
            {fullName}
          </ClippedText>
        </StyledLink>
        {!!email && (
          <Flex width="100%">
            <ClippedText variant="body-2" title={email}>
              {email}
            </ClippedText>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default EmployeeNameColumn;

import { createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';

import {
  Flex,
  Pagination,
  Pane,
  Table,
  TableContainer,
  Text,
} from '@electricjs/arc';

import TableHeader from '@/components/TableHeader';
import { AssetHistory } from '@/types/assets';

import AssetHistoryTableHeader from './AssetHistoryTableHeader';
import { useGetAssetHistoryQuery } from '@/redux/slices/assetApiSlice';
import AssetError from '@/components/Assets/AssetDetails/AssetError';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useParams } from 'react-router-dom';
import { useState } from 'react';

const PAGE_SIZE = 10;

const columnHelper = createColumnHelper<AssetHistory>();

const AssetHistoryTable = () => {
  const organizationId = useGetOrganizationId();
  const { assetId = '' } = useParams();
  const [currentPage, setCurrentPage] = useState(0);

  const {
    isFetching: isLoadingAssetHistory,
    isError: isAssetHistoryError,
    data: assetHistoryData,
  } = useGetAssetHistoryQuery(
    {
      organizationId,
      assetId,
      limit: PAGE_SIZE,
      offset: currentPage * PAGE_SIZE,
    },
    { skip: !assetId }
  );
  const totalAssetHistory = assetHistoryData?.total ?? 0;

  if (isAssetHistoryError) {
    return <AssetError />;
  }

  const tableColumns = [
    columnHelper.accessor('performedAt', {
      header: () => <TableHeader title="Date - Time" />,
      id: 'date-time',
      cell: data => {
        const date: Date = new Date(data.getValue());
        const formattedDate = format(date, 'MM/dd/yy - hh:mm a');
        return <Text variant="body-2">{formattedDate}</Text>;
      },
    }),
    columnHelper.accessor('description', {
      header: () => <TableHeader title="Action" />,
      id: 'action',
      cell: data => {
        return <Text variant="body-2">{data.getValue()}</Text>;
      },
    }),
    columnHelper.accessor('performedByUser', {
      header: () => <TableHeader title="Performed By" />,
      id: 'performedByUser',
      cell: info => <Text variant="body-2">{info.getValue()}</Text>,
    }),
  ];

  return (
    <TableContainer flexDirection="column" width="100%">
      <AssetHistoryTableHeader assetHistoryCount={totalAssetHistory} />
      <Flex width="100%">
        <Table
          columns={tableColumns}
          data={assetHistoryData?.assetHistory ?? []}
          loading={isLoadingAssetHistory}
        />
      </Flex>
      <Pane horizontal={'center'}>
        <Pagination
          id="asset-history-pagination"
          pageChangeCallback={(_, nextPage) => setCurrentPage(nextPage - 1)}
          totalPageCount={
            totalAssetHistory ? Math.ceil(totalAssetHistory / PAGE_SIZE) : 1
          }
        />
      </Pane>
    </TableContainer>
  );
};

export default AssetHistoryTable;

export enum Auth0UserKey {
  IS_USER = 'https://my.electric.ai/is_user',
  ORGANIZATIONS = 'https://my.electric.ai/organizations',
  ELECTRIC_PERMISSIONS = 'https://my.electric.ai/permissions',
  ADP_SSO_LOGIN = 'https://my.electric.ai/adp_sso_login',
}

export type Auth0User = {
  email: string;
  email_verified: boolean;
  name: string;
  nickname: string;
  picture: string;
  [Auth0UserKey.IS_USER]: boolean;
  [Auth0UserKey.ORGANIZATIONS]?: string[];
  [Auth0UserKey.ELECTRIC_PERMISSIONS]?: string[];
  [Auth0UserKey.ADP_SSO_LOGIN]?: boolean;
  [key: string]: unknown;
};

import { PopoverContainer } from '@electricjs/arc';

import { Notification } from '@/types/notifications';

import NotificationButton from './NotificationButton';
import NotificationDropdown from './NotificationDropdown';

export type NotificationsComponentProps = {
  notificationsCount: number;
  notifications: Notification[];
  onFetchNotifications: (limit: number, offset: number) => void;
  loadingNotifications: boolean;
  onMarkSeenNotifications?: (ids: string[]) => void;
  totalNotifications: number;
};

export const NotificationComponent = ({
  notifications,
  notificationsCount,
  onFetchNotifications,
  onMarkSeenNotifications,
  loadingNotifications,
  totalNotifications,
}: NotificationsComponentProps) => {
  return (
    <PopoverContainer placement="bottom-end">
      <NotificationButton notificationsCount={notificationsCount ?? 0} />
      <NotificationDropdown
        notifications={notifications ?? []}
        onMarkNotificationsAsSeen={onMarkSeenNotifications}
        loading={loadingNotifications}
        loadMore={onFetchNotifications}
        totalNotifications={totalNotifications}
      />
    </PopoverContainer>
  );
};

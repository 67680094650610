import { ReactNode, useState } from 'react';

import {
  Button,
  ColorsV2,
  Divider,
  Flex,
  Icon,
  ModalV2,
  Text,
  useBreakpoint,
  useThemeColors,
} from '@electricjs/arc';
import styled from 'styled-components';

const ButtonImg = styled(Button)`
  :hover {
    background-color: transparent;
  }
`;

type ImageProps = {
  scr: string;
  alt: string;
};

const Image = ({ scr, alt }: ImageProps) => {
  const { lg } = useBreakpoint();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [closeButtonColor] = useThemeColors([ColorsV2.WHITE]);

  return (
    <>
      <Flex maxWidth="32rem" p="2rem">
        <ButtonImg
          id="mdm-apple-open-modal-button-img"
          variant="text"
          onClick={() => setIsModalVisible(true)}
          p="0">
          <img src={scr} alt={alt} width="100%" />
        </ButtonImg>
      </Flex>
      <ModalV2
        visible={isModalVisible}
        hide={() => setIsModalVisible(false)}
        ariaLabelledby="add-application"
        alignItems="center"
        justifyContent="center"
        box-shadow="none"
        maxWidth={lg ? '55%' : '70%'}
        backgroundColor="transparent"
        boxShadow="none">
        <ButtonImg
          id="mdm-apple-close-modal-button-img"
          marginLeft="auto"
          p="1rem"
          borderRadius="100%"
          backgroundColor="transparent"
          intent="neutral"
          onClick={() => setIsModalVisible(false)}>
          <Icon icon="close" size="medium" color={closeButtonColor} />
        </ButtonImg>
        <Flex width="100%">
          <img src={scr} alt={alt} width="100%" />
        </Flex>
      </ModalV2>
    </>
  );
};

export const StepContainer = ({
  step,
  children,
  image,
}: {
  children: ReactNode;
  step?: number;
  image?: ImageProps;
}) => {
  return (
    <>
      <Flex width="100%">
        <Flex p="2rem" width="8rem" flexDirection="column" alignItems="end">
          {step && (
            <>
              <Text variant="label-small">STEP</Text>
              <Text variant="heading-2">{step}</Text>
            </>
          )}
        </Flex>
        <Divider vertical />
        <Flex width="100%" flexWrap="wrap" justifyContent="space-between">
          <Flex gap="1.2rem" p="2rem" flexDirection="column" maxWidth="65rem">
            {children}
          </Flex>
          {image && <Image {...image} />}
        </Flex>
      </Flex>

      <Divider />
    </>
  );
};
ModalV2;

import { useFlags } from 'launchdarkly-react-client-sdk';
import { Flex, Radio, Text, Tooltip } from '@electricjs/arc';
import { LockStatus } from '@electricjs/core_entity-client';
import { DeviceDataAction } from '../types';
import DeviceRadioTile from '../DeviceRadioTile';

type LockDeviceRadioOptionProps = {
  lockStatus: LockStatus | undefined;
  value: DeviceDataAction | undefined;
  disabled: boolean;
};

const LockDeviceRadioOption = ({
  lockStatus,
  value,
  disabled,
}: LockDeviceRadioOptionProps) => {
  const { itHubWarehousingWithStripe } = useFlags();
  const isLocked = lockStatus === LockStatus.Locked;
  const isLockRequested = lockStatus === LockStatus.Requested;
  const getDisabledLockMessage = () => {
    if (isLocked) {
      return 'This device is already locked.';
    }
    if (isLockRequested) {
      return 'This device has been scheduled to lock. Device will lock when connected to the internet.';
    }
    return '';
  };
  const disableLockOption = disabled || isLocked || isLockRequested;

  return itHubWarehousingWithStripe ? (
    <Tooltip
      placement="top-start"
      content={<Text>{getDisabledLockMessage}</Text>}
      disabled={disabled || (!isLocked && !isLockRequested)}>
      <Flex>
        <DeviceRadioTile
          value={DeviceDataAction.Lock}
          optionText="Lock the device"
          icon="lock"
          checked={value === DeviceDataAction.Lock}
          disabled={disableLockOption}
        />
      </Flex>
    </Tooltip>
  ) : (
    <Flex pb="1.6rem">
      <Tooltip
        placement="top-start"
        content={<Text>{getDisabledLockMessage}</Text>}
        disabled={disabled || (!isLocked && !isLockRequested)}>
        <Radio
          id="radio-option-lock"
          value={DeviceDataAction.Lock}
          disabled={disableLockOption}
          checked={value === DeviceDataAction.Lock}>
          <Text
            variant="subheading"
            intent={disableLockOption ? 'disabled' : 'primary'}>
            Lock the employee’s computer access, but leave the data alone
          </Text>
        </Radio>
      </Tooltip>
    </Flex>
  );
};

export default LockDeviceRadioOption;

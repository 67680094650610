import styled from 'styled-components';

import {
  Badge,
  IconButton,
  PopoverButton,
  Tooltip,
  useBreakpoint,
} from '@electricjs/arc';
import { useFlags } from 'launchdarkly-react-client-sdk';

const BadgeContainer = styled.div`
  position: relative;
`;

type NotificationButtonProps = {
  notificationsCount?: number;
};

const NotificationButton = ({
  notificationsCount = 0,
}: NotificationButtonProps) => {
  const { electrolyteNewTopNav } = useFlags();
  const { md } = useBreakpoint();

  if (electrolyteNewTopNav)
    return (
      <Tooltip content="Notifications" placement="bottom">
        <BadgeContainer>
          <PopoverButton icon="alarm-bell" as={IconButton} iconSize="medium" />
          <Badge
            size="1rem"
            position="absolute"
            top="0px"
            left="2.2rem"
            count={notificationsCount}
            hideCount={true}
          />
        </BadgeContainer>
      </Tooltip>
    );

  return (
    <BadgeContainer>
      {md ? (
        <PopoverButton variant="text" iconBefore="alarm-bell">
          Notifications
        </PopoverButton>
      ) : (
        <PopoverButton icon="alarm-bell" ml={'0.7rem'} as={IconButton} />
      )}
      <Badge
        size="1.8rem"
        variant="legal-bold"
        position="absolute"
        top="0px"
        left="2.2rem"
        count={notificationsCount}
      />
    </BadgeContainer>
  );
};

export default NotificationButton;

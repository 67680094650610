import { useAppSelector } from '@/redux/store';
import {
  IntegrationAvailabilityEnum,
  InternalType,
} from '@electricjs/core_entity-client';

/**
 * Custom hook to determine the available integration types based on the organization's type.
 *
 * This hook retrieves the organization type of the logged-in user and uses it to decide
 * which integration availabilities should be returned. It supports different integration
 * types depending on whether the organization is of type `Full` or another type (e.g., TEST or DEMO).
 *
 * - If the organization type is `Full`, only integrations with `IntegrationAvailabilityEnum.All`
 *   are considered available.
 * - For other organization types (e.g., TEST or DEMO), both `IntegrationAvailabilityEnum.All`
 *   and `IntegrationAvailabilityEnum.Internal` are included.
 *
 * @returns {IntegrationAvailabilityEnum[]} An array of available integration types.
 */
const useIntegrationAvailabilities: () => IntegrationAvailabilityEnum[] =
  () => {
    const organizationType = useAppSelector(
      state => state.loggedUser?.organizationInternalType
    );

    // Determine the integration availabilities based on the organization type
    // Applications with internal availability should be considered as “not supported”
    // if the organization is not TEST or DEMO.
    const integrationAvailabilities =
      organizationType === InternalType.Full
        ? [IntegrationAvailabilityEnum.All, IntegrationAvailabilityEnum.Beta]
        : [
            IntegrationAvailabilityEnum.All,
            IntegrationAvailabilityEnum.Internal,
            IntegrationAvailabilityEnum.Beta,
          ];

    return integrationAvailabilities;
  };

export default useIntegrationAvailabilities;

import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isFetchBaseQueryError(
  error: unknown
): error is FetchBaseQueryError {
  return (
    typeof error === 'object' &&
    error !== null &&
    error !== undefined &&
    'status' in error
  );
}

export function getBaseQueryErrorMessage(error: unknown): string {
  let errorMessage = 'Something went wrong. Please try again.';

  if (isFetchBaseQueryError(error)) {
    if ('error' in error) {
      errorMessage = error.error;
    } else if ('data' in error) {
      const errorData = error.data;

      if (typeof errorData === 'string') {
        errorMessage = errorData;
      } else if (typeof errorData === 'object') {
        if (
          errorData &&
          'detail' in errorData &&
          typeof errorData.detail === 'string'
        ) {
          errorMessage = errorData.detail;
        }
      }
    }
  }
  return errorMessage;
}

import { useFlags } from 'launchdarkly-react-client-sdk';
import { useParams, useSearchParams } from 'react-router-dom';
import OptionalMDMBanner from '@/pages/Assets/AssetDetailsContent/OptionalMDMBanner';

import {
  Flex,
  Icon,
  Pane,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  TabsContainer,
  Text,
  useBreakpoint,
} from '@electricjs/arc';

import AssetError from '@/components/Assets/AssetDetails/AssetError';
import DeviceSecurityPanel from '@/components/Assets/AssetDetails/DeviceSecurityPanel/DeviceSecurityPanel';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useGetAssetQuery } from '@/redux/slices/assetApiSlice';

import CenteredSpinner from 'common/CenteredSpinner';
import AssetHistoryTable from '../AssetHistoryTable';
import AssetImagesGrid from '../AssetImagesGrid';
import AssetDetailsPanel from './AssetDetailsPanel';
import { isDeviceAsset } from '@/types/assets';
import { useGetEmployeeQuery } from '@/redux/slices/employeeApiSlice';

enum AssetDetailsTabs {
  Details = 'details',
  History = 'history',
  Images = 'images',
  Security = 'security',
}

const AssetDetailsContent = () => {
  const organizationId = useGetOrganizationId();
  const { lg } = useBreakpoint();

  const { assetId = '' } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTab = searchParams.get('tab') ?? AssetDetailsTabs.Details;
  const { electrolyteAssetFiles } = useFlags();

  const {
    isFetching: isFetchingAsset,
    isError: isAssetError,
    data: assetData,
  } = useGetAssetQuery(
    {
      organizationId,
      assetId,
    },
    { skip: !assetId }
  );

  const { isFetching: isFetchingEmployee, data: employee } =
    useGetEmployeeQuery(
      {
        employeeId: assetData?.assignedTo || '',
        organizationId: organizationId,
      },
      {
        skip: !assetData?.assignedTo,
      }
    );

  const deviceData = assetData?.device?.reportingData;

  if (isFetchingAsset) {
    return <CenteredSpinner />;
  }

  const hasDeviceInfo = assetData && isDeviceAsset(assetData);
  const showSecurityTab = hasDeviceInfo && !!assetData.assignedTo;
  const hasIssues =
    deviceData?.issues && !!Object.keys(deviceData.issues).length;

  return !assetData || isAssetError ? (
    <AssetError />
  ) : (
    <Flex
      gap="2rem"
      flexDirection={lg ? 'row' : 'column'}
      justifyContent="space-evenly">
      <Flex vertical width="100%">
        <OptionalMDMBanner assetData={assetData} employee={employee} />
        <TabsContainer selectedId={selectedTab}>
          <Spacer y={4} />
          <TabList>
            <Tab
              id={AssetDetailsTabs.Details}
              onClick={() =>
                setSearchParams(
                  { tab: AssetDetailsTabs.Details },
                  { replace: true }
                )
              }>
              Details
            </Tab>
            {showSecurityTab && (
              <Tab
                id={AssetDetailsTabs.Security}
                onClick={() =>
                  setSearchParams(
                    { tab: AssetDetailsTabs.Security },
                    { replace: true }
                  )
                }>
                <Flex alignItems="center">
                  <Text>Security</Text>
                  {hasIssues && (
                    <>
                      <Spacer x={2} />
                      <Icon
                        icon="alert-triangle"
                        intent="warning"
                        customSize="1.6rem"
                      />
                    </>
                  )}
                </Flex>
              </Tab>
            )}
            <Tab
              id={AssetDetailsTabs.History}
              onClick={() =>
                setSearchParams(
                  { tab: AssetDetailsTabs.History },
                  { replace: true }
                )
              }>
              History
            </Tab>
            {electrolyteAssetFiles && (
              <Tab
                id={AssetDetailsTabs.Images}
                onClick={() =>
                  setSearchParams(
                    { tab: AssetDetailsTabs.Images },
                    { replace: true }
                  )
                }>
                Images
              </Tab>
            )}
          </TabList>
          <TabPanels width="100%">
            <AssetDetailsPanel asset={assetData} />
            {showSecurityTab && (
              <DeviceSecurityPanel
                device={assetData.device}
                employee={employee}
                isLoading={isFetchingEmployee}
              />
            )}
            <TabPanel>
              <Spacer y="2rem" />
              <Pane flexDirection="column" width="100%">
                <AssetHistoryTable />
              </Pane>
            </TabPanel>
            {electrolyteAssetFiles && (
              <TabPanel>
                <Spacer y="2rem" />
                <Pane flexDirection="column" width="100%">
                  <AssetImagesGrid assetId={assetId} />
                </Pane>
              </TabPanel>
            )}
          </TabPanels>
        </TabsContainer>
      </Flex>
    </Flex>
  );
};

export default AssetDetailsContent;

import { Button, Flex, Header } from '@electricjs/arc';
import appIcon from '@/assets/app.svg';
import laptopIcon from '@/assets/laptop.svg';
import CheckBoxTile from './CheckBoxTile';
import { FormActionsContainer } from './common';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { OnboardingFormData, OnboardingStep } from './types';
import { useNavigate } from 'react-router-dom';

import { RequestTypeCategoryNameEnum } from '@/hooks/useGetRequestTypeCategoryChampionEmployee';
import useIsRequestTypeCategoryChampion from './hooks/useIsRequestTypeCategoryChampion';

import { getFullName } from 'common/utils/getFullName';
import { useBulkUpdateEmployeeStatusMutation } from '@/redux/slices/employeeApiSlice';
import { EmployeeStatus } from '@/types/employees';
import { SelectionParamKeys } from '@/types/queryParamKeys';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useUpdateRequestStatus } from '@/hooks/useUpdateRequestStatus';
import {
  EmployeeStatusEnum,
  RequestStatusEnum,
} from '@electricjs/core_entity-client';

type OnboardingProps = {
  addEmployee: (
    onSuccess: (employeeId: string) => void,
    status?: EmployeeStatusEnum
  ) => void;
  submitOnboarding: (
    employeeId: string,
    onSuccess: () => void,
    needsHardware?: boolean
  ) => void;
  existingEmployeeId?: string;
  hrisOnboardingRequestId?: string;
};

const Onboarding = ({
  addEmployee,
  submitOnboarding,
  existingEmployeeId,
  hrisOnboardingRequestId = '',
}: OnboardingProps) => {
  const organizationId = useGetOrganizationId();
  const navigate = useNavigate();
  const { control, getValues, setValue } = useFormContext<OnboardingFormData>();
  const { isValid, isSubmitting } = useFormState();
  const [needsApplications, needsHardware, firstName, lastName] = useWatch({
    control,
    name: [
      'needsApplications',
      'needsHardware',
      'demographics.firstName',
      'demographics.lastName',
    ],
  });
  const isCurrentUserHardwareChampion = useIsRequestTypeCategoryChampion(
    RequestTypeCategoryNameEnum.DEVICE
  );

  const [bulkUpdateEmployeeStatus, { isLoading: isLoadingEmployee }] =
    useBulkUpdateEmployeeStatusMutation();
  const { updateRequest, updateRequestLoading } = useUpdateRequestStatus({
    requestId: hrisOnboardingRequestId,
  });

  const fullName = getFullName(firstName, lastName);
  const needsOnboarding = needsApplications || needsHardware;
  const nextButtonLabel = needsOnboarding ? 'Next' : 'Set employee as active';

  const handlePrevious = () => {
    const currentStep = getValues('currentStep');
    setValue('currentStep', currentStep - 1);
  };

  const handleSetInactive = async () => {
    // If the employee doesn't already exist, add them as INACTIVE
    if (!existingEmployeeId) {
      addEmployee((employeeId: string) => {
        if (employeeId) {
          navigate(`/employees/${employeeId}`);
        }
      }, EmployeeStatusEnum.Inactive);
    } else {
      // This is an existing employee, probably ingested from an HRIS
      // Set existing employee inactive
      await bulkUpdateEmployeeStatus({
        organizationId,
        employees: [
          { id: existingEmployeeId, status: EmployeeStatus.Inactive },
        ],
      });

      // If it exists, mark HRIS onboarding request as completed
      if (hrisOnboardingRequestId) {
        await updateRequest({
          newStatus: RequestStatusEnum.Completed,
        });
      }

      // Navigate to employee profile
      navigate(`/employees/${existingEmployeeId}`);
    }
  };

  // If the employee does not need onboarding, navigate to the employee's profile page.
  // If the employee needs applications, navigate to the next step, where the onboarding will be created after adding the employee to the groups.
  // If the employee needs only hardware, create the onboarding and navigate to the hardware procurement page.
  const handleNextNavigation = async (employeeId: string) => {
    const employeeProfileUrl = `/employees/${employeeId}`;
    const hardwareStoreUrl = `/purchase-hardware?${SelectionParamKeys.EmployeeId}=${employeeId}`;
    const newUrl = isCurrentUserHardwareChampion
      ? hardwareStoreUrl
      : employeeProfileUrl;

    if (!employeeId) {
      return;
    }
    if (!needsOnboarding) {
      // If the HRIS-ingested employee doesn't need hardware or apps, set them as active
      if (existingEmployeeId) {
        await bulkUpdateEmployeeStatus({
          organizationId,
          employees: [
            { id: existingEmployeeId, status: EmployeeStatus.Active },
          ],
        });
      }

      // If it exists, mark HRIS onboarding request as completed
      if (hrisOnboardingRequestId) {
        await updateRequest({
          newStatus: RequestStatusEnum.Completed,
        });
      }

      navigate(employeeProfileUrl);
      return;
    }
    if (needsApplications) {
      setValue('currentStep', OnboardingStep.Applications);
    } else {
      submitOnboarding(employeeId, () => navigate(newUrl), true);
    }
  };

  const handleNext = () => {
    if (!existingEmployeeId) {
      // If the employee doesn't already exist, add them
      addEmployee((employeeId: string) => {
        handleNextNavigation(employeeId);
      });
    } else {
      handleNextNavigation(existingEmployeeId);
    }
  };

  return (
    <>
      <Header
        title={`Does ${fullName} require onboarding?`}
        subtitle="Choose all that apply"
      />
      <Flex mt="6rem" gap="3.5rem" flexWrap="wrap">
        <CheckBoxTile
          text="Applications"
          icon={appIcon}
          name="needsApplications"
        />
        <CheckBoxTile text="Hardware" icon={laptopIcon} name="needsHardware" />
      </Flex>
      <FormActionsContainer>
        <Button
          id="onboarding-previous-button"
          variant="outline"
          onClick={handlePrevious}
          disabled={isSubmitting || !!existingEmployeeId}>
          Previous
        </Button>
        <Flex gap="2rem">
          {!needsOnboarding && (
            <Button
              id="onboarding-set-as-inactive-button"
              variant="text"
              onClick={handleSetInactive}
              disabled={
                isSubmitting || updateRequestLoading || isLoadingEmployee
              }>
              Set as inactive
            </Button>
          )}
          <Button
            id="onboarding-next-button"
            onClick={handleNext}
            loading={isSubmitting || updateRequestLoading || isLoadingEmployee}
            disabled={!isValid}>
            {nextButtonLabel}
          </Button>
        </Flex>
      </FormActionsContainer>
    </>
  );
};

export default Onboarding;

import {
  ColorsV2,
  ModalAction,
  ModalBody,
  ModalFooter,
  ModalFooterActions,
  ModalHeader,
  ModalV2,
  Spacer,
  Text,
} from '@electricjs/arc';

import { useGlobalUI } from '@/components/GlobalUIProvider';
import { useLockDeviceMutation } from '@/redux/slices/mdmManagementApiSlice';
import { DeviceAsset } from '@/types/assets';

type LockDeviceModalProps = {
  organizationId: string;
  deviceAsset: DeviceAsset;
  employeeName: string;
  onClose: () => void;
  setDisableLock: () => void;
};

const LockDeviceModal = ({
  organizationId,
  deviceAsset,
  employeeName,
  onClose,
  setDisableLock,
}: LockDeviceModalProps) => {
  const { showSuccessToast, showErrorToast } = useGlobalUI();
  const [lockDevice, { isLoading }] = useLockDeviceMutation();

  const deviceId = deviceAsset.device?.id;
  const serialNumber = deviceAsset.serialNumber;

  const handleSubmit = async () => {
    try {
      await lockDevice({
        organizationId,
        deviceId,
        serialNumber,
      }).unwrap();
      showSuccessToast({
        id: 'lock-device-success-toast',
        message: 'Lock command sent to device.',
      });
      onClose();
      setDisableLock();
    } catch (error) {
      console.error('Error sending lock command: ', error);
      showErrorToast({
        id: 'lock-device-error-toast',
        message: 'There was an error sending lock command. Please try again.',
      });
    }
  };

  return (
    <ModalV2 visible hide={onClose} ariaLabelledby="lock-device">
      <ModalHeader>
        <Text variant="heading-2">Lock {employeeName}&apos;s device</Text>
      </ModalHeader>
      <ModalBody>
        <Text>Please confirm if you&apos;d like to lock this device.</Text>
        <Spacer />
        <Text>
          Click lock device to confirm you want to lock this device. You can
          unlock a locked device by clicking the &quot;unlock device&quot;
          button.
        </Text>
      </ModalBody>
      <ModalFooter>
        <ModalFooterActions>
          <ModalAction id="lock-device-cancel" onClick={onClose} variant="text">
            Cancel
          </ModalAction>
          <ModalAction
            backgroundColor={ColorsV2.ERROR}
            id="lock-device-submit"
            onClick={handleSubmit}
            loading={isLoading}>
            Lock device
          </ModalAction>
        </ModalFooterActions>
      </ModalFooter>
    </ModalV2>
  );
};

export default LockDeviceModal;

import { useState } from 'react';
import styled from 'styled-components';

import {
  Field,
  FieldLabel,
  ModalAction,
  ModalBody,
  ModalFooter,
  ModalFooterActions,
  ModalHeader,
  ModalV2,
  Text,
  Textarea,
} from '@electricjs/arc';

import { useGlobalUI } from '@/components/GlobalUIProvider';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useSendISVRequestMutation } from '@/redux/slices/requestApiSlice';

const MessageInput = styled(Textarea)`
  height: 20rem !important;
`;

type SendAddonEmailModalProps = {
  companyName: string;
  vendorName: string;
  vendorEmail: string;
  userEmail: string;
  closeModalAction: () => void;
};

const SendAddonEmailModal = ({
  companyName,
  vendorName,
  vendorEmail,
  userEmail,
  closeModalAction,
}: SendAddonEmailModalProps) => {
  const [message, setMessage] = useState('');

  const { showToast } = useGlobalUI();

  const [sendISVRequestEmail, { isLoading }] = useSendISVRequestMutation();

  const organizationId = useGetOrganizationId();

  const handleSendAddonRequestAction = () => {
    const maybeEmployee = userEmail
      ? `This is a request from ${userEmail}`
      : `This is a request regarding ${vendorName}`;
    const connector = userEmail ? 'of' : 'by';
    const maybeCompany = companyName ? ` ${connector} ${companyName}` : '';
    const heading = `${maybeEmployee}${maybeCompany}`;

    sendISVRequestEmail({
      organizationId,
      heading,
      message,
      vendorEmail,
      vendorName,
    })
      .then(() => {
        showToast({
          id: 'send-isv-request-success-toast',
          intent: 'success',
          title: 'Your message has been sent successfully.',
          message: `You have requested support for ${vendorName}.`,
        });
        closeModalAction();
      })
      .catch(() => {
        showToast({
          id: 'send-isv-request-error-toast',
          intent: 'error',
          title: 'Error',
          message:
            'Sorry, we could not submit your request at this time. Please, try again.',
        });
      });
  };

  return (
    <ModalV2 ariaLabelledby="ISV Request" visible hide={closeModalAction}>
      <ModalHeader>
        <Text
          variant="heading-2"
          id="ISV Request">{`Get help with ${vendorName}`}</Text>
      </ModalHeader>
      <ModalBody minHeight="17rem">
        <Text>
          Clicking submit will create a ticket for our product support team who
          will reach out to assist you.
        </Text>
        <Field margin="2rem 0" width="100%">
          <FieldLabel htmlFor="message">Request message</FieldLabel>
          <MessageInput
            id="message"
            placeholder="Enter your message here"
            onChange={e => {
              setMessage(e.target?.value);
            }}
            resize={false}
          />
        </Field>
      </ModalBody>
      <ModalFooter>
        <ModalFooterActions>
          <ModalAction
            id="send-addon-email-cancel-button"
            variant="outline"
            onClick={closeModalAction}>
            Cancel
          </ModalAction>
          <ModalAction
            id="send-addon-email-submit-button"
            onClick={handleSendAddonRequestAction}
            disabled={!message}
            loading={isLoading}>
            Submit
          </ModalAction>
        </ModalFooterActions>
      </ModalFooter>
    </ModalV2>
  );
};

export default SendAddonEmailModal;

import { useState, useCallback, useEffect } from 'react';

import {
  AssetStatus,
  ProductTypeName,
  SortOrder,
} from '@electricjs/core_entity-client';
import { Flex } from '@electricjs/arc';

import { useGlobalUI } from '@/components/GlobalUIProvider';
import { STOREFRONT_PAGE_SIZE } from '@/constants/pagination';
import { SortingParams } from '@/types/common';
import { type Asset } from '@/types/assets';
import {
  useLazyGetAssetQuery,
  useLazyListAssetsV2Query,
} from '@/redux/slices/assetApiSlice';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';

import { useStorefront } from '../../StorefrontProvider';

import {
  type AssetCartEntry,
  Store,
  productTypesByTypeGroup,
  StoreFiltersSelections,
} from '@/types/storefront';
import { SelectionParamKeys } from '@/types/queryParamKeys';

import {
  ProductTypeGroupSelector,
  ProductTypeGroupSelectorProps,
  ItemList,
} from '../';
import { useItemDetailsModal } from '../useItemDetailsModal';

export type AssetStoreProps = Omit<
  ProductTypeGroupSelectorProps,
  'onProductTypesSelectFromGroup'
>;

export const AssetStore = ({
  selectedProductTypeGroup,
  onProductTypeGroupSelect,
  laptopRecommendationUrl,
}: AssetStoreProps) => {
  const { showSuccessToast } = useGlobalUI();

  const { assetCart, setAssetCart } = useStorefront();

  const organizationId = useGetOrganizationId();

  const [selectedPage, setSelectedPage] = useState<number>(0);

  const [selectedProductTypes, setSelectedProductTypes] = useState<
    ProductTypeName[]
  >(productTypesByTypeGroup[selectedProductTypeGroup]); // initializing with the types from the selected group of types

  const [selectedSorting, setSelectedSorting] = useState<SortingParams>({
    orderBy: 'name',
    sortOrder: SortOrder.Asc,
  });

  const [
    getPaginatedAssets,
    {
      data: paginatedAssets,
      isFetching: isAssetListLoading,
      isError: hasAssetListError,
    },
  ] = useLazyListAssetsV2Query();

  const assets = paginatedAssets?.results ?? [];

  const applyFiltersSelections = useCallback(
    ({
      selectedSorting,
      selectedPage,
      selectedProductTypes,
    }: StoreFiltersSelections) => {
      // fetch the assets based on the selections
      getPaginatedAssets(
        {
          organizationId,
          orderBy: selectedSorting.orderBy ?? 'name',
          sortOrder: selectedSorting.sortOrder ?? SortOrder.Asc,
          offset: selectedPage * STOREFRONT_PAGE_SIZE,
          limit: STOREFRONT_PAGE_SIZE,
          statuses: [AssetStatus.Available],
          productTypes: selectedProductTypes,
        },
        true
      )
        .unwrap()
        .catch(error => console.error('Error while fetching assets', error));
    },
    [getPaginatedAssets, organizationId]
  );

  const handleTypesSelectionFromGroup = useCallback(
    (types: ProductTypeName[]) => {
      setSelectedProductTypes([...types]);

      setSelectedSorting({
        orderBy: 'name',
        sortOrder: SortOrder.Asc,
      });

      setSelectedPage(0);
    },
    []
  );

  const handlePageChange = (newPage: number) => {
    // only update the page (keep all filters and sorting)
    setSelectedPage(newPage);
  };

  const handleAddEntry = useCallback(
    (item: Asset) => {
      // add the entry to the end of the cart
      const cart: AssetCartEntry[] = [
        ...assetCart,
        {
          item: { ...item },
        },
      ];

      setAssetCart(cart);

      showSuccessToast({
        id: 'added-asset-success-toast',
        title: 'Item added',
        message: `${item.name} has been added to your cart`,
        testId: 'added-asset-product-toast',
      });
    },
    [assetCart, setAssetCart, showSuccessToast]
  );

  const handleRemoveEntry = useCallback(
    (item: Asset) => {
      // remove the entry from the cart, independent of its amount
      const cart: AssetCartEntry[] = assetCart.filter(
        entry => entry.item.id !== item.id
      );

      setAssetCart(cart);

      showSuccessToast({
        id: 'removed-asset-success-toast',
        title: 'Item removed',
        message: `${item.name} has been removed from your cart`,
        testId: 'removed-asset-product-toast',
      });
    },
    [assetCart, setAssetCart, showSuccessToast]
  );

  // Applying filters/sorting/pagination selections
  useEffect(() => {
    applyFiltersSelections({
      selectedProductTypes,
      selectedSorting,
      selectedPage,
    });
  }, [
    applyFiltersSelections,
    selectedProductTypes,
    selectedSorting,
    selectedPage,
  ]);

  const [
    getAsset,
    { isFetching: isLoadingAssetDetails, isError: hasAssetDetailsError },
  ] = useLazyGetAssetQuery();

  const getAssetDetails = useCallback(
    async (assetId: string) => {
      const asset = await getAsset({ organizationId, assetId }, true).unwrap();
      return asset;
    },
    [getAsset, organizationId]
  );

  useItemDetailsModal({
    items: assets,
    queryParam: SelectionParamKeys.AssetId,
    store: Store.Asset,
    loadingItemsList: isAssetListLoading,
    loadingItemDetails: isLoadingAssetDetails,
    onAddItem: handleAddEntry,
    onRemoveItem: handleRemoveEntry,
    hasItemDetailsError: hasAssetDetailsError,
    itemDetailsErrorMessage:
      'Could not get asset details. Please try again later',
    onItemDetailsAsyncRequest: getAssetDetails,
  });

  return (
    <Flex vertical rowGap="2.4rem" data-testid="asset-store">
      <ProductTypeGroupSelector
        selectedProductTypeGroup={selectedProductTypeGroup}
        onProductTypeGroupSelect={onProductTypeGroupSelect}
        onProductTypesSelectFromGroup={handleTypesSelectionFromGroup}
        laptopRecommendationUrl={laptopRecommendationUrl}
      />
      <ItemList
        store={Store.Asset}
        items={assets}
        onAddItem={handleAddEntry}
        onRemoveItem={handleRemoveEntry}
        totalItemsResult={paginatedAssets?.total ?? 0}
        selectedPage={selectedPage}
        onPageChange={handlePageChange}
        isLoading={isAssetListLoading}
        hasError={hasAssetListError}
        errorMessage="Could not get the list of assets. Please try again later."
        selectedItemsIds={assetCart.map(entry => entry.item.id)}
      />
    </Flex>
  );
};

import { useGlobalUI } from '@/components/GlobalUIProvider';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import {
  useGetAssetQuery,
  useDeleteAssetMutation,
} from '@/redux/slices/assetApiSlice';
import { useNavigate } from 'react-router-dom';
import { MenuItem, Text } from '@electricjs/arc';
import AssetDeleteModal from './AssetDeleteModal';

type DeleteAssetMenuItemProps = {
  assetId: string;
  setIsloading: (_: boolean) => void;
};

const DeleteAssetMenuItem = ({
  assetId,
  setIsloading,
}: DeleteAssetMenuItemProps) => {
  const { showSuccessToast, showErrorToast, openModal, closeModal } =
    useGlobalUI();
  const navigate = useNavigate();
  const organizationId = useGetOrganizationId();
  const { isFetching } = useGetAssetQuery(
    {
      organizationId,
      assetId,
    },
    {
      skip: !assetId,
    }
  );
  const [deleteAsset, { isLoading: isDeleting }] = useDeleteAssetMutation();

  const disableAction = isDeleting || isFetching;

  const handleClick = () => {
    openModal(
      <AssetDeleteModal
        onClose={closeModal}
        onDelete={() => {
          setIsloading(true);

          deleteAsset({
            assetId,
            organizationId,
          })
            .unwrap()
            .then(() => {
              showSuccessToast({
                id: 'delete-asset-success-toast',
                message: 'Asset deleted successfully.',
              });
              closeModal();
            })
            .catch(() => {
              showErrorToast({
                id: 'delete-asset-error-toast',
                message: 'Failed to delete the asset. Please try again.',
              });
            })
            .finally(() => {
              setIsloading(false);
              navigate(-1);
            });
        }}
      />
    );
  };

  return (
    <MenuItem
      id="delete-asset-menu-item"
      onClick={handleClick}
      disabled={disableAction}>
      <Text>Delete Asset</Text>
    </MenuItem>
  );
};

export default DeleteAssetMenuItem;

import { ReactNode } from 'react';
import { DescriptionColumn } from '@/components/DeviceOverview/DeviceHealthMonitor/ColumnsAndContainers/DescriptionColumn';
import { DeviceIssueType } from '@/types/devices';

type IssueTypeDescriptionColumnMapType = {
  [key in DeviceIssueType]?: (
    serialNumber?: string,
    showIcon?: boolean
  ) => ReactNode;
};

export const IssueTypeDescriptionColumnMap: IssueTypeDescriptionColumnMapType =
  {
    [DeviceIssueType.Firewall]: (serialNumber?: string, showIcon?: boolean) => (
      <DescriptionColumn
        iconType="COMPLETED"
        title="Firewall enabled"
        serialNumber={serialNumber}
        detail="Electric enabled the firewall on this device."
        showIcon={showIcon}
      />
    ),
    [DeviceIssueType.Gatekeeper]: (
      serialNumber?: string,
      showIcon?: boolean
    ) => (
      <DescriptionColumn
        iconType="COMPLETED"
        title="Gatekeeper enabled"
        serialNumber={serialNumber}
        detail="Electric enabled Gatekeeper on this device."
        showIcon={showIcon}
      />
    ),
    [DeviceIssueType.OS]: (serialNumber?: string, showIcon?: boolean) => (
      <DescriptionColumn
        iconType="COMPLETED"
        title="OS update issue resolved"
        serialNumber={serialNumber}
        detail="This employee has upgraded to the newest version."
        showIcon={showIcon}
      />
    ),
    [DeviceIssueType.Encryption]: (
      serialNumber?: string,
      showIcon?: boolean
    ) => (
      <DescriptionColumn
        iconType="COMPLETED"
        title="Encryption enabled"
        serialNumber={serialNumber}
        detail="Electric enabled encryption on this device."
        showIcon={showIcon}
      />
    ),
    [DeviceIssueType.Storage]: (serialNumber?: string, showIcon?: boolean) => (
      <DescriptionColumn
        iconType="COMPLETED"
        title="Disk space issue resolved"
        serialNumber={serialNumber}
        detail="This employee has freed up their disk space."
        showIcon={showIcon}
      />
    ),
    [DeviceIssueType.RAM]: (serialNumber?: string, showIcon?: boolean) => (
      <DescriptionColumn
        iconType="COMPLETED"
        title="Low RAM issue resolved"
        serialNumber={serialNumber}
        detail="The employee has resolved the RAM issue."
        showIcon={showIcon}
      />
    ),
    /* TODO HY-218 We're temporarily disabling Battery health while we improve the logic behind it */
    // [DeviceIssueType.BatteryHealth]: serialNumber => (
    //   <DescriptionColumn
    //     iconType="COMPLETED"
    //     title="Battery health issue resolved"
    //     serialNumber={serialNumber}
    //     detail="The employee has successfully replaced the battery."
    //   />
    // ),
    [DeviceIssueType.DeviceLock]: (
      serialNumber?: string,
      showIcon?: boolean
    ) => (
      <DescriptionColumn
        iconType="COMPLETED"
        title="Lost device is unlocked"
        serialNumber={serialNumber}
        detail="The employee has successfully unlocked the device."
        showIcon={showIcon}
      />
    ),
    [DeviceIssueType.OsqueryNotReporting]: (
      serialNumber?: string,
      showIcon?: boolean
    ) => (
      <DescriptionColumn
        iconType="COMPLETED"
        title="Electric App connection issue is resolved"
        serialNumber={serialNumber}
        detail="The employee has rebooted their device and reconnected to Electric App."
        showIcon={showIcon}
      />
    ),
    [DeviceIssueType.MdmNotReporting]: (
      serialNumber?: string,
      showIcon?: boolean
    ) => (
      <DescriptionColumn
        iconType="COMPLETED"
        title="Lost MDM connection issue is resolved"
        serialNumber={serialNumber}
        detail="This employee has reconnected to the MDM."
        showIcon={showIcon}
      />
    ),
  } as const;

export type IssueTypeColumnMapType = keyof typeof IssueTypeDescriptionColumnMap;

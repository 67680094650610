import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ifProp } from 'styled-tools';

import {
  Box,
  ColorsV2,
  Flex,
  Card,
  Skeleton,
  Text,
  useThemeColors,
  IconInCircle,
  Button,
  Divider,
  Icon,
  KebabMenuOption,
} from '@electricjs/arc';

import eihData from '@/assets/eih_data.svg';
import DonutGraph, { Dot } from '@/components/DonutGraph';
import { useGetEmployeesWithAssets } from '@/hooks/useGetEmployeesWithAssets';
import { Employee } from '@/types/employees';
import StyledRouterLink from '../StyledRouterLink';
import AppInviteModalWrapper from './AppInviteModal/AppInviteModalWrapper';

const StyledDivider = styled(Divider)`
  height: calc(100% + 0.8rem);
  margin-top: -0.8rem;
`;

const StyledCard = styled(Card)`
  padding-bottom: ${ifProp('$allAssigned', '0', '1rem')};
`;

type CardFooterProps = {
  employeesWithoutDevices: Employee[];
};
const CardFooter = ({ employeesWithoutDevices }: CardFooterProps) => {
  const [showInviteModal, setShowInviteModal] = useState(false);
  return (
    <Flex justifyContent="flex-end" alignItems="center" width="100%">
      <Button
        id="view-employees-without-device"
        variant="text"
        mt={2}
        onClick={() => setShowInviteModal(true)}>
        View employees without devices
      </Button>
      <AppInviteModalWrapper
        includeDevice={false}
        includeEmail
        inviteVisible={showInviteModal}
        setShowInviteModal={setShowInviteModal}
        employees={employeesWithoutDevices}
        title="Employees without devices"
        description="When employees download the Electric application, their devices are automatically assigned in Electric. Electric can email instructions to help your employees install the Electric application, or you can copy Electric app instructions and send them to employees. "
      />
    </Flex>
  );
};

const DotKeyGrid = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-columns: 2rem 3fr 1fr;
  row-gap: 1rem;
  align-items: baseline;
  margin-top: 0;
`;
export type DeviceAssignmentStatusCardProps = {
  employeesWithoutDevices: Employee[];
  isFetchingDevices?: boolean;
  isFetchingEmployees?: boolean;
};
const DeviceAssignmentStatusCard = ({
  employeesWithoutDevices,
  isFetchingEmployees,
  isFetchingDevices,
}: DeviceAssignmentStatusCardProps) => {
  const { electrolyteDeviceManagement } = useFlags();

  const [successColor, grayLightColor, iconBackgroundColor] = useThemeColors([
    ColorsV2.SUCCESS,
    ColorsV2.GRAY_LIGHT,
    ColorsV2.GRAY_LIGHTER,
  ]);

  const { isFetching: isLoadingAssets, assets: assetsData } =
    useGetEmployeesWithAssets({
      devicesOnly: true,
      availableAssetsOnly: true,
    });

  const navigate = useNavigate();

  const employeesWithoutDevicesCount = employeesWithoutDevices?.length || 0;

  const totalAssets = assetsData?.length || 0;

  const assigned = assetsData?.filter(asset => asset.assignedTo).length || 0;
  const inStorage = totalAssets - assigned;

  const viewDevicesLink = electrolyteDeviceManagement
    ? '/device-management'
    : '/assets?page=0&searchTerm=laptop&orderBy=name&sortOrder=asc';

  const kebabMenuItems: KebabMenuOption[] = [
    {
      label: 'View devices',
      intent: 'neutral',
      onClick: () => {
        navigate(viewDevicesLink);
      },
    },
  ];

  if (!isLoadingAssets && !totalAssets) {
    return (
      <Card
        id="device-assignment-status-card-empty"
        headerDivider
        height="24rem"
        width="100%"
        elevated
        title={
          <Flex gap="1rem" alignItems="center">
            <IconInCircle
              size="3.6rem"
              icon="user-check"
              iconSize="medium"
              intent="neutral"
              background={iconBackgroundColor}
            />
            <Flex alignItems="center">
              <Text variant="heading-3">Device assignment status</Text>
            </Flex>
          </Flex>
        }
        footer={
          <Flex justifyContent="center" alignItems="center" width="100%">
            <StyledRouterLink mt={0} id="assets-breakdown-link" to="/assets">
              <Text variant="label-small" color="inherit">
                Add devices
              </Text>
            </StyledRouterLink>
          </Flex>
        }>
        <Flex vertical width="100%" alignItems="center" justifyContent="center">
          <img alt="assetBreakdown" src={eihData} height="78rem" />
          <Text variant="body-2" pt="1rem">
            There are currently no devices in your inventory.
          </Text>
        </Flex>
      </Card>
    );
  }

  const isLoading = isLoadingAssets || isFetchingDevices || isFetchingEmployees;

  return (
    <StyledCard
      $allAssigned={!employeesWithoutDevicesCount}
      headerDivider
      footerDivider
      id="asset-breakdown"
      elevated
      flexDirection="column"
      width="100%"
      minWidth="34rem"
      height="24rem"
      kebabMenuItems={kebabMenuItems}
      title={
        <Flex gap="1rem" alignItems="center">
          <IconInCircle
            size="3.6rem"
            icon="user-check"
            iconSize="medium"
            intent="neutral"
            background={iconBackgroundColor}
          />
          <Flex alignItems="center">
            <Text whiteSpace="nowrap" variant="heading-3">
              Device assignment status
            </Text>
          </Flex>
        </Flex>
      }
      footer={
        !!employeesWithoutDevicesCount && (
          <CardFooter employeesWithoutDevices={employeesWithoutDevices} />
        )
      }>
      {isLoading ? (
        <Flex
          padding="2rem"
          width="100%"
          height="18rem"
          justifyContent="center"
          vAlignContent="center">
          <Skeleton circle width="12rem" height="12rem" />
          <Flex justifyContent="flex-end" pl="2rem">
            <Skeleton count={3} width="12rem" height="2rem" />
          </Flex>
        </Flex>
      ) : (
        <Flex height="100%" minWidth="34rem" alignItems="center">
          <Flex
            flex="6"
            alignItems="stretch"
            vertical
            pt={0}
            pr={4}
            pb={employeesWithoutDevicesCount ? '0.8rem' : '2.6rem'}>
            <Text variant="body-2" mb={4}>
              Total devices
            </Text>
            <Flex height="100%" alignItems="center">
              <Flex justifyContent="flex-end" width="6rem">
                <DonutGraph
                  innerLabel={totalAssets.toString()}
                  data={[
                    { key: 'Assigned', value: assigned, color: successColor },
                    {
                      key: 'Unassigned',
                      value: inStorage,
                      color: grayLightColor,
                    },
                  ]}
                />
              </Flex>
              <Flex>
                <DotKeyGrid mt={6} ml={2}>
                  <Dot color={successColor} width="0.75rem" height="0.75rem" />
                  <Text variant="legal" pr="0.5rem" textAlign="left" mr={2}>
                    Assigned
                  </Text>
                  <Text variant="legal" textAlign="right">
                    {assigned}
                  </Text>
                  <Dot
                    color={grayLightColor}
                    width="0.75rem"
                    height="0.75rem"
                  />
                  <Text variant="legal" textAlign="left" mr={2}>
                    Unassigned
                  </Text>
                  <Text variant="legal" textAlign="right">
                    {inStorage}
                  </Text>
                </DotKeyGrid>
              </Flex>
            </Flex>
          </Flex>
          <StyledDivider vertical />
          {!!employeesWithoutDevicesCount && (
            <Flex flex={4} px={4} vertical>
              <Text variant="body-2">Employees without devices</Text>
              <Text variant="heading-1">{employeesWithoutDevicesCount}</Text>
            </Flex>
          )}
          {!employeesWithoutDevicesCount && (
            <Flex flex="4" py={0} px={4} vertical justifyContent="flex-start">
              <Icon
                intent="success"
                icon="check-circle"
                width="2rem"
                height="2rem"
                mb={2}></Icon>
              <Text variant="body">All employees have assigned devices</Text>
            </Flex>
          )}
        </Flex>
      )}
    </StyledCard>
  );
};

export default DeviceAssignmentStatusCard;

import {
  Box,
  Button,
  Flex,
  ColorsV2,
  Banner,
  Card,
  useThemeColors,
  OneColumnLayout,
  Header,
} from '@electricjs/arc';
import electricLogo from '@/assets/electric_logo_red.svg';
import {
  Auth0CandidateUser,
  HandleAccountLinkArgs,
  PrimaryConnections,
} from './auth0-link-user-types';
import { UserProfile, UserProfilesLinkVisual } from './UserProfiles';
import { Auth0Client, IdToken, User } from '@auth0/auth0-spa-js';

const AccountLinking = ({
  userToAuth,
  currentUser,
  handleAccountLink,
}: {
  userToAuth: Auth0CandidateUser;
  currentUser: User;
  handleAccountLink: (args: HandleAccountLinkArgs) => void;
}) => {
  const authenticateUser = async () => {
    // We leverage the Auth0Client to authenticate the user so that
    // it does not interfere with our session from the Auth0Provider
    // in main.js. This allows us access to both user tokens simultaneously.
    const a0 = new Auth0Client({
      domain: window._env_?.VITE_AUTH0_DOMAIN,
      clientId: window._env_?.VITE_AUTH0_CLIENT_ID,
      useRefreshTokens: true,
    });
    const connection = userToAuth?.identities[0]?.connection;
    const authorizationParams = {
      audience: window._env_?.VITE_AUTH0_AUDIENCE,
      scope:
        'openid email profile read:current_user update:current_user_identities',
      connection,
      login_hint: userToAuth?.email,
      accountLinking: true,
    };
    // Prompt the user to login via a popup rather than a redirect so that
    // we don't lose our current session info.
    await a0.loginWithPopup({
      authorizationParams,
    });
    const idTokenClaims = (await a0.getIdTokenClaims()) as IdToken;
    const accessToken = await a0.getTokenSilently({ authorizationParams });
    // Determine if the user should be made the primary account.
    // In prod and staging the primary account is the Electric account.
    const makePrimary =
      connection === PrimaryConnections.UsernamePassword ||
      connection === PrimaryConnections.Electric ||
      connection === PrimaryConnections.LegacyElectric;
    return {
      idTokenClaims,
      accessToken,
      makePrimary,
    };
  };

  const authenticateAndLinkUser = async () => {
    // We need to challenge the user to log into the account they
    // are linking to in order to verify they have access to it.
    try {
      const {
        idTokenClaims: {
          __raw: targetUserIDToken,
          sub: targetUserIdWithProvider,
        },
        accessToken,
        makePrimary,
      } = await authenticateUser();

      const targetUserId = targetUserIdWithProvider;
      handleAccountLink({
        targetUserIDToken,
        targetUserAccessToken: accessToken,
        targetUserId,
        makePrimary,
      });
    } catch (err) {
      console.log('Error linking account', err);
    }
  };

  return (
    <AccountLinkingPage
      currentUser={currentUser}
      userToAuth={userToAuth}
      handleAccountLink={authenticateAndLinkUser}
    />
  );
};

type AccountLinkingPageProps = {
  currentUser?: Auth0CandidateUser | User;
  candidateIdentitiesj?: Auth0CandidateUser[];
  userToAuth?: Auth0CandidateUser;
  handleAccountLink: () => void;
};

const AccountLinkingPage = ({
  currentUser,
  userToAuth,
  handleAccountLink,
}: AccountLinkingPageProps) => {
  const [borderColor] = useThemeColors([ColorsV2.BORDER]);
  return (
    <Box
      display="flex"
      flexDirection="column"
      height={'100vh'}
      backgroundColor={ColorsV2.BACKGROUND}
      width="100vw"
      overflow="hidden">
      <Box
        display="flex"
        flexDirection="row"
        padding="2.4rem"
        borderBottom={`solid 1px ${borderColor}`}>
        <img src={electricLogo} alt="Electric" height="42px" />
      </Box>
      <OneColumnLayout>
        <Header
          title="Link Your Existing Account"
          subtitle="It appears there is already an account associated with this e-mail."
        />
        {currentUser && userToAuth && (
          <UserProfilesLinkVisual
            currentUser={currentUser as Auth0CandidateUser}
            candidateUser={userToAuth}
          />
        )}
        <Flex vertical padding="3.2rem" alignItems="center">
          {' '}
          <Banner
            id="account-link-banner"
            isFlat
            message="Please authenticate with the following user credentials to link this
        user."></Banner>
          <Flex justifyContent="center" paddingTop="2.4rem">
            <Card id="account-link-card" elevated>
              <form>
                <Flex
                  justifyContent="space-evenly"
                  alignItems="center"
                  padding="1.2rem">
                  <Flex vertical alignItems="center">
                    <Flex paddingBottom="1.2rem">
                      {userToAuth && <UserProfile {...userToAuth} />}
                    </Flex>
                    <Button
                      id="account-link-button"
                      onClick={handleAccountLink}>
                      Link Account
                    </Button>
                  </Flex>
                </Flex>
              </form>
            </Card>
          </Flex>
        </Flex>
      </OneColumnLayout>
    </Box>
  );
};

export default AccountLinking;

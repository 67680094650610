import { datadogRum } from '@datadog/browser-rum';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';

import { useLazyGetMyEmployeeQuery } from '@/redux/slices/employeeApiSlice';
import { useLazyGetOrganizationQuery } from '@/redux/slices/organizationApiSlice';
import { useAppDispatch } from '@/redux/store';
import { OrganizationUserRoleSlugs, UserOrganization } from '@/types/users';
import safelyOpenExternalURL from 'common/safelyOpenExternalURL';

type SetUserOrganizationProps = {
  selectedOrgId?: string;
  selectedOrgRoles?: UserOrganization['roles'];
  selectedEmployeeId?: UserOrganization['employeeId'];
  userDataRequestCompleted: boolean;
};

export const useRequestUserOrganization = ({
  selectedOrgId,
  selectedOrgRoles,
  selectedEmployeeId,
  userDataRequestCompleted,
}: SetUserOrganizationProps) => {
  const { electrolyteNavigationUpdate } = useFlags();
  const [isRequestingOrgData, setIsRequestingOrgData] = useState(false);
  const [orgDataRequestCompleted, setOrgDataRequestCompleted] = useState(false);

  const dispatch = useAppDispatch();

  const [getOrganization, { isError: organizationError }] =
    useLazyGetOrganizationQuery();
  const [getMyEmployee, { isError: employeeError }] =
    useLazyGetMyEmployeeQuery();

  useEffect(() => {
    const setUserOrganization = async () => {
      if (
        userDataRequestCompleted &&
        !orgDataRequestCompleted &&
        !isRequestingOrgData &&
        selectedOrgId &&
        selectedOrgRoles
      ) {
        // set loading state to true
        setIsRequestingOrgData(true);

        const isEndUser =
          selectedOrgRoles.length === 1 &&
          selectedOrgRoles[0].slug === OrganizationUserRoleSlugs.HUB_USER;

        // Force redirect end users to EUH, unless they're trying to access the notification center
        // or the 'electrolyteNavigationUpdate' feature flag is enabled
        if (
          isEndUser &&
          !electrolyteNavigationUpdate &&
          window.location.pathname !== '/notification-center'
        ) {
          safelyOpenExternalURL(window._env_?.VITE_END_USER_HUB_URL, '_self');
        } else {
          await getOrganization(selectedOrgId, true);

          // update the flags to unblock the page loading
          setOrgDataRequestCompleted(true);
          setIsRequestingOrgData(false);

          // request employee data (won't block the page loading and its response will be automatically saved by an extraReducer)
          // Use the getMyEmployee endpoint as it doesn't require the caller to have any specific permissions.
          if (selectedEmployeeId) {
            getMyEmployee(selectedOrgId, true)
              .unwrap()
              .then(response => {
                // Now that we have the employee name, set the Datadog RUM user's name field
                datadogRum.setUserProperty(
                  'name',
                  `${response.firstName} ${response.lastName}`
                );
              });
          }
        }
      }
    };

    setUserOrganization();
  }, [
    orgDataRequestCompleted,
    isRequestingOrgData,
    selectedOrgId,
    selectedOrgRoles,
    selectedEmployeeId,
    getOrganization,
    getMyEmployee,
    dispatch,
    userDataRequestCompleted,
    electrolyteNavigationUpdate,
  ]);

  return {
    organizationError,
    employeeError,
    isRequestingOrgData,
    orgDataRequestCompleted,
  };
};

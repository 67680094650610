import {
  ColorsV2,
  Flex,
  Icon,
  IconNames,
  Skeleton,
  Text,
  useThemeColors,
} from '@electricjs/arc';

type NotificationDetailProps = {
  icon: IconNames;
  value?: string;
  loading: boolean;
};

export const NotificationDetail = ({
  icon,
  value,
  loading,
}: NotificationDetailProps) => {
  const [textColor] = useThemeColors([ColorsV2.GRAY_DARK]);

  if (loading) {
    return <Skeleton></Skeleton>;
  }

  return (
    <Flex my="0.3rem">
      <Icon icon={icon} size="small" color={textColor} />
      <Text variant="legal" color={textColor} fontSize="1rem">
        {value}
      </Text>
    </Flex>
  );
};

import { rest } from 'msw';

import { invoicesList } from '@/mocks/data/billingData';

export const billingHandlers = [
  rest.get(`*/v1/organizations/:organizationId/invoices`, (req, res, ctx) => {
    const { searchParams } = req.url;
    if (searchParams.get('check_documents') === 'true') {
      return res(ctx.status(200), ctx.delay(), ctx.json(invoicesList));
    }
    // We don't have a non check_documents mock for now, so we just return the same data
    return res(ctx.status(200), ctx.delay(), ctx.json(invoicesList));
  }),
];

import { OSType } from '@electricjs/core_entity-client';

const OSNameMap = {
  [OSType.Windows]: 'Microsoft Windows',
  [OSType.Darwin]: 'macOS',
};

type GetOsFullNameParams = {
  osType: string | undefined;
  osName: string | undefined;
  osVersion: string | undefined;
};

export const getOsFullName = ({
  osType,
  osName,
  osVersion,
}: GetOsFullNameParams) => {
  if (!osType && !osName) {
    return '';
  }

  const maybeVersion = osVersion ? ` (${osVersion})` : '';
  const prettyName = osType ? OSNameMap[osType as OSType] : '';

  if (!osName) {
    return `${prettyName}${maybeVersion}`;
  }

  if (osType === OSType.Darwin) {
    return `${prettyName} ${osName}${maybeVersion}`;
  }

  // Windows system already includes the OS brand name as a part of the osName value
  return `${osName}${maybeVersion}`;
};

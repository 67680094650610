import { useFormContext, useFormState, useWatch } from 'react-hook-form';

import {
  AddressGroupField,
  Button,
  ColorsV2,
  Flex,
  Header,
  NumberInputField,
  RadioGroup,
  Text,
  TextInputField,
  useThemeColors,
} from '@electricjs/arc';

import RadioTile from './RadioTile';
import { HiringStatus, OnboardingStep } from './types';
import { useNavigate } from 'react-router-dom';
import { FormActionsContainer } from './common';

const GOOGLE_MAPS_API_KEY = window._env_?.VITE_GOOGLE_MAPS_PUB_API_KEY;

const Demographics = () => {
  const borderColor = useThemeColors(ColorsV2.BORDER_LIGHT);
  const { control, setValue, clearErrors } = useFormContext();
  const { isValid } = useFormState();
  const hiringStatus = useWatch({ name: 'hiringStatus', control });
  const navigate = useNavigate();

  const isNewHire = hiringStatus === HiringStatus.New;
  const hasComeByNavigation = history.state.idx > 0;

  const handleCancel = () => {
    navigate(-1);
  };

  const handleNext = () => {
    setValue('currentStep', OnboardingStep.EmployeeInformation, {
      shouldValidate: true,
    });
  };

  const handleChangeHiringStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as HiringStatus;
    if (value === HiringStatus.Existing) {
      clearErrors([
        'demographics.personalEmail',
        'demographics.address',
        'demographics.phone',
        'employeeInformation',
      ]);
    }
  };

  return (
    <>
      <Header
        title="We’ll need some information"
        subtitle="Who are we onboarding?"
      />
      <Flex width="100%" vertical>
        <RadioGroup
          aria-label="new-hire"
          pb="4.2rem"
          gap="1.2rem"
          onChange={handleChangeHiringStatus}>
          <RadioTile name="hiringStatus" value={HiringStatus.New}>
            New hire
          </RadioTile>
          <RadioTile name="hiringStatus" value={HiringStatus.Existing}>
            Existing employee
          </RadioTile>
        </RadioGroup>
        <TextInputField
          id="firstName"
          name="demographics.firstName"
          control={control}
          label="First Name"
          required
        />
        <TextInputField
          id="lastName"
          name="demographics.lastName"
          control={control}
          label="Last Name"
          required
        />
        <TextInputField
          id="personalEmail"
          name="demographics.personalEmail"
          control={control}
          label="Personal Email"
          required={isNewHire}
        />
      </Flex>
      <Flex
        width="100%"
        borderTop={`1px solid ${borderColor}`}
        pt="3.2rem"
        vertical>
        <Flex width="100%" vertical>
          <Text variant="subheading" mb="3.2rem">
            What is their address?
          </Text>
          <AddressGroupField
            name="demographics.address"
            control={control}
            onChange={address =>
              setValue('demographics.address', address, {
                shouldValidate: true,
              })
            }
            googleMapsApiKey={GOOGLE_MAPS_API_KEY}
            required={isNewHire}
            columnGap={'1rem'}
          />
          <NumberInputField
            id="phone"
            name="demographics.phone"
            control={control}
            label="Phone"
            format="(###) ###-####"
            required={isNewHire}
          />
        </Flex>
      </Flex>
      <FormActionsContainer hidePreviousButton={!hasComeByNavigation}>
        {hasComeByNavigation && (
          <Button
            id="onboarding-demographic-cancel-button"
            variant="outline"
            onClick={handleCancel}>
            Cancel
          </Button>
        )}
        <Button
          id="onboarding-demographic-next-button"
          onClick={handleNext}
          disabled={!isValid}>
          Next
        </Button>
      </FormActionsContainer>
    </>
  );
};

export default Demographics;

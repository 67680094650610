import { Notification } from '@electricjs/core_entity-client';
import {
  ApiListResponseDto,
  UnseenNotificationsCountResponse,
} from '@externalTypes/general';

import { simpleOffboarding } from '@/mocks/data/offboardingData';
import { simpleOnboarding } from '@/mocks/data/onboardingData';

export const getCreatedAt = (min: number) => {
  const date = new Date();
  date.setMinutes(date.getMinutes() - min);
  return date.toString();
};

export const getNotificationsResponse: ApiListResponseDto<Notification> = {
  results: [
    {
      id: '002df5c0-054f-11ee-be56-0242ac120002',
      type: 'MDM_SETUP_COMPLETED',
      data: {},
      seen_at: undefined,
      created_at: getCreatedAt(10), // 10 min ago
      organization: {
        id: '002df5c0-054f-11ee-be56-0242ac120054',
        name: 'electric',
      },
    },
    {
      id: '002df5c0-054f-11ee-be56-0242ac120012',
      type: 'OFFBOARDING_COMPLETED',
      data: {
        offboarding_id: simpleOffboarding.id,
        employee_id: simpleOffboarding.employee.id,
      },
      seen_at: undefined,
      created_at: getCreatedAt(10), // 10 min ago
      organization: {
        id: '002df5c0-054f-11ee-be56-0242ac120054',
        name: 'electric',
      },
    },
    {
      id: '002df5c0-054f-11ee-be56-0242ac120011',
      type: 'ONBOARDING_COMPLETED',
      data: {
        onboarding_id: simpleOnboarding.id,
        employee_id: simpleOnboarding.employee.id,
      },
      seen_at: undefined,
      created_at: getCreatedAt(10), // 10 min ago
      organization: {
        id: '002df5c0-054f-11ee-be56-0242ac120054',
        name: 'electric',
      },
    },
    {
      id: '002df5c0-054f-11ee-be56-0242ac120003',
      type: 'REQUEST_COMPLETED',
      data: { request_id: '002df5c0-054f-11ee-be56-0242ac120005' },
      seen_at: undefined,
      created_at: getCreatedAt(10), // 10 min ago
      organization: {
        id: '002df5c0-054f-11ee-be56-0242ac120054',
        name: 'electric',
      },
    },
    {
      id: '002df5c0-054f-11ee-be56-0242ac12000a',
      type: 'REQUEST_ASSIGNED',
      data: { request_id: '002df5c0-054f-11ee-be56-0242ac12000b' },
      seen_at: undefined,
      created_at: getCreatedAt(2880), // 2 days ago
      organization: {
        id: '002df5c0-054f-11ee-be56-0242ac120054',
        name: 'electric',
      },
    },
    {
      id: '002df5c0-054f-11ee-be56-0242ac12000c',
      type: 'REQUEST_COMPLETED',
      data: { request_id: '002df5c0-054f-11ee-be56-0242ac12000d' },
      seen_at: '2023-07-29T05:00:00.000Z',
      created_at: getCreatedAt(43800), // 1 month ago
      organization: {
        id: '002df5c0-054f-11ee-be56-0242ac120054',
        name: 'electric',
      },
    },
    {
      id: '002df5c0-054f-11ee-be56-0242ac120005',
      type: 'REQUEST_COMPLETED',
      data: { request_id: '002df5c0-054f-11ee-be56-0242ac120005' },
      seen_at: undefined,
      created_at: getCreatedAt(10), // 10 min ago
      organization: {
        id: '002df5c0-054f-11ee-be56-0242ac120054',
        name: 'electric',
      },
    },
    {
      id: '002df5c0-054f-11ee-be56-0242ac12000d',
      type: 'REQUEST_ASSIGNED',
      data: { request_id: '002df5c0-054f-11ee-be56-0242ac12000b' },
      seen_at: undefined,
      created_at: getCreatedAt(2880), // 2 days ago
      organization: {
        id: '002df5c0-054f-11ee-be56-0242ac120056',
        name: 'electric',
      },
    },
    {
      id: '002df5c0-054f-11ee-be56-0242ac120007',
      type: 'REQUEST_COMPLETED',
      data: { request_id: '002df5c0-054f-11ee-be56-0242ac12000d' },
      seen_at: '2023-07-29T05:00:00.000Z',
      created_at: getCreatedAt(43800), // 1 month ago
      organization: {
        id: '002df5c0-054f-11ee-be56-0242ac120054',
        name: 'electric',
      },
    },
    {
      id: '002df5c0-054f-11ee-be56-0242ac120008',
      type: 'REQUEST_COMPLETED',
      data: { request_id: '002df5c0-054f-11ee-be56-0242ac120005' },
      seen_at: undefined,
      created_at: getCreatedAt(10), // 10 min ago
      organization: {
        id: '002df5c0-054f-11ee-be56-0242ac120054',
        name: 'electric',
      },
    },
    {
      id: '002df5c0-054f-11ee-be56-0242ac120009',
      type: 'REQUEST_ASSIGNED',
      data: { request_id: '002df5c0-054f-11ee-be56-0242ac12000b' },
      seen_at: undefined,
      created_at: getCreatedAt(2880), // 2 days ago
      organization: {
        id: '002df5c0-054f-11ee-be56-0242ac120010',
        name: 'electric',
      },
    },
    {
      id: '002df5c0-054f-11ee-be56-0242ac120011',
      type: 'REQUEST_COMPLETED',
      data: { request_id: '002df5c0-054f-11ee-be56-0242ac12000d' },
      seen_at: '2023-07-29T05:00:00.000Z',
      created_at: getCreatedAt(43800), // 1 month ago
      organization: {
        id: '002df5c0-054f-11ee-be56-0242ac120054',
        name: 'electric',
      },
    },
    {
      id: '002df5c0-054f-11ee-be56-0242ac120012',
      type: 'REQUEST_COMPLETED',
      data: { request_id: '002df5c0-054f-11ee-be56-0242ac120005' },
      seen_at: undefined,
      created_at: getCreatedAt(10), // 10 min ago
      organization: {
        id: '002df5c0-054f-11ee-be56-0242ac120054',
        name: 'electric',
      },
    },
    {
      id: '002df5c0-054f-11ee-be56-0242ac120013',
      type: 'REQUEST_ASSIGNED',
      data: { request_id: '002df5c0-054f-11ee-be56-0242ac12000b' },
      seen_at: undefined,
      created_at: getCreatedAt(2880), // 2 days ago
      organization: {
        id: '002df5c0-054f-11ee-be56-0242ac120054',
        name: 'electric',
      },
    },
    {
      id: '002df5c0-054f-11ee-be56-0242ac120014',
      type: 'REQUEST_COMPLETED',
      data: { request_id: '002df5c0-054f-11ee-be56-0242ac12000d' },
      seen_at: '2023-07-29T05:00:00.000Z',
      created_at: getCreatedAt(43800), // 1 month ago
      organization: {
        id: '002df5c0-054f-11ee-be56-0242ac120054',
        name: 'electric',
      },
    },
  ],
  total: 14,
};

export const getUnseenNotificationsCountResponse = (
  notifications: Notification[]
): UnseenNotificationsCountResponse => ({
  unseen_notifications_count: notifications.filter(n => !n.seen_at).length,
});

import styled from 'styled-components';

import {
  ColorsV2,
  Divider,
  Flex,
  IconButton,
  Table,
  TableContainer,
  Text,
} from '@electricjs/arc';

import {
  useGetOrganizationInvoicesQuery,
  useLazyDownloadInvoiceFileQuery,
} from '@/redux/slices/billingApiSlice';
import { Invoice } from '@/types/billing';
import { createColumnHelper } from '@tanstack/react-table';
import TableCellFlexContainer from 'common/Table/TableCellFlexContainer';

type InvoicesTileProps = {
  orgId: string;
  isInGrid: boolean;
  height: string;
};

const columnHelper = createColumnHelper<Invoice>();

const StyledText = styled(Text)`
  display: -webkit-box;
  // Ellipsis text when it reaches 2nd line
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const InvoicesTile = ({ orgId, isInGrid, height }: InvoicesTileProps) => {
  const borderColor = ColorsV2.BORDER;
  const [downloadInvoiceFile] = useLazyDownloadInvoiceFileQuery();
  const { data: organizationInvoicesData, isFetching: invoicesLoading } =
    useGetOrganizationInvoicesQuery({
      organizationId: orgId,
      check_documents: true,
    });

  const organizationInvoices: Invoice[] = organizationInvoicesData?.invoices
    ? organizationInvoicesData.invoices
    : [];

  // Show download column if there is at least one invoice with document
  const isSomeInvoiceDownloadable = organizationInvoices.some(
    invoice => invoice.hasDocuments
  );

  // Dynamically change table column width
  const columnWidth = Math.round(window.innerWidth * 0.05);

  const invoicesTableColumns = [
    columnHelper.accessor('invoiceName', {
      header: () => <Text variant="body-2">Invoice</Text>,
      id: 'invoice-name',
      meta: {
        minWidth: isInGrid ? 0 : 300,
        width: columnWidth * 1.8,
        flex: isInGrid ? '1' : '2',
      },
      cell: ({ getValue }) => {
        return (
          <TableCellFlexContainer>
            <Text variant="label-small">{getValue()}</Text>
          </TableCellFlexContainer>
        );
      },
    }),
    columnHelper.accessor('invoiceAmount', {
      header: () => {
        return <Text variant="body-2">Amount</Text>;
      },
      id: 'invoice-amount',
      meta: {
        minWidth: isInGrid ? 0 : 200,
        width: columnWidth * 1.5,
      },
      cell: ({ getValue }) => {
        return (
          <TableCellFlexContainer>
            <Text variant="body-2">$ {getValue()}</Text>
          </TableCellFlexContainer>
        );
      },
    }),
    columnHelper.accessor('invoiceDate', {
      header: () => {
        return <Text variant="body-2">Date</Text>;
      },
      id: 'invoice-date',
      meta: {
        minWidth: isInGrid ? 0 : 200,
        width: columnWidth * 1.2,
      },
      cell: ({ getValue }) => {
        return (
          <TableCellFlexContainer>
            <Text variant="body-2">{getValue()}</Text>
          </TableCellFlexContainer>
        );
      },
    }),
    columnHelper.accessor('id', {
      header: () => null,
      id: 'invoice-download',
      meta: {
        width: columnWidth * (isSomeInvoiceDownloadable ? 0.8 : 0),
        minWidth: 'unset',
      },
      cell: ({ row }) => {
        return (
          row.original.hasDocuments && (
            <IconButton
              id="download-invoice-icon-button"
              icon="file"
              onClick={() =>
                downloadInvoiceFile({
                  invoiceId: row.original.id,
                  organizationId: orgId,
                })
              }
              color={ColorsV2.PRIMARY}
            />
          )
        );
      },
    }),
  ];

  return (
    <TableContainer
      vertical
      hAlignContent="center"
      width="100%"
      alignSelf="flex-start"
      flexDirection="column"
      height={isInGrid && height}>
      <Flex vertical hAlignContent="center" minWidth="100%">
        <Flex alignSelf="flex-start" vertical rowGap="0.5rem" p="2.5rem">
          <Text variant="heading-2">Invoices</Text>
          <StyledText>View your billing and payment details.</StyledText>
        </Flex>
        <Divider color={borderColor} />
        <Table
          id="invoices-table"
          columns={invoicesTableColumns}
          data={organizationInvoices}
          loading={invoicesLoading}
        />
      </Flex>
    </TableContainer>
  );
};

export default InvoicesTile;

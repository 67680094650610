import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  AddressGroupField,
  Banner,
  Flex,
  ModalAction,
  ModalBody,
  ModalFooter,
  ModalFooterActions,
  ModalHeader,
  ModalV2,
  requiredAddressValidationSchema,
  Text,
  TextInputField,
} from '@electricjs/arc';

import { BillingInfo } from '@/types/billing';
import { emailRegex } from '@/constants/email';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useSaveBillingInfoMutation } from '@/redux/slices/billingApiSlice';
import { useGlobalUI } from '@/components/GlobalUIProvider';

const BillingInfoBanner = () => {
  return (
    <Banner
      id="billing-info-banner"
      isFlat
      title="Company-wide billing update"
      message="Billing information is linked to your company, not an individual account. Any changes made will update the billing details for your entire company."
    />
  );
};

const billingInfoSchema = yup.object().shape({
  email: yup.string().matches(emailRegex, 'Invalid email').required(),
  address: requiredAddressValidationSchema,
});

const defaultValues: BillingInfo = {
  email: '',
  address: {
    streetAddress1: '',
    streetAddress2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  },
};

type BillingInfoModalProps = {
  onClose: () => void;
  billingInfo?: BillingInfo;
};

export const BillingInfoModal = ({
  onClose,
  billingInfo,
}: BillingInfoModalProps) => {
  const { showSuccessToast, showErrorToast } = useGlobalUI();

  const organizationId = useGetOrganizationId();
  const [saveBillingInfo, { isLoading }] = useSaveBillingInfoMutation();

  const {
    control,
    handleSubmit,
    formState: { isValid },
    setValue,
  } = useForm<BillingInfo>({
    resolver: yupResolver(billingInfoSchema),
    mode: 'onTouched',
    defaultValues,
  });

  useEffect(() => {
    if (billingInfo) {
      setValue('email', billingInfo.email);
      setValue('address', billingInfo.address);
    }
  }, [billingInfo, setValue]);

  const handleSave = (formData: BillingInfo) => {
    // call backend to save the billing info
    saveBillingInfo({
      organizationId,
      billingInfo: formData,
    })
      .unwrap()
      .then(() => {
        {
          billingInfo
            ? showSuccessToast({
                id: 'edit-billing-info-success-toast',
                title: 'Success',
                message: 'Company billing information has been updated.',
                stack: true,
              })
            : showSuccessToast({
                id: 'save-billing-info-success-toast',
                title: 'Success',
                message: 'Company billing information successfully saved.',
                stack: true,
              });
        }
        onClose();
      })
      .catch(() => {
        showErrorToast({
          id: 'save-billing-info-error-toast',
          message: 'Something went wrong. Please try again.',
        });
      });
  };

  return (
    <ModalV2
      ariaLabelledby="billing-info-modal-header-text"
      hide={onClose}
      maxWidth="80rem"
      visible>
      <ModalHeader>
        <Flex vertical rowGap="2rem">
          <Text id="billing-info-modal-header-text" variant="heading-2">
            {billingInfo ? 'Edit' : 'Add'} company billing information
          </Text>
          {billingInfo && <BillingInfoBanner />}
        </Flex>
      </ModalHeader>
      <form onSubmit={handleSubmit(handleSave)}>
        <ModalBody>
          <TextInputField
            id="billing-info-email"
            name="email"
            control={control}
            label="Company billing email"
            placeholder="e.g. accountspayable@company.com"
            required
          />
          <AddressGroupField
            name="address"
            control={control}
            onChange={address =>
              setValue('address', address, { shouldValidate: true })
            }
            googleMapsApiKey={window._env_?.VITE_GOOGLE_MAPS_PUB_API_KEY}
            required
          />
        </ModalBody>
        <ModalFooter>
          <ModalFooterActions>
            <ModalAction
              id="billing-info-modal-cancel-button"
              variant="text"
              disabled={isLoading}
              onClick={onClose}>
              Cancel
            </ModalAction>
            <ModalAction
              id="billing-info-modal-save-button"
              loading={isLoading}
              disabled={!isValid || isLoading}
              onClick={() => {}} // empty function to avoid the ModalAction default click behavior (that is to close the modal)
              type="submit">
              Save
            </ModalAction>
          </ModalFooterActions>
        </ModalFooter>
      </form>
    </ModalV2>
  );
};

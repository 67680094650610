import React, { useEffect, useRef, useState } from 'react';
import { IconNames } from '@electricjs/arc/components/Icon';
import { Button, ButtonProps } from '@electricjs/arc';

type CopyButtonProps = ButtonProps & {
  textToCopy: string;
  children?: React.ReactNode;
};

export const copiedTextTimeoutLength = 1500;

/**
 * Hook to copy text to clipboard and show a confirmation message
 *
 * @param textToCopy Text to copy to clipboard
 * @param timeoutDurationMs Optional duration to show the confirmation message
 */
export const useCopyTextToClipboard = (
  textToCopy: string,
  timeoutDurationMs: number = copiedTextTimeoutLength
): [() => Promise<void>, boolean] => {
  const [confirmationOn, setConfirmationOn] = useState<boolean>(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const copyTextToClipboard = async () => {
    await navigator.clipboard.writeText(textToCopy);
    setConfirmationOn(true);
    timeoutRef.current = setTimeout(() => {
      setConfirmationOn(false);
    }, timeoutDurationMs);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return [copyTextToClipboard, confirmationOn];
};

const CopyButton = ({ textToCopy, ...buttonProps }: CopyButtonProps) => {
  const [handleCopy, confirmationOn] = useCopyTextToClipboard(textToCopy);

  const updatedButtonProps = {
    ...buttonProps,
  };
  const updatedChildren = confirmationOn ? 'Copied!' : buttonProps.children;
  const iconBefore: IconNames = confirmationOn ? 'check' : 'copy';

  return (
    <Button
      variant="outline"
      {...updatedButtonProps}
      iconBefore={iconBefore}
      onClick={handleCopy}>
      {updatedChildren}
    </Button>
  );
};

export default CopyButton;

// Created this types only to have some params for it

export enum SortIcons {
  Default = './sort-default.svg',
  SortUp = './sort-up.svg',
  SortDown = './sort-down.svg',
}

export enum SortDirections {
  Asc = 'asc',
  Desc = 'desc',
}

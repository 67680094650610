import { Employee as EmployeeDto } from '@electricjs/core_entity-client';
import { rest } from 'msw';

import { simpleGroup } from '@/mocks/data/groupData';
import {
  simpleOrganizationGroups,
  simpleOrganization,
  simpleOrganizationEmployees,
} from '@/mocks/data/organizationData';

interface AddEmployeesResponse {
  description: string;
  added_employees: EmployeeDto[];
  failed_employee_emails: string[];
}

export const organizationHandlers = [
  rest.get('*/v1/organizations', (_req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(),
      ctx.json({ results: [simpleOrganization], total: 1 })
    );
  }),

  rest.get('*/v1/organizations/:id', (_req, res, ctx) => {
    // As example, returning always the same organization for any given id:
    return res(ctx.status(200), ctx.delay(), ctx.json(simpleOrganization));

    //If needed (in future cases), we can be more realistic:
    // if (req.params.id === simpleOrganization.id) {
    //   return res(ctx.status(200), ctx.delay(), ctx.json(simpleOrganization));
    // } else {
    //   res(ctx.status(404)); // returning "not found" for other given ids
    // }
  }),

  rest.get('*/v1/organizations/:id/employees', (_req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(),
      ctx.json({
        results: simpleOrganizationEmployees,
        total: simpleOrganizationEmployees.length,
      })
    );
  }),

  rest.post('*/v1/organizations/:id/employees', async (req, res, ctx) => {
    const body = await req.json();

    // add the employees to the mock data (mutate the data)
    simpleOrganizationEmployees.push(...body.employees);

    const addEmployeesResponse: AddEmployeesResponse = {
      description: 'Employees added successfully',
      added_employees: body.employees,
      failed_employee_emails: [],
    };

    return res(ctx.status(201), ctx.delay(), ctx.json(addEmployeesResponse));
  }),

  rest.get('*/v1/organizations/:id/groups', (_req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(),
      ctx.json({
        results: simpleOrganizationGroups,
        total: simpleOrganizationGroups.length,
      })
    );
  }),

  rest.get('*/v1/organizations/:orgId/groups/:groupId', (_req, res, ctx) => {
    return res(ctx.status(200), ctx.delay(), ctx.json(simpleGroup));
  }),
];

import { IconNames, useBreakpoint, Flex, Text } from '@electricjs/arc';
import { HRISProviderSlugEnum } from '@electricjs/core_entity-client';
import { Box } from 'reakit';
import styled from 'styled-components';
import { ADPIntegrationCard } from './ADPIntegrationCard';
import MergeIntegrations from './MergeIntegrations';
import { ADPRunIntegrationCard } from './ADPRunIntegrationCard';
import { useFlags } from 'launchdarkly-react-client-sdk';

export type Integration = {
  slug?: HRISProviderSlugEnum;
  name: string;
  icon?: IconNames;
  customIcon?: string;
  description: string;
  homepage?: string;
};

const CardsGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fit, 34rem);
  gap: 2.6rem;
  min-height: 19rem;
  width: 100%;
  margin-top: 1.6rem;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const HRISIntegrations = () => {
  const { md } = useBreakpoint();
  const { electrolyteAdpRun } = useFlags();

  return (
    <Flex width="100%" vertical alignItems="stretch">
      <Text variant="heading-2">HR Systems</Text>
      <Flex
        my="0.5rem"
        maxWidth="107rem"
        alignItems="center"
        gap={md ? '5rem' : '2.5rem'}
        flexWrap={md ? 'nowrap' : 'wrap'}>
        <Text variant="body" maxWidth="70rem">
          With HR System integrations, Electric can sync up-to-date information
          from your HRIS to Electric for updates, onboardings, and offboardings.
        </Text>
      </Flex>

      <CardsGrid>
        <ADPIntegrationCard />
        {electrolyteAdpRun && <ADPRunIntegrationCard />}
        <MergeIntegrations />
      </CardsGrid>
    </Flex>
  );
};
export default HRISIntegrations;

import { Flex, IconNames } from '@electricjs/arc';
import SquareIconTab from '@/components/SquareIconTab';
import { DeviceIssueType } from '@/types/devices';

export type TabContent = {
  id: number;
  icon: string;
  property: DeviceIssueType;
  label: string;
  numErrors: number;
};

type IssueTabsProps = {
  tabContent: TabContent[];
  selectedTab: DeviceIssueType;
  handleTabClick: (tab: DeviceIssueType) => void;
};

export const IssueTabs = ({
  tabContent,
  selectedTab,
  handleTabClick,
}: IssueTabsProps) => {
  return (
    <Flex flexWrap="wrap">
      {tabContent.map(tab => {
        return (
          <SquareIconTab
            id={`${tab.property}-tab`}
            key={tab.id}
            icon={tab.icon as IconNames}
            label={tab.label}
            property={tab.property}
            selectedTab={selectedTab}
            onClick={handleTabClick}
            numErrors={tab.numErrors}
          />
        );
      })}
    </Flex>
  );
};

import { format } from 'date-fns';

import {
  OrderLineItemStatus,
  OrderStatus,
} from '@electricjs/core_entity-client';

export const formatToDateOnly = (date: string) => format(date, 'MM/dd/yy');

/**
 * Object to translate order's status
 */
export const orderStatusDisplayName: Record<OrderStatus, string> = {
  [OrderStatus.Created]: 'Received',
  [OrderStatus.Deleted]: 'Canceled',
  [OrderStatus.Delivered]: 'Delivered',
  [OrderStatus.NotFound]: 'Not found',
  [OrderStatus.Null]: 'Unknown',
  [OrderStatus.PartiallyDelivered]: 'Partially delivered',
  [OrderStatus.PartiallyShipped]: 'Partially shipped',
  [OrderStatus.Processing]: 'In progress',
  [OrderStatus.Rejected]: 'Rejected',
  [OrderStatus.Shipped]: 'Shipped',
};

/**
 * Object to translate order line's status
 */
export const orderLineStatusDisplayName: Record<OrderLineItemStatus, string> = {
  [OrderLineItemStatus.Assigned]: 'Assigned',
  [OrderLineItemStatus.Backordered]: 'Backordered',
  [OrderLineItemStatus.CarrierDelay]: 'Carrier delay',
  [OrderLineItemStatus.CarrierException]: 'Carrier exception',
  [OrderLineItemStatus.Created]: 'Received',
  [OrderLineItemStatus.Deleted]: 'Canceled',
  [OrderLineItemStatus.Delivered]: 'Delivered',
  [OrderLineItemStatus.InTransit]: 'In transit',
  [OrderLineItemStatus.NotFound]: 'Not found',
  [OrderLineItemStatus.OutForDelivery]: 'Out for delivery',
  [OrderLineItemStatus.PendingPickup]: 'Pending pickup',
  [OrderLineItemStatus.Pickup]: 'Pickup',
  [OrderLineItemStatus.Processing]: 'In progress',
  [OrderLineItemStatus.Rejected]: 'Rejected',
  [OrderLineItemStatus.Shipped]: 'Shipped',
};

import {
  Box,
  Flex,
  IconButton,
  Text,
  Radio,
  RadioGroup,
} from '@electricjs/arc';

import styled from 'styled-components';

import { useGlobalUI } from '@/components/GlobalUIProvider';
import { useResponsiveness } from '@common';

import formatCurrency from '@/helpers/formatCurrency';

import {
  AssetCartEntry,
  CatalogCartEntry,
  Store,
  type Warranty,
} from '@/types/storefront';

import { isLaptop } from '../utils';
import { ProductBasicInfo } from './Catalog/ProductBasicInfo';
import { AssetBasicInfo } from './Asset/AssetBasicInfo';
import { ItemDetailsModal } from './ItemDetailsModal';
import { capitalize } from './ProductSpecsFilter';

const StyledWarrantyList = styled.ul`
  padding-left: 2.4rem;
  margin: 0;
`;

export type CatalogCartEntryRow = {
  store: Store.Catalog;
  entry: CatalogCartEntry;
  onQuantityDecrement: (itemId: string) => void;
  onQuantityIncrement: (itemId: string) => void;
  onWarrantySelect: (itemId: string, warranty: Warranty) => void;
};

export type AssetCartEntryRow = {
  store: Store.Asset;
  entry: AssetCartEntry;
  onQuantityDecrement?: never;
  onQuantityIncrement?: never;
  onWarrantySelect?: never;
};

export type CommonCartEntryRow = {
  onEntryRemove: (itemId: string) => void;
};

export type CartEntryRowProps = (CatalogCartEntryRow | AssetCartEntryRow) &
  CommonCartEntryRow;

export const CartEntryRow = ({
  store,
  entry,
  onQuantityDecrement,
  onQuantityIncrement,
  onWarrantySelect,
  onEntryRemove,
}: CartEntryRowProps) => {
  const { openModal, closeModal } = useGlobalUI();

  const getEntryTotalPrice = (entry: CatalogCartEntry) => {
    const warrantyPrice = entry.selectedWarranty?.price ?? 0;

    return (entry.item.price + warrantyPrice) * entry.quantity;
  };

  const { hasNoHorizontalSpace } = useResponsiveness();

  return (
    <Flex vertical>
      <Flex
        width="100%"
        minWidth={hasNoHorizontalSpace ? '60rem' : '68rem'}
        columnGap="3rem"
        alignItems="center">
        {/* Item data (e.g., image, name) */}
        <Box flexGrow="1">
          {store === Store.Catalog && (
            <ProductBasicInfo
              name={entry.item.name}
              image={entry.item.image}
              shouldBreak={hasNoHorizontalSpace}
              onLinkClick={() =>
                openModal(
                  <ItemDetailsModal
                    item={entry.item}
                    store={Store.Catalog}
                    onAddItem={() => {}}
                    onRemoveItem={() => onEntryRemove(entry.item.id)}
                    onClose={closeModal}
                    selected
                  />
                )
              }
            />
          )}
          {store === Store.Asset && (
            <AssetBasicInfo
              name={entry.item.name}
              customId={entry.item.customAssetId}
              serialNumber={entry.item.serialNumber}
              siteName={entry.item.site?.name}
              onLinkClick={() =>
                openModal(
                  <ItemDetailsModal
                    item={entry.item}
                    store={Store.Asset}
                    onAddItem={() => {}}
                    onRemoveItem={() => onEntryRemove(entry.item.id)}
                    onClose={closeModal}
                    selected
                  />
                )
              }
            />
          )}
        </Box>

        {/* Item price (unit price) */}
        {store === Store.Catalog && (
          <Text flexBasis="9rem" flexShrink="0" variant="body" textAlign="end">
            {formatCurrency(entry.item.price)}
          </Text>
        )}

        {/* Quantity selectors */}
        {store === Store.Catalog && (
          <Flex flexBasis="9rem" flexShrink="0" alignItems="center">
            <IconButton
              id={`cart-entry-decrease-quantity-button-${entry.item.id}`}
              icon="minus-circle"
              iconSize="medium"
              disabled={entry.quantity === 1}
              onClick={() => onQuantityDecrement(entry.item.id)}
            />
            <Text flexBasis="2rem" textAlign="center" variant="label-large">
              {entry.quantity}
            </Text>
            <IconButton
              id={`cart-entry-increase-quantity-button-${entry.item.id}`}
              icon="add-circle"
              iconSize="medium"
              onClick={() => onQuantityIncrement(entry.item.id)}
            />
          </Flex>
        )}

        {/* Entry total price */}
        {store === Store.Catalog && (
          <Text
            flexBasis="10rem"
            flexShrink="0"
            variant="label-large"
            textAlign="end">
            {formatCurrency(getEntryTotalPrice(entry))}
          </Text>
        )}

        {/* Entry remove button */}
        <IconButton
          flexBasis="3.4rem"
          id={`cart-entry-remove-button-${entry.item.id}`}
          icon="trashcan"
          testId="remove-entry"
          onClick={() => onEntryRemove(entry.item.id)}
        />
      </Flex>

      {/* Laptop Warranty */}
      {store === Store.Catalog && isLaptop(entry.item) && (
        <Flex vertical width="100%" rowGap="2rem" mt="2rem">
          {/* Title */}
          <Text variant="label-large">Upgrade your device warranty</Text>

          {/* Warranty */}
          <Flex width="100%">
            {/* Warranty Options */}
            <RadioGroup aria-label="warranty-selection-option" stack>
              {'warranties' in entry.item &&
                entry.item?.warranties?.map(warranty => {
                  // for each warranty available for the product
                  const isWarrantyDefault =
                    'defaultWarranty' in entry.item &&
                    warranty.id === entry.item?.defaultWarranty?.id;

                  const isWarrantySelected =
                    warranty.id === entry.selectedWarranty?.id;

                  return (
                    <Radio
                      id={`warranty-${warranty.id}`}
                      key={`warranty-${warranty.id}`}
                      value={warranty.price}
                      checked={isWarrantySelected}
                      onChange={() =>
                        onWarrantySelect(entry.item.id, warranty)
                      }>
                      <Text>
                        {capitalize(warranty.brand)} {capitalize(warranty.name)}{' '}
                        Warranty -{' '}
                        <Text variant="label-large">
                          {isWarrantyDefault
                            ? 'Included'
                            : formatCurrency(warranty.price)}
                        </Text>
                      </Text>
                    </Radio>
                  );
                })}
            </RadioGroup>

            {/* Warranty Includes */}
            {entry.selectedWarranty && (
              <Flex vertical width="100%">
                <Text mb="2rem">
                  {capitalize(entry.selectedWarranty.brand)}{' '}
                  {entry.selectedWarranty.name} Warranty includes:{' '}
                </Text>
                <StyledWarrantyList>
                  {entry.selectedWarranty.includes.map(inc => (
                    <li key={`warranty-include-${inc}`}>
                      <Text>{inc}</Text>
                    </li>
                  ))}
                </StyledWarrantyList>
              </Flex>
            )}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

import {
  ProductResponse as ProductDto,
  ProductTypeName,
  ConnectivityEnum,
  PortTypesEnum,
} from '@electricjs/core_entity-client';

import cableImage from '../assets/cable-250x188.png';
import dockImage from '../assets/dock-1200x900.png';
import headsetImage from '../assets/headset-250x188.png';
import keyboardImage from '../assets/keyboard-250x188.png';
import smallLaptopImage from '../assets/laptop-451x303.png';
import bigLaptopImage from '../assets/laptop-1200x900.png';
import smallMonitorImage from '../assets/monitor-72x72.png';
import bigMonitorImage from '../assets/monitor-700x525.png';
import mouseImage from '../assets/mouse-500x375.png';
import webcamImage from '../assets/webcam-250x188.png';

// warranties

const warranty1: ProductDto = {
  id: '2aff6dcc-7f11-4bf5-82f9-1fd77a934ebd',
  name: 'Warranty 1',
  brand: 'Brand 1',
  product_type: ProductTypeName.Warranty,
  price: 0,
  active: true,
  description: 'Warranty 1 description',
  link: 'https://electric.ai',
  td_synnex_sku_number: 12345,
  tax_code: '123',
};

const warranty2: ProductDto = {
  id: 'b72417d7-8042-4431-9dca-e9145c5de09e',
  name: 'Warranty 2',
  brand: 'Brand 1',
  product_type: ProductTypeName.Warranty,
  price: 199,
  active: true,
  description: 'Warranty 2 description',
  link: 'https://electric.ai',
  td_synnex_sku_number: 12345,
  tax_code: '123',
};

const warranty3: ProductDto = {
  id: '144cbb88-5230-4223-8f57-e5971cb2747a',
  name: 'Warranty 3',
  brand: 'Brand 2',
  product_type: ProductTypeName.Warranty,
  price: 0,
  active: true,
  description: 'Warranty 3 description',
  link: 'https://electric.ai',
  td_synnex_sku_number: 12345,
  tax_code: '123',
};

const warranty4: ProductDto = {
  id: '3a6ebb1f-73d5-4704-b761-70bbda2d70d7',
  name: 'Warranty 4',
  brand: 'Brand 2',
  product_type: ProductTypeName.Warranty,
  price: 249,
  active: true,
  description: 'Warranty 4 description',
  link: 'https://electric.ai',
  td_synnex_sku_number: 12345,
  tax_code: '123',
};

const warranty5: ProductDto = {
  id: '349f9280-c0e8-4dea-ab33-d8b4f8b9057c',
  name: 'Warranty 5',
  brand: 'apple',
  product_type: ProductTypeName.Warranty,
  price: 225,
  active: true,
  description: 'Warranty 5 description',
  link: 'https://electric.ai',
  td_synnex_sku_number: 12345,
  tax_code: '123',
};

// keyboards

const keyboard1: ProductDto = {
  id: '6fed093c-f7b0-4a3d-b9e1-b806dba0dd61',
  name: 'Dell Keyboard 1',
  brand: 'dell',
  product_type: ProductTypeName.Keyboard,
  price: 49,
  active: true,
  notes: 'Keyboard 1 notes',
  connectivity: [ConnectivityEnum.Wired],
  port_types: [PortTypesEnum.UsbA],
  link: 'https://electric.ai',
  image: keyboardImage,
  td_synnex_sku_number: 12345,
  tax_code: '123',
};

const keyboard2: ProductDto = {
  id: '83264779-e1df-4295-be51-425ccf9c32f7',
  name: 'Dell Keyboard 2',
  brand: 'dell',
  product_type: ProductTypeName.Keyboard,
  price: 69,
  active: true,
  notes: 'Keyboard 2 notes',
  connectivity: [ConnectivityEnum.Wireless],
  port_types: [PortTypesEnum.UsbC],
  link: 'https://electric.ai',
  td_synnex_sku_number: 12345,
  tax_code: '123',
};

const keyboard3: ProductDto = {
  id: '6e48928b-6f9b-4860-86c5-22c5bf048a4b',
  name: 'HP Keyboard 3',
  brand: 'hp',
  product_type: ProductTypeName.Keyboard,
  price: 39,
  active: true,
  notes: 'Keyboard 3 notes',
  connectivity: [ConnectivityEnum.Wired],
  port_types: [PortTypesEnum.UsbA, PortTypesEnum.UsbC],
  link: 'https://electric.ai',
  td_synnex_sku_number: 12345,
  tax_code: '123',
};

// mouses

const mouse1: ProductDto = {
  id: 'ea309333-cda8-4aca-9cc5-ef55a863f746',
  name: 'Lenovo Mouse 1',
  brand: 'lenovo',
  product_type: ProductTypeName.Mouse,
  price: 29,
  active: true,
  notes: 'Mouse 1 notes',
  connectivity: [ConnectivityEnum.Wired],
  port_types: [PortTypesEnum.UsbA],
  link: 'https://electric.ai',
  image: mouseImage,
  td_synnex_sku_number: 12345,
  tax_code: '123',
};

const mouse2: ProductDto = {
  id: '93aae850-e22f-424c-af92-9d3b2e8a9f2a',
  name: 'Logitech Mouse 2',
  brand: 'logitech',
  product_type: ProductTypeName.Mouse,
  price: 39,
  active: true,
  notes: 'Mouse 2 notes',
  connectivity: [ConnectivityEnum.Wireless],
  port_types: [PortTypesEnum.UsbC],
  link: 'https://electric.ai',
  td_synnex_sku_number: 12345,
  tax_code: '123',
};

const mouse3: ProductDto = {
  id: '059ed95a-98c0-4a1e-992b-9463044c5cc4',
  name: 'Apple Mouse 3',
  brand: 'apple',
  product_type: ProductTypeName.Mouse,
  price: 49,
  active: true,
  notes: 'Mouse 3 notes',
  connectivity: [ConnectivityEnum.Wired],
  port_types: [PortTypesEnum.UsbA, PortTypesEnum.UsbC],
  link: 'https://electric.ai',
  td_synnex_sku_number: 12345,
  tax_code: '123',
};

// keyboards and mice

const keyboardMouse1: ProductDto = {
  id: 'f3ea559b-5dd6-4cc6-9df7-ddf6719eb2f1',
  name: 'Logitech Combo 1',
  brand: 'logitech',
  product_type: ProductTypeName.KeyboardMouse,
  price: 59,
  active: true,
  notes: 'Combo 1 notes',
  connectivity: [ConnectivityEnum.Wired],
  port_types: [PortTypesEnum.UsbA],
  link: 'https://electric.ai',
  image: keyboardImage,
  td_synnex_sku_number: 12345,
  tax_code: '123',
};

const keyboardMouse2: ProductDto = {
  id: '64d199b3-846d-49c8-8114-e41b740cda22',
  name: 'Dell Combo 2',
  brand: 'dell',
  product_type: ProductTypeName.KeyboardMouse,
  price: 79,
  active: true,
  notes: 'Combo 2 notes',
  connectivity: [ConnectivityEnum.Wireless],
  port_types: [PortTypesEnum.UsbA, PortTypesEnum.UsbC],
  link: 'https://electric.ai',
  td_synnex_sku_number: 12345,
  tax_code: '123',
};

const keyboardMouse3: ProductDto = {
  id: 'dec5c817-08e3-407b-b1a0-db3c9073e0af',
  name: 'HP Combo 3',
  brand: 'hp',
  product_type: ProductTypeName.KeyboardMouse,
  price: 99,
  active: true,
  notes: 'Combo 3 notes',
  connectivity: [ConnectivityEnum.Wired],
  port_types: [
    PortTypesEnum.UsbA,
    PortTypesEnum.UsbC,
    PortTypesEnum.Thunderbolt,
  ],
  link: 'https://electric.ai',
  td_synnex_sku_number: 12345,
  tax_code: '123',
};

const keyboardMouse4: ProductDto = {
  id: '3b3f881d-36e7-4c7e-9dfb-594af0276d0f',
  name: 'Apple Combo 4',
  brand: 'apple',
  product_type: ProductTypeName.KeyboardMouse,
  price: 99,
  active: true,
  notes: 'Combo 4 notes',
  connectivity: [ConnectivityEnum.Wired],
  port_types: [
    PortTypesEnum.UsbA,
    PortTypesEnum.UsbC,
    PortTypesEnum.Thunderbolt,
  ],
  link: 'https://electric.ai',
  td_synnex_sku_number: 12345,
  tax_code: '123',
};

// monitors

const monitor1: ProductDto = {
  id: '9c517367-198d-45d1-8212-8f36cfd8b241',
  name: 'Dell Monitor 1',
  brand: 'dell',
  product_type: ProductTypeName.Monitor,
  price: 199,
  active: true,
  notes: 'Monitor 1 notes',
  screen_size: 24,
  quality: '1080p',
  link: 'https://electric.ai',
  image: smallMonitorImage,
  td_synnex_sku_number: 12345,
  tax_code: '123',
};

const monitor2: ProductDto = {
  id: '05500877-ff88-4ec0-bbd7-cb6f2a02075d',
  name: 'Dell Monitor 2',
  brand: 'dell',
  product_type: ProductTypeName.Monitor,
  price: 299,
  active: true,
  notes: 'Monitor 2 notes',
  screen_size: 27,
  quality: '4K',
  link: 'https://electric.ai',
  image: bigMonitorImage,
  td_synnex_sku_number: 12345,
  tax_code: '123',
};

const monitor3: ProductDto = {
  id: '2268d664-98b9-4b6a-b19e-51a169ecdb3e',
  name: 'HP Monitor 3',
  brand: 'hp',
  product_type: ProductTypeName.Monitor,
  price: 249,
  active: true,
  notes: 'Monitor 3 notes',
  screen_size: 32,
  quality: '1440p',
  link: 'https://electric.ai',
  td_synnex_sku_number: 12345,
  tax_code: '123',
};

// audios

const audio1: ProductDto = {
  id: '91ea8b90-a6ae-45b0-a155-8bd1701622eb',
  name: 'Apple Audio 1',
  brand: 'apple',
  product_type: ProductTypeName.Audio,
  price: 79,
  active: true,
  notes: 'Audio 1 notes',
  connectivity: [ConnectivityEnum.Wireless],
  link: 'https://electric.ai',
  image: headsetImage,
  td_synnex_sku_number: 12345,
  tax_code: '123',
};

const audio2: ProductDto = {
  id: 'bc11464f-491e-4b48-90dd-0f3d7e19364d',
  name: 'Dell Audio 2',
  brand: 'dell',
  product_type: ProductTypeName.Audio,
  price: 99,
  active: true,
  notes: 'Audio 2 notes',
  connectivity: [ConnectivityEnum.Wired],
  link: 'https://electric.ai',
  td_synnex_sku_number: 12345,
  tax_code: '123',
};

const audio3: ProductDto = {
  id: '4360c60a-c75f-483c-a22d-b1b79be66de8',
  name: 'HP Audio 3',
  brand: 'hp',
  product_type: ProductTypeName.Audio,
  price: 149,
  active: true,
  notes: 'Audio 3 notes',
  connectivity: [ConnectivityEnum.Wireless],
  link: 'https://electric.ai',
  td_synnex_sku_number: 12345,
  tax_code: '123',
};

// videos

const video1: ProductDto = {
  id: '0008e9d6-ca7e-419f-b006-6e972ff8bba7',
  name: 'Lenovo Video 1',
  brand: 'lenovo',
  product_type: ProductTypeName.Video,
  price: 199,
  active: true,
  notes: 'Video 1 notes',
  quality: '1080p',
  connectivity: [ConnectivityEnum.Wired],
  link: 'https://electric.ai',
  image: webcamImage,
  td_synnex_sku_number: 12345,
  tax_code: '123',
};

const video2: ProductDto = {
  id: '17a5e104-d9a5-4dc5-83c8-4c5ab4e7e937',
  name: 'Logitech Video 2',
  brand: 'logitech',
  product_type: ProductTypeName.Video,
  price: 299,
  active: true,
  notes: 'Video 2 notes',
  quality: '4K',
  connectivity: [ConnectivityEnum.Wireless],
  link: 'https://electric.ai',
  td_synnex_sku_number: 12345,
  tax_code: '123',
};

const video3: ProductDto = {
  id: '66ea2c8f-7425-4863-a07b-1c309a0b27b6',
  name: 'Dell Video 3',
  brand: 'dell',
  product_type: ProductTypeName.Video,
  price: 149,
  active: true,
  notes: 'Video 3 notes',
  quality: '1080p',
  connectivity: [ConnectivityEnum.Wireless],
  link: 'https://electric.ai',
  td_synnex_sku_number: 12345,
  tax_code: '123',
};

// docks

const dock1: ProductDto = {
  id: 'd8894ece-6eff-4e1c-8308-469b4abd3ae1',
  name: 'Dock 1',
  brand: 'Brand 1',
  product_type: ProductTypeName.Dock,
  price: 199,
  active: true,
  notes: 'Dock 1 notes',
  num_displays: 2,
  link: 'https://electric.ai',
  image: dockImage,
  td_synnex_sku_number: 12345,
  tax_code: '123',
};

const dock2: ProductDto = {
  id: 'e11df045-26a7-4f73-926d-a3ecd1da470d',
  name: 'Dock 2',
  brand: 'Brand 2',
  product_type: ProductTypeName.Dock,
  price: 299,
  active: true,
  notes: 'Dock 2 notes',
  num_displays: 3,
  link: 'https://electric.ai',
  td_synnex_sku_number: 12345,
  tax_code: '123',
};

const dock3: ProductDto = {
  id: '636d785e-4a86-4fd5-a0fa-cbe97aca94da',
  name: 'Dock 3',
  brand: 'Brand 3',
  product_type: ProductTypeName.Dock,
  price: 149,
  active: true,
  notes: 'Dock 3 notes',
  num_displays: 1,
  link: 'https://electric.ai',
  td_synnex_sku_number: 12345,
  tax_code: '123',
};

// miscellaneous

const misc1: ProductDto = {
  id: '1bf2d7a1-aef6-4e11-8852-0e62eb993304',
  name: 'Miscellaneous 1',
  brand: 'Brand 1',
  product_type: ProductTypeName.Miscellaneous,
  price: 49,
  active: true,
  notes: 'Miscellaneous 1 notes',
  description: 'Miscellaneous 1 description',
  link: 'https://electric.ai',
  image: cableImage,
  td_synnex_sku_number: 12345,
  tax_code: '123',
};

const misc2: ProductDto = {
  id: '768740ce-ef21-49b1-bed1-6c251d8bbd44',
  name: 'Miscellaneous 2',
  brand: 'Brand 2',
  product_type: ProductTypeName.Miscellaneous,
  price: 99,
  active: true,
  notes: 'Miscellaneous 2 notes',
  description: 'Miscellaneous 2 description',
  link: 'https://electric.ai',
  td_synnex_sku_number: 12345,
  tax_code: '123',
};

const misc3: ProductDto = {
  id: '38b7243d-6961-486a-8e36-a5e3a1d4c715',
  name: 'Miscellaneous 3',
  brand: 'Brand 3',
  product_type: ProductTypeName.Miscellaneous,
  price: 79,
  active: true,
  notes: 'Miscellaneous 3 notes',
  description: 'Miscellaneous 3 description',
  link: 'https://electric.ai',
  td_synnex_sku_number: 12345,
  tax_code: '123',
};

// laptops

const laptop1: ProductDto = {
  id: '0c3efc59-cfc2-448f-8e5b-ce35bac4983b',
  name: 'Laptop 1 (Dell)',
  brand: 'dell',
  product_type: ProductTypeName.Laptop,
  price: 999,
  active: true,
  notes: 'Laptop 1 notes',
  storage: '512GB SSD',
  memory: 16,
  processor: 'Processor 1',
  screen_size: 15.6,
  default_warranty_id: 'warranty-1',
  default_warranty: warranty1,
  warranties: [warranty2],
  link: 'https://electric.ai',
  image: smallLaptopImage,
  td_synnex_sku_number: 12345,
  tax_code: '123',
};

const laptop2: ProductDto = {
  id: 'c033855b-71d4-41eb-9eda-d5eadc67cda1',
  name: 'Laptop 2 (HP)',
  brand: 'hp',
  product_type: ProductTypeName.Laptop,
  price: 1299,
  active: true,
  notes: 'Laptop 2 notes',
  storage: '1024GB SSD',
  memory: 32,
  processor: 'Processor 2',
  screen_size: 13.3,
  default_warranty_id: 'warranty-3',
  default_warranty: warranty3,
  warranties: [warranty3, warranty4],
  link: 'https://electric.ai',
  image: bigLaptopImage,
  td_synnex_sku_number: 12345,
  tax_code: '123',
};

const appleLaptop: ProductDto = {
  id: 'f5476a5f-3a4e-4a09-8aa2-610a835869fc',
  name: 'Laptop 3 (Apple)',
  brand: 'apple',
  product_type: ProductTypeName.Laptop,
  price: 1499,
  active: true,
  notes: 'Macbook Pro',
  storage: '1024GB SSD',
  memory: 32,
  processor: 'Processor 3',
  screen_size: 13.3,
  default_warranty_id: 'warranty-4',
  default_warranty: warranty3,
  warranties: [warranty5],
  link: 'https://electric.ai',
  td_synnex_sku_number: 12345,
  tax_code: '123',
};

export const allProductsV2 = [
  laptop1,
  laptop2,
  keyboard1,
  keyboard2,
  keyboard3,
  mouse1,
  mouse2,
  mouse3,
  keyboardMouse1,
  keyboardMouse2,
  keyboardMouse3,
  monitor1,
  monitor2,
  monitor3,
  audio1,
  audio2,
  audio3,
  video1,
  video2,
  video3,
  dock1,
  dock2,
  dock3,
  misc1,
  misc2,
  misc3,
  keyboardMouse4,
  appleLaptop,
];

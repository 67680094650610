import { Button } from '@electricjs/arc';
import { IntegrationType } from '@electricjs/core_entity-client';
import Nango, { ConnectUI } from '@nangohq/frontend';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { apiSlice } from '@/redux/slices/apiSlice';
import {
  useCreateNangoConnectionTokenMutation,
  useGetIntegrationSupportQuery,
  useCreateNangoConnectionIdMutation,
} from '@/redux/slices/integrationsApiSlice';
import { useGlobalUI } from '@/components/GlobalUIProvider';
import { OAuthFlowMessageStatus } from '@/components/Integrations/OAuthFlowMessage';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import useIsProdSupport from '@/hooks/useIsProdSupport';

type useIntegrationConnectProps = {
  integrationName: string;
  appSlug: string;
};

export const useIntegrationConnect = ({
  appSlug,
  integrationName,
}: useIntegrationConnectProps) => {
  const { showSuccessToast, showErrorToast, showToast } = useGlobalUI();
  const oauthWindow = useRef<Window | null>(null);
  const nangoConnectUI = useRef<ConnectUI>();
  const dispatch = useDispatch();
  const orgId = useGetOrganizationId();
  const isProdSupport = useIsProdSupport();

  // Gets the Nango connection token required to interact with the Nango frontend SDK
  const [getNangoToken] = useCreateNangoConnectionTokenMutation();
  const [saveNangoConnection] = useCreateNangoConnectionIdMutation();

  const handleAuthWindowError = () => {
    showErrorToast({
      id: 'integration-auth-window-pop-up-blocked-error-toast',
      message: 'Please allow pop-ups to connect the integration',
    });
  };

  const handleNangoAuthFlow = async () => {
    if (isProdSupport) {
      return handleProdSupportFlow();
    }

    const nango = new Nango({ publicKey: window._env_.NANGO_PUBLIC_KEY });
    const { data: nangoTokenData, error: tokenError } = await getNangoToken({
      organizationId: orgId,
      applicationKey: appSlug,
    });

    if (nangoTokenData?.token) {
      try {
        nangoConnectUI.current = nango.openConnectUI({
          baseURL: window._env_.NANGO_BASE_URL,
          sessionToken: nangoTokenData.token,

          // Nango returns a connect event when connection is successful
          onEvent: async event => {
            if (event.type === 'connect') {
              const { connectionId } = event.payload;
              // save connection id here, data is in event.payload
              const { error: nangoError } = await saveNangoConnection({
                organizationId: orgId,
                applicationKey: appSlug,
                connectionId,
              });

              if (nangoError) {
                showErrorToast({
                  id: 'nango-save-connection-error-toast',
                  message:
                    'An error occurred while saving the connection. Please try again later or contact support if the issue persists.',
                });
              }
            }
          },
        });
      } catch (error) {
        handleAuthWindowError();
      }
    } else if (tokenError) {
      showErrorToast({
        id: 'nango-token-error-toast',
        message:
          'An error occurred while connecting. Please try again later or contact support if the issue persists.',
      });
    }
  };

  const handleOAuthFlow = () => {
    if (isProdSupport) {
      return handleProdSupportFlow();
    }

    const HEIGHT = 600;
    const WIDTH = 445;
    const top = window.top ?? window;
    const y = top.outerHeight / 2 + top.screenY - HEIGHT / 2;
    const x = top.outerWidth / 2 + top.screenX - WIDTH / 2;
    const windowName = `${appSlug}_authorization`;

    try {
      oauthWindow.current = window.open(
        `/integrations/connect/${appSlug}?appName=${integrationName}`,
        windowName,
        `toolbar=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no,width=${WIDTH},height=${HEIGHT},top=${y},left=${x}`
      );
      if (oauthWindow === null || oauthWindow.current?.closed) {
        handleAuthWindowError();
      }
    } catch (error) {
      handleAuthWindowError();
    }
  };

  const handleProdSupportFlow = () => {
    showToast({
      id: 'integration-connect-disabled-for-prod-support',
      message: 'This feature is disabled for product support.',
      intent: 'info',
      title: 'Disabled',
    });
  };

  useEffect(() => {
    const bc = new BroadcastChannel(`integrations-oauth-flow-${appSlug}`);
    bc.onmessage = event => {
      if (event.data.status === OAuthFlowMessageStatus.Success) {
        dispatch(apiSlice.util.invalidateTags([{ type: 'Application' }]));
        showSuccessToast({
          id: 'integration-connect-success-toast',
          message: `${integrationName} connected successfully!`,
        });
      }
    };
  }, [dispatch, integrationName, showSuccessToast, showErrorToast, appSlug]);

  sessionStorage.setItem('integrationAppName', integrationName);

  return { handleOAuthFlow, handleNangoAuthFlow };
};

type IntegrationConnectButtonProps = {
  integrationName: string;
  appSlug: string;
};

const IntegrationConnectButton = ({
  integrationName,
  appSlug,
}: IntegrationConnectButtonProps) => {
  const { handleNangoAuthFlow, handleOAuthFlow } = useIntegrationConnect({
    integrationName,
    appSlug,
  });

  const orgId = useGetOrganizationId();

  const { data: integrationSupport } = useGetIntegrationSupportQuery({
    applicationKey: appSlug,
    organizationId: orgId,
  });

  return (
    <Button
      id={`connect-integration-${appSlug}`}
      onClick={
        integrationSupport?.type === IntegrationType.Nango
          ? handleNangoAuthFlow
          : handleOAuthFlow
      }
      variant="outline">
      Connect {integrationName}
    </Button>
  );
};

export default IntegrationConnectButton;

import { HRISProviderSlugEnum } from '@electricjs/core_entity-client';
import HRISIntegrationCard from './HRISIntegrationCard';
import { Integration } from './HRISIntegrations';
import HRISConnectedSuccessfullyModal from '@/components/People/HRISConnectedSuccessfullyModal';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { ActionConfirmationModal, CenteredSpinner } from '@common';
import {
  useCreateHrisIntegrationMutation,
  useDeleteHrisIntegrationMutation,
  useCreateLinkTokenMutation,
} from '@/redux/slices/hrisIntegrationApiSlice';
import { useMergeLink } from '@mergeapi/react-merge-link';
import { useCallback, useEffect, useState } from 'react';
import justworksLogo from '@/assets/integrations/justworks.svg';
import trinetLogo from '@/assets/integrations/trinet.png';
import useIntegrationProvider from './useIntegrationProvider';
import { Text } from '@electricjs/arc';
import { useGlobalUI } from '@/components/GlobalUIProvider';

const INTEGRATIONS: Integration[] = [
  {
    slug: HRISProviderSlugEnum.Justworks,
    name: 'Justworks',
    customIcon: justworksLogo,
    description:
      'All-in-one HR, payroll, benefits, and compliance platform for SMBs.',
    homepage: 'https://www.justworks.com',
  },
  {
    slug: HRISProviderSlugEnum.TrinetHrPlatform,
    name: 'TriNet HR',
    customIcon: trinetLogo,
    description:
      'Payroll, benefits & HR admin — all in one streamlined solution.',
    homepage: 'https://www.trinet.com',
  },
  {
    name: 'Other integrations',
    icon: 'add-circle',
    description: 'Setup a different connection for seamless automation.',
  },
];

const MergeIntegrations = () => {
  const [linkToken, setLinkToken] = useState('');
  const [isHRISConnectionLoading, setHRISConnectionLoading] = useState(false);
  const organizationId = useGetOrganizationId();
  const { showSuccessToast, showErrorToast, openModal, closeModal } =
    useGlobalUI();

  const {
    hrisIntegrationId: currentHrisIntegrationId,
    provider: currentProvider,
    isLoadingProvider: isLoadingCurrentProvider,
  } = useIntegrationProvider();

  const [createHrisIntegration, { isLoading: isConnectingIntegration }] =
    useCreateHrisIntegrationMutation();

  const [deleteHrisIntegration, { isLoading: isDisconnectingIntegration }] =
    useDeleteHrisIntegrationMutation();

  const [createLinkToken] = useCreateLinkTokenMutation();

  const onSuccess = useCallback(
    publicToken => {
      setHRISConnectionLoading(false);

      createHrisIntegration({
        organizationId,
        publicToken: publicToken,
      })
        .unwrap()
        .then(() => {
          openModal(<HRISConnectedSuccessfullyModal onClose={closeModal} />);
        })
        .catch(error => {
          console.error(`Error creating Hris integration: ${error}`);
          showErrorToast({
            id: 'hris-connection-error-toast',
            stack: true,
            message: 'Something went wrong. Please try again.',
          });
        });
    },
    [
      createHrisIntegration,
      organizationId,
      showErrorToast,
      openModal,
      closeModal,
    ]
  );

  const onExit = useCallback(() => {
    setHRISConnectionLoading(false);
  }, []);

  const { open: openMergeLink, isReady: isMergeLinkReady } = useMergeLink({
    linkToken,
    onSuccess,
    onExit,
    shouldSendTokenOnSuccessfulLink: false,
  });

  const handleHRISConnectionRequest = (integration?: string) => {
    setHRISConnectionLoading(true);
    createLinkToken({ organizationId, integration })
      .unwrap()
      .then(linkToken => setLinkToken(linkToken))
      .catch(error => {
        console.error(`Error generating link token: ${error}`);
        showErrorToast({
          id: 'hris-connection-error-toast',
          stack: true,
          message: 'Something went wrong. Please try again.',
        });
      });
  };

  const handleDisconnectionRequest = () => {
    if (currentHrisIntegrationId) {
      openModal(
        <ActionConfirmationModal
          title="Disconnect HRIS integration"
          message={
            <>
              <Text>
                Disconnecting your HRIS would mean that you wouldn&rsquo;t be
                alerted when employees are joining or leaving your company. Your
                employee information will have to be manually updated and will
                not be synced with your HRIS employee information.
              </Text>
              <Text>
                You will be able to connect a HRIS after disconnecting.
              </Text>
            </>
          }
          cancelButtonLabel="Cancel"
          confirmButtonLabel="Disconnect HRIS"
          onCancel={() => closeModal()}
          onConfirm={async () => {
            return deleteHrisIntegration({
              organizationId,
              hrisIntegrationId: currentHrisIntegrationId,
            })
              .unwrap()
              .then(() => {
                showSuccessToast({
                  id: 'hris-disconnection-success-toast',
                  stack: true,
                  message: 'HRIS integration disconnected',
                });
              })
              .catch(error => {
                console.error(`Error deleting HRIS integration: ${error}`);
                showErrorToast({
                  id: 'hris-disconnection-error-toast',
                  stack: true,
                  message: 'Something went wrong. Please try again.',
                });
              })
              .finally(() => closeModal());
          }}
        />
      );
    }
  };

  useEffect(() => {
    if (isMergeLinkReady && linkToken && isHRISConnectionLoading) {
      setHRISConnectionLoading(false);
      openMergeLink();
    }
    return () => {
      setLinkToken('');
    };
  }, [isMergeLinkReady, linkToken, openMergeLink, isHRISConnectionLoading]);

  if (
    isLoadingCurrentProvider ||
    isConnectingIntegration ||
    isDisconnectingIntegration
  ) {
    return <CenteredSpinner />;
  }

  return (
    <>
      {INTEGRATIONS.map(integration => (
        <HRISIntegrationCard
          key={integration.slug}
          name={integration.name}
          slug={integration.slug}
          customIcon={integration.customIcon}
          icon={integration.icon}
          homepage={integration.homepage}
          description={integration.description}
          handleConnectionRequest={() =>
            handleHRISConnectionRequest(integration.slug)
          }
          handleDisconnectionRequest={handleDisconnectionRequest}
          currentProvider={currentProvider}
        />
      ))}
    </>
  );
};

export default MergeIntegrations;

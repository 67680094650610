/**
 * Returns the full name composed of the first name and last name.
 * If both first name and last name are undefined, returns the default name if provided.
 * If no default name is provided, returns an empty string.
 *
 * @param {string | undefined} firstName - The first name.
 * @param {string | undefined} lastName - The last name.
 * @param {string} [defaultName] - The default name to return if both first name and last name are undefined.
 */
export const getFullName = (
  firstName: string | undefined,
  lastName: string | undefined,
  defaultName?: string
) => {
  const fullName = [firstName, lastName].filter(Boolean).join(' ');
  return fullName || defaultName || '';
};

import { Chip, ColorsV2, Text, useThemeColors } from '@electricjs/arc';

type UpgradeChipProps = {
  className?: string;
  onClick: () => void;
};

const UpgradeChip = ({ className, onClick }: UpgradeChipProps) => {
  const [backgroundColor, textColor] = useThemeColors([
    ColorsV2.INFO_LIGHTEST,
    ColorsV2.INFO,
  ]);

  return (
    <Chip
      id="upgrade-chip"
      backgroundColor={backgroundColor}
      variant="small"
      width="fit-content"
      ml={2}
      className={className}
      onClick={e => {
        e.stopPropagation();
        onClick();
      }}>
      <Text variant="legal" intent="brand" color={textColor}>
        Upgrade
      </Text>
    </Chip>
  );
};

export default UpgradeChip;

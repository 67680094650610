import { sortBy } from 'lodash';
import { FormEvent, useMemo } from 'react';

import { Checkbox, Radio, RadioGroup, Select, Textarea } from '@electricjs/arc';

import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import {
  useGetOrganizationApplicationsQuery,
  useGetOrganizationEmployeesQuery,
} from '@/redux/slices/organizationApiSlice';
import { Employee, EmployeeStatus } from '@/types/employees';

import ApplicationSelect from '../ApplicationSelect/ApplicationSelect';

type RequestSupportFormInputProps = {
  name: string;
  type: string;
  // The form fields come from the DB, so we can't statically type them
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: any[] | null;
  // The form fields come from the DB, so we can't statically type them
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values?: any;
  handleCheckbox?: (e: FormEvent) => void;
};

const RequestSupportFormInput = ({
  name,
  type,
  options,
  values,
  handleCheckbox,
  ...field
}: RequestSupportFormInputProps) => {
  const organizationId = useGetOrganizationId();

  const { data: activeEmployees, isFetching: employeesFetching } =
    useGetOrganizationEmployeesQuery(
      {
        organizationId,
        status: EmployeeStatus.Active,
      },
      {
        skip: !organizationId || type !== 'employeeDropdown',
      }
    );

  const { data: orgAppsResponse, isFetching: applicationsFetching } =
    useGetOrganizationApplicationsQuery(
      { organizationId },
      {
        skip: !organizationId || type !== 'applicationDropdown',
      }
    );

  const applicationOptions = useMemo(() => {
    const organizationApplications = orgAppsResponse?.applications ?? [];

    return organizationApplications?.map(appData => ({
      name: appData.application.name,
      id: appData.application.id,
      icon: appData.application.icon,
    }));
  }, [orgAppsResponse]);

  switch (type) {
    case 'textarea':
      return (
        <Textarea
          id="request-form-textarea"
          {...field}
          aria-labelledby={name}
        />
      );
    case 'dropdown':
      if (!options) return null;
      return (
        <Select
          id="request-form-select"
          {...field}
          isClearable
          aria-labelledby={name}
          options={options}
        />
      );
    case 'employeeDropdown':
      return (
        <Select
          id="request-form-employee-select"
          {...field}
          options={sortBy(activeEmployees, 'firstName')}
          aria-labelledby={name}
          isLoading={employeesFetching}
          loadingText="Loading employee list..."
          getOptionLabel={(employee: Employee) =>
            `${employee.firstName} ${employee.lastName} (${employee.email})`
          }
          getOptionValue={(employee: Employee) => employee.id}
          isSearchable
          isClearable
        />
      );
    case 'applicationDropdown':
      return (
        <ApplicationSelect
          {...field}
          aria-labelledby={name}
          options={applicationOptions}
          isLoading={applicationsFetching}
        />
      );
    case 'radio':
      return (
        <RadioGroup {...field} aria-label={name} stack>
          {options?.map((item, key) => (
            <Radio id="request-form-radio" key={key} value={item}>
              {item}
            </Radio>
          ))}
        </RadioGroup>
      );
    case 'checkbox':
      return (
        <>
          {options?.map((item, key) => (
            <Checkbox
              id="request-form-checkbox"
              {...field}
              key={key}
              value={item.value}
              checked={values?.includes(item.value)}
              onChange={handleCheckbox}>
              {item.label}
            </Checkbox>
          ))}
        </>
      );
    default:
      return null;
  }
};

export default RequestSupportFormInput;

import { Banner, Button, Flex, Text, useBreakpoint } from '@electricjs/arc';
import MDMInviteModalWrapper from '../Organization/MDMInviteModal/MDMInviteModalWrapper';
import { useEffect, useState } from 'react';
import { useGetOrganizationEmployeesQuery } from '@/redux/slices/organizationApiSlice';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { ProvisionStatusEnum } from '@/types/mdmManagement';
import { CopyButton } from '@common';
import SetUpAppleMDMBanner from '@/components/MDMEnrollment/SetUpAppleMDMBanner';
import {
  MdmCertExpirationStatus,
  useGetMdmCertExpirationStatus,
} from '@/hooks/useGetMdmCertExpiration';

const PendingStatus = ({
  onRefetchStatus,
}: {
  onRefetchStatus: () => void;
}) => {
  // fetch configuration status every 5 seconds when component is mounted
  useEffect(() => {
    const intervalId = setInterval(() => {
      onRefetchStatus();
    }, 5000);

    // Clean interval when component is unmounted.
    return () => clearInterval(intervalId);
  }, [onRefetchStatus]);

  return (
    <Flex marginLeft="auto" flexDirection="column" alignItems="end">
      <Text variant="body-2" maxWidth="41rem" textAlign="right" mt="1.2rem">
        Once everything is ready, we&apos;ll notify you to send out enrollment
        instructions to your employees. Thank you for your patience!
      </Text>
    </Flex>
  );
};

const CompletedStatus = ({
  onSendEmailInstructions,
  onSetupAppleDevices,
  onRenewCertificate,
  hasMacDevices,
}: {
  onSetupAppleDevices: () => void;
  onSendEmailInstructions: () => void;
  onRenewCertificate: () => void;
  hasMacDevices: boolean;
}) => {
  const { lg } = useBreakpoint();
  const downloadLink = window._env_?.VITE_DESKTOP_APP_DOWNLOAD_URL;

  const {
    status: mdmCertStatus,
    expirationDate: expirationDateAsString,
    appleCreatorId,
    isLoading,
  } = useGetMdmCertExpirationStatus();

  const expirationDate = expirationDateAsString
    ? new Date(expirationDateAsString).toLocaleDateString()
    : '';

  const isExpirationAboutToExpire =
    mdmCertStatus === MdmCertExpirationStatus.ABOUT_TO_EXPIRE;
  const isExpired = mdmCertStatus === MdmCertExpirationStatus.EXPIRED;

  const enrollmentStatusIfAppleDevices = (
    <Text>
      Your MDM setup is complete! Your Apple push certificate is active until{' '}
      {expirationDate}. Ensure your organization’s devices are protected by
      enrolling all employees in MDM. You can email the instructions or share
      instructions via a link.{' '}
    </Text>
  );

  const enrollmentStatusIfWindowsOnly = (
    <Text>
      Your MDM setup is complete! Ensure your organization’s devices are
      protected by enrolling all employees in MDM. You can email the
      instructions or share instructions via a link.{' '}
    </Text>
  );

  const normalEnrollmentStatus = (
    <Flex vertical>
      <Flex flexWrap={lg ? 'nowrap' : 'wrap'}>
        {hasMacDevices && expirationDate !== ''
          ? enrollmentStatusIfAppleDevices
          : enrollmentStatusIfWindowsOnly}
        <Flex
          justifyContent={lg ? 'flex-end' : 'center'}
          width={lg ? 'unset' : '100%'}
          columnGap={5}
          mt={lg ? 0 : 5}>
          <CopyButton
            id="copy-instructions-link-button"
            textToCopy={downloadLink}
            variant="text"
            minWidth="20rem">
            Copy instructions link
          </CopyButton>
          <Button
            id="send-email-instructions-button"
            testId="send-email-instructions-button"
            onClick={onSendEmailInstructions}
            minWidth="16rem"
            variant="outline">
            Email instructions
          </Button>
        </Flex>
      </Flex>
      {!isLoading && !expirationDate && (
        <Flex mt={5}>
          <SetUpAppleMDMBanner onSetupAppleDevices={onSetupAppleDevices} />
        </Flex>
      )}
    </Flex>
  );

  const messageIfAboutToExpire = (
    <Text>
      Your Apple Signing Certificate (CSR) will expire on{' '}
      <b>{expirationDate}</b>
      . If the CSR expires, MDM communication with already enrolled devices will
      cease, and new devices cannot be enrolled.
      <br />
      Renew today using the same Apple ID <b>{appleCreatorId}</b> if possible to
      ensure continued device management and security.
    </Text>
  );

  const messageIfExpired = (
    <Text>
      Your Apple Signing Certificate (CSR) expired on <b>{expirationDate}</b>.
      MDM communication with already enrolled devices has ceased, and new
      devices cannot be enrolled.
      <br />
      Renew today using the same Apple ID <b>{appleCreatorId}</b> if possible to
      ensure continued device management and security.
    </Text>
  );

  const statusIfCertNeedsRenewal = (
    <Flex vertical>
      <Banner
        id="mdm-certificate-expiration-banner"
        isFlat
        isFullWidth
        title="Action required: Renew your Apple push certificate"
        intent={isExpired ? 'error' : 'warning'}
        message={() =>
          isExpirationAboutToExpire ? messageIfAboutToExpire : messageIfExpired
        }
        cta={
          <Flex mt="1rem">
            <Button
              id="mdm-renew-now-button"
              iconAfter="arrow-right"
              onClick={onRenewCertificate}
              intent={isExpired ? 'danger' : 'warning'}
              variant="outline">
              Renew now
            </Button>
          </Flex>
        }
      />
    </Flex>
  );

  if (isExpired || isExpirationAboutToExpire) {
    return statusIfCertNeedsRenewal;
  } else {
    return normalEnrollmentStatus;
  }
};

type MdmEnrollmentStatusProps = {
  hasMacDevices: boolean;
  onSetupAppleDevices: () => void;
  onRenewCertificate: () => void;
  onRefetchStatus: () => void;
  provisionStatus?: ProvisionStatusEnum;
};

export const MdmEnrollmentStatus = ({
  hasMacDevices,
  onSetupAppleDevices,
  onRenewCertificate,
  onRefetchStatus,
  provisionStatus,
}: MdmEnrollmentStatusProps) => {
  const organizationId = useGetOrganizationId();

  const { data: employees } = useGetOrganizationEmployeesQuery({
    organizationId,
    includeDevices: true,
  });

  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  return (
    <>
      <Flex
        width="100%"
        p="2.5rem 3rem"
        justifyContent="space-between"
        flexWrap="wrap-reverse"
        alignItems="flex-end"
        gap="2rem">
        {provisionStatus === ProvisionStatusEnum.COMPLETED_STATUS ? (
          <CompletedStatus
            hasMacDevices={hasMacDevices}
            onSendEmailInstructions={() => setShowInviteModal(true)}
            onSetupAppleDevices={onSetupAppleDevices}
            onRenewCertificate={onRenewCertificate}
          />
        ) : (
          <PendingStatus onRefetchStatus={onRefetchStatus} />
        )}
      </Flex>
      <MDMInviteModalWrapper
        employees={employees || []}
        inviteVisible={showInviteModal}
        previewVisible={showPreviewModal}
        setShowInviteModal={setShowInviteModal}
        setShowPreviewModal={setShowPreviewModal}
      />
    </>
  );
};

import { ReactElement } from 'react';

import { Flex, Skeleton, Text } from '@electricjs/arc';

type NotificationTitleProps = {
  children: ReactElement;
  loading: boolean;
};

export const NotificationTitle = ({
  children,
  loading,
}: NotificationTitleProps) => {
  if (loading) {
    // Loading state
    return (
      <Flex mt="1">
        <Skeleton width="20rem" height="1.5rem" count={1}></Skeleton>
      </Flex>
    );
  }

  if (!loading && !children) {
    return null;
  }

  return (
    <Flex>
      <Text variant="legal">{children}</Text>
    </Flex>
  );
};

import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export const getDataFromLocalStorage = (keyName: string) => {
  try {
    const data = localStorage.getItem(keyName);

    if (data) {
      return JSON.parse(data);
    }

    return data;
  } catch (error) {
    window?.DD_RUM?.addError(
      `Error getting data from local storage: ${JSON.stringify(error)}`,
      {
        location: 'useStoredState hook',
      }
    );
    return null;
  }
};

export const setDataToLocalStorage = (keyName: string, data: unknown) => {
  try {
    localStorage.setItem(keyName, JSON.stringify(data));
  } catch (error) {
    window?.DD_RUM?.addError(
      `Error saving data to local storage': ${JSON.stringify(error)}`,
      {
        location: 'useStoredState hook',
      }
    );
  }
};

/**
 * This hook seamlessly integrates the capabilities of the useState hook with local storage,
 * enabling efficient storage and retrieval of the current state.
 * It is designed for scenarios where utilizing local storage is essential.
 * However, for cases where local storage is not imperative, it is recommended to opt for temporary storage alternatives
 * such as local state or redux.
 *
 * @param keyName Key for save value in the local storage.
 * @param initialData Optional initial value. This will be used when there are not match in the local storage for the key provide.
 * @returns Returns a stateful value stored in local storage, and a function to update it.
 */
export const useStoredState = <S>(
  keyName: string,
  initialData?: S
): [S, Dispatch<SetStateAction<S>>] => {
  const storedData = getDataFromLocalStorage(keyName);
  const [data, setData] = useState(
    storedData !== null ? storedData : initialData
  );

  useEffect(() => {
    setDataToLocalStorage(keyName, data);
  }, [data, keyName]);

  return [data, setData];
};

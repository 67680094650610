import { Asset } from '@/types/assets';
import { Row } from '@tanstack/react-table';
import { getFullName } from 'common/utils/getFullName';

export const getAssignedToSortingValue = (asset: Asset) => {
  if (!asset) return '';

  return getFullName(
    asset.assignedToEmployee?.firstName,
    asset.assignedToEmployee?.lastName
  );
};

export const getSiteSortingValue = (asset: Asset) => {
  return asset.site?.name || '';
};

/**
 * Curried custom sorting function.
 * @param {Function} getValueFn - Function to get the sorting value from the data.
 * @returns {Function} - A function that takes two rows and returns the sorting order.
 * @note An empty value is considered to be in a later position (in ascending order) than a value with a higher alphabetical designation. For example, "" > "Z".
 */
export const handleCustomSorting =
  (getValueFn: (_: Asset) => string) =>
  (assetA: Row<Asset>, assetB: Row<Asset>) => {
    const assignedToA = getValueFn(assetA.original);
    if (!assignedToA) return 1;

    const assignedToB = getValueFn(assetB.original);
    if (!assignedToB) return -1;

    return assignedToA.localeCompare(assignedToB);
  };

import { HRISProviderSlugEnum } from '@electricjs/core_entity-client';
import adpLogo from '@/assets/integrations/adp.png';
import useIntegrationProvider from './useIntegrationProvider';
import HRISIntegrationCard from './HRISIntegrationCard';

export const ADPIntegrationCard = () => {
  const { provider: currentProvider } = useIntegrationProvider();

  return (
    <HRISIntegrationCard
      slug={HRISProviderSlugEnum.AdpWfn}
      name="ADP Workforce Now"
      customIcon={adpLogo}
      homepage="https://www.adp.com"
      description="Industry-leading online payroll and HR solutions, plus tax, compliance, benefit administration, and more."
      handleConnectionRequest={() => {
        window.open('https://apps.adp.com/en-US/home', '_blank');
      }}
      currentProvider={currentProvider}
    />
  );
};

import { ColorsV2, Flex, useBreakpoint, useThemeColors } from '@electricjs/arc';
import React, { ReactElement } from 'react';
import { v4 as uuid } from 'uuid';

const TimeLineDot = () => {
  const [bgColor, borderColor] = useThemeColors([
    ColorsV2.INFO_DARK,
    ColorsV2.INFO_LIGHTEST,
  ]);

  return (
    <Flex
      width="3.3rem"
      height="3.2rem"
      backgroundColor={bgColor}
      borderRadius="50%"
      border={`1rem solid ${borderColor}`}></Flex>
  );
};

type LineProps = { isTopLine: boolean; hidden?: boolean };

const Line = ({ hidden = false, isTopLine }: LineProps) => {
  const [color] = useThemeColors([ColorsV2.GRAY_LIGHT]);

  return (
    <Flex
      border={`2px solid ${color}`}
      flexGrow={1}
      opacity={hidden ? 0 : 6} // 6 is the token in arc to opacity: 1
      borderRadius={isTopLine ? '0 0 16px 16px' : '16px 16px 0 0'}></Flex>
  );
};

type TimeLineProps = {
  children: ReactElement | ReactElement[];
};

export const TimeLine = ({ children }: TimeLineProps) => {
  const { md } = useBreakpoint();
  return (
    <Flex flexDirection="column" width="100%">
      {React.Children.toArray(children).map((child, index, list) => {
        // Ensure each child is a fragment containing two elements
        if (
          !React.isValidElement(child) ||
          !Array.isArray(child.props.children)
        ) {
          console.error(
            'Each child must be a fragment containing exactly two elements.'
          );
          return null;
        }

        const isFirstItem = index === 0;
        const isLastItem = index === list.length - 1;

        const childElement = child as ReactElement;
        const key = childElement?.key ?? uuid();

        const [beforeDotElement, afterDotElement] = childElement.props.children;

        return (
          <Flex key={key} alignItems="stretch" gap="1.5rem" width="100%">
            <Flex justifyContent="flex-end">{beforeDotElement}</Flex>
            <Flex flexDirection="column" alignItems="center">
              <Line isTopLine={true} hidden={isFirstItem} />
              {md && <TimeLineDot />}
              <Line isTopLine={false} hidden={isLastItem} />
            </Flex>
            {afterDotElement}
          </Flex>
        );
      })}
    </Flex>
  );
};

import { IconNames } from '@electricjs/arc';

// Currently, cards will use icons from a subset of those available to Core customers. In the future, we may allow users to provide their own icons or choose from a larger selection.
// The card icon is defined by the category `name` and, in the case the value does not exist, it uses the most generic icon as a fallback.
export function getCategoryIcon(name = 'Default') {
  const CardIcons: Record<string, IconNames> = {
    Applications: 'app',
    Device: 'laptop',
    Connectivity: 'wifi',
    'Something Else': 'question-circle',
    Default: 'question-circle',
  };

  return CardIcons[name] || CardIcons.Default;
}

import { RequestTypeSlugEnum } from '@/types/requests';

import { NotificationItemProps } from '../types';
import HardwareRequestNotificationItem, {
  HardwareRequestActionsColumn,
  HardwareRequestDetailsColumns,
} from './HardwareRequestNotificationItem';
import ApplicationRequestNotificationItem, {
  ApplicationRequestActionsColumn,
  ApplicationRequestDetailsColumns,
} from './ApplicationRequestNotificationItem';
import { useGetRequestWithTypeById } from '@/hooks/useGetRequestWithTypeById';
import HRISOnboardingNotificationItem from './HRISOnboardingNotificationItem';
import HRISOffboardingNotificationItem from './HRISOffboardingNotificationItem';

export const RequestActionsColumn = ({
  notification,
}: NotificationItemProps) => {
  const requestId = notification?.data?.request_id;
  const { data, isLoading } = useGetRequestWithTypeById(requestId);

  if (data?.requestType?.slug === RequestTypeSlugEnum.HARDWARE_PURCHASE) {
    return (
      <HardwareRequestActionsColumn
        request={data?.request}
        requestType={data?.requestType}
        isLoading={isLoading}
      />
    );
  }

  return <ApplicationRequestActionsColumn notification={notification} />;
};

export const RequestDetailsColumns = ({
  notification,
}: NotificationItemProps) => {
  const requestId = notification?.data?.request_id;
  const { data } = useGetRequestWithTypeById(requestId);

  if (data?.requestType?.slug === RequestTypeSlugEnum.HARDWARE_PURCHASE) {
    return <HardwareRequestDetailsColumns notification={notification} />;
  }

  return <ApplicationRequestDetailsColumns notification={notification} />;
};

const RequestNotificationItem = ({
  notification,
  hideDetails,
}: NotificationItemProps) => {
  const requestId = notification?.data?.request_id;
  const { data } = useGetRequestWithTypeById(requestId);

  if (data?.requestType?.slug === RequestTypeSlugEnum.HARDWARE_PURCHASE) {
    return (
      <HardwareRequestNotificationItem
        notification={notification}
        hideDetails={hideDetails}
      />
    );
  } else if (data?.requestType?.slug === RequestTypeSlugEnum.HRIS_ONBOARDING) {
    return (
      <HRISOnboardingNotificationItem
        notification={notification}
        hideDetails={hideDetails}
      />
    );
  } else if (data?.requestType?.slug === RequestTypeSlugEnum.HRIS_OFFBOARDING) {
    return (
      <HRISOffboardingNotificationItem
        notification={notification}
        hideDetails={hideDetails}
      />
    );
  }

  return (
    <ApplicationRequestNotificationItem
      notification={notification}
      hideDetails={hideDetails}
    />
  );
};

export default RequestNotificationItem;

import {
  InternalType,
  PermissionEnum,
  Slug,
} from '@electricjs/core_entity-client';

import { HRISIntegration, PackageSlug } from './organizations';

export enum OrganizationUserRoleSlugs {
  HUB_USER = 'hub_user',
  SUPER_ADMIN = 'super_admin',
  PRODUCT_SUPPORT = 'product_support',
}

export const RoleDisplayMapping = {
  [OrganizationUserRoleSlugs.HUB_USER]: {
    displayName: 'Employee',
    description:
      'Employee Access and submit tickets in the Electric User Hub, our support center',
  },
  [OrganizationUserRoleSlugs.SUPER_ADMIN]: {
    displayName: 'Admin',
    description:
      'Admin Access to Electric User Hub and Electric IT Hub, our admin tool',
  },
  [OrganizationUserRoleSlugs.PRODUCT_SUPPORT]: {
    displayName: 'Product Support',
    description:
      'Product Support access to Electric User Hub and Electric IT Hub',
  },
};

export type OrganizationUserRoles = Array<{
  id: string;
  slug: OrganizationUserRoleSlugs;
}>;

export type Role = {
  id: string;
  slug: OrganizationUserRoleSlugs;
  displayName: string;
  description: string;
};

export type RoleOption = {
  label: string;
  value: OrganizationUserRoleSlugs;
  description: string;
};

export type UserOrganization = {
  employeeId?: string;
  roles: OrganizationUserRoles;
  permissions: PermissionEnum[];
};

type CallerIdentity = {
  id: string;
  email: string;
};

export type User = CallerIdentity & {
  auth0Id?: string;
};

export type CallerIdentityWithOrganizations = CallerIdentity & {
  organizations: {
    [key: string]: UserOrganization;
  };
};

export enum ElectricPermission {
  ORGANIZATION_CREATE = 'organizations:create',
  APPLICATION_CREATE = 'applications:create',
  APPLICATION_READ = 'applications:read',
  APPLICATION_UPDATE = 'applications:update',
  APPLICATION_DELETE = 'applications:delete',
  ORDERS_READ = 'orders:read',
  ORDERS_ISSUE_REFUND = 'orders:issue_refund',
  PRODUCTS_READ = 'products:read',
  PRODUCTS_UPDATE = 'products:update',
}

export type LoggedUser = {
  id?: string;
  name: string;
  email: string;
  accessToken?: string;
  employeeId?: string;
  employeeFirstName?: string;
  employeeLastName?: string;
  employeeJobTitle?: string;
  organizationId?: string;
  organizationInternalType?: InternalType;
  organizationName?: string;
  organizationPlanName?: PackageSlug;
  organizationLicenseCount?: number | null;
  organizationEntitlementSlugs?: Slug[];
  organizationSalesforceId?: string;
  organizationUserRoles?: OrganizationUserRoles;
  organizationHrisIntegration?: HRISIntegration;
  allUserOrganizationRoles?: CallerIdentityWithOrganizations['organizations'];
  electricPermissions?: ElectricPermission[];
  adp_sso_login?: boolean;
};

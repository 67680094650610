import { Chip, ColorsV2, Flex, Text, useThemeColors } from '@electricjs/arc';

const InternalChip = () => {
  const [infoBackgroundColor, infoTextColor] = useThemeColors([
    ColorsV2.INFO_LIGHTEST,
    ColorsV2.INFO_DARKEST,
  ]);

  return (
    <Chip
      variant="small"
      id="integration-beta-chip"
      backgroundColor={infoBackgroundColor}>
      <Flex vAlignContent="center" gap="1rem">
        <Text variant="legal" color={infoTextColor}>
          Internal
        </Text>
      </Flex>
    </Chip>
  );
};

export default InternalChip;

import { useEffect } from 'react';

import { useGetApplicationQuery } from '@/redux/slices/applicationApiSlice';
import {
  useGetEmployeeQuery,
  useGetOrganizationGroupsForEmployeeQuery,
} from '@/redux/slices/employeeApiSlice';
import {
  useGetRequestByIdQuery,
  useGetRequestTypeByIdQuery,
  useListAllSystemRequestersQuery,
} from '@/redux/slices/requestApiSlice';
import { useAppSelector } from '@/redux/store';
import { Application } from '@/types/applications';
import { Employee } from '@/types/employees';
import { Group } from '@/types/groups';
import { Request } from '@/types/requests';

export type ApplicationRequestData = Request & {
  onBehalfOfEmployee?: Employee;
  employeeGroups?: Group[];
  application?: Application;
};

type UseGetNotificationDataProps = {
  requestId: string | null;
};

const logError = (message: string) => {
  window?.DD_RUM?.addError(message, {
    location: 'Get request data',
  });
};

export const useGetRequestData = ({
  requestId,
}: UseGetNotificationDataProps) => {
  const organizationId = useAppSelector(
    state => state.loggedUser?.organizationId
  );

  // Get request
  const {
    data: request,
    isLoading: loadingRequest,
    error: requestError,
  } = useGetRequestByIdQuery(
    {
      organizationId: organizationId ?? '',
      requestId: requestId ?? '',
    },
    {
      skip: !organizationId || !requestId,
    }
  );

  useEffect(() => {
    if (requestError) {
      logError(
        `Error fetching request with requestId: ${requestId} and organizationId: ${organizationId}. Error: ${requestError}`
      );
    }
  }, [organizationId, requestError, requestId]);

  // Get request type
  const requestTypeId = request?.requestTypeId ?? null;

  const {
    data: requestType,
    isLoading: loadingRequestType,
    error: requestTypeError,
  } = useGetRequestTypeByIdQuery(
    {
      requestTypeId: requestTypeId ?? '',
    },
    {
      skip: !organizationId || !requestTypeId,
    }
  );

  useEffect(() => {
    if (requestTypeError) {
      logError(
        `Error fetching requestType with requestTypeId: ${requestTypeId}. Error: ${requestTypeError}`
      );
    }
  }, [organizationId, requestTypeError, requestTypeId]);

  // Get application
  const applicationKey = request?.data?.applicationKey ?? null;
  const {
    data: application,
    isLoading: loadingApp,
    error: applicationError,
  } = useGetApplicationQuery(
    {
      applicationKey: applicationKey ?? '',
    },
    { skip: !applicationKey }
  );

  useEffect(() => {
    if (applicationError) {
      logError(
        `Error fetching application with applicationKey: ${applicationKey} and organizationId: ${organizationId}. Error: ${applicationError}`
      );
    }
  }, [applicationError, applicationKey, organizationId]);

  // Get employee
  const employeeId = request?.onBehalfOf ?? null;

  const {
    data: employee,
    isLoading: loadingEmployee,
    error: employeeError,
  } = useGetEmployeeQuery(
    { organizationId: organizationId ?? '', employeeId: employeeId ?? '' },
    {
      skip: !employeeId || !organizationId,
    }
  );

  useEffect(() => {
    if (employeeError) {
      logError(
        `Error fetching employee with employeeId: ${employeeId} and organizationId: ${organizationId}. Error: ${employeeError}`
      );
    }
  }, [employeeError, employeeId, organizationId]);

  // Get employee groups

  const {
    data: employeeGroups,
    isLoading: loadingGroups,
    error: groupsError,
  } = useGetOrganizationGroupsForEmployeeQuery(
    {
      employeeId: employeeId ?? '',
      organizationId: organizationId ?? '',
    },
    {
      skip: !employeeId || !organizationId,
    }
  );

  useEffect(() => {
    if (groupsError) {
      logError(
        `Error fetching employee with employeeId: ${employeeId} and organizationId: ${organizationId}. Error: ${employeeError}`
      );
    }
  }, [employeeError, employeeId, groupsError, organizationId]);

  // Get system requester
  const systemRequesterId = request?.systemRequesterId;

  const { data: systemRequesters, isFetching: fetchingSystemRequesters } =
    useListAllSystemRequestersQuery();

  const systemRequester = systemRequesters?.find(
    sr => sr.id === systemRequesterId
  );

  return {
    data: {
      request,
      requestType,
      application,
      employee,
      employeeGroups,
      systemRequester,
    },
    isLoading:
      loadingRequest ||
      loadingRequestType ||
      loadingApp ||
      loadingEmployee ||
      loadingGroups ||
      fetchingSystemRequesters,
    requestError,
  };
};

import { Flex, Icon, Text, getToken, ColorsV2 } from '@electricjs/arc';
import { FilterDropdown } from '@common';
import styled from 'styled-components';
import { FilterOption } from 'common/TableFilters/types';

type SelectFilterProps<T> = {
  filterName: string;
  selectedValues: T[];
  options: FilterOption<T>[];
  handleAddFilter: (value: T) => void;
};

const StyledFilterOption = styled(Flex)<{ disabled?: boolean }>`
  :hover {
    background-color: ${props =>
      props.disabled ? 'inherit' : getToken(ColorsV2.PRIMARY_LIGHTEST)};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  }
  opacity: ${props => (props.disabled ? 0.5 : 1)};
`;

const SelectFilter = <T extends string>({
  filterName,
  selectedValues = [],
  options,
  handleAddFilter,
}: SelectFilterProps<T>) => {
  const someOptionIsChecked = selectedValues.length > 0;

  return (
    <Flex mt="0.5rem">
      <FilterDropdown
        someOptionIsChecked={someOptionIsChecked}
        dropdownContent={options.map(option => {
          const showIcon = selectedValues.includes(option.value);
          return (
            <StyledFilterOption
              id={`filter-${option.id}`}
              key={option.id}
              onClick={() => {
                if (!option.disabled) {
                  handleAddFilter(option.value);
                }
              }}
              disabled={option.disabled}
              p="0.7rem 1.2rem"
              alignItems="center">
              {showIcon && <Icon icon="check" intent="primary" size="small" />}
              <Text
                variant="body-2"
                pl={someOptionIsChecked && !showIcon ? '2.6rem' : '1rem'}>
                {option.text}
              </Text>
            </StyledFilterOption>
          );
        })}
        dropdownContentProps={{ padding: '0' }}
        filterName={filterName}
        size="small"
      />
    </Flex>
  );
};

export default SelectFilter;

export const getDiskUsage = ({
  capacity,
  available,
  usagePercent,
}: {
  capacity: number | undefined;
  available: number | undefined;
  usagePercent: number | undefined;
}) => {
  if (capacity && available) {
    const used = capacity - available;
    const percent = Math.floor(usagePercent ?? (used / capacity) * 100);

    return `${percent}% (${Math.floor(used)} / ${Math.floor(capacity)} GB)`;
  }

  return usagePercent ? `${usagePercent}%` : 'N/A';
};

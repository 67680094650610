import styled from 'styled-components';

import {
  ModalAction,
  ModalBody,
  ModalFooter,
  ModalFooterActions,
  ModalHeader,
  ModalV2,
  Text,
} from '@electricjs/arc';

import { Employee } from '@/types/employees';

import EmployeeCheckboxList from '@/components/DeviceOverview/EmployeeCheckboxList';

const StyledModalBody = styled(ModalBody)`
  overflow-y: scroll;
`;

type MDMInviteModalProps = {
  visible: boolean;
  hide: () => void;
  employees: Employee[];
  selectedEmployees: Employee[];
  setSelectedEmployees: React.Dispatch<React.SetStateAction<Employee[]>>;
  isLoading: boolean;
  handleCancel: () => void;
  handleSubmit: () => void;
};

const MDMInviteModal = ({
  visible,
  hide,
  employees,
  selectedEmployees,
  setSelectedEmployees,
  isLoading,
  handleCancel,
  handleSubmit,
}: MDMInviteModalProps) => {
  return (
    <ModalV2 visible={visible} hide={hide} ariaLabelledby="org-mdm-invite">
      <ModalHeader>
        <Text variant="heading-2">Send Employees Enrollment Instructions</Text>
      </ModalHeader>
      <StyledModalBody>
        <Text>
          Electric can send email instructions to your employees on how to
          enroll in MDM through the Electric desktop application. Employees are
          responsible for enrolling in MDM so that you can enforce essential
          security policies on their devices.
        </Text>
        <EmployeeCheckboxList
          employees={employees}
          selectedEmployees={selectedEmployees}
          setSelectedEmployees={setSelectedEmployees}
        />
      </StyledModalBody>
      <ModalFooter>
        <ModalFooterActions>
          <ModalAction
            id="org-mdm-invite-invite-modal-cancel"
            variant="text"
            disabled={isLoading}
            onClick={handleCancel}>
            Cancel
          </ModalAction>
          <ModalAction
            id="org-mdm-invite-invite-modal-submit"
            loading={isLoading}
            disabled={selectedEmployees.length === 0}
            onClick={handleSubmit}>
            Preview email
          </ModalAction>
        </ModalFooterActions>
      </ModalFooter>
    </ModalV2>
  );
};

export default MDMInviteModal;

import { AssetHistory, AssetLogDeviceIssue } from '@/types/assets';
import { EmployeeColumn } from '@/components/DeviceOverview/DeviceHealthMonitor/ColumnsAndContainers/EmployeeColumn';
import { AssetLogRowContainer } from '@/components/DeviceOverview/DeviceHealthMonitor/ColumnsAndContainers/AssetLogRowContainer';
import { IssueTypeDescriptionColumnMap } from '@/components/DeviceOverview/DeviceHealthMonitor/Resolved/DescriptionColumnMap';
import { isDeviceIssueType } from '@/components/DeviceOverview/DeviceHealthMonitor/types';
import { EmployeeStatus } from '@/types/employees';
import { useGetEmployeesWithAssets } from '@/hooks/useGetEmployeesWithAssets';

// Function to check if an issue type is supported
// This function checks if the issueType is a valid DeviceIssueType and if it exists in IssueTypeDescriptionColumnMap
export const isSupportedType = (issueType?: string): boolean =>
  !!issueType &&
  isDeviceIssueType(issueType) &&
  !!IssueTypeDescriptionColumnMap[issueType];

export const ResolvedRow = ({
  data,
}: Pick<AssetHistory<AssetLogDeviceIssue>, 'data'>) => {
  const {
    employee_id: employeeId,
    issue_type: issueType,
    device_id: deviceId,
  } = data;

  const { employeesWithAssets, isFetching } = useGetEmployeesWithAssets({
    devicesOnly: true,
    listEmployeesQueryProps: {
      status: [EmployeeStatus.Active, EmployeeStatus.Inactive],
    },
  });

  if (isFetching || !employeesWithAssets) {
    return <AssetLogRowContainer isLoading />;
  }

  const employee = employeesWithAssets.find(e => e.id === employeeId);

  if (!isSupportedType(issueType)) {
    return null;
  }

  const hasMultipleDevices =
    employee && employee.assets && employee.assets?.length > 1;

  const serialNumber = hasMultipleDevices
    ? employee.assets?.find(asset => asset.device?.id === deviceId)
        ?.serialNumber
    : '';

  return (
    <AssetLogRowContainer>
      <EmployeeColumn employee={employee} />
      {isDeviceIssueType(issueType) &&
        IssueTypeDescriptionColumnMap[issueType]?.(serialNumber)}
    </AssetLogRowContainer>
  );
};

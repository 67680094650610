import {
  AutomationStatusEnum,
  RequestListParamsOrderBy,
  RequestStatusEnum,
  SortOrder,
} from '@electricjs/core_entity-client';

import { PaginationParams } from './common';
import { Employee } from './employees';
import { Group } from './groups';
import { Organization } from './organizations';

export enum RequestTypeSlugEnum {
  APPLICATIONS_OTHER = 'applications_other',
  BROKEN_OR_DAMAGED_HARDWARE = 'broken_or_damaged_hardware',
  CHANGE_ACCESS_OR_PERMISSIONS = 'change_access_or_permissions',
  CONFERENCE_OR_VIDEO_EQUIPMENT = 'conference_or_video_equipment',
  CONFIGURE_APPLICATION_SETTINGS = 'configure_application_settings',
  CONNECTIVITY_OTHER = 'connectivity_other',
  DEVICE_LOGIN_ISSUE = 'device_login_issue',
  DEVICE_OTHER = 'device_other',
  DOCUMENTATION = 'documentation',
  DRIVE_SPACE_ISSUE = 'drive_space_issue',
  FILE_OR_FOLDER_ISSUE = 'file_or_folder_issue',
  GET_ACCESS_OR_LICENSE = 'get_access_or_license',
  HARDWARE_PURCHASE = 'purchase',
  HRIS_OFFBOARDING = 'hris_offboarding',
  HRIS_ONBOARDING = 'hris_onboarding',
  INSTALLION_OR_SOFTWARE_UPDATE_ISSUE = 'installation_or_software_update_issue',
  INTERNET = 'internet',
  LOGIN_ISSUE = 'applications_login_issue',
  NETWORK_EQUIPMENT = 'network_equipment',
  OFFBOARDING_REMOVE_ACCOUNT = 'offboarding_remove_account',
  ONBOARDING_CREATE_ACCOUNT = 'onboarding_create_account',
  PERMISSIONS_ISSUE = 'permissions_issue',
  PRINTER = 'printer',
  REMOVE_ACCESS_OR_LICENSE = 'remove_access_or_license',
  SECURITY_CONCERN_OR_QUESTION = 'security_concern_or_question',
  SERVER_OR_FILE_SHARES = 'server_or_file_shares',
  SETUP_OR_PROVISIONING = 'setup_or_provisioning',
  SHIPPING_OR_RETURN = 'shipping_or_return',
  SLOW_DEVICE = 'slow_device',
  SOMETHING_ELSE_OTHER = 'something_else_other',
  SYSTEM_OR_SETTINGS_ISSUE = 'system_or_settings_issue',
  UPDATE_SETTINGS_OR_OS = 'update_settings_or_os',
  VIRUS_OR_SECURITY_ISSUE = 'virus_or_security_issue',
  VPN = 'vpn',
  XBOARDING_CLARIFICATIONS_OR_CORRECTIONS_ONLY = 'xboarding_clarifications_or_corrections_only',
  INTAKE_FORM_ISSUE = 'intake_form_issue',
  MDM = 'mdm',
}

export type ChampionResponse = {
  id: string;
  employeeId: string;
};

export type Champion = {
  id: string;
  employee?: Employee;
  group?: Group;
  organization: Organization;
};

export type RequestTypeCategory = {
  id: string;
  name: string;
  slug?: string;
  description: string;
  champions?: ChampionResponse[];
  serviceProviderId?: string;
  isSupportCenterTile: boolean;
};

export type ListRequestTypeCategoriesResponse = {
  requestTypeCategories: RequestTypeCategory[];
  total: number;
};

export type Request = {
  id: string;
  assignedTo?: string;
  requestTypeId: string;
  organizationId: string;
  requesterUserId?: string;
  systemRequesterId?: string;
  status: RequestStatusEnum;
  automationStatus: AutomationStatusEnum;
  data: { [key: string]: string } & RequestData;
  requestCollectionId?: string;
  notBefore?: string;
  onBehalfOf?: string;
  resolvedAt?: string;
  resolvedByUserId?: string;
  createdAt?: string;
};

export type RequestData = {
  applicationKey?: string;
  application?: {
    icon: string;
    id: string;
    name: string;
  };
  companyOwnedDevice?: boolean;
  description?: string;
  impact?: string;
  issue?: {
    id: string;
    isInternal: boolean;
    name: string;
    requestTypeCategoryId: string;
    slug: string;
  };
  onBehalfOf?: {
    email: string;
    firstName: string;
    id: string;
    lastName: string;
    userId: string;
  };
  reason?: string;
  troubleshooting?: string;
  urgency?: {
    label: string;
    priority: number;
    value: string;
  };
  expiresAt?: string;
};

export type ListRequestsArgs = {
  assignedTo?: string;
  limit?: number;
  offset?: number;
  orderBy?: RequestListParamsOrderBy;
  organizationId: string;
  sortOrder?: SortOrder;
  status?: RequestStatus;
  categorySlug?: string;
};

export type ListRequestsResponse = {
  results: Request[];
  total: number;
};

export type GetRequestArgs = {
  organizationId: string;
  requestId: string;
};

export type RequestCreationArgs = {
  requestTypeId: string;
  organizationId: string;
  requesterUserId: string;
  status: `${RequestStatus}`;
  data: { [key: string]: string | object };
  requestCollectionId?: string;
  notBefore?: string;
  onBehalfOf?: string;
};

export type RequestType = {
  id: string;
  name: string;
  slug: string;
  requestTypeCategoryId: string;
  isInternal: boolean;
};

export type RequestTypeListArgs = {
  requestTypeCategoryId?: string;
} & PaginationParams;

export type GetRequestTypeArgs = {
  requestTypeId: string;
};

export type UpdateRequestArgs = {
  organizationId: string;
  requestId: string;
  status: RequestStatusEnum;
  resolvedAt: string;
  resolvedByUserId: string;
};

export type RequestCollection = {
  id: string;
  createdAt?: string;
  completedAt?: string;
};

export type RequestTypeCategoryChampionCreationArgs = {
  requestTypeCategoryId: string;
  organizationId: string;
  employeeId: string;
};

export type RequestTypeCategoryChampionDeletionArgs = {
  requestTypeCategoryId: string;
  organizationId: string;
  championId: string;
};

export type GetRequestTypeCategoryChampionArgs = {
  requestTypeCategoryId: string;
  organizationId: string;
};

export type GetRequestTypeCategoryByIdArgs = {
  requestTypeCategoryId: string;
  organizationId: string;
};

export type ISVSupportRequestPayload = {
  organizationId: string;
  heading: string;
  message: string;
  vendorEmail: string;
  vendorName: string;
};

export type RequestTypeSetting = {
  id: string;
  name: string;
  displayName: string;
  type: string;
  required: boolean;
  allowsDefault: boolean;
  requestTypeId: string;
  parentRequestTypeSettingId?: string | null;
  parentSettingValue?: string | null;
  prompt?: string | null;
  defaultValue?: string | null;
  options?: string | null;
  optionsUrl?: string | null;
  orderValue?: number | null;
};

export enum RequestStatus {
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Canceled = 'CANCELED',
}

export type RequestStatusExtended = `${RequestStatus}` | 'OVERDUE';

export type SystemRequester = {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
};

import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { apiSlice } from './slices/apiSlice';
import { deviceReportingSlice } from './slices/deviceReportingApiSlice';
import loggedUserSlice from './slices/loggedUserSlice';
import { mdmManagementSlice } from './slices/mdmManagementApiSlice';
import yourItPlanSlice from './slices/itPlanSlice';

// Create the store and automatically add "thunk middleware" and set Redux DevTools
export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    [loggedUserSlice.name]: loggedUserSlice.reducer,
    [deviceReportingSlice.reducerPath]: deviceReportingSlice.reducer,
    [mdmManagementSlice.reducerPath]: mdmManagementSlice.reducer,
    [yourItPlanSlice.name]: yourItPlanSlice.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(
      apiSlice.middleware,
      deviceReportingSlice.middleware,
      mdmManagementSlice.middleware
    ),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

// Use throughout the app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

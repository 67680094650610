import { apiSlice } from '@/redux/slices/apiSlice';
import {
  ChatCompletionArgs,
  ChatCompletionResponse,
  ChatVotePayload,
  OpenAIChatRoles,
} from '@/types/aiChat';

export const aiChatApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    chatCompletion: builder.mutation<
      ChatCompletionResponse,
      ChatCompletionArgs
    >({
      query: ({ organizationId, currentQuestion, deviceInfo, history }) => ({
        url: `/v1/organizations/${organizationId}/chat`,
        responseHandler: async response => {
          const data = await response.json();
          return {
            chat: [
              {
                role: OpenAIChatRoles.User,
                content: currentQuestion,
              },
              {
                role: OpenAIChatRoles.Assistant,
                content: data.answer,
              },
            ],
            id: data.id,
          };
        },
        method: 'POST',
        body: {
          current_question: currentQuestion,
          device_info: deviceInfo,
          history,
        },
      }),
    }),
    chatVote: builder.mutation<void, ChatVotePayload>({
      query: ({ organizationId, id, like }) => ({
        url: `/v1/organizations/${organizationId}/chat/${id}`,
        responseHandler: async response => {
          const data = await response.json();
          return data;
        },
        method: 'PUT',
        body: { like },
      }),
    }),
  }),
});

export const { useChatCompletionMutation, useChatVoteMutation } =
  aiChatApiSlice;

/**
 * The type of `error` is `BaseQueryError<BaseQueryFn>`, which is unknown, so we need to check if it has the `status` property (which is always will) to convince TypeScript that it is a `FetchBaseQueryError`.
 */
function isFetchQueryError(
  error: unknown
): error is { status: string | number } {
  return typeof error === 'object' && error !== null && 'status' in error;
}

/**
 * Retry every request, but only if the status code is 500 or higher, or if the status is FETCH_ERROR or TIMEOUT_ERROR
 * Documentation: https://github.com/reduxjs/redux-toolkit/blob/master/packages/toolkit/src/query/retry.ts
 * Error definitions: https://github.com/reduxjs/redux-toolkit/blob/master/packages/toolkit/src/query/fetchBaseQuery.ts
 */
const retryCondition = (
  error: unknown,
  _args: unknown,
  extraArgs: {
    attempt: number;
  }
): boolean => {
  const errorShouldRetry =
    isFetchQueryError(error) &&
    typeof error.status === 'number' &&
    (error.status === 429 ||
      error.status === 502 ||
      error.status === 503 ||
      error.status === 504);
  // For now, we don't want to retry FETCH_ERROR because it could actually be that the service isn't returning the correct headers (e.g. CORS) to allow RTK to parse the data for the status code. We'll test with just specific status codes and TIMEOUT_ERROR for now.
  // const errorIsFetchError = error.status === 'FETCH_ERROR';
  const errorIsTimeoutError =
    isFetchQueryError(error) && error.status === 'TIMEOUT_ERROR';
  return (
    // Only retry 3 times
    extraArgs.attempt <= 3 && (errorShouldRetry || errorIsTimeoutError)
  );
};

export default retryCondition;

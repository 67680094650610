import { Auth0ContextInterface, User as Auth0User } from '@auth0/auth0-react';
import { useEffect } from 'react';

import { loggedIn } from '@/redux/slices/loggedUserSlice';
import { useAppDispatch } from '@/redux/store';

type StoreLoggedUserProps = {
  auth0UserAuthenticated: boolean;
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'];
  auth0User?: Auth0User;
  loginWithRedirect: Auth0ContextInterface['loginWithRedirect'];
};

export const useStoreLoggedUser = ({
  auth0UserAuthenticated,
  getAccessTokenSilently,
  auth0User,
  loginWithRedirect,
}: StoreLoggedUserProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const saveLoggedUser = async () => {
      if (auth0UserAuthenticated) {
        try {
          const accessToken = await getAccessTokenSilently();
          dispatch(
            loggedIn({
              auth0User, // auth0User contains the JWT decoded data
              accessToken,
            })
          );
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          window?.DD_RUM?.addError(e, {
            location: 'getAccessTokenSilently',
          });
          // If for some reason Auth0 throws a invalid_grant error, logout the user
          if (
            e.error === 'missing_refresh_token' ||
            e.error === 'invalid_grant'
          ) {
            loginWithRedirect({
              appState: {
                returnTo: `${window.location.pathname}${window.location.search}`,
              },
            });
          }
        }
      }
    };

    saveLoggedUser();
  }, [
    auth0UserAuthenticated,
    auth0User,
    dispatch,
    getAccessTokenSilently,
    loginWithRedirect,
  ]);
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import camelCaseKeys from 'camelcase-keys';

export const camelize = (thingToCamelize: any) =>
  camelCaseKeys(thingToCamelize, {
    deep: true,
    preserveConsecutiveUppercase: true,
  });

const pipe =
  (...functions: Array<(responseData: any) => any>) =>
  (value: any) =>
    functions.reduce((currValue, currFunc) => currFunc(currValue), value);

const responseMiddlewares = {
  dataCamelizer: (responseData: any) => camelize(responseData),
  // TODO: Remove this middleware when the console.log is not needed anymore
  consoleLogger: (responseData: any) => {
    console.log(responseData);
    return responseData;
  },
};

const responseCamelizer = (responseData: any) =>
  pipe(
    responseMiddlewares['dataCamelizer'],
    // TODO: Remove this middleware when the console.log is not needed anymore
    responseMiddlewares['consoleLogger']
  )(responseData);

export default responseCamelizer;

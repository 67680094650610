import {
  Button,
  ColorsV2,
  Text,
  usePopoverContext,
  useThemeColors,
} from '@electricjs/arc';
import { useNavigate } from 'react-router-dom';

type SecondaryActionProps = {
  isDropDownMenu: boolean;
  requestId?: string;
  loading: boolean;
};

export const SecondaryAction = ({
  isDropDownMenu,
  requestId,
  loading,
}: SecondaryActionProps) => {
  const navigate = useNavigate();
  const { toggle } = usePopoverContext();
  const textColor = useThemeColors(ColorsV2.PRIMARY);

  if (!requestId) {
    return null;
  }

  const handleViewMore = () => {
    if (isDropDownMenu) {
      toggle();
    }

    setTimeout(() => {
      navigate(`/notification-center?requestId=${requestId}`);
    }, 300);
  };

  return (
    <Button
      id={`button-view-${isDropDownMenu ? 'more' : 'request'}`}
      alignSelf="center"
      variant="text"
      showSkeleton={loading}
      onClick={handleViewMore}>
      <Text variant="legal" color={textColor}>
        View {isDropDownMenu ? 'more' : 'request'}
      </Text>
    </Button>
  );
};

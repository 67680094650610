import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Flex, Button } from '@electricjs/arc';
import { ABMEnrollmentStatusEnum } from '@electricjs/core_entity-client';

import { OrderType } from '@/types/orders';
import { StorefrontSteps } from '@/types/storefront';
import { useGetOrganizationAdditionalInfoQuery } from '@/redux/slices/organizationApiSlice';
import { usePlaceOrderMutation } from '@/redux/slices/orderApiSlice';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useGlobalUI } from '@/components/GlobalUIProvider';

import OrderSummary from './Summary/OrderSummary';
import CartSummary from './Summary/CartSummary';
import QuickActions from './Summary/QuickActions';
import { useStorefront } from '../StorefrontProvider';
import { hasAnyLaptopBrandEntry } from '../utils';
import OrderSubmitModal from './OrderSubmitModal';

const SummaryContainer = styled(Flex)`
  z-index: 999;
  top: 0;
  padding-top: 2rem;
  row-gap: 2rem;
`;

type RightPanelProps = {
  hasBillingInfo?: boolean;
};

const RightPanel = ({ hasBillingInfo }: RightPanelProps) => {
  const {
    storefrontStep,
    setStorefrontStep,
    itemsInCart,
    assetCart,
    catalogCart,
    hasOnlyAssetsInCart,
    cartRequiresShippingAddress,
    storefrontCheckoutForm,
    orderKey,
    regenerateOrderKey,
  } = useStorefront();

  const organizationId = useGetOrganizationId();

  const { data: orgAdditionalInfoData } = useGetOrganizationAdditionalInfoQuery(
    { organizationId }
  );

  const [isAbmStatusUnknown, setIsAbmStatusUnknown] = useState(false);

  const [placeOrder] = usePlaceOrderMutation(); // loading and errors are handled by the function and its promise

  const { openModal, closeModal, showErrorToast } = useGlobalUI();

  const isSticky = storefrontStep === StorefrontSteps.CART;

  const handleCartPageContinueButton = () => {
    if (!cartRequiresShippingAddress) {
      openModal(<OrderSubmitModal />); // open the "loading" modal

      // place the "asset only" order
      placeOrder({
        orderType: OrderType.AssetOnly,
        orderIdempotentKey: orderKey,
        organizationId,
        selectedEmployeeId:
          storefrontCheckoutForm?.getValues('employee')?.value ?? '',
        selectedAssets: assetCart,
      })
        .unwrap()
        .then(() => {
          setStorefrontStep(StorefrontSteps.ORDER_PLACED); // go to confirmation page
          closeModal();
        })
        .catch(error => {
          console.log('Error submitting order', error);
          const errorMessage =
            error?.data?.detail && typeof error?.data?.detail === 'string'
              ? error?.data?.detail
              : 'Something went wrong. Please try again.';

          closeModal();
          showErrorToast({
            id: 'order-submit-error-toast',
            title: 'Error submitting order',
            message: errorMessage,
            hideAfter: 5000,
            stack: true,
          });
        })
        .finally(() => regenerateOrderKey());
    } else {
      setStorefrontStep(StorefrontSteps.CHECKOUT);
    }
  };

  useEffect(() => {
    switch (orgAdditionalInfoData?.abmStatus) {
      case ABMEnrollmentStatusEnum.Connected:
        setIsAbmStatusUnknown(false);
        break;
      case ABMEnrollmentStatusEnum.Unconnected:
        setIsAbmStatusUnknown(false);
        break;
      case ABMEnrollmentStatusEnum.NotApplied:
        setIsAbmStatusUnknown(false);
        break;
      case ABMEnrollmentStatusEnum.Unknown:
      default:
        setIsAbmStatusUnknown(true);
        break;
    }
  }, [orgAdditionalInfoData?.abmStatus]);

  return (
    <SummaryContainer vertical position={isSticky ? 'sticky' : 'relative'}>
      <OrderSummary />
      {storefrontStep !== StorefrontSteps.CART && <CartSummary />}
      {/* Checkout Button */}
      {storefrontStep === StorefrontSteps.CART && (
        <Button
          id="cart-page-continue-button"
          disabled={
            !itemsInCart ||
            (!hasBillingInfo && !hasOnlyAssetsInCart) ||
            (isAbmStatusUnknown &&
              hasAnyLaptopBrandEntry('apple', { catalogCart }))
          }
          width="100%"
          p="2rem"
          onClick={handleCartPageContinueButton}>
          {!cartRequiresShippingAddress
            ? 'Place order'
            : 'Continue to checkout'}
        </Button>
      )}
      {storefrontStep === StorefrontSteps.ORDER_PLACED && <QuickActions />}
    </SummaryContainer>
  );
};

export default RightPanel;

import {
  CallerIdentity as CallerIdentityDto,
  PermissionEnum,
  User as UserDto,
} from '@electricjs/core_entity-client';
import { Auth0User, Auth0UserKey } from '@externalTypes/auth0';

import { simpleEmployee } from '@/mocks/data/employeeData';
import { simpleOrganization } from '@/mocks/data/organizationData';
import { OrganizationUserRoleSlugs } from '@/types/users';

export const simpleUser: UserDto = {
  id: 'simple-user-id',
  email: 'simple-user@simple-org.com',
  auth0_id: 'simple-auth0-id',
};

export const simpleCallerIdentity: CallerIdentityDto = {
  id: simpleUser.id,
  email: simpleUser.email,
  organizations: {
    ['simple-org-id']: {
      employee_id: 'simple-employee-id',
      roles: [
        {
          id: 'simple-role-id',
          name: 'simple-role-name',
          slug: OrganizationUserRoleSlugs.HUB_USER,
        },
        {
          id: 'simple-role-id',
          name: 'simple-role-name',
          slug: OrganizationUserRoleSlugs.SUPER_ADMIN,
        },
      ],
      permissions: [
        PermissionEnum.Applicationscreate,
        PermissionEnum.Employeesread,
      ],
      hris_integrations: [
        {
          id: '6695cb87-899d-4d3d-8a44-b872b01872e6',
          hris_provider: {
            id: '6695cb87-899d-4d3d-8a44-b872b01872e6',
            name: 'BambooHR',
            slug: 'bamboohr',
          },
        },
      ],
    },
  },
};

// This auth0SimpleUser was primarily created to simulate a user on unit/integration tests
export const auth0SimpleUser: Auth0User = {
  email: simpleCallerIdentity.email,
  email_verified: true,
  name: `${simpleEmployee.first_name} ${simpleEmployee.last_name}`,
  nickname: `${simpleEmployee.first_name}.${simpleEmployee.last_name}`,
  [Auth0UserKey.IS_USER]: true,
  picture: '',
  [Auth0UserKey.ELECTRIC_PERMISSIONS]: ['applications:create'],
  [Auth0UserKey.ORGANIZATIONS]: [simpleOrganization.id],
  [`${Auth0UserKey.ORGANIZATIONS}/${simpleOrganization.id}/employee_id`]:
    simpleEmployee.id,
};

import { useDeleteApplicationIntegrationMutation } from '@/redux/slices/integrationsApiSlice';
import {
  ModalAction,
  ModalBody,
  ModalFooter,
  ModalFooterActions,
  ModalHeader,
  ModalV2,
  Text,
} from '@electricjs/arc';
import { useGlobalUI } from '@/components/GlobalUIProvider';
import useIsProdSupport from '@/hooks/useIsProdSupport';

type DisconnectIntegrationModal = {
  organizationId: string;
  applicationKey: string;
  onClose: () => void;
};

export const DisconnectIntegrationModal = ({
  applicationKey,
  organizationId,
  onClose,
}: DisconnectIntegrationModal) => {
  const [deleteIntegration, { isLoading }] =
    useDeleteApplicationIntegrationMutation();
  const { showErrorToast, showSuccessToast, showToast } = useGlobalUI();
  const isProdSupport = useIsProdSupport();

  const handleDisconnectIntegration = () => {
    if (isProdSupport) {
      showToast({
        id: 'integration-disconnect-disabled-for-prod-support',
        message: 'This feature is disabled for product support.',
        intent: 'info',
        title: 'Disabled',
      });
      return;
    }

    deleteIntegration({
      applicationKey,
      organizationId,
    })
      .unwrap()
      .then(() => {
        showSuccessToast({
          id: 'disconnect-app-integration-success-toast',
          message:
            'The application integration has been successfully disconnected.',
        });

        onClose();
      })
      .catch(error => {
        window?.DD_RUM?.addError(
          `Failed to disconnect the application integration. Error details: ${error}`,
          {
            location: 'DisconnectIntegrationModal',
          }
        );
        showErrorToast({
          id: 'disconnect-app-integration-error-toast',
          message:
            'Failed to disconnect the application integration. Please try again.',
        });
      });
  };

  return (
    <ModalV2
      ariaLabelledby="action-confirmation-header-text"
      hide={onClose}
      visible>
      <ModalHeader py={1}>
        <Text variant="heading-2">Disconnect integration</Text>
      </ModalHeader>
      <ModalBody py={24}>
        <Text variant="body">
          Going forward, you will need to manually create and delete Google
          accounts for employees during onboarding and offboarding.
        </Text>
      </ModalBody>
      <ModalFooter py={16}>
        <ModalFooterActions>
          <ModalAction
            id="action-modal-cancel-button"
            variant="text"
            disabled={isLoading}
            onClick={onClose}>
            Cancel
          </ModalAction>

          <ModalAction
            id="action-modal-confirm-button"
            loading={isLoading}
            onClick={handleDisconnectIntegration}>
            Disconnect integration
          </ModalAction>
        </ModalFooterActions>
      </ModalFooter>
    </ModalV2>
  );
};

import AssetDetail from '@/components/Assets/AssetDetails/AssetDetail';
import {
  getWasElectricAppInstalledOnDevice,
  getWasMDMInstalledOnDevice,
} from '@/components/Assets/helpers/getEnrollment';
import { useOrganizationHasEntitlement } from '@/hooks/useOrganizationHasEntitlement';
import { Card, Flex, IconInCircle, Text, useBreakpoint } from '@electricjs/arc';
import { EntitlementSlugEnum } from '@electricjs/core_entity-client';
import { getOsFullName } from 'common/utils/getOsFullName';
import { getDiskUsage } from '@/pages/Assets/AssetDetailsContent/getDiskUsage';
import { DetailsContainer } from '@/components/Assets/AssetDetails/common';
import { AssetLastCheck } from '@/pages/Assets/AssetDetailsContent/AssetLastCheck';
import { DeviceAsset } from '@/types/assets';
import { DeviceIssueType } from '@/types/devices';

type DeviceDetailsCardProps = {
  deviceAsset: DeviceAsset;
  fullWidth?: boolean;
};

const DeviceDetailsCard = ({
  deviceAsset,
  fullWidth,
}: DeviceDetailsCardProps) => {
  const deviceReportingData = deviceAsset.device.reportingData ?? {};

  const { lg } = useBreakpoint();
  const hasMdmEntitlement = useOrganizationHasEntitlement(
    EntitlementSlugEnum.JumpcloudMdm
  );
  const hasElectricAppEntitlement = useOrganizationHasEntitlement(
    EntitlementSlugEnum.DesktopApp
  );

  // TODO: this was previously using a more complicated workaround to see if the device was associated with the employee: if a device in the employee's device list matches the asset device id, then it's associated. This is a simpler (and hopefully accurate?) way to check if the device is associated with an employee. It _does_ seem to change the result in staging though, so it's worth checking.
  const employeeAssociationExists = !!deviceAsset.assignedToEmployee;

  const isDisabled = (key: string) => {
    const issues = deviceAsset.device?.reportingData?.issues;
    const issue = issues ? issues[key] : undefined;
    return !!issue?.extra?.disabled;
  };

  return (
    <Card
      id="device-details-card"
      width="100%"
      headerDivider
      title={
        <Flex alignItems="center" gap="2rem">
          <IconInCircle icon="laptop" intent="info" />
          <Text variant="subheading">Device information</Text>
        </Flex>
      }>
      <Flex vertical={!(lg && fullWidth)} gap="2rem" mt="2.2rem" mb="1rem">
        <DetailsContainer>
          {hasElectricAppEntitlement && (
            <>
              <AssetDetail
                label="Operating System"
                disabled={isDisabled(DeviceIssueType.OS)}
                value={
                  getOsFullName({
                    osName: deviceReportingData?.osName,
                    osVersion: deviceReportingData?.osVersion,
                    osType: deviceAsset.device.osType,
                  }) ?? 'N/A'
                }
              />
              <AssetDetail
                label="Hard Drive Storage"
                disabled={isDisabled(DeviceIssueType.Storage)}
                value={
                  deviceReportingData?.storageCapacity
                    ? `${Math.floor(deviceReportingData?.storageCapacity)} GB`
                    : 'N/A'
                }
              />
              <AssetDetail
                label="RAM"
                disabled={isDisabled(DeviceIssueType.RAM)}
                value={
                  deviceReportingData?.ramCapacity
                    ? `${deviceReportingData?.ramCapacity} GB`
                    : 'N/A'
                }
              />
              <AssetDetail
                label="Disk Usage"
                disabled={isDisabled(DeviceIssueType.Storage)}
                value={getDiskUsage({
                  capacity: deviceReportingData?.storageCapacity,
                  available: deviceReportingData?.storageAvailable,
                  usagePercent: deviceReportingData?.storageUsagePercent,
                })}
              />
              <AssetDetail
                label="Disk Encryption"
                disabled={isDisabled(DeviceIssueType.Encryption)}
                value={
                  deviceReportingData?.encryptionPercent !== null &&
                  deviceReportingData?.encryptionPercent !== undefined
                    ? `${deviceReportingData?.encryptionPercent}%`
                    : 'N/A'
                }
              />
              <AssetDetail
                label="Electric App Installed"
                value={
                  deviceAsset
                    ? getWasElectricAppInstalledOnDevice(deviceAsset)
                      ? 'Yes'
                      : 'No'
                    : 'N/A'
                }
              />
              <AssetDetail
                label="Last Check"
                labelTooltip="This time is when the Electric app last scanned the device."
                value={
                  <AssetLastCheck
                    lastCheck={
                      deviceReportingData?.lastOsqueryReport ??
                      deviceReportingData?.lastOsqueryEnrollment // use the enrollment date if no report
                    }
                  />
                }
              />
            </>
          )}
          {hasMdmEntitlement && (
            <>
              <AssetDetail
                label="MDM Installed"
                value={
                  employeeAssociationExists
                    ? getWasMDMInstalledOnDevice(deviceAsset)
                      ? 'Yes'
                      : 'No'
                    : 'N/A'
                }
              />
            </>
          )}
        </DetailsContainer>
      </Flex>
    </Card>
  );
};

export default DeviceDetailsCard;

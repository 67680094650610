import electricLogo from '@/assets/electric_logo_red.svg';
import { NAV_HEIGHT } from '@/components/Nav/sizes';
import {
  ColorsV2,
  EmptyState,
  Flex,
  getToken,
  OneColumnLayout,
  Pane,
} from '@electricjs/arc';
import styled from 'styled-components';

const StyledTopNav = styled(Pane)`
  z-index: 2;
  height: ${NAV_HEIGHT};
  min-height: ${NAV_HEIGHT};
  padding: 0 2.5rem;
  border-radius: unset;
  flex-wrap: nowrap;
  align-items: center;

  justify-content: flex-start;

  background: ${getToken(ColorsV2.BACKGROUND)};
  border-bottom: solid 1px ${getToken(ColorsV2.GRAY_LIGHT)};

  img {
    height: 3rem;
  }
`;

const MaintenancePage = () => {
  return (
    <>
      <nav>
        <StyledTopNav>
          <img alt="Electric" height="42" src={electricLogo} />
        </StyledTopNav>
      </nav>
      <OneColumnLayout>
        <Flex
          height="calc(100vh - 12.5rem)"
          justifyContent="center"
          alignItems="center">
          <EmptyState
            title="Site under maintenance"
            subTitle="Sorry, the site is currently under a scheduled maintenance, but will be available again soon."
            icon="wrench"
          />
        </Flex>
      </OneColumnLayout>
    </>
  );
};

export default MaintenancePage;

import { InvoiceResponse } from '@/types/billing';

export const invoicesList: InvoiceResponse[] = [
  {
    id: 'a8c230000000RzdAAE',
    name: 'INV-2618',
    total_amount: 4580.88,
    invoice_date: '2023-10-23',
    content_documents: undefined,
    has_documents: false,
  },
  {
    id: 'a8c230000000S0nAAE',
    name: 'INV-2639',
    total_amount: 0,
    invoice_date: '2023-10-24',
    content_documents: undefined,
    has_documents: false,
  },
  {
    id: 'a8c230000000S3QAAU',
    name: 'INV-2698',
    total_amount: 0,
    invoice_date: '2024-07-01',
    content_documents: undefined,
    has_documents: false,
  },
  {
    id: 'a8c230000000S3hAAE',
    name: 'INV-2715',
    total_amount: 1717,
    invoice_date: '2024-07-01',
    content_documents: undefined,
    has_documents: true,
  },
  {
    id: 'a8c230000000S6YAAU',
    name: 'INV-2771',
    total_amount: 227.25,
    invoice_date: '2024-01-01',
    content_documents: undefined,
    has_documents: false,
  },
  {
    id: 'a8c230000000S7vAAE',
    name: 'INV-2773',
    total_amount: 0,
    invoice_date: '2023-11-01',
    content_documents: undefined,
    has_documents: false,
  },
];

import { Banner, Button, Flex } from '@electricjs/arc';

type ADPConsentRequiredBannerProps = {
  adpConsentRedirectURL: string;
};

const ADPConsentRequiredBanner = ({
  adpConsentRedirectURL,
}: ADPConsentRequiredBannerProps) => {
  return (
    <Flex m="3rem">
      <Banner
        id="adp-consent-banner"
        intent="warning"
        width="100%"
        title="ADP Consent Required"
        message={`Without your consent, we cannot access your employee data. Please provide consent so we can keep your employee information up-to-date.`}
        cta={
          <Button
            id="adp-consent-link"
            variant="text"
            intent="warning"
            onClick={() => (window.location.href = adpConsentRedirectURL)}>
            Provide consent now
          </Button>
        }
      />
    </Flex>
  );
};

export default ADPConsentRequiredBanner;

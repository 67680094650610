import { NotificationType } from '@electricjs/core_entity-client';

import { Box, ColorsV2, Text } from '@electricjs/arc';

import { useGetRequestData } from '@/hooks/useGetRequestData';
import { Employee } from '@/types/employees';
import { SystemRequester } from '@/types/requests';

import { EmailDetail } from '../EmailDetail';
import { GroupDetail } from '../GroupDetail';
import { NotificationItemHeader } from '../NotificationItemHeader';
import { RequestActions } from '../RequestActions/RequestActions';
import { NotificationItemProps } from '../types';

const getTitle = (employee?: Employee, systemRequester?: SystemRequester) => {
  const employeeName = employee
    ? `${employee.firstName} ${employee.lastName}`
    : 'Someone';

  const systemRequesterName = systemRequester?.name ?? 'your HRIS';

  return (
    <>
      <b>{employeeName}</b> has been <b>hired</b> in {systemRequesterName}
    </>
  );
};

const HRISOnboardingNotificationItem = ({
  notification,
  hideDetails,
}: NotificationItemProps) => {
  const { data: requestData, isLoading } = useGetRequestData({
    requestId: notification?.data?.request_id ?? null,
  });

  const employee = requestData?.employee;
  const employeeGroups = requestData?.employeeGroups;
  const employeeEmail = employee?.email;
  const systemRequester = requestData?.systemRequester;

  const getLabel = () => {
    switch (notification.type) {
      case NotificationType.RequestAssigned:
        return 'REQUEST ASSIGNED';

      case NotificationType.RequestCompleted:
        return 'REQUEST COMPLETED';
      default:
        return '';
    }
  };

  const getIcon = (text: string) => (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius="50%"
      backgroundColor={ColorsV2.GRAY_LIGHTER}
      width="4rem"
      height="4rem">
      <Text variant="legal" color={ColorsV2.TEXT}>
        {text}
      </Text>
    </Box>
  );

  return (
    <NotificationItemHeader
      notification={notification}
      isLoading={isLoading}
      title={getTitle(employee, systemRequester)}
      icon={getIcon(systemRequester?.name ?? 'HRIS')}
      label={getLabel()}>
      {!hideDetails ? (
        <>
          <GroupDetail isLoading={isLoading} groups={employeeGroups} />
          <EmailDetail isLoading={isLoading} email={employeeEmail} />
          <RequestActions
            loading={isLoading}
            request={requestData.request}
            requestType={requestData.requestType}
          />
        </>
      ) : null}
    </NotificationItemHeader>
  );
};

export default HRISOnboardingNotificationItem;

import styled from 'styled-components';

import { Flex, Text } from '@electricjs/arc';

import EmployeeAppsByGroup from '@/components/People/EmployeeAppsByGroup/EmployeeAppsByGroup';
import { useGetApplicationsForGroups } from '@/components/People/EmployeeAppsByGroup/useGetApplicationsForGroups';
import { Group } from '@/types/groups';

type OnboardEmployeeModalAppsProps = {
  orgHasNoGroups: boolean;
  employeeGroups: Group[];
  maxHeight?: string;
};

const TextCenteredDiv = styled.div`
  text-align: center;
  margin: 2rem;
`;

const Error = ({ title, message }: { title: string; message?: string }) => {
  const defaultMessage = (
    <>
      We use groups to simplify the onboarding process and to make sure your new
      employees have the access they need. Please select a group with at least
      one application to begin onboarding.
    </>
  );

  return (
    <TextCenteredDiv>
      <Text variant="heading-2" mb={2}>
        {title}
      </Text>
      <Text variant="body-2">{message ?? defaultMessage}</Text>
    </TextCenteredDiv>
  );
};

const OnboardEmployeeModalApps = ({
  orgHasNoGroups,
  employeeGroups,
  maxHeight = '18rem',
}: OnboardEmployeeModalAppsProps) => {
  const {
    data: appsByGroup,
    isLoading,
    isError,
  } = useGetApplicationsForGroups(employeeGroups);

  const appCount = (appsByGroup ?? []).reduce(
    (prev, curr) => prev + curr.applications.length,
    0
  );

  return (
    <Flex
      flexDirection="column"
      width="100%"
      maxHeight={maxHeight}
      overflow="auto">
      {orgHasNoGroups && <Error title="You don't have any groups set up yet" />}
      {!orgHasNoGroups && employeeGroups.length === 0 && (
        <Error title="Assign this employee to a group" />
      )}

      {!isLoading &&
        !isError &&
        employeeGroups.length > 0 &&
        appCount === 0 && (
          <Error
            title={`No applications found for ${
              employeeGroups.length > 1 ? 'these groups' : 'this group'
            }`}
          />
        )}

      {isError && (
        <Error
          title="There was an error loading applications for your groups."
          message=""
        />
      )}

      <EmployeeAppsByGroup
        employeeGroups={employeeGroups}
        isLoading={isLoading}
        appsByGroup={appsByGroup}
      />
    </Flex>
  );
};

export default OnboardEmployeeModalApps;

import { useEffect } from 'react';

import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import {
  useGetEmployeeQuery,
  useGetOrganizationGroupsForEmployeeQuery,
} from '@/redux/slices/employeeApiSlice';

type UseGetXboardingDataProps = {
  employeeId: string | null;
};

const logError = (message: string) => {
  window?.DD_RUM?.addError(message, {
    location: 'Get Xboarding data',
  });
};

export const useGetXboardingData = ({
  employeeId,
}: UseGetXboardingDataProps) => {
  const organizationId = useGetOrganizationId();
  const {
    data: employee,
    isLoading: loadingEmployee,
    error: employeeError,
  } = useGetEmployeeQuery(
    { organizationId: organizationId ?? '', employeeId: employeeId ?? '' },
    {
      skip: !employeeId || !organizationId,
    }
  );

  useEffect(() => {
    if (employeeError) {
      logError(
        `Error fetching employee with employeeId: ${employeeId} and organizationId: ${organizationId}. Error: ${employeeError}`
      );
    }
  }, [employeeError, employeeId, organizationId]);

  // Get employee groups

  const {
    data: employeeGroups,
    isLoading: loadingGroups,
    error: groupsError,
  } = useGetOrganizationGroupsForEmployeeQuery(
    {
      employeeId: employeeId ?? '',
      organizationId: organizationId ?? '',
    },
    {
      skip: !employeeId || !organizationId,
    }
  );

  useEffect(() => {
    if (groupsError) {
      logError(
        `Error fetching employee with employeeId: ${employeeId} and organizationId: ${organizationId}. Error: ${employeeError}`
      );
    }
  }, [employeeError, employeeId, groupsError, organizationId]);

  return {
    data: {
      employee,
      employeeGroups,
    },
    isXboardingLoading: loadingEmployee || loadingGroups,
  };
};

import {
  Chip,
  ColorsV2,
  Flex,
  Icon,
  Text,
  useThemeColors,
} from '@electricjs/arc';

type StatusChipProps = {
  id: string;
  text: string;
  variant: 'success' | 'warning' | 'error';
};

export const StatusChip = ({ id, text, variant }: StatusChipProps) => {
  const [
    successColorBg,
    successColorText,
    errorColorBg,
    errorColorText,
    warningColorBg,
    warningColorText,
  ] = useThemeColors([
    ColorsV2.SUCCESS_LIGHTEST,
    ColorsV2.SUCCESS_DARKEST,
    ColorsV2.ERROR_LIGHTEST,
    ColorsV2.ERROR_DARKEST,
    ColorsV2.WARNING_LIGHTEST,
    ColorsV2.WARNING_DARKEST,
  ]);

  switch (variant) {
    case 'success':
      return (
        <Chip id={id} backgroundColor={successColorBg} variant="small">
          <Flex>
            <Icon size="small" icon="check-circle" color={successColorText} />
            <Text
              variant="legal"
              fontWeight="500"
              ml="0.3rem"
              color={successColorText}>
              {text}
            </Text>
          </Flex>
        </Chip>
      );
    case 'warning':
      return (
        <Chip id={id} backgroundColor={warningColorBg} variant="small">
          <Flex>
            <Icon size="small" icon="alert-triangle" color={warningColorText} />
            <Text
              variant="legal"
              fontWeight="500"
              ml="0.3rem"
              color={warningColorText}>
              {text}
            </Text>
          </Flex>
        </Chip>
      );
    case 'error':
      return (
        <Chip id={id} backgroundColor={errorColorBg} variant="small">
          <Flex>
            <Icon size="small" icon="alert-circle" color={errorColorText} />
            <Text
              variant="legal"
              fontWeight="500"
              ml="0.3rem"
              color={errorColorText}>
              {text}
            </Text>
          </Flex>
        </Chip>
      );
  }
};

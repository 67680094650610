import { OffboardingResponse as OffboardingDto } from '@electricjs/core_entity-client';

import { simpleEmployee } from './employeeData';
import { simpleRequestCollection } from './requestData';
import { simpleUser } from './userData';

export const simpleOffboarding: OffboardingDto = {
  id: '002df5c0-054f-11ee-be56-0242ac000006',
  employee: simpleEmployee,
  requester: simpleUser,
  request_collection: simpleRequestCollection,
  created_at: '2023-12-10T15:00:00.000Z',
  updated_at: '2023-12-10T15:00:00.000Z',
  scheduled_at: '2023-12-20T20:00:00.000Z',
};

export const simpleCompletedOffboarding: OffboardingDto = {
  id: '002df5c0-054f-11ee-be56-0242ac000008',
  employee: simpleEmployee,
  requester: simpleUser,
  request_collection: simpleRequestCollection,
  created_at: '2023-12-10T15:00:00.000Z',
  updated_at: '2023-12-10T15:00:00.000Z',
  scheduled_at: '2023-12-20T20:00:00.000Z',
  completed_at: simpleRequestCollection.completed_at,
};

export const mockedOffboardings: OffboardingDto[] = [];
export const simpleMockedOffboardings: OffboardingDto[] = [simpleOffboarding];

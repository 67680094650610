import { useAuth0 } from '@auth0/auth0-react';
import { Suspense } from 'react';

import { Auth0ErrorState } from '@/components/Errors/Auth0ErrorState';
import { InitFailedState } from '@/components/Errors/InitFailedState';
import PageSpinner from '@/components/PageSpinner';
import { useHandleAuthRedirect } from '@/hooks/initialization/useHandleAuthRedirect';
import { useRequestUserData } from '@/hooks/initialization/useRequestUserData';
import { useSetExternalServices } from '@/hooks/initialization/useSetExternalServices';
import { useStoreLoggedUser } from '@/hooks/initialization/useStoreLoggedUser';
import { loggedOut } from '@/redux/slices/loggedUserSlice';
import { useAppSelector } from '@/redux/store';
import { useAppDispatch } from '@/redux/store';
import ElectrolyteRoutes from '@/router/routes';

import { useRequestUserOrganization } from './hooks/initialization/useRequestUserOrganization';
import { useSetOrgFromStorage } from './hooks/initialization/useSetOrgFromStorage';
import { SessionStorageItems } from './types/sessionStorageItems';
import AccountLinking from './pages/AccountLinking/AccountLinking';
import { HandleAccountLinkArgs } from './pages/AccountLinking/auth0-link-user-types';
import { useGlobalUI } from '@/components/GlobalUIProvider';
import { useFlags } from 'launchdarkly-react-client-sdk';
import MaintenancePage from './pages/MaintenancePage';

const AuthenticatedApp = () => {
  const loggedUser = useAppSelector(state => state.loggedUser);
  const selectedOrgIdFromState = loggedUser?.organizationId;
  const selectedOrgRolesFromState = loggedUser?.organizationUserRoles;
  const selectedEmployeeIdFromState = loggedUser?.employeeId;

  const dispatch = useAppDispatch();
  const { showErrorToast, showSuccessToast } = useGlobalUI();

  const { itHubMaintenancePage } = useFlags();

  // auth0 hook (here is the only place in the app that handles auth0 directly)
  const {
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
    user: auth0User,
    isLoading: auth0UserLoading,
    isAuthenticated: auth0UserAuthenticated,
    error: auth0Error,
    getIdTokenClaims,
  } = useAuth0();

  // Handle unauthenticated user (redirect to Auth0's Login page)
  useHandleAuthRedirect({
    isInMaintenanceMode: itHubMaintenancePage,
    auth0UserLoading,
    auth0UserAuthenticated,
    loginWithRedirect,
    auth0Error,
  });

  // When user is authenticated, create a loggedUser in the store (with token and some auth0's data)
  useStoreLoggedUser({
    auth0UserAuthenticated,
    getAccessTokenSilently,
    auth0User,
    loginWithRedirect,
  });

  // Check for previously selected org before the app is initialized
  useSetOrgFromStorage({
    loggedUser,
    selectedOrgIdFromState,
  });

  // After loggedUser is created (login is done), request the user data.
  // This will skip setting the org info if it was set in the previous dispatch step,
  // Otherwise the app "auto-selects" the first org it finds.
  // The response data is automatically saved by the extraReducers.
  const {
    isRequestingUserData,
    userDataRequestCompleted,
    myUserDataError,
    isUnauthorizedError,
  } = useRequestUserData({
    auth0UserAuthenticated,
    auth0User,
    loggedUser,
    selectedOrgId: selectedOrgIdFromState,
  });

  // After user data is ready, request the organization data using the selected org data,
  // and fetch employee data.
  const {
    isRequestingOrgData,
    orgDataRequestCompleted,
    organizationError,
    employeeError,
  } = useRequestUserOrganization({
    selectedOrgId: selectedOrgIdFromState,
    selectedOrgRoles: selectedOrgRolesFromState,
    selectedEmployeeId: selectedEmployeeIdFromState,
    userDataRequestCompleted,
  });

  // Set/reset external services as org/employee changes (but only after extra data is ready)
  const { externalServicesFirstSetCompleted } = useSetExternalServices({
    loggedUser,
    extraDataRequestCompleted:
      userDataRequestCompleted && orgDataRequestCompleted,
  });

  if (itHubMaintenancePage) {
    return <MaintenancePage />;
  }

  if (auth0Error) {
    return (
      <Auth0ErrorState
        auth0Error={auth0Error}
        logout={logout}
        loggedOut={loggedOut}
      />
    );
  }

  // Function used to handle client-side account linking for auth0.
  // This is used when a user is logging into an account with an e-mail
  // that we already have in our system from another provider.
  // e.g. Someone uses Google Social auth to log in but they already have
  // an auth0 user with that same e-mail.
  // See here for more information:
  // https://auth0.com/docs/manage-users/user-accounts/user-account-linking/user-initiated-account-linking-client-side-implementation
  const handleAccountLink = async ({
    targetUserIDToken,
    targetUserAccessToken,
    makePrimary,
    targetUserId,
  }: HandleAccountLinkArgs) => {
    // Determine how to link based on what account should be the primary account.

    const primaryAccountAccessToken = makePrimary
      ? targetUserAccessToken
      : await getAccessTokenSilently();
    const secondaryAccountIDtoken = makePrimary
      ? (await getIdTokenClaims())?.__raw
      : targetUserIDToken;
    const primaryUserId = makePrimary ? targetUserId : auth0User?.sub;
    // Make a call to the Auth0 Management API to link the accounts.
    // This uses the scopes 'read:current_user update:current_user_identities' which
    // were provided to both the Auth0Provider and the Auth0Client.
    try {
      const accountLinkResponse = await fetch(
        `${window._env_.VITE_AUTH0_DOMAIN}/api/v2/users/${primaryUserId}/identities`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${primaryAccountAccessToken}`,
          },
          body: JSON.stringify({
            link_with: secondaryAccountIDtoken,
          }),
        }
      );
      if (accountLinkResponse.ok) {
        // Show the success toast.
        showSuccessToast({
          id: 'account-link-toast',
          message:
            'Account linked successfully. You will be redirected back to the application.',
        });
        // Wait 3 seconds before redirecting to allow the user to see the toast.
        setTimeout(() => {
          // We call loginWithRedirect to refresh the user's session and get the new token
          // which is now linked to the other account.
          loginWithRedirect();
        }, 3000);
      } else {
        throw new Error(accountLinkResponse.statusText);
      }
    } catch (err) {
      showErrorToast({
        id: 'account-link-error-toast',
        message:
          'Error linking account. If this error persists please reach out to support@electric.ai.',
      });
      console.error('Error linking account', err);
    }
  };

  // If the user has an account linking info, show the account linking page.
  if (auth0User && auth0User['https://my.electric.ai/accountLinkingInfo']) {
    return (
      <AccountLinking
        currentUser={auth0User}
        userToAuth={auth0User['https://my.electric.ai/accountLinkingInfo']}
        handleAccountLink={handleAccountLink}
      />
    );
  }

  // handles stale auth token – this logic should always be after the auth0Error handling
  if (isUnauthorizedError) {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
    dispatch(loggedOut()); // clear states on redux
  }

  // handle initialization errors
  const initFailed = myUserDataError || organizationError || employeeError;

  if (initFailed) {
    // Clear sessionStorage to avoid infinite loop in case it's causing the error
    sessionStorage.removeItem(SessionStorageItems.SELECTED_ORG_DATA);

    return <InitFailedState />;
  }

  // Render "loading": While the app is initializing (getting essential data and setting up external services for the first time)
  if (
    auth0UserLoading ||
    isRequestingUserData ||
    !userDataRequestCompleted ||
    isRequestingOrgData ||
    !orgDataRequestCompleted ||
    !externalServicesFirstSetCompleted // Launch Darkly is initialized with user's org and id
  ) {
    return <PageSpinner />;
  }

  // Render the application itself (after authentication and initialization is done)
  return (
    <Suspense fallback={<PageSpinner />}>
      <ElectrolyteRoutes />
    </Suspense>
  );
};

export default AuthenticatedApp;

import { Chip, ColorsV2, Text, useThemeColors } from '@electricjs/arc';

export const UnavailableInfoChip = () => {
  const [backgroundColor, textColor] = useThemeColors([
    ColorsV2.INFO_LIGHTEST,
    ColorsV2.INFO_DARK,
  ]);

  return (
    <Chip
      id="info-unavailable-chip"
      variant="small"
      backgroundColor={backgroundColor}
      color={textColor}>
      <Text color={textColor} variant="legal">
        Info unavailable
      </Text>
    </Chip>
  );
};

import { sortBy, uniqBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import {
  useLazyGetGroupAncestorsApplicationsQuery,
  useLazyGetGroupApplicationsQuery,
} from '@/redux/slices/groupApiSlice';
import { useAppSelector } from '@/redux/store';
import { Application } from '@/types/applications';
import { Group } from '@/types/groups';

export type AppsByGroup = {
  // Applications in this group + ancestors
  applications: Application[];
  // Group
  group: Group;
};

/**
 * Get all the applications for a set of groups by fetching all the apps in
 * the groups and parent groups.
 *
 * This method returns a list of AppsByGroup objects that contain a group and
 * the applications associated with it, sorted by group name.
 *
 * @param groups list of groups
 * @param options.skip boolean flag to skip fetching data. If true, the hook will not initiate any data fetching.
 */
export function useGetApplicationsForGroups(
  groups: Group[],
  options: { skip: boolean } = { skip: false }
): {
  data: AppsByGroup[] | undefined;
  isLoading: boolean;
  isError: boolean;
  error: unknown;
} {
  const organizationId = useAppSelector(
    state => state.loggedUser?.organizationId
  );

  const [getGroupApplications] = useLazyGetGroupApplicationsQuery();
  const [getGroupAncestorsApplications] =
    useLazyGetGroupAncestorsApplicationsQuery();

  // @TODO: Replace multiple state updates with a useReducer hook to avoid re-renders and intermediate states
  const [data, setData] = useState<AppsByGroup[] | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | undefined>();

  // Fetch all the apps for a given group, inherited and not inherited
  const fetchGroupApps = useCallback(
    async (groupId: string): Promise<Application[]> => {
      if (!organizationId) {
        return [];
      }

      const apps = await getGroupApplications(
        {
          organizationId: organizationId,
          groupId: groupId,
        },
        true
      ).unwrap();
      const inheritedApps = await getGroupAncestorsApplications(
        {
          organizationId: organizationId,
          groupId: groupId,
        },
        true
      ).unwrap();

      return uniqBy(apps.concat(inheritedApps), 'id');
    },
    [getGroupApplications, getGroupAncestorsApplications, organizationId]
  );

  const fetchData = useCallback(async (): Promise<
    AppsByGroup[] | undefined
  > => {
    if (!organizationId) {
      return;
    }

    // Build the hashmap of apps and groups
    const promises = groups.map(group => {
      return fetchGroupApps(group.id).then(apps => {
        return {
          group: group,
          applications: apps,
        };
      });
    });

    return await Promise.all(promises);
  }, [fetchGroupApps, organizationId, groups]);

  useEffect(() => {
    if (options.skip) {
      return;
    }

    setLoading(true);

    fetchData()
      .then((resp: AppsByGroup[] | undefined) => {
        setError(undefined);
        setData(sortBy(resp, ag => ag.group.name));
        setLoading(false);
      })
      .catch(err => {
        setError(err);
        setLoading(false);
      });
  }, [
    getGroupApplications,
    getGroupAncestorsApplications,
    fetchData,
    options.skip,
  ]);

  return {
    data,
    isLoading: loading,
    isError: !!error,
    error,
  };
}

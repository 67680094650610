// A list of all the search param keys used in the app's URL (they are not the ones passed in the API calls to backend)
// Used to avoid typos and to make sure all keys are used in the same way
export enum PaginationParamKeys {
  OrderBy = 'orderBy',
  Page = 'page',
  SearchTerm = 'searchTerm',
  SortOrder = 'sortOrder',
  Types = 'types',
}

export enum FilteringParamKeys {
  Status = 'status', // for Active/Archived
  HasElectricApp = 'hasElectricApp',
  HasMDM = 'hasMDM',
  AssetStatus = 'assetStatus', // Assets individual status
  ProductTypeName = 'productTypeName',
  Warranty = 'warranty',
  ExcludeAssigned = 'excludeAssigned',
}

export enum NavigationParamKeys {
  Tab = 'tab',
}

export enum SortingParamKeys {
  Column = 'sortCol',
  Direction = 'sortDir',
}

export enum SelectionParamKeys {
  EmployeeId = 'employeeId',
  ProductId = 'productId',
  AssetId = 'assetId',
}

/** Just an object representation of a queryParam (can be used both for URL or API call query params) */
export type QueryParam = {
  key: string;
  value?: string;
};

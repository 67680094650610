import React from 'react';
import styled, { css } from 'styled-components';
import { CollapsibleSubNav } from './CollapsibleSubNav';
import {
  navIconStyles,
  navTextStyles,
  sharedNavItemRootStyles,
} from '../styles';
import {
  Chip,
  ColorsV2,
  Flex,
  getToken,
  Icon,
  IconNames,
  Skeleton,
  Text,
} from '@electricjs/arc';
import isVisible from '@/helpers/isVisible';

export const navItemStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 4.5rem;
  min-height: 4.5rem;
  line-height: 4.5rem;
  transition: 0.2s ease;
  text-decoration: none !important;
  color: ${getToken(ColorsV2.TEXT)};
  border-radius: 0.8rem;
  ${sharedNavItemRootStyles}

  span {
    ${navTextStyles}
  }

  &.active {
    background: ${getToken(ColorsV2.BACKGROUND_ALT)};
    color: ${getToken(ColorsV2.PRIMARY)};

    /* The component text styles overwrites the active styles, so this overwrites it again */
    span {
      background: ${getToken(ColorsV2.BACKGROUND_ALT)};
      color: ${getToken(ColorsV2.PRIMARY)};
    }
  }

  &.ignore-active-styles {
    /* It's difficult to control the appending of the active class, */
    /* so instead if the "ignore-active-styles" class is included, we'll ignore active class properties */
    background: inherit;
    color: inherit;

    /* The component text styles overwrites the active styles, so this overwrites it again */
    span {
      background: inherit;
      color: inherit;
    }
  }

  &:hover {
    background: ${getToken(ColorsV2.BACKGROUND_ALT)};
    color: ${getToken(ColorsV2.PRIMARY)};

    /* The component text styles overwrites the hover styles, so this overwrites it again */
    span {
      background: ${getToken(ColorsV2.BACKGROUND_ALT)};
      color: ${getToken(ColorsV2.PRIMARY)};
    }
  }

  ${navIconStyles}
`;

const NavItemComponent = styled.a`
  ${navItemStyles}
`;

const CustomNavItemComponent = styled(
  ({
    component: NavComponent,
    className: styledClassName,
    icon,
    chipText,
    text,
    visible: _visible, // Throws a warning if passed to the component, and we don't need it
    ...props
  }: {
    component: JSX.Element;
    className?: string;
  } & SideNavItemProps) =>
    React.cloneElement(NavComponent, {
      'data-testid': 'nav-item',
      className: `nav-item${
        NavComponent.props?.className ? ` ${NavComponent.props.className}` : ''
      }${styledClassName ? ` ${styledClassName}` : ''}`,
      children: (
        <Flex width="100%" alignItems="center" justifyContent="space-between">
          <Flex>
            {icon ? <Icon icon={icon} /> : null}
            <Text tag="span">{text}</Text>
          </Flex>
          {chipText && (
            <Chip
              id={
                text
                  ? `${text.replace(' ', '').toLocaleLowerCase()}-nav-chip`
                  : 'nav-chip'
              }
              backgroundColor={ColorsV2.INFO_LIGHTEST}
              variant="small">
              <Text color={ColorsV2.INFO} variant="legal">
                {chipText}
              </Text>
            </Chip>
          )}
        </Flex>
      ),
      ...props,
    })
)`
  ${navItemStyles}
`;

const SectionHeading = styled(Text)`
  text-transform: uppercase;
`;

export type SideNavItemProps = {
  /** Sets the skeleton loading state for this specific nav item */
  isLoading?: boolean;
  /** Changes the CSS & accessibility of the nav item if it's active */
  isActive?: boolean;
  /** Text for the nav item */
  text?: string;
  /** Link the nav item will navigate to */
  href?: string;
  /** Component used for navigation, for example a `<NavLink>` from React Router. Must be an `<a>` tag for proper styling */
  component?: JSX.Element;
  /** Custom component option. Use an `<a>` tag for proper styling. */
  customComponent?: JSX.Element;
  /** Text that represents a section heading for grouping nav items. */
  sectionHeading?: string;
  /** Optional link target (e.g. "_blank" for new tab) for the nav item */
  target?: string;
  /** Left-only icon for the nav item */
  icon?: IconNames;
  /** Array of sub nav items */
  subNavItems?: Pick<
    SideNavItemProps,
    | 'text'
    | 'href'
    | 'target'
    | 'isActive'
    | 'component'
    | 'visible'
    | 'chipText'
  >[];
  /** show or hide the nav item */
  visible?: boolean | undefined;
  /** Text for the chip */
  chipText?: string;
};

const NavItem = (props: SideNavItemProps) => {
  const {
    isActive = false,
    text,
    href,
    component: NavComponent,
    customComponent: CustomComponent,
    sectionHeading,
    target,
    icon,
    chipText,
  } = props;
  if (sectionHeading) {
    return (
      <SectionHeading
        className="section-heading"
        intent="secondary"
        variant="legal"
        fontWeight={700}
        mt="1rem"
        mb="2rem">
        {sectionHeading}
      </SectionHeading>
    );
  }

  if (CustomComponent) return CustomComponent;

  if (NavComponent) {
    return <CustomNavItemComponent component={NavComponent} {...props} />;
  }

  return (
    <NavItemComponent
      className={`nav-item${isActive ? ' active' : ''}`}
      data-testid="nav-item"
      {...(isActive ? { ['aria-current']: 'page' } : {})}
      href={href}
      target={target}>
      <Flex width="100%" alignItems="center" justifyContent="space-between">
        <Flex>
          {icon ? <Icon icon={icon} /> : null}
          <Text tag="span">{text}</Text>
        </Flex>
        {chipText && (
          <Chip
            id={
              text
                ? `${text.replace(' ', '').toLocaleLowerCase()}-nav-chip`
                : 'nav-chip'
            }
            backgroundColor={ColorsV2.INFO_LIGHTEST}
            variant="small">
            <Text color={ColorsV2.INFO} variant="legal">
              {chipText}
            </Text>
          </Chip>
        )}
      </Flex>
    </NavItemComponent>
  );
};

export const SideNavItem = ({
  isLoading = false,
  subNavItems,
  ...navItemProps
}: SideNavItemProps): React.ReactElement => {
  if (isLoading) {
    return (
      <Skeleton
        width="23.0rem"
        height="4.5rem"
        data-testid="sidenav-item-skeleton"
      />
    );
  }
  if (subNavItems) {
    const hasActiveSubNav = subNavItems.some(
      item =>
        (item.component &&
          item.component?.props?.to === window.location.pathname) ||
        item.isActive
    );

    return (
      <CollapsibleSubNav {...navItemProps} hasActiveSubNav={hasActiveSubNav}>
        {subNavItems.filter(isVisible).map(item => (
          <NavItem key={item.text} {...item} />
        ))}
      </CollapsibleSubNav>
    );
  }
  return <NavItem {...navItemProps} />;
};

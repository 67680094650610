import styled from 'styled-components';

import { ColorsV2, Flex, getToken } from '@electricjs/arc';

const LoadingMessageContainer = styled(Flex)`
  justify-content: center;

  & div {
    width: 0.4rem;
    height: 0.4rem;
    margin: 0.4rem;
    border-radius: 50%;
    background-color: ${getToken(ColorsV2.GRAY_DARKEST)};
    opacity: 1;
    animation: bouncing-loader 0.6s infinite alternate;
  }
  & > div:nth-child(2) {
    animation-delay: 0.2s;
  }
  & > div:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes bouncing-loader {
    to {
      opacity: 0.1;
      transform: translateY(-0.4rem);
    }
  }
`;

export default function LoadingMessage() {
  return (
    <LoadingMessageContainer>
      <div></div>
      <div></div>
      <div></div>
    </LoadingMessageContainer>
  );
}

import { MalwarebytesProtectionStatus } from '@electricjs/core_entity-client';

export type AddOnInterestEmailArgs = {
  organizationId: string;
  addOn: string;
};

export type LearnMoreAboutSecurityEmailArgs = {
  organizationId: string;
  securityType: string;
};

export const AddOnVendors = {
  ThreatDown: 'threatdown',
  Perimeter81: 'perimeter-81',
  HEC: 'hec',
  Keeper: 'keeper',
  Fullsteam: 'fullsteam',
  BreachSecureNow: 'breachsecurenow',
  Dropsuite: 'dropsuite',
  Drata: 'drata',
} as const;

export type AddOnVendor = (typeof AddOnVendors)[keyof typeof AddOnVendors];

// the vendors for addOns from the api response are not consistent with the keys in AddOnVendors
export const apiVendorMap: Record<string, AddOnVendor> = {
  Malwarebytes: AddOnVendors.ThreatDown,
  Perimeter81: AddOnVendors.Perimeter81,
  Avanan: AddOnVendors.HEC,
  Keeper: AddOnVendors.Keeper,
  'Breach Secure Now': AddOnVendors.BreachSecureNow,
  Dropsuite: AddOnVendors.Dropsuite,
};

export type SecuritySolutionsVendor = Extract<
  AddOnVendor,
  | typeof AddOnVendors.ThreatDown
  | typeof AddOnVendors.Perimeter81
  | typeof AddOnVendors.HEC
>;

export type AddOn = {
  id: string;
  name: string;
  vendor: string;
  sku: string;
};

export type AddOrganizationAddOnArgs = {
  addOnId: string;
  licenseCount: number;
};

export type UpdateOrganizationAddOnArgs = {
  organizationId: string;
  addOnId: string;
  licenseCount: number;
};

export type RemoveOrganizationAddOnArgs = {
  organizationId: string;
  addOnId: string;
};

export type ThreatDownOverview = {
  blockedThreatsCount: number;
  machinesRegisteredCount: number;
  devicesRestoredCleanedCount: number;
  devicesScannedCount: number;
};

export type ThreatDownDeviceInfo = {
  employeeFirstName?: string;
  employeeLastName?: string;
  deviceHardwareModel?: string;
  deviceSerialNumber?: string;
  deviceHostname?: string;
  protectionStatus: MalwarebytesProtectionStatus | string;
  requiresReboot: boolean;
  groupPolicyName?: string;
  lastSeenAt?: string;
  lastScannedAt?: string;
  assetId?: string;
  employeeId?: string;
};

import { useState } from 'react';

import {
  FilePicker,
  Flex,
  ModalAction,
  ModalBody,
  ModalFooter,
  ModalFooterActions,
  ModalHeader,
  ModalV2,
  Text,
} from '@electricjs/arc';

import { useGlobalUI } from '@/components/GlobalUIProvider';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useAddFilesToAssetMutation } from '@/redux/slices/assetApiSlice';

enum FileStatus {
  Empty = 'empty',
  InvalidContent = 'invalidContent',
  Undefined = 'undefined',
  Valid = 'valid',
}

type AssetsImageUploadModalProps = {
  assetId: string;
  onCancel: () => void;
  onSuccess: () => void;
};

const AssetsImageUploadModal = ({
  assetId,
  onCancel,
  onSuccess,
}: AssetsImageUploadModalProps) => {
  const organizationId = useGetOrganizationId();
  const [addFilesToAsset, { isLoading: isAddFilesToAssetLoading }] =
    useAddFilesToAssetMutation();
  const [files, setFiles] = useState<File[]>([]);
  const { showSuccessToast, showErrorToast, showToast } = useGlobalUI();
  const [fileStatus, setFileStatus] = useState<FileStatus>(
    FileStatus.Undefined
  );
  const [fileErrorMessage, setFileErrorMessage] = useState('');

  const handleFileChange = (file: File) => {
    setFileErrorMessage('');
    setFileStatus(FileStatus.Valid);
    setFiles([file]);
  };

  const discardFileContent = () => {
    setFiles([]);
    setFileStatus(FileStatus.Undefined);
    setFileErrorMessage('');
  };

  const handleSubmit = async () => {
    if (files.length === 0) {
      showErrorToast({
        message: 'Please select at least one image to upload.',
        id: 'image-missing-error-toast',
      });
      return;
    }

    const formData = new FormData();
    files.forEach(file => {
      formData.append('files', file);
    });

    try {
      const response = await addFilesToAsset({
        organizationId,
        assetId,
        formData,
      }).unwrap();

      const errors = response.errors ?? [];
      if (errors.length > 0) {
        const failedFiles = errors
          .map(error => `${error.filename}: ${error.error}`)
          .join(', ');
        const successfulFiles = files
          .filter(file => !errors.some(error => error.filename === file.name))
          .map(file => file.name)
          .join(', ');

        if (failedFiles.length > 0 && successfulFiles.length === 0) {
          showErrorToast({
            id: 'asset-image-upload-file-error-toast',
            message: `${response.message}\nFailed: ${failedFiles}.`,
          });
        } else {
          showToast({
            id: 'asset-image-upload-info-toast',
            message: `${response.message}\nFailed: ${failedFiles}. Successful: ${successfulFiles}`,
          });
        }
      } else {
        showSuccessToast({
          id: 'asset-image-upload-success-toast',
          message: 'Images uploaded successfully.',
        });
        onSuccess();
      }
    } catch (error) {
      showErrorToast({
        id: 'asset-image-upload-error-toast',
        message: 'Failed to upload images.',
      });
      return Promise.reject(error);
    }
  };

  return (
    <ModalV2 hide={onCancel} visible ariaLabelledby="assets-image-add-modal">
      <ModalHeader showCloseButton={true}>
        <Flex alignItems="center" columnGap="1rem">
          <Text id="assets-image-modal-header-text" variant="heading-2">
            Upload Images
          </Text>
        </Flex>
      </ModalHeader>
      <form>
        <ModalBody>
          <FilePicker
            onChange={handleFileChange}
            onRemove={discardFileContent}
            acceptedExtensions=".jpg,.jpeg,.png,.tiff,.tga,.gif,.bmp,.tif"
            errorMessage={fileErrorMessage}
          />
        </ModalBody>
        <ModalFooter>
          <ModalFooterActions>
            <ModalAction id="asset-upload-image-cancel-button" variant="text">
              Cancel
            </ModalAction>
            <ModalAction
              id="assets-modal-add-image-button"
              type="button"
              disabled={fileStatus !== FileStatus.Valid}
              loading={isAddFilesToAssetLoading}
              onClick={handleSubmit}>
              Save Image
            </ModalAction>
          </ModalFooterActions>
        </ModalFooter>
      </form>
    </ModalV2>
  );
};

export default AssetsImageUploadModal;

import { ColorsV2, Flex, Spinner } from '@electricjs/arc';

import VPNCheck from '@/components/VPNCheck';

/**
 * Component used to show almost a blank page with a spinner, but it can be used inside other components as well.
 * Mainly used as the fallback element on Suspense components.
 */
const PageSpinner = () => {
  return (
    <Flex
      width="100%"
      height="100vh"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      backgroundColor={ColorsV2.BACKGROUND}>
      <Spinner />
      <VPNCheck />
    </Flex>
  );
};

export default PageSpinner;

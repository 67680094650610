import { rest } from 'msw';

import { requestTypes, requests } from '../data/requestData';

export const requestsHandlers = [
  rest.get(
    `*/v1/organizations/:organization_id/requests/:request_id`,
    (req, res, ctx) => {
      const request = requests.find(r => r.id === req.params.request_id);

      if (request) {
        return res(ctx.status(200), ctx.delay(200), ctx.json(request));
      } else {
        return res(ctx.status(404), ctx.delay(200));
      }
    }
  ),
  rest.patch(
    `*/v1/organizations/:organization_id/requests/:request_id`,
    async (req, res, ctx) => {
      const requestIndex = requests.findIndex(
        r => r.id === req.params.request_id
      );
      if (requestIndex >= 0) {
        const body = await req.json();

        requests[requestIndex] = { ...requests[requestIndex], ...body };
      }

      if (requestIndex >= 0) {
        return res(ctx.status(200), ctx.delay(500));
      } else {
        return res(ctx.status(404), ctx.delay(500));
      }
    }
  ),
  rest.get(`*/v1/request_types`, (_, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(200),
      ctx.json({ total: requestTypes.length, results: requestTypes })
    );
  }),
  rest.get(`*/v1/request_types/:request_type_id`, (req, res, ctx) => {
    const requestType = requestTypes.find(
      rt => rt.id === req.params.request_type_id
    );
    if (requestType) {
      return res(ctx.status(200), ctx.delay(200), ctx.json(requestType));
    } else {
      return res(ctx.status(404), ctx.delay(200));
    }
  }),
  rest.post(
    `*/v1/organizations/:organization_id/requests`,
    async (req, res, ctx) => {
      const body = await req.json();
      const request = {
        id: '002df5c0-054f-11ee-be56-0242ac120000',
        request_type_id: body.request_type_id,
        organization_id: body.organization_id,
        requester_user_id: body.requester_user_id,
        status: 'IN_PROGRESS',
        data: body.data,
      };
      requests.push(body);
      return res(ctx.status(201), ctx.delay(500), ctx.json(request));
    }
  ),
];

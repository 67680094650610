import { useNavigate } from 'react-router-dom';

import { Button } from '@electricjs/arc';

import { NotificationIcon } from '../NotificationIcon';
import { NotificationItemHeader } from '../NotificationItemHeader';
import { NotificationItemProps } from '../types';

export const MdmActionButton = () => {
  const navigate = useNavigate();
  return (
    <Button
      id="button-go-to-settings"
      m="2"
      variant="text"
      onClick={() => navigate('/settings')}>
      Go to Settings page
    </Button>
  );
};

const MdmNotificationItem = ({
  notification,
  hideDetails,
}: NotificationItemProps) => {
  return (
    <NotificationItemHeader
      notification={notification}
      title={
        <>
          <b>Send MDM enrollment instructions</b>
          <br />
          <>
            Your devices are ready for action. Now it’s time to send MDM
            enrollment instructions to your employees
          </>
        </>
      }
      icon={
        <NotificationIcon
          isNotificationUnseen={!notification.seenAt}
          icon="cog"
        />
      }
      label="MDM SETUP COMPLETED">
      {!hideDetails ? <MdmActionButton /> : null}
    </NotificationItemHeader>
  );
};

export default MdmNotificationItem;

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  Button,
  ColorsV2,
  Flex,
  IconInCircle,
  Text,
  IconNames,
} from '@electricjs/arc';

import AddAssetTile from '@/components/Assets/AddAssetTile';
import AssetsCSVUploadModal from '@/components/Assets/AssetsCSVUploadModal';
import { useGlobalUI } from '@/components/GlobalUIProvider';
import AppInviteModalWrapper from '@/components/DeviceOverview/AppInviteModal/AppInviteModalWrapper';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useLazyGetOrganizationEmployeesQuery } from '@/redux/slices/organizationApiSlice';
import { useOrganizationHasEntitlement } from '@/hooks/useOrganizationHasEntitlement';
import { EntitlementSlugEnum } from '@electricjs/core_entity-client';

const GridContainer = styled(Flex)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4rem;
  margin-top: 3rem;
  justify-content: center;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
type Tile = {
  recommended?: boolean;
  title: string;
  subtitle: string;
  icon: IconNames;
  button: React.ReactNode;
};
const AddAssetsSection = () => {
  const navigate = useNavigate();
  const [showInviteModal, setShowInviteModal] = useState(false);
  const { openModal, closeModal } = useGlobalUI();

  const organizationId = useGetOrganizationId();

  const [getOrganizationEmployees, { data: employees }] =
    useLazyGetOrganizationEmployeesQuery();
  const hasElectricAppEntitlement = useOrganizationHasEntitlement(
    EntitlementSlugEnum.DesktopApp
  );
  const tiles: Tile[] = [
    hasElectricAppEntitlement && {
      recommended: true,
      title: 'Electric app',
      subtitle:
        'Too many devices? Install the Electric app on your devices to auto-fill data.',
      icon: 'bolt',
      button: (
        <Button
          id="button-send-invite-employees"
          variant="fill"
          onClick={() => setShowInviteModal(true)}>
          Send invite to employees
        </Button>
      ),
    },
    {
      title: 'Add asset',
      subtitle: "Start managing your assets in Electric's IT Hub!",
      icon: 'briefcase',
      button: (
        <Button
          id="button-add-asset"
          variant="outline"
          onClick={() => navigate(`/assets/new`)}>
          Add asset
        </Button>
      ),
    },
    {
      title: 'CSV upload',
      subtitle:
        'Have existing assets? Don’t worry, import your assets from a CSV file.',
      icon: 'upload',
      button: (
        <Button
          id="button-import-assets"
          variant="outline"
          onClick={() => {
            openModal(
              <AssetsCSVUploadModal
                onCancel={closeModal}
                onSuccess={closeModal}
              />
            );
          }}>
          Import assets
        </Button>
      ),
    },
    {
      title: 'Purchase items',
      subtitle:
        'Order new devices from the Electric store and assign them to your employees.',
      icon: 'shop',
      button: (
        <Button
          id="button-purchase-hardware"
          variant="outline"
          onClick={() => navigate('/purchase-hardware')}>
          Purchase hardware
        </Button>
      ),
    },
  ].filter(Boolean) as Tile[];

  useEffect(() => {
    getOrganizationEmployees(
      {
        organizationId: organizationId,
        includeDevices: false,
      },
      true
    );
  }, [organizationId, getOrganizationEmployees]);

  return (
    <Flex hAlignContent="center" width="100%">
      <Flex vertical alignItems="center" p="3rem 6rem ">
        <IconInCircle
          intent="primary"
          background={ColorsV2.PRIMARY_LIGHTEST}
          icon="briefcase"
          mb="2rem"
          size="7rem"
          iconSize="large"
          smoothedBorder
        />
        <Text variant="heading-2" mb="1rem">
          Add assets
        </Text>
        <Text intent="secondary" textAlign="center">
          To manage inventory, start by adding your existing assets.
        </Text>

        {hasElectricAppEntitlement ? (
          <GridContainer>
            {tiles.map((tile, index) => (
              <AddAssetTile
                key={index}
                recommended={tile.recommended}
                title={tile.title}
                subtitle={tile.subtitle}
                icon={tile.icon}
                button={tile.button}
              />
            ))}
          </GridContainer>
        ) : (
          <Flex gap="4rem" mt="3rem" flexWrap="wrap" justifyContent="center">
            {tiles.map((tile, index) => (
              <AddAssetTile
                key={index}
                recommended={tile.recommended}
                title={tile.title}
                subtitle={tile.subtitle}
                icon={tile.icon}
                button={tile.button}
              />
            ))}
          </Flex>
        )}
      </Flex>
      <AppInviteModalWrapper
        includeEmail={true}
        includeDevice={false}
        inviteVisible={showInviteModal}
        setShowInviteModal={setShowInviteModal}
        employees={employees ?? []}
        title="Employees without devices"
      />
    </Flex>
  );
};

export default AddAssetsSection;

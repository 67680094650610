import { ProductTypeName } from '@electricjs/core_entity-client';
import { Chip, useThemeColors, ColorsV2, Text } from '@electricjs/arc';

import { FilterKeyEnum } from '@/types/storefront';
import { translateProductsFilters } from '../../utils';

import { updateSelectedFilters } from './filterUtils';

type ProductChipFilterProps = {
  filter: string;
  filterKey: FilterKeyEnum;
  selectedFilters: string[];
  onFilterSelect: (value: string[], key: FilterKeyEnum) => void;
  isKeyboardMouseGroup: boolean;
  onProductTypesSelect: (types: ProductTypeName[]) => void;
};

const ProductChipFilter = ({
  filter,
  filterKey,
  selectedFilters,
  onFilterSelect,
  isKeyboardMouseGroup,
  onProductTypesSelect,
}: ProductChipFilterProps) => {
  const primaryColor = useThemeColors(ColorsV2.PRIMARY_LIGHTEST);

  const handleRemove = (key: FilterKeyEnum, value: string) => {
    const updatedSelectedFilters = updateSelectedFilters(
      value,
      selectedFilters
    );
    onFilterSelect(updatedSelectedFilters, key);

    if (isKeyboardMouseGroup && key === FilterKeyEnum.TYPE) {
      if (updatedSelectedFilters.length === 0) {
        // when no type is selected, return all types of this group
        onProductTypesSelect([
          ProductTypeName.Keyboard,
          ProductTypeName.Mouse,
          ProductTypeName.KeyboardMouse,
        ]);
      } else {
        onProductTypesSelect([...updatedSelectedFilters] as ProductTypeName[]);
      }
    }
  };

  return (
    <Chip
      id="product-chip-filter"
      removeIcon="close"
      onRemove={() => handleRemove(filterKey, filter)}
      backgroundColor={primaryColor}>
      <Text variant="label-large">
        {translateProductsFilters(filterKey, filter)}
      </Text>
    </Chip>
  );
};

export default ProductChipFilter;

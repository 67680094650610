import styled from 'styled-components';

import { Box, Checkbox, Flex, Text } from '@electricjs/arc';

import { Employee } from '@/types/employees';

import { EmployeeWithReminderReason } from '@/pages/Hardware/DeviceOverview/types';

const StyledSelectAll = styled(Flex)`
  display: grid;
  grid-template-columns: 3.5rem 1fr;
  align-items: center;
`;

const StyledFlex = styled(Flex)`
  display: grid;
  grid-template-columns: 3.5rem minmax(10px, 1.5fr) minmax(10px, 1.5fr) minmax(
      10px,
      1.5fr
    );
  align-items: top;
`;

function isEmployeeWithReminderReason(
  obj: Employee
): obj is EmployeeWithReminderReason {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    'reminderReason' in obj &&
    typeof obj.reminderReason === 'string'
  );
}

type EmployeeCheckboxProps<GenericEmployee extends Employee> = {
  employee: GenericEmployee;
  onChange: (currEmployee: GenericEmployee, isSelected: boolean) => void;
  isSelected: boolean;
  includeEmail?: boolean;
  includeDevice?: boolean;
};

const EmployeeCheckbox = <GenericEmployee extends Employee>({
  employee,
  onChange,
  isSelected,
  includeEmail = false,
  includeDevice = true,
}: EmployeeCheckboxProps<GenericEmployee>) => {
  const handleChange = () => {
    onChange(employee, isSelected);
  };

  return (
    <StyledFlex key={employee.id} mt={2}>
      <Checkbox
        id={`${employee.id}-employee-checkbox`}
        onChange={handleChange}
        checked={isSelected}
      />

      {isEmployeeWithReminderReason(employee) && includeDevice && (
        <Flex vAlignContent={'center'}>
          <Text mt={2} mr={1}>
            {employee.asset?.name ||
              employee.asset?.model ||
              employee.asset?.device?.hardwareModel ||
              '-- --'}
          </Text>
        </Flex>
      )}
      <Text mt={2}>
        {employee.firstName} {employee.lastName}
      </Text>
      {includeEmail && <Text mt={2}>{employee.email}</Text>}
    </StyledFlex>
  );
};

type EmployeeCheckboxListProps<GenericEmployee extends Employee> = {
  employees: GenericEmployee[];
  selectedEmployees: GenericEmployee[];
  setSelectedEmployees: React.Dispatch<React.SetStateAction<GenericEmployee[]>>;
  includeDevice?: boolean;
  includeEmail?: boolean;
};

const EmployeeCheckboxList = <GenericEmployee extends Employee>({
  employees,
  selectedEmployees,
  setSelectedEmployees,
  includeEmail = false,
  includeDevice = true,
}: EmployeeCheckboxListProps<GenericEmployee>) => {
  const allEmployeesSelected = employees.length === selectedEmployees.length;

  const selectedEmployeeMap: { [key: string]: boolean } =
    selectedEmployees.reduce((acc, currEmployee) => {
      return { ...acc, [currEmployee.id]: true };
    }, {});

  const handleChange = (currEmployee: GenericEmployee, isSelected: boolean) => {
    isSelected
      ? setSelectedEmployees(
          selectedEmployees.filter(employee => employee.id !== currEmployee.id)
        )
      : setSelectedEmployees([...selectedEmployees, currEmployee]);
  };
  const handleSelectAll = () => {
    allEmployeesSelected
      ? setSelectedEmployees([])
      : setSelectedEmployees(employees);
  };

  const selectionNoun = includeDevice ? 'device' : 'employee';

  return (
    <>
      <StyledSelectAll mt={3}>
        <Checkbox
          id="select-all-checkbox"
          checked={allEmployeesSelected}
          onClick={handleSelectAll}
        />
        <Text variant="label-large">
          Select {employees.length > 1 ? 'all' : ''} {employees.length}{' '}
          {selectionNoun}
          {employees.length > 1 ? 's' : ''}
        </Text>
      </StyledSelectAll>
      <StyledFlex mt={4}>
        <Box />
        {includeDevice && <Text variant="label-large">Device</Text>}
        <Text variant="label-large">Device Owner</Text>
        {includeEmail && <Text variant="label-large">Email</Text>}
      </StyledFlex>
      {employees.map(employee => (
        <EmployeeCheckbox
          includeDevice={includeDevice}
          key={
            isEmployeeWithReminderReason(employee)
              ? employee.id + employee?.serialIfMultipleDevices
              : employee.id
          }
          employee={employee}
          onChange={handleChange}
          isSelected={!!selectedEmployeeMap[employee.id]}
          includeEmail={includeEmail}
        />
      ))}
    </>
  );
};

export default EmployeeCheckboxList;

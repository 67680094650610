import { Champion as ChampionDto } from '@electricjs/core_entity-client';

export const organizationApplicationChampionsList: ChampionDto[] = [
  {
    id: 'champion-id-1',
    employee_id: 'simple-employee-id',
  },
  {
    id: 'champion-id-2',
    employee_id: 'simple-employee-id',
  },
  {
    id: 'champion-id-3',
    employee_id: 'simple-employee-id',
  },
];

export const createOrganizationApplicationChampion = {
  id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  employee_id: 'test',
};

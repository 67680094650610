import { rest } from 'msw';

import { applicationsList } from '@/mocks/data/applicationData';
import {
  createOrganizationApplication,
  organizationApplicationsList,
} from '@/mocks/data/organizationApplicationData';

export const organizationApplicationHandlers = [
  rest.get(
    `*/v1/organizations/:id/organization_applications`,
    (req, res, ctx) => {
      let limit = 10;
      const limitParam = req.url.searchParams.get('limit');
      if (limitParam) {
        limit = parseInt(limitParam);
      }
      const offsetParam = req.url.searchParams.get('offset');
      let organizationApplicationsListPage = organizationApplicationsList.slice(
        0,
        limit
      );
      if (offsetParam) {
        const offset = parseInt(offsetParam);
        if (offset > 0) {
          organizationApplicationsListPage = organizationApplicationsList.slice(
            offset,
            offset + limit
          );
        }
      }
      return res(
        ctx.status(200),
        ctx.delay(200),
        ctx.json({
          total: organizationApplicationsList.length,
          results: organizationApplicationsListPage,
        })
      );
    }
  ),
  rest.get(
    `*/v1/organizations/:id/organization_applications/:applicationKey`,
    (req, res, ctx) => {
      const { applicationKey } = req.params;

      const orgApp = applicationsList.find(
        orgApp => orgApp.slug === applicationKey || orgApp.id === applicationKey
      );
      if (orgApp) {
        return res(ctx.status(200), ctx.delay(200), ctx.json(orgApp));
      } else {
        return res(ctx.status(404), ctx.delay(200), ctx.json({}));
      }
    }
  ),
  rest.post(
    `*/v1/organizations/:id/organization_applications`,
    async (req, res, ctx) => {
      const body = await req.json();
      const app = applicationsList.find(app => app.id === body?.application_id);
      const orgApp = {
        ...createOrganizationApplication,
        application: { ...createOrganizationApplication.application, ...app },
      };

      organizationApplicationsList.push(orgApp);

      return res(
        ctx.status(201),
        ctx.delay(200),
        ctx.json(createOrganizationApplication)
      );
    }
  ),
  rest.delete(
    `*/v1/organizations/:organizationId/organization_applications/:applicationKey`,
    async (req, res, ctx) => {
      const { applicationKey } = req.params;
      organizationApplicationsList.splice(
        organizationApplicationsList.findIndex(
          a =>
            a.application.id === applicationKey ||
            a.application.slug === applicationKey
        ),
        1
      );
      return res(ctx.status(204), ctx.delay(200));
    }
  ),
];

import { rest } from 'msw';

import { applicationsList } from '@/mocks/data/applicationData';

export const applicationHandlers = [
  rest.get('*/v1/applications', (_req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(),
      ctx.json({ total: applicationsList.length, results: applicationsList })
    );
  }),
  rest.get('*/v1/applications/:applicationKey', (req, res, ctx) => {
    const applicationKey = req.params.applicationKey;
    const app = applicationsList.find(
      app => app.id === applicationKey || app.slug === applicationKey
    );

    if (app) {
      return res(ctx.status(200), ctx.delay(500), ctx.json(app));
    }

    return res(ctx.status(404), ctx.delay(500));
  }),
];

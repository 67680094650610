import { Flex, Text } from '@electricjs/arc';
import styled from 'styled-components';

export const DetailsContainer = styled(Flex)`
  width: 100%;
  flex-direction: column;
  gap: 2rem;
`;

export const NoWidowText = styled(Text)`
  text-wrap-style: pretty;
`;

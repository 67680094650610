import { Skeleton, Text } from '@electricjs/arc';

import { NotificationIcon } from '../NotificationIcon';
import { NotificationItemHeader } from '../NotificationItemHeader';
import { NotificationItemProps } from '../types';
import { useGetRequestData } from '@/hooks/useGetRequestData';
import formatDate from '@/helpers/formatDate';
import { RenewMdmCertButton } from '@/components/Tasks/Actions/RenewMdmCertButton';

const MdmCertificateHasExpiredNotificationItem = ({
  notification,
  hideDetails,
}: NotificationItemProps) => {
  const { data: requestData, isLoading } = useGetRequestData({
    requestId: notification?.data?.request_id ?? null,
  });

  if (isLoading) return <Skeleton />;

  const title = (
    <Text fontSize={'14px'} pb={'1rem'}>
      Your Apple Push Certificate (CSR) has expired on&nbsp;
      <b>
        {formatDate(requestData.request?.data.expiresAt)}. MDM communication
        with already enrolled devices will cease, and new devices cannot be
        enrolled
      </b>
      . Renew immediately.
    </Text>
  );

  return (
    <NotificationItemHeader
      notification={notification}
      title={title}
      icon={
        <NotificationIcon
          isNotificationUnseen={!notification.seenAt}
          icon="alert-circle"
          intent="danger"
        />
      }
      label="DEVICE MANAGEMENT">
      {!hideDetails ? <RenewMdmCertButton /> : null}
    </NotificationItemHeader>
  );
};

export default MdmCertificateHasExpiredNotificationItem;

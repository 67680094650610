import { Themes } from '@/components/ThemeProvider';
import { ColorsV2 } from '@electricjs/arc';
import { Appearance, loadStripe, Stripe } from '@stripe/stripe-js';
import { DefaultTheme } from 'styled-components';

let stripe: Stripe | null = null;

if (window._env_.VITE_STRIPE_PUBLIC_KEY) {
  stripe = await loadStripe(window._env_.VITE_STRIPE_PUBLIC_KEY);
}

export const generateStripeAppearance = (theme: DefaultTheme): Appearance => {
  const isLightTheme = theme.name === Themes.LIGHT;
  return {
    theme: isLightTheme ? 'stripe' : 'night',
    variables: {
      fontFamily: 'Roboto, sans-serif',
      borderRadius: '4px',
      colorPrimary: theme.colors[ColorsV2.PRIMARY],
      colorText: theme.colors[ColorsV2.TEXT],
      colorTextSecondary: theme.colors[ColorsV2.TEXT_LIGHT],
      colorDanger: theme.colors[ColorsV2.ERROR],
      colorSuccess: theme.colors[ColorsV2.SUCCESS],
      colorWarning: theme.colors[ColorsV2.WARNING],
      iconColor: theme.colors[ColorsV2.PRIMARY],
      iconHoverColor: theme.colors[ColorsV2.PRIMARY_DARKEST],
    },
  };
};

export default stripe;

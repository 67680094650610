import { DeviceIssueType } from '@/types/devices';

export const HealthMonitorTabs = {
  NEEDS_ATTENTION: 'needs-attention',
  ACTIVITY_LOG: 'activity-log',
  RESOLVED: 'resolved',
} as const;

export type HealthMonitorTabValues =
  (typeof HealthMonitorTabs)[keyof typeof HealthMonitorTabs];

export const HealthMonitorTabsText = {
  [HealthMonitorTabs.NEEDS_ATTENTION]: 'Needs attention',
  [HealthMonitorTabs.ACTIVITY_LOG]: 'Activity log',
  [HealthMonitorTabs.RESOLVED]: 'Resolved',
} as const;

// Type guard to check if a string is a valid DeviceIssueType
export const isDeviceIssueType = (value?: string): value is DeviceIssueType => {
  return Object.values(DeviceIssueType).includes(value as DeviceIssueType);
};

import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useState } from 'react';
import { useUpdateABMOrganizationInfoMutation } from '@/redux/slices/organizationApiSlice';
import { ABMEnrollmentStatusEnum } from '@electricjs/core_entity-client';

type UseABMSetupType = {
  onSaveAbm: () => void;
  onErrorAbm: () => void;
  abmOrgID?: string;
  isCheckboxSelected?: boolean;
};

/** Hook to validate ABM setup */
export const useABMSetup = ({
  onSaveAbm,
  onErrorAbm,
  abmOrgID = '',
  isCheckboxSelected = false,
}: UseABMSetupType) => {
  const organizationId = useGetOrganizationId();
  const [isChecked, setIsChecked] = useState(isCheckboxSelected);
  const [needsThirdParty, setNeedsThirdParty] = useState(false);
  const [needsValidAbmID, setNeedsValidAbmID] = useState(false);
  const [abmIDErrorMessage, setAbmIDErrorMessage] = useState('');
  const [abmID, setAbmID] = useState(abmOrgID);
  const [isLoading, setIsLoading] = useState(false);

  // Disables the save button when the user has not edited the abm ID (saved abmOrgID is the same as input value)
  const needsEditAbmID = abmID === abmOrgID;

  const [updateABMOrganizationInfo] = useUpdateABMOrganizationInfoMutation();

  // Resets state when changing ABM status (e.g. DEFAULT to SETUP and back to DEFAULT)
  const handleResetState = () => {
    setNeedsThirdParty(false);
    setNeedsValidAbmID(false);
    setIsChecked(false);
    setAbmIDErrorMessage('');
    setAbmID('');
  };

  const handleCheckbox = () => {
    setIsChecked(!isChecked);
    needsThirdParty && setNeedsThirdParty(false);
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNeedsValidAbmID(false);
    setAbmID(e.currentTarget.value);
  };

  const isNum = /^\d+$/.test(abmID);

  const handleSave = () => {
    if (!needsThirdParty && !isChecked) {
      setNeedsThirdParty(true);
    }
    if (abmID.length < 4 || abmID.length > 50 || !isNum) {
      setNeedsValidAbmID(true);
      abmID.length < 4 &&
        setAbmIDErrorMessage(
          'A valid ABM organizational ID of at least 4 characters is required.'
        );
      abmID.length > 50 &&
        setAbmIDErrorMessage(
          'A valid ABM organizational ID of maximum 50 characters is required.'
        );
      !isNum &&
        setAbmIDErrorMessage(
          'A valid ABM organizational ID of only numeric characters is required.'
        );
      return;
    }
    if (!needsThirdParty && !needsValidAbmID && isNum && isChecked) {
      setIsLoading(true);
      updateABMOrganizationInfo({
        organizationId,
        abmID: Number.parseInt(abmID),
        abmStatus: ABMEnrollmentStatusEnum.Connected,
      })
        .unwrap()
        .then(() => {
          setIsLoading(false);
          onSaveAbm();
        })
        .catch(() => {
          setIsLoading(false);
          onErrorAbm();
        });
    }
  };

  return {
    abmIDErrorMessage,
    handleCheckbox,
    handleInput,
    handleSave,
    handleResetState,
    needsValidAbmID,
    needsThirdParty,
    needsEditAbmID,
    isLoading,
  };
};

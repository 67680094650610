import { PermissionEnum } from '@electricjs/core_entity-client';

import { useAppSelector } from '@/redux/store';

import { useGetOrganizationId } from './useGetOrganizationId';

export const useUserHasOrganizationPermission = (
  permission: PermissionEnum
): boolean => {
  const organizationId = useGetOrganizationId();

  const userOrganizationPermissions =
    useAppSelector(
      state =>
        state.loggedUser?.allUserOrganizationRoles?.[organizationId].permissions
    ) || [];

  return userOrganizationPermissions.includes(permission);
};

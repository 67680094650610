import {
  Application as ApplicationDto,
  Group as GroupDto,
} from '@electricjs/core_entity-client';

import { simpleEmployee } from './employeeData';

export const simpleGroup: GroupDto = {
  id: 'simple-group-id',
  name: 'Test Group Name',
  employees: [simpleEmployee],
  created_at: '2023-05-22',
  updated_at: '2023-05-22',
  applications_count: 2,
};

export const simpleGroupApplicationList: ApplicationDto[] = [
  {
    id: 'aaeea7bc-054e-11ee-be56-0242ac120002',
    name: 'Zoom',
    slug: 'zoom',
    icon: 'https://logo.clearbit.com/zoom.us',
    url: 'https://zoom.us',
    integration_availability: 'not_available',
  },
  {
    id: '13e20646-1322-4493-8417-8993e77332cc',
    name: 'Teams',
    slug: 'teams',
    icon: 'https://logo.clearbit.com/teams.microsoft.com',
    url: 'https://teams.microsoft.com',
    integration_availability: 'not_available',
  },
];

export const employeeGroups = [
  {
    id: '5b7c6edc-f6b4-416c-af0a-a1e41f57ea48',
    name: 'Lexi Inherit',
    employees: [
      {
        id: '8578788e-81db-4597-aac8-378487f3c57a',
        first_name: 'Andrew',
        last_name: 'Huynh',
        email: 'andrew.huynh@electric.ai',
        personal_email: null,
        start_date: null,
        job_title: 'Sleep deprived cat dad',
        user_id: 'c877b110-7687-4288-9a72-308fa4d3f2c8',
        address: null,
        created_at: '2023-07-19T14:52:01.259766',
        updated_at: '2023-09-27T21:03:56.455508',
        status: 'ACTIVE',
        source: 'MANUAL',
        roles: [
          {
            id: '46a2dd89-cf31-450b-b21a-8afed056b6ec',
            slug: 'hub_user',
          },
          {
            id: '9c0fe0ed-dadd-48b1-bdae-d10a2e497b5a',
            slug: 'super_admin',
          },
        ],
        hris_employee_integrations: [],
        devices: [],
      },
      {
        id: '0be459d2-482d-4b4e-9a85-47484cd44ca8',
        first_name: 'Leo',
        last_name: 'Messi',
        email: 'leo.messi@electric-tester.com',
        personal_email: 'leo.messi@gmail.com',
        start_date: '2023-09-30T00:00:00',
        job_title: 'GOAT',
        user_id: 'e2d3968f-aafc-49fc-af6d-9f065f000acf',
        address: null,
        created_at: '2023-09-13T15:08:59.758024',
        updated_at: '2023-09-13T15:08:59.758024',
        status: 'ACTIVE',
        source: 'MANUAL',
        roles: [
          {
            id: '46a2dd89-cf31-450b-b21a-8afed056b6ec',
            slug: 'hub_user',
          },
        ],
        hris_employee_integrations: [],
        devices: [],
      },
      {
        id: 'f40c8e00-a9de-4e8c-b5aa-716203f0b9df',
        first_name: 'Tony',
        last_name: 'Stark',
        email: 'tony.stark@electric-tester.com',
        personal_email: 'tony.stark@gmail.com',
        start_date: null,
        job_title: 'Senior Engineer',
        user_id: '51d4b79e-3d3b-4576-a1b7-15cc16f628f6',
        address: null,
        created_at: '2023-09-13T15:08:59.758024',
        updated_at: '2023-09-29T13:47:46.067447',
        status: 'ACTIVE',
        source: 'MANUAL',
        roles: [
          {
            id: '46a2dd89-cf31-450b-b21a-8afed056b6ec',
            slug: 'hub_user',
          },
        ],
        hris_employee_integrations: [],
        devices: [],
      },
      {
        id: 'a021ce81-d154-4002-80db-ca37bf1b0663',
        first_name: 'Pablo',
        last_name: 'Cettour',
        email: 'pablo.cettour@electric.ai',
        personal_email: 'test@mail.com',
        start_date: null,
        job_title: 'dev1',
        user_id: '37d709ab-65a4-4cfc-9155-3ddc191a53cc',
        address: null,
        created_at: '2023-07-14T15:38:11.347344',
        updated_at: '2023-10-03T15:43:30.999393',
        status: 'ACTIVE',
        source: 'MANUAL',
        roles: [
          {
            id: '9c0fe0ed-dadd-48b1-bdae-d10a2e497b5a',
            slug: 'super_admin',
          },
        ],
        hris_employee_integrations: [],
        devices: [
          {
            serial_number: '12345',
            hardware_model: 'Dell Latitude',
            os_type: 'windows',
            organization_id: 'd4ae5998-2af8-500a-a2c8-cfcc47055197',
            id: '5f1bb7da-ddb2-4b7f-9a6d-b5e132c7e2e2',
          },
        ],
      },
    ],
    created_at: '2023-09-08T13:54:17.026537',
    updated_at: '2023-09-08T13:54:17.026537',
    parent_group_id: null,
  },
  {
    id: 'a4283a80-ec82-4015-b712-0d63cabe3c8f',
    name: 'test-Great-grandparent',
    employees: [
      {
        id: 'a021ce81-d154-4002-80db-ca37bf1b0663',
        first_name: 'Pablo',
        last_name: 'Cettour',
        email: 'pablo.cettour@electric.ai',
        personal_email: 'test@mail.com',
        start_date: null,
        job_title: 'dev1',
        user_id: '37d709ab-65a4-4cfc-9155-3ddc191a53cc',
        address: null,
        created_at: '2023-07-14T15:38:11.347344',
        updated_at: '2023-10-03T15:43:30.999393',
        status: 'ACTIVE',
        source: 'MANUAL',
        roles: [
          {
            id: '9c0fe0ed-dadd-48b1-bdae-d10a2e497b5a',
            slug: 'super_admin',
          },
        ],
        hris_employee_integrations: [],
        devices: [
          {
            serial_number: '12345',
            hardware_model: 'Dell Latitude',
            os_type: 'windows',
            organization_id: 'd4ae5998-2af8-500a-a2c8-cfcc47055197',
            assigned_to: 'a021ce81-d154-4002-80db-ca37bf1b0663',
            id: '5f1bb7da-ddb2-4b7f-9a6d-b5e132c7e2e2',
          },
        ],
      },
    ],
    created_at: '2023-09-06T14:19:47.196628',
    updated_at: '2023-09-08T14:09:37.677815',
    parent_group_id: null,
  },
  {
    id: '89f59e26-c007-4f1d-94d1-60a4e64c390b',
    name: 'test-grandparent1',
    employees: [
      {
        id: 'a021ce81-d154-4002-80db-ca37bf1b0663',
        first_name: 'Pablo',
        last_name: 'Cettour',
        email: 'pablo.cettour@electric.ai',
        personal_email: 'test@mail.com',
        start_date: null,
        job_title: 'dev1',
        user_id: '37d709ab-65a4-4cfc-9155-3ddc191a53cc',
        address: null,
        created_at: '2023-07-14T15:38:11.347344',
        updated_at: '2023-10-03T15:43:30.999393',
        status: 'ACTIVE',
        source: 'MANUAL',
        roles: [
          {
            id: '9c0fe0ed-dadd-48b1-bdae-d10a2e497b5a',
            slug: 'super_admin',
          },
        ],
        hris_employee_integrations: [],
        devices: [
          {
            serial_number: '12345',
            hardware_model: 'Dell Latitude',
            os_type: 'windows',
            organization_id: 'd4ae5998-2af8-500a-a2c8-cfcc47055197',
            assigned_to: 'a021ce81-d154-4002-80db-ca37bf1b0663',
            id: '5f1bb7da-ddb2-4b7f-9a6d-b5e132c7e2e2',
          },
        ],
      },
      {
        id: '3a4df577-5678-4a74-9206-a9d84c567c60',
        first_name: 'John',
        last_name: 'Doe',
        email: 'john.doe@test.com',
        personal_email: null,
        start_date: null,
        job_title: 'Dev',
        user_id: '7c48fa95-c0cd-4e8e-91b6-435edd3594bf',
        address: null,
        created_at: '2023-06-07T16:06:55.425300',
        updated_at: '2023-08-09T09:14:10.207775',
        status: 'ACTIVE',
        source: 'MANUAL',
        roles: [
          {
            id: '46a2dd89-cf31-450b-b21a-8afed056b6ec',
            slug: 'hub_user',
          },
        ],
        hris_employee_integrations: [],
        devices: [],
      },
    ],
    created_at: '2023-09-01T19:04:28.212193',
    updated_at: '2023-09-09T01:16:12.464124',
    parent_group_id: 'a4283a80-ec82-4015-b712-0d63cabe3c8f',
  },
];

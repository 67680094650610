import styled from 'styled-components';

import {
  Button,
  ColorsV2,
  Divider,
  Flex,
  IconButton,
  Table,
  TableContainer,
  Text,
} from '@electricjs/arc';

import { ActionConfirmationModal } from '@common';

import { useGlobalUI } from '@/components/GlobalUIProvider';
import AddPlanModal from '@/components/Organization/AddPlanModal';
import EditOrganizationAddOnLicenseCountModal from '@/components/Organization/EditOrganizationAddOnLicenseCountModal';
import { useRemoveOrganizationAddOnMutation } from '@/redux/slices/addonApiSlice';
import { Organization, OrganizationAddOn } from '@/types/organizations';
import { createColumnHelper } from '@tanstack/react-table';
import TableCellFlexContainer from 'common/Table/TableCellFlexContainer';

type AddOnTileProps = {
  canUpdateOrganizationAddOns: boolean;
  canCreateOrganizationAddOns: boolean;
  organization: Organization | undefined;
  organizationLoading: boolean;
  isInGrid: boolean;
  height: string;
};

const columnHelper = createColumnHelper<OrganizationAddOn>();

const StyledText = styled(Text)`
  display: -webkit-box;
  // Ellipsis text when it reaches 2nd line
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const AddOnTile = ({
  canUpdateOrganizationAddOns,
  canCreateOrganizationAddOns,
  organization,
  organizationLoading,
  isInGrid,
  height,
}: AddOnTileProps) => {
  const borderColor = ColorsV2.BORDER;
  const { openModal, closeModal, showSuccessToast, showErrorToast } =
    useGlobalUI();
  const [removeOrganizationAddOn] = useRemoveOrganizationAddOnMutation();

  const organizationAddOnsData: OrganizationAddOn[] = organization?.addOns
    ?.length
    ? organization.addOns
    : [];

  const handleRemoveAddOn = (organizationAddOn: OrganizationAddOn) => {
    openModal(
      <ActionConfirmationModal
        title="Removing add on"
        message={
          <>
            <Text>
              You are about to remove {organizationAddOn.name} from this
              customer&apos;s current subscription.
            </Text>
            <Text>
              By proceeding you acknowledge:
              <ul>
                <li>
                  This action can NOT be undone and you will need to manually
                  add this plan back if you wish to use it again in the future.
                </li>
              </ul>
            </Text>
          </>
        }
        cancelButtonLabel="Cancel"
        confirmButtonLabel="Remove add on"
        onCancel={() => closeModal()}
        onConfirm={() => {
          return removeOrganizationAddOn({
            organizationId: organizationAddOn.organizationId,
            addOnId: organizationAddOn.id,
          })
            .unwrap()
            .then(() => {
              showSuccessToast({
                id: 'remove-add-on-success-toast',
                stack: true,
                title: 'Add on removed',
                message: `The add on was removed successfully.`,
              });
              closeModal();
            })
            .catch(() => {
              showErrorToast({
                id: 'remove-add-on-error-toast',
                stack: true,
                message:
                  'Something went wrong while removing the add on. Please try again.',
              });
              closeModal();
            });
        }}
      />
    );
  };

  // Dynamically change table column width
  const columnWidth = Math.round(window.innerWidth * 0.05);

  const addOnsTableColumns = [
    columnHelper.accessor('name', {
      header: () => <Text variant="body-2">Plan</Text>,
      id: 'plan',
      meta: {
        minWidth: isInGrid ? 0 : 300,
        width: columnWidth * 1.2,
        flex: isInGrid ? `${columnWidth * 1.2} 0 auto` : '2',
      },
      cell: ({ getValue }) => {
        return (
          <TableCellFlexContainer>
            <Text variant="label-small">{getValue()}</Text>
          </TableCellFlexContainer>
        );
      },
    }),
    columnHelper.accessor('vendor', {
      header: () => <Text variant="body-2">Vendor</Text>,
      id: 'vendor',
      meta: {
        minWidth: isInGrid ? 0 : 300,
        width: columnWidth * 1.5,
        flex: isInGrid ? `${columnWidth * 1.5} 0 auto` : '2',
      },
      cell: ({ getValue }) => {
        return (
          <TableCellFlexContainer>
            <Text variant="label-small">{getValue()}</Text>
          </TableCellFlexContainer>
        );
      },
    }),
    columnHelper.accessor('licenseCount', {
      header: () => {
        return <Text variant="body-2">License Count</Text>;
      },
      id: 'license-count',
      meta: {
        minWidth: isInGrid ? 0 : 200,
        width: columnWidth * 0.8,
        flex: isInGrid ? `${columnWidth * 0.8} 0 auto` : '1',
      },
      cell: ({ getValue }) => {
        return (
          <TableCellFlexContainer>
            <Text variant="body-2">{getValue()}</Text>
          </TableCellFlexContainer>
        );
      },
    }),
    columnHelper.accessor('id', {
      header: () => null,
      id: 'actions',
      meta: {
        width: columnWidth * (canUpdateOrganizationAddOns ? 1.5 : 0),
        minWidth: '0px',
        maxWidth: '70px',
        flex: isInGrid
          ? `${columnWidth * (canUpdateOrganizationAddOns ? 1.5 : 0)} 0 auto`
          : '1',
      },
      cell: ({ row }) => {
        return (
          canUpdateOrganizationAddOns && (
            <Flex alignItems="center" height="100%">
              <IconButton
                id="edit-add-on-icon-button"
                icon="pencil"
                intent="primary"
                onClick={() =>
                  openModal(
                    <EditOrganizationAddOnLicenseCountModal
                      onCancel={closeModal}
                      onSuccess={closeModal}
                      organizationAddOn={row.original}
                    />
                  )
                }
              />
              <IconButton
                id="remove-add-on-icon-button"
                icon="trashcan"
                intent="primary"
                onClick={() => handleRemoveAddOn(row.original)}
              />
            </Flex>
          )
        );
      },
    }),
  ];

  return (
    <TableContainer
      vertical
      hAlignContent="center"
      width="100%"
      alignSelf="flex-start"
      flexDirection="column"
      height={isInGrid && height}>
      <Flex justifyContent="space-between" vAlignContent="center" width="100%">
        <Flex vertical rowGap="0.5rem" p="2.5rem">
          <Text variant="heading-2">Add-Ons</Text>
          <StyledText>
            {canUpdateOrganizationAddOns ? 'Manage' : 'View'} your
            company&apos;s plan and license counts.
          </StyledText>
        </Flex>
        {canCreateOrganizationAddOns && (
          <Flex vertical rowGap="0.5rem" p="2.5rem">
            <Button
              id="button-add-plan"
              alignSelf="flex-end"
              iconBefore="add-circle"
              onClick={() =>
                openModal(
                  <AddPlanModal
                    onCancel={closeModal}
                    onSuccess={closeModal}
                    organizationId={organization?.id ?? ''}
                    currentAddOnsIds={
                      organization?.addOns?.map(addOn => addOn.id) || []
                    }
                  />
                )
              }>
              Add plan
            </Button>
          </Flex>
        )}
      </Flex>
      <Divider color={borderColor} />
      <Table
        id="organization-add-ons-table"
        columns={addOnsTableColumns}
        data={organizationAddOnsData}
        loading={organizationLoading}
      />
    </TableContainer>
  );
};

export default AddOnTile;

import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  Banner,
  Box,
  Button,
  Chip,
  ColorsV2,
  Flex,
  Pane,
  Text,
  useThemeColors,
} from '@electricjs/arc';

import DeviceOverviewContent from '@/components/DeviceOverview/DeviceIssues/DeviceIssuesContent';
import EmployeeCard from '@/components/People/EmployeeCard';
import { DeviceIssueType, DeviceIssueTypeOld } from '@/types/devices';
import { Employee, EmployeeWithAssets } from '@/types/employees';

import { OSBlock } from '@/components/DeviceOverview/DeviceIssues/OSBlock';
import { useHashParams } from '@/hooks/useQueryString';
import { NavigationParamKeys } from '@/components/DeviceOverview/types';
import { DeviceIssuesInfoModal } from '@/components/DeviceOverview/IssueModals/InfoModal';
import { useGlobalUI } from '@/components/GlobalUIProvider';
import { DeviceIssuesInfoModalText } from '@/components/DeviceOverview/IssueModals/InfoModalText';
import { IssueTabs, TabContent } from '@/components/DeviceOverview/IssueTabs';
import { Asset } from '@/types/assets';

const DeviceOwnerGridContainer = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 30rem));
  gap: 2rem;
`;

const StyledBanner = styled(Banner)`
  max-width: 100%;
  margin-top: 2rem;
`;

const ISSUE_MESSAGES = {
  [DeviceIssueType.Firewall]: 'All devices have firewall',
  [DeviceIssueType.Gatekeeper]: 'All devices have Gatekeeper',
  [DeviceIssueType.OS]: 'All devices have up-to-date OS',
  [DeviceIssueType.Encryption]: 'All devices have encryption',
  [DeviceIssueType.Storage]: 'All devices have more than 15% free disk space.',
  [DeviceIssueType.RAM]: 'All devices have RAM higher than 8GB.',
  /* TODO HY-218 We're temporarily disabling Battery health while we improve the logic behind it */
  // [DeviceIssueType.BatteryHealth]: 'All devices have good battery health',
} as const;

const NoIssuesBanner = ({
  selectedTab,
}: {
  selectedTab: DeviceIssueTypeOld;
}) => {
  const getMessage = () => ISSUE_MESSAGES[selectedTab] ?? 'No issues found';

  return (
    <StyledBanner
      id="no-issues-banner"
      isFlat
      stack
      width="72rem"
      intent="success"
      title={getMessage()}
    />
  );
};

type DeviceIssuesProps = {
  employees: EmployeeWithAssets[] | undefined;
  devices: Asset[];
  deviceIssuesByType: Record<string, Record<string, Asset[]>>;
  tabContent: TabContent[];
  isMDMSetUp?: boolean;
  isDemo?: boolean;
};
const DeviceIssues = ({
  employees,
  devices,
  tabContent,
  deviceIssuesByType,
  isMDMSetUp = false,
  isDemo = false,
}: DeviceIssuesProps) => {
  const { queryParams, updateHashParams } = useHashParams();
  const { openModal, closeModal } = useGlobalUI();
  const deviceIssueTab = queryParams.get(NavigationParamKeys.DEVICE_ISSUE_TAB);
  const selectedTab: DeviceIssueType =
    deviceIssueTab &&
    Object.values(DeviceIssueType).some(v => v === deviceIssueTab)
      ? (deviceIssueTab as DeviceIssueTypeOld)
      : tabContent[0].property;

  const navigate = useNavigate();

  const selectedTabDevicesWithIssues = deviceIssuesByType[selectedTab] || {};
  const employeesWithDeviceIssues =
    employees?.filter(
      employee => selectedTabDevicesWithIssues[employee.id]?.length
    ) ?? [];

  const handleTabClick = (property: DeviceIssueType) => {
    if (isDemo) return;

    updateHashParams(NavigationParamKeys.DEVICE_ISSUE_TAB, property);
  };

  const handleEmployeeClick = (employee: Employee) => {
    if (isDemo) return;
    navigate(`/employees/${employee.id}`);
  };

  const [textColor, backgroundColor] = useThemeColors([
    ColorsV2.SECONDARY,
    ColorsV2.SECONDARY_LIGHTEST,
  ]);

  // These are not supported in the older version of the device issues page
  if (
    selectedTab === DeviceIssueType.OsqueryNotReporting ||
    selectedTab === DeviceIssueType.MdmNotReporting ||
    selectedTab === DeviceIssueType.DeviceLock
  ) {
    return null;
  }

  return (
    <Pane elevated flexDirection="column" p={6} mt={5}>
      <Flex>
        <Text variant="heading-2" pr="1rem">
          Device issues
        </Text>
        {isMDMSetUp && (
          <Chip
            id="security-enforced-chip"
            backgroundColor={backgroundColor}
            textColor={textColor}
            icon="bolt"
            variant="medium">
            Security enforced by Electric
          </Chip>
        )}
      </Flex>
      <IssueTabs
        tabContent={tabContent}
        selectedTab={selectedTab}
        handleTabClick={handleTabClick}
      />
      <Button
        id="open-modal"
        onClick={() =>
          openModal(
            <DeviceIssuesInfoModal
              visible
              hide={closeModal}
              deviceIssueType={selectedTab}
            />
          )
        }
        mt="3rem">
        {DeviceIssuesInfoModalText[selectedTab].buttonText}
      </Button>
      {selectedTab !== DeviceIssueType.OS ? (
        <>
          <DeviceOverviewContent
            selectedTab={selectedTab}
            employeesWithDeviceIssues={employeesWithDeviceIssues}
            isMDMSetup={isMDMSetUp}
            isDemo={isDemo}
          />
          {employeesWithDeviceIssues !== undefined &&
          employeesWithDeviceIssues.length > 0 ? (
            <DeviceOwnerGridContainer mt={6}>
              {employeesWithDeviceIssues.map(employee => {
                return (
                  <EmployeeCard
                    key={employee.id}
                    firstName={employee.firstName}
                    lastName={employee.lastName}
                    jobTitle={employee.jobTitle || ''}
                    assets={employee.assets || []}
                    deviceIssues={{ [selectedTab]: 'true' }}
                    onClick={() => handleEmployeeClick(employee)}
                  />
                );
              })}
            </DeviceOwnerGridContainer>
          ) : (
            <NoIssuesBanner selectedTab={selectedTab} />
          )}
        </>
      ) : (
        <OSBlock
          deviceAssets={devices}
          employeesWithDeviceIssues={employeesWithDeviceIssues}
        />
      )}
    </Pane>
  );
};

export default DeviceIssues;

import { SetStateAction, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SortingState } from '@tanstack/react-table';

import { Flex, Header, OneColumnLayout } from '@electricjs/arc';
import {
  AssetStatus,
  ProductTypeName,
  SortOrder,
} from '@electricjs/core_entity-client';

import { SortDirections } from 'common/Table/types';
import { exportCSV } from 'common/utils/exportCsv';
import { useGlobalUI } from '@/components/GlobalUIProvider';
import MDMCertificateExpirationBanner from '@/components/MDMEnrollment/MDMCertificateExpirationBanner';
import PageSpinner from '@/components/PageSpinner';
import { DEFAULT_PAGE_SIZE } from '@/constants/pagination';
import useDebounceValue from '@/hooks/useDebounceValue';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import AssetsEmptyState from '@/pages/Assets/AssetsEmptyState';
import { assetStatusDisplayNameMap } from '@/pages/Assets/AssetStatus';
import { useLazyListAssetsV2Query } from '@/redux/slices/assetApiSlice';
import {
  FilteringParamKeys,
  PaginationParamKeys,
} from '@/types/queryParamKeys';
import AssetsTable, { AssetStatusValue, SortableColumns } from './AssetsTable';
import useFilteredAssetsData from './useFilteredAssetsData';
import { Warranty } from './types';

const Assets = () => {
  const { showSuccessToast, showErrorToast } = useGlobalUI();
  const organizationId = useGetOrganizationId();

  const [isExportingAssets, setIsExportingAssets] = useState<boolean>(false);

  const [queryParams, writeQueryParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(
    parseInt(queryParams.get(PaginationParamKeys.Page) || '0')
  );
  const [searchTerm, setSearchTerm] = useState(
    queryParams.get(PaginationParamKeys.SearchTerm) || ''
  );
  const [selectedStatus, setSelectedStatus] = useState<AssetStatusValue>(
    (queryParams.get(FilteringParamKeys.Status) as AssetStatusValue) ||
      AssetStatusValue.Active
  );
  const [assetStatus, setAssetStatus] = useState<AssetStatus[]>(
    (queryParams
      .get(FilteringParamKeys.AssetStatus)
      ?.split(',') as AssetStatus[]) || []
  );
  const [productType, setProductType] = useState<ProductTypeName[]>(
    (queryParams
      .get(FilteringParamKeys.ProductTypeName)
      ?.split(',') as ProductTypeName[]) || []
  );
  const [warranty, setWarranty] = useState<Warranty[]>(
    (queryParams.get(FilteringParamKeys.Warranty)?.split(',') as Warranty[]) ||
      []
  );
  const excludeAssigned =
    queryParams.get(FilteringParamKeys.ExcludeAssigned) === 'true';

  const debouncedSearchTerm = useDebounceValue<string>(searchTerm, 500);
  const defaultSortableColumn = SortableColumns.AssetName;
  const initialSorting = [
    {
      id: queryParams.get(PaginationParamKeys.OrderBy) || defaultSortableColumn,
      desc:
        queryParams.get(PaginationParamKeys.SortOrder) === SortDirections.Desc,
    },
  ];
  const [sorting, setSorting] = useState<SortingState>(initialSorting);

  const { assets, totalAssets, assetsCount, isFetching, isLoading, error } =
    useFilteredAssetsData({
      searchTerm: debouncedSearchTerm,
      assetStatusValue: selectedStatus,
      status: assetStatus,
      productType: productType,
      warranty,
      excludeAssigned,
    });

  const handleSorting = (sortingParams: SetStateAction<SortingState>) => {
    // Extends the default sorting function to reset the pagination on change
    setCurrentPage(0);
    setSorting(sortingParams);
  };

  // This filter is for Active/Archive
  const handleStatusFilter = (status: AssetStatusValue) => {
    setCurrentPage(0);
    setSelectedStatus(status);
    // Reset other filters
    setAssetStatus([]);
    setProductType([]);
    setWarranty([]);
  };

  // This filter is for Asset Status
  const handleAssetStatusFilter = (
    assetStatus: AssetStatus[] | ((prev: AssetStatus[]) => AssetStatus[])
  ) => {
    setAssetStatus(assetStatus);
    setCurrentPage(0);
  };

  const handleProductTypeFilter = (
    productType:
      | ProductTypeName[]
      | ((prev: ProductTypeName[]) => ProductTypeName[])
  ) => {
    setProductType(productType);
    setCurrentPage(0);
  };

  const handleWarrantyFilter = (
    warranty: Warranty[] | ((prev: Warranty[]) => Warranty[])
  ) => {
    setWarranty(warranty);
    setCurrentPage(0);
  };

  useEffect(() => {
    if (
      debouncedSearchTerm !== queryParams.get(PaginationParamKeys.SearchTerm)
    ) {
      setCurrentPage(0);
    }
  }, [debouncedSearchTerm, queryParams]);

  useEffect(() => {
    // Prevents the `page` url parameter from setting a page greater than the last possible one
    // NOTE: We can eventually move this logic to Arc to avoid repetition
    if (isFetching) return;

    const pageQueryParam = queryParams.get(PaginationParamKeys.Page);
    if (!pageQueryParam) return;

    const pageFromParams = parseInt(pageQueryParam);
    const lastPage = Math.floor(assetsCount / DEFAULT_PAGE_SIZE);
    if (pageFromParams <= lastPage) return;

    setCurrentPage(lastPage);
  }, [assetsCount, isFetching, queryParams]);

  useEffect(() => {
    queryParams.set(PaginationParamKeys.Page, currentPage.toString());
    queryParams.set(PaginationParamKeys.SearchTerm, debouncedSearchTerm || '');
    queryParams.set(PaginationParamKeys.OrderBy, sorting[0].id);
    queryParams.set(
      PaginationParamKeys.SortOrder,
      sorting[0].desc ? SortDirections.Desc : SortDirections.Asc
    );
    queryParams.set(FilteringParamKeys.Status, selectedStatus || '');
    if (assetStatus.length > 0) {
      queryParams.set(FilteringParamKeys.AssetStatus, assetStatus.join(','));
    } else {
      queryParams.delete(FilteringParamKeys.AssetStatus);
    }
    if (productType.length > 0) {
      queryParams.set(
        FilteringParamKeys.ProductTypeName,
        productType.join(',')
      );
    } else {
      queryParams.delete(FilteringParamKeys.ProductTypeName);
    }
    if (warranty.length > 0) {
      queryParams.set(FilteringParamKeys.Warranty, warranty.join(','));
    } else {
      queryParams.delete(FilteringParamKeys.Warranty);
    }

    writeQueryParams(queryParams, { replace: true });
  }, [
    currentPage,
    debouncedSearchTerm,
    selectedStatus,
    sorting,
    assetStatus,
    productType,
    warranty,
    queryParams,
    writeQueryParams,
  ]);

  const showAssetsEmptyState = (totalAssets ?? 0) === 0 && !isLoading && !error;

  // This is used to fetch all assets without filters for exporting
  const [listAssets] = useLazyListAssetsV2Query();

  const handleExport = async () => {
    setIsExportingAssets(true);

    try {
      const fullAssetsData = await listAssets(
        {
          organizationId: organizationId,
          limit: 1000,
          offset: 0,
          orderBy: 'name',
          sortOrder: 'asc' as SortOrder,
        },
        true
      ).unwrap();

      const assetsCSVData = fullAssetsData?.results?.map(asset => ({
        Name: asset.name,
        'Asset Id': asset.customAssetId,
        Type: asset.productType.name,
        Site: asset.site?.name,
        'Assigned To': asset.assignedTo
          ? `${asset.assignedToEmployee?.firstName} ${asset.assignedToEmployee?.lastName}`
          : '',
        Status: assetStatusDisplayNameMap[asset.status as AssetStatus],
        'Serial Number': asset.serialNumber,
        'Purchase Date': asset.purchaseDate,
        'Purchase Price': asset.purchasePrice?.toString(),
        'Purchased From': asset.purchasedFromUrl,
        Brand: asset.brand,
        Model: asset.model,
        Notes: asset.notes,
        Condition: asset.condition,
        Image: asset.imageUrl,
        'Device ID': asset.device?.id,
      }));

      exportCSV(assetsCSVData, 'assets-list.csv');

      showSuccessToast({
        id: 'assets-export-success-toast',
        message: 'Assets list exported successfully.',
        stack: true,
      });
    } catch (error) {
      console.error('Unable to export assets list', error);
      showErrorToast({
        id: 'assets-export-error-toast',
        message: 'Something went wrong. Please try again.',
        stack: true,
      });
    } finally {
      setIsExportingAssets(false);
    }
  };

  if (isLoading) {
    return <PageSpinner />;
  }

  return (
    <OneColumnLayout>
      <MDMCertificateExpirationBanner />
      <Header
        title="Assets"
        subtitle="An overview of your assets and devices."
      />
      {showAssetsEmptyState ? (
        <AssetsEmptyState />
      ) : (
        <Flex vertical rowGap="4rem" width="100%">
          <AssetsTable
            assets={assets}
            totalAssets={totalAssets ?? 0}
            assetsCount={assetsCount}
            isFetchingAssets={isFetching}
            handleExport={handleExport}
            isExportingAssets={isExportingAssets}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            handleSearch={term => setSearchTerm(term)}
            searchTerm={searchTerm}
            handleStatusFilter={handleStatusFilter}
            selectedStatus={selectedStatus}
            handleAssetStatusFilter={handleAssetStatusFilter}
            assetStatus={assetStatus}
            handleProductTypeFilter={handleProductTypeFilter}
            productType={productType}
            handleWarrantyFilter={handleWarrantyFilter}
            warranty={warranty}
            sorting={sorting}
            handleSorting={handleSorting}
            pageSize={DEFAULT_PAGE_SIZE}
          />
        </Flex>
      )}
    </OneColumnLayout>
  );
};

export default Assets;

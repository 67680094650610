import { Link, Text } from '@electricjs/arc';

import { DeviceIssueType } from '@/types/devices';
import { EmployeeWithAssets } from '@/types/employees';

import EmailModal from './EmailModal';
import { storageLinks } from '@/constants/deviceIssueLinks';

type DiskSpaceEmailModalProps = {
  hide: () => void;
  employees: EmployeeWithAssets[];
};

const DiskSpaceEmailModal = ({ hide, employees }: DiskSpaceEmailModalProps) => {
  return (
    <EmailModal
      hide={hide}
      issueType={DeviceIssueType.Storage}
      employees={employees}
      title="Send employees disk space instructions"
      showDevice={true}
      showDiskSpace={true}>
      <Text>
        We’ll send instructions to your employees on how to free up storage on
        their devices.
      </Text>
      <Text mt={3}>
        <Link target="_blank" rel="noopener" href={storageLinks.mac}>
          Disk space instructions for Mac devices
        </Link>
      </Text>
      <Text mt={3}>
        <Link target="_blank" rel="noopener" href={storageLinks.windows}>
          Disk space instructions for Windows devices
        </Link>
      </Text>
      <Text mt={5}>Employees that have low disk space:</Text>
    </EmailModal>
  );
};

export default DiskSpaceEmailModal;

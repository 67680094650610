import { useState, useEffect } from 'react';
import { ABMEnrollmentStatusEnum } from '@electricjs/core_entity-client';

import ABMCompleted from '@/components/ABMManagement/ABMCompleted';
import ABMSetupForm from '@/components/ABMManagement/ABMSetupForm';
import ABMTile from '@/components/ABMManagement/ABMTile';
import { ABMStatus } from '@/components/ABMManagement/types';
import { useGlobalUI } from '@/components/GlobalUIProvider';
import { useGetOrganizationAdditionalInfoQuery } from '@/redux/slices/organizationApiSlice';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import ABMRemoveModal from '@/components/ABMManagement/ABMRemoveModal';
import { ABMLoadingContainer } from '@/components/ABMManagement/ABMComponents';

const ABMManagement = () => {
  const { showSuccessToast, showErrorToast, openModal, closeModal } =
    useGlobalUI();
  const [abmStatus, setAbmStatus] = useState<keyof typeof ABMStatus>(
    ABMStatus.DEFAULT
  );
  const organizationId = useGetOrganizationId();

  const {
    data: orgAdditionalInfoData,
    isFetching: isFetchingOrgAdditionalInfoData,
  } = useGetOrganizationAdditionalInfoQuery({ organizationId });

  const onSaveAbm = () => {
    showSuccessToast({
      id: 'abm-save-success-toast',
      message: 'Apple Business Manager has been successfully connected.',
    });
    setAbmStatus(ABMStatus.COMPLETED);
  };

  const onErrorAbm = () => {
    showErrorToast({
      id: 'abm-error-toast',
      message: 'Failed to connect Apple Business Manager, please try again.',
    });
    setAbmStatus(ABMStatus.DEFAULT);
  };

  const onUpdateAbm = () => {
    showSuccessToast({
      id: 'abm-update-success-toast',
      message: 'You have successfully updated your ABM configuration!',
    });
    setAbmStatus(ABMStatus.COMPLETED);
  };

  const onRemoveAbm = () => {
    showSuccessToast({
      id: 'abm-remove-success-toast',
      message: 'You have successfully removed your ABM connection.',
    });
    setAbmStatus(ABMStatus.DEFAULT);
    closeModal();
  };

  const onErrorRemoveAbm = () => {
    showErrorToast({
      id: 'abm-remove-error-toast',
      message: 'Failed to remove Apple Business Manager, please try again.',
    });
    setAbmStatus(ABMStatus.COMPLETED);
    closeModal();
  };

  // Fetches the ABM status from the API and sets the ABM status locally
  useEffect(() => {
    switch (orgAdditionalInfoData?.abmStatus) {
      case ABMEnrollmentStatusEnum.Connected:
        setAbmStatus(ABMStatus.COMPLETED);
        break;
      case ABMEnrollmentStatusEnum.NotApplied:
        setAbmStatus(ABMStatus.NOT_APPLIED);
        break;
      case ABMEnrollmentStatusEnum.Unconnected:
      case ABMEnrollmentStatusEnum.Unknown:
      default:
        setAbmStatus(ABMStatus.DEFAULT);
        break;
    }
  }, [orgAdditionalInfoData?.abmStatus]);

  if (isFetchingOrgAdditionalInfoData) {
    return <ABMLoadingContainer />;
  }

  // Renders different components to match local ABM status
  switch (abmStatus) {
    case ABMStatus.SETUP:
      return (
        <ABMSetupForm
          onCancelAbm={() => setAbmStatus(ABMStatus.DEFAULT)}
          onSaveAbm={onSaveAbm}
          onErrorAbm={onErrorAbm}
        />
      );
    case ABMStatus.COMPLETED:
      return (
        <ABMCompleted
          abmID={orgAdditionalInfoData?.abmID}
          onEdit={() => setAbmStatus(ABMStatus.EDIT)}
          onRemove={() =>
            openModal(
              <ABMRemoveModal
                onCloseAbm={closeModal}
                onErrorAbm={onErrorRemoveAbm}
                onRemoveAbm={onRemoveAbm}
              />
            )
          }
        />
      );
    case ABMStatus.EDIT:
      return (
        <ABMSetupForm
          onCancelAbm={() => setAbmStatus(ABMStatus.COMPLETED)}
          onSaveAbm={onUpdateAbm}
          onErrorAbm={onErrorAbm}
          abmID={orgAdditionalInfoData?.abmID}
          isCheckboxSelected
        />
      );
    case ABMStatus.NOT_APPLIED:
    case ABMStatus.DEFAULT:
    default:
      return <ABMTile onConnectAbm={() => setAbmStatus(ABMStatus.SETUP)} />;
  }
};

export default ABMManagement;

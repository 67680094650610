import { Flex, Icon, Text, Tooltip, useBreakpoint } from '@electricjs/arc';
import { ReactNode } from 'react';
import { UnavailableInfoChip } from '@/components/Assets/AssetDetails/UnavailableInfoChip';

type DetailProps = {
  label: string;
  value: string | ReactNode;
  labelTooltip?: string;
  fixed?: boolean;
  disabled?: boolean;
};

const AssetDetail = ({
  label,
  value,
  fixed,
  labelTooltip,
  disabled = false,
}: DetailProps) => {
  const { xl } = useBreakpoint();

  const getValue = () => {
    return typeof value === 'string' ? (
      <Tooltip
        content="Populated by the Electric App"
        disabled={!fixed}
        placement="top">
        <Text
          intent={fixed ? 'disabled' : 'primary'}
          textAlign="end"
          preventWidows>
          {value}
        </Text>
      </Tooltip>
    ) : (
      value
    );
  };

  return (
    <Flex width="100%" px={4} justifyContent="space-between">
      <Flex width="50%" alignItems="center">
        <Text variant="label-large">{label}</Text>
        {labelTooltip && (
          <Tooltip content={labelTooltip} placement="top" display="flex">
            <Icon icon="information-circle" size="small" ml="1rem" />
          </Tooltip>
        )}
      </Flex>
      <Flex width="50%" justifyContent={xl ? 'flex-start' : 'flex-end'}>
        {disabled ? <UnavailableInfoChip /> : getValue()}
      </Flex>
    </Flex>
  );
};

export default AssetDetail;

import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useGetOrganizationProductTypesQuery } from '@/redux/slices/productApiSlice';

type params = {
  skip?: boolean;
};

export const useOrganizationProductTypes = (params?: params) => {
  const organizationId = useGetOrganizationId();

  const {
    data: productTypes,
    isFetching,
    isError,
  } = useGetOrganizationProductTypesQuery(organizationId, {
    skip: params?.skip,
  });

  return {
    productTypes: productTypes ?? [],
    isFetching,
    isError,
  };
};

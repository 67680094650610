export const ABMStatus = {
  DEFAULT: 'DEFAULT',
  SETUP: 'SETUP',
  COMPLETED: 'COMPLETED',
  NOT_APPLIED: 'NOT_APPLIED',
  LOADING: 'LOADING',
  EDIT: 'EDIT',
} as const;

export const SurveyOptions = {
  YES: 'YES',
  NO: 'NO',
  SKIP: 'SKIP',
} as const;

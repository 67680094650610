import { ColorsV2, FieldLabel, Text } from '@electricjs/arc';
import type { ReactNode } from 'react';
import styled from 'styled-components';

export const Required = styled(Text)`
  line-height: unset;
  height: 1.2rem;
`;

export type RequiredLabelProps = {
  children: ReactNode;
};

export const RequiredLabel = ({ children }: RequiredLabelProps) => {
  return (
    <FieldLabel columnGap="0.5rem">
      {children}
      <Required color={ColorsV2.ERROR}>*</Required>
    </FieldLabel>
  );
};

import { Box, Flex, Icon, Text } from '@electricjs/arc';
import { Auth0CandidateUser, AuthProvider } from './auth0-link-user-types';

import google_workspace from '@/assets/google_workspace.svg';
import electric_logo_only_black from '@/assets/electric_logo_only_black.svg';
import adp_logo from '@/assets/integrations/adp-light.png';
import adp from '@/assets/integrations/adp.png';

type ConnectionImageMap = {
  [key in AuthProvider]: string;
};

const connectionImageMap: ConnectionImageMap = {
  [AuthProvider.Auth0]: electric_logo_only_black,
  [AuthProvider.Google]: google_workspace,
  [AuthProvider.ADPWorkforceNow]: adp_logo,
  [AuthProvider.ADPRun]: adp,
  [AuthProvider.Unknown]: '',
};

const AUTH0_ID_PARTS_REGEX = /(.*)(?:\|.*)/;

export const UserProfile = ({
  picture,
  name,
  email,
  sub,
  user_id,
}: Auth0CandidateUser) => {
  // We need to check if the user_id is a sub or a user_id
  // because the user_id is a sub in the case of the current user.
  const idParts = sub
    ? sub.match(AUTH0_ID_PARTS_REGEX)
    : user_id?.match(AUTH0_ID_PARTS_REGEX);

  const provider = idParts
    ? (idParts[1] as AuthProvider)
    : AuthProvider.Unknown;
  // Get the connection logo for the user.
  const connectionLogo = connectionImageMap[provider];
  const isADPProvider =
    provider === AuthProvider.ADPWorkforceNow ||
    provider === AuthProvider.ADPRun;

  return (
    <Flex alignItems="center">
      <Box alignItems="center">
        <img
          src={picture}
          alt="User"
          height="42px"
          style={{ borderRadius: '50%' }}
        />
        <img
          src={connectionLogo}
          alt="Connection"
          height={isADPProvider ? '42px' : '32px'}
          style={{
            marginLeft: '-1.6rem',
            marginBottom: isADPProvider ? '-1.5rem' : '-1.0rem',
            background: 'white',
            borderRadius: isADPProvider ? '0%' : '50%',
          }}
        />
      </Box>
      <Flex vertical justifyContent="center" paddingLeft="0.8rem">
        <Text>{name}</Text>
        <Text variant="body-2">{email}</Text>
      </Flex>
    </Flex>
  );
};

export type UserProfilesLinkVisualProps = {
  currentUser: Auth0CandidateUser;
  candidateUser: Auth0CandidateUser;
};
export const UserProfilesLinkVisual = ({
  currentUser,
  candidateUser,
}: UserProfilesLinkVisualProps) => {
  return (
    <Flex alignItems="center" justifyContent="center">
      <UserProfile {...currentUser} />
      <Box paddingX="2.4rem">
        <Icon icon="link" size="large" intent="primary" />
      </Box>
      <UserProfile {...candidateUser} />
    </Flex>
  );
};

import { EntitlementSlugEnum } from '@electricjs/core_entity-client';
import { Flex, TabPanel, Text } from '@electricjs/arc';

import { useOrganizationHasEntitlement } from '@/hooks/useOrganizationHasEntitlement';
import ABMManagement from '@/components/ABMManagement/ABMManagement';
import { MdmEnrollment } from '@/components/MDMEnrollment/MdmEnrollment';

const DevicePane = () => {
  const hasMdmEntitlement = useOrganizationHasEntitlement(
    EntitlementSlugEnum.JumpcloudMdm
  );

  return (
    <TabPanel>
      <Flex flexDirection="column" px="2rem" gap="2rem">
        <Text variant="heading-2">Device management</Text>
        {hasMdmEntitlement && <MdmEnrollment />}
        <ABMManagement />
      </Flex>
    </TabPanel>
  );
};

export default DevicePane;

import { UseFormSetValue } from 'react-hook-form';
import { StepContainer } from '@/components/MDMEnrollment/MDMAppleForm/StepComponents';
import { FilePicker, Text } from '@electricjs/arc';
import { FormData } from '@/components/MDMEnrollment/MDMAppleForm/MdmAppleSetupFormSchema';
import { useCallback, useState } from 'react';

export const UploadMdmPushCertificateStep = ({
  setValue,
  reset,
}: {
  setValue: UseFormSetValue<FormData>;
  reset: () => void;
}) => {
  const [fileErrorMessage, setFileErrorMessage] = useState('');
  /**
   * Handles the selection of a file, reads its content as text, and encodes it
   * in Base64. The encoded certificate is then stored in the form state.
   *
   * @param {ProgressEvent<FileReader} event - The event provided by the FileReader.onload function.
   */
  const handleFileSelect = useCallback(
    async (event: ProgressEvent<FileReader>) => {
      // Read the content of the first selected file as text, or set an empty string if no file is selected
      const certificate = event?.target?.result;

      if (certificate && typeof certificate === 'string') {
        // Encode the certificate in Base64 and set it in the form state
        setValue('appleSignedCert', btoa(certificate), {
          shouldValidate: true,
        });
      } else {
        setFileErrorMessage(
          'The content of the certificate could not be validated, please try again.'
        );
      }
    },
    [setValue]
  );

  const handleRemoveSelectedFile = useCallback(() => {
    setFileErrorMessage('');
    reset();
  }, [reset]);

  return (
    <StepContainer step={5}>
      <Text variant="label-large">Upload MDM Push Certificate</Text>
      <Text variant="body">
        To finish setting up your connection, upload&nbsp;
        <b>MDM_JumpCloud Inc._Certificate.pem.</b> You downloaded this file from
        Apple and can find it in your Downloads folder if you saved it to the
        default location.
      </Text>
      <FilePicker
        onLoad={handleFileSelect}
        onRemove={handleRemoveSelectedFile}
        acceptedExtensions=".pem"
        errorMessage={fileErrorMessage}
      />
    </StepContainer>
  );
};

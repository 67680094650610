import { Box, Select } from '@electricjs/arc';

import ApplicationOption, { ApplicationOptionProps } from './ApplicationOption';

type ApplicationOptionInfo = {
  name: string;
  id: string;
  icon?: string;
};

type ApplicationSelectProps = {
  id?: string;
  options: ApplicationOptionInfo[] | undefined;
  isLoading?: boolean;
};

const ApplicationSelect = ({
  id,
  options,
  isLoading,
  ...props
}: ApplicationSelectProps) => {
  const unlistedApp = {
    id: '',
    name: 'Application not listed',
  };
  const appOptions = [...(options ?? []), unlistedApp];

  return (
    <Box>
      <Select
        {...props}
        id={id ?? 'application-select'}
        options={appOptions}
        isLoading={isLoading}
        isSearchable
        isClearable
        getOptionLabel={(application: ApplicationOptionInfo) =>
          `${application?.name}`
        }
        getOptionValue={(application: ApplicationOptionInfo) =>
          application?.name
        }
        customComponents={{
          Option: (props: ApplicationOptionProps) => {
            return <ApplicationOption {...props} />;
          },
        }}
      />
    </Box>
  );
};

export default ApplicationSelect;

import { Employee as EmployeeDto } from '@electricjs/core_entity-client';

import { EmployeeSource, EmployeeStatus } from '@/types/employees';

export const simpleEmployee: EmployeeDto = {
  id: 'simple-employee-id',
  email: 'simple-user@simple-org.com',
  personal_email: 'simple-user@personal-email.com',
  first_name: 'John',
  last_name: 'Doe',
  job_title: 'Engineer',
  created_at: '2023-05-22',
  updated_at: '2023-05-22',
  status: EmployeeStatus.Active,
  source: EmployeeSource.Manual,
};

export const unknownEmployee1: EmployeeDto = {
  id: 'unknown-employee-id-1',
  email: 'unknown.employee-1@connected-org.com',
  first_name: 'Person',
  last_name: 'One',
  job_title: 'Engineer',
  created_at: '2023-05-22',
  updated_at: '2023-05-22',
  status: EmployeeStatus.Unknown,
  source: EmployeeSource.Hris,
};

export const unknownEmployee2: EmployeeDto = {
  id: 'unknown-employee-id-2',
  email: 'unknown.employee-2@connected-org.com',
  first_name: 'Person',
  last_name: 'Two with very long name',
  job_title: 'Product Manager with very job title',
  created_at: '2023-05-22',
  updated_at: '2023-05-22',
  status: EmployeeStatus.Unknown,
  source: EmployeeSource.Hris,
};

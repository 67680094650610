import { useEffect, useState } from 'react';

import { DeviceManagementCard } from '@/components/MDMEnrollment/DeviceManagementCard';
import { DeviceQuestion } from '@/components/MDMEnrollment/DeviceQuestion';
import { MdmAppleSetupForm } from '@/components/MDMEnrollment/MDMAppleForm/MdmAppleSetupForm';
import { HeaderAction } from '@/components/MDMEnrollment/MdmHeaderActions';
import { StartSetupCard } from '@/components/MDMEnrollment/StartSetupCard';
import { MdmEnrollmentStep } from '@/components/MDMEnrollment/types';
import { MdmEnrollmentStatus } from './MdmEnrollmentStatus';
import {
  useGetJumpcloudProvisionStatusQuery,
  useGetMDMInformationQuery,
  useJumpCloudProvisionMutation,
} from '@/redux/slices/mdmManagementApiSlice';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { Flex, KebabMenu, Spinner } from '@electricjs/arc';
import { useAppSelector } from '@/redux/store';
import { useGlobalUI } from '@/components/GlobalUIProvider';
import { ProvisionStatusEnum } from '@/types/mdmManagement';
import { useSearchParams } from 'react-router-dom';
import { SettingsPageTabs } from '@/pages/Organization/Settings/Settings';
import { NavigationParamKeys } from '@/types/queryParamKeys';
import { useLocation } from 'react-router-dom';

/**
 * Loading state component that displays a spinner.
 */
const LoadingState = () => {
  return (
    <Flex
      width="100%"
      height="12rem"
      justifyContent="center"
      alignItems="center">
      <Spinner />
    </Flex>
  );
};

/**
 * Main component for the MDM enrollment process.
 *
 * This component handles the different steps involved in setting up MDM,
 * including displaying the appropriate forms and status based on the user's progress.
 */
export const MdmEnrollment = () => {
  const [enrollmentStep, setEnrollmentStep] = useState<MdmEnrollmentStep>(
    MdmEnrollmentStep.WELCOME
  );

  const { state } = useLocation();
  const { renewCertificate } = state ?? {};

  const { showErrorToast } = useGlobalUI();

  const organizationId = useGetOrganizationId();

  const organizationName =
    useAppSelector(state => state.loggedUser)?.organizationName ?? '';

  const [searchParams] = useSearchParams();

  const [hasMacDevices, setHasMacDevices] = useState(true);

  const [provision, { isLoading: provisionLoading }] =
    useJumpCloudProvisionMutation();

  const { data: mdmInformation, isLoading: isLoadingMdmInformation } =
    useGetMDMInformationQuery({
      organizationId,
    });

  /**
   * Handles saving the device question information and progresses the enrollment step.
   */
  const handleSaveDeviceQuestion = () => {
    provision({
      organizationId,
      name: organizationName,
      hasMacDevices,
    })
      .unwrap()
      .then(() => {
        if (hasMacDevices) {
          setEnrollmentStep(MdmEnrollmentStep.MDM_APPLE_SETUP_FORM);
        } else {
          setEnrollmentStep(MdmEnrollmentStep.ENROLLMENT_STATUS);
        }
      })
      .catch(error => {
        window?.DD_RUM?.addError(
          `Unable to provision JumpCloud MDM: ${JSON.stringify(error)}`,
          {
            location: 'useStoredState hook',
          }
        );
        showErrorToast({
          id: 'jumpcloud-mdm-setup-error-toast',
          title: 'An error occurred while setting up your MDM account',
          message:
            'Our team has been alerted of this error and will resolve it as soon as possible.',
        });
      });
  };

  const {
    data: provisionStatus,
    isLoading: loadingProvisionStatus,
    refetch: refetchStatus,
  } = useGetJumpcloudProvisionStatusQuery(organizationId);

  const setupStarted = !!provisionStatus;

  /**
   * Effect hook to update the enrollment step based on provision status and loading state.
   */
  useEffect(() => {
    if (
      searchParams.get(NavigationParamKeys.Tab) === SettingsPageTabs.Device &&
      !loadingProvisionStatus &&
      setupStarted
    ) {
      if (renewCertificate) {
        setEnrollmentStep(MdmEnrollmentStep.ENROLLMENT_RENEWAL);
      } else if (
        provisionStatus === ProvisionStatusEnum.PENDING_CERTIFICATE_STATUS
      ) {
        // redirect to Apple set up form when pending certificate
        setEnrollmentStep(MdmEnrollmentStep.MDM_APPLE_SETUP_FORM);
      } else {
        setEnrollmentStep(MdmEnrollmentStep.ENROLLMENT_STATUS);
      }
    }
  }, [
    loadingProvisionStatus,
    provisionStatus,
    setupStarted,
    searchParams,
    renewCertificate,
  ]);

  // Not render loading state when it's in Status step
  const showLoadingState =
    loadingProvisionStatus &&
    isLoadingMdmInformation &&
    enrollmentStep !== MdmEnrollmentStep.ENROLLMENT_STATUS;

  return (
    <DeviceManagementCard
      title="Mobile Device Management (MDM)"
      icon="users-gear"
      actions={
        <Flex>
          <HeaderAction step={enrollmentStep} />
          {mdmInformation?.appleCertificate?.expiresAt && (
            <KebabMenu
              id="abm-settings-menu"
              items={[
                {
                  label: 'Edit MDM',
                  iconBefore: 'pencil',
                  onClick: () =>
                    setEnrollmentStep(MdmEnrollmentStep.ENROLLMENT_RENEWAL),
                },
              ]}
            />
          )}
        </Flex>
      }>
      {showLoadingState ? (
        <LoadingState />
      ) : (
        <>
          {enrollmentStep === MdmEnrollmentStep.WELCOME && (
            <StartSetupCard
              onStartSetup={() =>
                setEnrollmentStep(MdmEnrollmentStep.DEVICE_QUESTION)
              }
            />
          )}

          {enrollmentStep === MdmEnrollmentStep.DEVICE_QUESTION && (
            <DeviceQuestion
              hasMacDevices={hasMacDevices}
              onSelectionChange={hasMacDevices =>
                setHasMacDevices(hasMacDevices)
              }
              onSaveInfo={handleSaveDeviceQuestion}
              isLoading={provisionLoading}
            />
          )}

          {enrollmentStep === MdmEnrollmentStep.MDM_APPLE_SETUP_FORM && (
            <MdmAppleSetupForm
              onMdmAppleSetupSuccess={() => {
                setEnrollmentStep(MdmEnrollmentStep.ENROLLMENT_STATUS);
              }}
              onCancel={() => {
                if (setupStarted) {
                  setEnrollmentStep(MdmEnrollmentStep.ENROLLMENT_STATUS);
                } else {
                  setEnrollmentStep(MdmEnrollmentStep.DEVICE_QUESTION);
                }
              }}
            />
          )}

          {enrollmentStep === MdmEnrollmentStep.ENROLLMENT_STATUS && (
            <MdmEnrollmentStatus
              hasMacDevices={hasMacDevices}
              provisionStatus={provisionStatus}
              onSetupAppleDevices={() => {
                setHasMacDevices(true);
                setEnrollmentStep(MdmEnrollmentStep.MDM_APPLE_SETUP_FORM);
              }}
              onRenewCertificate={() => {
                setEnrollmentStep(MdmEnrollmentStep.ENROLLMENT_RENEWAL);
              }}
              onRefetchStatus={() => refetchStatus()}
            />
          )}

          {enrollmentStep === MdmEnrollmentStep.ENROLLMENT_RENEWAL && (
            <MdmAppleSetupForm
              onMdmAppleSetupSuccess={() => {
                setEnrollmentStep(MdmEnrollmentStep.ENROLLMENT_STATUS);
              }}
              onCancel={() => {
                setEnrollmentStep(MdmEnrollmentStep.ENROLLMENT_STATUS);
              }}
              existingCertIfRenewal={mdmInformation}
            />
          )}
        </>
      )}
    </DeviceManagementCard>
  );
};

import { useGetMDMInformationQuery } from '@/redux/slices/mdmManagementApiSlice';
import { differenceInDays } from 'date-fns';
import { useGetOrganizationId } from './useGetOrganizationId';

export enum MdmCertExpirationStatus {
  EXPIRED = 'EXPIRED',
  ABOUT_TO_EXPIRE = 'ABOUT_TO_EXPIRE',
  OK = 'OK',
  NO_CERTIFICATE = 'NO_CERTIFICATE',
}

/**
 * Hook to get the status of the MDM certificate expiration.
 * @returns The status of the MDM certificate expiration.
 * @returns The expiration date of the MDM certificate.
 * @returns The Apple creator ID.
 * @returns Whether the data is loading.
 */
export function useGetMdmCertExpirationStatus() {
  const organizationId = useGetOrganizationId();

  const { data, isLoading } = useGetMDMInformationQuery({ organizationId });
  const expirationDate = data?.appleCertificate?.expiresAt
    ? data.appleCertificate.expiresAt
    : null;

  if (!expirationDate || !data?.appleCertificate) {
    return {
      status: MdmCertExpirationStatus.NO_CERTIFICATE,
      expirationDate: null,
      appleCreatorId: null,
      isLoading,
    };
  }

  const appleCreatorId = data.appleCertificate.creatorAppleId;
  const currentDate = new Date();
  const daysDifference = differenceInDays(expirationDate, currentDate);

  if (daysDifference <= 14 && daysDifference > 0) {
    return {
      status: MdmCertExpirationStatus.ABOUT_TO_EXPIRE,
      expirationDate,
      appleCreatorId,
      isLoading,
    };
  }

  if (daysDifference <= 0) {
    return {
      status: MdmCertExpirationStatus.EXPIRED,
      expirationDate,
      appleCreatorId,
      isLoading,
    };
  }

  return {
    status: MdmCertExpirationStatus.OK,
    expirationDate,
    appleCreatorId,
    isLoading,
  };
}

import { OneColumnLayout } from '@electricjs/arc';

import { PageErrorComponent } from '@/components/PageErrorComponent';

const PageNotFound = () => {
  return (
    <OneColumnLayout>
      <PageErrorComponent
        errorType="404 error"
        title="Page not found"
        message="Sorry, the page you are looking for doesn't exist or has been
            moved."
      />
    </OneColumnLayout>
  );
};

export default PageNotFound;

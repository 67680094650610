import { type ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  StorefrontCartProvider,
  StorefrontCheckoutFormProvider,
  StorefrontStepProvider,
  useStorefrontCart,
  useStorefrontCheckoutForm,
  useStorefrontStep,
} from './components';

import { SelectionParamKeys } from '@/types/queryParamKeys';
import { StorefrontSteps } from '@/types/storefront';
import { SchemaOption } from './components';

type StorefrontProviderProps = {
  children: ReactNode;
};

type StorefrontResetOptions = {
  keepQueryParams: boolean;
};

/**
 * The Storefront provider for its global state (carts, checkout data, etc)
 */
export const StorefrontProvider = ({ children }: StorefrontProviderProps) => {
  return (
    <StorefrontCartProvider>
      <StorefrontCheckoutFormProvider>
        <StorefrontStepProvider>{children}</StorefrontStepProvider>
      </StorefrontCheckoutFormProvider>
    </StorefrontCartProvider>
  );
};

export const useStorefront = () => {
  const storefrontCartValue = useStorefrontCart();
  const storefrontCheckoutValue = useStorefrontCheckoutForm();
  const storefrontStepValue = useStorefrontStep();

  const [queryParams, writeQueryParams] = useSearchParams();

  const employeeId = queryParams.get(SelectionParamKeys.EmployeeId);

  const resetStorefront = (options?: StorefrontResetOptions) => {
    const { setAssetCart, setCatalogCart, setShippingCost, setTotalPrice } =
      storefrontCartValue;
    const {
      storefrontCheckoutForm,
      setSchemaOption,
      setSelectedShippingDestination,
    } = storefrontCheckoutValue;
    const { storefrontStep, setStorefrontStep, setConfirmedOrder } =
      storefrontStepValue;

    // reset all storefront values
    setAssetCart([]);
    setCatalogCart([]);
    setShippingCost(undefined);
    setTotalPrice(0);
    setSelectedShippingDestination(undefined);
    storefrontCheckoutForm?.reset();
    setConfirmedOrder(undefined);

    // clear query params (e.g., employeeId)
    if (!options?.keepQueryParams && employeeId) {
      queryParams.delete(SelectionParamKeys.EmployeeId);
      writeQueryParams(queryParams, {
        // Each change in the query params will replace the current history state, meaning the "back button" will go back to the previous page.
        replace: true,
      });
    }

    // go back to first step
    if (storefrontStep !== StorefrontSteps.SHOPPING) {
      setStorefrontStep(StorefrontSteps.SHOPPING);
      setSchemaOption(SchemaOption.DEFAULT);
    }
  };

  return {
    ...storefrontCartValue,
    ...storefrontCheckoutValue,
    ...storefrontStepValue,
    resetStorefront,
  };
};

import { HRISProviderSlugEnum } from '@electricjs/core_entity-client';

const PARTNER_PROVIDERS = {
  [HRISProviderSlugEnum.AdpWfn]: 'ADP',
  [HRISProviderSlugEnum.AdpR]: 'ADP RUN',
  [HRISProviderSlugEnum.Justworks]: 'JUSTWORKS',
  [HRISProviderSlugEnum.TrinetHrPlatform]: 'TRINET',
};

export const getIsProviderConnected = (
  needsUpgrade: boolean | undefined,
  providerSlug: string | undefined,
  cardSlug: HRISProviderSlugEnum | undefined
) => {
  if (needsUpgrade || !providerSlug) {
    return false;
  }
  // Return true if:
  // The provider is connected and matches the current card's slug.
  // The card does not have a slug and the provider is not among the displayed providers.
  return cardSlug
    ? providerSlug === cardSlug
    : !Object.keys(PARTNER_PROVIDERS).includes(providerSlug);
};

export const getCustomStatusLabel = (
  isConnected: boolean,
  currentProviderSlug: string | undefined,
  currentProviderName: string | undefined
) => {
  return isConnected &&
    currentProviderSlug &&
    currentProviderName &&
    !Object.keys(PARTNER_PROVIDERS).includes(currentProviderSlug)
    ? `Connected: ${currentProviderName}`
    : '';
};

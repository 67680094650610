import {
  ColorsV2,
  Flex,
  getToken,
  Icon,
  IconNames,
  RadioTile,
  Text,
} from '@electricjs/arc';
import styled from 'styled-components';

const StyledRadioTile = styled(RadioTile)`
  border: 1px solid ${getToken(ColorsV2.GRAY_LIGHT)};
  border-radius: 8px;
  box-shadow: 0px 4px 8px -2px ${getToken(ColorsV2.PRIMARY)}1f;
`;

type DeviceRadioTileProps = {
  value: string;
  optionText: string;
  secondaryText?: string;
  icon: IconNames;
  checked: boolean;
  disabled?: boolean;
};

const DeviceRadioTile = ({
  value,
  optionText,
  secondaryText,
  icon,
  checked,
  disabled,
}: DeviceRadioTileProps) => {
  return (
    <StyledRadioTile
      id={`device-radio-tile-${value}`}
      height="15rem"
      width="17rem"
      value={value}
      checked={checked}
      disabled={disabled}>
      <Flex vertical alignItems="center" justifyContent="center" gap="1rem">
        <Icon icon={icon} customSize="2.8rem" />
        <Flex vertical alignItems="center">
          <Text color="inherit" fontWeight="inherit">
            {optionText}
          </Text>
          {!!secondaryText && (
            <Text variant="label-small" color="inherit">
              {secondaryText}
            </Text>
          )}
        </Flex>
      </Flex>
    </StyledRadioTile>
  );
};

export default DeviceRadioTile;

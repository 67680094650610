import styled from 'styled-components';
import { AssetFileWithURL } from '@/types/assets';
import {
  ModalV2,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalFooterActions,
  Text,
  Button,
  Flex,
} from '@electricjs/arc';

type ImageViewModalProps = {
  image: AssetFileWithURL;
  onClose: () => void;
};

const Image = styled.img`
  max-height: 75vh;
  max-width: 75vw;
`;

const ImageViewModal = ({ image, onClose }: ImageViewModalProps) => {
  return (
    <ModalV2
      visible
      hide={onClose}
      ariaLabelledby="view-image-modal-header-text"
      width="auto"
      height="auto"
      maxWidth="80vw">
      <ModalHeader showCloseButton={true} width="auto">
        <Text id="view-image-modal-header-text" variant="heading-2">
          {image.fileName}
        </Text>
      </ModalHeader>
      <ModalBody>
        <Flex justifyContent="center">
          <Image src={image.url} alt="Asset" />
        </Flex>
      </ModalBody>
      <ModalFooter>
        <ModalFooterActions>
          <Button id="close-button" onClick={onClose} variant="fill">
            Close
          </Button>
        </ModalFooterActions>
      </ModalFooter>
    </ModalV2>
  );
};

export default ImageViewModal;

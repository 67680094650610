import styled from 'styled-components';

import { Link } from '@electricjs/arc';

const LinkNoUnderline = styled(Link)`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export default LinkNoUnderline;

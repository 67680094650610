import hec from '@/assets/HEC.png';
import breachSecureNow from '@/assets/breach_secure_now.svg';
import drataImage from '@/assets/drata.png';
import dropsuiteImage from '@/assets/dropsuite.png';
import fullsteam from '@/assets/fullsteam_logo_small.png';
import keeper from '@/assets/keeper.svg';
import perimeter81 from '@/assets/perimeter81.svg';
import threatdown from '@/assets/threatdown.svg';
import { AddOnVendor, AddOnVendors } from '@/types/addons';
import { IconNames } from '@electricjs/arc';

export type AddOnCardDetails = {
  id: AddOnVendor;
  name: string;
  title: string;
  icon: string;
  tileDescription: string;
  description: string;
  content: { highlightText: string; text: string }[];
  referralUrl?: string;
  costText: string;
};

type AddOnData = {
  [key in AddOnVendor]: AddOnCardDetails;
};

export type AddOnService = Omit<
  AddOnCardDetails,
  'id' | 'icon' | 'costText'
> & {
  id: string;
  icon: IconNames;
};

type AddOnServicesData = {
  [key: string]: AddOnService;
};

export const AddOnServiceType = {
  AudioVisualConferencing: 'audio-visual-and-conferencing-services',
  Cybersecurity: 'cybersecurity-services',
  Cloud: 'cloud-services',
  Network: 'network-services',
} as const;

export const addOns: AddOnData = {
  [AddOnVendors.ThreatDown]: {
    id: AddOnVendors.ThreatDown,
    name: 'ThreatDown',
    title: 'Antivirus',
    icon: threatdown,
    tileDescription:
      'ThreatDown’s antivirus solution protects all of your endpoints, protecting devices from malicious attacks.',
    description:
      'Continuous threats require continuous protection. ThreatDown Antivirus Solution protects critical business data from hardware failure, ransomware, human error, and situations where devices are lost or stolen.',
    content: [
      {
        highlightText: 'Safeguard your software:',
        text: 'Fend off malware and ransomware attacks.',
      },
      {
        highlightText: 'Defend your devices:',
        text: 'Detect & remove malware and ransomware from devices.',
      },
      {
        highlightText: 'Proactive protection:',
        text: 'Stop attacks at the source before they reach your company data.',
      },
    ],
    costText: 'Starting at $1.50/user',
  },
  [AddOnVendors.Perimeter81]: {
    id: AddOnVendors.Perimeter81,
    name: 'Perimeter81',
    title: 'VPN',
    icon: perimeter81,
    tileDescription:
      "Perimeter81’s cloud based VPN solution protects access to your company's critical cloud environments and applications.",
    description:
      "Allow employees to securely access on-site and remote resources with Perimeter81's VPN solution.",
    content: [
      {
        highlightText: 'Strong and simple network security:',
        text: "Extensive protection for your company's resources, people, and assets.",
      },
      {
        highlightText: "Replace outdated VPN's:",
        text: 'The modern workforce requires modern security.  Modernize your network security with a fully cloud-based solution.',
      },
      {
        highlightText: 'Compliance made easy:',
        text: "Ensure all connected endpoints are secured compliantly and don't present a threat to the network.",
      },
    ],
    costText: 'Starting at $10.00/user',
  },
  [AddOnVendors.HEC]: {
    id: AddOnVendors.HEC,
    name: 'Harmony Email & Collaboration',
    title: 'Email Security & Collaboration',
    icon: hec,
    tileDescription:
      'Harmony’s email security leverages patented inline protection and advanced machine learning.',
    description:
      'Email threats are steadily on the rise. Harmony Email & Collaboration protects your email and collaboration suites from phishing, malware, account takeover, and more.',
    content: [
      {
        highlightText: 'Comprehensive email protection:',
        text: 'Harmony Email & Collaboration detects and prevents phishing incidents before they reach your inbox.',
      },
      {
        highlightText: 'Advanced malicious file analysis:',
        text: 'Harmony Email & Collaboration goes beyond conventional protection by scanning email attachments for malicious content, quarantining threats before they can be downloaded.',
      },
      {
        highlightText: 'Account takeover protection:',
        text: 'Harmony Email & Collaboration identifies behaviors that can be a sign of account takeover events, immediately revoking access when suspicious activity is detected.',
      },
    ],
    costText: 'Starting at $6.00/user',
  },
  [AddOnVendors.Keeper]: {
    id: AddOnVendors.Keeper,
    name: 'Keeper',
    title: 'Password Manager',
    icon: keeper,
    tileDescription:
      'Keeper protects online identities with best-in-class password management.',
    description:
      'Cybersecurity threats are steadily on the rise. Keeper’s streamlined platform safeguards your passwords and credentials, keeping your users and your organization safe.',
    content: [
      {
        highlightText: 'Enables Secure Sharing of Credentials and Secrets:',
        text: 'Keeper allows IT Admins to enable secure, granular and controlled sharing of credentials, secrets and vaults among employees and teams.',
      },
      {
        highlightText: 'Mitigates Risk of Data Breaches:',
        text: 'Keeper uses a proprietary, zero-knowledge security architecture that supports on-prem, cloud, and hybrid-cloud environments for client-side encryption key management.',
      },
      {
        highlightText: 'Protects Your Organization Against Ransomware Attacks:',
        text: 'Keeper protects your organization against ransomware attacks using robust administration, controls, and visibility over strong password security and real-time dark web monitoring.',
      },
    ],
    costText: 'Starting at $3.75/user',
  },
  [AddOnVendors.Fullsteam]: {
    id: AddOnVendors.Fullsteam,
    name: 'Fullsteam',
    title: 'Cyber Insurance',
    icon: fullsteam,
    tileDescription: 'Comprehensive cyber insurance to protect your growth.',
    description:
      'Protect your business from cyber attacks, lawsuits, wire fraud, and more. Apply for a quick quote, or set up a meeting with your advisor today.',
    content: [
      {
        highlightText: 'Best-In-Class Coverage:',
        text: 'With over 50 insurance providers, Fullsteam will match you with the best and build a comprehensive policy.',
      },
      {
        highlightText: 'White-Glove Service:',
        text: 'Fullsteam is an extension of your team and is always available and working proactively to protect your business.',
      },
      {
        highlightText: 'Partner Discount:',
        text: 'Fullsteam offers the most competitive prices in the market. As an Electric AI partner, you receive an additional 25% off.',
      },
    ],
    referralUrl: 'https://www.fullsteam.io/partner/electric',
    costText: 'Paid',
  },
  [AddOnVendors.BreachSecureNow]: {
    id: AddOnVendors.BreachSecureNow,
    name: 'Breach Secure Now',
    title: 'Security Awareness & Training',
    icon: breachSecureNow,
    tileDescription:
      "Breach Secure Now's innovative platform is dedicated to educating individuals on cybersecurity best practices.",
    description:
      'An innovative security awareness platform dedicated to educating individuals on cybersecurity best practices, including phishing prevention training. ',
    content: [
      {
        highlightText: 'Cybersecurity Training:',
        text: 'Automated training that gets deployed to all employees in your organization on an annual and weekly basis through quizzes.',
      },
      {
        highlightText: 'Dark Web Monitoring:',
        text: 'Proactively monitor the dark web for compromised account data.',
      },
      {
        highlightText: 'Impactful Phishing Prevention Methodologies',
        text: 'A mix of effective training strategies and exercises to ensure that employees are prepared to identify and prevent potential phishing attacks.',
      },
    ],
    costText: 'Starting at $2.50/user',
  },
  [AddOnVendors.Dropsuite]: {
    id: AddOnVendors.Dropsuite,
    name: 'Dropsuite',
    title: 'Data Backup & Archiving',
    icon: dropsuiteImage,
    tileDescription:
      'Dropsuite simplifies data protection by safeguarding your critical business data with SaaS backup and archiving solutions.',
    description:
      'Dropsuite is a cloud software platform enabling businesses to easily backup, recover and protect their important business data. Solutions include regulatory compliance, data security including archiving and data retrieval.',
    content: [
      {
        highlightText: 'Unlimited Storage:',
        text: 'Never worry about running out of space with unlimited storage, ensuring that all of your data is protected.',
      },
      {
        highlightText: 'Military-grade Encryption:',
        text: 'Your data is kept safe and in compliance with regulatory requirements that include GDPR, HIPAA, SOC, and more, through military-grade encryption that protects data 24/7.',
      },
      {
        highlightText: 'Stay Compliant:',
        text: 'Your data is kept safe and in compliance with regulatory requirements that include GDPR, HIPAA, SOC, and more.',
      },
    ],
    costText: 'Starting at $3.00/user',
  },
  [AddOnVendors.Drata]: {
    id: AddOnVendors.Drata,
    name: 'Drata',
    title: 'Compliance & Security Monitoring',
    icon: drataImage,
    tileDescription:
      'Drata automates compliance and security monitoring with real-time insights and audit readiness.',
    description:
      "Drata is a security and compliance automation platform that monitors and collects evidence of a company's security controls while streamlining compliance workflows end-to-end to ensure audit readiness.",
    content: [
      {
        highlightText: 'Automated Monitoring & Evidence Collection',
        text: "Drata's autopilot system communicates between siloed tech stacks and compliance controls. And 75+ integrations ensure that you don't need to manually check dozens of systems to provide evidence to auditors.",
      },
      {
        highlightText: 'Cross-Mapped & Custom Controls',
        text: "Enjoy cross-mapped controls across multiple frameworks to streamline your workflows and customize policies where needed. You can also create custom controls without a framework to fit your business's unique needs.",
      },
      {
        highlightText: 'Streamlined Auditing',
        text: 'Auditors only see the evidence they need to see through a separate Auditor view and can download everything to one zip file, simplifying the audit process.',
      },
    ],
    costText: '25% off',
  },
};

export const addOnServices: AddOnServicesData = {
  [AddOnServiceType.AudioVisualConferencing]: {
    id: AddOnServiceType.AudioVisualConferencing,
    name: 'Audio Visual and Conferencing Services',
    title: 'Audio Visual and Conferencing Services',
    icon: 'audio',
    tileDescription:
      'Enhance your audio visual and conferencing capabilities with our comprehensive design-to-installation solutions.',
    description:
      'Connect with specialized partners for all your AV and conferencing needs. Collaborate directly without experts to design, install, and manage your AV systems for seamless communication and collaboration.',
    content: [
      {
        highlightText: 'Customized AV Solutions',
        text: 'Our partners offer tailored AV solutions that meet the specific needs of your business.',
      },
      {
        highlightText: 'Seamless Conferencing Solutions',
        text: 'Enhance your conferencing capabilities with state-of-the-art technology and professional design/installation.',
      },
      {
        highlightText: 'Ongoing Support and Management',
        text: 'Benefit from ongoing support and management services to keep your AV/conferencing systems running smoothly.',
      },
    ],
  },
  [AddOnServiceType.Cybersecurity]: {
    id: AddOnServiceType.Cybersecurity,
    name: 'Cybersecurity Services',
    title: 'Cybersecurity Services',
    icon: 'shield-check',
    tileDescription:
      'From Penetration Testing to IDS/IPS monitoring, our partners offer additional comprehensive security solutions.',
    description:
      'Connect with specialized cybersecurity partners for all of your cybersecurity needs, including Penetration Testing, Vulnerability Scanning & Management, and IDS/IPS Monitoring. Work with our experts to safeguard your business from threats.',
    content: [
      {
        highlightText: 'Comprehensive Security Solutions',
        text: 'Our partners offer a wide range of cybersecurity services to project your business from threats and vulnerabilities.',
      },
      {
        highlightText: 'Proactive Vulnerability Management',
        text: 'Stay ahead of potential threats with continuous vulnerability scanning and management.',
      },
      {
        highlightText: 'Advanced IDS/IPS Monitoring',
        text: 'Benefit from real-time intrusion detection and prevention systems that monitor and project your network.',
      },
    ],
  },
  [AddOnServiceType.Cloud]: {
    id: AddOnServiceType.Cloud,
    name: 'Cloud Services',
    title: 'Cloud Services',
    icon: 'cloud',
    tileDescription:
      'Implement, migrate, and manage your cloud solutions. From Azure to GCP, we provide comprehensive cloud services.',
    description:
      'Connect with specialized partners for all of your cloud needs, including cloud implementation, cloud migration, Azure, E-mail, and GCP services. Collaborate directly with our experts to ensure a smooth and successful implementation or upgrade to the cloud.',
    content: [
      {
        highlightText: 'End-to-End Cloud Solutions',
        text: 'Our partners offer comprehensive cloud services, from initial implementation to ongoing management and support.',
      },
      {
        highlightText: 'Multi-Cloud Expertise',
        text: "Leverage our partners' expertise in leading cloud platforms, including Azure, Email, Microsoft 365, Google Workspace, and Google Cloud Platform, to find the best solution for your business.",
      },
      {
        highlightText: 'Scalable and Secure Cloud Infrastructure',
        text: 'Benefit from scalable and secure cloud solutions that grow with your business and protect your data.',
      },
    ],
  },
  [AddOnServiceType.Network]: {
    id: AddOnServiceType.Network,
    name: 'Network Services',
    title: 'Network Services',
    icon: 'globe',
    tileDescription:
      'From network management to server management, manage, build out, or upgrade your network with our expert partners.',
    description:
      'Connect with specialized partners for all of your network needs, including network management, network buildouts, and network upgrades. Work directly with our experts to ensure a robust and efficient network infrastructure.',
    content: [
      {
        highlightText: 'Comprehensive Network Solutions',
        text: 'Our service partners offer a wide range of services, from initial network design and buildouts to ongoing management services, including monitoring.',
      },
      {
        highlightText: 'Expert Network Management',
        text: 'Ensure your network is always performing optimally with professional management services, including monitoring, troubleshooting, and maintenance.',
      },
      {
        highlightText: 'Seamless Upgrades',
        text: 'Upgrade your network infrastructure with minimal disruption, leveraging the expertise of our partners to enhance performance and reliability.',
      },
    ],
  },
};

import {
  format,
  isToday,
  parseISO,
  isValid,
  isSameYear,
  formatDistanceToNow,
  isBefore,
  subWeeks,
  addWeeks,
  isPast,
} from 'date-fns';

/**
 * Formats a given date string into a readable format.
 *
 * - If the date is today, it returns "Today, [Month] [Day]".
 * - If the date is valid and not today, it returns "[Day of the week], [Month] [Day]".
 * - If the date is invalid or empty, it returns "Invalid date".
 *
 * @param {string} dateRaw - The raw date string to format.
 * @returns {string} The formatted date string.
 */
export function formatCustomDate(dateRaw: string): string {
  if (!dateRaw) {
    return 'Invalid date';
  }

  const date = parseISO(dateRaw);

  // Check if the parsed date is valid
  if (!isValid(date)) {
    return 'Invalid date';
  }

  // Check if the date is today
  if (isToday(date)) {
    return `Today, ${format(date, 'MMMM d')}`;
  }

  if (!isSameYear(new Date(), date)) {
    return `${format(date, 'EEEE')}, ${format(date, 'MMMM d, yyyy')}`;
  }

  return `${format(date, 'EEEE')}, ${format(date, 'MMMM d')}`;
}

export const formatTimestamp = (
  dateRaw?: string,
  formatStr: string = 'MM/dd/yy'
) => {
  //refactor it to re use
  if (!dateRaw) {
    return 'Invalid date';
  }

  const date = parseISO(dateRaw);

  // Check if the parsed date is valid
  if (!isValid(date)) {
    return 'Invalid date';
  }

  const formattedDate = format(date, formatStr);
  return formattedDate;
};

export function getRelativeTime(date: string): string {
  const parsedDate = parseISO(date);
  return formatDistanceToNow(parsedDate, { addSuffix: true });
}

export function isWithinLastWeek(date: string): boolean {
  const parsedDate = parseISO(date);
  const oneWeekAgo = subWeeks(new Date(), 1); // 1 week ago
  return isBefore(oneWeekAgo, parsedDate);
}

export function hasExpired(expires_at?: string): boolean {
  if (!expires_at) {
    return false;
  }

  const expiredDate = parseISO(expires_at);
  const now = new Date();

  return isBefore(expiredDate, now);
}

export function isWithinTwoWeeksAhead(dueDate: string | undefined): boolean {
  if (!dueDate) {
    return false;
  }

  const date = parseISO(dueDate);
  if (!isValid(date)) {
    return false;
  }

  if (isPast(dueDate) || isToday(dueDate)) {
    return false;
  }

  const twoWeeksFromNow = addWeeks(new Date(), 2);

  return isBefore(date, twoWeeksFromNow);
}

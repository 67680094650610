import { ProductTypeName } from '@electricjs/core_entity-client';

import { AssetCartEntry, FinalCart, type Product } from '@/types/storefront';
import { ELECTRIC_WAREHOUSE_SITE_NAME } from '@/constants/assets';

export function isLaptop(product: Product): boolean {
  return product.productType === ProductTypeName.Laptop;
}

export function isMonitor(product: Product): boolean {
  return product.productType === ProductTypeName.Monitor;
}

export function isKeyboardAndMouse(product: Product): boolean {
  return (
    product.productType === ProductTypeName.Keyboard ||
    product.productType === ProductTypeName.Mouse ||
    product.productType === ProductTypeName.KeyboardMouse
  );
}

export function isAudio(product: Product): boolean {
  return product.productType === ProductTypeName.Audio;
}

export function isVideo(product: Product): boolean {
  return product.productType === ProductTypeName.Video;
}

export function isDock(product: Product): boolean {
  return product.productType === ProductTypeName.Dock;
}

export const hasCartEntries = (finalCart: FinalCart) => {
  const allCartEntries = [...finalCart.catalogCart];

  return allCartEntries.length > 0;
};

export const areAllLaptopsSameBrand = (brand: string, finalCart: FinalCart) => {
  // filter by laptops from Catalog
  const allLaptopEntries = [...finalCart.catalogCart].filter(
    entry => entry.item && entry.item.productType === ProductTypeName.Laptop
  );

  // check if they are all of the same brand
  const checkIfAllLaptopsAreFromSameBrand =
    Boolean(allLaptopEntries.length) &&
    allLaptopEntries.every(
      entry => entry.item.brand.toLowerCase() === brand.toLowerCase()
    );

  return checkIfAllLaptopsAreFromSameBrand;
};

export const allItemsAreFromCatalog = (finalCart: FinalCart) => {
  return finalCart.catalogCart.length > 0;
};

/** Whether there is at least one known laptop (item marked as laptop on Catalog) */
export const hasAnyKnownLaptopEntry = (finalCart: FinalCart) => {
  return [...finalCart.catalogCart].some(
    entry => entry.item && entry.item.productType === ProductTypeName.Laptop
  );
};

export const allItemsAreNotLaptop = (finalCart: FinalCart) => {
  return !hasAnyKnownLaptopEntry(finalCart);
};

/** Whether there is at least one laptop of a specific brand */
export const hasAnyLaptopBrandEntry = (brand: string, finalCart: FinalCart) => {
  // filter by laptops from Catalog
  const allLaptopEntries = [...finalCart.catalogCart].filter(
    entry => entry.item && entry.item.productType === ProductTypeName.Laptop
  );

  // check if there is at least one laptop of a specific brand
  const checkIfHasAnyLaptopFromSpecificBrand =
    Boolean(allLaptopEntries.length) &&
    allLaptopEntries.some(
      entry => entry.item.brand.toLowerCase() === brand.toLowerCase()
    );

  return checkIfHasAnyLaptopFromSpecificBrand;
};

export const allItemsAreFromCatalogAndAreNotLaptop = (finalCart: FinalCart) => {
  return allItemsAreFromCatalog(finalCart) && allItemsAreNotLaptop(finalCart);
};

// Determines whether the user needs to self-ship their assets after placing an order
// They need to self-ship if any of the items are stored at an organization's site
export const shouldShowSelfShip = (assetCart: AssetCartEntry[]) => {
  return assetCart.some(asset => asset.item.site?.organizationId !== null);
};

export const isWarehouseAsset = (asset: AssetCartEntry) => {
  return (
    asset.item.site?.organizationId === null &&
    asset.item.site?.name === ELECTRIC_WAREHOUSE_SITE_NAME
  );
};

export const assetCartContainsWarehouseAsset = (
  assetCart: AssetCartEntry[]
) => {
  return assetCart.some(isWarehouseAsset);
};

import styled from 'styled-components';

import {
  ColorsV2,
  Divider,
  Flex,
  IconButton,
  Table,
  TableContainer,
  Text,
} from '@electricjs/arc';

import { useGlobalUI } from '@/components/GlobalUIProvider';
import ChangePlanModal from '@/components/Organization/ChangePlanModal/ChangePlanModal';
import { PACKAGE_DISPLAY_NAME_MAP } from '@/constants/plans-data';
import { Organization, PackageSlug } from '@/types/organizations';
import { createColumnHelper } from '@tanstack/react-table';
import TableCellFlexContainer from 'common/Table/TableCellFlexContainer';

type BasePlanTileProps = {
  organization: Organization | undefined;
  canUpdateOrganizationPackage: boolean;
  organizationLoading: boolean;
  isInGrid: boolean;
  height: string;
};

const columnHelper = createColumnHelper<{
  plan: string;
  licenseCount?: number | null;
}>();

const StyledText = styled(Text)`
  display: -webkit-box;
  // Ellipsis text when it reaches 2nd line
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const BasePlanTile = ({
  organization,
  canUpdateOrganizationPackage,
  organizationLoading,
  isInGrid,
  height,
}: BasePlanTileProps) => {
  const borderColor = ColorsV2.BORDER;
  const { openModal, closeModal } = useGlobalUI();
  const getPlanDisplayName = (plan: PackageSlug | undefined) => {
    if (!plan) return '';

    return PACKAGE_DISPLAY_NAME_MAP[plan] || plan;
  };

  const basePlanData = [
    {
      plan: getPlanDisplayName(organization?.plan?.slug),
      licenseCount: organization?.licenseCount,
    },
  ];

  const changePlanModal = () => {
    organization &&
      openModal(
        <ChangePlanModal
          onClose={closeModal}
          currentLicenseCount={organization.licenseCount || 0}
          currentPlan={organization.plan}
        />
      );
  };

  // Dynamically change table column width
  const columnWidth = Math.round(window.innerWidth * 0.05);

  const basePlanTableColumns = [
    columnHelper.accessor('plan', {
      header: () => <Text variant="body-2">Plan</Text>,
      id: 'plan',
      meta: {
        minWidth: isInGrid ? 0 : 300,
        width: columnWidth,
        flex: '2',
      },
      cell: ({ getValue }) => {
        return (
          <TableCellFlexContainer>
            <Text variant="label-small">{getValue()}</Text>
          </TableCellFlexContainer>
        );
      },
    }),
    columnHelper.accessor('licenseCount', {
      header: () => {
        return <Text variant="body-2">License Count</Text>;
      },
      id: 'license-count',
      meta: {
        minWidth: isInGrid ? 0 : 200,
        width: columnWidth,
      },
      cell: ({ getValue }) => {
        const value = getValue();
        return (
          <TableCellFlexContainer>
            <Text variant="body-2">{value !== null ? value : 'Unlimited'}</Text>
          </TableCellFlexContainer>
        );
      },
    }),
    columnHelper.display({
      header: () => null,
      id: 'actions',
      meta: {
        maxWidth: columnWidth * (canUpdateOrganizationPackage ? 0.5 : 0),
        width: columnWidth * (canUpdateOrganizationPackage ? 0.5 : 0),
      },
      cell: () => {
        return (
          canUpdateOrganizationPackage && (
            <IconButton
              id="edit-plan-icon-button"
              icon="pencil"
              onClick={changePlanModal}
              color={ColorsV2.PRIMARY}
            />
          )
        );
      },
    }),
  ];

  return (
    <TableContainer
      vertical
      hAlignContent="center"
      width="100%"
      alignSelf="flex-start"
      flexDirection="column"
      height={isInGrid && height}>
      <Flex alignSelf="flex-start" vertical rowGap="0.5rem" p="2.5rem">
        <Text variant="heading-2">Electric Base Plan</Text>
        <StyledText>
          {canUpdateOrganizationPackage ? 'Manage' : 'View'} your company&apos;s
          plan and license counts.
        </StyledText>
      </Flex>
      <Divider color={borderColor} />
      <Table
        id="employees-table"
        columns={basePlanTableColumns}
        data={basePlanData}
        loading={organizationLoading}
      />
    </TableContainer>
  );
};

export default BasePlanTile;

import {
  Button,
  ColorsV2,
  getToken,
  Icon,
  IconButton,
  IconNames,
  InitialsInCircle,
  Menu,
  MenuSeparator,
  Skeleton,
  Text,
} from '@electricjs/arc';
import isFunction from 'lodash/isFunction';
import React from 'react';
import styled, { css } from 'styled-components';
import { TopNavMenuItem } from './types';

export const topNavItemStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 3rem;
  line-height: 3rem;
  transition: 0.2s ease;
  text-decoration: none !important;
  color: ${getToken(ColorsV2.TEXT)};
  padding: 0 1.6rem;

  span {
    font-size: 1.4rem;
    font-weight: 400;
    transition: 0.2s ease;
  }

  &:hover {
    cursor: pointer; /* for non-link items (e.g. logout button) */
    background: ${getToken(ColorsV2.BACKGROUND_ALT)};
    color: ${getToken(ColorsV2.PRIMARY)};

    /* The component text styles overwrites the hover styles, so this overwrites it again */
    span {
      background: ${getToken(ColorsV2.BACKGROUND_ALT)};
      color: ${getToken(ColorsV2.PRIMARY)};
    }
  }

  div[data-arc='icon'] {
    margin-left: 0.8rem;

    &:first-child {
      margin-left: 0;
      margin-right: 0.8rem;
    }

    svg {
      align-self: center;
    }
  }
`;

const NavItem = styled.a`
  ${topNavItemStyles}
`;

const CustomNavItemComponent = styled(
  ({
    component: NavComponent,
    className: styledClassName,
    onClick,
    icon,
    text,
  }: {
    component: JSX.Element;
    className?: string;
    onClick?: () => void;
  } & TopNavMenuItem) =>
    React.cloneElement(NavComponent, {
      'data-testid': 'top-nav-menu-item',
      onClick,
      className: `top-nav-item${
        NavComponent.props?.className ? ` ${NavComponent.props.className}` : ''
      }${styledClassName ? ` ${styledClassName}` : ''}`,
      children: (
        <>
          {icon && <Icon icon={icon} size="small" />}
          <Text tag="span">{text}</Text>
        </>
      ),
    })
)`
  ${topNavItemStyles}
`;

export const handleCloseNavOnMenuItemClick = (
  elementId = 'user-menu-button'
) => {
  // Trigger a click outside of the menu to force the menu to close.
  // Doing it this way prevents us from having to migrate the menu
  // to a controlled component.
  const event = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  const element = document.getElementById(elementId);
  element?.dispatchEvent(event);
};

type TopNavMenuProps = {
  md: boolean;
  navMenuName?: string;
  navMenuIcon?: IconNames;
  menuItems: TopNavMenuItem[];
  logoutLink?: string;
  logoutOnClick?: () => void;
  isNewVersion?: boolean;
  menuDetailSection?: JSX.Element;
};

export const TopNavMenu = ({
  md,
  navMenuName,
  navMenuIcon,
  menuItems,
  logoutLink,
  logoutOnClick,
  isNewVersion,
  menuDetailSection,
}: TopNavMenuProps) => {
  const hasLogout = logoutLink || logoutOnClick;

  const menuTrigger = () => {
    /* If the navMenuName is not provided, show a skeleton loading state */
    if (!navMenuName) {
      return (
        <Skeleton
          circle={!md}
          count={1}
          width={!md ? '4rem' : '14.5rem'}
          height={!md ? '4rem' : '3.4rem'}
        />
      );
    } else if (isNewVersion) {
      /* If the new version is enabled, show the initials in a circle */
      return (
        <InitialsInCircle
          text={navMenuName ?? ''}
          backgroundColor={ColorsV2.GRAY_LIGHT}
        />
      );
      /* These are the old nav styles below */
    } else if (md) {
      /* If the screen size is medium or above, show the button with the icon */
      return (
        <Button
          id="user-menu-button"
          testId="user-menu-button"
          variant="text"
          iconBefore={navMenuIcon as IconNames}>
          {navMenuName}
        </Button>
      );
    } else {
      /* If the screen size is below medium, show the icon button */
      return (
        <IconButton
          id="user-menu-button"
          icon={navMenuIcon as IconNames}
          testId="user-menu-button"
          intent="primary"
        />
      );
    }
  };

  const renderNavItem = (item: TopNavMenuItem) =>
    isFunction(item?.callbackFn) ? (
      <NavItem
        key={item.text}
        data-testid="top-nav-menu-item"
        onClick={item.callbackFn}>
        {item?.icon && <Icon icon={item.icon} size="small" />}
        <Text tag="span">{item.text}</Text>
      </NavItem>
    ) : (
      <NavItem key={item.text} data-testid="top-nav-menu-item" {...item}>
        {item?.icon && <Icon icon={item.icon} size="small" />}
        <Text tag="span">{item.text}</Text>
      </NavItem>
    );

  return (
    <Menu
      placement="bottom-end"
      trigger={
        <div id="user-menu-button" style={{ cursor: 'pointer' }}>
          {menuTrigger()}
        </div>
      }>
      {!!menuDetailSection && (
        <>
          {menuDetailSection}
          <MenuSeparator />
        </>
      )}
      {menuItems.map(({ callbackFn, icon, text, ...item }) => {
        if (item?.customItem) return item?.customItem;

        if (item?.component)
          return (
            <CustomNavItemComponent
              onClick={() => handleCloseNavOnMenuItemClick()}
              component={item.component}
              key={text}
              icon={icon}
              text={text}
            />
          );

        if (item?.subNavItems) {
          return (
            <Menu
              key={text}
              placement="left-start"
              offset={0}
              trigger={
                <NavItem key={text} data-testid="top-nav-menu-item" {...item}>
                  {icon && <Icon icon={icon} size="small" />}
                  <Text tag="span">{text}</Text>
                </NavItem>
              }>
              {item.subNavItems.map(subNavItem => {
                if (subNavItem?.customItem) return subNavItem?.customItem;

                if (subNavItem?.component) {
                  return (
                    <CustomNavItemComponent
                      onClick={() => handleCloseNavOnMenuItemClick()}
                      component={subNavItem.component}
                      key={subNavItem.text}
                      icon={subNavItem.icon}
                      text={subNavItem.text}
                    />
                  );
                }
                return renderNavItem(subNavItem);
              })}
            </Menu>
          );
        }
        return renderNavItem({ callbackFn, icon, text, ...item });
      })}
      {menuItems?.length && hasLogout ? <MenuSeparator /> : null}
      {hasLogout ? (
        <NavItem
          data-testid="top-nav-menu-logout"
          {...(logoutLink ? { href: logoutLink } : {})}
          {...(logoutOnClick ? { onClick: logoutOnClick } : {})}>
          <Text tag="span">Logout</Text>
          <Icon icon="logout" size="small" />
        </NavItem>
      ) : null}
    </Menu>
  );
};

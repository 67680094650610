import { Banner, Flex } from '@electricjs/arc';

export const InitFailedState = () => {
  return (
    <Flex width="100%" justifyContent="center" mt="10rem">
      <Banner
        id="init-failed-banner"
        isFlat
        intent="error"
        title="Error"
        message="Sorry, an error has occurred while loading the application. Please reload the page or contact your administrator."
        cta="Reload"
        onClickCta={() => window.location.reload()}
      />
    </Flex>
  );
};

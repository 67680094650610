export const navIconStyles = `
div[data-arc='icon'] {
	margin-right: 1.5rem;

	svg {
		width: 2rem;
		height: 2rem;
		align-self: center;
	}
}
`;

export const navTextStyles = `
	font-size: 1.6rem;
	font-weight: 400;
  transition: 0.2s ease;
`;

export const sharedNavItemRootStyles = `
	width: 100%;
	padding: 0 1.6rem;
	margin-bottom: 2rem;
	box-sizing: border-box;
`;

import {
  ColorsV2,
  Flex,
  IconInCircle,
  Text,
  useBreakpoint,
} from '@electricjs/arc';

export type PageNavigationResultProps = {
  text: string;
  description: string;
};

export const PageNavigationResult = ({
  text,
  description,
}: PageNavigationResultProps) => {
  const { lg } = useBreakpoint();

  return (
    <Flex vAlignContent="center">
      <Flex>
        <IconInCircle
          iconSize="small"
          size="3rem"
          icon="page"
          color={ColorsV2.SECONDARY}
          background={ColorsV2.SECONDARY_LIGHTEST}
        />
      </Flex>
      <Flex
        width="100%"
        vAlignContent="center"
        textAlign="left"
        flexDirection={lg ? 'row' : 'column'}
        flexWrap={lg ? 'nowrap' : 'wrap'}>
        <Text
          width={lg ? null : '100%'}
          textAlign="left"
          whiteSpace="nowrap"
          ml="2rem"
          variant="label-small">
          {text}
        </Text>
        <Text
          width={lg ? null : '100%'}
          ml="2rem"
          variant="body-2"
          color={ColorsV2.GRAY_DARK}>
          {description}
        </Text>
      </Flex>
    </Flex>
  );
};

import { Asset } from '@/types/assets';
import {
  Flex,
  IconInCircle,
  Text,
  useThemeColors,
  ColorsV2,
  PaneWithBorder,
} from '@electricjs/arc';

type DeviceCardProps = {
  asset: Asset;
};

const DeviceCard = ({ asset }: DeviceCardProps) => {
  const serialNumber = asset.serialNumber;
  const hardwareModel = asset.device?.hardwareModel || asset.model;
  const [iconColor] = useThemeColors([ColorsV2.SECONDARY]);

  const deviceName = asset?.name || hardwareModel;

  return (
    <PaneWithBorder padding="1.8rem 3.8rem 1.4rem 1.4rem" maxWidth="40rem">
      <IconInCircle
        size="4.8rem"
        background="transparent"
        icon="bolt"
        color={iconColor}
        iconSize="large"
      />
      <Flex vertical ml="1.2rem" flexGrow={1}>
        <Text variant="label-small" minWidth="30rem">
          {deviceName}
        </Text>
        <Flex width="100%" hAlignContent="between">
          {asset?.site && (
            <Text variant="body-2" mt="0.8rem" minWidth="14rem">
              {`Site: ${asset?.site?.name}`}
            </Text>
          )}
          <Text variant="body-2" mt="0.8rem" minWidth="14rem">
            {`Serial: ${serialNumber}`}
          </Text>
        </Flex>
      </Flex>
    </PaneWithBorder>
  );
};

export default DeviceCard;

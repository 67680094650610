import { transformSitesToOptions } from '@/components/Assets/helpers/transformOptions';
import { useGlobalUI } from '@/components/GlobalUIProvider';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import SiteModal from '@/pages/Assets/SiteModal';
import {
  useGetOrganizationSitesQuery,
  useGetSiteByIdQuery,
} from '@/redux/slices/assetApiSlice';
import {
  Button,
  Flex,
  FlexProps,
  SelectField,
  useBreakpoint,
} from '@electricjs/arc';
import { formatAddress } from 'common/utils/formatAddress';
import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

type SiteGroupFieldProps = {
  name: string;
  label?: string;
  id?: string;
  buttonId?: string;
  placeholder?: string;
  required?: boolean;
  shouldShowAddress?: boolean;
} & FlexProps;

const SiteGroupField = ({
  name,
  label = 'Site',
  id = 'site-select',
  buttonId = 'button-add-site',
  placeholder = 'Select a site',
  required,
  shouldShowAddress,
  ...containerProps
}: SiteGroupFieldProps) => {
  const { sm } = useBreakpoint();
  const { openModal, closeModal } = useGlobalUI();
  const organizationId = useGetOrganizationId();
  const { sites, isFetchingOrganizationSites } = useGetOrganizationSitesQuery(
    { organizationId },
    {
      selectFromResult: ({
        data: sites,
        isFetching: isFetchingOrganizationSites,
      }) => {
        return {
          // Exclude global sites
          sites: sites?.filter(site => site.organizationId),
          isFetchingOrganizationSites,
        };
      },
    }
  );
  const siteOptions = useMemo(
    () => transformSitesToOptions(sites || []),
    [sites]
  );
  const { control } = useFormContext();
  const currentSiteOption = useWatch({ name });
  const { data: site } = useGetSiteByIdQuery(
    {
      siteId: currentSiteOption?.value,
      organizationId: organizationId,
    },
    { skip: !currentSiteOption?.value }
  );
  const formattedAddress = formatAddress(site?.address);

  return (
    <Flex
      columnGap="2rem"
      width="100%"
      vertical={!sm}
      hAlignContent={sm ? 'between' : 'center'}
      {...containerProps}>
      <SelectField
        id={id}
        name={name}
        label={label}
        control={control}
        placeholder={placeholder}
        options={siteOptions}
        isLoading={isFetchingOrganizationSites}
        disabled={isFetchingOrganizationSites}
        required={required}
        helperText={shouldShowAddress ? formattedAddress : ''}
        isSearchable
        isClearable
      />
      <Button
        id={buttonId}
        iconBefore="add-circle"
        onClick={() => openModal(<SiteModal hideModal={() => closeModal()} />)}
        variant="outline"
        mt={sm ? '2rem' : 0}
        mb={sm ? 0 : '2rem'}
        width="14.5rem">
        Add site
      </Button>
    </Flex>
  );
};

export default SiteGroupField;

import { formatPhoneNumber } from '@common';

import { EmployeeOption } from '@/components/People/EmployeeGroups/NewEmployeeGroupForm';
import { Employee } from '@/types/employees';

export const transformEmployeesToOptions = (
  employees: Employee[]
): EmployeeOption[] => {
  return employees.map((employee: Employee) => ({
    value: employee.id ?? '',
    label: `${employee.firstName} ${employee.lastName}`,
    name: `${employee.firstName} ${employee.lastName}`,
    jobTitle: `${employee.jobTitle ?? ''}`,
    email: `${employee.email}`,
    personalEmail: employee.personalEmail ?? '',
    phone: formatPhoneNumber(employee.phone),
    address: employee.address,
  }));
};

import { Banner, Button, Flex } from '@electricjs/arc';

type SetUpAppleMDMBannerProps = {
  onSetupAppleDevices: () => void;
};

const SetUpAppleMDMBanner = ({
  onSetupAppleDevices,
}: SetUpAppleMDMBannerProps) => {
  return (
    <Banner
      id="setup-apple-mdm-banner"
      isFlat
      title="You currently have MDM support for Windows devices."
      message="If you need MDM support for Apple devices, there’s a few more
              steps needed."
      cta={
        <Flex mt="1rem">
          <Button
            id="setup-apple-mdm"
            testId="setup-apple-mdm-button"
            onClick={onSetupAppleDevices}
            intent="info"
            variant="outline">
            Set up MDM for Apple devices
          </Button>
        </Flex>
      }
    />
  );
};

export default SetUpAppleMDMBanner;

// Types copied on 17/05/2023 from https://app.pendo.io/s/4840435140132864/admin/app/-323232?tab=install
type PendoVisitor = {
  id: string;
  email: string;
  isAdmin: boolean;
  isProdSupport: boolean;
};
type PendoAccount = {
  id: string;
  name: string;
  plan: string;
  activatedAt?: string | null;
  // We don't want to default to 0, instead if it's missing, allow pendo to interpret it as such.
  licenseCount?: number | null;
};

declare let pendo: {
  initialize: ({
    visitor,
    account,
  }: {
    visitor: PendoVisitor;
    account: PendoAccount;
  }) => void;
};

type PendoDimensions = {
  visitor: PendoVisitor;
  account: PendoAccount;
};
export const initPendo = (pendoDimensions: PendoDimensions): void => {
  pendo.initialize(pendoDimensions);
};

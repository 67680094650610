import { Navigate, Outlet } from 'react-router-dom';

type ProtectedRouteProps = {
  isAllowed: boolean;
  redirectPath?: string;
  children?: React.ReactElement;
};

const ProtectedRoute = ({
  isAllowed,
  redirectPath = '/404',
  children,
}: ProtectedRouteProps): React.ReactElement => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;

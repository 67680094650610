import { Package as PackageDto } from '@electricjs/core_entity-client';

export const packages: PackageDto[] = [
  {
    id: 'e97bdcd7-c243-4d12-8ae1-23729d49c3e6',
    name: 'beta',
    sku: 'SKU_20230828',
  },
  {
    id: '2f55ce6b-7026-4ec4-8785-339659b1d225',
    name: 'essentials',
    sku: 'essentials_package_placeholder',
  },
  {
    id: 'b12b976d-25f4-4d9f-aa70-fcb8912484a6',
    name: 'advanced',
    sku: 'advanced_package_placeholder',
  },
  {
    id: 'ece99a37-c98d-4a3a-b0a4-1d6c8b4673a1',
    name: 'professional',
    sku: 'professional_package_placeholder',
  },
  {
    id: '5cb1d5b7-2dfd-442b-beb2-0990a5e5695c',
    name: 'quickstart',
    sku: 'SKU_20230828.1',
  },
];

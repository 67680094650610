import { ReactNode } from 'react';

import {
  Button,
  EmptyState as ArcEmptyState,
  Flex,
  Pane,
  Text,
} from '@electricjs/arc';

import deviceImage from '@/assets/eih_data.svg';
import networkImage from '@/assets/eih_network.svg';
import safelyOpenExternalURL from 'common/safelyOpenExternalURL';

type OptionCardProp = {
  image: string;
  title: string;
  subTitle: string;
  children: ReactNode;
};

const OptionCard = ({ image, title, subTitle, children }: OptionCardProp) => {
  return (
    <Pane
      elevated
      flexDirection="column"
      alignItems="center"
      width="32rem"
      px="2rem"
      pb="2.2rem">
      <img alt="header" width="125" height="125" src={image} />
      <Text variant="heading-3">{title}</Text>
      <Text variant="body-2" textAlign="center" my="1.2rem">
        {subTitle}
      </Text>
      {children}
    </Pane>
  );
};

export const EmptyState = () => {
  return (
    <Pane elevated flexDirection="column" alignItems="center">
      <Flex maxWidth="52rem" mt="3rem">
        <ArcEmptyState
          title="No device data yet"
          subTitle="We don't have any of your device information yet. Set up your devices to see your company's device data or learn more about our Device Overview page."
          size="5.6rem"
          icon="laptop"
          customIconSize="2.8rem"
          rowGapEmptyState="0.8rem"
          paddingEmptyState="0rem"
        />
      </Flex>
      <Flex
        flexDirection="row"
        justifyContent="center"
        gap="3rem"
        my="2rem"
        flexWrap="wrap">
        <OptionCard
          image={deviceImage}
          title="Add your own data"
          subTitle="To explore the full power of Electric, set up your devices so that you can view your own devices and troubleshoot IT issues.">
          <Button
            id="set-up-devices-button"
            onClick={() => {
              // Since these are links outside of the hub, we want to open a new tab
              safelyOpenExternalURL('https://downloads.electric.ai/app');
            }}>
            Set up devices
          </Button>
        </OptionCard>
        <OptionCard
          image={networkImage}
          title="Explore the Device Overview"
          subTitle="Read our knowledge base article to learn more about what you can do and see on your Device Overview page.">
          <Button
            id="learn-more-button"
            variant="outline"
            onClick={() => {
              // Since these are links outside of the hub, we want to open a new tab
              safelyOpenExternalURL('https://support.electric.ai/');
            }}>
            Learn more
          </Button>
        </OptionCard>
      </Flex>
    </Pane>
  );
};

import { NotificationType } from '@electricjs/core_entity-client';
import { Flex } from '@electricjs/arc';

import { useGetXboardingData } from '@/hooks/useGetXboardingData';
import { Employee } from '@/types/employees';
import { Notification } from '@/types/notifications';

import { EmailDetail } from '../EmailDetail';
import { GroupDetail } from '../GroupDetail';
import { NotificationIcon } from '../NotificationIcon';
import { NotificationItemHeader } from '../NotificationItemHeader';
import { NotificationItemProps } from '../types';
import { RequestCompleted } from '../RequestActions/RequestCompleted';

const getTitle = (notification: Notification, employee?: Employee) => {
  const employeeName = employee
    ? `${employee.firstName} ${employee.lastName}`
    : 'An Employee';

  switch (notification.type) {
    case NotificationType.OnboardingCompleted:
      return (
        <>
          <b>{employeeName}</b> onboarding is now complete
        </>
      );
    case NotificationType.OffboardingCompleted:
      return (
        <>
          <b>{employeeName}</b> offboarding is now complete
        </>
      );
    default:
      return <>default message</>;
  }
};

export const XboardingCompleteAction = () => {
  return (
    <Flex m="2">
      <RequestCompleted />
    </Flex>
  );
};

export const XboardingDetailsColumn = ({
  notification,
}: NotificationItemProps) => {
  const { data: xboardingData, isXboardingLoading } = useGetXboardingData({
    employeeId: notification?.data?.employee_id ?? null,
  });
  const employeeGroups = xboardingData.employeeGroups;

  const employeeEmail = xboardingData?.employee?.email;

  return (
    <Flex flexDirection="column">
      <GroupDetail isLoading={isXboardingLoading} groups={employeeGroups} />
      <EmailDetail isLoading={isXboardingLoading} email={employeeEmail} />
    </Flex>
  );
};

const XboardingCompleteNotificationItem = ({
  notification,
  hideDetails,
}: NotificationItemProps) => {
  const { data: requestData, isXboardingLoading: isLoading } =
    useGetXboardingData({
      employeeId: notification?.data?.employee_id ?? null,
    });

  const getLabel = () => {
    switch (notification.type) {
      case NotificationType.OnboardingCompleted:
        return 'ONBOARDING COMPLETED';

      case NotificationType.OffboardingCompleted:
        return 'OFFBOARDING COMPLETED';
      default:
        return '';
    }
  };

  const employeeGroups = requestData.employeeGroups ?? undefined;

  const employeeEmail = requestData?.employee?.email ?? undefined;

  if (hideDetails) {
    return (
      <NotificationItemHeader
        notification={notification}
        isLoading={isLoading}
        title={getTitle(notification, requestData.employee)}
        icon={
          <NotificationIcon
            isNotificationUnseen={!notification.seenAt}
            loading={isLoading}
            icon="user"
          />
        }
        label={getLabel()}
      />
    );
  }

  return (
    <NotificationItemHeader
      notification={notification}
      isLoading={isLoading}
      title={getTitle(notification, requestData.employee)}
      icon={
        <NotificationIcon
          isNotificationUnseen={!notification.seenAt}
          loading={isLoading}
          icon="user"
        />
      }
      label={getLabel()}>
      {!hideDetails ? (
        <>
          <GroupDetail isLoading={isLoading} groups={employeeGroups} />
          <EmailDetail isLoading={isLoading} email={employeeEmail} />
          <XboardingCompleteAction />
        </>
      ) : null}
    </NotificationItemHeader>
  );
};

export default XboardingCompleteNotificationItem;

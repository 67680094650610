import { AssetStatus, ProductTypeName } from '@electricjs/core_entity-client';
import { FilterOption } from 'common/TableFilters/types';
import { Warranty } from '../types';

/** Status filter */
export const statusOptions: FilterOption<AssetStatus>[] = [
  {
    id: AssetStatus.InUse,
    value: AssetStatus.InUse,
    text: 'In use',
    disabled: false,
  },
  {
    id: AssetStatus.Available,
    value: AssetStatus.Available,
    text: 'Available',
    disabled: false,
  },
  {
    id: AssetStatus.UnderRepair,
    value: AssetStatus.UnderRepair,
    text: 'Under repair',
    disabled: false,
  },
  {
    id: AssetStatus.Donated,
    value: AssetStatus.Donated,
    text: 'Donated',
    disabled: false,
  },
  {
    id: AssetStatus.Sold,
    value: AssetStatus.Sold,
    text: 'Sold',
    disabled: false,
  },
  {
    id: AssetStatus.Disposed,
    value: AssetStatus.Disposed,
    text: 'Disposed',
    disabled: false,
  },
  {
    id: AssetStatus.Missing,
    value: AssetStatus.Missing,
    text: 'Missing',
    disabled: false,
  },
  {
    id: AssetStatus.Archived,
    value: AssetStatus.Archived,
    text: 'Archived',
    disabled: false,
  },
  {
    id: AssetStatus.InTransit,
    value: AssetStatus.InTransit,
    text: 'In transit',
    disabled: false,
  },
];

/** Product Type filter */
export const typeOptions: FilterOption<ProductTypeName>[] = [
  { id: ProductTypeName.Laptop, value: ProductTypeName.Laptop, text: 'Laptop' },
  { id: ProductTypeName.Mouse, value: ProductTypeName.Mouse, text: 'Mouse' },
  {
    id: ProductTypeName.Keyboard,
    value: ProductTypeName.Keyboard,
    text: 'Keyboard',
  },
  {
    id: ProductTypeName.KeyboardMouse,
    value: ProductTypeName.KeyboardMouse,
    text: 'Keyboard & Mouse',
  },
  {
    id: ProductTypeName.Monitor,
    value: ProductTypeName.Monitor,
    text: 'Monitor',
  },
  { id: ProductTypeName.Dock, value: ProductTypeName.Dock, text: 'Dock' },
  { id: ProductTypeName.Audio, value: ProductTypeName.Audio, text: 'Audio' },
  { id: ProductTypeName.Video, value: ProductTypeName.Video, text: 'Video' },
  {
    id: ProductTypeName.Warranty,
    value: ProductTypeName.Warranty,
    text: 'Warranty',
  },
  {
    id: ProductTypeName.Miscellaneous,
    value: ProductTypeName.Miscellaneous,
    text: 'Miscellaneous',
  },
  {
    id: ProductTypeName.Desktop,
    value: ProductTypeName.Desktop,
    text: 'Desktop',
  },
  { id: ProductTypeName.Phone, value: ProductTypeName.Phone, text: 'Phone' },
  {
    id: ProductTypeName.Tablets,
    value: ProductTypeName.Tablets,
    text: 'Tablets',
  },
  {
    id: ProductTypeName.Headphones,
    value: ProductTypeName.Headphones,
    text: 'Headphones',
  },
  {
    id: ProductTypeName.Microphone,
    value: ProductTypeName.Microphone,
    text: 'Microphone',
  },
  {
    id: ProductTypeName.PowerAdapter,
    value: ProductTypeName.PowerAdapter,
    text: 'Power Adapter',
  },
  { id: ProductTypeName.Cable, value: ProductTypeName.Cable, text: 'Cable' },
  {
    id: ProductTypeName.Accessory,
    value: ProductTypeName.Accessory,
    text: 'Accessory',
  },
];

/** Warranty filter */
export const warrantyOptions: FilterOption<Warranty>[] = [
  {
    id: Warranty.ACTIVE,
    value: Warranty.ACTIVE,
    text: 'Active',
  },
  {
    id: Warranty.EXPIRED,
    value: Warranty.EXPIRED,
    text: 'Expired',
  },
  {
    id: Warranty.UNKNOWN,
    value: Warranty.UNKNOWN,
    text: 'Unknown',
  },
];

import { useGetEmployeeQuery } from '@/redux/slices/employeeApiSlice';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetLatestOffboardingByEmployeeIdQuery } from '@/redux/slices/offboardingApiSlice';
import { EmployeeStatus } from '@/types/employees';
import formatDate from '@/helpers/formatDate';
import { ColorsV2, Icon, Tooltip, useThemeColors } from '@electricjs/arc';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';

type EmployeeOffboardedWarningIconProps = {
  employeeId?: string;
};

const EmployeeOffboardedWarningIcon = ({
  employeeId,
}: EmployeeOffboardedWarningIconProps) => {
  const organizationId = useGetOrganizationId();
  const [warningTextColor] = useThemeColors([ColorsV2.WARNING]);
  const { data: fullEmployee } = useGetEmployeeQuery(
    employeeId
      ? {
          employeeId,
          organizationId: organizationId,
        }
      : skipToken
  );
  const { data: offboardingData } = useGetLatestOffboardingByEmployeeIdQuery(
    employeeId && fullEmployee?.status === EmployeeStatus.Inactive
      ? {
          organizationId,
          employeeId,
        }
      : skipToken
  );

  if (!offboardingData) {
    return null;
  }

  const offboardingDate =
    offboardingData?.scheduledAt ?? offboardingData?.createdAt;

  return (
    <Tooltip
      content={`Employee was offboarded${offboardingDate ? ' on ' + formatDate(offboardingDate) : ''}`}
      placement="top">
      <Icon
        icon="alert-triangle"
        size="small"
        mr=".5rem"
        color={warningTextColor}
      />
    </Tooltip>
  );
};

export default EmployeeOffboardedWarningIcon;

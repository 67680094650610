import { ReactNode, createContext, useContext, useState } from 'react';

type TicketFlowType = {
  ticketCreated: boolean;
  setTicketCreated: (isCreated: boolean) => void;
};

type TicketFlowProviderProps = {
  children: ReactNode;
};

const TicketFlowContext = createContext<TicketFlowType>({
  ticketCreated: false,
  setTicketCreated: () => null,
});

export const TicketFlowProvider = (props: TicketFlowProviderProps) => {
  const [ticketCreated, setTicketCreated] = useState(false);

  return (
    <TicketFlowContext.Provider value={{ ticketCreated, setTicketCreated }}>
      {props.children}
    </TicketFlowContext.Provider>
  );
};

export const useTicketFlow = () => {
  const { ticketCreated, setTicketCreated } = useContext(TicketFlowContext);
  return { ticketCreated, setTicketCreated };
};

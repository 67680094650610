import { Flex, OneColumnLayout, useBreakpoint } from '@electricjs/arc';

import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useGetEmployeeQuery,
  useGetOrganizationGroupsForEmployeeQuery,
} from '@/redux/slices/employeeApiSlice';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetApplicationsForGroups } from '@/components/People/EmployeeAppsByGroup/useGetApplicationsForGroups';
import { CenteredSpinner } from '@common';
import { useCreateOffboardingMutation } from '@/redux/slices/offboardingApiSlice';
import { useGlobalUI } from '@/components/GlobalUIProvider';
import Applications from '@/components/Offboardings/Applications';
import Devices from '@/components/Offboardings/Devices';
import { SelectionParamKeys } from '@/types/queryParamKeys';
import { HRIS_OFFBOARDING_REQUEST_ID_QUERY_PARAM } from '@/constants/onboarding';
import { useUpdateRequestStatus } from '@/hooks/useUpdateRequestStatus';
import {
  ProductTypeName,
  RequestStatusEnum,
  SortOrder,
} from '@electricjs/core_entity-client';
import PageNotFound from '../PageNotFound';
import SpinnerWithMessage from 'common/SpinnerWithMessage';
import { useListAssetsV2Query } from '@/redux/slices/assetApiSlice';
import { Asset, isDeviceAssetProductType } from '@/types/assets';

enum OffboardingSteps {
  Applications = 1,
  Devices = 2,
}

const DEVICE_PRODUCT_TYPES = [ProductTypeName.Laptop, ProductTypeName.Desktop];

const NewOffboarding = () => {
  const { md } = useBreakpoint();
  const [formStep, setFormStep] = useState(1);
  const organizationId = useGetOrganizationId();
  const [queryParams] = useSearchParams();
  const employeeId = queryParams.get(SelectionParamKeys.EmployeeId) || '';

  const navigate = useNavigate();

  useEffect(() => {
    if (!employeeId) {
      navigate('/employees');
      setTimeout(() => {
        throw new Error('Employee ID is required');
      }, 0);
    }
  }, [employeeId, navigate]);

  const { showErrorToast } = useGlobalUI();

  const { data: employee, isFetching: isFetchingEmployee } =
    useGetEmployeeQuery(
      {
        employeeId,
        organizationId,
      },
      { skip: !employeeId }
    );

  const { data: assetsData } = useListAssetsV2Query(
    {
      organizationId,
      offset: 0,
      limit: 2_000,
      orderBy: 'name',
      sortOrder: SortOrder.Asc,
      productTypes: DEVICE_PRODUCT_TYPES,
    },
    {
      skip: !organizationId || !employeeId,
    }
  );

  const assets = assetsData?.results;

  /* For the purposes of an offboarding, the employee's
   *  returnable assets are those that are device assets
   *  assigned to that employee that have serial numbers.
   */
  const employeeDeviceAssets = assets?.filter(
    asset =>
      asset.assignedTo === employeeId &&
      asset.serialNumber.length > 0 &&
      isDeviceAssetProductType(asset)
  ) as Asset[];

  const hasDevices = !!employeeDeviceAssets?.length;
  const {
    data: employeeGroupsData,
    isFetching: isFetchingEmployeeGroups,
    isSuccess: isEmployeeGroupsDataLoaded,
  } = useGetOrganizationGroupsForEmployeeQuery(
    {
      organizationId,
      employeeId,
    },
    { skip: !employeeId || isFetchingEmployee }
  );

  // Memoizing groupsData to prevent unnecessary re-renders in useGetApplicationsForGroups
  const groupsData = useMemo(() => {
    return employeeGroupsData ?? [];
  }, [employeeGroupsData]);

  // Skip the applications query if employeeGroups data is not loaded
  const { data: appsByGroup, isLoading: isLoadingAppsByGroup } =
    useGetApplicationsForGroups(groupsData, {
      skip: !isEmployeeGroupsDataLoaded,
    });

  const hasNoApplications =
    isEmployeeGroupsDataLoaded &&
    !isLoadingAppsByGroup &&
    !appsByGroup?.flatMap(group => group.applications).length;

  // Was this offboarding initiated from an HRIS offboarding request?
  const hrisOffboardingRequestId =
    queryParams.get(HRIS_OFFBOARDING_REQUEST_ID_QUERY_PARAM) ?? '';

  const { updateRequest } = useUpdateRequestStatus({
    requestId: hrisOffboardingRequestId,
  });

  const [submitOffboarding, { isLoading: isSubmittingOffboarding }] =
    useCreateOffboardingMutation();

  const createOffboarding = useCallback(
    async (onSuccess: () => void) => {
      if (!employee?.id) {
        return;
      }
      try {
        await submitOffboarding({
          employeeId: employee.id,
          organizationId,
        }).unwrap();

        if (hrisOffboardingRequestId) {
          // Mark the HRIS offboarding request as completed
          await updateRequest({
            newStatus: RequestStatusEnum.Completed,
          });
        }

        onSuccess();
      } catch (error) {
        console.error('Unable to submit offboarding', error);
        window?.DD_RUM?.addError(error, {
          location: 'Offboarding submit',
        });
        showErrorToast({
          id: 'offboard-employee-error-toast',
          message: `Something went wrong, please try again later.`,
        });
      }
    },
    [
      employee,
      organizationId,
      showErrorToast,
      submitOffboarding,
      updateRequest,
      hrisOffboardingRequestId,
    ]
  );

  const handlePostApplicationStep = useCallback(async () => {
    const onSuccess = () => {
      hasDevices
        ? setFormStep(OffboardingSteps.Devices)
        : navigate(`/employees/${employeeId}`);
    };
    createOffboarding(onSuccess);
  }, [createOffboarding, hasDevices, navigate, employeeId]);

  useEffect(() => {
    if (formStep === OffboardingSteps.Applications && hasNoApplications) {
      setFormStep(OffboardingSteps.Devices);
      handlePostApplicationStep();
    }
  }, [
    hasNoApplications,
    formStep,
    hasDevices,
    createOffboarding,
    employeeId,
    handlePostApplicationStep,
  ]);

  if (isFetchingEmployee || isFetchingEmployeeGroups || isLoadingAppsByGroup) {
    return <CenteredSpinner mt={md ? '4rem' : '2rem'} />;
  }

  if (isSubmittingOffboarding) {
    return (
      <SpinnerWithMessage
        message="Offboarding employee..."
        mt={md ? '4rem' : '2rem'}
      />
    );
  }

  if (!employee) {
    return <PageNotFound />;
  }

  return (
    <OneColumnLayout>
      <Flex maxWidth="90rem" margin="0 auto" vertical>
        {formStep === OffboardingSteps.Applications && (
          <Applications
            appsByGroup={appsByGroup}
            isLoading={isLoadingAppsByGroup}
            isSubmitting={isSubmittingOffboarding}
            onNext={handlePostApplicationStep}
          />
        )}
        {formStep === OffboardingSteps.Devices && hasDevices && (
          <Devices assets={employeeDeviceAssets} employee={employee} />
        )}
      </Flex>
    </OneColumnLayout>
  );
};

export default NewOffboarding;

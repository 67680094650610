import { Link, Text } from '@electricjs/arc';

import { DeviceIssueType } from '@/types/devices';
import { EmployeeWithAssets } from '@/types/employees';

import EmailModal from './EmailModal';
import { gatekeeperLinks } from '@/constants/deviceIssueLinks';

type GatekeeperEmailModalProps = {
  hide: () => void;
  employees: EmployeeWithAssets[];
};

const GatekeeperEmailModal = ({
  hide,
  employees,
}: GatekeeperEmailModalProps) => {
  return (
    <EmailModal
      hide={hide}
      issueType={DeviceIssueType.Gatekeeper}
      employees={employees}
      showDevice={true}
      title="Send employees Gatekeeper instructions">
      <Text>
        We’ll send instructions to your employees on how to enable Gatekeeper
      </Text>
      <Text mt={3}>
        <Link target="_blank" rel="noopener" href={gatekeeperLinks.mac}>
          Gatekeeper instructions
        </Link>
      </Text>
      <Text mt={5}>Employees that don’t have gatekeeper enabled:</Text>
    </EmailModal>
  );
};

export default GatekeeperEmailModal;

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Banner, Box, Button, Flex, Link, Text } from '@electricjs/arc';

import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useLazyGetEmployeeQuery } from '@/redux/slices/employeeApiSlice';
import { useGetRequestTypeCategoriesQuery } from '@/redux/slices/organizationApiSlice';
import { useAppSelector } from '@/redux/store';
import { ChampionResponse } from '@/types/requests';
import { OrganizationUserRoleSlugs } from '@/types/users';

import { SettingsPageTabs } from '../../Organization/Settings/Settings';
import { useGetEmployeeAssetsQuery } from '@/redux/slices/assetApiSlice';
import {
  DeviceAsset,
  isDeviceAsset,
  isDeviceAssetProductType,
} from '@/types/assets';

const LOW_DISK_SPACE_THRESHOLD = 15.0;

export const MAC_LOW_DISK_REF_PAGE = 'https://support.apple.com/en-us/HT206996';
export const WINDOWS_LOW_DISK_REF_PAGE =
  'https://support.microsoft.com/en-us/windows/free-up-drive-space-in-windows-a18fae02-a0fa-8df9-9838-8970f9939de4';

const StyledBanner = styled(Banner)`
  & > div div:nth-child(2) div {
    max-width: 100%;
  }
`;

enum BannerType {
  Champions = 'champions',
  LowSpace = 'lowSpace',
  Firewall = 'firewall',
}

const getAvailableDiskSpace = (deviceAsset: DeviceAsset | undefined) => {
  if (!deviceAsset) return null;
  if (
    !deviceAsset.device.reportingData?.storageCapacity ||
    deviceAsset.device.reportingData?.storageCapacity === 0
  )
    return 0;

  return (
    ((deviceAsset.device.reportingData.storageAvailable ?? 0) /
      deviceAsset.device.reportingData?.storageCapacity) *
    100
  );
};

const NotificationsContainer = () => {
  const organizationId = useGetOrganizationId();

  const loggedUser = useAppSelector(state => state.loggedUser);
  if (!loggedUser) {
    // it's supposed to be initialized on parent component
    throw new Error('Could not find the Logged User');
  }

  const isAdmin = !!loggedUser.organizationUserRoles?.some(
    role => role.slug === OrganizationUserRoleSlugs.SUPER_ADMIN
  );

  const navigate = useNavigate();

  const [currentBanner, setCurrentBanner] = useState<BannerType>();
  const [closedBanners, setClosedBanners] = useState<BannerType[]>([]);

  const { data: assetsData, isFetching: isFetchingAsset } =
    useGetEmployeeAssetsQuery(
      {
        organizationId,
        employeeId: loggedUser.employeeId || '',
      },
      {
        skip: !loggedUser.employeeId,
      }
    );

  const deviceAssets = assetsData?.results
    .filter(isDeviceAssetProductType) // Filter by the product type
    .filter(isDeviceAsset); // Filter by assets with device information

  let storageManagementUrl = '';
  let showFirewallBanner = false;
  let showLowSpaceBanner = false;

  if (deviceAssets && deviceAssets.length > 0) {
    // This is an implicit rule of the platform. It is ASSUMED that an employee
    // should not have two assets with Device info.
    // This was being done by the previous device endpoint call and was made
    // more explicit here. At the time of this rework, the system was calling
    // the /me/devices endpoint from CoreEntity and filtering out the first
    // device. This is archiving the same logic but using the /assets endpoint.
    const deviceAsset = deviceAssets[0];

    const validOSTypes = ['darwin', 'windows'];
    const isMacOrWinDevice = validOSTypes.includes(
      deviceAsset?.device?.osType ?? ''
    );

    storageManagementUrl =
      deviceAsset?.device?.osType === 'darwin'
        ? MAC_LOW_DISK_REF_PAGE
        : WINDOWS_LOW_DISK_REF_PAGE;

    const availableDiskSpaceElectrolyte =
      deviceAsset && isDeviceAsset(deviceAsset)
        ? getAvailableDiskSpace(deviceAsset)
        : null;

    showLowSpaceBanner =
      !closedBanners.includes(BannerType.LowSpace) &&
      isMacOrWinDevice &&
      !!availableDiskSpaceElectrolyte &&
      availableDiskSpaceElectrolyte < LOW_DISK_SPACE_THRESHOLD;

    showFirewallBanner =
      !closedBanners.includes(BannerType.Firewall) &&
      isMacOrWinDevice &&
      !deviceAsset?.device?.reportingData?.firewall;
  }

  const {
    data: requestTypeCategories,
    isFetching: isFetchingRequestTypeCategories,
  } = useGetRequestTypeCategoriesQuery({
    organizationId: organizationId,
  });

  const requestChampions: ChampionResponse[] = [];

  requestTypeCategories?.forEach(category => {
    if (category?.champions?.length) {
      requestChampions.push(category?.champions[0]);
    }
  });

  const requestChampionIds = requestChampions.map(champion => champion.id);
  const requestChampionEmployeeIds = requestChampions.map(
    champion => champion.employeeId
  );

  const originalAdminEmployeeId = requestChampionEmployeeIds[0];

  const [
    getEmployee,
    {
      data: originalAdminEmployee,
      isFetching: isFetchingOriginalAdminEmployee,
    },
  ] = useLazyGetEmployeeQuery();

  useEffect(() => {
    if (originalAdminEmployeeId && organizationId) {
      getEmployee({ employeeId: originalAdminEmployeeId, organizationId });
    }
  }, [originalAdminEmployeeId, organizationId, getEmployee]);

  const originalAdminEmail = originalAdminEmployee?.email ?? '';

  // If an organization has just been created, all request types will have the same champion - the original admin.
  // If this is the case, show the champions banner.
  const organizationJustCreated: boolean = requestChampionIds.every(
    (id, _index, arr) => id === arr[0]
  );

  const showChampionsBanner =
    !closedBanners.includes(BannerType.Champions) &&
    !isFetchingOriginalAdminEmployee &&
    isAdmin &&
    organizationJustCreated;

  /**
   * `availableBanners` is sorted by banners priority to be displayed.
   */
  const availableBanners = useMemo(
    () => ({
      [BannerType.Champions]: showChampionsBanner,
      [BannerType.LowSpace]: showLowSpaceBanner,
      [BannerType.Firewall]: showFirewallBanner,
    }),
    [showChampionsBanner, showLowSpaceBanner, showFirewallBanner]
  );

  /**
   * When a banner is dismissed, it will be marked as closed and the next one in the availableBanners will be displayed.
   */
  const closeCurrentBanner: () => void = useCallback(() => {
    if (!currentBanner || closedBanners.includes(currentBanner)) return null;
    setClosedBanners([...closedBanners, currentBanner]);
    const nextBanner = (Object.keys(availableBanners) as BannerType[]).find(
      bannerType =>
        bannerType !== currentBanner &&
        availableBanners[bannerType] &&
        !closedBanners.includes(bannerType)
    );
    setCurrentBanner(nextBanner);
  }, [closedBanners, currentBanner, availableBanners]);

  /**
   * When a new banner is added, its information should be added in this constant in the proper position, and the type of banner in the BannerType enum.
   */
  const bannersParams = useMemo(
    () => ({
      [BannerType.Champions]: {
        title: 'Support Center is ready for your employees to use.',
        message: () => (
          <Text>
            Internal requests will be routed to the assigned Ticket Champions.
            All requests are currently being sent to{' '}
            <Text variant="label-large"> {originalAdminEmail} </Text>
          </Text>
        ),
        cta: (
          <Button
            id="update-ticket-champions-button"
            variant="outline"
            intent="info"
            onClick={() => {
              navigate(`/settings?tab=${SettingsPageTabs.SupportCenter}`);
            }}>
            Update Ticket Champions
          </Button>
        ),
        dismissible: true,
        ctaSecondary: 'Dismiss',
      },
      [BannerType.LowSpace]: {
        title: 'Low disk space',
        message: () => (
          <Text>
            Your device is running low on disk space.{' '}
            <Link href={storageManagementUrl}>Click here</Link> for guidance on
            how to free up some space.
          </Text>
        ),
        dismissible: true,
      },
      [BannerType.Firewall]: {
        title: 'Firewall not enabled',
        message: () => <Text>Your firewall is not enabled.</Text>,
        dismissible: true,
      },
    }),
    [navigate, originalAdminEmail, storageManagementUrl]
  );

  useEffect(() => {
    const firstBanner = (Object.keys(availableBanners) as BannerType[]).find(
      bannerType => {
        return availableBanners[bannerType];
      }
    );
    setCurrentBanner(firstBanner);
  }, [availableBanners]);

  if (
    isFetchingAsset ||
    isFetchingRequestTypeCategories ||
    isFetchingOriginalAdminEmployee ||
    !currentBanner
  )
    return null;

  return (
    <Flex margin="0 auto" width="100%" maxWidth="125rem">
      <Box width="100%">
        <StyledBanner
          id="notification-banner"
          stack
          isFlat
          width="100%"
          intent={currentBanner === BannerType.Champions ? 'info' : 'warning'}
          onClickDismiss={closeCurrentBanner}
          onClickCtaSecondary={closeCurrentBanner}
          {...bannersParams[currentBanner]}
        />
      </Box>
    </Flex>
  );
};

export default NotificationsContainer;

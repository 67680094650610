import { Flex, Icon, Text } from '@electricjs/arc';

import LinkNoUnderline from './LinkNoUnderline';

type ExternalLinkProps = {
  /**
   * ID of the component (used for analytics tracking)
   */
  id?: string;
  href: string;
  /** Whether the "external link" icon should be added in the right side of the link's label. Default value is `true`. */
  showIcon?: boolean;
  /** Whether the link should be displayed inline with the other components, instead of a block. Default value is `true`. */
  displayInline?: boolean;
  children: string;
};

/** Component to safely open external links in a new tab. It already pass `rel="noopener noreferrer"`.
 *  The link's label should be passed as children.
 */
const ExternalLink = ({
  id,
  href,
  showIcon = true,
  displayInline = true,
  children,
}: ExternalLinkProps) => {
  return (
    <LinkNoUnderline
      id={id}
      href={href}
      target="_blank"
      rel="noopener noreferrer">
      <Flex gap="1rem" display={displayInline ? 'inline-flex' : 'flex'}>
        <Text variant="label-small" intent="brand">
          {children}
        </Text>
        {showIcon && (
          <Icon icon="external-link" size="small" intent="primary" />
        )}
      </Flex>
    </LinkNoUnderline>
  );
};

export default ExternalLink;

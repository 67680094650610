import { createApi } from '@reduxjs/toolkit/query/react';

import { RootState } from '@/redux/store';

import { baseQueryCamelized } from './helpers/baseQueryCamelized';

export enum TagTypes {
  ActiveXboardingEmployeeIds = 'ActiveXboardingEmployeeIds',
  AddOn = 'AddOn',
  AdditionalInfo = 'AdditionalInfo',
  Application = 'Application',
  ApplicationUsers = 'ApplicationUsers',
  Asset = 'Asset',
  AssetLog = 'AssetLog',
  AssetImage = 'AssetImage',
  BillingInfo = 'BillingInfo',
  Device = 'Device',
  Employee = 'Employee',
  EmployeeBreach = 'EmployeeBreach',
  EmployeeWithDevice = 'EmployeeWithDevice',
  GlobalSite = 'GlobalSite',
  Group = 'Group',
  HelpfulLinks = 'HelpfulLinks',
  Order = 'Order',
  InternalOrder = 'InternalOrder',
  Product = 'Product',
  ProductProfileV3 = 'ProductProfileV3',
  HrisIntegrationIssue = 'HrisIntegrationIssue',
  HrisProvider = 'HrisProvider',
  IntakeForm = 'IntakeForm',
  LatestOffboardingByEmployeeId = 'LatestOffboardingByEmployeeId',
  LatestOnboardingByEmployeeId = 'LatestOnboardingByEmployeeId',
  Location = 'Location',
  MDMInformation = 'MDMInformation',
  MyUserData = 'MyUserData',
  Notification = 'Notification',
  Offboarding = 'Offboarding',
  Onboarding = 'Onboarding',
  OrganizationRequestTypeCategoryChampion = 'OrganizationRequestTypeCategoryChampion',
  Organization = 'Organization',
  OrganizationApplicationChampion = 'OrganizationApplicationChampion',
  PaymentMethods = 'PaymentMethods',
  RequestTypeCategoryChampion = 'RequestTypeCategoryChampion',
  Request = 'Request',
  RequestType = 'RequestType',
  Role = 'Role',
  Shipment = 'Shipment',
  Site = 'Site',
  SystemRequester = 'SystemRequester',
}

/**
 * The "apiSlice" itself. It should be only 1 per baseUrl.
 * The others (e.g.: userApiSlice) will be extensions of this slice.
 * The endpoints of each entity/domain will be injected in other files.
 */
export const apiSlice = createApi({
  reducerPath: 'apiSlice',
  baseQuery: baseQueryCamelized(
    {
      baseUrl: window._env_?.VITE_API_BASE_URL, // the "/v1" won't be added here to allow flexibility when new versions of endpoints start coming
      prepareHeaders: (headers, api) => {
        const accessToken = (api.getState() as RootState).loggedUser
          ?.accessToken;

        if (accessToken) {
          headers.set('authorization', `Bearer ${accessToken}`);
        }

        return headers;
      },
    },
    // If you want to camelCase the response data, set this to true. Note that it may break existing responses.
    { shouldCamelizeResponse: false }
  ),
  endpoints: () => ({}), // Its mandatory to have at least an empty object here. The endpoints will be defined and inject in the other files.
  tagTypes: Object.keys(TagTypes),
});

import { datadogRum } from '@datadog/browser-rum';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';

import { initPendo } from '@/services/pendo';
import { LoggedUser } from '@/types/users';
import { OrganizationUserRoleSlugs } from '@/types/users';

type SetExternalServicesProps = {
  loggedUser: LoggedUser | null;
  extraDataRequestCompleted: boolean;
};

/**
 * Set/reset external services as org/employee changes.
 * Returns when the first set is completed (Launch Darkly is initialized with user's org and id)
 */
export const useSetExternalServices = ({
  loggedUser,
  extraDataRequestCompleted,
}: SetExternalServicesProps) => {
  const [isRequesting, setIsRequesting] = useState(false);
  const [requestCompleted, setRequestCompleted] = useState(false);
  const [
    externalServicesFirstSetCompleted,
    setExternalServicesFirstSetCompleted,
  ] = useState(false);

  const ldClient = useLDClient();

  useEffect(() => {
    const setExternalServices = async () => {
      if (
        ldClient &&
        loggedUser &&
        extraDataRequestCompleted &&
        !isRequesting &&
        !requestCompleted
      ) {
        // Wrap this in a try/catch so we can log to DD if there's an initialization error with our 3rd party tools
        try {
          console.info('**** External Services: started ****');
          setIsRequesting(true);

          if (
            window._env_?.VITE_DATADOG_APPLICATION_ID &&
            window._env_?.VITE_DATADOG_CLIENT_TOKEN
          ) {
            // Set Datadog RUM user excluding employee name, it's set later
            datadogRum.setUser({
              id: loggedUser.id,
              employee_id: loggedUser.employeeId,
              email: loggedUser.email,
              customer_id: loggedUser.organizationId,
              customer_name: loggedUser.organizationName,
              plan: loggedUser.organizationPlanName,
            });
          } else {
            console.warn('DataDog user not set, missing environment variables');
          }

          initPendo({
            visitor: {
              id: loggedUser.id ?? '',
              email: loggedUser.email,
              isAdmin:
                loggedUser.organizationUserRoles?.some(
                  role => role.slug === OrganizationUserRoleSlugs.SUPER_ADMIN
                ) ?? false,
              isProdSupport:
                loggedUser.organizationUserRoles?.some(
                  role =>
                    role.slug === OrganizationUserRoleSlugs.PRODUCT_SUPPORT
                ) ?? false,
            },
            account: {
              id: loggedUser.organizationId ?? '',
              name: loggedUser.organizationName ?? '',
              licenseCount: loggedUser.organizationLicenseCount,
              plan: loggedUser.organizationPlanName ?? '',
            },
          });

          try {
            await ldClient.identify(
              {
                kind: 'multi',
                user: {
                  key: loggedUser.id,
                  email: loggedUser.email,
                },
                organization: {
                  key: loggedUser.organizationId,
                  name: loggedUser.organizationName,
                },
              },
              'null'
            );
            console.info(
              'Feature Flags updated based on organization/employee'
            );
          } catch {
            console.error(
              'Could not update Feature Flags based organization/employee'
            );
          }

          if (!externalServicesFirstSetCompleted) {
            setExternalServicesFirstSetCompleted(true);
            console.info('**** External Services: first set completed ****');
          }

          console.info('**** External Services: finished ****');
          setRequestCompleted(true);
          setIsRequesting(false);
        } catch (error) {
          window.DD_RUM?.addError(error, {
            location: 'useSetExternalServices',
          });
          setRequestCompleted(true);
          setIsRequesting(false);
        }
      }

      // Log errors if external services are not initialized because of a Launch Darkly init error or missing user data
      if (extraDataRequestCompleted) {
        if (!loggedUser) {
          window.DD_RUM?.addError(
            'External services not initialized yet, loggedUser is null',
            {
              location: 'useSetExternalServices',
            }
          );
        }
        if (!ldClient) {
          window.DD_RUM?.addError(
            'External services not initialized yet, ldClient is null',
            {
              location: 'useSetExternalServices',
            }
          );
        }
      }
    };

    setExternalServices();
  }, [
    loggedUser,
    extraDataRequestCompleted,
    ldClient,
    externalServicesFirstSetCompleted,
    isRequesting,
    requestCompleted,
  ]);

  return {
    externalServicesFirstSetCompleted,
  };
};

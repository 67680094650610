import { PermissionEnum } from '@electricjs/core_entity-client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';

import { Flex, TabPanel, useBreakpoint } from '@electricjs/arc';

import AddOnTile from '@/components/Billing/AddOnTile';
import BasePlanTile from '@/components/Billing/BasePlanTile';
import InvoicesTile from '@/components/Billing/InvoicesTile';
import { useUserHasOrganizationPermission } from '@/hooks/useUserHasOrganizationPermission';
import { useLazyGetOrganizationQuery } from '@/redux/slices/organizationApiSlice';

import PaymentTile from '../../../components/Billing/PaymentTile';
import WarehousedDevicesTile from '@/components/Billing/WarehousedDevicesTile';

const BillingPane = ({ orgId }: { orgId: string }) => {
  const [
    getOrganization,
    { data: organization, isFetching: organizationLoading },
  ] = useLazyGetOrganizationQuery();

  useEffect(() => {
    if (orgId) {
      getOrganization(orgId, true);
    }
  });

  const { xl, lg } = useBreakpoint();

  // Divides the BillingPane in a grid of two columns (2x2) when screen size is HD (not FullHD or mobile)
  const isInGrid = !((xl && lg) || (!xl && !lg));

  // Default height for each tile when in grid (2x2)
  const gridRowHeight = '30rem';

  const canReadOrganizationPackage = useUserHasOrganizationPermission(
    PermissionEnum.OrganizationPackageread
  );

  const canUpdateOrganizationPackage = useUserHasOrganizationPermission(
    PermissionEnum.OrganizationPackageupdate
  );

  const canCreateOrganizationAddOns = useUserHasOrganizationPermission(
    PermissionEnum.OrganizationAddOnscreate
  );

  const canReadOrganizationAddOns = useUserHasOrganizationPermission(
    PermissionEnum.OrganizationAddOnsread
  );

  const canUpdateOrganizationAddOns = useUserHasOrganizationPermission(
    PermissionEnum.OrganizationAddOnsupdate
  );

  const canReadOrganizationInvoices = useUserHasOrganizationPermission(
    PermissionEnum.Billingread
  );

  const { showInvoicesTab: isInvoicesTabEnabled, paymentIntegration } =
    useFlags();

  return (
    <TabPanel>
      <Flex vertical={!isInGrid} rowGap="1.6rem" columnGap="1.6rem">
        <Flex vertical width={isInGrid ? '50%' : '100%'} rowGap="1.6rem">
          {canReadOrganizationPackage && (
            <BasePlanTile
              canUpdateOrganizationPackage={canUpdateOrganizationPackage}
              organization={organization}
              organizationLoading={organizationLoading}
              isInGrid={isInGrid}
              height={gridRowHeight}
            />
          )}
          {canReadOrganizationAddOns && (
            <AddOnTile
              canCreateOrganizationAddOns={canCreateOrganizationAddOns}
              canUpdateOrganizationAddOns={canUpdateOrganizationAddOns}
              organization={organization}
              organizationLoading={organizationLoading}
              isInGrid={isInGrid}
              height={gridRowHeight}
            />
          )}
          <WarehousedDevicesTile
            organization={organization}
            organizationLoading={organizationLoading}
            isInGrid={isInGrid}
            height={gridRowHeight}
          />
        </Flex>
        <Flex vertical width={isInGrid ? '50%' : '100%'} rowGap="1.6rem">
          {paymentIntegration && (
            <PaymentTile isInGrid={isInGrid} height={gridRowHeight} />
          )}
          {canReadOrganizationInvoices && isInvoicesTabEnabled && (
            <InvoicesTile
              orgId={orgId}
              isInGrid={isInGrid}
              height={gridRowHeight}
            />
          )}
        </Flex>
      </Flex>
    </TabPanel>
  );
};

export default BillingPane;

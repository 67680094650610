import {
  ProductTypeName,
  ShippingMethod,
  ConnectivityEnum,
  PortTypesEnum,
} from '@electricjs/core_entity-client';

import {
  SortingParams,
  PaginatedResponse,
  PaginationParams,
} from '@/types/common';
import { type Asset } from '@/types/assets';

//
// Storefront's general types
//

export enum StorefrontSteps {
  SHOPPING = 'SHOPPING',
  CART = 'CART',
  CHECKOUT = 'CHECKOUT',
  ORDER_PLACED = 'ORDER_PLACED',
}

export enum Store {
  Catalog = 'catalog',
  Asset = 'asset',
}

//
// Storefront's ProductType groups (the specific grouping used in Storefront filters)
//

/** It's the group of "product types" in Storefront (selected through the big buttons on the top)
 *  For example, the group "Keyboards and Mice" has 3 product types (Keyboards, Mouses, Combos).
 *  But some groups may have only 1 product type (e.g., Monitors).
 */
export enum StorefrontProductTypeGroup {
  Computers = 'computers',
  Monitors = 'monitors',
  KeyboardMouse = 'keyboardMouseRelated',
  Docks = 'docks',
  AudioVideo = 'audioVideo',
  Misc = 'misc',
}

/** The correspondent product types for each type group */
export const productTypesByTypeGroup = {
  [StorefrontProductTypeGroup.Computers]: [
    ProductTypeName.Laptop,
    ProductTypeName.Desktop,
  ],
  [StorefrontProductTypeGroup.Monitors]: [ProductTypeName.Monitor],
  [StorefrontProductTypeGroup.KeyboardMouse]: [
    ProductTypeName.Keyboard,
    ProductTypeName.Mouse,
    ProductTypeName.KeyboardMouse, // it's the keyboard+mouse combo
  ],
  [StorefrontProductTypeGroup.Docks]: [ProductTypeName.Dock],
  [StorefrontProductTypeGroup.AudioVideo]: [
    ProductTypeName.Audio, // keeping temporarily for backward compatibility. It will be replaced by Headphones, Microphone
    ProductTypeName.Headphones,
    ProductTypeName.Microphone,
    ProductTypeName.Video,
  ],
  [StorefrontProductTypeGroup.Misc]: [
    ProductTypeName.Miscellaneous, // keeping temporarily for backward compatibility. It will be replaced by PowerAdapter, Cable, Accessory
    ProductTypeName.PowerAdapter,
    ProductTypeName.Cable,
    ProductTypeName.Accessory,
    ProductTypeName.Phone,
    ProductTypeName.Tablets,
  ],
};

//
// Storefront's filters
//

export type DynamicFilters = {
  [filterKey in FilterKeyEnum]?: string[]; // selected values of each filter (e.g brand, price_range)
};

export enum FilterKeyEnum {
  PRICE = 'price_range',
  RAM = 'ram_range',
  SIZE = 'size_range',
  DISPLAY = 'displays_supported_range',
  BRAND = 'brand',
  /** This ProductType is used both for product_types changed by the group selector as for the inner changes (using dynamic filter). But it's always handled directly by the productType handler (it's not used as query param by dynamic filters)  */
  TYPE = 'product_type',
}

export type StoreFiltersSelections = {
  selectedProductTypes: ProductTypeName[];
  dynamicFiltersSelectedValues?: DynamicFilters;
  selectedSorting: SortingParams;
  selectedPage: number;
};

//
// Storefront's cart
//

/** Represents one entry in the Catalog cart */
export type CatalogCartEntry = {
  item: Product;
  quantity: number;
  selectedWarranty?: Warranty;
};

/** Represents one entry in the Asset cart */
export type AssetCartEntry = {
  item: Asset;
};

/** It's an object with all carts */
export type FinalCart = {
  catalogCart: CatalogCartEntry[];
  assetCart?: AssetCartEntry[];
};

//
// Storefront's shipping
//

export type ShippingMethodOption = {
  label: string;
  value: ShippingMethod;
  shippingDate: Date;
  deliveryDate: Date;
};

//
// Storefront's ShippingDestination/Location (From old version used in Turbine. Currently not being used in ITH)
//

/** It's the old concept of "Location" (used in Turbine's storefront).
 *  It was renamed in ITH to be "ShippingDestination", because Location means another thing in ITH.
 *  It's currently not being used in ITH, but when it does, it will probably be replaced by the concept of "Sites"
 * */
export type ShippingDestination = {
  id: string;
  phone: string;
  name: string;
  streetAddress1: string;
  streetAddress2?: string | null;
  city: string;
  country: string;
  state: string;
  zip: string;
};

export type ShippingDestinationOption = {
  label: string;
  testId: string;
  value: ShippingDestination;
};

/** It's the "Remote shipping destination" option with the base fields that will make this option understandable as "remote" internally */
export const baseRemoteShippingDestinationOption: ShippingDestinationOption = {
  label: 'Remote destination',
  testId: 'remote-shipping-destination',
  value: {
    id: 'remote-shipping-destination', // this id will be used by storefront to know that this is the special "remote" option
    phone: '',
    name: '',
    streetAddress1: '',
    streetAddress2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  },
};

/** It's an example of remote destination to be passed to the `remoteShippingDestination` prop when needed */
export const emptyRemoteShippingDestination: Partial<ShippingDestination> = {
  name: '',
  phone: '',
  streetAddress1: '',
  streetAddress2: '',
  city: '',
  state: '',
  country: '',
  zip: '',
};

/**
 * It's the Electric Inventory address that will held the customer's hardware.
 * It will be passed as "remote shipping destination" by the host app when needed.
 */
export const electricInventoryShippingDestination: Partial<ShippingDestination> =
  {
    name: 'Electric c/o Techvera',
    phone: '(252) 656-6580',
    streetAddress1: '625 Dallas Drive',
    streetAddress2: '#450',
    city: 'Denton',
    country: 'United States',
    state: 'Texas',
    zip: '76205',
  };

//
// Storefront's provisioning (From old version used in Turbine. Currently not being used in ITH)
//

/** It's the frontend enum for provisioning types.
 *  It's currently not being used in ITH, but when it does, it will probably be replaced by `ProvisioningType` exported from `@electricjs/core_entity-client`
 */
export enum ProvisioningType {
  Remote = 'REMOTE',
  AtElectric = 'AT_ELECTRIC',
  DeviceHasAutoEnrollment = 'DEVICE_HAS_AUTO_ENROLLMENT',
  NoProvisioningSupport = 'NO_PROVISIONING_SUPPORT',
}

export type ProvisioningOption = {
  label: string;
  value: ProvisioningType;
  isDisabled: boolean;
};

//
// Storefront's v2 products (Will be deleted after v3 is finished. Also, the v3 lives in it's own product domain file, because it's shared across other parts of ITH)
//

export type GetProductsArgs = PaginationParams &
  SortingParams & {
    productTypes: ProductTypeName[];
    dynamicFilters?: DynamicFilters;
  };

export type ProductsResponse = {
  paginatedResponse: PaginatedResponse<Product>;
  filters?: {
    [filterKey in ProductTypeName]?: DynamicFilters;
  };
};

type BaseProduct = {
  id: string;
  name: string;
  brand: string;
  productType: ProductTypeName;
  description?: string;
  price: number;
  image?: string;
  freeTextSpecs?: string[];
  sku: number;
  taxCode: string;
};

export type Warranty = BaseProduct & {
  productType: typeof ProductTypeName.Warranty;
  includes: string[];
};

type Laptop = BaseProduct & {
  productType: typeof ProductTypeName.Laptop;
  storage: string;
  memory: number; // in GB
  processor: string;
  screenSize: number; // in Inch
  warranties?: Warranty[];
  defaultWarranty?: Warranty;
};

type Monitor = BaseProduct & {
  productType: typeof ProductTypeName.Monitor;
  screenSize: number; // in Inch
  quality: string; // e.g. 1080p
};

type KeyboardOrMouse = BaseProduct & {
  productType:
    | typeof ProductTypeName.Keyboard
    | typeof ProductTypeName.Mouse
    | typeof ProductTypeName.KeyboardMouse;
  connectivity: ConnectivityEnum[];
  portTypes: PortTypesEnum[];
};

type Audio = BaseProduct & {
  productType: typeof ProductTypeName.Audio;
  connectivity: ConnectivityEnum[];
};

type Video = BaseProduct & {
  productType: typeof ProductTypeName.Video;
  quality: string; // e.g. 1080p
  connectivity: ConnectivityEnum[];
};

type Dock = BaseProduct & {
  productType: typeof ProductTypeName.Dock;
  numberOfDisplays: number; // Number of displays supported
};

export type Product =
  | BaseProduct
  | Warranty
  | Laptop
  | Monitor
  | KeyboardOrMouse
  | Audio
  | Video
  | Dock;

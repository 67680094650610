import { ReactNode, createContext, useCallback, useContext } from 'react';

import { LocalStorageKeys } from '@/constants/localStorageKeys';

import { useStoredState } from '../../common/useStoredState';

type IntakeFormContextType = {
  skipIntakeForm: (value: boolean) => void;
  showIntakeForm: boolean;
};

type IntakeFormProviderProps = {
  children: ReactNode;
};

const IntakeFormContext = createContext<IntakeFormContextType>({
  skipIntakeForm: () => {},
  showIntakeForm: false,
});

export const IntakeFormProvider = (props: IntakeFormProviderProps) => {
  const [skipIntakeForm, setSkipIntakeForm] = useStoredState(
    LocalStorageKeys.SKIP_INTAKE_FORM,
    false
  );

  return (
    <IntakeFormContext.Provider
      value={{
        showIntakeForm: !skipIntakeForm,
        skipIntakeForm: useCallback(
          value => setSkipIntakeForm(value),
          [setSkipIntakeForm]
        ),
      }}>
      {props.children}
    </IntakeFormContext.Provider>
  );
};

export const useIntakeFormUI = () => useContext(IntakeFormContext);

import { useState } from 'react';

import {
  Button,
  Checkbox,
  Flex,
  Header,
  PaneWithBorder,
  Text,
} from '@electricjs/arc';

import EmployeeAppsByGroup from '@/components/People/EmployeeAppsByGroup/EmployeeAppsByGroup';
import { AppsByGroup } from '@/components/People/EmployeeAppsByGroup/useGetApplicationsForGroups';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getFullName } from 'common/utils/getFullName';
import { CenteredSpinner } from '@common';
import {
  useGetEmployeeQuery,
  useGetOrganizationGroupsForEmployeeQuery,
} from '@/redux/slices/employeeApiSlice';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { SelectionParamKeys } from '@/types/queryParamKeys';

type ApplicationsProps = {
  appsByGroup: AppsByGroup[] | undefined;
  isLoading: boolean;
  onNext: () => void;
  isSubmitting: boolean;
};

const Applications = ({
  appsByGroup,
  isLoading,
  isSubmitting,
  onNext,
}: ApplicationsProps) => {
  const [userUnderstands, setUserUnderstands] = useState(false);

  const organizationId = useGetOrganizationId();
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const employeeId = queryParams.get(SelectionParamKeys.EmployeeId) || '';

  const { employeeName, isFetching: isFetchingEmployee } = useGetEmployeeQuery(
    {
      employeeId,
      organizationId,
    },
    {
      skip: !employeeId,
      selectFromResult: ({ data, isFetching }) => ({
        employeeName: getFullName(data?.firstName, data?.lastName),
        isFetching,
      }),
    }
  );

  const { data: employeeGroups, isFetching: isFetchingEmployeeGroups } =
    useGetOrganizationGroupsForEmployeeQuery(
      {
        organizationId,
        employeeId,
      },
      { skip: !employeeId }
    );

  const handleCancel = () => {
    navigate(`/employees/${employeeId}`);
  };

  if (!appsByGroup?.length || isFetchingEmployee || isFetchingEmployeeGroups) {
    return <CenteredSpinner />;
  }

  return (
    <>
      <Header
        title="Application access to be revoked"
        subtitle={`These are the applications that ${employeeName} has gained access to using Electric. We'll notify the app champions of these applications to revoke access for this employee.`}
      />
      <PaneWithBorder mt="4rem" minHeight="18rem">
        <Flex vertical width="100%">
          <EmployeeAppsByGroup
            employeeGroups={employeeGroups}
            isLoading={isLoading}
            appsByGroup={appsByGroup}
          />
        </Flex>
      </PaneWithBorder>
      <Flex vAlignContent="center" m="2rem 0 0 2rem">
        <Checkbox
          checked={userUnderstands}
          onClick={() => {
            setUserUnderstands(oldValue => !oldValue);
          }}
          id="user-understands-checkbox"
        />
        <Text>
          <label htmlFor="user-understands-checkbox">
            I understand that this action can not be undone
          </label>
        </Text>
      </Flex>
      <Flex mt="9rem" hAlignContent="right" gap="3.4rem" alignSelf="flex-end">
        <Button
          id="applications-revoke-cancel-button"
          variant="text"
          disabled={isSubmitting}
          onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          id="applications-revoke-button"
          onClick={onNext}
          disabled={!userUnderstands}
          loading={isSubmitting}>
          Revoke access
        </Button>
      </Flex>
    </>
  );
};

export default Applications;

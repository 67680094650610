import { useMemo } from 'react';
import Fuse from 'fuse.js';
import { ASSET_UNAVAILABLE_STATUSES } from '@/hooks/useGetEmployeesWithAssets';
import { useListAssetsV2 } from '@/hooks/useListAssetsV2';
import { AssetStatusValue } from './AssetsTable';
import { Asset } from '@/types/assets';
import { AssetStatus, ProductTypeName } from '@electricjs/core_entity-client';
import { Warranty } from './types';

const searchableFields = [
  'name',
  'customAssetId',
  'status',
  'serialNumber',
  'assignedToEmployee.firstName',
  'assignedToEmployee.lastName',
  'site.name',
  'brand',
  'model',
  'productType.name',
];

type UseFilteredAssetsDataParams = {
  searchTerm: string;
  assetStatusValue: AssetStatusValue | null;
  status: AssetStatus[];
  productType: ProductTypeName[];
  warranty: Warranty[];
  excludeAssigned?: boolean;
};

const useFilteredAssetsData = ({
  searchTerm,
  assetStatusValue,
  status,
  productType,
  warranty,
  excludeAssigned = false,
}: UseFilteredAssetsDataParams) => {
  const { assets, total, isFetching, isLoading, error } = useListAssetsV2();

  // Filter by search term
  const filteredAssets = useMemo(() => {
    if (!assets) return [];

    if (searchTerm.trim() === '') return assets;

    const fuse = new Fuse<Asset>(assets, {
      keys: searchableFields,
      threshold: 0.2,
    });
    return fuse.search(searchTerm).map(result => result.item);
  }, [assets, searchTerm]);

  // Filter by Active/Archived
  const assetsByActiveOrArchived = useMemo(() => {
    if (assetStatusValue === null) return filteredAssets;

    if (assetStatusValue === AssetStatusValue.Active) {
      return filteredAssets.filter(
        asset => !ASSET_UNAVAILABLE_STATUSES.has(asset.status)
      );
    } else if (assetStatusValue === AssetStatusValue.Archived) {
      return filteredAssets.filter(asset =>
        ASSET_UNAVAILABLE_STATUSES.has(asset.status)
      );
    }

    return filteredAssets;
  }, [filteredAssets, assetStatusValue]);

  // Filter by Asset's status
  const assetsByStatus = useMemo(() => {
    if (status.length === 0) return assetsByActiveOrArchived;

    return assetsByActiveOrArchived.filter(asset =>
      status.includes(asset.status)
    );
  }, [assetsByActiveOrArchived, status]);

  const assetsByProductType = useMemo(() => {
    if (productType.length === 0) return assetsByStatus;

    return assetsByStatus.filter(asset =>
      productType.includes(asset.productType.name)
    );
  }, [assetsByStatus, productType]);

  const assetsByExcludeAssigned = useMemo(() => {
    if (excludeAssigned === false) return assetsByProductType;

    return assetsByProductType.filter(asset => !asset.assignedTo);
  }, [assetsByProductType, excludeAssigned]);

  const assetsByWarranty = useMemo(() => {
    if (warranty.length === 0) return assetsByExcludeAssigned;

    return assetsByExcludeAssigned.filter(asset => {
      // If there's no warranty expiration date
      if (!asset.warrantyExpirationDate) {
        return warranty.includes(Warranty.UNKNOWN);
      }

      const warrantyExpirationDate = new Date(asset.warrantyExpirationDate);
      const currentDate = new Date();

      return warranty.some(warrantyType => {
        switch (warrantyType) {
          case Warranty.EXPIRED:
            return warrantyExpirationDate < currentDate;
          case Warranty.ACTIVE:
            return warrantyExpirationDate >= currentDate;
          case Warranty.UNKNOWN:
            return false; // Already handled above
          default:
            return false;
        }
      });
    });
  }, [assetsByExcludeAssigned, warranty]);

  // Assets count after filtering
  const assetsCount = assetsByWarranty.length;

  return {
    assets: assetsByWarranty,
    totalAssets: total,
    assetsCount,
    isFetching,
    isLoading,
    error,
  };
};

export default useFilteredAssetsData;

import * as yup from 'yup';

import {
  optionalAddressValidationSchema,
  requiredAddressValidationSchema,
} from '@electricjs/arc';

import { phoneValidationSchema } from '@common';

import { HiringStatus, OnboardingStep } from './types';
import { emailRegex } from '@/constants/email';

yup.setLocale({
  mixed: {
    required: 'This field is required',
  },
});

const newHireDemographicsSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  personalEmail: yup.string().matches(emailRegex, 'Invalid email').required(),
  address: requiredAddressValidationSchema,
  phone: phoneValidationSchema({ required: true }),
});

const existingEmployeeDemographicsSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  personalEmail: yup
    .string()
    .matches(emailRegex, 'Invalid email')
    .notRequired(),
  address: optionalAddressValidationSchema,
  phone: phoneValidationSchema(),
});

const hrisEmployeeDemographicsSchema = yup.object().shape({
  firstName: yup.string().notRequired(),
  lastName: yup.string().notRequired(),
  personalEmail: yup.string().notRequired(),
  address: optionalAddressValidationSchema,
  phone: yup.string().notRequired(),
});

const demographicsSchema = yup.object().shape({
  demographics: yup
    .object()
    .when('hiringStatus', ([hiringStatus]) =>
      hiringStatus === HiringStatus.New
        ? newHireDemographicsSchema
        : hiringStatus === HiringStatus.Existing
          ? existingEmployeeDemographicsSchema
          : hrisEmployeeDemographicsSchema
    ),
});

const newHireEmployeeInformationSchema = yup.object().shape({
  startDate: yup.date().required(),
  jobTitle: yup.string().required(),
  email: yup.string().matches(emailRegex, 'Invalid email').required(),
});

const existingEmployeeEmployeeInformationSchema = yup.object().shape({
  startDate: yup.date().notRequired(),
  jobTitle: yup.string().notRequired(),
  email: yup.string().matches(emailRegex, 'Invalid email').required(),
});

const hrisEmployeeEmployeeInformationSchema = yup.object().shape({
  startDate: yup.date().notRequired(),
  jobTitle: yup.string().notRequired(),
  email: yup.string().notRequired(),
});

const employeeInformationSchema = yup.object().shape({
  employeeInformation: yup
    .object()
    .when('hiringStatus', ([hiringStatus]) =>
      hiringStatus === HiringStatus.New
        ? newHireEmployeeInformationSchema
        : hiringStatus === HiringStatus.Existing
          ? existingEmployeeEmployeeInformationSchema
          : hrisEmployeeEmployeeInformationSchema
    ),
});

export const onboardingSchema = yup.lazy(({ currentStep }) => {
  return currentStep === OnboardingStep.Demographics
    ? demographicsSchema
    : employeeInformationSchema;
});

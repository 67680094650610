import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

import { Flex, OneColumnLayout } from '@electricjs/arc';

import Demographics from '@/components/Onboardings/Demographics';
import EmployeeInformation from '@/components/Onboardings/EmployeeInformation';
import Onboarding from '@/components/Onboardings/Onboarding';
import { onboardingSchema } from '@/components/Onboardings/OnboardingSchema';
import {
  OnboardingFormData,
  HiringStatus,
  OnboardingStep,
} from '@/components/Onboardings/types';
import { useAddOrganizationEmployeesMutation } from '@/redux/slices/organizationApiSlice';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useGlobalUI } from '@/components/GlobalUIProvider';
import { getEmployeeData } from './helpers';
import Applications from '@/components/Onboardings/Applications';
import { useEffect, useState } from 'react';
import useAddEmployeeToGroups from '@/hooks/useAddEmployeeToGroups';
import { Group } from '@/types/groups';
import { useCreateOnboardingMutation } from '@/redux/slices/onboardingApiSlice';
import { useSearchParams } from 'react-router-dom';
import {
  useBulkUpdateEmployeeStatusMutation,
  useLazyGetEmployeeQuery,
  useLazyGetOrganizationGroupsForEmployeeQuery,
} from '@/redux/slices/employeeApiSlice';
import { useUpdateRequestStatus } from '@/hooks/useUpdateRequestStatus';
import {
  EmployeeStatusEnum,
  RequestStatusEnum,
} from '@electricjs/core_entity-client';
import { EmployeeStatus } from '@/types/employees';
import {
  EXISTING_EMPLOYEE_ID_QUERY_PARAM,
  HRIS_ONBOARDING_REQUEST_ID_QUERY_PARAM,
} from '@/constants/onboarding';
import SpinnerWithMessage from 'common/SpinnerWithMessage';
import { CenteredSpinner } from '@common';
import { getFullName } from 'common/utils/getFullName';

const NewOnboarding = () => {
  const [employeeId, setEmployeeId] = useState('');
  const [addOrganizationEmployees] = useAddOrganizationEmployeesMutation();
  const { addEmployeeToGroups, isLoading: isAddingEmployee } =
    useAddEmployeeToGroups();
  const [createOnboarding, { isLoading: isCreatingOnboarding }] =
    useCreateOnboardingMutation();

  const organizationId = useGetOrganizationId();

  const { showSuccessToast, showErrorToast } = useGlobalUI();

  const defaultValues = {
    demographics: {
      firstName: '',
      lastName: '',
      personalEmail: '',
      address: {
        streetAddress1: '',
        streetAddress2: '',
        city: '',
        state: '',
        country: '',
      },
      phone: '',
    },
    employeeInformation: {
      startDate: null,
      jobTitle: '',
      email: '',
    },
    currentStep: 1,
    hiringStatus: HiringStatus.New,
    needsApplications: false,
    needsHardware: false,
    groups: [],
  };

  const methods = useForm<OnboardingFormData>({
    defaultValues,
    mode: 'onTouched',
    // @ts-expect-error: This is a known issue with yupResolver.
    // It has been fixed in version 3.3.0, but the update would affect multiple forms in the application where the typing is not accurate.
    //https://github.com/react-hook-form/resolvers/issues/567#issuecomment-1688110169
    resolver: yupResolver(onboardingSchema),
  });
  const { control, getValues, handleSubmit, setValue, reset } = methods;

  const currentStep = useWatch({ control, name: 'currentStep' });

  const handleErrorState = (logError?: string) => {
    showErrorToast({
      id: 'add-employee-error-toast',
      message: 'There was a problem adding the employee. Please try again.',
      stack: true,
    });

    if (logError) {
      window?.DD_RUM?.addError(logError, {
        location: 'Add New Employee',
      });

      console.error(logError);
    }
  };

  // `addEmployee` accepts a callback function `onSuccess` that is called with the `employeeId` as its argument if the employee is successfully added.
  // The employeeId is used to navigate to the employee's profile page and to determine the successful addition of the employee before proceeding in the child components.
  const addEmployee = (
    onSuccess: (employeeId: string) => void,
    status?: EmployeeStatusEnum
  ): void => {
    handleSubmit(async (formData: OnboardingFormData) => {
      const employeeData = getEmployeeData(formData);
      try {
        const addEmployeeResponse = await addOrganizationEmployees({
          organizationId,
          employees: [{ ...employeeData, status }],
          createUser: false,
        }).unwrap();

        if (addEmployeeResponse?.failedEmployeeEmails?.length) {
          const errorMessage = 'Error while adding new employee.';
          handleErrorState(errorMessage);
          return;
        }

        const employeeId = addEmployeeResponse?.addedEmployees?.[0]?.id;
        setEmployeeId(employeeId);
        onSuccess(employeeId);
      } catch (error) {
        handleErrorState(
          `Error while adding new employee: ${JSON.stringify(error)}`
        );
      }
    })();
  };

  // `addToGroups` receives the employeeGroups selected by the user and a callback function to be executed after the employee is added to the groups.
  const addToGroups = async (
    employeeGroups: Group[],
    onSuccess: () => void
  ) => {
    try {
      await addEmployeeToGroups(employeeId, employeeGroups);
      onSuccess();
    } catch (error) {
      handleErrorState(
        `Error while adding the employee to groups: ${JSON.stringify(error)}`
      );
    }
  };

  // Is this onboarding for an existing employee?
  // (probably one that we ingested from an HRIS and is currently in UNKNOWN status)
  const [queryParams] = useSearchParams();

  const existingEmployeeId =
    queryParams.get(EXISTING_EMPLOYEE_ID_QUERY_PARAM) ?? '';
  const hrisOnboardingRequestId =
    queryParams.get(HRIS_ONBOARDING_REQUEST_ID_QUERY_PARAM) ?? '';

  const [
    getEmployee,
    { data: existingEmployee, isFetching: isFetchingEmployee },
  ] = useLazyGetEmployeeQuery();

  const [
    getGroupsForEmployee,
    { data: existingEmployeeGroups, isFetching: isFetchingGroups },
  ] = useLazyGetOrganizationGroupsForEmployeeQuery();

  useEffect(() => {
    if (existingEmployeeId) {
      // If we have an existing employee ID passed in the URL, this is the employee we're dealing with
      setEmployeeId(existingEmployeeId);

      // Fetch the employee and their groups
      getEmployee({ organizationId, employeeId: existingEmployeeId });
      getGroupsForEmployee({ organizationId, employeeId: existingEmployeeId });
    }

    // Set the form values to the existing employee's data
    // This will bypass the first two steps of the onboarding flow, where we'd usually collect this info.
    // In this case, we've already retrieved it from the HRIS.
    if (existingEmployee && existingEmployeeId) {
      reset(
        {
          demographics: {
            firstName: existingEmployee.firstName ?? '',
            lastName: existingEmployee.lastName ?? '',
            personalEmail: existingEmployee.personalEmail ?? '',
            address: {
              streetAddress1: existingEmployee.address?.streetAddress1 ?? '',
              streetAddress2: existingEmployee.address?.streetAddress2 ?? '',
              city: existingEmployee.address?.city ?? '',
              state: existingEmployee.address?.state ?? '',
              country: existingEmployee.address?.country ?? '',
              zip: existingEmployee.address?.zip ?? '',
            },
            phone: existingEmployee.phone ?? '',
          },
          employeeInformation: {
            startDate: existingEmployee.startDate
              ? new Date(existingEmployee.startDate)
              : null,
            jobTitle: existingEmployee.jobTitle ?? '',
            email: existingEmployee.email ?? '',
          },
          groups: existingEmployeeGroups ?? [],
          currentStep: OnboardingStep.Onboarding,
          hiringStatus: HiringStatus.Hris,
        },
        // Don't reset the defaultValues, only update the form values
        { keepDefaultValues: true }
      );
    }
  }, [
    existingEmployeeId,
    setValue,
    reset,
    existingEmployee,
    existingEmployeeGroups,
    getEmployee,
    getGroupsForEmployee,
    organizationId,
  ]);

  const [bulkUpdateEmployeeStatus, { isLoading: isUpdatingEmployeeStatus }] =
    useBulkUpdateEmployeeStatusMutation();
  const { updateRequest, updateRequestLoading } = useUpdateRequestStatus({
    requestId: hrisOnboardingRequestId,
  });

  const submitOnboarding = async (
    employeeId: string,
    onSuccess: () => void,
    includesHardware?: boolean
  ) => {
    if (existingEmployeeId) {
      // If the employee already existed prior to the beginning of this flow, activate them
      await bulkUpdateEmployeeStatus({
        organizationId,
        employees: [{ id: employeeId, status: EmployeeStatus.Active }],
      });
    }

    try {
      await createOnboarding({
        organizationId,
        employeeId,
        includesHardware,
      }).unwrap();

      // If it exists, mark HRIS onboarding request as completed
      if (hrisOnboardingRequestId) {
        await updateRequest({
          newStatus: RequestStatusEnum.Completed,
        });
      }

      const [firstName, lastName] = getValues([
        'demographics.firstName',
        'demographics.lastName',
      ]);
      const fullName = getFullName(firstName, lastName);

      showSuccessToast({
        id: 'onboard-employee-success-toast',
        message: `${fullName} was onboarded successfully.`,
        stack: true,
      });

      onSuccess();
    } catch (error) {
      handleErrorState(
        `Unable to submit onboarding:  ${JSON.stringify(error)}`
      );
    }
  };

  if (isFetchingEmployee || isFetchingGroups) {
    return <CenteredSpinner />;
  }
  if (
    updateRequestLoading ||
    isCreatingOnboarding ||
    isUpdatingEmployeeStatus
  ) {
    return <SpinnerWithMessage message="Onboarding in progress..." />;
  }

  return (
    <OneColumnLayout>
      <Flex maxWidth="72rem" width="100%" margin="0 auto" vertical>
        <FormProvider {...methods}>
          {currentStep === OnboardingStep.Demographics && <Demographics />}
          {currentStep === OnboardingStep.EmployeeInformation && (
            <EmployeeInformation addEmployee={addEmployee} />
          )}
          {currentStep === OnboardingStep.Onboarding && (
            <Onboarding
              addEmployee={addEmployee}
              submitOnboarding={submitOnboarding}
              existingEmployeeId={existingEmployeeId}
              hrisOnboardingRequestId={hrisOnboardingRequestId}
            />
          )}
          {currentStep === OnboardingStep.Applications && (
            <Applications
              employeeId={employeeId}
              addToGroups={addToGroups}
              isAddingEmployee={isAddingEmployee}
              submitOnboarding={submitOnboarding}
            />
          )}
        </FormProvider>
      </Flex>
    </OneColumnLayout>
  );
};

export default NewOnboarding;

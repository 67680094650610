import { Box, Pane, Text } from '@electricjs/arc';

export type ApplicationOptionProps = {
  data: {
    icon: string;
    name: string;
  };
};

const ApplicationOption = ({ data }: ApplicationOptionProps) => {
  return (
    <Pane flexDirection="row" alignItems="center" padding="0.5rem 0.25rem">
      <Box width="2.5rem" marginRight="1rem">
        {data.icon && <img alt="app-icon" src={data.icon} width="100%" />}
      </Box>
      <Pane flexDirection="row" justifyContent="space-between">
        <Box display="flex" marginLeft="1rem">
          <Text variant="label-small">{data.name}</Text>
        </Box>
      </Pane>
    </Pane>
  );
};

export default ApplicationOption;

import { DefaultPrivacyLevel, datadogRum } from '@datadog/browser-rum';

export type InitDatadogProps = {
  applicationId: string;
  clientToken: string;
  version: string;
  env: string;
};

export const initDatadog = ({
  applicationId,
  clientToken,
  version,
  env,
}: InitDatadogProps) => {
  datadogRum.init({
    applicationId,
    clientToken,
    service: 'electrolyte-frontend',
    env,
    site: 'datadoghq.com',
    version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: DefaultPrivacyLevel.MASK,
    excludedActivityUrls: [
      // Ignore launchdarkly streaming events
      /.*clientstream.launchdarkly.com.*/,
      // Ignore aws "record" events, they're not needed to determine app load time
      /.*amazonaws.com\/.*\/record.*/,
    ],
    // Add traces to all requests to our backend
    allowedTracingUrls: [/https:\/\/(?!login).*\.electric\.ai/],
    telemetrySampleRate: 0, // Disable internal datadog telemetry
  });
};

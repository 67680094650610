import type { ReactNode } from 'react';
import styled from 'styled-components';

import {
  Text,
  Flex,
  Box,
  ColorsV2,
  getToken,
  TextMarkdown,
} from '@electricjs/arc';

import formatCurrency from '@/helpers/formatCurrency';

import noImagePicture from '@/assets/products/no-image.png';

type ProductDescriptionProps = {
  name: string;
  image?: string;
  specs?: string[] | ReactNode[];
  price: number;
  withPriceLabel?: boolean;
  shouldBreak?: boolean;
  isTextTruncated?: boolean;
  isTextBold?: boolean;
  showImage?: boolean;
  additionalDescription?: string;
};

const StyledSpecs = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const StyledFlex = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 100%;
`;

// Warning: Changing the image area sizes could break the card consistence
const ImageArea = styled(Flex)`
  height: 18.8rem;
  width: 26.2rem;
  justify-content: center;
  align-items: center;

  /* setting with the same color of the card's background (to work with light/dark modes, even on hover)  ---- TODO: will be back for Catalog 2.0, when all images will come with transparent background */
  /* background-color: ${getToken(ColorsV2.BACKGROUND_CONTENT)}; */

  /** UIBB-21: forcing white background for now (before Catalog 2.0) */
  background-color: white;
`;

// Warning: Changing image style could break the component for different image's sizes
const StyledImage = styled.img`
  height: 100%; /* Must have the same height of the ImageArea */
  max-width: 100%; /* As the height was set, the width will naturally fit, but let's set a max-width just in case */
`;

export const ProductDescription = ({
  name,
  image,
  specs,
  price,
  withPriceLabel = true,
  shouldBreak = true,
  isTextTruncated = true,
  isTextBold = true,
  showImage = true,
  additionalDescription,
}: ProductDescriptionProps) => {
  return (
    <Box>
      <StyledFlex
        wrap={shouldBreak}
        vertical={shouldBreak}
        rowGap={16}
        columnGap={32}>
        {showImage && (
          <ImageArea>
            <StyledImage src={image ?? noImagePicture} alt={name} />
          </ImageArea>
        )}
        <Flex vertical maxWidth="31rem" width="100%">
          <Box maxWidth="31rem" width="100%">
            <Text
              variant={isTextBold ? 'label-large' : undefined}
              truncateAt={isTextTruncated ? '100%' : undefined}
              title={name}>
              {name}
            </Text>
            {specs && (
              <StyledSpecs>
                {specs?.map((spec, idx) => (
                  <li key={idx}>
                    <Text truncateAt="100%">{spec}</Text>
                  </li>
                ))}
              </StyledSpecs>
            )}
            {withPriceLabel && (
              <Text variant="heading-3" pt={2} pb={4}>
                {`Price: ${formatCurrency(price)} + tax`}
              </Text>
            )}
          </Box>
        </Flex>
      </StyledFlex>
      {additionalDescription && (
        <Box padding={shouldBreak ? '1rem' : '2rem 8rem'}>
          <TextMarkdown specialLineBreak=";">
            {additionalDescription}
          </TextMarkdown>
        </Box>
      )}
    </Box>
  );
};

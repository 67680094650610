import StyledRouterLink from '@/components/StyledRouterLink';
import { Employee } from '@/types/employees';
import {
  ColorsV2,
  Flex,
  InitialsInCircle,
  Skeleton,
  Text,
  useBreakpoint,
  useThemeColors,
} from '@electricjs/arc';

export const EmployeeColumn = ({
  isLoading = false,
  employee,
}: {
  isLoading?: boolean;
  employee?: Employee;
}) => {
  const [nameColor, jobTitleColor, iconBackgroundColor] = useThemeColors([
    ColorsV2.PRIMARY,
    ColorsV2.GRAY_DARK,
    ColorsV2.GRAY_LIGHT,
  ]);

  const { xl } = useBreakpoint();

  const { firstName, lastName, id, jobTitle } = employee ?? {};

  const fullName = [firstName, lastName].filter(Boolean).join(' ');

  const columnWidth = xl ? '25rem' : '22rem';

  const unknownEmployee = (
    <Flex
      overflow="hidden"
      width={columnWidth}
      flexShrink="0"
      alignItems="center">
      <Flex mr="4">
        <InitialsInCircle text={`?`} backgroundColor={iconBackgroundColor} />
      </Flex>
      <Flex vertical overflow="hidden">
        <Text variant="label-large" color={nameColor}>
          Unknown
        </Text>
      </Flex>
    </Flex>
  );

  return !employee ? (
    unknownEmployee
  ) : (
    <Flex width={columnWidth} alignItems="center" flexShrink="0">
      <Flex mr="4" vertical>
        {isLoading ? (
          <Skeleton width="4rem" height="4rem" circle />
        ) : (
          <InitialsInCircle
            text={`${firstName} ${lastName}`}
            backgroundColor={iconBackgroundColor}
          />
        )}
      </Flex>
      {isLoading ? (
        <Skeleton width="12rem" height="4rem" />
      ) : (
        <Flex vertical overflow="hidden">
          <StyledRouterLink to={`/employees/${id}`}>
            <Text variant="label-large" color={nameColor}>
              {fullName}
            </Text>
          </StyledRouterLink>

          <Flex width="100%">
            <Text variant="body-2" title={jobTitle ?? ''} color={jobTitleColor}>
              {jobTitle}
            </Text>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

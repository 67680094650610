import { ResultKind, SmartSearchSelectOption } from '@/components/SmartSearch';

import { getElectrolyteBaseUrl } from '../urls';

const electrolyteBaseUrl = getElectrolyteBaseUrl();

export const searchableActions: SmartSearchSelectOption[] = [
  {
    kind: ResultKind.ACTION,
    title: 'Add new employee',
    url: `${electrolyteBaseUrl}/onboardings/new`,
  },
  {
    kind: ResultKind.ACTION,
    title: 'Add new group',
    url: `${electrolyteBaseUrl}/groups/new`,
  },
  {
    kind: ResultKind.ACTION,
    title: 'Add an application',
    url: `${electrolyteBaseUrl}/applications?new=true`,
  },
];

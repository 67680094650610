import { Box, ColorsV2, getToken, Text } from '@electricjs/arc';
import { DeviceRetrieveAction } from './types';
import styled from 'styled-components';

const StyledUnorderedList = styled.ul`
  font-size: 1.6rem;
  padding-left: 2.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0;
`;

const StyledListItem = styled.li`
  color: ${getToken(ColorsV2.TEXT)};
  margin-bottom: 0.5rem;
`;

type DeviceRetrievalPolicyProps = {
  deviceRetrieveAction: DeviceRetrieveAction;
};

const DeviceRetrievalPolicy = ({
  deviceRetrieveAction,
}: DeviceRetrievalPolicyProps) => {
  return (
    <Box mt="2.4rem">
      {deviceRetrieveAction === DeviceRetrieveAction.Warehouse && (
        <Box>
          <Text variant="body" mb="1rem">
            Warehousing Policy:
          </Text>
          <StyledUnorderedList>
            <StyledListItem>Standard price $120 for shipping</StyledListItem>
            <StyledListItem>
              Storage starts at $10 a month per device, billed monthly
            </StyledListItem>
            <StyledListItem>
              Price of shipping includes insurance
            </StyledListItem>
            <StyledListItem>
              Once shipped, items can not be canceled or re-routed
            </StyledListItem>
          </StyledUnorderedList>
        </Box>
      )}
      {deviceRetrieveAction === DeviceRetrieveAction.Ship && (
        <Box>
          <Text variant="body" mb="1rem">
            Shipping Policy:
          </Text>
          <StyledUnorderedList>
            <StyledListItem>
              Devices are shipped directly to the designated location
            </StyledListItem>
            <StyledListItem>
              Shipping cost includes tracking and insurance
            </StyledListItem>
            <StyledListItem>
              Once shipped, delivery can not be canceled or re-routed
            </StyledListItem>
          </StyledUnorderedList>
        </Box>
      )}
      {deviceRetrieveAction === DeviceRetrieveAction.InPerson && (
        <Box>
          <Text variant="body" mb="1rem">
            Returning in Person:
          </Text>
          <StyledUnorderedList>
            <StyledListItem>
              Employees return the device directly to your company&apos;s
              designated location
            </StyledListItem>
            <StyledListItem>
              Ensure all accessories, including charging cables, are included
              with the device
            </StyledListItem>
            <StyledListItem>
              Coordinate with your internal team to confirm the return process
            </StyledListItem>
            <StyledListItem>
              Review your company&apos;s return policy for detailed instructions
            </StyledListItem>
          </StyledUnorderedList>
        </Box>
      )}
      {deviceRetrieveAction === DeviceRetrieveAction.Keep && (
        <Box>
          <Text variant="body" mb="1rem">
            Keeping a Device past employment:
          </Text>
          <StyledUnorderedList>
            <StyledListItem>
              The employee retains the device after their employment ends
            </StyledListItem>
            <StyledListItem>
              Ensure proper security protocols are followed - we recommend
              wiping the device
            </StyledListItem>
          </StyledUnorderedList>
        </Box>
      )}
    </Box>
  );
};

export default DeviceRetrievalPolicy;

import { ReactNode, useContext } from 'react';

import {
  ColorsV2,
  Flex,
  FlexProps,
  Text,
  useBreakpoint,
} from '@electricjs/arc';

import { AssetDetailsContext } from '@/pages/Assets/AssetDetails';

import AssetFormField, { AssetFieldTypes } from './AssetFormField';
import MaybeLink from './MaybeLink';

type AssetHeaderItemProps = {
  label: string;
  name: string;
  value?: string;
  formattedValue?: string;
  url?: string;
  emptyValue?: string;
  fieldProps?: {
    fieldType?: AssetFieldTypes;
    options?: { value: string; label: string }[];
    isLoading?: boolean;
    placeholder?: string;
    isClearable?: boolean;
    isDisabled?: boolean;
  };
  readonly?: boolean;
  containerProps?: FlexProps;
  children?: ReactNode;
};

const AssetHeaderItem = ({
  label,
  name,
  value = '',
  formattedValue,
  url = '',
  emptyValue = 'N/A',
  fieldProps,
  readonly = false,
  containerProps,
  children,
}: AssetHeaderItemProps) => {
  const { md } = useBreakpoint();
  const { isEditing } = useContext(AssetDetailsContext);
  const {
    fieldType = AssetFieldTypes.TextInput,
    options,
    isLoading,
    placeholder,
    isClearable,
    isDisabled,
  } = fieldProps || {};

  return (
    <Flex
      columnGap="1.6rem"
      width={md ? '18rem' : '20rem'}
      grow={1}
      {...containerProps}>
      {isEditing ? (
        readonly ? null : (
          <AssetFormField
            name={name}
            fieldType={fieldType}
            label={label}
            options={options}
            isLoading={isLoading}
            placeholder={placeholder}
            isClearable={isClearable}
            isDisabled={isDisabled}
          />
        )
      ) : (
        <Flex flexDirection="column">
          {children ? (
            children
          ) : (
            <MaybeLink
              url={url}
              value={formattedValue ?? value}
              emptyValue={emptyValue}
            />
          )}
          <Text variant="legal" color={ColorsV2.GRAY_DARK}>
            {label}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default AssetHeaderItem;

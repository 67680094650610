import {
  Address,
  AddressGroupField,
  Button,
  Flex,
  Header,
  NumberInputField,
  OneColumnLayout,
  Text,
  TextInputField,
} from '@electricjs/arc';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { useCreateShipmentMutation } from '@/redux/slices/shipmentApiSlice';
import SiteGroupField from './SiteGroupField';
import { useGetEmployeeQuery } from '@/redux/slices/employeeApiSlice';
import { useSearchParams } from 'react-router-dom';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { getFullName } from 'common/utils/getFullName';
import { useGlobalUI } from '@/components/GlobalUIProvider';
import { useGetSiteByIdQuery } from '@/redux/slices/assetApiSlice';
import ShippingAddressDetails from './ShippingAddressDetails';
import {
  useGetRequestTypeCategoryChampionEmployee,
  RequestTypeCategoryNameEnum,
} from '@/hooks/useGetRequestTypeCategoryChampionEmployee';
import { useEffect } from 'react';
import { DeviceRetrieveAction } from '../types';
import { formatPhoneNumber } from 'common/utils/formatPhoneNumber';
import { SelectionParamKeys } from '@/types/queryParamKeys';
import ShippingInstructions from '@/pages/Shipment/ShippingInstructions';
import { ErrorPage } from '@/router/ErrorPage';
import { Asset } from '@/types/assets';

const GOOGLE_MAPS_API_KEY = window._env_?.VITE_GOOGLE_MAPS_PUB_API_KEY;

type DeviceRetrievalProps = {
  asset: Asset;
  onNext: () => void;
  onPrevious: () => void;
};

const DeviceShipping = ({
  asset,
  onNext,
  onPrevious,
}: DeviceRetrievalProps) => {
  const { showSuccessToast, showErrorToast } = useGlobalUI();

  const { control, setValue, getValues } = useFormContext();
  const { isValid } = useFormState();

  const [queryParams] = useSearchParams();
  const employeeId = queryParams.get(SelectionParamKeys.EmployeeId);
  const organizationId = useGetOrganizationId();

  const { data: employeeData, isLoading: isEmployeeLoading } =
    useGetEmployeeQuery(
      { employeeId: employeeId || '', organizationId },
      { skip: !employeeId }
    );
  const employeeName = getFullName(
    employeeData?.firstName,
    employeeData?.lastName
  );
  const employeeEmail = employeeData?.email;
  const employeePersonalEmail = employeeData?.personalEmail;
  const employeeAddress = employeeData?.address;
  const employeePhone = employeeData?.phone;

  const receiverSite = useWatch({ name: 'receiverSite', defaultValue: {} });
  const deviceRetrieveAction = useWatch({
    name: 'deviceRetrieveAction',
    control,
  });
  const isShippingToWarehouse =
    deviceRetrieveAction === DeviceRetrieveAction.Warehouse;

  const { data: site, isFetching: isLoadingSite } = useGetSiteByIdQuery(
    {
      siteId: receiverSite?.value,
      organizationId: organizationId,
    },
    { skip: !receiverSite?.value }
  );

  const receiverAddress = site?.address;

  const hardwareChampion = useGetRequestTypeCategoryChampionEmployee(
    organizationId,
    RequestTypeCategoryNameEnum.DEVICE
  );

  const [createShipment, { isLoading: isCreatingShipment }] =
    useCreateShipmentMutation();

  useEffect(() => {
    if (
      deviceRetrieveAction === DeviceRetrieveAction.Ship ||
      deviceRetrieveAction === DeviceRetrieveAction.Warehouse
    ) {
      if (employeeAddress) {
        setValue('senderAddress', employeeAddress, { shouldValidate: true });
      }
      if (employeePhone) {
        setValue('senderPhone', formatPhoneNumber(employeePhone), {
          shouldValidate: true,
        });
      }
      if (employeePersonalEmail) {
        setValue('personalEmail', employeePersonalEmail, {
          shouldValidate: true,
        });
      }
    }
  }, [
    deviceRetrieveAction,
    employeeAddress,
    employeePhone,
    employeePersonalEmail,
    setValue,
  ]);

  const handleSubmit = async () => {
    const formData = getValues();
    const serialNumber = asset?.serialNumber;
    const deviceName = asset?.name || asset.device?.hardwareModel || 'Device';
    const administratorEmail = hardwareChampion?.email;
    const senderEmail = [formData.personalEmail, employeeEmail]
      .filter(Boolean)
      .join(',');

    if (
      !serialNumber ||
      !deviceName ||
      !formData.personalEmail ||
      !receiverAddress ||
      !administratorEmail
    ) {
      console.error('Missing required data for shipment creation');

      showErrorToast({
        id: 'shipment-error-toast',
        message:
          'Something went wrong. Please contact product-support@electric.ai.',
      });
      return;
    }

    const shipment = {
      senderName: employeeName,
      senderPhone: formData.senderPhone,
      senderAddress: formData.senderAddress,
      deviceName,
      serialNumber,
      senderEmail,
      receiverName: receiverSite?.name,
      receiverPhone: formData.receiverPhone,
      receiverAddress,
      administratorEmail,
    };

    try {
      await createShipment({
        organizationId,
        shipment,
      }).unwrap();

      showSuccessToast({
        id: 'shipment-success-toast',
        message: () => (
          <Text>
            Your QR code request for <strong>{deviceName}</strong> has been
            submitted. A QR code will be sent to the recipient.
          </Text>
        ),
        stack: true,
      });
      onNext();
    } catch (error) {
      console.error('Error while creating shipment: ', error);
      showErrorToast({
        id: 'shipment-error-toast',
        message: 'Something went wrong. Please try again.',
      });
    }
  };

  // Display the error page if there is no employee id (provided as a query param), if there is no employee data, or if there is no warehouse site after fetching the global sites
  if (!employeeId || (!isEmployeeLoading && !employeeData)) {
    return <ErrorPage />;
  }

  return (
    <OneColumnLayout>
      <Header
        title="Where should the device be returned?"
        subtitle="Make it easy for your employee to return their device by providing them with a QR code for convenient shipping."
      />
      <ShippingInstructions
        listProps={{
          ml: '2rem',
          mb: '2.4rem',
        }}
        bannerProps={{
          dismissible: true,
          isFullWidth: true,
        }}
      />
      <Flex vertical maxWidth="72rem">
        <Text variant="heading-3" mt="3.4rem" mb="2rem">
          Shipping from
        </Text>
        <ShippingAddressDetails
          id="sender-address-details"
          title={employeeName}
          address={employeeAddress}
          phone={formatPhoneNumber(employeePhone)}
          isLoading={isEmployeeLoading}>
          <AddressGroupField
            name="senderAddress"
            control={control}
            onChange={(address: Address) => {
              setValue('senderAddress', address, {
                shouldValidate: true,
              });
            }}
            googleMapsApiKey={GOOGLE_MAPS_API_KEY}
            required
            columnGap="2rem"
          />
          <NumberInputField
            id="sender-phone-input"
            name="senderPhone"
            control={control}
            label="Phone"
            format="(###) ###-####"
            required
          />
        </ShippingAddressDetails>
        <TextInputField
          id="personal-email-input"
          name="personalEmail"
          control={control}
          label="Personal Email"
          placeholder="Enter personal email"
          width="100%"
          required
        />
        <Text variant="heading-3" mt="3.4rem" mb="2rem">
          Shipping to
        </Text>
        <ShippingAddressDetails
          id="receiver-address-details"
          title={receiverSite?.name}
          address={receiverAddress}
          phone={receiverSite?.phone}
          isLoading={isLoadingSite}
          disabled={isShippingToWarehouse}
          showPhoneNumber={!isShippingToWarehouse}>
          <SiteGroupField name="receiverSite" required shouldShowAddress />
          <NumberInputField
            id="receiver-phone-input"
            name="receiverPhone"
            control={control}
            label="Phone"
            format="(###) ###-####"
            required
          />
        </ShippingAddressDetails>
      </Flex>
      <Flex mt="9rem" hAlignContent="between" gap="3.4rem" width="100%">
        <Button
          id="devices-shipping-previous-button"
          variant="outline"
          onClick={onPrevious}
          disabled={isCreatingShipment}>
          Previous
        </Button>
        <Button
          id="devices-shipping-next-button"
          onClick={handleSubmit}
          loading={isCreatingShipment}
          disabled={!isValid}>
          Next
        </Button>
      </Flex>
    </OneColumnLayout>
  );
};

export default DeviceShipping;

import styled from 'styled-components';
import { AssetFileWithURL } from '@/types/assets';
import {
  ModalV2,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalFooterActions,
  Text,
  Button,
  Flex,
  Spacer,
} from '@electricjs/arc';

type ImageDeleteModalProps = {
  image: AssetFileWithURL;
  onClose: () => void;
  onDelete: () => void;
};

const Image = styled.img`
  max-height: 10vh;
  max-width: 10vw;
`;

const ImageDeleteModal = ({
  image,
  onClose,
  onDelete,
}: ImageDeleteModalProps) => {
  return (
    <ModalV2
      visible
      hide={onClose}
      ariaLabelledby="view-image-modal-header-text"
      width="auto"
      height="auto"
      maxWidth="80vw">
      <ModalHeader showCloseButton={true} width="auto">
        <Text id="view-image-modal-header-text" variant="heading-2">
          Delete Image
        </Text>
      </ModalHeader>
      <ModalBody>
        <Flex flexDirection="column" alignItems="center">
          <Text>Are you sure you want to delete this image?</Text>
          <Spacer space={16} />
          <Image src={image.url} alt="Asset" />
        </Flex>
      </ModalBody>
      <ModalFooter>
        <ModalFooterActions>
          <Button id="close-button" onClick={onClose} variant="text">
            Cancel
          </Button>
          <Button
            id="close-button"
            onClick={onDelete}
            variant="fill"
            intent="danger">
            Delete
          </Button>
        </ModalFooterActions>
      </ModalFooter>
    </ModalV2>
  );
};

export default ImageDeleteModal;

import { OnboardingFormData } from '@/components/Onboardings/types';

export const getEmployeeData = (formData: OnboardingFormData) => {
  const { demographics, employeeInformation } = formData;
  return {
    firstName: demographics.firstName,
    lastName: demographics.lastName,
    personalEmail: demographics.personalEmail,
    address: demographics.address,
    phone: demographics.phone || undefined,
    startDate: employeeInformation.startDate?.toISOString(),
    jobTitle: employeeInformation.jobTitle,
    email: employeeInformation.email,
  };
};

import styled from 'styled-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate } from 'react-router';

import {
  ColorsV2,
  Flex,
  Text,
  useThemeColors,
  Card,
  LinearProgress,
  Box,
  KebabMenuOption,
  Skeleton,
  IconInCircle,
} from '@electricjs/arc';

import { CardFooter } from './CardFooter';
import { EmployeeWithReminderReason } from '@/pages/Hardware/DeviceOverview/types';

const StyledCard = styled(Card)`
  padding-bottom: 1rem;
`;

// Create a styled span for showing the value of enrolled devices
const EnrolledDevicesValue = styled.span`
  font-weight: bold;
  font-size: 1.6rem;
`;

type DeviceEnrollmentStatusType = {
  employeesThatNeedAReminder: EmployeeWithReminderReason[];
  unmonitoredDevicesCount: number;
  isFetchingEmployees: boolean;
  isFetchingDevices: boolean;
  monitoredDevicesCount: number;
};

const DeviceEnrollmentStatusNoMdm = ({
  employeesThatNeedAReminder,
  unmonitoredDevicesCount,
  isFetchingEmployees,
  isFetchingDevices,
  monitoredDevicesCount,
}: DeviceEnrollmentStatusType) => {
  const { electrolyteDeviceManagement } = useFlags();

  const [iconBackgroundColor] = useThemeColors([ColorsV2.GRAY_LIGHTER]);

  const navigate = useNavigate();

  const viewDevicesLink = electrolyteDeviceManagement
    ? '/device-management'
    : '/assets?page=0&searchTerm=laptop&orderBy=name&sortOrder=asc';

  const kebabMenuItems: KebabMenuOption[] = [
    {
      label: 'What is the electric app?',
      intent: 'neutral',
      onClick: () => {
        window
          .open(
            'https://support.electric.ai/electricithub/electric-it-hub-desktop-app-employee-hub',
            '_blank'
          )
          ?.focus();
      },
    },
    {
      label: 'View devices',
      intent: 'neutral',
      onClick: () => {
        navigate(viewDevicesLink);
      },
    },
  ];

  const linearProgressValue =
    (monitoredDevicesCount /
      (monitoredDevicesCount + unmonitoredDevicesCount)) *
    100;

  return (
    <StyledCard
      id="mdm-enrollment-status-card"
      elevated
      flexDirection="column"
      width="100%"
      height="24rem"
      headerDivider
      footerDivider
      kebabMenuItems={kebabMenuItems}
      footer={
        <CardFooter
          mdmEnabled={false}
          employeesThatNeedAReminder={employeesThatNeedAReminder}
          isFetchingEmployees={isFetchingEmployees}
        />
      }
      title={
        <Flex justifyContent="space-between" width="100%">
          <Flex gap="1rem" alignItems="center">
            <IconInCircle
              size="3.6rem"
              icon="users-gear"
              iconSize="medium"
              intent="neutral"
              background={iconBackgroundColor}
            />
            <Text variant="heading-3">Electric app status</Text>
          </Flex>
        </Flex>
      }>
      <Flex py={4} width="100%" vertical>
        <Text variant="body-2" width="100%" mb={3}>
          <EnrolledDevicesValue>{monitoredDevicesCount}</EnrolledDevicesValue>
          {`/${monitoredDevicesCount + unmonitoredDevicesCount} assigned devices are monitored by Electric.`}
        </Text>
        <Flex vertical width="100%">
          <Flex alignItems="center" justifyContent="center" width="100%" pb={2}>
            {isFetchingEmployees || isFetchingDevices ? (
              <Flex height="100%" vAlignContent="center">
                <Skeleton width="5rem" height="3rem" />
              </Flex>
            ) : (
              <LinearProgress
                width="calc(100% + 4rem)"
                height="1.5rem"
                showGap={!!linearProgressValue}
                marginRight="-1rem"
                remainingIntent="danger"
                intent="primary"
                value={linearProgressValue}
              />
            )}
          </Flex>
          <Flex width="100%" justifyContent="space-between" pb={2}>
            <Flex alignItems="center">
              <Box
                mr={2}
                height="8px"
                width="8px"
                borderRadius="50%"
                backgroundColor={ColorsV2.PRIMARY}></Box>
              <Text variant="legal">{`${monitoredDevicesCount} Monitored`}</Text>
            </Flex>
            <Flex alignItems="center">
              <Box
                mr={2}
                height="8px"
                width="8px"
                borderRadius="50%"
                backgroundColor={ColorsV2.ERROR}></Box>
              <Text variant="legal">{`${unmonitoredDevicesCount} Unmonitored`}</Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </StyledCard>
  );
};
export default DeviceEnrollmentStatusNoMdm;

import { Flex, Text, Link } from '@electricjs/arc';

import { emptyValue } from '@/constants/common';

type AssetBasicInfoProps = {
  name: string;
  customId: string;
  serialNumber?: string;
  siteName?: string;
  onLinkClick: () => void;
};

export const AssetBasicInfo = ({
  name,
  customId,
  serialNumber,
  siteName,
  onLinkClick,
}: AssetBasicInfoProps) => {
  return (
    <Flex vertical>
      <Link
        id={`asset-details-link-${name}-${customId}`}
        href=""
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          onLinkClick();
        }}>
        {name}
      </Link>
      <Text>Serial: {serialNumber ? serialNumber : emptyValue}</Text>
      <Text>Site: {siteName ? siteName : emptyValue}</Text>
    </Flex>
  );
};

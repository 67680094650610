import { useGlobalUI } from '@/components/GlobalUIProvider';
import { ColorsV2, getToken, IconButton, useBreakpoint } from '@electricjs/arc';
import {
  MouseEventHandler,
  ReactElement,
  useLayoutEffect,
  ReactNode,
} from 'react';
import styled from 'styled-components';
import { ifProp, prop } from 'styled-tools';

const StyledNav = styled.nav<{
  $sm: boolean;
  $md: boolean;
  $mobileNavWidth?: string;
  $mobilePositionTop?: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: ${ifProp('$md', '100%', 'calc(100vh - 6.5rem)')};
  position: ${ifProp('$sm', 'inherit', 'absolute')};
  top: ${ifProp(
    '$sm',
    'unset',
    ifProp('$mobilePositionTop', prop('$mobilePositionTop'), '6.5rem')
  )};
  width: ${ifProp(
    '$sm',
    '23.2rem',
    /* 3.8rem is the calculated value for the default padding and margin applied on the side nav */
    ifProp('$mobileNavWidth', prop('$mobileNavWidth'), 'calc(100vw - 3.8rem)')
  )};
  min-width: 23.2rem;
  background-color: ${getToken(ColorsV2.BACKGROUND_CONTENT)};
  box-shadow: 0 0 8px ${getToken(ColorsV2.GRAY_LIGHT)};
  overflow-x: hidden;
  border-left-style: solid;
  border-left-width: 6px;
  border-image-slice: 1;
  border-image-source: ${getToken(ColorsV2.GRADIENT_NAV)};
  padding: 0 1.6rem;
  /* z-index puts this above the top nav */
  z-index: 3;

  /* Electric Logo for desktop/tablet, first nav item for mobile */
  & > :first-child {
    margin: ${ifProp('$md', '2rem 0 2rem 0.4rem', '3rem 0 2rem')};
  }

  /* First nav item for desktop/tablet gets more top margin */
  & > :nth-child(2) {
    margin-top: ${ifProp('$md', '2rem')};
  }

  /* Arrow button to expand nav when the screen is "tablet" size */
  .sidenav-turbine__arrow-button {
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    margin: auto 0 2rem auto;
  }

  /* If the side nav has the collapsed class, apply these styles */
  ${({ className }) =>
    className &&
    className.includes('sidenav-turbine--collapsed') &&
    `
      width: 4.5rem;
      min-width: 4.5rem;
      padding: 0 1.2rem;

      /* All children nav item divs */
      & > a, div.collapsible-subnav {
        padding: 0;
      }

      & > .section-heading {
        display: none;
      }

      a.nav-item,
      div.collapsible-subnav > .collapsible-subnav__section {
        div[data-testid='arc-icon'] {
          margin-left: 1.3rem;
        }

        // Hides the text and chip when in tablet view
        [data-arc="text"], [data-arc="chip"], span.collapsible-subnav__text {
          display: none;
        }
      }

      div.collapsible-subnav {
        &__section {
          padding-top: 0.8rem;
        }

        &__items {
          display: none;
          transition: none;
        }
      }

      .sidenav-turbine__arrow-button {
        margin-right: auto;
      }

      /* Electric logo clipping */
      .nav-logo {
        clip-path: inset(0 75px 0 0);
        margin-left: 0.8rem;
      }
  `}
`;

type SideNavComponentProps = {
  children: ReactNode;
  mobileNavWidth?: string;
  mobilePositionTop?: string;
};

const SideNavComponent = ({
  children,
  mobileNavWidth,
  mobilePositionTop,
}: SideNavComponentProps): ReactElement => {
  const { sm, md, lg } = useBreakpoint();
  const { openSideNav, closeSideNav, isSideNavOpen } = useGlobalUI();

  // Collapse the side nav on render if the screen is medium and below
  const isCollapsedOnRender = Boolean(md) && !lg;
  useLayoutEffect(() => {
    isCollapsedOnRender ? closeSideNav() : openSideNav();
  }, [isCollapsedOnRender, closeSideNav, openSideNav]);

  const toggleSideNav = () => (isSideNavOpen ? closeSideNav() : openSideNav());

  const shouldTriggerToggle = (target: EventTarget | null) => {
    if (target instanceof Element) {
      return (
        (target && target.closest('.collapsible-subnav') && !isSideNavOpen) ||
        (target && target.tagName === 'NAV')
      );
    }
    return false;
  };

  const triggerToggleIfNeeded: MouseEventHandler<HTMLElement> = ({ target }) =>
    shouldTriggerToggle(target) && toggleSideNav();

  const arrowIcon = !isSideNavOpen ? 'chevron-right' : 'chevron-left';

  return (
    <StyledNav
      data-testid="sidenav"
      onClick={triggerToggleIfNeeded}
      aria-label="Main"
      $sm={Boolean(sm)}
      $mobileNavWidth={mobileNavWidth}
      $mobilePositionTop={mobilePositionTop}
      $md={Boolean(md)}
      className={`sidenav-turbine${
        !isSideNavOpen ? ' sidenav-turbine--collapsed' : ''
      }`}>
      {children}
      <IconButton
        id="side-nav-arrow-button"
        data-testid={'side-nav-arrow-button'}
        className="sidenav-turbine__arrow-button"
        onClick={toggleSideNav}
        icon={arrowIcon}
        intent="neutral"
        size="small"
      />
    </StyledNav>
  );
};

export default SideNavComponent;

import { DeviceIssueType } from '@/types/devices';
import { DescriptionColumn } from '../ColumnsAndContainers/DescriptionColumn';
import { ReactNode } from 'react';

type DeviceIssueReminderMapType = Partial<{
  [key in DeviceIssueType]: (
    serialNumber?: string,
    showIcon?: boolean
  ) => ReactNode;
}>;

export const DeviceIssueReminderMap: DeviceIssueReminderMapType = {
  [DeviceIssueType.Firewall]: (serialNumber, showIcon) => (
    <DescriptionColumn
      iconType="COMPLETED_BY_ELECTRIC"
      title="Electric has sent a reminder to enable firewall"
      serialNumber={serialNumber}
      detail="Electric will help remediate this issue."
      showIcon={showIcon}
    />
  ),
  [DeviceIssueType.Gatekeeper]: (serialNumber, showIcon) => (
    <DescriptionColumn
      iconType="COMPLETED_BY_ELECTRIC"
      title="Electric has sent a reminder to enable Gatekeeper"
      serialNumber={serialNumber}
      detail="Electric will help remediate this issue."
      showIcon={showIcon}
    />
  ),
  [DeviceIssueType.OS]: (serialNumber, showIcon) => (
    <DescriptionColumn
      iconType="COMPLETED_BY_ELECTRIC"
      title="Electric has sent a reminder to update the operating system"
      serialNumber={serialNumber}
      detail="Electric will help remediate this issue."
      showIcon={showIcon}
    />
  ),
  [DeviceIssueType.Encryption]: (serialNumber, showIcon) => (
    <DescriptionColumn
      iconType="COMPLETED_BY_ELECTRIC"
      title="Electric has sent a reminder to enable device encryption"
      serialNumber={serialNumber}
      detail="Electric will help remediate this issue."
      showIcon={showIcon}
    />
  ),
  [DeviceIssueType.Storage]: (serialNumber, showIcon) => (
    <DescriptionColumn
      iconType="COMPLETED_BY_ELECTRIC"
      title="Electric has sent a reminder to free up disk space"
      serialNumber={serialNumber}
      detail="Electric will help remediate this issue."
      showIcon={showIcon}
    />
  ),
  [DeviceIssueType.OsqueryNotReporting]: (serialNumber, showIcon) => (
    <DescriptionColumn
      iconType="COMPLETED_BY_ELECTRIC"
      title="Electric has sent a reminder to reboot device"
      serialNumber={serialNumber}
      detail="The employee will need to restart the device to reconnect."
      showIcon={showIcon}
    />
  ),
  [DeviceIssueType.MdmNotReporting]: (serialNumber, showIcon) => (
    <DescriptionColumn
      iconType="COMPLETED_BY_ELECTRIC"
      title="Electric has sent a reminder to reboot device"
      serialNumber={serialNumber}
      detail="The employee will need to restart the device to reconnect MDM."
      showIcon={showIcon}
    />
  ),
  [DeviceIssueType.DeviceLock]: (serialNumber, showIcon) => (
    <DescriptionColumn
      iconType="COMPLETED_BY_ELECTRIC"
      title="Electric has sent a reminder that the device is locked"
      serialNumber={serialNumber}
      detail="If the device is found, you can unlock it directly from the employee's profile to restore access."
      showIcon={showIcon}
    />
  ),
} as const;

import { Card, EmptyState, Flex, Text } from '@electricjs/arc';
import { Store } from '@/types/storefront';

type ItemListEmptyStateProps = {
  store: Store;
};

export const ItemListEmptyState = ({ store }: ItemListEmptyStateProps) => {
  if (store === Store.Catalog) {
    return (
      <Card id="item-empty-state-card" elevated width="100%">
        <EmptyState
          title="No results found"
          subTitle="We currently don't have any products with these specifications. Try searching for a different brand, or change the specifications."
          icon="laptop"
        />
      </Card>
    );
  } else if (store === Store.Asset) {
    return (
      <Card id="item-empty-state-card" elevated width="100%">
        <EmptyState
          title="No available assets of this type"
          subTitle={
            <Flex vertical alignItems="center">
              <Text>
                It looks like there are no assets of this type currently
                available.
              </Text>
              <Text>
                Need new equipment? Easily purchase them through our Hardware
                Store.
              </Text>
            </Flex>
          }
          icon="laptop"
        />
      </Card>
    );
  } else {
    return null;
  }
};

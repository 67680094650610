import { sortBy } from 'lodash';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  Box,
  Button,
  ColorsV2,
  Field,
  FieldLabel,
  Flex,
  OneColumnLayout,
  Select,
  Spinner,
  getToken,
} from '@electricjs/arc';

import { useGlobalUI } from '@/components/GlobalUIProvider';
import { useTicketFlow } from '@/components/TicketFlowContext';
import { useGetLoggedUser } from '@/hooks/useGetLoggedUser';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import {
  RequestTypeCategoryNameEnum,
  useGetRequestTypeCategoryChampionEmployee,
} from '@/hooks/useGetRequestTypeCategoryChampionEmployee';
import { useGetRequestTypeCategoriesQuery } from '@/redux/slices/organizationApiSlice';
import {
  useCreateRequestMutation,
  useGetRequestTypesByRequestTypeCategoryIdQuery,
  useLazyGetRequestTypeSettingsByRequestTypeIdQuery,
} from '@/redux/slices/requestApiSlice';
import {
  RequestCreationArgs,
  RequestStatus,
  RequestType,
  RequestTypeSetting,
} from '@/types/requests';

import RequestSupportFormHeader from './components/RequestSupportForm/RequestSupportFormHeader';
import RequestSupportFormInput from './components/RequestSupportForm/RequestSupportFormInput';
import { getCategoryIcon } from './utils/getCategoryIcon';

const BorderBox = styled(Box)`
  width: 100%;
  max-width: 84rem;
  border: 1px solid ${getToken(ColorsV2.GRAY_DARK)};
  border-radius: 0.75rem;
  padding: 4rem 3.5rem;
  margin: 0 auto;

  @media (max-width: 820px) {
    width: 100%;
    border: none;
    padding-top: 0;
  }
`;

export default function RequestSupportPage() {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();

  const loggedUser = useGetLoggedUser();
  const userId = loggedUser?.id;

  if (!userId) {
    throw new Error('Could not find the Logged User');
  }

  const organizationId = useGetOrganizationId();

  const firstName = loggedUser.employeeFirstName;
  const lastName = loggedUser.employeeLastName;
  const email = loggedUser.email;
  const employeeId = loggedUser.employeeId;

  const { setTicketCreated } = useTicketFlow();
  const { showToast } = useGlobalUI();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { isDirty, isValid },
  } = useForm();

  const issue = watch('issue');

  const { data: requestTypeCategories } = useGetRequestTypeCategoriesQuery({
    organizationId: organizationId,
  });

  const [createRequest, { isLoading: isSubmitting }] =
    useCreateRequestMutation();

  const getCategoryInfo = (name: string) => {
    return requestTypeCategories?.find(item => item.name === name);
  };

  const category =
    queryParams.get('category') || RequestTypeCategoryNameEnum.SOMETHING_ELSE;

  const requestTypeCategoryData = getCategoryInfo(category);
  const requestTypeCategoryId = requestTypeCategoryData?.id ?? '';

  const champion = useGetRequestTypeCategoryChampionEmployee(
    organizationId,
    category
  );

  const applicationValue = watch('application');

  // TODO: Update `requestChampionMessage` logic to handle multiple champions once the ability to assign multiple champions is finished
  const shouldSendRequest =
    (category !== 'Applications' &&
      !!requestTypeCategoryData &&
      !requestTypeCategoryData?.serviceProviderId) ||
    applicationValue?.name === 'Application not listed';

  const requestChampionMessage =
    shouldSendRequest && champion
      ? `This request will be sent to ${champion.email ?? ''}.`
      : undefined;

  const { data: requestTypes, isFetching: isFetchingRequestTypes } =
    useGetRequestTypesByRequestTypeCategoryIdQuery(requestTypeCategoryId, {
      skip: !requestTypeCategoryId,
    });

  const [getElectrolyteRequestTypeSettings, { data: requestTypeSettings }] =
    useLazyGetRequestTypeSettingsByRequestTypeIdQuery();

  const currentEmployee = useMemo(
    () => ({
      email,
      firstName,
      lastName,
      userId,
      id: employeeId,
    }),
    [email, firstName, lastName, userId, employeeId]
  );

  useEffect(() => {
    // Set default values
    if (requestTypes) {
      reset({
        issue: requestTypes[0],
        on_behalf_of: currentEmployee,
      });
    }
  }, [currentEmployee, requestTypes, reset]);

  useEffect(() => {
    if (issue?.id) {
      getElectrolyteRequestTypeSettings(issue?.id);
    }
  }, [issue, getElectrolyteRequestTypeSettings]);

  const [checkbox, setCheckbox] = useState<{ name: string; values: string[] }>({
    name: '',
    values: [],
  });

  useEffect(() => {
    if (checkbox.name !== '') {
      setValue(checkbox.name, checkbox.values);
    }
  }, [checkbox, setValue]);

  const handleCheckbox = (event: FormEvent, name: string) => {
    if (!event?.target) return;

    const eventTarget = event.target as HTMLInputElement;

    setCheckbox(
      checkbox?.values.includes(eventTarget.value)
        ? {
            name,
            values: checkbox?.values.filter(
              value => value !== eventTarget.value
            ),
          }
        : {
            name,
            values: [...checkbox.values, eventTarget.value],
          }
    );
  };

  const sortedFields = useMemo<RequestTypeSetting[]>(
    () => sortBy(requestTypeSettings, 'orderValue'),
    [requestTypeSettings]
  );

  const successCallback = () => {
    showToast({
      id: 'request-success-toast',
      intent: 'success',
      title: 'Success',
      message: `Your request has been sent. You'll receive any updates to your email address at ${email}.`,
    });
    setTicketCreated(true);
    navigate('/support-center');
  };

  const errorCallback = () => {
    showToast({
      id: 'request-error-toast',
      intent: 'error',
      title: 'Error',
      message:
        'Sorry, we could not submit your ticket at this time. Please, try again.',
    });
  };

  // The form fields come from the DB, so we can't statically type them
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (data: any) => {
    const payload: RequestCreationArgs = {
      organizationId,
      requestTypeId: data.issue.id,
      requesterUserId: userId,
      status: RequestStatus.InProgress,
      data: data,
      onBehalfOf: data.on_behalf_of.id,
    };

    createRequest(payload)
      .unwrap()
      .then(() => successCallback())
      .catch(() => errorCallback());
  };

  // TODO: Add OneColumnLayout
  return (
    <OneColumnLayout>
      <BorderBox>
        <form data-testid="form" onSubmit={handleSubmit(onSubmit)}>
          <Flex vertical basis={900}>
            <RequestSupportFormHeader
              icon={getCategoryIcon(category)}
              title={category}
              subtitle={requestTypeCategoryData?.description ?? ''}
              subheading={requestChampionMessage}
            />

            {isFetchingRequestTypes ? (
              <Flex width="100%" justifyContent="center" mt={6}>
                <Spinner />
              </Flex>
            ) : (
              <>
                <Field>
                  <FieldLabel id="issue-label">
                    With what do you need assistance?
                  </FieldLabel>
                  <Controller
                    name="issue"
                    control={control}
                    render={({ field }) => (
                      <Select
                        id="issue-select"
                        {...field}
                        options={requestTypes}
                        aria-labelledby="issue"
                        isLoading={isFetchingRequestTypes}
                        loadingText="Loading request types..."
                        getOptionLabel={(requestType: RequestType) =>
                          requestType.name
                        }
                        getOptionValue={(requestType: RequestType) =>
                          requestType.name
                        }
                        isSearchable
                        isClearable
                      />
                    )}
                  />
                </Field>
                {sortedFields?.map((item, key) => {
                  let showField = true;
                  if (item.parentRequestTypeSettingId) {
                    const parentSettingName =
                      requestTypeSettings?.find(
                        rts => rts.id === item.parentRequestTypeSettingId
                      )?.name || '';
                    const currentParentValue = watch(parentSettingName);
                    showField = item.parentSettingValue === currentParentValue;
                  }

                  return (
                    showField && (
                      <Field key={key}>
                        <FieldLabel id={item.name}>
                          {item.displayName}
                        </FieldLabel>
                        <Controller
                          name={item.name}
                          control={control}
                          defaultValue={item.defaultValue}
                          rules={{ required: item.required }}
                          render={({ field }) => (
                            <RequestSupportFormInput
                              {...field}
                              type={item.type}
                              name={item.name}
                              options={JSON.parse(String(item.options))}
                              values={checkbox.values}
                              handleCheckbox={(e: FormEvent) =>
                                handleCheckbox(e, item.name)
                              }
                            />
                          )}
                        />
                      </Field>
                    )
                  );
                })}
                <Box alignSelf="center">
                  <Button
                    id="request-form-submit"
                    type="submit"
                    disabled={!isDirty || !isValid}
                    loading={isSubmitting}>
                    Submit Request
                  </Button>
                </Box>
              </>
            )}
          </Flex>
        </form>
      </BorderBox>
    </OneColumnLayout>
  );
}

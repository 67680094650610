import { EntitlementSlugEnum } from '@electricjs/core_entity-client';
import CenteredSpinner from 'common/CenteredSpinner';
import styled from 'styled-components';

import { Box, Header, OneColumnLayout } from '@electricjs/arc';

import { EmptyState } from '@/components/DeviceOverview/EmptyState';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useOrganizationHasEntitlement } from '@/hooks/useOrganizationHasEntitlement';
import { useGetJumpcloudProvisionStatusQuery } from '@/redux/slices/mdmManagementApiSlice';
import { DeviceIssueType } from '@/types/devices';

import DeviceEnrollmentStatusWithMdm from '../../../components/DeviceOverview/DeviceEnrollmentStatus/MdmCard';
import DeviceEnrollmentStatusNoMdm from '../../../components/DeviceOverview/DeviceEnrollmentStatus/NoMdmCard';
import DeviceIssues from '../../../components/DeviceOverview/DeviceIssues/DeviceIssues';
import { DeviceHealthMonitor } from '@/components/DeviceOverview/DeviceHealthMonitor/DeviceHealthMonitor';
import {
  getEmployeesThatNeedEnrollmentReminder,
  getEmployeesWithoutDevices,
  getEnrolledDevices,
  getUnenrolledDevices,
} from './DeviceOverviewHelpers';
import DeviceAssignmentStatusCard from '@/components/DeviceOverview/DeviceAssignmentStatusCard';
import { isDeviceIssueType } from '@/components/DeviceOverview/DeviceHealthMonitor/types';
import DeviceOrderUpdates from '@/components/DeviceOverview/DeviceOrderUpdates';
import MDMCertificateExpirationBanner from '@/components/MDMEnrollment/MDMCertificateExpirationBanner';
import { useGetEmployeesWithAssets } from '@/hooks/useGetEmployeesWithAssets';
import { Asset } from '@/types/assets';
import { EmployeeStatus } from '@/types/employees';

export const GridContainer = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-flow: column;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
  }
`;

const DeviceOverview = () => {
  const organizationId = useGetOrganizationId();

  const {
    employeesWithAssets,
    isFetching: isFetchingEmployeesWithAssets,
    assets,
  } = useGetEmployeesWithAssets({
    devicesOnly: true,
    availableAssetsOnly: true,
    listEmployeesQueryProps: {
      status: [EmployeeStatus.Active, EmployeeStatus.Inactive],
    },
  });

  const { data: provisionStatusData } =
    useGetJumpcloudProvisionStatusQuery(organizationId);
  const isMDMSetUp = !!provisionStatusData;

  const hasMdmEntitlement = useOrganizationHasEntitlement(
    EntitlementSlugEnum.JumpcloudMdm
  );

  const totalEmployees = employeesWithAssets?.length;

  const deviceIssueCounts: Record<string, number> =
    assets?.reduce((acc: Record<string, number>, currAsset: Asset) => {
      const currIssues = Object.keys(
        currAsset.device?.reportingData?.issues || {}
      ) as DeviceIssueType[];

      currIssues.forEach(issueType => {
        // Only handle supported types
        if (isDeviceIssueType(issueType)) {
          acc[issueType] = (acc[issueType] || 0) + 1;
        }
      });
      return acc;
    }, {}) ?? {};

  const deviceIssuesByTypeMaps: Record<
    string,
    Record<string, Asset[]>
  > = assets?.reduce(
    (acc: Record<string, Record<string, Asset[]>>, currAsset: Asset) => {
      const currIssues = Object.keys(
        currAsset.device?.reportingData?.issues || {}
      ) as DeviceIssueType[];

      currIssues.forEach(issueType => {
        // Only handle supported types
        if (isDeviceIssueType(issueType)) {
          const existingIssues = acc[issueType] || {};
          const existingIssueForEmployee =
            existingIssues[currAsset.assignedTo || 'unassigned'] || [];
          acc[issueType] = {
            ...existingIssues,
            [currAsset.assignedTo || 'unassigned']: [
              ...existingIssueForEmployee,
              currAsset,
            ],
          };
        }
      });
      return acc;
    },
    {}
  ) || {};

  const employeesWithoutDevices = getEmployeesWithoutDevices(
    employeesWithAssets || []
  );

  const employeesWithoutDevicesCount = employeesWithoutDevices?.length;
  const unenrolledDevices =
    !isFetchingEmployeesWithAssets && employeesWithAssets
      ? getUnenrolledDevices({
          employeesWithAssets: employeesWithAssets,
          hasMdmEntitlement,
        })
      : [];

  const enrolledDevices =
    !isFetchingEmployeesWithAssets && employeesWithAssets
      ? getEnrolledDevices({
          employeesWithAssets,
          hasMdmEntitlement,
        })
      : [];

  const employeeReminderReasons =
    !isFetchingEmployeesWithAssets && employeesWithAssets
      ? getEmployeesThatNeedEnrollmentReminder({
          employees: employeesWithAssets,
          hasMdmEntitlement,
        })
      : [];

  const hasEnrolledDevice = employeesWithoutDevicesCount !== totalEmployees;

  const tabContent = [
    {
      id: 0,
      icon: 'firewall',
      property: DeviceIssueType.Firewall,
      label: 'Firewall',
      numErrors: deviceIssueCounts[DeviceIssueType.Firewall],
    },
    {
      id: 1,
      icon: 'shield-virus',
      property: DeviceIssueType.Gatekeeper,
      label: 'Gatekeeper',
      numErrors: deviceIssueCounts[DeviceIssueType.Gatekeeper],
    },
    {
      id: 2,
      icon: 'cog',
      property: DeviceIssueType.OS,
      label: 'OS update',
      numErrors: deviceIssueCounts[DeviceIssueType.OS],
    },
    {
      id: 3,
      icon: 'key',
      property: DeviceIssueType.Encryption,
      label: 'Encryption',
      numErrors: deviceIssueCounts[DeviceIssueType.Encryption],
    },
    {
      id: 4,
      icon: 'storage',
      property: DeviceIssueType.Storage,
      label: 'Storage',
      numErrors: deviceIssueCounts[DeviceIssueType.Storage],
    },
    {
      id: 5,
      icon: 'ram',
      property: DeviceIssueType.RAM,
      label: 'RAM',
      numErrors: deviceIssueCounts[DeviceIssueType.RAM],
    },
    /* TODO HY-218 We're temporarily disabling Battery health while we improve the logic behind it */
    // {
    //   id: 7,
    //   icon: 'battery-bolt',
    //   property: DeviceIssueType.BatteryHealth,
    //   label: 'Battery',
    //   numErrors: deviceIssuesByType[DeviceIssueType.BatteryHealth]?.length || 0,
    // },
  ];
  tabContent.sort((a, b) =>
    a.numErrors < b.numErrors || a.numErrors === b.numErrors ? 1 : -1
  );

  return (
    <OneColumnLayout>
      <MDMCertificateExpirationBanner />
      <Header
        title="Device overview"
        subtitle="Device status and health at a glance."
      />
      {!hasEnrolledDevice ? (
        <EmptyState />
      ) : (
        <>
          <GridContainer>
            {hasMdmEntitlement ? (
              <DeviceEnrollmentStatusWithMdm
                unprotectedDevicesCount={unenrolledDevices?.length || 0}
                isFetchingEmployees={isFetchingEmployeesWithAssets}
                isFetchingDevices={isFetchingEmployeesWithAssets}
                employeesThatNeedAReminder={employeeReminderReasons || []}
                secureDevicesCount={enrolledDevices?.length || 0}
              />
            ) : (
              <DeviceEnrollmentStatusNoMdm
                unmonitoredDevicesCount={unenrolledDevices.length || 0}
                employeesThatNeedAReminder={employeeReminderReasons}
                isFetchingEmployees={isFetchingEmployeesWithAssets}
                isFetchingDevices={isFetchingEmployeesWithAssets}
                monitoredDevicesCount={enrolledDevices?.length || 0}
              />
            )}
            <DeviceAssignmentStatusCard
              employeesWithoutDevices={employeesWithoutDevices.filter(
                employee => employee.status === EmployeeStatus.Active
              )}
              isFetchingEmployees={isFetchingEmployeesWithAssets}
              isFetchingDevices={isFetchingEmployeesWithAssets}
            />
            <DeviceOrderUpdates />
          </GridContainer>
          {hasMdmEntitlement ? (
            <DeviceHealthMonitor />
          ) : isFetchingEmployeesWithAssets ? (
            <CenteredSpinner />
          ) : deviceIssuesByTypeMaps ? (
            <DeviceIssues
              employees={employeesWithAssets || []}
              devices={assets || []}
              deviceIssuesByType={deviceIssuesByTypeMaps}
              tabContent={tabContent}
              isMDMSetUp={isMDMSetUp}
            />
          ) : null}
        </>
      )}
    </OneColumnLayout>
  );
};

export default DeviceOverview;

import { ModalV2 } from '@electricjs/arc';
import { SmartSearch, SmartSearchSelectOption } from '../SmartSearch';

const GlobalSearch = ({
  searchableData,
  onCloseModal,
}: {
  searchableData: SmartSearchSelectOption[];
  onCloseModal: () => void;
}) => {
  return (
    <ModalV2
      ariaLabelledby="global-search-modal"
      visible
      hide={onCloseModal}
      minHeight="fit-content"
      position="fixed"
      top="15vh">
      <SmartSearch searchableData={searchableData} isInsideModal />
    </ModalV2>
  );
};

export default GlobalSearch;

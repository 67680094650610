import { useEffect } from 'react';
import styled from 'styled-components';

import { Box, Button, Flex, Text, getToken, ColorsV2 } from '@electricjs/arc';

import { StorefrontSteps } from '@/types/storefront';

import { EmployeeSection } from '../../components/Sections/EmployeeSection';
import {
  StoreSection,
  StoreSectionProps,
} from '../../components/Sections/StoreSection';
import { useStorefront } from '../../StorefrontProvider';
import { SchemaOption } from '../StorefrontCheckoutFormProvider';

const StyledBox = styled(Box)`
  max-width: 102.3rem;
  margin: 0 auto;
  margin-bottom: 3.2rem;
  @media (max-width: 1420px) {
    max-width: 100%;
  }
`;

const StickyBanner = styled(Flex)`
  position: sticky;
  z-index: 999;
  top: 0;
  min-width: 100rem;
  border-bottom: solid ${getToken(ColorsV2.BORDER)} 0.1rem;
  background-color: ${getToken(ColorsV2.BACKGROUND)};
  padding-top: 2rem;
  margin-bottom: 2.4rem;
`;

export type ShoppingStepProps = StoreSectionProps & {
  isTiedToEmployee: boolean;
};

/**
 * The "shop" module: Employee + Stores (Asset & Catalog)
 */
export const ShoppingStep = ({
  isTiedToEmployee,
  laptopRecommendationUrl,
}: ShoppingStepProps) => {
  const {
    storefrontCheckoutForm,
    setStorefrontStep,
    itemsInCart,
    setSchemaOption,
  } = useStorefront();

  const handleGoToCart = () => {
    // The form submit process was already handled by the form's lib (form validation, setting focus on the error field, etc)
    // After form is valid, just need to go to the Cart step (this step will pull data directly from the form's state)
    setStorefrontStep(StorefrontSteps.CART);
  };

  useEffect(() => {
    if (isTiedToEmployee) {
      setSchemaOption(SchemaOption.EMPLOYEE);
    }
  }, [isTiedToEmployee, setSchemaOption]);

  return (
    <StyledBox>
      <Flex vertical width="100%" maxWidth="100rem">
        <form onSubmit={storefrontCheckoutForm?.handleSubmit(handleGoToCart)}>
          {/* Title */}
          <Box>
            <Text variant="heading-2">Purchase hardware</Text>
            <Text variant="subheading">
              Browse and order hardware for your company and employees.
            </Text>
          </Box>
          <StickyBanner>
            {/* Employee Section */}
            {isTiedToEmployee && <EmployeeSection />}
            {/* Cart button */}
            <Button
              id="go-to-cart-button"
              type="submit"
              disabled={!itemsInCart}
              mt="2rem" // need to use margin-top instead of "align-items:center" to align vertically with Search employee component (specially when the input error message appears)
              minWidth="14rem">
              {!itemsInCart ? 'Go to cart' : `Go to cart (${itemsInCart})`}
            </Button>
          </StickyBanner>
          {/* Store Section */}
          <Box p="0 1rem">
            <StoreSection laptopRecommendationUrl={laptopRecommendationUrl} />
          </Box>
        </form>
      </Flex>
    </StyledBox>
  );
};

import { Suspense, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import {
  Box,
  ColorsV2,
  Flex,
  Icon,
  Text,
  useBreakpoint,
} from '@electricjs/arc';

import PageSpinner from '@/components/PageSpinner';
import { useRouteReloader } from '@/hooks/useRouteReloader';
import { GlobalUIProvider } from '@/components/GlobalUIProvider';
import { default as InternalSideNav } from '@/components/Nav/SideNav/Internal/InternalSideNavWrapper';
import { default as InternalTopNav } from '@/components/Nav/TopNav/Internal/InternalTopNavWrapper';
import { CenteredSpinner } from '@common';

const InternalPageContent = () => {
  const [showSideNav, setShowSideNav] = useState(true);
  const { md } = useBreakpoint();
  const location = useLocation();
  const scrollableWrapperRef = useRef<HTMLElement>(null);

  useEffect(() => {
    setShowSideNav(Boolean(md));
  }, [md]);

  // Temporary workaround for react-router's behavior of retaining scroll position
  // when navigating between routes. This ensures the main scrollable content
  // resets to the top whenever the route changes.
  useLayoutEffect(() => {
    if (scrollableWrapperRef.current) {
      scrollableWrapperRef.current.scrollTo({ top: 0 });
    }
  }, [location.pathname]);

  const toggleSideNav = () => setShowSideNav(!showSideNav);

  // Medium to large screens:
  //   - The side bar always exists
  //   - Split into a main row:
  //     - Left: SideNav
  //     - Right: Content
  if (md) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        height="100vh"
        backgroundColor={ColorsV2.BACKGROUND}
        width="100vw"
        overflow="hidden">
        {/* ......... left side (SideNav) ......... */}
        <InternalSideNav />
        {/* ......... right side (Content) ......... */}
        <Box
          flexGrow="1" // right side: will grow in width (to the right)
          display="flex"
          flexDirection="column"
          overflowY="hidden">
          <Box
            flexGrow="1" // content: will grow in height (to the bottom)
            overflowY="auto" // can scroll the content
            ref={scrollableWrapperRef}>
            <Suspense fallback={<PageSpinner />}>
              <Outlet />
            </Suspense>
          </Box>
        </Box>
      </Box>
    );
  }

  // Small screens:
  //   - The side bar is controlled by the TopBar menu button
  //   - Split into a main column: TopBar + "inner row"
  //   - The inner row:
  //     - Left: SideNav
  //     - Right: Content
  return (
    <Box
      display="flex"
      flexDirection="column"
      overflow="hidden"
      height="100vh"
      width="100vw">
      <InternalTopNav onMobileMenuClick={toggleSideNav} />
      <Box
        flexGrow="1" // will grow in height (to the bottom)
        display="flex"
        flexDirection="row"
        backgroundColor={ColorsV2.BACKGROUND}
        overflow="hidden">
        {/* ......... left side (SideNav) ......... */}
        {showSideNav ? (
          <InternalSideNav mobileOnMenuClick={toggleSideNav} />
        ) : null}
        {/* ......... right side (Content) ......... */}
        <Box
          flexGrow="1" // will grow in width (to the right)
          overflowY="auto" // can scroll the content
          ref={scrollableWrapperRef}>
          <Suspense fallback={<PageSpinner />}>
            <Outlet />
          </Suspense>
        </Box>
      </Box>
    </Box>
  );
};

export const InternalPageWrapper = () => {
  // **** Route interceptor **** //
  // This hook is used to reload the page when the app version changes
  useRouteReloader();

  // This will only reset on page reload or navigation back to IT Hub
  const [shouldShowWarning, setShouldShowWarning] = useState(true);

  // Show timed full screen warning to warn users about navigating to the Internal Portal
  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldShowWarning(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  // Show warning if the user is just entering the Internal Portal
  if (shouldShowWarning) {
    return (
      <Flex
        width="100vw"
        height="100vh"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        backgroundColor={ColorsV2.BACKGROUND}>
        <Flex alignItems="center">
          <Icon
            icon="alert-triangle"
            color={ColorsV2.WARNING}
            size="large"
            mr="1.6rem"
          />
          <Text variant="heading-1">Opening Internal Portal</Text>
          <Icon
            icon="alert-triangle"
            color={ColorsV2.WARNING}
            size="large"
            ml="1.6rem"
          />
        </Flex>
        <Text variant="subheading">
          These settings affect all Electric users, please use caution.
        </Text>
        <CenteredSpinner />
      </Flex>
    );
  }

  return (
    // We are re-initializing the GlobalUIProvider here to ensure that the Provider used is inside the Route component, making it possible to use Router based Links inside modals/toasts.
    // TODO: add ReduxProvider here with separate store
    <GlobalUIProvider>
      <InternalPageContent />
    </GlobalUIProvider>
  );
};

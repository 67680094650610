import { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  Card,
  Text,
  Divider,
  ColorsV2,
  Flex,
  Box,
  Skeleton,
} from '@electricjs/arc';

import { InlineError } from '@common';

import useDebounceValue from '@/hooks/useDebounceValue';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useCalculateShippingCostMutation } from '@/redux/slices/orderApiSlice';
import { useEstimateTaxesMutation } from '@/redux/slices/billingApiSlice';
import { useGlobalUI } from '@/components/GlobalUIProvider';

import { emptyValue } from '@/constants/common';
import formatCurrency from '@/helpers/formatCurrency';
import { StorefrontSteps } from '@/types/storefront';

import { useStorefront } from '../../StorefrontProvider';

const StyledFlex = styled(Flex)`
  width: 100%;
  justify-content: space-between;
`;

const OrderSummary = () => {
  const { showErrorToast } = useGlobalUI();

  const {
    storefrontCheckoutForm,
    catalogCart,
    itemsInCart,
    storefrontStep,
    shippingCost,
    setShippingCost,
    subTotalPrice,
    totalPrice,
    setTotalPrice,
    hasOnlyAssetsInCart,
  } = useStorefront();

  const [totalTax, setTotalTax] = useState<number | undefined>();

  const selectedShippingMethod =
    storefrontCheckoutForm?.watch('shippingMethod')?.value;

  const shippingAddress = storefrontCheckoutForm?.watch('shippingAddress');

  const organizationId = useGetOrganizationId();

  const [
    calculateShippingCost,
    { isLoading: shippingCostLoading, isError: shippingCostError },
  ] = useCalculateShippingCostMutation();

  const [estimateTaxes, { isLoading: taxesLoading, isError: taxesError }] =
    useEstimateTaxesMutation();

  // calculate the shippingCost every time shippingMethod or catalog cart change
  useEffect(() => {
    if (selectedShippingMethod && catalogCart.length > 0) {
      calculateShippingCost({
        organizationId,
        selectedShippingMethod,
        selectedProducts: catalogCart,
      })
        .unwrap()
        .then(value => setShippingCost(value))
        .catch(() => {
          showErrorToast({
            id: 'shipping-cost-error-toast',
            title: 'Shipping calculation error',
            message:
              'There was an error calculating the cost of shipping. Reselect the desired shipping method to try again.',
            hideAfter: 5000,
          });

          // resetting the shipping method, so when the user re-selects it, the calculation will be triggered again
          storefrontCheckoutForm.setValue('shippingMethod', undefined);
          setShippingCost(undefined);
        });
    }

    // handle when catalog cart gets empty
    if (catalogCart.length === 0) {
      setShippingCost(undefined);
    }
  }, [
    organizationId,
    selectedShippingMethod,
    catalogCart,
    calculateShippingCost,
    setShippingCost,
    showErrorToast,
    storefrontCheckoutForm,
  ]);

  const debouncedShippingAddress = useDebounceValue(shippingAddress, 1000);

  // estimate the taxes every time address or shippingCost or catalog cart change
  useEffect(() => {
    if (
      debouncedShippingAddress !== undefined &&
      debouncedShippingAddress.streetAddress1 && // check if the address is full (if Google api returned all the properties needed)
      debouncedShippingAddress.city &&
      debouncedShippingAddress.state &&
      debouncedShippingAddress.country &&
      debouncedShippingAddress.zip &&
      shippingCost !== undefined &&
      catalogCart.length > 0
    ) {
      estimateTaxes({
        organizationId,
        shippingAddress: debouncedShippingAddress,
        shippingCost,
        selectedProducts: catalogCart,
      })
        .unwrap()
        .then(value => setTotalTax(value))
        .catch(() => {
          showErrorToast({
            id: 'tax-error-toast',
            title: 'Taxes estimation error',
            message:
              'There was an error estimating the taxes. Reenter shipping address to try again.',
            hideAfter: 5000,
          });

          // resetting the shipping address, so when the user re-enters it, the calculation will be triggered again
          storefrontCheckoutForm?.setValue('shippingAddress', undefined);
        });
    }

    // handle shipping address clearing (when the user removes the address by clicking on the "x" button) or when catalog cart gets empty
    if (
      debouncedShippingAddress === undefined ||
      debouncedShippingAddress.zip === '' ||
      catalogCart.length === 0
    ) {
      setTotalTax(undefined);
    }
  }, [
    organizationId,
    selectedShippingMethod,
    catalogCart,
    calculateShippingCost,
    setShippingCost,
    showErrorToast,
    storefrontCheckoutForm,
    shippingCost,
    debouncedShippingAddress,
    estimateTaxes,
  ]);

  const preTaxTotalPrice = subTotalPrice + (shippingCost || 0);

  const estimatedTotalPrice = preTaxTotalPrice + (totalTax || 0);

  useEffect(() => {
    setTotalPrice(estimatedTotalPrice);
  }, [estimatedTotalPrice, setTotalPrice]);

  const formatValue = (value: number | undefined) => {
    if (value !== undefined) {
      return formatCurrency(value);
    }
    if (hasOnlyAssetsInCart) {
      return 'N/A';
    }
    return emptyValue;
  };

  const renderCalculatedValue = (
    loading: boolean,
    error: boolean,
    value: number | undefined
  ) => {
    if (loading) {
      return <Skeleton width="6rem" />;
    } else if (error) {
      return <InlineError />;
    } else {
      return <Text>{formatValue(value)}</Text>;
    }
  };

  return (
    <Card elevated id="order-summary-card">
      <Text variant="heading-1" p="1rem">
        Order summary
      </Text>
      <Box p="1rem" minWidth="35rem">
        <StyledFlex mb="2rem">
          <Text>Subtotal ({itemsInCart}):</Text>
          <Text>{formatCurrency(subTotalPrice)}</Text>
        </StyledFlex>
        <Box mb="1rem">
          <StyledFlex>
            <Text>Shipping:</Text>
            {renderCalculatedValue(
              shippingCostLoading,
              shippingCostError,
              shippingCost
            )}
          </StyledFlex>
          {shippingCost === undefined && !hasOnlyAssetsInCart && (
            <Text variant="legal">Calculated once shipping is selected</Text>
          )}
        </Box>
        <Box mb="1rem">
          <StyledFlex>
            <Text>Taxes:</Text>
            {renderCalculatedValue(taxesLoading, taxesError, totalTax)}
          </StyledFlex>
          {totalTax === undefined && !hasOnlyAssetsInCart && (
            <Text variant="legal">Calculated once address is confirmed</Text>
          )}
        </Box>
        <Divider
          leadingSpace="2rem"
          trailingSpace="2rem"
          color={ColorsV2.GRAY}
        />
        {totalTax !== undefined ? (
          <StyledFlex>
            <Text variant="label-large">
              {storefrontStep === StorefrontSteps.ORDER_PLACED
                ? 'Total:'
                : 'Estimated Total:'}
            </Text>
            <Text variant="label-large">{formatCurrency(totalPrice)}</Text>
          </StyledFlex>
        ) : (
          <StyledFlex>
            <Text variant="label-large">
              {storefrontStep === StorefrontSteps.ORDER_PLACED ||
              hasOnlyAssetsInCart
                ? 'Total:'
                : 'Pre-tax total:'}
            </Text>
            <Text variant="label-large">
              {formatCurrency(preTaxTotalPrice)}
            </Text>
          </StyledFlex>
        )}
      </Box>
    </Card>
  );
};

export default OrderSummary;

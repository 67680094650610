import { ProductTypeName } from '@electricjs/core_entity-client';
import { IconNames } from '@electricjs/arc';

const productTypeIcons: Record<ProductTypeName, IconNames> = {
  laptop: 'laptop',
  desktop: 'laptop',
  mouse: 'mouse',
  keyboard: 'keyboard',
  keyboard_mouse: 'keyboard',
  monitor: 'monitor',
  dock: 'dongle',
  audio: 'audio',
  video: 'webcam',
  warranty: 'shield-check',
  miscellaneous: 'briefcase',
  phone: 'telephone',
  tablets: 'briefcase',
  headphones: 'audio',
  microphone: 'audio',
  power_adapter: 'battery-bolt',
  cable: 'dongle',
  accessory: 'dongle',
};

export default function getProductTypeIcon(
  productType: ProductTypeName
): IconNames {
  return productTypeIcons[productType] ?? productTypeIcons.miscellaneous;
}

import { NavLink as RouterNavLink, NavLinkProps } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Flex, IconNames, Text, useBreakpoint } from '@electricjs/arc';

import Nav from '../SideNavBase';
import { SideNavItem } from '../SideNavItem';

import isVisible from '@/helpers/isVisible';
import { useUserHasElectricPermission } from '@/hooks/useUserHasElectricPermission';
import { ElectricPermission } from '@/types/users';

import electric_logo_only_black from '@/assets/electric_logo_only_black.svg';

/**
 * Creates a wrapper when an onClick method is provided.
 * This is primarily used to close the mobile menu when a menu item is clicked.
 */
function NavLinkWithOnClick(onClick?: () => void) {
  return function NavLink(props: NavLinkProps) {
    return <RouterNavLink {...(onClick ? { onClick } : {})} {...props} />;
  };
}

const InternalSideNavWrapper = ({
  mobileOnMenuClick,
}: {
  mobileOnMenuClick?: () => void;
}) => {
  const { md } = useBreakpoint();
  const { storefrontCatalogManager, itHubInternalBillingFeature } = useFlags();

  const canReadInternalOrders = useUserHasElectricPermission(
    ElectricPermission.ORDERS_READ
  );

  const canReadInternalProducts = useUserHasElectricPermission(
    ElectricPermission.PRODUCTS_READ
  );

  // If the mobile menu is open, we want to close it when a menu item is clicked
  const NavLink = mobileOnMenuClick
    ? NavLinkWithOnClick(mobileOnMenuClick)
    : RouterNavLink;

  const navItems: Array<{
    text: string;
    component?: JSX.Element;
    icon?: IconNames;
    visible?: boolean;
  }> = [
    {
      text: 'Back to IT Hub',
      component: NavLink && <NavLink to="/" />,
      icon: 'arrow-left',
    },
    {
      text: 'Applications',
      icon: 'app',
      component: NavLink && <NavLink to="/internal/applications" />,
    },
    {
      text: 'Billing',
      icon: 'piggy-bank',
      component: NavLink && <NavLink to="/internal/billing" />,
      visible: itHubInternalBillingFeature,
    },
    {
      text: 'Catalog',
      icon: 'page',
      component: NavLink && <NavLink to="/internal/catalog/products" />,
      visible: storefrontCatalogManager && canReadInternalProducts,
    },
    {
      text: 'Order Management',
      icon: 'truck',
      component: NavLink && <NavLink to="/internal/order-management" />,
      visible: canReadInternalOrders,
    },
    {
      text: 'Warehouse',
      icon: 'building',
      component: NavLink && <NavLink to="/internal/warehouse" />,
    },
  ];

  return (
    <Nav mobilePositionTop="auto">
      {md ? (
        <Flex justifyContent="center" alignItems="center">
          <img
            src={electric_logo_only_black}
            alt="Electric Internal Logo"
            style={{ marginRight: '1.6rem' }}
          />
          <Text variant="heading-3">Internal Portal</Text>
        </Flex>
      ) : null}
      {navItems
        ? navItems
            .filter(isVisible)
            .map(item => <SideNavItem key={item.text} {...item} />)
        : null}
    </Nav>
  );
};

export default InternalSideNavWrapper;

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  ModalAction,
  ModalBody,
  ModalFooter,
  ModalFooterActions,
  ModalHeader,
  ModalV2,
  Text,
  TextInputField,
} from '@electricjs/arc';

import { useGlobalUI } from '@/components/GlobalUIProvider';
import { useUpdateOrganizationAddOnMutation } from '@/redux/slices/addonApiSlice';
import { OrganizationAddOn } from '@/types/organizations';

type EditOrganizationAddOnLicenseCountModalProps = {
  onCancel: () => void;
  onSuccess: () => void;
  organizationAddOn: OrganizationAddOn;
};

type EditOrganizationAddOnLicenseCountModalHeaderProps = {
  name: string;
};

type EditOrganizationAddOnLicenseCountModalBodyProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any;
};

type EditOrganizationAddOnLicenseCountModalFooterActionsProps = {
  isValid: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  isLoading: boolean;
};

type FormData = {
  addOnId: string;
  licenseCount: number;
};

const editOrganizationAddOnLicenseCountSchema = yup.object().shape({
  addOnId: yup.string().required('Please select at least one add-on'),
  licenseCount: yup
    .number()
    .required('License Count is required')
    .typeError('Please add at least 1 license')
    .min(1, 'Please add at least 1 license'),
});

const EditOrganizationAddOnLicenseCountModalHeader = ({
  name,
}: EditOrganizationAddOnLicenseCountModalHeaderProps) => (
  <ModalHeader py={1}>
    <Text variant="heading-2">Edit the license count for {name}</Text>
  </ModalHeader>
);

const EditOrganizationAddOnLicenseCountModalBody = ({
  control,
}: EditOrganizationAddOnLicenseCountModalBodyProps) => (
  <ModalBody py={24}>
    <TextInputField
      id="license-count-input"
      name="licenseCount"
      control={control}
      label="License Count"
      required
    />
  </ModalBody>
);

const EditOrganizationAddOnLicenseCountModalFooterActions = ({
  isValid,
  onCancel,
  onSubmit,
  isLoading,
}: EditOrganizationAddOnLicenseCountModalFooterActionsProps) => (
  <ModalFooter py={16}>
    <ModalFooterActions>
      <ModalAction
        id="edit-organization-add-on-license-count-cancel-button"
        variant="text"
        onClick={onCancel}>
        Cancel
      </ModalAction>
      <ModalAction
        id="edit-organization-add-on-license-count-submit-button"
        type="button"
        disabled={!isValid}
        onClick={onSubmit}
        loading={isLoading}>
        Update License Count
      </ModalAction>
    </ModalFooterActions>
  </ModalFooter>
);

const EditOrganizationAddOnLicenseCountModal = ({
  onCancel,
  onSuccess,
  organizationAddOn,
}: EditOrganizationAddOnLicenseCountModalProps) => {
  const { showErrorToast, showSuccessToast } = useGlobalUI();
  const [
    updateOrganizationAddOn,
    { isLoading: updateOrganizationAddOnLoading },
  ] = useUpdateOrganizationAddOnMutation();

  const defaultValues: FormData = {
    addOnId: organizationAddOn.id,
    licenseCount: organizationAddOn.licenseCount || 0,
  };

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<FormData>({
    resolver: yupResolver(editOrganizationAddOnLicenseCountSchema),
    mode: 'onTouched',
    defaultValues,
  });

  const onSubmit = (data: FormData) => {
    const organizationId = organizationAddOn.organizationId;
    const addOnId = organizationAddOn.id;
    const { licenseCount } = data;

    updateOrganizationAddOn({ organizationId, addOnId, licenseCount })
      .unwrap()
      .then(() => {
        showSuccessToast({
          id: 'edit-organization-add-on-license-count-success-toast',
          stack: true,
          title: 'Add on updated',
          message: `${organizationAddOn.name} add on was updated successfully.`,
        });
        onSuccess();
      })
      .catch(() => {
        showErrorToast({
          id: 'edit-organization-add-on-license-count-error-toast',
          stack: true,
          message:
            'Something went wrong while updating the add on. Please try again.',
        });
      });
  };

  return (
    <ModalV2
      ariaLabelledby="edit-organization-addon-license-count"
      hide={onCancel}
      visible>
      <EditOrganizationAddOnLicenseCountModalHeader
        name={organizationAddOn.name}
      />
      <EditOrganizationAddOnLicenseCountModalBody control={control} />
      <EditOrganizationAddOnLicenseCountModalFooterActions
        isValid={isValid}
        onCancel={onCancel}
        onSubmit={handleSubmit(onSubmit)}
        isLoading={updateOrganizationAddOnLoading}
      />
    </ModalV2>
  );
};

export default EditOrganizationAddOnLicenseCountModal;

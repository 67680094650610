import {
  resultKindComponentMap,
  type SmartSearchSelectOption,
} from '../resultKindConfig';

type SearchResultDisplayOptionPickerProps = {
  resultItem: SmartSearchSelectOption;
};

const SearchResultDisplayOptionPicker = ({
  resultItem,
}: SearchResultDisplayOptionPickerProps): JSX.Element => {
  const resultItemKind = resultItem.kind;
  const Component = resultKindComponentMap[resultItemKind];

  if (Component) {
    return <Component {...resultItem} />;
  } else {
    console.error(`cannot render unknown result kind: ${resultItemKind}`);
    return <></>;
  }
};

export default SearchResultDisplayOptionPicker;

import {
  AssetCondition,
  AssetStatus,
  ProductTypeName,
} from '@electricjs/core_entity-client';

import { assetStatusDisplayNameMap } from '@/pages/Assets/AssetStatus';
import { Location, Site } from '@/types/assets';
import { ProductType } from '@/types/products';

import getProductTypeLabel from './getProductTypeLabel';

export type ProductTypeOption = {
  value: string;
  label: string;
  name: ProductTypeName;
};

export type SiteOption = {
  value: string;
  label: string;
  name: string;
};

export type LocationOption = {
  value: string;
  label: string;
  name: string;
};

export type AssetConditionOption = {
  value: AssetCondition;
  label: string;
  name: string;
};

export type AssetStatusOption = {
  value: AssetStatus;
  label: string;
  name: string;
};

export const assetConditionDisplayNameMap: Record<AssetCondition, string> = {
  [AssetCondition.New]: 'New',
  [AssetCondition.UsedGood]: 'Used (Good)',
  [AssetCondition.UsedFair]: 'Used (Fair)',
  [AssetCondition.UsedPoor]: 'Used (Poor)',
  [AssetCondition.Damaged]: 'Damaged',
};

export const transformProductTypesToOptions = (
  productTypes: ProductType[]
): ProductTypeOption[] => {
  return productTypes.map((productType: ProductType) => ({
    value: productType.id,
    label: getProductTypeLabel(productType.name),
    name: productType.name,
  }));
};

export const transformSitesToOptions = (sites: Site[]): SiteOption[] => {
  return sites.map((site: Site) => ({
    value: site.id,
    label: site.name,
    name: site.name,
  }));
};

export const transformLocationsToOptions = (
  locations: Location[]
): LocationOption[] => {
  return locations.map((location: Location) => ({
    value: location.id,
    label: location.name,
    name: location.name,
  }));
};

export const transformAssetConditionsToOptions = (): AssetConditionOption[] => {
  return Object.values(AssetCondition).map(condition => ({
    value: condition,
    label: assetConditionDisplayNameMap[condition] || condition,
    name: assetConditionDisplayNameMap[condition] || condition,
  }));
};

export const getAssetStatusOptions = (): AssetStatusOption[] => {
  return Object.values(AssetStatus).map(status => ({
    value: status,
    label: assetStatusDisplayNameMap[status] || status,
    name: assetStatusDisplayNameMap[status] || status,
  }));
};

import { NotificationType } from '@electricjs/core_entity-client';

import { Flex } from '@electricjs/arc';

import { useGetRequestData } from '@/hooks/useGetRequestData';
import { Employee } from '@/types/employees';
import { Request, RequestType } from '@/types/requests';

import { EmailDetail } from '../EmailDetail';
import { GroupDetail } from '../GroupDetail';
import { NotificationIcon } from '../NotificationIcon';
import { NotificationItemHeader } from '../NotificationItemHeader';
import { RequestActions } from '../RequestActions/RequestActions';
import { NotificationItemProps } from '../types';
import { useGetRequestHardwareData } from '@/hooks/useGetRequestHardwareData';

const getTitle = (employee?: Employee) => {
  const employeeName = employee
    ? `${employee.firstName} ${employee.lastName}`
    : 'the new hire.';

  return (
    <>
      Assign or purchase hardware for <b>{employeeName}</b>.
    </>
  );
};

type HardwareRequestActionsColumnProps = {
  request?: Request;
  requestType?: RequestType;
  isLoading: boolean;
};

export const HardwareRequestActionsColumn = ({
  request,
  requestType,
  isLoading,
}: HardwareRequestActionsColumnProps) => {
  return (
    <RequestActions
      loading={isLoading}
      request={request}
      requestType={requestType}
      isDropDownMenu={false}
    />
  );
};

export const HardwareRequestDetailsColumns = ({
  notification,
}: NotificationItemProps) => {
  const { data: requestData, isLoading } = useGetRequestHardwareData({
    requestId: notification?.data?.request_id ?? null,
  });

  const employeeGroups = requestData.employeeGroups;
  const employeeEmail = requestData?.employee?.email;

  return (
    <Flex flexDirection="column">
      <GroupDetail isLoading={isLoading} groups={employeeGroups} />
      <EmailDetail isLoading={isLoading} email={employeeEmail} />
    </Flex>
  );
};

const HardwareRequestNotificationItem = ({
  notification,
  hideDetails,
}: NotificationItemProps) => {
  const { data: requestData, isLoading } = useGetRequestData({
    requestId: notification?.data?.request_id ?? null,
  });

  const employee = requestData?.employee;
  const employeeGroups = requestData?.employeeGroups;
  const employeeEmail = employee?.email;

  const getLabel = () => {
    switch (notification.type) {
      case NotificationType.RequestAssigned:
        return 'REQUEST ASSIGNED';

      case NotificationType.RequestCompleted:
        return 'REQUEST COMPLETED';
      default:
        return '';
    }
  };

  const getIcon = () => (
    <NotificationIcon
      isNotificationUnseen={!notification.seenAt}
      loading={isLoading}
      icon="laptop"
    />
  );

  return (
    <NotificationItemHeader
      notification={notification}
      isLoading={isLoading}
      title={getTitle(employee)}
      icon={getIcon()}
      label={getLabel()}>
      {!hideDetails ? (
        <>
          <GroupDetail isLoading={isLoading} groups={employeeGroups} />
          <EmailDetail isLoading={isLoading} email={employeeEmail} />
          <RequestActions
            loading={isLoading}
            request={requestData.request}
            requestType={requestData.requestType}
          />
        </>
      ) : null}
    </NotificationItemHeader>
  );
};

export default HardwareRequestNotificationItem;

import { useGlobalUI } from '@/components/GlobalUIProvider';
import { EmployeeWithAssets } from '@/types/employees';
import { Button } from '@electricjs/arc';
import OSUpdateEmailModal from '@/components/DeviceOverview/IssueModals/OSUpdateEmailModal';
import EncryptionEmailModal from '@/components/DeviceOverview/IssueModals/EncryptionEmailModal';
import FirewallEmailModal from '@/components/DeviceOverview/IssueModals/FirewallEmailModal';
import GatekeeperEmailModal from '@/components/DeviceOverview/IssueModals/GatekeeperEmailModal';
import DiskSpaceEmailModal from '@/components/DeviceOverview/IssueModals/DiskSpaceEmailModal';

type SendFollowUpEmailButtonProps = {
  employee: EmployeeWithAssets;
  ModalComponent:
    | typeof OSUpdateEmailModal
    | typeof EncryptionEmailModal
    | typeof FirewallEmailModal
    | typeof GatekeeperEmailModal
    | typeof DiskSpaceEmailModal;
};

export const SendFollowUpEmailButton = ({
  employee,
  ModalComponent,
}: SendFollowUpEmailButtonProps) => {
  const { openModal, closeModal } = useGlobalUI();
  const handleSendEmail = () => {
    openModal(<ModalComponent employees={[employee]} hide={closeModal} />);
  };

  return (
    <Button
      id="needs-attention-send-follow-up-email-button"
      variant="outline"
      onClick={handleSendEmail}>
      Send follow up email
    </Button>
  );
};

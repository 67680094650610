import CenteredSpinner from 'common/CenteredSpinner';
import { ReactNode } from 'react';
import styled from 'styled-components';

import {
  Box,
  ColorsV2,
  Flex,
  Icon,
  IconInCircle,
  IconNames,
  Text,
  getToken,
  useThemeColors,
} from '@electricjs/arc';

const StyledBox = styled(Box)<{ selected: boolean; $customWidth?: string }>`
  cursor: pointer;
  border-style: solid;
  border-radius: 6px;
  width: ${props => (props.$customWidth ? props.$customWidth : '12.5rem')};
  padding: 0 1rem 1rem 1rem;
  border-color: ${props =>
    props.selected
      ? getToken(ColorsV2.PRIMARY)
      : getToken(ColorsV2.GRAY_LIGHT)};
  border-width: ${props => (props.selected ? '2px' : '1px')};
  background-color: ${props =>
    props.selected
      ? getToken(ColorsV2.PRIMARY_LIGHTEST)
      : getToken(ColorsV2.WHITE)};
  transition: background-color 0.5s ease-out;

  &:hover {
    background-color: ${getToken(ColorsV2.PRIMARY_LIGHTEST)};
    border-color: ${getToken(ColorsV2.PRIMARY)};
  }
`;

type SquareIconTabProps<T> = {
  numErrors?: number;
  isLoading?: boolean;
  label: string;
  property: T;
  selectedTab: T;
  onClick: (label: T) => void;
  id: string;
  customWidth?: string;
  hideBubble?: boolean;
};

type SquareIconTabPropsWithIcon<T> = SquareIconTabProps<T> & {
  icon: IconNames;
  image?: never;
};

type SquareIconTabPropsWithImage<T> = SquareIconTabProps<T> & {
  icon?: never;
  image: ReactNode;
};

const Bubble = ({
  numErrors,
  isLoading,
}: {
  numErrors: number;
  isLoading: boolean;
}) => {
  const [red, green] = useThemeColors([ColorsV2.ERROR, ColorsV2.SUCCESS]);
  return isLoading ? (
    <Flex
      width="2rem"
      height="2rem"
      position="relative"
      top="1rem"
      justifyContent="center"
      alignItems="center">
      <CenteredSpinner />
    </Flex>
  ) : (
    <Flex
      width="2.2rem"
      height="2.2rem"
      borderRadius="50%"
      backgroundColor={numErrors ? red : green}
      position="relative"
      top="1.5rem"
      justifyContent="center"
      alignItems="center">
      {numErrors ? (
        <Text variant="label-small" intent="primary-invert">
          {numErrors}
        </Text>
      ) : (
        <Icon icon="check" size="small" intent="primary-invert" />
      )}
    </Flex>
  );
};

// The comma here is necessary for the type to be generic
const SquareIconTab = <T,>({
  property,
  icon,
  image,
  numErrors = 0,
  isLoading = false,
  label,
  selectedTab,
  onClick,
  id,
  customWidth,
  hideBubble = false,
}: SquareIconTabPropsWithIcon<T> | SquareIconTabPropsWithImage<T>) => {
  const [iconBackground, iconColor, selectedIconColor] = useThemeColors([
    ColorsV2.GRAY_LIGHTER,
    ColorsV2.TEXT,
    ColorsV2.PRIMARY,
  ]);
  const selected = selectedTab === property;
  const handleClick = () => {
    onClick(property);
  };

  return (
    <StyledBox
      selected={selected}
      mr={3}
      mt={4}
      onClick={handleClick}
      id={id}
      $customWidth={customWidth}>
      {hideBubble ? (
        <Box height="2rem" />
      ) : (
        <Bubble numErrors={numErrors} isLoading={isLoading} />
      )}
      <Flex flexDirection="column" alignItems="center">
        {icon ? (
          <IconInCircle
            icon={icon}
            background={iconBackground}
            color={selected ? selectedIconColor : iconColor}
            iconSize="medium"
            size="4rem"
          />
        ) : null}
        {image ? image : null}
        <Text
          pt={3}
          variant="label-large"
          intent={selected ? 'brand' : 'primary'}>
          {label}
        </Text>
      </Flex>
    </StyledBox>
  );
};

export default SquareIconTab;

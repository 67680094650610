import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Flex,
  ModalBody,
  ModalFooter,
  ModalFooterActions,
  ModalHeader,
  ModalV2,
  Text,
  TextInputField,
  NumberInputField,
  Spacer,
  ModalAction,
  ColorsV2,
  useThemeColors,
  AddressGroupField,
  type Address,
  requiredAddressValidationSchema,
} from '@electricjs/arc';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  type PaymentMethodV2,
  type PaymentMethodOption,
} from '@/types/billing';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useEditPaymentMethodMutation } from '@/redux/slices/billingApiSlice';
import { useGlobalUI } from '@/components/GlobalUIProvider';
import { isPaymentMethodExpired } from '@/components/Billing/utils/isPaymentMethodExpired';

type FormData = {
  expirationDate: string;
  address: Address;
  nickname?: string;
};

const paymentMethodEditSchema = yup.object<FormData>().shape({
  expirationDate: yup
    .string()
    .required()
    .transform(value => {
      const [expirationMonth, expirationYear] = value.split('/').map(Number);
      // Converting to a full year (4 digits)
      return `${expirationMonth}/${2000 + expirationYear}`;
    })
    .test(
      'expirationDate',
      'Please enter a valid expiration date',
      value => isPaymentMethodExpired(value) === false
    )
    .test('expirationDate', 'Expiration date is required', value => {
      const [expirationMonth, expirationYear] = value.split('/').map(Number);

      return Boolean(expirationMonth) && Boolean(expirationYear);
    }),

  address: requiredAddressValidationSchema,
  nickname: yup.string(),
});

const defaultValues: FormData = {
  expirationDate: '',
  address: {
    streetAddress1: '',
    streetAddress2: '',
    city: '',
    state: '',
    country: '',
    countryISO: '',
    zip: '',
  },
  nickname: '',
};

type EditCardModalProps = {
  onClose: () => void;
  paymentMethodOption: PaymentMethodOption;
};

const EditCardModal = ({
  onClose,
  paymentMethodOption,
}: EditCardModalProps) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
    setValue,
  } = useForm<FormData>({
    resolver: yupResolver(paymentMethodEditSchema),
    mode: 'onTouched',
    defaultValues,
  });

  const { showSuccessToast, showErrorToast } = useGlobalUI();

  const {
    value: {
      nickname,
      lastFourDigits,
      expirationMonth,
      expirationYear,
      address,
    },
  } = paymentMethodOption;

  const cardNumber = `xxxx xxxx xxxx ${lastFourDigits}`;

  const disabledColor = useThemeColors(ColorsV2.GRAY_LIGHTER);

  const organizationId = useGetOrganizationId();

  const [editPaymentMethod, { isLoading: isEditingPaymentMethod }] =
    useEditPaymentMethodMutation();

  useEffect(() => {
    // Getting the last two digits of the year
    const expirationDate = `${expirationMonth.toString().padStart(2, '0')}/${expirationYear.toString().slice(-2)}`;
    setValue('expirationDate', expirationDate);

    setValue('nickname', nickname);
    setValue('address', address);
  }, [expirationMonth, expirationYear, nickname, address, setValue]);

  const handleEdit = (formData: FormData) => {
    const { address, expirationDate, nickname } = formData;
    const [expirationMonth, expirationYear] = expirationDate
      .split('/')
      .map(Number);

    const paymentMethod: PaymentMethodV2 = {
      ...paymentMethodOption.value,
      expirationMonth,
      expirationYear,
      nickname,
      address,
    };

    editPaymentMethod({
      organizationId,
      ...paymentMethod,
    })
      .unwrap()
      .then(() => {
        showSuccessToast({
          id: 'edit-payment-method-success-toast',
          message: 'Payment method has been successfully updated.',
        });
        onClose();
      })
      .catch(() => {
        showErrorToast({
          id: 'edit-payment-method-error-toast',
          message: 'Something went wrong. Please try again.',
        });
      });
  };

  return (
    <ModalV2
      ariaLabelledby="edit-card-modal-title"
      testId="edit-card-modal"
      visible
      hide={onClose}
      maxWidth="75rem">
      <ModalHeader>
        <Text id="edit-card-modal-title" variant="heading-2">
          Edit payment method details
        </Text>
      </ModalHeader>
      <form onSubmit={handleSubmit(handleEdit)}>
        <ModalBody p="2.4rem">
          <Flex vertical>
            <Text variant="heading-3" mb="1.6rem">
              Payment Information
            </Text>
            <Spacer space="1.6rem" />
            <Flex width="100%" columnGap="2rem">
              <Flex width="100%">
                {/* Card number */}
                <TextInputField
                  id="card-number-input"
                  name="cardNumber"
                  label="Card Number"
                  control={control}
                  defaultValue={cardNumber}
                  backgroundColor={disabledColor}
                  disabled
                />
              </Flex>
              <Flex width="100%" columnGap="2rem">
                {/* Expiration date */}
                <NumberInputField
                  id="card-expiration-date-input"
                  format="##/##"
                  placeholder="MM/YY"
                  name="expirationDate"
                  label="Expiration date"
                  control={control}
                  required
                />
                {/* CVC */}
                <TextInputField
                  id="card-cvc-number-input"
                  placeholder="12345"
                  name="cvc"
                  label="CVC"
                  control={control}
                  defaultValue="xxx"
                  backgroundColor={disabledColor}
                  disabled
                />
              </Flex>
            </Flex>
            <Flex width="100%" columnGap="2rem">
              <AddressGroupField
                name="address"
                control={control}
                onChange={address =>
                  setValue('address', address, {
                    shouldValidate: true,
                  })
                }
                googleMapsApiKey={window._env_?.VITE_GOOGLE_MAPS_PUB_API_KEY}
                allowInternational
                required
              />
            </Flex>
            {/* Card nickname */}
            <TextInputField
              id="card-nickname-input"
              placeholder="Corporate Visa..."
              name="nickname"
              label="Card nickname"
              control={control}
              width="100%"
            />
          </Flex>
        </ModalBody>
        <ModalFooter>
          <ModalFooterActions>
            <ModalAction
              id="edit-card-modal-cancel-button"
              onClick={onClose}
              variant="text">
              Cancel
            </ModalAction>
            <ModalAction
              id="edit-card-modal-save-button"
              disabled={!isValid || isEditingPaymentMethod}
              loading={isEditingPaymentMethod}
              onClick={() => {}} // empty function to avoid the ModalAction default click behavior (that is to close the modal)
              type="submit">
              Save
            </ModalAction>
          </ModalFooterActions>
        </ModalFooter>
      </form>
    </ModalV2>
  );
};

export default EditCardModal;

import { Button, Menu, MenuItem, Text } from '@electricjs/arc';
import { useNavigate } from 'react-router-dom';
import { SelectionParamKeys } from '@/types/queryParamKeys';

type ReplaceMenuOptionsProps = {
  employeeId: string;
};

export const ReplaceMenuOptions = ({ employeeId }: ReplaceMenuOptionsProps) => {
  const navigate = useNavigate();

  return (
    <Menu
      placement="bottom-start"
      trigger={
        <Button
          variant="outline"
          id="replace-device-button-menu"
          iconAfter="chevron-down">
          Replace device
        </Button>
      }>
      <MenuItem
        id="choose-from-inventory-menu-item"
        onClick={() => navigate('/assets?orderBy=status&sortOrder=asc')}>
        <Text>Choose from company assets</Text>
      </MenuItem>
      <MenuItem
        id="choose-from-hw-store-menu-item"
        onClick={() =>
          navigate(
            `/purchase-hardware?${SelectionParamKeys.EmployeeId}=${employeeId}`
          )
        }>
        <Text>Choose from hardware store</Text>
      </MenuItem>
    </Menu>
  );
};

import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { UseToastParams, useToast } from '@electricjs/arc';

type GlobalUIContextType = {
  /* Modal */
  openModal: (component: ReactNode) => void;
  closeModal: () => void;
  isModalOpen: boolean;
  /* Toast */
  showToast: (params: UseToastParams) => void;
  showSuccessToast: (overrideParams: UseToastParams) => void;
  showErrorToast: (overrideParams: UseToastParams) => void;
  /* Side Nav */
  openSideNav: () => void;
  closeSideNav: () => void;
  showSideNav: () => void;
  hideSideNav: () => void;
  isSideNavOpen?: boolean;
  isSideNavVisible?: boolean;
};

type GlobalUIProviderProps = {
  children: ReactNode;
};

const GlobalUIContext = createContext<GlobalUIContextType>({
  openModal: () => {},
  closeModal: () => {},
  isModalOpen: false,
  showToast: () => {},
  showSuccessToast: () => {},
  showErrorToast: () => {},
  openSideNav: () => {},
  closeSideNav: () => {},
  showSideNav: () => {},
  hideSideNav: () => {},
  isSideNavOpen: true,
  isSideNavVisible: true,
});

export const GlobalUIProvider = (props: GlobalUIProviderProps) => {
  const [modal, setModal] = useState<ReactNode>(null);
  const { toast, showToast } = useToast({ id: 'global-ui-toast-default' });
  const [isSideNavOpen, setIsSideNavOpen] = useState(true);
  const [isSideNavVisible, setIsSideNavVisible] = useState(true);

  // When the "back" button is pressed, close any open modals
  useEffect(() => {
    const handlePopstate = () => {
      if (modal) setModal(null);
    };

    window.addEventListener('popstate', handlePopstate);

    return () => window.removeEventListener('popstate', handlePopstate);
  }, [modal]);

  return (
    <GlobalUIContext.Provider
      value={{
        openModal: useCallback(component => setModal(component), []),
        closeModal: useCallback(() => setModal(null), []),
        isModalOpen: !!modal,
        showToast: useCallback(params => showToast(params), [showToast]),
        showSuccessToast: useCallback(
          overrideParams =>
            showToast({
              intent: 'success',
              title: 'Success',
              ...overrideParams,
            }),
          [showToast]
        ),
        showErrorToast: useCallback(
          overrideParams =>
            showToast({ intent: 'error', title: 'Error', ...overrideParams }),
          [showToast]
        ),
        openSideNav: useCallback(() => setIsSideNavOpen(true), []),
        closeSideNav: useCallback(() => setIsSideNavOpen(false), []),
        showSideNav: useCallback(() => setIsSideNavVisible(true), []),
        hideSideNav: useCallback(() => setIsSideNavVisible(false), []),
        isSideNavOpen,
        isSideNavVisible,
      }}>
      {props.children}

      {/* Root modal */}
      {modal}
      {/* Root toast */}
      {toast}
    </GlobalUIContext.Provider>
  );
};

export const useGlobalUI = () => {
  const {
    openModal,
    closeModal,
    showToast,
    showSuccessToast,
    showErrorToast,
    openSideNav,
    closeSideNav,
    showSideNav,
    hideSideNav,
    isSideNavOpen,
    isSideNavVisible,
  } = useContext(GlobalUIContext);

  return {
    openModal,
    closeModal,
    showToast,
    showSuccessToast,
    showErrorToast,
    openSideNav,
    closeSideNav,
    showSideNav,
    hideSideNav,
    isSideNavOpen,
    isSideNavVisible,
  };
};

import { rest } from 'msw';

import { simpleGroupApplicationList } from '@/mocks/data/groupData';
import { simpleOrganizationGroups } from '@/mocks/data/organizationData';

export const groupHandlers = [
  rest.delete(
    '*/v1/organizations/:organizationId/groups/:groupId/employees/:employeeId',
    (req, res, ctx) => {
      // find the group in the mock data
      const group = simpleOrganizationGroups.find(
        group => group.id === req.params.groupId
      );

      // remove the employee from the mock data (mutate the data)
      if (group) {
        group.employees = group.employees.filter(
          employee => employee.id !== req.params.employeeId
        );

        return res(ctx.status(200), ctx.delay());
      } else {
        return res(ctx.status(404), ctx.delay());
      }
    }
  ),

  rest.patch(
    '*/v1/organizations/:organizationId/groups/:groupId',
    (req, res, ctx) => {
      // find the group in the mock data
      const group = simpleOrganizationGroups.find(
        group => group.id === req.params.groupId
      );

      if (group) {
        group.name = 'renamed group';
        return res(ctx.status(200), ctx.delay());
      } else {
        return res(ctx.status(404), ctx.delay());
      }
    }
  ),

  rest.get('*/v1/*/groups/:groupId/applications', (req, res, ctx) => {
    // find the group in the mock data
    const group = simpleOrganizationGroups.find(
      group => group.id === req.params.groupId
    );

    if (group) {
      return res(
        ctx.status(200),
        ctx.delay(),
        ctx.json(simpleGroupApplicationList)
      );
    } else {
      return res(ctx.status(404), ctx.delay());
    }
  }),
];

import {
  Badge,
  Box,
  Button,
  ColorsV2,
  CustomComponents,
  Flex,
  getToken,
  Icon,
  Select,
  Tab,
  Text,
  useBreakpoint,
  useThemeColors,
} from '@electricjs/arc';
import styled from 'styled-components';
import { DeviceIssueType } from '@/types/devices';
import { DeviceIssuesInfoModal } from '../IssueModals/InfoModal';
import { DeviceIssuesInfoModalText } from '../IssueModals/InfoModalText';
import { useGlobalUI } from '@/components/GlobalUIProvider';
import { IssueTabs, TabContent } from '../IssueTabs';

const TabMobileContainer = styled(Tab)`
  justify-content: flex-start;
  padding: 0;

  :hover {
    background-color: ${getToken(ColorsV2.PRIMARY_LIGHTEST)};
  }

  &[aria-selected='true'],
  &:focus {
    border-bottom: none;
  }
`;

const ButtonStyled = styled(Button)`
  :hover {
    background-color: ${getToken(ColorsV2.PRIMARY_LIGHTEST)};
  }
`;

export type DeviceHealthMonitorTabSelectorProps = {
  tabContent: TabContent[];
  selectedTab: DeviceIssueType;
  handleTabChange: (tab: string) => void;
};

export const DeviceHealthMonitorTabSelector = ({
  handleTabChange,
  selectedTab,
  tabContent,
}: DeviceHealthMonitorTabSelectorProps) => {
  const { sm } = useBreakpoint();
  const { openModal, closeModal } = useGlobalUI();
  const [btnBackground, btnTextColor] = useThemeColors([
    ColorsV2.PRIMARY_LIGHTEST,
    ColorsV2.PRIMARY,
  ]);

  const numErrors =
    tabContent.find(tab => tab.property === selectedTab)?.numErrors || 0;

  const tabs = (
    <Box px="1.5rem" py="2rem">
      <IssueTabs
        tabContent={tabContent}
        selectedTab={selectedTab as DeviceIssueType}
        handleTabClick={handleTabChange}
      />
      {numErrors > 0 && (
        <ButtonStyled
          id="open-modal"
          backgroundColor={btnBackground}
          color={btnTextColor}
          onClick={() =>
            openModal(
              <DeviceIssuesInfoModal
                visible
                hide={closeModal}
                deviceIssueType={selectedTab}
              />
            )
          }
          mt="3rem">
          {DeviceIssuesInfoModalText[selectedTab as DeviceIssueType].buttonText}
        </ButtonStyled>
      )}
    </Box>
  );

  const MobileTabOption: CustomComponents = {
    Option: option => {
      const {
        label,
        data: { value },
      } = option;

      const textIntent = value === selectedTab ? 'disabled' : 'primary';

      return (
        <TabMobileContainer
          id={option.data.value}
          data-testid={`${value}-tab`}
          minWidth="0px"
          isSelected={selectedTab}
          my="0.8rem"
          mx="0.4rem"
          gap="0.5rem">
          <Icon icon={option.data.icon} size="medium" />
          <Text variant={'body'} intent={textIntent}>
            {label}
          </Text>
          <Badge size="2rem" count={option.data.numErrors} />
        </TabMobileContainer>
      );
    },
  };

  const mobileTabOptions = tabContent.map(tab => ({
    label: tab.label,
    value: tab.property,
    numErrors: tab.numErrors,
    icon: tab.icon,
  }));

  const mobileTabs = (
    <Box width="100%" px="4rem" mt="2rem">
      <Select
        id="mobile-health-monitor-tab-filter"
        options={mobileTabOptions}
        value={mobileTabOptions.find(option => option.value === selectedTab)}
        closeMenuOnSelect
        onChange={({ value }) => {
          handleTabChange(value);
        }}
        formatOptionLabel={option => {
          return (
            <Flex alignItems="center" gap="0.5rem">
              <Icon icon={option.icon} size="medium" />
              <Text variant="body">{option.label}</Text>
              <Badge size="2rem" count={option.numErrors} />
            </Flex>
          );
        }}
        customComponents={MobileTabOption}
      />
    </Box>
  );

  return <>{sm ? tabs : mobileTabs}</>;
};

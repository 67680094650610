import { AssetCondition } from '@electricjs/core_entity-client';
import {
  Link,
  Flex,
  IconInCircle,
  Text,
  ColorsV2,
  useThemeColors,
  Box,
} from '@electricjs/arc';

import { OutlineChip } from '@common';
import { assetConditionDisplayNameMap } from '@/components/Assets/helpers/transformOptions';
import { Site } from '@/types/assets';
import formatCurrency from '@/helpers/formatCurrency';
import formatDate from '@/helpers/formatDate';
import getProductTypeIcon from '@/components/Assets/helpers/getProductTypeIcon';

import { ELECTRIC_WAREHOUSE_SITE_NAME } from '@/constants/assets';
import { emptyValue } from '@/constants/common';
import { ProductType } from '@/types/products';

type AssetDescriptionProps = {
  customAssetId: string;
  name: string;
  brand?: string;
  model?: string;
  serialNumber?: string;
  condition?: AssetCondition;
  isTextBold?: boolean;
  isTextTruncated?: boolean;
  fullDescription?: boolean;
  purchaseDate?: string;
  purchasePrice?: number;
  purchasedFromUrl?: string;
  notes?: string;
  site?: Site | null;
  productType?: ProductType;
};

const AssetDescription = ({
  customAssetId,
  name,
  brand,
  model,
  serialNumber,
  condition,
  isTextBold = true,
  isTextTruncated = true,
  fullDescription = false,
  purchaseDate,
  purchasePrice,
  purchasedFromUrl,
  notes,
  site,
  productType,
}: AssetDescriptionProps) => {
  const backgroundColor = useThemeColors(ColorsV2.PRIMARY_LIGHTEST);
  const updatedCondition = condition && assetConditionDisplayNameMap[condition];

  const isWarehouseAsset =
    site?.organizationId === null &&
    site?.name === ELECTRIC_WAREHOUSE_SITE_NAME;

  return (
    <Flex
      vertical
      rowGap="1rem"
      m={fullDescription ? '2rem 0' : '2rem 0 3rem 0'}
      p={fullDescription ? '0rem 4rem' : undefined}>
      {fullDescription ? (
        <Flex columnGap="4rem" mb="2rem" alignItems="center">
          <IconInCircle
            intent="primary"
            background={backgroundColor}
            icon={productType ? getProductTypeIcon(productType.name) : 'laptop'}
            size="8rem"
            iconSize="large"
            smoothedBorder
          />
          <Flex vertical>
            <Text
              variant={isTextBold ? 'label-large' : undefined}
              truncateAt={isTextTruncated ? '100%' : undefined}
              title={name}
              mb="1rem">
              {name}
            </Text>
            <Flex gap="1rem" alignItems="center">
              <Text>Site: {site ? site.name : emptyValue}</Text>
              {isWarehouseAsset && (
                <OutlineChip
                  id="warehouse-shipping-cost-chip"
                  label="Shipping starting at $65"
                />
              )}
            </Flex>
          </Flex>
        </Flex>
      ) : (
        <Text
          variant={isTextBold ? 'label-large' : undefined}
          truncateAt={isTextTruncated ? '100%' : undefined}
          title={name}
          mb="1rem">
          {name}
        </Text>
      )}
      <Text>Brand: {brand ? brand : emptyValue}</Text>
      <Text>Model: {model ? model : emptyValue}</Text>
      <Text>Serial: {serialNumber ? serialNumber : emptyValue}</Text>
      {fullDescription && <Text>Asset ID: {customAssetId}</Text>}
      <Text>Condition: {updatedCondition ?? emptyValue}</Text>
      {!fullDescription && (
        <>
          <Text>Site: {site ? site.name : emptyValue}</Text>
          {isWarehouseAsset && (
            <Box m="-0.5rem 0 -2rem 0">
              <OutlineChip
                id="warehouse-shipping-cost-chip"
                label="Shipping starting at $65"
              />
            </Box>
          )}
        </>
      )}
      {fullDescription && (
        <>
          <Flex vertical rowGap="1rem" m="2rem 0rem">
            <Text>
              Purchase Date:{' '}
              {purchaseDate ? formatDate(purchaseDate) : emptyValue}
            </Text>
            <Text>
              Purchase Price:{' '}
              {purchasePrice ? formatCurrency(purchasePrice) : emptyValue}
            </Text>
            <Text>
              Purchased From:{' '}
              {purchasedFromUrl ? (
                <Link
                  href={purchasedFromUrl}
                  rel="noopener noreferrer"
                  target="_blank">
                  {purchasedFromUrl}
                </Link>
              ) : (
                emptyValue
              )}
            </Text>
          </Flex>
          <Text>Notes: {notes ? notes : emptyValue}</Text>
        </>
      )}
    </Flex>
  );
};

export default AssetDescription;

import { useGetEmployeeQuery } from '@/redux/slices/employeeApiSlice';
import { useGetRequestTypeCategoriesQuery } from '@/redux/slices/organizationApiSlice';
import { Employee } from '@/types/employees';
import { skipToken } from '@reduxjs/toolkit/query';

export const RequestTypeCategoryNameEnum = {
  APPLICATIONS: 'Applications',
  CONNECTIVITY: 'Connectivity',
  DEVICE: 'Device',
  SOMETHING_ELSE: 'Something Else',
};

type RequestTypeCategoryNameEnum =
  (typeof RequestTypeCategoryNameEnum)[keyof typeof RequestTypeCategoryNameEnum];

export const useGetRequestTypeCategoryChampionEmployee = (
  organizationId: string,
  requestTypeCategoryName: RequestTypeCategoryNameEnum,
  skip?: boolean
): Employee | undefined => {
  const { data: requestTypeCategoriesResponse } =
    useGetRequestTypeCategoriesQuery(
      skip
        ? skipToken
        : {
            organizationId: organizationId,
          }
    );

  const requestTypeCategory = requestTypeCategoriesResponse?.find(
    rtc => rtc.name === requestTypeCategoryName
  );

  const requestTypeCategoryChampionEmployeeId =
    requestTypeCategory?.champions?.[0]?.employeeId;

  const { data: requestTypeCategoryChampionEmployee } = useGetEmployeeQuery(
    {
      // NOTE: This cannot actually ever be an empty string, but the the type requires it, so we're using the skip option to handle when this is empty.
      // At the moment, we only allow one champion per request type category.
      employeeId: requestTypeCategoryChampionEmployeeId ?? '',
      organizationId: organizationId,
    },
    { skip: !requestTypeCategory || !requestTypeCategoryChampionEmployeeId }
  );

  return requestTypeCategoryChampionEmployee;
};

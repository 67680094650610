import {
  EntitlementSlugEnum,
  PackageNameEnum,
} from '@electricjs/core_entity-client';

import { PlansResponse } from '@/types/organizations';

export const PACKAGE_DISPLAY_NAME_MAP: Record<PackageNameEnum, string> = {
  [PackageNameEnum.Beta]: 'Beta',
  [PackageNameEnum.Quickstart]: 'Quick Start',
  [PackageNameEnum.Pro]: 'Pro',
  [PackageNameEnum.Premier]: 'Premier',
  [PackageNameEnum.ProAdp]: 'ADP Pro',
  [PackageNameEnum.Lyte]: 'Light',
  [PackageNameEnum.LightAdp]: 'ADP Light',
};

/** A "hardcoded" plans list while we don't have it coming from the backend
 *  In the backend, they are called "packages"
 *  Endpoint: GET /v1/packages
 */
export const plansResponse: PlansResponse = {
  mostPopularPlanSlug: PackageNameEnum.Pro,
  availablePlans: [
    {
      id: 'beta-plan-id',
      sku: 'beta-plan-sku',
      name: PackageNameEnum.Beta,
      displayName: PACKAGE_DISPLAY_NAME_MAP[PackageNameEnum.Beta],
      description: '',
      inheritedFeaturesPlanSlug: null,
      specificFeatures: [],
    },
    {
      id: 'quick-start-plan-id',
      sku: 'quick-start-plan-sku',
      name: PackageNameEnum.Quickstart,
      displayName: PACKAGE_DISPLAY_NAME_MAP[PackageNameEnum.Quickstart],
      description:
        'Introduction to the Electric offering with access to third party software add-ons',
      inheritedFeaturesPlanSlug: null, // it's the simplest plan (doesn't inherit any feature)
      specificFeatures: [
        { name: 'IT Scorecard', slug: null },
        { name: 'Read-only insights', slug: null },
        { name: 'Device visibility', slug: null },
        { name: 'Partner Marketplace', slug: null },
        { name: 'Support ticket routing', slug: null },
        { name: 'Hardware procurement', slug: null },
      ],
    },
    {
      id: 'pro-plan-id',
      sku: 'pro-plan-sku',
      name: PackageNameEnum.Pro,
      displayName: PACKAGE_DISPLAY_NAME_MAP[PackageNameEnum.Pro],
      description:
        'Core service package that enables small businesses to manage their IT with confidence',
      inheritedFeaturesPlanSlug: PackageNameEnum.Quickstart,
      specificFeatures: [
        {
          name: 'HRIS Integrations',
          slug: EntitlementSlugEnum.HrisIntegration,
        },
        { name: 'Employee Groups', slug: EntitlementSlugEnum.EmployeeGroups },
        { name: 'Device management', slug: null },
        { name: 'On/Offboarding visibility', slug: null },
        { name: 'Actionable insights', slug: null },
      ],
    },
    {
      id: 'premier-plan-id',
      sku: 'premier-plan-sku',
      name: PackageNameEnum.Premier,
      displayName: PACKAGE_DISPLAY_NAME_MAP[PackageNameEnum.Premier],
      description:
        'Full service experience with the support of a best-in-class services team',
      inheritedFeaturesPlanSlug: PackageNameEnum.Pro,
      specificFeatures: [
        { name: 'Service Desk support', slug: null },
        { name: 'MSP partnership', slug: null },
        { name: 'Custom request workflows', slug: null },
        { name: 'Enhancement requests', slug: null },
        { name: 'Proactive monitoring', slug: null },
      ],
    },
    {
      id: 'light-plan-id',
      sku: 'light-plan-sku',
      name: PackageNameEnum.Lyte,
      displayName: PACKAGE_DISPLAY_NAME_MAP[PackageNameEnum.Lyte],
      description: '',
      inheritedFeaturesPlanSlug: null,
      specificFeatures: [],
    },
  ],
};

import { useGetJumpcloudProvisionStatusQuery } from '@/redux/slices/mdmManagementApiSlice';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import {
  Chip,
  ColorsV2,
  Flex,
  Spinner,
  Text,
  useThemeColors,
} from '@electricjs/arc';
import {
  MdmCertExpirationStatus,
  useGetMdmCertExpirationStatus,
} from '@/hooks/useGetMdmCertExpiration';

const PendingChip = () => {
  const [pendingBackground, pendingTextColor, spinnerColor] = useThemeColors([
    ColorsV2.INFO_LIGHTEST,
    ColorsV2.INFO_DARKEST,
    ColorsV2.INFO_DARK,
  ]);

  return (
    <Chip
      id="mdm-setup-pending-chip"
      backgroundColor={pendingBackground}
      py="1.6rem">
      <Flex gap="0.8rem" alignItems="center" px="0.5rem">
        <Text color={pendingTextColor} variant="label-input">
          MDM setup pending
        </Text>
        <Spinner size="small" color={spinnerColor} />
      </Flex>
    </Chip>
  );
};

const CompletedChip = () => {
  const [completedBackground, completedTextColor] = useThemeColors([
    ColorsV2.SUCCESS_LIGHTEST,
    ColorsV2.SUCCESS_DARKEST,
  ]);

  return (
    <Chip
      id="mdm-setup-complete-chip"
      backgroundColor={completedBackground}
      textColor={completedTextColor}>
      MDM setup complete
    </Chip>
  );
};

const RenewalSoonChip = () => {
  const [renewalSoonBgColor, renewalSoonTextColor] = useThemeColors([
    ColorsV2.WARNING_LIGHTEST,
    ColorsV2.WARNING_DARKEST,
  ]);
  return (
    <Chip
      id="mdm-setup-renewal-due-chip"
      backgroundColor={renewalSoonBgColor}
      py="1.6rem">
      <Text color={renewalSoonTextColor} variant="label-input">
        Apple Signing Certificate (CSR) renewal due soon
      </Text>
    </Chip>
  );
};

const ExpiredChip = () => {
  const [expiredBgColor, expiredTextColor] = useThemeColors([
    ColorsV2.ERROR_LIGHTEST,
    ColorsV2.ERROR_DARKEST,
  ]);
  return (
    <Chip
      id="mdm-setup-expired-chip"
      backgroundColor={expiredBgColor}
      py="1.6rem">
      <Text color={expiredTextColor} variant="label-input">
        Apple Signing Certificate (CSR) expired
      </Text>
    </Chip>
  );
};

const ProvisionStatusChip = () => {
  const organizationId = useGetOrganizationId();
  const { data: provisionStatus } =
    useGetJumpcloudProvisionStatusQuery(organizationId);
  const { status } = useGetMdmCertExpirationStatus();

  if (!provisionStatus || status === MdmCertExpirationStatus.NO_CERTIFICATE) {
    return null;
  }

  if (provisionStatus === 'completed') {
    if (status === MdmCertExpirationStatus.EXPIRED) {
      return <ExpiredChip />;
    }
    if (status === MdmCertExpirationStatus.ABOUT_TO_EXPIRE) {
      return <RenewalSoonChip />;
    }
    return <CompletedChip />;
  }
  return <PendingChip />;
};

export default ProvisionStatusChip;

import { DeviceIssueType } from '@/types/devices';

type DeviceIssuesInfoTextSections = {
  buttonText: string;
  title: string;
  sections: { header: string; description: string }[];
};

export const DeviceIssuesInfoModalText: {
  [key in DeviceIssueType]: DeviceIssuesInfoTextSections;
} = {
  [DeviceIssueType.Firewall]: {
    buttonText: "Learn about Electric's Firewall policy",
    title: "Electric's Firewall Policy",
    sections: [
      {
        header: 'What is a firewall?',
        description:
          'A firewall can protect your device from unwanted contact initiated by other computers when you’re connected to the internet or network.',
      },
      {
        header: 'How does Electric help?',
        description:
          "Our firewall policy chooses to block all incoming connections, makes computers stealthy, and keeps records of what's happening.",
      },
      {
        header: 'Why are there still devices without a firewall?',
        description:
          'For Mac users, logging out and back in will apply the firewall policy. For PC users, a device restart is required for the policy to take effect.',
      },
    ],
  },
  [DeviceIssueType.Gatekeeper]: {
    buttonText: "Learn about Electric's Gatekeeper policy",
    title: "Electric's Gatekeeper Policy",
    sections: [
      {
        header: 'What is Gatekeeper?',
        description:
          'Gatekeeper is a built-in security feature that protects your Apple devices by blocking unauthorized or malicious software from running.',
      },
      {
        header: 'How does Electric help?',
        description:
          "Electric enforces Gatekeeper to reduce the risk of security breaches and protects your company's sensitive data. It acts like a security guard for your Mac, ensuring that only trusted and verified software is allowed to install and run.",
      },
    ],
  },
  [DeviceIssueType.OS]: {
    buttonText: "Learn about Electric's OS update policy",
    title: "Electric's OS Update Policy",
    sections: [
      {
        header: 'Why is it important to have an up-to-date OS?',
        description:
          'Operating system upgrades and periodic patches introduce enhanced security and prevent against newly discovered vulnerabilities.',
      },
      {
        header: 'How does Electric help?',
        description:
          "This policy sets the rules for when your Apple device updates its software. It decides when to install updates from the App Store, new macOS versions, important updates, and early releases. However, it doesn't control major upgrades.",
      },
    ],
  },
  [DeviceIssueType.Encryption]: {
    buttonText: "Learn about Electric's encryption policy",
    title: "Electric's Encryption Policy",
    sections: [
      {
        header: 'What is encryption?',
        description:
          'BitLocker for Windows and FileVault for Macs: both help prevent unauthorized access to the information on your startup disk by encrypting your disk.',
      },
      {
        header: 'How does Electric help?',
        description:
          "Electric's encryption policy securely protects sensitive data on your devices, maintains compliance with industry regulations, and upholds the confidentiality of critical business information.",
      },
      {
        header: 'Why are there still devices without encryption?',
        description:
          'For Mac users, logging out and back in will apply the policy. For PC users, a device restart is required for the policy to take effect.',
      },
    ],
  },
  [DeviceIssueType.Storage]: {
    buttonText: 'Learn about disk space & troubleshooting tips',
    title: 'Disk Space & Troubleshooting Tips',
    sections: [
      {
        header: 'What is disk space?',
        description:
          'Disk space helps computers perform system functions, run applications, and manage memory use with optimum speed and efficiency.',
      },
      {
        header: 'How does Electric help?',
        description:
          "When an employee's disk space falls below 15%, Electric automatically sends them an email with step-by-step instructions to free up space.",
      },
      {
        header: 'How can I troubleshoot?',
        description:
          'If additional follow-up is needed, you can send a reminder email to the employee.',
      },
    ],
  },
  [DeviceIssueType.RAM]: {
    buttonText: 'Learn about RAM',
    title: 'RAM (Random Access Memory)',
    sections: [
      {
        header: 'What is RAM?',
        description:
          "RAM (Random Access Memory) is your computer's short-term memory. It helps your computer quickly access active apps and files, making multitasking smoother. Unlike storage, RAM clears when your computer is turned off.",
      },
      {
        header: 'What should I do when a device has low RAM?',
        description:
          'Electric recommends upgrading or replacing devices with low RAM to ensure optimal performance for your employees.',
      },
    ],
  },
  // MdmNotReporting and OsqueryNotReporting are "MDM issues" tab and have the same text
  [DeviceIssueType.MdmNotReporting]: {
    buttonText: 'Learn about MDM and the Electric app',
    title: 'MDM (Mobile Device Management) and the Electric application',
    sections: [
      {
        header: 'What is MDM?',
        description:
          'Mobile Device Management (MDM) centralizes device management, ensuring security and compliance across your organization.',
      },
      {
        header: 'What is the Electric application?',
        description:
          'The Electric app simplifies device assignment, monitors device security and performance, and connects devices to the MDM.',
      },
      {
        header:
          "Why are employees' devices getting disconnected from MDM & the Electric app?",
        description:
          'This is usually due to the need for a device reboot to reconnect the app and MDM.',
      },
      {
        header: 'Have questions about the Electric app or MDM?',
        description:
          'Feel free to contact our product support team for assistance.',
      },
    ],
  },
  [DeviceIssueType.OsqueryNotReporting]: {
    buttonText: 'Learn about MDM and the Electric app',
    title: 'MDM (Mobile Device Management) and the Electric application',
    sections: [
      {
        header: 'What is MDM?',
        description:
          'Mobile Device Management (MDM) centralizes device management, ensuring security and compliance across your organization.',
      },
      {
        header: 'What is the Electric application?',
        description:
          'The Electric app simplifies device assignment, monitors device security and performance, and connects devices to the MDM.',
      },
      {
        header:
          "Why are employees' devices getting disconnected from MDM & the Electric app?",
        description:
          'This is usually due to the need for a device reboot to reconnect the app and MDM.',
      },
      {
        header: 'Have questions about the Electric app or MDM?',
        description:
          'Feel free to contact our product support team for assistance.',
      },
    ],
  },
  [DeviceIssueType.DeviceLock]: {
    buttonText: 'Learn about MDM actions',
    title: 'MDM Actions: Lock and Wipe',
    sections: [
      {
        header: 'What is MDM?',
        description:
          'Mobile Device Management (MDM) centralizes device management, ensuring security and compliance across your organization.',
      },
      {
        header: 'How does locking a device work?',
        description:
          'You can lock a device from the employee profile, on the device card you can click the “Actions” button and find “Lock device.” Locking a device through MDM restricts access to ensure data security if a device is lost or stolen. Once locked, the device cannot be used until the correct password is entered, helping prevent unauthorized access.',
      },
      {
        header: 'How does wiping a device work?',
        description:
          'You can wipe a device from the employee profile or on the asset profile. You can click the “Actions” button and find “Wipe device.” Wiping a device through MDM erases all data, restoring it to factory settings. This action is recommended for lost or compromised devices to protect sensitive information. Note that wiping is irreversible and permanently deletes all files and settings on the device.',
      },
      {
        header: 'Have questions about MDM actions?',
        description:
          'Feel free to contact our product support team for assistance.',
      },
    ],
  },
};

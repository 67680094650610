import { format } from 'date-fns';

function formatDate(rawDate?: string, formatString = 'MM/dd/yy') {
  if (!rawDate) return '';

  const date = new Date(rawDate);
  return isNaN(date.getTime()) ? '' : format(date, formatString);
}

export default formatDate;

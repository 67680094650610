import { LinearProgress } from '@electricjs/arc';

type ProgressBarProps = {
  isCompleted?: boolean;
  isInProgress?: boolean;
};

const ProgressBar = ({ isCompleted, isInProgress }: ProgressBarProps) => {
  if (isCompleted) {
    return <LinearProgress value={100} intent="success" />;
  }
  if (isInProgress) {
    return <LinearProgress value={50} intent="success" />;
  }
  return <LinearProgress value={0} />;
};

export default ProgressBar;

import {
  ColorsV2,
  Divider,
  Flex,
  IconInCircle,
  Pane,
  Text,
  useThemeColors,
} from '@electricjs/arc';
import { useHashParams } from '@/hooks/useQueryString';
import { NavigationParamKeys } from '@/components/DeviceOverview/types';
import { DeviceHealthMonitorTabSelector } from '@/components/DeviceOverview/DeviceHealthMonitor/DeviceHealthMonitorTabSelector';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useGetOrganizationAssetHistoryQuery } from '@/redux/slices/assetApiSlice';
import { AssetActionEnum, AssetStatus } from '@electricjs/core_entity-client';
import { DeviceLogTab } from '@/components/DeviceOverview/DeviceHealthMonitor/DeviceLogTab';
import { DeviceIssueType } from '@/types/devices';
import {
  getUnresolvedLogsByTypeCount,
  groupByParentAndChildrenSorted,
} from '@/components/DeviceOverview/DeviceHealthMonitor/helpers/AssetHistoryHelpers';

export const DeviceHealthMonitor = () => {
  const { queryParams, updateHashParams } = useHashParams();

  const deviceIssueTab = queryParams.get(NavigationParamKeys.HEALTH_MONITOR);

  const [grayLightColor, iconBackgroundColor] = useThemeColors([
    ColorsV2.GRAY_LIGHT,
    ColorsV2.GRAY_LIGHTER,
  ]);

  const handleTabChange = (tab: string) => {
    updateHashParams(NavigationParamKeys.HEALTH_MONITOR, tab);
  };

  const organizationId = useGetOrganizationId();

  // Get the asset history for the last 30 days
  // We filter out Assign, Unassign, ChangeStatus and ChangeSite actions since these are not really relevant for
  // the device health monitor activity logs because these include keyboards, mice and all kind of assets.
  const { data, isLoading } = useGetOrganizationAssetHistoryQuery({
    organizationId,
    assetActions: [
      AssetActionEnum.DeviceIssueCreated,
      AssetActionEnum.DeviceIssueReminder,
      AssetActionEnum.DeviceIssueResolved,
      AssetActionEnum.DeviceIssueIgnored,
      AssetActionEnum.DeviceWipeRequested,
      AssetActionEnum.DeviceWipeDone,
    ],
    ignoreStatuses: [
      AssetStatus.Archived,
      AssetStatus.Donated,
      AssetStatus.Disposed,
      AssetStatus.Sold,
      AssetStatus.Missing,
    ],
    days: 30,
    limit: 1000,
    ignoreDaysForUnresolvedIssues: true,
  });

  const logs = data?.assetHistory
    ? groupByParentAndChildrenSorted(data?.assetHistory)
    : [];

  const unresolvedLogsByTypeCount = getUnresolvedLogsByTypeCount(logs);

  const tabContent = [
    {
      id: 0,
      icon: 'firewall',
      property: DeviceIssueType.Firewall,
      label: 'Firewall',
      numErrors: unresolvedLogsByTypeCount[DeviceIssueType.Firewall] ?? 0,
    },
    {
      id: 1,
      icon: 'shield-virus',
      property: DeviceIssueType.Gatekeeper,
      label: 'Gatekeeper',
      numErrors: unresolvedLogsByTypeCount[DeviceIssueType.Gatekeeper] ?? 0,
    },
    {
      id: 2,
      icon: 'cog',
      property: DeviceIssueType.OS,
      label: 'OS update',
      numErrors: unresolvedLogsByTypeCount[DeviceIssueType.OS] ?? 0,
    },
    {
      id: 3,
      icon: 'key',
      property: DeviceIssueType.Encryption,
      label: 'Encryption',
      numErrors: unresolvedLogsByTypeCount[DeviceIssueType.Encryption] ?? 0,
    },
    {
      id: 4,
      icon: 'storage',
      property: DeviceIssueType.Storage,
      label: 'Storage',
      numErrors: unresolvedLogsByTypeCount[DeviceIssueType.Storage] ?? 0,
    },
    {
      id: 5,
      icon: 'ram',
      property: DeviceIssueType.RAM,
      label: 'RAM',
      numErrors: unresolvedLogsByTypeCount[DeviceIssueType.RAM] ?? 0,
    },
    {
      id: 6,

      icon: 'plug-circle-xmark',
      property: DeviceIssueType.MdmNotReporting,
      label: 'MDM issues',
      numErrors:
        unresolvedLogsByTypeCount[DeviceIssueType.MdmNotReporting] ?? 0,
    },
    {
      id: 7,
      icon: 'laptop-arrow-down-sharp',
      property: DeviceIssueType.DeviceLock,
      label: 'MDM actions',
      numErrors: unresolvedLogsByTypeCount[DeviceIssueType.DeviceLock] ?? 0,
    },
    /* TODO HY-218 We're temporarily disabling Battery health while we improve the logic behind it */
    // {
    //   id: 7,
    //   icon: 'battery-bolt',
    //   property: DeviceIssueType.BatteryHealth,
    //   label: 'Battery',
    //  numErrors: unresolvedLogsByTypeCount[DeviceIssueType.BatteryHealth] ?? 0
    // },
  ];

  tabContent.sort((a, b) => {
    // Sort by numErrors descending
    if (a.numErrors !== b.numErrors) {
      return b.numErrors - a.numErrors;
    }

    // If numErrors are equal, place MDM issues (id: 6) and MDM actions (id: 7) at the end
    if (a.id === 6 || a.id === 7) return 1; // Move MDM entries down
    if (b.id === 6 || b.id === 7) return -1; // Keep other entries above MDM entries

    // Otherwise, preserve original order
    return a.id - b.id;
  });

  const selectedTab: DeviceIssueType =
    deviceIssueTab &&
    Object.values(DeviceIssueType).some(v => v === deviceIssueTab)
      ? (deviceIssueTab as DeviceIssueType)
      : tabContent[0].property;

  return (
    <Pane elevated flexDirection="column" mt={5}>
      <Flex justifyContent="space-between" width="100%" px="1.5rem" my="1.5rem">
        <Flex gap="1rem" alignItems="center">
          <IconInCircle
            size="3.6rem"
            icon="health-monitor"
            iconSize="small"
            intent="neutral"
            background={iconBackgroundColor}
          />
          <Text variant="heading-2">Device health monitor</Text>
          {/* TODO add search employee bar */}
        </Flex>
      </Flex>
      <Divider color={grayLightColor} />
      <Flex flexDirection="column" width="100%">
        <DeviceHealthMonitorTabSelector
          tabContent={tabContent}
          selectedTab={selectedTab}
          handleTabChange={handleTabChange}
        />

        <Flex
          width="100%"
          minHeight="43rem"
          display="flex"
          flexDirection="column">
          <DeviceLogTab
            selectedTab={selectedTab}
            logs={logs}
            isLoading={isLoading}
          />
        </Flex>
      </Flex>
    </Pane>
  );
};

import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useGetOrganizationQuery } from '@/redux/slices/organizationApiSlice';

const useIntegrationProvider = () => {
  const organizationId = useGetOrganizationId();

  const { hrisIntegrationId, provider, isLoadingProvider } =
    useGetOrganizationQuery(organizationId, {
      selectFromResult: ({ data, isFetching: isLoadingProvider }) => ({
        hrisIntegrationId: data?.hrisIntegration?.id,
        provider: data?.hrisIntegration?.hrisProvider,
        isLoadingProvider,
      }),
    });

  return {
    hrisIntegrationId,
    provider,
    isLoadingProvider,
  };
};

export default useIntegrationProvider;

export enum DeviceDataAction {
  Remove = 'remove',
  Lock = 'lock',
  Keep = 'keep',
}

export enum DeviceRetrieveAction {
  Ship = 'ship',
  InPerson = 'inPerson',
  Keep = 'keep',
  Warehouse = 'warehouse',
}

import { RequestStatusEnum } from '@electricjs/core_entity-client';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { useGlobalUI } from '@/components/GlobalUIProvider';
import { useUpdateRequestStatusMutation } from '@/redux/slices/requestApiSlice';
import { useAppSelector } from '@/redux/store';

type UseUpdateRequestStatusProps = {
  requestId: string;
};

export const useUpdateRequestStatus = ({
  requestId,
}: UseUpdateRequestStatusProps) => {
  const { showErrorToast } = useGlobalUI();
  const organizationId = useAppSelector(
    state => state.loggedUser?.organizationId
  );
  const userId = useAppSelector(state => state.loggedUser?.id);

  const [updateRequestStatus, { isLoading: updateRequestLoading }] =
    useUpdateRequestStatusMutation();

  const handleError = (error: FetchBaseQueryError) => {
    window?.DD_RUM?.addError(
      `Error while updating a request status with requestId: ${requestId} and organizationId: ${organizationId}. Error: ${error}`,
      {
        location: 'Request actions',
      }
    );
    showErrorToast({
      id: 'update-request-error-toast',
      title: 'Unable to update request',
      message:
        'Something went wrong while updating the request. Please try again.',
    });
  };

  type UpdateRequestProps = {
    newStatus: RequestStatusEnum;
  };

  const updateRequest = async ({ newStatus }: UpdateRequestProps) => {
    if (!updateRequestLoading && organizationId && userId) {
      await updateRequestStatus({
        organizationId: organizationId,
        requestId,
        resolvedAt: new Date().toISOString(),
        resolvedByUserId: userId,
        status: newStatus,
      })
        .unwrap()
        .catch(handleError);
    }
  };

  return { updateRequest, updateRequestLoading };
};

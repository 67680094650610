import { Chip, ColorsV2, useThemeColors } from '@electricjs/arc';

type OutlineChipProps = {
  id: string;
  label: string;
  textColor?: string;
  backgroundColor?: string;
  outlineColor?: string;
};

const OutlineChip = ({
  id,
  label,
  textColor,
  backgroundColor,
  outlineColor,
}: OutlineChipProps) => {
  const [defaultTextColor, defaultBackgroundColor, defaultOutlineColor] =
    useThemeColors([
      ColorsV2.PRIMARY,
      ColorsV2.PRIMARY_LIGHTEST,
      ColorsV2.PRIMARY,
    ]);

  if (!label) {
    return <></>;
  }

  return (
    <Chip
      id={id}
      variant="small"
      textColor={textColor || defaultTextColor}
      backgroundColor={backgroundColor || defaultBackgroundColor}
      border={`1.5px solid ${outlineColor || defaultOutlineColor}`}>
      {label}
    </Chip>
  );
};

export default OutlineChip;

import {
  Address,
  Button,
  ColorsV2,
  Flex,
  Skeleton,
  Text,
  getToken,
} from '@electricjs/arc';
import { formatAddress } from 'common/utils/formatAddress';
import { useState } from 'react';
import styled from 'styled-components';

const DetailsContainer = styled(Flex)`
  width: 46rem;
  padding: 1.8rem;
  border-radius: 0.8rem;
  border: 1px solid ${getToken(ColorsV2.BORDER)};
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.8rem;
`;

type ShippingAddressDetailsProps = {
  id: string;
  title: string;
  address: Address | undefined;
  phone: string | undefined;
  isLoading: boolean;
  children: React.ReactNode;
  disabled?: boolean;
  showPhoneNumber?: boolean;
};

const ShippingAddressDetails = ({
  id,
  title,
  address,
  phone,
  isLoading,
  children,
  disabled,
  showPhoneNumber = true,
}: ShippingAddressDetailsProps) => {
  const [isEditing, setIsEditing] = useState(false);

  const emptyAddress =
    Object.values(address || {}).filter(Boolean).length === 0;

  if ((isEditing || emptyAddress || !phone) && !isLoading && !disabled) {
    return <>{children}</>;
  }

  const shippingInfo = [formatAddress(address), showPhoneNumber && phone]
    .filter(Boolean)
    .join(', ');

  return (
    <DetailsContainer>
      <Flex vertical rowGap={isLoading ? '0.5rem' : 0}>
        {isLoading ? (
          <Skeleton width="14rem" height="1.8rem" />
        ) : (
          <Text variant="label-small">{title}</Text>
        )}
        <Flex vAlignContent="center" columnGap="1rem">
          {isLoading ? (
            <Skeleton width="28rem" height="1.8rem" />
          ) : (
            <Text variant="body-2" mt="0.4rem">
              {shippingInfo}
            </Text>
          )}
        </Flex>
      </Flex>
      {!disabled && (
        <Button
          id={`edit-shipping-address-button-${id}`}
          variant="text"
          onClick={() => setIsEditing(true)}
          disabled={isLoading}>
          Edit
        </Button>
      )}
    </DetailsContainer>
  );
};

export default ShippingAddressDetails;

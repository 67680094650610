import { Application } from '@/types/applications';
import {
  IntegrationCard,
  IntegrationCardProps,
} from '@/components/Settings/IntegrationsPane/IntegrationCard/IntegrationCard';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import {
  useAddOrganizationApplicationMutation,
  useGetOrganizationApplicationsQuery,
} from '@/redux/slices/organizationApiSlice';
import { useIntegrationConnect } from '@/components/Integrations/IntegrationConnectButton';
import { DisconnectIntegrationModal } from '@/components/Settings/IntegrationsPane/DisconnectIntegrationModal';
import { useGlobalUI } from '@/components/GlobalUIProvider';
import { GoogleAppIntegrationCard } from '@/components/Settings/IntegrationsPane/GoogleAppIntegrationCard';
import { Flex } from '@electricjs/arc';
import { useLazyGetIntegrationSupportQuery } from '@/redux/slices/integrationsApiSlice';
import {
  IntegrationAvailabilityEnum,
  IntegrationType,
} from '@electricjs/core_entity-client';
import { useEffect } from 'react';

const CustomIntegrationMap: {
  [key: string]: (props: IntegrationCardProps) => JSX.Element;
} = {
  'google-workspace': GoogleAppIntegrationCard,
} as const;

type ApplicationIntegrationCardProps = {
  application: Application;
};

export const ApplicationIntegrationCard = ({
  application,
}: ApplicationIntegrationCardProps) => {
  const { id: applicationId, slug, name, icon, url } = application;

  const organizationId = useGetOrganizationId();

  const { organizationApplication } = useGetOrganizationApplicationsQuery(
    {
      organizationId,
      limit: 1000,
    },
    {
      selectFromResult: ({ data }) => ({
        organizationApplication: data?.applications.find(
          orgApp => orgApp.application.slug === slug
        ),
      }),
    }
  );

  const [getIntegrationSupport, { isLoading }] =
    useLazyGetIntegrationSupportQuery();

  const { openModal, closeModal, showErrorToast } = useGlobalUI();

  const currentIntegrationSlug = organizationApplication?.integrationConnected
    ? slug
    : undefined;

  const { handleOAuthFlow, handleNangoAuthFlow } = useIntegrationConnect({
    appSlug: slug,
    integrationName: name,
  });

  const [addApplication] = useAddOrganizationApplicationMutation();

  const handleConnectionRequest = async () => {
    // if not orgApp, then create orgApp
    if (!organizationApplication) {
      try {
        await addApplication({
          organizationId,
          application: {
            applicationId,
          },
        }).unwrap();
      } catch (error) {
        window?.DD_RUM?.addError(
          `Failed to connect the application integration. Error details: ${error}`,
          {
            location: 'ApplicationIntegrationCard',
          }
        );
        showErrorToast({
          id: 'connect-application-error-toast',
          message: 'Something went wrong, please try again.',
        });
        return;
      }
    }

    const integrationSupport = await getIntegrationSupport({
      applicationKey: slug,
      organizationId: organizationId,
    }).unwrap();

    // start
    integrationSupport?.type === IntegrationType.Nango
      ? handleNangoAuthFlow()
      : handleOAuthFlow();
  };

  useEffect(() => {
    document.body.style.cursor = isLoading ? 'wait' : 'default';
    return () => {
      document.body.style.cursor = 'default'; // Cleanup on unmount
    };
  }, [isLoading]);

  const handleDisconnectionRequest = () => {
    openModal(
      <DisconnectIntegrationModal
        applicationKey={slug}
        organizationId={organizationId}
        onClose={closeModal}
      />
    );
  };

  const IntegrationCardComponent =
    CustomIntegrationMap[slug] ?? IntegrationCard;

  const integrationCard = (
    <IntegrationCardComponent
      key={slug}
      id={slug}
      name={name}
      customIcon={icon}
      integrationSettingPage={
        organizationApplication?.integrationConnected
          ? `/applications/${slug}`
          : ''
      }
      homepage={!organizationApplication?.integrationConnected ? url : ''}
      description={`Automate creating and removing ${name} accounts for onboarding & offboarding.`}
      handleConnectionRequest={handleConnectionRequest}
      handleDisconnectionRequest={handleDisconnectionRequest}
      currentIntegrationSlug={currentIntegrationSlug}
      needsUpgrade={false}
      isConnected={currentIntegrationSlug === slug}
      isBeta={
        application.integrationAvailability === IntegrationAvailabilityEnum.Beta
      }
      isInternal={
        application.integrationAvailability ===
        IntegrationAvailabilityEnum.Internal
      }
    />
  );

  if (CustomIntegrationMap[slug]) {
    return integrationCard;
  }

  return <Flex width="34rem">{integrationCard}</Flex>;
};

import { isFunction, kebabCase } from 'lodash';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  Box,
  ColorsV2,
  Flex,
  Icon,
  IconNames,
  Skeleton,
  Text,
  getToken,
} from '@electricjs/arc';
import safelyOpenExternalURL from 'common/safelyOpenExternalURL';

type MenuCardProps = {
  icon: IconNames | string;
  customIcon?: string;
  title: string;
  newTab?: boolean;
  description: string;
  id?: string;
  disabled?: boolean;
  href?: string;
  onClick?: () => void;
};

const CardIcon = styled(Flex)`
  width: 3rem;
  height: 3rem;
  padding: 2.2rem;
  align-items: center;
  justify-content: center;
  margin-top: -1.4rem;
  margin-left: -1.1rem;
  border-radius: 2.2rem;
`;

const CardContainer = styled(Box)`
  cursor: ${props => (props.$isSkeleton ? 'default' : 'pointer')};
  flex-basis: 45%;
  padding: 2.8rem 2.2rem 1.8rem;
  text-align: left;
  text-decoration: none;
  border: 0.1rem solid ${getToken(ColorsV2.BORDER)};
  border-radius: 1.6rem;
  box-shadow: 0 0 1.2rem ${getToken(ColorsV2.GRAY_LIGHT)};
  position: relative;
  height: 100%;
  ${props => props.$disabled && 'background-color:' + getToken(ColorsV2.GRAY)};
  ${props => props.$disabled && 'pointer-events: none'};
  :hover {
    ${CardIcon} {
      background-color: ${getToken(ColorsV2.PRIMARY_LIGHTEST)};
    }
  }
`;

const CardMaintenance = styled(Text)`
  position: absolute;
  top: 2.8rem;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: bold;
`;

const DEFAULT_ICON_SIZE = 30;

export const MenuCard = ({
  icon,
  customIcon,
  title,
  description,
  newTab,
  id,
  disabled,
  href,
  onClick,
}: MenuCardProps) => {
  const navigate = useNavigate();

  const content = (
    <CardContainer $disabled={disabled}>
      <CardIcon>
        {/* The customIcon value takes precedence over the icon value. If no
        custom icon is provided, the icon value is used by default. */}
        {customIcon ? (
          <img
            alt={`${kebabCase(title)}-logo`}
            src={customIcon}
            width={DEFAULT_ICON_SIZE}
            height={DEFAULT_ICON_SIZE}
          />
        ) : (
          <Icon
            icon={icon as IconNames}
            intent="primary"
            customSize={DEFAULT_ICON_SIZE}
          />
        )}
      </CardIcon>
      <Text variant="subheading" mt={5} style={{ textTransform: 'capitalize' }}>
        {title}
      </Text>
      <Text color={ColorsV2.GRAY_DARK} fontSize={3} mt={2} lineHeight={3}>
        {description}
      </Text>
      {disabled && (
        <CardMaintenance intent="error">
          This option is under maintenance
        </CardMaintenance>
      )}
    </CardContainer>
  );

  if (!href) {
    const onClickFn = isFunction(onClick) ? onClick : undefined;
    return <Box onClick={onClickFn}>{content}</Box>;
  }

  if (newTab) {
    return (
      <Box id={id} onClick={() => safelyOpenExternalURL(href)}>
        {content}
      </Box>
    );
  } else {
    return (
      <Box id={id} onClick={() => navigate(href)}>
        {content}
      </Box>
    );
  }
};

export const MenuCardSkeleton = () => {
  return (
    <CardContainer $isSkeleton>
      <Skeleton circle height={DEFAULT_ICON_SIZE} width={DEFAULT_ICON_SIZE} />
      <Text variant="heading-3" mt={5}>
        <Skeleton width="75%" />
      </Text>
      <Text mt={2} lineHeight={3}>
        <Skeleton count={2} />
      </Text>
    </CardContainer>
  );
};

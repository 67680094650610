import { CSSProperties } from 'react';

import {
  Banner,
  Box,
  ColorsV2,
  OneColumnLayout,
  useThemeColors,
} from '@electricjs/arc';
import { useRouteError } from 'react-router-dom';

const ErrorTypes = {
  UNKNOWN: 'unknown',
  NEW_VERSION: 'newVersion',
};

function getErrorType(error: unknown) {
  if (
    error &&
    error instanceof Error &&
    (error?.message.includes('Failed to fetch dynamically imported module:') ||
      error?.message.includes('Importing a module script failed'))
  ) {
    return ErrorTypes.NEW_VERSION;
  }
  return ErrorTypes.UNKNOWN;
}

export const ErrorPage = () => {
  const [backgroundColor] = useThemeColors([ColorsV2.BACKGROUND]);
  const errorContainerStyles: CSSProperties = {
    textAlign: 'center',
    maxWidth: '600px',
    margin: '3rem auto',
    backgroundColor: backgroundColor,
  };

  const error = useRouteError();
  const errorType = getErrorType(error);

  // Error handling specific to when a new version is deployed mid-session for a user
  if (errorType === ErrorTypes.NEW_VERSION) {
    window?.location.reload();
    // While browser is reloading the page, render this temporary warning
    return (
      <Box backgroundColor={backgroundColor} overflow="hidden">
        <OneColumnLayout>
          <div style={errorContainerStyles}>
            <Banner
              id="new-version-banner"
              isFlat
              intent="warning"
              message="A new version of Electric was detected, reloading the page..."
            />
          </div>
        </OneColumnLayout>
      </Box>
    );
  }

  return (
    <Box backgroundColor={backgroundColor} overflow="hidden">
      <OneColumnLayout>
        <div style={errorContainerStyles}>
          <Banner
            id="general-error-banner"
            isFlat
            intent="error"
            title="There was an error"
            message="Something went wrong, please refresh the page."
            cta="Refresh"
            onClickCta={() => window?.location.reload()}
          />
        </div>
      </OneColumnLayout>
    </Box>
  );
};

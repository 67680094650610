import { Flex, TabPanel, useBreakpoint } from '@electricjs/arc';

import { Asset, isDeviceAsset } from '@/types/assets';
import DeviceDetailsCard from '@/pages/Assets/AssetDetailsContent/DeviceDetailsCard';
import AssetDetailsCard from '@/pages/Assets/AssetDetailsContent/AssetDetailsCard';
import AssetNotesCard from '@/pages/Assets/AssetDetailsContent/AssetNotesCard';
import AssetInternalNotesCard from '@/pages/Assets/AssetDetailsContent/AssetInternalNotesCard';
import { useUserHasOrganizationPermission } from '@/hooks/useUserHasOrganizationPermission';
import { useOrganizationHasEntitlement } from '@/hooks/useOrganizationHasEntitlement';
import {
  EntitlementSlugEnum,
  PermissionEnum,
} from '@electricjs/core_entity-client';
import { IssueCheckDisabledBanner } from '@/components/Assets/AssetDetails/IssueCheckDisabledBanner';

type AssetDetailsPanelProps = {
  asset: Asset;
};

const AssetDetailsPanel = ({ asset }: AssetDetailsPanelProps) => {
  const { lg } = useBreakpoint();
  const canReadInternalNotes = useUserHasOrganizationPermission(
    PermissionEnum.Warehousingread
  );
  const hasMdmEntitlement = useOrganizationHasEntitlement(
    EntitlementSlugEnum.JumpcloudMdm
  );
  const hasElectricAppEntitlement = useOrganizationHasEntitlement(
    EntitlementSlugEnum.DesktopApp
  );

  const hasNotes = !!asset?.notes;
  const hasInternalNotes = !!asset?.internalNotes;

  const hasDeviceInfo = isDeviceAsset(asset);
  const shouldShowDeviceDetails =
    (hasMdmEntitlement || hasElectricAppEntitlement) && hasDeviceInfo;
  return (
    <TabPanel>
      <IssueCheckDisabledBanner reportingData={asset.device?.reportingData} />
      <Flex
        vertical={!lg || hasNotes}
        mt="3rem"
        gap="2.4rem"
        alignItems="stretch">
        {shouldShowDeviceDetails && (
          <DeviceDetailsCard deviceAsset={asset} fullWidth={hasNotes} />
        )}
        <Flex
          vertical={!lg}
          width={
            !lg ||
            hasNotes ||
            (canReadInternalNotes && hasInternalNotes) ||
            hasDeviceInfo
              ? '100%'
              : '50%'
          }
          gap="1.5rem"
          alignItems="stretch">
          <AssetDetailsCard asset={asset} />
          {hasNotes && <AssetNotesCard value={asset?.notes} />}
          {canReadInternalNotes && hasInternalNotes && (
            <AssetInternalNotesCard value={asset?.internalNotes} />
          )}
        </Flex>
      </Flex>
    </TabPanel>
  );
};

export default AssetDetailsPanel;

import { Address, Flex } from '@electricjs/arc';
import DeviceData from './DeviceData';
import { useState } from 'react';
import DeviceRetrieval from './DeviceRetrieval';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SelectionParamKeys } from '@/types/queryParamKeys';
import { Employee } from '@/types/employees';
import DeviceShipping from './DeviceRetrieval/DeviceShipping';
import { deviceShippingSchema } from './DeviceRetrieval/DeviceRetrievalSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { DeviceDataAction, DeviceRetrieveAction } from './types';

import { SiteOption } from '../Assets/helpers/transformOptions';
import { Asset } from '@/types/assets';

type DeviceOffboardingFormData = {
  deviceRetrieveAction: DeviceRetrieveAction | null;
  deviceDataAction: DeviceDataAction | null;
  senderAddress?: Address | null;
  senderPhone?: string;
  receiverSite?: SiteOption | null;
  receiverPhone?: string;
};

type DevicesProps = {
  assets: Asset[];
  employee: Employee;
};

enum DevicesSteps {
  Retrieval = 1,
  Data = 2,
  Shipping = 3,
}

const Devices = ({ assets, employee }: DevicesProps) => {
  const [currentStep, setCurrentStep] = useState(DevicesSteps.Retrieval);
  const [currentDevice, setCurrentDevice] = useState(0);

  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const employeeId = queryParams.get(SelectionParamKeys.EmployeeId);

  const methods = useForm<DeviceOffboardingFormData>({
    resolver: yupResolver(deviceShippingSchema),
    mode: 'onTouched',
    defaultValues: {
      deviceRetrieveAction: null,
      deviceDataAction: null,
      senderAddress: {
        streetAddress1: '',
        streetAddress2: '',
        city: '',
        state: '',
        country: '',
        zip: '',
      },
      senderPhone: '',
      receiverSite: null,
      receiverPhone: '',
    },
  });

  const deviceRetrieveAction = methods.watch('deviceRetrieveAction');
  const shouldShowShippingStep =
    deviceRetrieveAction === DeviceRetrieveAction.Ship ||
    deviceRetrieveAction === DeviceRetrieveAction.Warehouse;

  const handlePrevious = () => {
    const hasComeByNavigation = history.state.idx > 0;

    // If the user is at the Data step, go back to the Retrieval step for the same device
    if (currentStep === DevicesSteps.Data) {
      setCurrentStep(DevicesSteps.Retrieval);
      return;
    }

    if (currentStep === DevicesSteps.Shipping) {
      setCurrentStep(DevicesSteps.Data);
      return;
    }

    // If the user is on the Data step of the first device on the list and has navigated to the page,
    // go back to the previous page, otherwise go to the employee's profile page
    if (hasComeByNavigation) {
      navigate(-1);
      return;
    }

    navigate(`/employees/${employeeId}`);
  };

  const handleNext = () => {
    const isLastDevice = currentDevice === assets.length - 1;

    // If the user is at the Retrieval step, move to the device Data step
    if (currentStep === DevicesSteps.Retrieval) {
      setCurrentStep(DevicesSteps.Data);
      return;
    }

    // We only show the Shipping step if one of the shipping options was selected
    if (currentStep === DevicesSteps.Data && shouldShowShippingStep) {
      setCurrentStep(DevicesSteps.Shipping);
      return;
    }

    // If the user is at the Data step and the current device is the last one,
    // navigate to the employee's profile page
    if (isLastDevice) {
      navigate(`/employees/${employeeId}`);
      return;
    }

    // Otherwise, reset the form and move to the Retrieval step for the next device
    methods.reset();
    setCurrentDevice(prev => prev + 1);
    setCurrentStep(DevicesSteps.Retrieval);
  };

  return (
    <Flex width="100%">
      <FormProvider {...methods}>
        {currentStep === DevicesSteps.Retrieval && (
          <DeviceRetrieval
            asset={assets[currentDevice]}
            onNext={handleNext}
            onPrevious={handlePrevious}
            shouldDisablePrevious={currentDevice !== 0}
          />
        )}
        {currentStep === DevicesSteps.Data && (
          <DeviceData
            asset={assets[currentDevice]}
            employee={employee}
            onNext={handleNext}
            onPrevious={handlePrevious}
          />
        )}
        {currentStep === DevicesSteps.Shipping && (
          <DeviceShipping
            asset={assets[currentDevice]}
            onNext={handleNext}
            onPrevious={handlePrevious}
          />
        )}
      </FormProvider>
    </Flex>
  );
};

export default Devices;

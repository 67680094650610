import { LinkNoUnderline } from '@common';
import { Button } from '@electricjs/arc';

export const ContactProductSupportButton = () => {
  return (
    <LinkNoUnderline
      href="mailto:product-support@electric.ai"
      rel="noopener noreferrer"
      target="_blank">
      <Button variant="outline" id="contact-product-support-button">
        Contact support
      </Button>
    </LinkNoUnderline>
  );
};

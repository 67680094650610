import { Asset } from './assets';
import { Group } from './groups';
import { OrganizationUserRoles } from './users';
import { Address } from '@electricjs/arc';

export type Employee = {
  id: string;
  firstName: string;
  lastName: string;
  email: string | null;
  personalEmail?: string;
  jobTitle?: string;
  userId?: string;
  /** @deprecated */
  devices?: Array<Record<string, string>>;
  createdAt?: string;
  updatedAt?: string;
  status: EmployeeStatus;
  /** startDate  can't be a Date object inside Redux. It needs to be serializable (therefore, an ISO string) */
  startDate?: string;
  source: EmployeeSource;
  hrisIntegration?: HRISEmployeeIntegration;
  roles: OrganizationUserRoles;
  address?: Address;
  phone?: string;
};

export type EmployeeWithAssets = Employee & {
  assets: Asset[];
};

export type EmployeeWithGroups = Employee & {
  groups: Group[];
  sortGroupName: string; // Which group name to sort by
};

export type EmployeeCount = {
  isEmployeeCountLoading: boolean;
  /** The total amount of ACTIVE (billable) employees of an organization */
  employeeCount: number;
};

export type EmployeeWithRequiredEmail = Employee & { email: string };

export type DesktopAppInstructionsArgs = {
  employeeIds: string[];
  organizationId: string;
};

export type MDMEnrollInstructionsArgs = {
  employeeIds: string[];
  organizationId: string;
};

export enum EmployeeStatus {
  Unknown = 'UNKNOWN',
  Unmanaged = 'UNMANAGED',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Onboarding = 'ONBOARDING',
  Offboarding = 'OFFBOARDING',
}

export enum EmployeeSource {
  Manual = 'MANUAL',
  CsvUpload = 'CSV_UPLOAD',
  Hris = 'HRIS',
}

export type EmployeeUpdateArgs = {
  id: string;
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  status?: EmployeeStatus;
  email?: string;
  personalEmail?: string;
  startDate?: string;
  address?: Address | null;
  phone?: string | null;
};

export type EmployeeStatusUpdateArgs = {
  id: string;
  status: EmployeeStatus;
};

export type HRISEmployeeIntegration = {
  sourceId: string;
};

export type GetEmployeeByEmailArgs = {
  organizationId: string;
  email: string;
};

import {
  ADPCredentialsExist,
  CoreEntityDomainsOrganizationsEntrypointsApiV1SchemasResponseHRISProvider as HRISProviderDto,
  HRISIssues as HRISIssuesDto,
} from '@electricjs/core_entity-client';

import { apiSlice } from '@/redux/slices/apiSlice';
import { TagTypes } from '@/redux/slices/apiSlice';
import {
  CreateHrisIntegrationArgs,
  CreateLinkTokenArgs,
  CreateLinkTokenResponse,
  HrisIntegrationIssue,
  ManageHrisIntegrationArgs,
  UpdateHrisIntegrationArgs,
  SyncHRISEmployeeArgs,
} from '@/types/hrisIntegration';

import {
  invalidatesList,
  invalidatesListAndById,
  providesListAndAllById,
} from './helpers/cacheTagHelpers';

export const hrisIntegrationApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    createLinkToken: builder.mutation<string, CreateLinkTokenArgs>({
      query: ({ organizationId, integration }) => {
        return {
          url: `/v1/organizations/${organizationId}/hris_integrations/link_token`,
          method: 'POST',
          body: {
            integration,
          },
        };
      },
      transformResponse: (response: CreateLinkTokenResponse) => {
        return response.link_token;
      },
    }),
    createHrisIntegration: builder.mutation<void, CreateHrisIntegrationArgs>({
      query: ({ organizationId, publicToken }) => ({
        url: `/v1/organizations/${organizationId}/hris_integrations`,
        method: 'POST',
        body: {
          public_token: publicToken,
        },
      }),
      invalidatesTags: (_results, _error, args) =>
        invalidatesListAndById(TagTypes.Organization, args.organizationId),
    }),
    updateHrisIntegration: builder.mutation<void, UpdateHrisIntegrationArgs>({
      query: ({ organizationId, publicToken, hrisIntegrationId }) => ({
        url: `/v1/organizations/${organizationId}/hris_integrations/${hrisIntegrationId}`,
        method: 'PATCH',
        body: {
          public_token: publicToken,
        },
      }),
      invalidatesTags: (_results, _error, args) => {
        return [
          ...invalidatesListAndById(TagTypes.Organization, args.organizationId),
          ...invalidatesList(TagTypes.HrisIntegrationIssue),
        ];
      },
    }),
    deleteHrisIntegration: builder.mutation<void, ManageHrisIntegrationArgs>({
      query: ({ organizationId, hrisIntegrationId }) => ({
        url: `/v1/organizations/${organizationId}/hris_integrations/${hrisIntegrationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_results, _error, args) =>
        invalidatesListAndById(TagTypes.Organization, args.organizationId),
    }),
    syncHrisIntegration: builder.mutation<void, ManageHrisIntegrationArgs>({
      query: ({ organizationId, hrisIntegrationId }) => ({
        url: `/v1/organizations/${organizationId}/hris_integrations/${hrisIntegrationId}/sync`,
        method: 'POST',
      }),
      invalidatesTags: (_results, _error, args) => {
        return [
          ...invalidatesListAndById(TagTypes.Organization, args.organizationId),
          TagTypes.Employee,
          TagTypes.EmployeeWithDevice,
        ];
      },
    }),
    getHrisIntegrationIssues: builder.query<
      HrisIntegrationIssue[],
      ManageHrisIntegrationArgs
    >({
      query: ({ organizationId, hrisIntegrationId }) => ({
        url: `/v1/organizations/${organizationId}/hris_integrations/${hrisIntegrationId}/issues`,
        method: 'GET',
      }),
      transformResponse: (response: HRISIssuesDto) => {
        return (
          response.issues.map(issue => {
            return {
              id: issue.id ?? '',
              status: issue.status ?? '',
              errorDescription: issue.error_description,
              errorDetails: issue.error_details ?? [],
            };
          }) ?? []
        );
      },
      providesTags: providesListAndAllById(TagTypes.HrisIntegrationIssue),
    }),
    listHrisProviders: builder.query<HRISProviderDto[], void>({
      query: () => ({
        url: '/v1/hris_providers',
        method: 'GET',
      }),
      providesTags: providesListAndAllById(TagTypes.HrisProvider),
    }),
    demoSyncHRISEmployee: builder.mutation<void, SyncHRISEmployeeArgs>({
      query: ({
        organizationId,
        hrisProviderSlug,
        hrisEmployee,
        isOnboarding,
        isOffboarding,
      }) => ({
        url: `/v1/organizations/${organizationId}/hris_integrations/demo_sync_employee`,
        method: 'POST',
        body: {
          hris_provider_slug: hrisProviderSlug,
          hris_employee: {
            source_id: hrisEmployee.sourceId,
            remote_id: hrisEmployee.remoteId || '',
            first_name: hrisEmployee.firstName,
            last_name: hrisEmployee.lastName,
            job_title: hrisEmployee.jobTitle || '',
            work_email: hrisEmployee.workEmail || '',
            personal_email: hrisEmployee.personalEmail || '',
            status: hrisEmployee.status,
            start_date: hrisEmployee.startDate || undefined,
            remote_created_at: hrisEmployee.remoteCreatedAt || undefined,
            address: hrisEmployee.address && {
              street_1: hrisEmployee.address?.streetAddress1 || '',
              street_2: hrisEmployee.address?.streetAddress2 || '',
              city: hrisEmployee.address?.city || '',
              state: hrisEmployee.address?.state || '',
              country: hrisEmployee.address?.country || '',
              zip_code: hrisEmployee.address?.zip || '',
            },
            termination_date: hrisEmployee.terminationDate || undefined,
          },
          is_onboarding: isOnboarding,
          is_offboarding: isOffboarding,
        },
      }),
      invalidatesTags: (_result, _error, args) => [
        ...invalidatesList(TagTypes.Request),
        ...invalidatesList(TagTypes.Offboarding),
        {
          type: TagTypes.LatestOffboardingByEmployeeId,
          id: args.hrisEmployee.sourceId,
        },
        TagTypes.Employee, // to refresh all employee data (active, inactive, dropdown lists)
      ],
    }),
    fetchADPCredentials: builder.query<boolean, ManageHrisIntegrationArgs>({
      query: args => ({
        url: `/v1/organizations/${args.organizationId}/hris_integrations/${args.hrisIntegrationId}/adp_credentials/fetch`,
        method: 'GET',
      }),
      transformResponse: (response: ADPCredentialsExist) => response.exists,
    }),
  }),
});

export const {
  useCreateLinkTokenMutation,
  useCreateHrisIntegrationMutation,
  useDeleteHrisIntegrationMutation,
  useUpdateHrisIntegrationMutation,
  useSyncHrisIntegrationMutation,
  useLazyGetHrisIntegrationIssuesQuery,
  useFetchADPCredentialsQuery,
  useListHrisProvidersQuery,
  useDemoSyncHRISEmployeeMutation,
} = hrisIntegrationApiSlice;

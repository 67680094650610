import { ProductTypeName } from '@electricjs/core_entity-client';
import { Button, Flex } from '@electricjs/arc';
import { FilterKeyEnum, type DynamicFilters } from '@/types/storefront';

import ProductChipFilter from './ProductChipFilter';

type ProductChipFilterGroupProps = {
  currentFilters?: DynamicFilters;
  onFilterSelect: (value: string[], key: FilterKeyEnum) => void;
  onClearFilters: () => void;
  isKeyboardMouseGroup: boolean;
  onProductTypesSelect: (types: ProductTypeName[]) => void;
};

const ProductChipFilterGroup = ({
  currentFilters,
  onFilterSelect,
  onClearFilters,
  isKeyboardMouseGroup,
  onProductTypesSelect,
}: ProductChipFilterGroupProps) => {
  const hasFilters = Object.keys(currentFilters ?? {}).length > 0;

  return (
    <>
      {currentFilters && (
        <Flex gap="2rem" wrap alignItems="center">
          {(Object.keys(currentFilters) as FilterKeyEnum[]).map(filterKey => {
            const values = currentFilters[filterKey];
            return values?.map(value => {
              return (
                <ProductChipFilter
                  key={value}
                  filter={value}
                  filterKey={filterKey}
                  selectedFilters={values}
                  onFilterSelect={onFilterSelect}
                  isKeyboardMouseGroup={isKeyboardMouseGroup}
                  onProductTypesSelect={onProductTypesSelect}
                />
              );
            });
          })}
          {hasFilters && (
            <Button
              id="clear-all-button"
              variant="text"
              onClick={() => onClearFilters()}>
              Clear all
            </Button>
          )}
        </Flex>
      )}
    </>
  );
};

export default ProductChipFilterGroup;

function formatCurrency(
  price?: number | string,
  locale = 'en-US',
  style: keyof Intl.NumberFormatOptionsStyleRegistry = 'currency',
  currency = 'USD'
) {
  if (price === null || price === undefined || price === '') return '';
  return Number(price).toLocaleString(locale, {
    style,
    currency,
  });
}

export default formatCurrency;

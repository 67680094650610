import { NotificationType } from '@electricjs/core_entity-client';

import { Flex, Text } from '@electricjs/arc';

import ApplicationIcon from '@/components/Applications/ApplicationIcon';
import { useGetRequestData } from '@/hooks/useGetRequestData';
import { Application } from '@/types/applications';
import { Employee } from '@/types/employees';
import { RequestTypeSlugEnum } from '@/types/requests';

import { EmailDetail } from '../EmailDetail';
import { GroupDetail } from '../GroupDetail';
import { NotificationIcon } from '../NotificationIcon';
import { NotificationItemHeader } from '../NotificationItemHeader';
import { RequestActions } from '../RequestActions/RequestActions';
import { NotificationItemProps } from '../types';

const getTitle = (
  employee?: Employee,
  application?: Application,
  requestTypeSlug?: string,
  notificationType?: NotificationType
) => {
  // Title
  const employeeName = employee
    ? `${employee.firstName} ${employee.lastName}`
    : 'An Employee';
  const applicationName = application ? application.name : 'an application';

  switch (requestTypeSlug) {
    case RequestTypeSlugEnum.ONBOARDING_CREATE_ACCOUNT:
      return (
        <Text variant="legal">
          {notificationType === NotificationType.RequestAutomationFailed && (
            <Text variant="legal">
              <b>Automated Task Failed:</b> Please complete manually
              <br />
            </Text>
          )}
          <b>{employeeName}</b> requires access to <b>{applicationName}</b>
        </Text>
      );
    case RequestTypeSlugEnum.OFFBOARDING_REMOVE_ACCOUNT:
      return (
        <Text variant="legal">
          Revoke access to <b>{applicationName}</b> for <b>{employeeName}</b>
        </Text>
      );
    default:
      // This should never happen
      return (
        <Text variant="legal">
          <b>{employeeName}</b> requires assistance with{' '}
          <b>{applicationName}</b>
        </Text>
      );
  }
};

export const ApplicationRequestActionsColumn = ({
  notification,
}: NotificationItemProps) => {
  const { data: requestData, isLoading } = useGetRequestData({
    requestId: notification?.data?.request_id ?? null,
  });

  return (
    <RequestActions
      loading={isLoading}
      request={requestData.request}
      requestType={requestData.requestType}
      isDropDownMenu={false}
    />
  );
};

export const ApplicationRequestDetailsColumns = ({
  notification,
}: NotificationItemProps) => {
  const { data: requestData, isLoading } = useGetRequestData({
    requestId: notification?.data?.request_id ?? null,
  });

  const employeeGroups = requestData.employeeGroups;

  const employeeEmail = requestData?.employee?.email;

  return (
    <Flex flexDirection="column">
      <GroupDetail isLoading={isLoading} groups={employeeGroups} />
      <EmailDetail isLoading={isLoading} email={employeeEmail} />
    </Flex>
  );
};

const ApplicationRequestNotificationItem = ({
  notification,
  hideDetails,
}: NotificationItemProps) => {
  const { data: requestData, isLoading } = useGetRequestData({
    requestId: notification?.data?.request_id ?? null,
  });

  const employee = requestData?.employee;

  const employeeGroups = requestData?.employeeGroups;

  const employeeEmail = employee?.email;

  const application = requestData?.application;

  const getLabel = () => {
    switch (notification.type) {
      case NotificationType.RequestAssigned:
        return 'REQUEST ASSIGNED';

      case NotificationType.RequestCompleted:
        return 'REQUEST COMPLETED';

      case NotificationType.RequestAutomationFailed:
        return 'REQUEST ASSIGNED';
      default:
        return '';
    }
  };

  const applicationIcon = () =>
    application ? (
      <ApplicationIcon
        name={application.name}
        icon={application.icon}
        size="2.6rem"
      />
    ) : (
      'app'
    );

  const getIcon = () => (
    <NotificationIcon
      isNotificationUnseen={!notification.seenAt}
      loading={isLoading}
      icon={applicationIcon()}
    />
  );

  return (
    <NotificationItemHeader
      notification={notification}
      isLoading={isLoading}
      title={getTitle(
        employee,
        requestData?.application,
        requestData?.requestType?.slug,
        notification.type
      )}
      icon={getIcon()}
      label={getLabel()}>
      {!hideDetails ? (
        <>
          <GroupDetail isLoading={isLoading} groups={employeeGroups} />
          <EmailDetail isLoading={isLoading} email={employeeEmail} />
          <RequestActions
            loading={isLoading}
            request={requestData.request}
            requestType={requestData.requestType}
          />
        </>
      ) : null}
    </NotificationItemHeader>
  );
};

export default ApplicationRequestNotificationItem;

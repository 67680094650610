import MdmSetupStep3Img from '@/assets/mdm_apple_setup_step_3.png';
import MdmSetupStep3ImgRenew from '@/assets/mdm_apple_setup_step_3_renew.png';

import { Text } from '@electricjs/arc';
import { StepContainer } from '@/components/MDMEnrollment/MDMAppleForm/StepComponents';

type UploadCsrCertificateStepProps = {
  isRenewing?: boolean;
};

export const UploadCsrCertificateStep = ({
  isRenewing,
}: UploadCsrCertificateStepProps) => {
  return (
    <StepContainer
      step={3}
      image={
        isRenewing
          ? { scr: MdmSetupStep3ImgRenew, alt: 'Apple Renew Certificate' }
          : { scr: MdmSetupStep3Img, alt: 'Apple Create Certificate' }
      }>
      <Text variant="label-large">
        Upload your CSR and download the New Push Certificate
      </Text>
      {isRenewing ? (
        <Text variant="body">
          On the &quot;Renew Push Certificate&quot; page, upload your CSR.
        </Text>
      ) : (
        <Text variant="body">
          On the &quot;Create a New Push Certificate&quot; page, upload your
          CSR.
        </Text>
      )}
      <Text variant="body">
        Enter your company&apos;s Apple ID. This is the same account that you
        used to log in to the Apple Push Certificates Portal.
      </Text>
      <Text variant="body">
        Click &quot;Download&quot; to download the new certificate. You&apos;ll
        need this for step 5.
      </Text>
    </StepContainer>
  );
};

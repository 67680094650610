import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { EssentialActionsKeys } from '@/components/YourItPlan/EssentialActions';
import { SecurityActionsKeys } from '@/components/YourItPlan/SecurityPlanActions';
import { SuggestedActionKeys } from '@/components/YourItPlan/SuggestedActions';
import { LocalStorageKeys } from '@/constants/localStorageKeys';

import {
  getDataFromLocalStorage,
  setDataToLocalStorage,
} from '../../../common/useStoredState';

type ActionKey = EssentialActionsKeys | SuggestedActionKeys;

type ActionCompleted = Partial<
  Record<EssentialActionsKeys | SecurityActionsKeys, boolean>
>;

type ActionsDismissed = Partial<Record<ActionKey, boolean>>;

type YourItPlanState = {
  actionsDismissed: ActionsDismissed;
  actionsCompleted: ActionCompleted;
};

const initialState: YourItPlanState = {
  actionsDismissed:
    getDataFromLocalStorage(LocalStorageKeys.YOUR_IT_PLAN_STATE) ?? {},
  actionsCompleted: {},
};

const yourItPlanSlice = createSlice({
  name: 'yourItPlanState',
  initialState: initialState,
  reducers: {
    actionCompleted: (
      state,
      action: PayloadAction<{
        actionKey: EssentialActionsKeys | SecurityActionsKeys;
        isCompleted: boolean;
      }>
    ) => {
      const { actionKey, isCompleted } = action.payload;

      if (state.actionsCompleted[actionKey] !== isCompleted) {
        state.actionsCompleted[actionKey] = isCompleted;
      }

      return state;
    },
    actionDismissed: (
      state,
      action: PayloadAction<{ actionKey: ActionKey; isDismissed: boolean }>
    ) => {
      const { actionKey, isDismissed } = action.payload;

      if (state.actionsDismissed[actionKey] !== isDismissed) {
        state.actionsDismissed[actionKey] = isDismissed;

        setDataToLocalStorage(
          LocalStorageKeys.YOUR_IT_PLAN_STATE,
          state.actionsDismissed
        );
      }

      return state;
    },
  },
});

export const { actionCompleted, actionDismissed } = yourItPlanSlice.actions;

export default yourItPlanSlice;

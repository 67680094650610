import { Text } from '@electricjs/arc';

import { Link } from 'react-router-dom';

import { ExternalLink } from '@common';

import MdmSetupStep2Img from '@/assets/mdm_apple_setup_step_2.png';
import MdmSetupStep2ImgRenew from '@/assets/mdm_apple_setup_step_2_renew.png';
import { StepContainer } from '@/components/MDMEnrollment/MDMAppleForm/StepComponents';

type ApplePushCertificatePortalStepProps = {
  isRenewing?: boolean;
};

export const ApplePushCertificatePortalStep = ({
  isRenewing,
}: ApplePushCertificatePortalStepProps) => {
  return (
    <StepContainer
      step={2}
      image={
        isRenewing
          ? {
              scr: MdmSetupStep2ImgRenew,
              alt: 'Apple Certificate Renew Action',
            }
          : { scr: MdmSetupStep2Img, alt: 'Apple Certificate Get Started' }
      }>
      <Text variant="label-large">Sign into Apple Push Certificate Portal</Text>
      <Text variant="body">
        Use your company&apos;s Apple ID credentials to sign into Apple Push
        Certificate Portal.
      </Text>
      <ExternalLink href="https://identity.apple.com/pushcert/">
        Go to Apple
      </ExternalLink>
      {isRenewing ? (
        <Text variant="body">
          Once signed in, Click &quot;<b>Renew</b>&quot;
        </Text>
      ) : (
        <Text variant="body">
          Once signed in, Click &quot;Create a Certificate.&quot;
        </Text>
      )}
      <Text variant="legal">
        Note: If you haven&apos;t created an Apple ID, navigate&nbsp;
        <Link
          to="https://appleid.apple.com/account"
          target="_blank"
          rel="noopener">
          here
        </Link>
        &nbsp;and fill out the required fields. We recommend creating a new
        Apple ID that is tied to a group email, or shared inbox, and not a
        particular user. When it comes to MFA setup, use a business phone
        number.
      </Text>
    </StepContainer>
  );
};

import { Link, Text } from '@electricjs/arc';

import { DeviceIssueType } from '@/types/devices';
import { EmployeeWithAssets } from '@/types/employees';

import EmailModal from './EmailModal';
import { encryptionLinks } from '@/constants/deviceIssueLinks';

type EncryptionEmailModalProps = {
  hide: () => void;
  employees: EmployeeWithAssets[];
};

const EncryptionEmailModal = ({
  hide,
  employees,
}: EncryptionEmailModalProps) => {
  return (
    <EmailModal
      hide={hide}
      issueType={DeviceIssueType.Encryption}
      employees={employees}
      showDevice={true}
      title="Send employees encryption instructions">
      <Text>
        We’ll send instructions to your employees on how to encrypt their data
      </Text>
      <Text mt={3}>
        <Link target="_blank" rel="noopener" href={encryptionLinks.mac}>
          Encryption instructions for Mac devices (Filevault)
        </Link>
      </Text>
      <Text mt={3}>
        <Link target="_blank" rel="noopener" href={encryptionLinks.windows}>
          Encryption instructions for Windows devices (BitLocker)
        </Link>
      </Text>
      <Text mt={5}>Employees that don’t have encryption:</Text>
    </EmailModal>
  );
};

export default EncryptionEmailModal;

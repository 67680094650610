import { useState } from 'react';

import { Button, ColorsV2, Flex, Icon, Text } from '@electricjs/arc';

import AppInviteModalWrapper from '@/components/DeviceOverview/AppInviteModal/AppInviteModalWrapper';
import { EmployeeWithReminderReason } from '@/pages/Hardware/DeviceOverview/types';
import { useOrganizationHasEntitlement } from '@/hooks/useOrganizationHasEntitlement';
import { EntitlementSlugEnum } from '@electricjs/core_entity-client';

type CardFooterType = {
  employeesThatNeedAReminder: EmployeeWithReminderReason[];
  isFetchingEmployees: boolean;
  mdmEnabled?: boolean;
};

export const CardFooter = ({
  employeesThatNeedAReminder,
  isFetchingEmployees,
  mdmEnabled = false,
}: CardFooterType) => {
  const [showInviteModal, setShowInviteModal] = useState(false);
  const hasMdmEntitlement = useOrganizationHasEntitlement(
    EntitlementSlugEnum.JumpcloudMdm
  );

  return (
    <Flex
      justifyContent="flex-end"
      alignItems="center"
      width="100%"
      minHeight="4.6rem">
      {!isFetchingEmployees &&
        (employeesThatNeedAReminder.length > 0 ? (
          <Button
            id="button-invite-employees"
            variant="text"
            mt={2}
            onClick={() => setShowInviteModal(true)}>
            {mdmEnabled ? 'Send MDM instructions' : 'View unmonitored devices'}
          </Button>
        ) : (
          <Flex verticalAlign="center" mt="1rem" alignItems="center">
            <Flex
              width="2rem"
              height="2rem"
              borderRadius="50%"
              backgroundColor={ColorsV2.SUCCESS}
              justifyContent="center"
              alignItems="center">
              <Icon icon="check" size="small" intent="primary-invert" />
            </Flex>
            <Text pl="1rem" variant="body" alignContent="center">
              {hasMdmEntitlement
                ? `All devices are enrolled`
                : `All devices are monitored`}
            </Text>
          </Flex>
        ))}
      <AppInviteModalWrapper
        inviteVisible={showInviteModal}
        setShowInviteModal={setShowInviteModal}
        employees={employeesThatNeedAReminder}
        title={hasMdmEntitlement ? 'Unenrolled devices' : 'Unmonitored devices'}
      />
    </Flex>
  );
};

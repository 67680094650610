import { SideNavItemProps } from '@/components/Nav/SideNav/SideNavItem';
import { has } from 'lodash';

/**
 *
 * When the `visible` property is not set, the item should be displayed by default.
 * If the `visible` prop is a falsy value (undefined, false) it should be hidden.
 */
export default function isVisible(
  item: Partial<Pick<SideNavItemProps, 'visible'>>
) {
  return !has(item, 'visible') || !!item.visible;
}

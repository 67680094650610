import { TabPanel } from '@electricjs/arc';
import { ApplicationsIntegration } from '@/components/Settings/IntegrationsPane/ApplicationsIntegration';
import HRISIntegrations from '@/components/Settings/IntegrationsPane/HRISIntegrations/HRISIntegrations';
import { useOrganizationHasEntitlement } from '@/hooks/useOrganizationHasEntitlement';
import { EntitlementSlugEnum } from '@electricjs/core_entity-client';

const IntegrationsPane = () => {
  const hasAppIntegrationEntitlement = useOrganizationHasEntitlement(
    EntitlementSlugEnum.Integrations
  );

  return (
    <TabPanel pt="3.4rem" pl="2.2rem">
      <HRISIntegrations />
      {hasAppIntegrationEntitlement && <ApplicationsIntegration />}
    </TabPanel>
  );
};

export default IntegrationsPane;

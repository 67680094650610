import { ReactNode } from 'react';
import { IgnoreAssetLogButton } from '@/components/DeviceOverview/DeviceHealthMonitor/Actions/IgnoreAssetLogButton';
import { SendFollowUpEmailButton } from '@/components/DeviceOverview/DeviceHealthMonitor/Actions/SendFollowUpEmailButton';
import OSUpdateEmailModal from '@/components/DeviceOverview/IssueModals/OSUpdateEmailModal';
import { ActionsColumn } from '@/components/DeviceOverview/DeviceHealthMonitor/ColumnsAndContainers/ActionsColumn';
import { EmployeeWithAssets } from '@/types/employees';
import EncryptionEmailModal from '@/components/DeviceOverview/IssueModals/EncryptionEmailModal';
import FirewallEmailModal from '@/components/DeviceOverview/IssueModals/FirewallEmailModal';
import GatekeeperEmailModal from '@/components/DeviceOverview/IssueModals/GatekeeperEmailModal';
import DiskSpaceEmailModal from '@/components/DeviceOverview/IssueModals/DiskSpaceEmailModal';
import { ContactProductSupportButton } from '@/components/DeviceOverview/DeviceHealthMonitor/Actions/ContactProductSupportButton';
import { ReplaceMenuOptions } from '@/components/DeviceOverview/DeviceHealthMonitor/Actions/ReplaceDeviceOptions';
import { UnlockDeviceButton } from '../Actions/UnlockDeviceButton';
import { AssetHistoryWithChildren } from '@/types/assets';
import { DeviceIssueType } from '@/types/devices';

type ActionsColumnMapType = Partial<{
  [key in DeviceIssueType]: (
    assetLog: AssetHistoryWithChildren,
    employee: EmployeeWithAssets
  ) => ReactNode;
}>;

export const ActionsColumnMap: ActionsColumnMapType = {
  [DeviceIssueType.Firewall]: (assetLog, employee) => (
    <ActionsColumn>
      <IgnoreAssetLogButton assetLogId={assetLog.id} />
      <SendFollowUpEmailButton
        ModalComponent={FirewallEmailModal}
        employee={employee}
      />
    </ActionsColumn>
  ),
  [DeviceIssueType.Gatekeeper]: (assetLog, employee) => (
    <ActionsColumn>
      <IgnoreAssetLogButton assetLogId={assetLog.id} />
      <SendFollowUpEmailButton
        ModalComponent={GatekeeperEmailModal}
        employee={employee}
      />
    </ActionsColumn>
  ),
  [DeviceIssueType.OS]: (assetLog, employee) => (
    <ActionsColumn>
      <IgnoreAssetLogButton assetLogId={assetLog.id} />
      <SendFollowUpEmailButton
        ModalComponent={OSUpdateEmailModal}
        employee={employee}
      />
    </ActionsColumn>
  ),
  [DeviceIssueType.Encryption]: (assetLog, employee) => (
    <ActionsColumn>
      <IgnoreAssetLogButton assetLogId={assetLog.id} />
      <SendFollowUpEmailButton
        ModalComponent={EncryptionEmailModal}
        employee={employee}
      />
    </ActionsColumn>
  ),
  [DeviceIssueType.Storage]: (assetLog, employee) => (
    <ActionsColumn>
      <IgnoreAssetLogButton assetLogId={assetLog.id} />
      <SendFollowUpEmailButton
        ModalComponent={DiskSpaceEmailModal}
        employee={employee}
      />
    </ActionsColumn>
  ),
  [DeviceIssueType.RAM]: (assetLog, employee) => (
    <ActionsColumn>
      <IgnoreAssetLogButton assetLogId={assetLog.id} />
      <ReplaceMenuOptions employeeId={employee.id} />
    </ActionsColumn>
  ),
  /* TODO HY-218 We're temporarily disabling Battery health while we improve the logic behind it */
  // [DeviceIssueType.BatteryHealth]: (assetLog, employee) => (
  //   <ActionsColumn>
  //     <IgnoreAssetLogButton assetLogId={assetLog.id} />
  //     <ReplaceMenuOptions employeeId={employee.id} />
  //   </ActionsColumn>
  // ),
  [DeviceIssueType.OsqueryNotReporting]: assetLog => (
    <ActionsColumn>
      <IgnoreAssetLogButton assetLogId={assetLog.id} />
      <ContactProductSupportButton />
    </ActionsColumn>
  ),
  [DeviceIssueType.MdmNotReporting]: assetLog => (
    <ActionsColumn>
      <IgnoreAssetLogButton assetLogId={assetLog.id} />
      <ContactProductSupportButton />
    </ActionsColumn>
  ),
  [DeviceIssueType.DeviceLock]: (assetLog, employee) => (
    <ActionsColumn>
      <IgnoreAssetLogButton assetLogId={assetLog.id} />
      <UnlockDeviceButton assetId={assetLog.assetId} employee={employee} />
    </ActionsColumn>
  ),
} as const;

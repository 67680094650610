import { useState } from 'react';

import {
  ColorsV2,
  FieldLabel,
  ModalAction,
  ModalBody,
  ModalFooter,
  ModalFooterActions,
  ModalHeader,
  ModalV2,
  Spacer,
  Text,
  TextInput,
} from '@electricjs/arc';

import { useGlobalUI } from '@/components/GlobalUIProvider';
import { useWipeDeviceMutation } from '@/redux/slices/mdmManagementApiSlice';
import { DeviceAsset } from '@/types/assets';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';

type WipeDeviceModalProps = {
  deviceAsset: DeviceAsset;
  employeeName: string;
  onCompleteWipe: () => void;
  onCancel: () => void;
};

const WipeDeviceModal = ({
  deviceAsset,
  employeeName,
  onCompleteWipe,
  onCancel,
}: WipeDeviceModalProps) => {
  const organizationId = useGetOrganizationId();
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const { showSuccessToast, showErrorToast } = useGlobalUI();
  const [wipeDevice, { isLoading }] = useWipeDeviceMutation();

  const serialNumber = deviceAsset.serialNumber;

  const handleInputChange: React.ChangeEventHandler<
    HTMLInputElement
  > = event => {
    const newValue = event.target.value;
    setButtonEnabled(newValue.toLowerCase() === serialNumber?.toLowerCase());
  };

  const handleWipeDevice = async () => {
    try {
      if (!organizationId) {
        throw new Error('Organization ID for device not found');
      }
      await wipeDevice({
        organizationId,
        deviceId: deviceAsset.device.id || '',
      }).unwrap();
      showSuccessToast({
        id: 'wipe-device-success-toast',
        message: `Wipe command sent to ${employeeName}'s device.`,
      });
      onCompleteWipe();
    } catch (error) {
      showErrorToast({
        id: 'wipe-device-error-toast',
        message:
          'There was an error sending the wipe command. Please try again.',
      });
    }
  };

  return (
    <ModalV2 visible hide={onCancel} ariaLabelledby="wipe-device">
      <ModalHeader>
        <Text variant="heading-2">Wipe {employeeName}&apos;s device</Text>
      </ModalHeader>
      <ModalBody>
        <Text>{`You are about to initiate a wipe for ${employeeName}'s device. This action will permanently erase all data on the device and restore it to its factory settings. This process cannot be undone.`}</Text>
        <Spacer />
        <Text>
          {`To confirm, enter the device's serial number and click "Yes, wipe
          device".`}
        </Text>
        <Spacer />
        <FieldLabel>
          <Text color={ColorsV2.ERROR} pr="1">
            *
          </Text>
          <Text variant="label-small">
            {`Enter the device's serial number (`}
          </Text>
          <Text intent="secondary" variant="label-small" fontWeight="normal">
            {serialNumber}
          </Text>
          <Text variant="label-small">{`)`}</Text>
        </FieldLabel>
        <TextInput
          autoComplete="off"
          onPaste={event => event.preventDefault()}
          id="wipe-device-serial-number-input"
          placeholder="Serial #"
          onChange={handleInputChange}
        />
      </ModalBody>
      <ModalFooter>
        <ModalFooterActions>
          <ModalAction
            id="wipe-device-cancel"
            onClick={onCancel}
            variant="text"
            loading={isLoading}>
            Cancel
          </ModalAction>
          <ModalAction
            id="wipe-device-submit"
            onClick={handleWipeDevice}
            disabled={!buttonEnabled}
            loading={isLoading}
            intent="danger">
            Yes, wipe device
          </ModalAction>
        </ModalFooterActions>
      </ModalFooter>
    </ModalV2>
  );
};

export default WipeDeviceModal;

import { EntitlementSlugEnum } from '@electricjs/core_entity-client';

import { useAppSelector } from '@/redux/store';

export const useOrganizationHasEntitlement = (
  slug: EntitlementSlugEnum
): boolean => {
  const entitlementSlugs =
    useAppSelector(state => state.loggedUser?.organizationEntitlementSlugs) ||
    [];

  return entitlementSlugs.includes(slug);
};

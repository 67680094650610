import styled from 'styled-components';

import {
  Button,
  Chip,
  ColorsV2,
  Divider,
  Flex,
  IconInCircle,
  Text,
  useThemeColors,
  Banner,
  Pane,
  getToken,
} from '@electricjs/arc';

import { CenteredSpinner, ExternalLink, LinkNoUnderline } from '@common';

import { useLazyGetOrganizationPaymentMethodV1Query } from '@/redux/slices/billingApiSlice';
import { useEffect, useState } from 'react';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import StyledRouterLink from '@/components/StyledRouterLink';

type PaymentTileProps = {
  isInGrid: boolean;
  height: string;
};

enum PaymentStatus {
  EMPTY = 'EMPTY',
  PROCESSING = 'PROCESSING',
  ON_FILE = 'ON_FILE',
  LOADING = 'LOADING',
  UNKNOWN = 'UNKNOWN',
}

type EmptyPaymentFileProps = PaymentTileProps & {
  paymentUrl?: string;
  handleProcessing: () => void;
};

type ValidPaymentFileProps = PaymentTileProps & {
  paymentType?: string;
};

type ProcessingPaymentFileProps = PaymentTileProps & {
  onRefresh: () => void;
};

const TableContainer = styled(Pane)`
  width: 100%;
  border-radius: 1.2rem;
  border: 1px solid ${getToken(ColorsV2.BORDER)};
  background: ${getToken(ColorsV2.BACKGROUND_CONTENT)};
  overflow-y: auto;
`;

const tileTitle = 'Services Payment Method';

const PaymentMethodNotice = () => {
  return (
    <Text variant="body" width="100%">
      This payment method is used for your service invoices, including IT Hub
      licenses, warehousing, and other subscriptions. It does not apply to
      hardware purchases. To manage your services payment method, contact
      customer support at{' '}
      <ExternalLink
        id="link-to-product-support-email"
        href="mailto:product-support@electric.ai"
        showIcon={false}>
        product-support@electric.ai
      </ExternalLink>
      . To manage your hardware payment method, visit the{' '}
      <StyledRouterLink to={`/purchase-hardware`}>
        Hardware Store
      </StyledRouterLink>{' '}
      checkout.
    </Text>
  );
};

const LoadingPaymentFile = ({ isInGrid, height }: PaymentTileProps) => {
  return (
    <TableContainer height={isInGrid && height}>
      <CenteredSpinner height="20rem" alignItems="center" />
    </TableContainer>
  );
};

const ProcessingPaymentFile = ({
  isInGrid,
  height,
  onRefresh,
}: ProcessingPaymentFileProps) => {
  const [
    iconColor,
    iconBackgroundColor,
    borderColor,
    warningBackgroundColor,
    warningTextColor,
  ] = useThemeColors([
    ColorsV2.INFO,
    ColorsV2.INFO_LIGHTEST,
    ColorsV2.BORDER,
    ColorsV2.WARNING_LIGHTEST,
    ColorsV2.WARNING_DARK,
  ]);

  return (
    <TableContainer height={isInGrid && height}>
      <Flex vertical rowGap="0.5rem" minWidth="100%">
        <Flex vAlignContent="center" gap="2rem" p={isInGrid ? '1rem' : '2rem'}>
          <IconInCircle
            color={iconColor}
            background={iconBackgroundColor}
            icon="address-card"
            size="4rem"
          />
          <Text variant="heading-2">{tileTitle}</Text>
        </Flex>
        <Divider color={borderColor} />
        <Flex
          vertical
          p={isInGrid ? '1rem 2rem' : '4rem'}
          width="100%"
          rowGap="2rem">
          <PaymentMethodNotice />
          <Flex vAlignContent="center" gap="2rem">
            <Text variant="label-large" width="6rem">
              Status
            </Text>{' '}
            <Chip
              id="processing-payment-method-chip"
              backgroundColor={warningBackgroundColor}
              textColor={warningTextColor}>
              Processing
            </Chip>
          </Flex>
          <Banner
            title="Payment method processing"
            isFlat
            intent="warning"
            id="processing-payment-method-banner"
            testId="processing-payment-method-banner"
            message={() => (
              <>
                <Text>
                  We see you are attempting to add a payment method. Refresh
                  screen to see if your payment method has been accepted and
                  placed On file.
                </Text>
                <Button
                  id="button-refresh-screen"
                  intent="warning"
                  variant="outline"
                  p="1rem 2rem"
                  iconAfter="refresh"
                  mt="1rem"
                  onClick={onRefresh}>
                  Refresh screen
                </Button>
              </>
            )}
          />
        </Flex>
      </Flex>
    </TableContainer>
  );
};

const UnknownPaymentFile = ({ isInGrid, height }: PaymentTileProps) => {
  const [
    iconColor,
    iconBackgroundColor,
    borderColor,
    warningBackgroundColor,
    warningTextColor,
  ] = useThemeColors([
    ColorsV2.INFO,
    ColorsV2.INFO_LIGHTEST,
    ColorsV2.BORDER,
    ColorsV2.WARNING_LIGHTEST,
    ColorsV2.WARNING_DARK,
  ]);

  return (
    <TableContainer height={isInGrid && height}>
      <Flex vertical rowGap="0.5rem" minWidth="100%">
        <Flex vAlignContent="center" gap="2rem" p={isInGrid ? '1rem' : '2rem'}>
          <IconInCircle
            color={iconColor}
            background={iconBackgroundColor}
            icon="address-card"
            size="4rem"
          />
          <Text variant="heading-2">{tileTitle}</Text>
        </Flex>
        <Divider color={borderColor} />
        <Flex
          vertical
          p={isInGrid ? '1rem 2rem' : '4rem'}
          width="100%"
          rowGap="2rem">
          <PaymentMethodNotice />
          <Flex vAlignContent="center" gap="2rem">
            <Text variant="label-large" width="6rem">
              Status
            </Text>{' '}
            <Chip
              id="unknown-payment-method-chip"
              backgroundColor={warningBackgroundColor}
              textColor={warningTextColor}>
              Unknown
            </Chip>
          </Flex>
          <Banner
            title="Payment method error"
            isFlat
            intent="warning"
            id="unknown-payment-method-banner"
            testId="unknown-payment-method-banner"
            message={() => (
              <Text>
                An error occurred and a payment method can not be added to your
                organization at this time. Please try again later. If the
                problem persists, send an email request to{' '}
                <ExternalLink
                  id="link-to-product-support-email"
                  href="mailto:product-support@electric.ai"
                  showIcon={false}>
                  product-support@electric.ai
                </ExternalLink>
                . Reminder, never send payment information in unsecured emails.
              </Text>
            )}
          />
        </Flex>
      </Flex>
    </TableContainer>
  );
};

const ValidPaymentFile = ({
  isInGrid,
  height,
  paymentType,
}: ValidPaymentFileProps) => {
  const [
    iconColor,
    iconBackgroundColor,
    borderColor,
    successBackgroundColor,
    successTextColor,
  ] = useThemeColors([
    ColorsV2.INFO,
    ColorsV2.INFO_LIGHTEST,
    ColorsV2.BORDER,
    ColorsV2.SUCCESS_LIGHTEST,
    ColorsV2.SUCCESS_DARK,
  ]);

  return (
    <TableContainer height={isInGrid && height}>
      <Flex vertical rowGap="0.5rem" minWidth="100%">
        <Flex vAlignContent="center" gap="2rem" p={isInGrid ? '1rem' : '2rem'}>
          <IconInCircle
            color={iconColor}
            background={iconBackgroundColor}
            icon="address-card"
            size="4rem"
          />
          <Text variant="heading-2">{tileTitle}</Text>
        </Flex>
        <Divider color={borderColor} />
        <Flex
          vertical
          p={isInGrid ? '1rem 2rem' : '4rem'}
          width="100%"
          rowGap="2rem">
          <PaymentMethodNotice />
          <Flex vAlignContent="center" gap="2rem">
            <Text variant="label-large" width="6rem">
              Status
            </Text>{' '}
            <Chip
              id="on-file-payment-method-chip"
              backgroundColor={successBackgroundColor}
              textColor={successTextColor}>
              On file
            </Chip>
          </Flex>
          {paymentType && (
            <Flex vAlignContent="center" gap="2rem">
              <Text variant="label-large" width="6rem">
                Type
              </Text>{' '}
              <Text>{paymentType}</Text>
            </Flex>
          )}
        </Flex>
      </Flex>
    </TableContainer>
  );
};

const EmptyPaymentFile = ({
  isInGrid,
  height,
  paymentUrl,
  handleProcessing,
}: EmptyPaymentFileProps) => {
  const iconBackgroundColor = useThemeColors(ColorsV2.PRIMARY_LIGHTEST);

  return (
    <TableContainer height={isInGrid && height}>
      <Flex vertical hAlignContent="center" minWidth="100%">
        <Flex
          vertical
          hAlignContent="center"
          rowGap="2rem"
          p={isInGrid ? '1rem' : '4rem'}>
          <IconInCircle
            intent="primary"
            background={iconBackgroundColor}
            icon="address-card"
            iconSize="large"
            size="6rem"
          />
          <Text variant="heading-2">{tileTitle}</Text>
          <Text textAlign="center">
            Add a payment method to ensure uninterrupted service for your IT Hub
            licenses, warehousing, and other subscriptions. This does not apply
            to hardware purchases, which are managed separately within the{' '}
            <StyledRouterLink to={`/purchase-hardware`}>
              Hardware Store
            </StyledRouterLink>
            .
          </Text>
          {paymentUrl ? (
            <LinkNoUnderline href={paymentUrl} target="_blank" rel="noopener">
              <Button
                id="button-add-payment-method"
                intent="primary"
                variant="fill"
                p="1rem 2rem"
                onClick={handleProcessing}>
                Add payment method
              </Button>
            </LinkNoUnderline>
          ) : (
            <Button
              id="button-add-payment-method"
              intent="primary"
              variant="fill"
              p="1rem 2rem"
              disabled>
              Add payment method
            </Button>
          )}
        </Flex>
      </Flex>
    </TableContainer>
  );
};

const PaymentTile = ({ ...props }: PaymentTileProps) => {
  const [paymentStatus, setPaymentStatus] = useState(PaymentStatus.EMPTY);
  const organizationId = useGetOrganizationId();

  const [getOrganizationPaymentMethod, { data: organizationPaymentMethod }] =
    useLazyGetOrganizationPaymentMethodV1Query();

  useEffect(() => {
    getOrganizationPaymentMethod({ organizationId }, true);
  }, [getOrganizationPaymentMethod, organizationId]);

  useEffect(() => {
    if (organizationPaymentMethod?.paymentType) {
      setPaymentStatus(PaymentStatus.ON_FILE);
    }
    if (
      organizationPaymentMethod?.paymentType === null &&
      organizationPaymentMethod?.paymentUrl === null
    ) {
      setPaymentStatus(PaymentStatus.UNKNOWN);
    }
  }, [
    organizationPaymentMethod?.paymentType,
    organizationPaymentMethod?.paymentUrl,
    paymentStatus,
  ]);

  const handleRefresh = () => {
    getOrganizationPaymentMethod({ organizationId });
    setPaymentStatus(PaymentStatus.LOADING);
    setTimeout(() => setPaymentStatus(PaymentStatus.PROCESSING), 500);
  };

  switch (paymentStatus) {
    case PaymentStatus.PROCESSING:
      return <ProcessingPaymentFile {...props} onRefresh={handleRefresh} />;
    case PaymentStatus.ON_FILE:
      return (
        <ValidPaymentFile
          {...props}
          paymentType={organizationPaymentMethod?.paymentType}
        />
      );
    case PaymentStatus.UNKNOWN:
      return <UnknownPaymentFile {...props} />;
    case PaymentStatus.LOADING:
      return <LoadingPaymentFile {...props} />;
    case PaymentStatus.EMPTY:
    default:
      return (
        <EmptyPaymentFile
          {...props}
          handleProcessing={() => setPaymentStatus(PaymentStatus.PROCESSING)}
          paymentUrl={organizationPaymentMethod?.paymentUrl}
        />
      );
  }
};

export default PaymentTile;

import { ThemeContext, Themes } from '@/components/ThemeProvider';
import {
  useThemeColors,
  ColorsV2,
  Flex,
  Text,
  Card,
  KebabMenuOption,
  MenuItem,
  IconNames,
  Icon,
  InitialsInCircle,
} from '@electricjs/arc';
import { ButtonIntent } from '@electricjs/arc/components/Button';
import { useContext } from 'react';
import styled from 'styled-components';
import { ifProp } from 'styled-tools';
import { StatusChip } from './StatusChip';
import { Upgradable } from '@common';
import {
  EntitlementSlugEnum,
  HRISProviderSlugEnum,
} from '@electricjs/core_entity-client';
import { useNavigate } from 'react-router-dom';
import BetaChip from '@/components/Settings/IntegrationsPane/IntegrationCard/BetaChip';
import InternalChip from './Internalchip';

type AddonLogoImg = {
  $isDarkTheme: boolean;
};

const AddonLogoImg = styled.img<AddonLogoImg>`
  background-color: ${ifProp('$isDarkTheme', '#ffffff', 'transparent')};
  padding: ${ifProp('$isDarkTheme', '.5rem', '0')};
  border-radius: ${ifProp('$isDarkTheme', '.5rem', 'none')};
`;

export const BaseCard = styled(Card)`
  height: 21.5rem;
  width: 100%;
`;

export type IntegrationCardProps = {
  slug?: HRISProviderSlugEnum;
  id?: string;
  name: string;
  icon?: IconNames;
  customIcon?: string;
  homepage?: string;
  description: string;
  handleConnectionRequest: () => void;
  handleDisconnectionRequest?: () => void;
  currentIntegrationSlug?: string | undefined;
  integrationSettingPage?: string;
  customStatusLabel?: string;
  needsUpgrade?: boolean;
  isConnected: boolean;
  isBeta?: boolean;
  isInternal?: boolean;
};

export const IntegrationCard = ({
  slug,
  name,
  icon,
  customIcon,
  homepage,
  description,
  handleConnectionRequest,
  handleDisconnectionRequest,
  currentIntegrationSlug,
  integrationSettingPage,
  customStatusLabel,
  needsUpgrade,
  isConnected,
  isBeta,
  isInternal,
}: IntegrationCardProps) => {
  const {
    theme: { name: themeName },
  } = useContext(ThemeContext);
  const isDarkTheme = themeName === Themes.DARK;
  const [borderColor, cardDescriptionColor, iconColor] = useThemeColors([
    ColorsV2.GRAY_LIGHT,
    ColorsV2.GRAY_DARK,
    ColorsV2.PRIMARY,
  ]);
  const navigate = useNavigate();

  const kebabMenuItems: KebabMenuOption[] = [];

  if (homepage) {
    kebabMenuItems.push({
      label: 'Visit homepage',
      onClick: () => {
        window.open(homepage, '_blank');
      },
    });
  }

  if (integrationSettingPage) {
    kebabMenuItems.push({
      label: 'Edit integration settings',
      onClick: () => {
        navigate(integrationSettingPage);
      },
    });
  }

  const connectIntegrationItem = needsUpgrade
    ? {
        customComponent: (
          <Upgradable
            id="upgradable-menu-item"
            component={MenuItem}
            entitlement={EntitlementSlugEnum.HrisIntegration}
            onUpgradeClick={() => navigate('/plans')}>
            Connect integration
          </Upgradable>
        ),
        id: 'custom-menu-item-connect-integration',
      }
    : {
        label: 'Connect integration',
        onClick: () => handleConnectionRequest(),
        disabled: !!currentIntegrationSlug,
      };

  if (isConnected) {
    if (handleDisconnectionRequest) {
      kebabMenuItems.unshift({
        label: 'Disconnect integration',
        onClick: () => handleDisconnectionRequest(),
        intent: 'danger' as ButtonIntent,
      });
    }
  } else {
    kebabMenuItems.unshift(connectIntegrationItem);
  }

  const CardIcon = () => {
    if (customIcon) {
      return (
        <AddonLogoImg
          $isDarkTheme={isDarkTheme}
          alt={name}
          src={customIcon}
          height={40}
        />
      );
    }
    if (icon) {
      return <Icon icon={icon} size="large" color={iconColor} />;
    }

    return <InitialsInCircle text={name} size="4rem" borderRadius="0.5rem" />;
  };

  return (
    <BaseCard
      elevated
      id={`${slug ? slug : 'other-integrations'}-card`}
      cardPadding="1rem 0 0"
      title={
        <Flex
          height="4rem"
          vAlignContent="center"
          gap="1.2rem"
          p="0 2.4rem"
          my="1.6rem">
          <CardIcon />
          <Text variant="subheading">{name}</Text>
        </Flex>
      }
      footer={
        <Flex
          height="5.4rem"
          width="100%"
          vAlignContent="center"
          pl="2rem"
          gap="0.8rem"
          borderTop={`1px solid ${borderColor}`}>
          <StatusChip
            isConnected={isConnected}
            customLabel={customStatusLabel}
          />
          {isBeta && <BetaChip />}
          {isInternal && <InternalChip />}
        </Flex>
      }
      kebabMenuItems={kebabMenuItems}>
      <Flex
        vertical
        vAlignContent="between"
        height="100%"
        pl="2.4rem"
        pr="1.2rem">
        <Text variant="body-2" color={cardDescriptionColor}>
          {description}
        </Text>
      </Flex>
    </BaseCard>
  );
};

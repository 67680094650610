import { rest } from 'msw';

import { orders } from '../data/orderData';
import { internalOrders } from '../data/orderManagementData';

export const orderHandlers = [
  rest.get('*/v2/organizations/:id/procurements', (_req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(),
      ctx.json({ results: orders, total: orders.length })
    );
  }),
  rest.post('*/v2/organizations/:id/procurements', (_req, res, ctx) => {
    return res(ctx.status(200), ctx.delay());
  }),

  // internal orders
  rest.get('*/v2/orders', (_req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(),
      ctx.json({ results: internalOrders, total: internalOrders.length })
    );
  }),
  rest.get('*/v2/orders/:friendlyId', (req, res, ctx) => {
    const order = internalOrders.find(
      order => order.friendly_id === req.params.friendlyId
    );

    if (order) {
      return res(ctx.status(200), ctx.delay(), ctx.json(order));
    } else {
      return res(ctx.status(404));
    }
  }),
];

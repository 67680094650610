import { Control, Controller } from 'react-hook-form';
import { Checkbox, Flex } from '@electricjs/arc';
import { ReactElement } from 'react';

export type ControlledCheckboxProps = {
  id: string;
  name: string;
  label?: string | ReactElement;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  disabled?: boolean;
};

const ControlledCheckbox = ({
  id,
  name,
  label,
  control,
  disabled = false,
}: ControlledCheckboxProps) => {
  return (
    <Flex alignItems="center">
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Checkbox
            id={id}
            name={name}
            onChange={onChange}
            checked={value}
            disabled={disabled}>
            {label}
          </Checkbox>
        )}
      />
    </Flex>
  );
};

export default ControlledCheckbox;

import { Text, Button } from '@electricjs/arc';

import { MdmEnrollmentStep } from '@/components/MDMEnrollment/types';
import ProvisionStatusChip from '@/components/MDMEnrollment/ProvisionStatusChip';

type HeaderActionProps = {
  step: MdmEnrollmentStep;
  onClick?: () => void;
};

export const HeaderAction = ({ step }: HeaderActionProps) => {
  const contactSupportActionWhenSettingUp = (
    <>
      <Text variant="body" mr="2rem" fontSize="14px">
        Struggling with MDM setup?
      </Text>
      <Button
        id="contact-support-button"
        variant="link"
        onClick={() =>
          (window.location.href = 'mailto:product-support@electric.ai')
        }>
        Contact product support
      </Button>
    </>
  );

  const contactSupportActionWhenRenewing = (
    <>
      <Text variant="body" mr="2rem" fontSize="14px">
        Need help with the renewal process?
      </Text>
      <Button
        id="contact-support-button"
        variant="link"
        onClick={() =>
          (window.location.href = 'mailto:product-support@electric.ai')
        }>
        Contact product support
      </Button>
    </>
  );

  switch (step) {
    case MdmEnrollmentStep.MDM_APPLE_SETUP_FORM:
      return contactSupportActionWhenSettingUp;
    case MdmEnrollmentStep.ENROLLMENT_RENEWAL:
      return contactSupportActionWhenRenewing;
    // TODO: MDM removal logic will be implemented when the backend is finished
    // case MdmEnrollmentStep.ENROLLMENT_STATUS:
    //   return (
    //     <Button
    //       id="remove-mdm-button"
    //       testId="remove-mdm-button"
    //       variant="outline"
    //       intent="danger"
    //       onClick={onClick}>
    //       Remove MDM
    //     </Button>
    //   );
    default:
      return <ProvisionStatusChip />;
  }
};

import { Icon, Text } from '@electricjs/arc';
import { createColumnHelper } from '@tanstack/react-table';

import TableHeader from '@/components/TableHeader';
import TableCellFlexContainer from 'common/Table/TableCellFlexContainer';
import { DeviceIssuesData } from '@/components/Assets/AssetDetails/DeviceSecurityPanel/DeviceSecurityPanel';
import { UnavailableInfoChip } from '@/components/Assets/AssetDetails/UnavailableInfoChip';

const getDeviceSecurityColumns = () => {
  const columnHelper = createColumnHelper<DeviceIssuesData>();

  return [
    columnHelper.display({
      header: () => <TableHeader title="Security control" />,
      id: 'name',
      cell: data => (
        <TableCellFlexContainer>
          <Text>{data.row.original.name}</Text>
        </TableCellFlexContainer>
      ),
    }),
    columnHelper.display({
      header: () => <TableHeader title="Status" />,
      id: 'status',
      cell: data => {
        // Render the "UnavailableInfoChip" if the "Check issue"
        // feature is disabled for the item report.
        if (data.row.original.disabled) {
          return (
            <TableCellFlexContainer>
              <UnavailableInfoChip />
            </TableCellFlexContainer>
          );
        }

        // Display "N/A" if no data is available in the device reporting.
        if (!data.row.original.hasData) {
          return (
            <TableCellFlexContainer ml="1rem">
              <Text>N/A</Text>
            </TableCellFlexContainer>
          );
        }

        // If data is available, render the appropriate icon based
        // on whether an issue is detected.
        return (
          <TableCellFlexContainer ml="1rem">
            {data.row.original.hasIssue ? (
              <Icon
                icon="alert-triangle"
                customSize="2.4rem"
                intent="warning"
              />
            ) : (
              <Icon icon="check-circle" customSize="2.4rem" intent="success" />
            )}
          </TableCellFlexContainer>
        );
      },
    }),
    columnHelper.display({
      header: () => <TableHeader title="Actions" />,
      id: 'actions',
      meta: { maxWidth: 220 },
      cell: data => {
        return (
          <TableCellFlexContainer justifyContent="flex-start">
            {data.row.original.hasData && data.row.original.hasIssue ? (
              data.row.original.action?.()
            ) : (
              <Text>No action required</Text>
            )}
          </TableCellFlexContainer>
        );
      },
    }),
  ];
};

export default getDeviceSecurityColumns;

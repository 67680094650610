import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

export enum OAuthFlowMessageStatus {
  Success = 'success',
  Redirecting = 'redirecting',
  Error = 'error',
}

export type OAuthFlowMessage = {
  message: FetchBaseQueryError | string;
  status: OAuthFlowMessageStatus;
};

import { useGetAssetHistoryQuery } from '@/redux/slices/assetApiSlice';
import { useGetOrganizationId } from './useGetOrganizationId';
import { getDeviceStatus } from '@/components/People/EmployeeProfile/helpers/DevicePropertyHelpers';
import { DeviceAsset, isDeviceAsset } from '@/types/assets';

const PAGE_SIZE = 10;

/**
 * This hook combines common logic for fetching asset history and calculating MDM status.
 */
const useGetMdmStatus = ({
  deviceAsset,
}: {
  deviceAsset?: DeviceAsset | null;
}) => {
  const organizationId = useGetOrganizationId();
  const {
    isLoading: isLoadingAssetHistory,
    isFetching: isFetchingAssetHistory,
    isError: isAssetHistoryError,
    data: assetHistoryData,
  } = useGetAssetHistoryQuery(
    {
      organizationId,
      assetId: deviceAsset?.id ?? '',
      limit: PAGE_SIZE,
      offset: 0,
    },
    { skip: !deviceAsset?.id }
  );

  // Copied over comment from the original code
  // Keeping the same logic used to calculate this status before, but we may want to revisit it since getDeviceStatus uses paginated asset history data and it only includes last 10 events
  const mdmStatus =
    assetHistoryData && deviceAsset && isDeviceAsset(deviceAsset)
      ? getDeviceStatus({
          assetHistoryData: assetHistoryData.assetHistory,
          deviceAsset: deviceAsset,
        })
      : null;

  return {
    isLoadingAssetHistory,
    isFetchingAssetHistory,
    isAssetHistoryError,
    mdmStatus,
  };
};

export default useGetMdmStatus;

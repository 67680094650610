import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryArgs,
  FetchBaseQueryError,
  retry,
} from '@reduxjs/toolkit/query';
import responseCamelizer from './responseCamelizer';
import retryCondition from './retryCondition';

export const baseQueryCamelized =
  (
    fetchBaseQueryArgs: FetchBaseQueryArgs,
    {
      shouldCamelizeResponse = false,
    }: { shouldCamelizeResponse?: boolean } = {}
  ): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =>
  async (args, api, extraOptions) => {
    const baseQuery = retry(fetchBaseQuery(fetchBaseQueryArgs), {
      retryCondition,
    });
    const { data, ...rest } = await baseQuery(args, api, extraOptions);
    return {
      data: shouldCamelizeResponse ? responseCamelizer(data) : data,
      ...rest,
    };
  };

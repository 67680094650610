import { Fragment, useState } from 'react';
import styled from 'styled-components';

import { Chip, ColorsV2, Flex, Text, useThemeColors } from '@electricjs/arc';

import { FilterKeyEnum } from '@/types/storefront';

import { productsAttributesDisplayName } from '../../utils';
import { formatFilterValue, updateSelectedFilters } from './filterUtils';

type StyledChipProps = {
  selected: boolean;
  background: {
    primary: string;
    primaryLightest: string;
  };
  text: {
    default: string;
    inverse: string;
  };
};

export const StyledChip = styled(Chip)<StyledChipProps>`
  padding: 0 1.5rem;

  background-color: ${props =>
    props.selected
      ? props.background.primaryLightest
      : props.background.primary};

  span {
    color: ${props =>
      props.selected ? props.text.default : props.text.inverse};
  }

  :focus {
    background-color: ${props =>
      props.selected
        ? props.background.primaryLightest
        : props.background.primary};
  }

  :hover {
    background-color: ${props =>
      !props.selected
        ? `${props.background.primary}50`
        : `${props.background.primary}40`};
  }
`;

export type ProductSpecsFilterGroupProps = {
  filterKey: FilterKeyEnum;
  values: string[];
  getSelectedFilters: (value: string[], filterKey: FilterKeyEnum) => void;
};

export const ProductSpecsFilterGroup = ({
  filterKey,
  values,
  getSelectedFilters,
}: ProductSpecsFilterGroupProps) => {
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [primary, primaryLightest, text, textInverse] = useThemeColors([
    ColorsV2.PRIMARY,
    ColorsV2.PRIMARY_LIGHTEST,
    ColorsV2.TEXT,
    ColorsV2.TEXT_INVERSE,
  ]);

  const handleOnClick = (key: FilterKeyEnum, value: string) => {
    const updatedSelectedFilters = updateSelectedFilters(
      value,
      selectedFilters
    );
    getSelectedFilters(updatedSelectedFilters, key);
    setSelectedFilters(updatedSelectedFilters);
  };

  const selected = (value: string) => selectedFilters.includes(value);

  const getProductAttributeDisplayName = (key: FilterKeyEnum) => {
    return (
      productsAttributesDisplayName[
        key as keyof typeof productsAttributesDisplayName
      ] ?? key
    );
  };

  const unselectAll = () => {
    getSelectedFilters([], filterKey);
    setSelectedFilters([]);
  };

  const hasSelectedFilters = () => selectedFilters.length > 0;

  return (
    <Fragment key={filterKey}>
      <Text variant="label-small" tag="p" mb={3}>
        {getProductAttributeDisplayName(filterKey)}
      </Text>
      <Flex gap={2} mb={5}>
        <StyledChip
          id={`${filterKey}-all`}
          onClick={() => unselectAll()}
          selected={hasSelectedFilters()}
          background={{ primary, primaryLightest }}
          text={{ default: text, inverse: textInverse }}
          testId={`${filterKey}-all`}>
          All
        </StyledChip>
        {values.map(value => (
          <StyledChip
            id={`${filterKey}-${value}`}
            key={value}
            variant="medium"
            onClick={() => handleOnClick(filterKey, value)}
            selected={!selected(value)}
            background={{ primary, primaryLightest }}
            text={{ default: text, inverse: textInverse }}
            testId={`${filterKey}-${value}`}>
            {formatFilterValue(value)}
          </StyledChip>
        ))}
      </Flex>
    </Fragment>
  );
};

import { Button, Flex } from '@electricjs/arc';
import SelectFilter from 'common/TableFilters/SelectFilter';
import { AssetStatus, ProductTypeName } from '@electricjs/core_entity-client';
import SelectChip from 'common/TableFilters/SelectChip';
import { statusOptions, typeOptions, warrantyOptions } from './filterOptions';
import { ASSET_UNAVAILABLE_STATUSES } from '@/hooks/useGetEmployeesWithAssets';
import { Warranty } from '../types';

type AssetsTableFiltersGroupProps = {
  showStatusFilter: boolean;
  showTypeFilter: boolean;
  showWarrantyFilter: boolean;
  active: boolean;
  setAssetStatus: (
    assetStatus: AssetStatus[] | ((prev: AssetStatus[]) => AssetStatus[])
  ) => void;
  assetStatus: AssetStatus[];
  setProductType: (
    productType:
      | ProductTypeName[]
      | ((prev: ProductTypeName[]) => ProductTypeName[])
  ) => void;
  productType: ProductTypeName[];
  setWarranty: (
    warranty: Warranty[] | ((prev: Warranty[]) => Warranty[])
  ) => void;
  warranty: Warranty[];
  isMultiSelect?: boolean;
};

const AssetsTableFiltersGroup = ({
  showStatusFilter,
  showTypeFilter,
  showWarrantyFilter,
  active,
  setAssetStatus,
  assetStatus,
  setProductType,
  productType,
  setWarranty,
  warranty,
  isMultiSelect = false,
}: AssetsTableFiltersGroupProps) => {
  const modifiedStatusOptions = statusOptions.map(option => ({
    ...option,
    disabled: active
      ? ASSET_UNAVAILABLE_STATUSES.has(option.value)
      : !ASSET_UNAVAILABLE_STATUSES.has(option.value),
  }));

  const handleAddStatus = (status: AssetStatus) => {
    if (isMultiSelect) {
      setAssetStatus((prev: AssetStatus[]) => {
        if (prev.includes(status)) {
          return prev.filter(s => s !== status);
        }
        return [...prev, status];
      });
    } else {
      // Single select mode
      setAssetStatus([status]);
    }
  };

  const handleAddType = (type: ProductTypeName) => {
    if (isMultiSelect) {
      setProductType(prev => {
        if (prev.includes(type)) {
          return prev.filter(t => t !== type);
        }
        return [...prev, type];
      });
    } else {
      // Single select mode
      setProductType([type]);
    }
  };

  const handleAddWarranty = (warranty: Warranty) => {
    if (isMultiSelect) {
      setWarranty(prev => {
        if (prev.includes(warranty)) {
          return prev.filter(w => w !== warranty);
        }
        return [...prev, warranty];
      });
    } else {
      // Single select mode
      setWarranty([warranty]);
    }
  };

  const handleRemoveStatus = (statusToRemove: AssetStatus) => {
    setAssetStatus(prev => prev.filter(status => status !== statusToRemove));
  };

  const handleRemoveProductType = (productTypeToRemove: ProductTypeName) => {
    setProductType(prev => prev.filter(type => type !== productTypeToRemove));
  };

  const handleRemoveWarranty = (warrantyToRemove: Warranty) => {
    setWarranty(prev => prev.filter(type => type !== warrantyToRemove));
  };

  // clearing all filters
  const handleClearFilters = () => {
    setAssetStatus([]);
    setProductType([]);
    setWarranty([]);
  };

  const showClearButton =
    assetStatus.length > 0 || productType.length > 0 || warranty.length > 0;

  return (
    <Flex justifyContent="space-between" flexGrow={1}>
      <Flex gap="1.6rem" alignItems="center" flexWrap="wrap">
        {showStatusFilter && (
          <SelectFilter
            filterName="Status"
            options={modifiedStatusOptions}
            selectedValues={assetStatus}
            handleAddFilter={handleAddStatus}
          />
        )}
        {showTypeFilter && (
          <SelectFilter
            filterName="Type"
            options={typeOptions}
            selectedValues={productType}
            handleAddFilter={handleAddType}
          />
        )}
        {showWarrantyFilter && (
          <SelectFilter
            filterName="Warranty"
            options={warrantyOptions}
            selectedValues={warranty}
            handleAddFilter={handleAddWarranty}
          />
        )}
        <Flex gap="1.6rem" alignItems="center">
          <SelectChip
            selectedValues={assetStatus}
            options={statusOptions}
            handleRemoveFilter={handleRemoveStatus}
          />
          <SelectChip
            selectedValues={productType}
            options={typeOptions}
            handleRemoveFilter={handleRemoveProductType}
          />
          <SelectChip
            selectedValues={warranty}
            options={warrantyOptions}
            handleRemoveFilter={handleRemoveWarranty}
          />
          {showClearButton && (
            <Button
              id="clear-all-filters"
              variant="text"
              intent="primary"
              size="small"
              onClick={handleClearFilters}>
              Clear all
            </Button>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AssetsTableFiltersGroup;

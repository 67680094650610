import { FilterKeyEnum, type DynamicFilters } from '@/types/storefront';

export const updateSelectedFilters = (
  value: string,
  selectedFilters: string[]
) => {
  if (selectedFilters.includes(value)) {
    const updatedSelectedFilters = selectedFilters.filter(
      filter => filter !== value
    );
    return updatedSelectedFilters;
  }
  const updatedSelectedFilters: string[] = [...selectedFilters, value];
  return updatedSelectedFilters;
};

export const updateAllSelectedFilters = (
  selectedFilters?: DynamicFilters,
  value?: DynamicFilters,
  key?: FilterKeyEnum
) => {
  const updatedSelectedFilters = { ...selectedFilters, ...value };

  if (
    key &&
    (Object.keys(value ?? {}).length === 0 ||
      (value && value[key]?.length === 0))
  ) {
    delete updatedSelectedFilters[key];
  }

  return updatedSelectedFilters;
};

export const replaceUnderscoreWithDash = (value: string) =>
  value.split('_').join(' - ');

export const capitalize = (value: string) => {
  return value
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const formatFilterValue = (value: string) => {
  return capitalize(replaceUnderscoreWithDash(value));
};

import { formatDistance } from 'date-fns';

import { Notification } from '@/types/notifications';

export const getUnseenNotifications = (
  notifications: Notification[]
): string[] => {
  return notifications.filter(n => !n?.seenAt).map(n => n?.id);
};

export const hasUnseenNotifications = (
  notifications: Notification[]
): boolean => {
  return getUnseenNotifications(notifications).length > 0;
};

export const getCreatedAtFormatted = (createdAt?: string): string => {
  if (!createdAt) {
    return '';
  }
  const dateToday = new Date();
  const notificationDate = new Date(createdAt);

  return formatDistance(notificationDate, dateToday, {
    addSuffix: true,
  });
};

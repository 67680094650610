import { Suspense, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  ColorsV2,
  Flex,
  Text,
  useBreakpoint,
  useThemeColors,
} from '@electricjs/arc';

import ADPConsentRequiredBannerWrapper from '@/components/ADP/ADPConsentRequiredBannerWrapper';
import SideNav from '@/components/Nav/SideNav/SideNavWrapper';
import TopNav from '@/components/Nav/TopNav/TopNavWrapper';
import PageSpinner from '@/components/PageSpinner';
import { useAppSelector } from '@/redux/store';
import { OrganizationUserRoleSlugs } from '@/types/users';
import { useRouteReloader } from '@/hooks/useRouteReloader';
import { GlobalUIProvider } from '@/components/GlobalUIProvider';

const DefaultPageContent = ({
  hasProductSupportBanner,
  bannerHeight,
}: {
  hasProductSupportBanner: boolean;
  bannerHeight: string;
}) => {
  const [showSideNav, setShowSideNav] = useState(true);
  const { md } = useBreakpoint();
  const location = useLocation();
  const scrollableWrapperRef = useRef<HTMLElement>(null);

  useEffect(() => {
    setShowSideNav(Boolean(md));
  }, [md]);

  // Temporary workaround for react-router's behavior of retaining scroll position
  // when navigating between routes. This ensures the main scrollable content
  // resets to the top whenever the route changes.
  useLayoutEffect(() => {
    if (scrollableWrapperRef.current) {
      scrollableWrapperRef.current.scrollTo({ top: 0 });
    }
  }, [location.pathname]);

  const toggleSideNav = () => setShowSideNav(!showSideNav);

  // Medium to large screens:
  //   - The side bar always exists
  //   - Split into a main row:
  //     - Left: SideNav
  //     - Right: TopBar + Content (in a column)
  if (md) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        height={
          hasProductSupportBanner ? `calc(100vh - ${bannerHeight})` : '100vh'
        }
        backgroundColor={ColorsV2.BACKGROUND}
        width="100vw"
        overflow="hidden">
        {/* ......... left side (SideNav) ......... */}
        <SideNav />
        {/* ......... right side (TopBar + Content) ......... */}
        <Box
          flexGrow="1" // right side: will grow in width (to the right)
          display="flex"
          flexDirection="column"
          overflowY="hidden">
          <TopNav />
          <Box
            flexGrow="1" // content: will grow in height (to the bottom)
            overflowY="auto" // can scroll the content
            ref={scrollableWrapperRef}>
            <Suspense fallback={<PageSpinner />}>
              <ADPConsentRequiredBannerWrapper />
              <Outlet />
            </Suspense>
          </Box>
        </Box>
      </Box>
    );
  }

  // Small screens:
  //   - The side bar is controlled by the TopBar menu button
  //   - Split into a main column: TopBar + "inner row"
  //   - The inner row:
  //     - Left: SideNav
  //     - Right: Content
  return (
    <Box
      display="flex"
      flexDirection="column"
      overflow="hidden"
      height={
        hasProductSupportBanner ? `calc(100vh - ${bannerHeight})` : '100vh'
      }
      width="100vw">
      <TopNav onMenuClick={toggleSideNav} />
      <Box
        flexGrow="1" // will grow in height (to the bottom)
        display="flex"
        flexDirection="row"
        backgroundColor={ColorsV2.BACKGROUND}
        overflow="hidden">
        {/* ......... left side (SideNav) ......... */}
        {showSideNav ? <SideNav mobileOnMenuClick={toggleSideNav} /> : null}
        {/* ......... right side (Content) ......... */}
        <Box
          flexGrow="1" // will grow in width (to the right)
          overflowY="auto" // can scroll the content
          ref={scrollableWrapperRef}>
          <Suspense fallback={<PageSpinner />}>
            <ADPConsentRequiredBannerWrapper />
            <Outlet />
          </Suspense>
        </Box>
      </Box>
    </Box>
  );
};

export const DefaultPageWrapper = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const loggedUser = useAppSelector(state => state.loggedUser);
  const hasProductSupportRole =
    (
      loggedUser?.organizationUserRoles?.filter(
        role => role.slug === OrganizationUserRoleSlugs.PRODUCT_SUPPORT
      ) || []
    ).length > 0;
  const organizationName = loggedUser?.organizationName;
  const bannerHeight = '5rem';
  const [bannerColor, shadowColor] = useThemeColors([
    ColorsV2.WARNING_LIGHTEST,
    ColorsV2.GRAY,
  ]);

  const handleClick = () => {
    if (location.pathname !== '/switcher') {
      navigate('/switcher');
    }
  };

  // **** Route interceptor **** //
  // This hook is used to reload the page when the app version changes
  useRouteReloader();

  return (
    // We are re-initializing the GlobalUIProvider here to ensure that the Provider used is inside the Route component, making it possible to use Router based Links inside modals/toasts.
    <GlobalUIProvider>
      {hasProductSupportRole && (
        <Flex
          justifyContent="space-between"
          alignItems="center"
          py="0.5rem"
          px="2rem"
          height={bannerHeight}
          width="100%"
          backgroundColor={bannerColor}
          boxShadow={`0 0 8px ${shadowColor}`}
          position="relative"
          zIndex="4" // Puts the banner above the TopBar & SideNav
          overflow="hidden">
          <Text variant="label-small">
            You are currently accessing &quot;{organizationName}&quot; as
            Product Support.
          </Text>
          <Button
            id="button-switch-orgs-banner"
            variant="link"
            intent="neutral"
            onClick={handleClick}>
            Switch Orgs
          </Button>
        </Flex>
      )}
      <DefaultPageContent
        hasProductSupportBanner={hasProductSupportRole}
        bannerHeight={bannerHeight}
      />
    </GlobalUIProvider>
  );
};

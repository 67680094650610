type Auth0CandidateIdentity = {
  connection: string;
  provider: string;
  user_id: string;
  isSocial: boolean;
};

export type Auth0CandidateUser = {
  email_verified: boolean;
  email: string;
  identities: Array<Auth0CandidateIdentity>;
  user_id?: string;
  sub?: string;
  name: string;
  nickname: string;
  picture: string;
};

export type AccountLinkToken = {
  sub: string;
  current_user: Auth0CandidateUser;
  candidate_users: Array<Auth0CandidateUser>;
  candidate_identities: Array<Auth0CandidateIdentity>;
  idToken: string;
};

export type HandleAccountLinkArgs = {
  targetUserIDToken: string;
  targetUserAccessToken: string;
  targetUserId: string;
  makePrimary: boolean;
};

export enum AuthProvider {
  Auth0 = 'auth0',
  Google = 'google-oauth2',
  ADPWorkforceNow = 'oauth2|adp-workforce-now',
  ADPRun = 'oauth2|adp-run',
  Unknown = 'unknown',
}

export enum PrimaryConnections {
  Electric = 'Electric',
  UsernamePassword = 'Username-Password-Authentication',
  LegacyElectric = 'Turbine-Production',
}

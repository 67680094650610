import { Flex, Text } from '@electricjs/arc';

import ApplicationIcon from '@/components/Applications/ApplicationIcon';
import { Application } from '@/types/applications';
import { Group } from '@/types/groups';

type AppListItemProps = {
  application: Application;
};
const AppListItem = ({ application }: AppListItemProps) => {
  return (
    <Flex vAlignContent="center" width="18rem">
      <Flex mr="4" vertical>
        <ApplicationIcon
          name={application.name}
          icon={application.icon}
          size="3rem"
        />
      </Flex>
      <Flex vertical>
        <Flex>
          <Text variant="legal">{application.name}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

type EmployeeAppsByGroupAppItemProps = {
  group: Group;
  applications: Application[];
};

const EmployeeAppsByGroupAppItem = ({
  group,
  applications,
}: EmployeeAppsByGroupAppItemProps) => {
  // Do not render if the list of applications is empty
  if (applications.length === 0) {
    return null;
  }

  return (
    <Flex flexDirection="column" width="100%">
      <Text variant="label-large" mb="1rem" mt=".5rem">
        {group.name}
      </Text>
      <Flex gap=".8rem" wrap width="100%">
        {applications.map(application => {
          return <AppListItem application={application} key={application.id} />;
        })}
      </Flex>
    </Flex>
  );
};

export default EmployeeAppsByGroupAppItem;

import { useGlobalUI } from '@/components/GlobalUIProvider';
import {
  EmptyState as ArcEmptyState,
  ColorsV2,
  Flex,
  IconNames,
  Text,
} from '@electricjs/arc';
import { DeviceIssuesInfoModalText } from '../IssueModals/InfoModalText';
import { DeviceIssueType } from '@/types/devices';
import { DeviceIssuesInfoModal } from '../IssueModals/InfoModal';

type EmptyStateProps = {
  title: string;
  details: string[];
  icon: IconNames;
  selectedTab?: DeviceIssueType;
};

const ModalButtonProps = {
  variant: 'text' as const,
  size: 'medium' as const,
};

export const EmptyState = ({
  title,
  details,
  icon,
  selectedTab,
}: EmptyStateProps) => {
  const { openModal, closeModal } = useGlobalUI();

  if (selectedTab === undefined) {
    return null;
  }

  return (
    <Flex
      flexDirection="column"
      width="100%"
      alignItems="center"
      justifyContent="center"
      height="43rem">
      <ArcEmptyState
        intent="success"
        title={title}
        subTitle={
          <>
            {details.map((detail, index) => (
              <Text key={index} maxWidth="64rem" textAlign="center">
                {detail}
              </Text>
            ))}
          </>
        }
        size="6.5rem"
        customIconSize="2.8rem"
        icon={icon}
        backgroundColor={ColorsV2.SUCCESS_LIGHTEST}
        primaryCtaButtonText={DeviceIssuesInfoModalText[selectedTab].buttonText}
        primaryCtaButtonProps={ModalButtonProps}
        primaryCtaOnClick={() =>
          openModal(
            <DeviceIssuesInfoModal
              visible
              hide={closeModal}
              deviceIssueType={selectedTab}
            />
          )
        }
      />
    </Flex>
  );
};

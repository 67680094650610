import {
  OffboardingResponse as OffboardingDto,
  OffboardingWithRequestCollectionStats as OffboardingWithRequestCollectionStatsDto,
  RequestResponse as RequestDto,
} from '@electricjs/core_entity-client';
import { ApiListResponseDto } from '@externalTypes/general';

import { TagTypes, apiSlice } from '@/redux/slices/apiSlice';
import {
  GetLatestOffboardingArgs,
  Offboarding,
  OffboardingCreationArgs,
  OffboardingGetArgs,
  OffboardingWithRequestCollectionStats,
  OrganizationOffboardingsGetArgs,
} from '@/types/offboardings';
import { Request } from '@/types/requests';

import { transformEmployee } from './employeeApiSlice';
import {
  invalidatesList,
  providesById,
  providesListAndAllById,
} from './helpers/cacheTagHelpers';
import { transformUser } from './userApiSlice';
import { transformRequest } from './requestApiSlice';
import { camelize } from './helpers/responseCamelizer';

export function transformOffboarding(offboarding: OffboardingDto): Offboarding {
  return {
    id: offboarding.id,
    employee: transformEmployee(offboarding.employee),
    requester: transformUser(offboarding.requester),
    requestCollection: offboarding.request_collection,
    createdAt: offboarding.created_at,
    updatedAt: offboarding.updated_at,
    completedAt: offboarding?.completed_at ?? undefined,
    scheduledAt: offboarding?.scheduled_at ?? undefined,
  };
}

export const offboardingApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    createOffboarding: builder.mutation<Offboarding, OffboardingCreationArgs>({
      query: ({ employeeId, organizationId, scheduledAt }) => ({
        url: `/v1/organizations/${organizationId}/offboardings`,
        method: 'POST',
        body: {
          employee_id: employeeId,
          scheduled_at: scheduledAt,
        },
        transformResponse: (offboarding: OffboardingDto) => {
          return transformOffboarding(offboarding);
        },
      }),
      invalidatesTags: (_result, _error, args) => [
        ...invalidatesList(TagTypes.Offboarding),
        { type: TagTypes.EmployeeWithDevice, id: args.employeeId },
        {
          type: TagTypes.LatestOffboardingByEmployeeId,
          id: args.employeeId,
        },
        { type: TagTypes.ActiveXboardingEmployeeIds, id: args.organizationId },
        TagTypes.Employee, // to refresh all employee data (active, inactive, dropdown lists)
      ],
    }),
    getOffboarding: builder.query<Offboarding, OffboardingGetArgs>({
      query: ({ organizationId, offboardingId }) => {
        return {
          url: `/v1/organizations/${organizationId}/offboardings/${offboardingId}`,
        };
      },
      providesTags: (_result, _error, args) =>
        providesById(TagTypes.Offboarding, args.offboardingId),
      transformResponse: (offboarding: OffboardingDto) => {
        return transformOffboarding(offboarding);
      },
    }),
    getLatestOffboardingByEmployeeId: builder.query<
      Offboarding | undefined,
      GetLatestOffboardingArgs
    >({
      query: ({ organizationId, employeeId }) => {
        return {
          url: `/v1/organizations/${organizationId}/employees/${employeeId}/offboarding`,
        };
      },
      providesTags: (result, _error, args) =>
        result
          ? [
              ...providesById(TagTypes.Offboarding, result.id),
              ...providesById(
                TagTypes.LatestOffboardingByEmployeeId,
                args.employeeId
              ),
            ]
          : providesById(
              TagTypes.LatestOffboardingByEmployeeId,
              args.employeeId
            ),
      transformResponse: (offboarding: OffboardingDto) => {
        return offboarding ? transformOffboarding(offboarding) : undefined;
      },
    }),
    getRequestsForAnOffboarding: builder.query<Request[], OffboardingGetArgs>({
      query: ({ organizationId, offboardingId }) => {
        return {
          url: `/v1/organizations/${organizationId}/offboardings/${offboardingId}/requests`,
        };
      },
      transformResponse: (response: ApiListResponseDto<RequestDto>) => {
        return response.results.map(transformRequest);
      },
      providesTags: providesListAndAllById(TagTypes.Request),
    }),
    getOrganizationOffboardings: builder.query<
      OffboardingWithRequestCollectionStats[],
      OrganizationOffboardingsGetArgs
    >({
      query: ({ organizationId, activeOnly }) => {
        return {
          url: `/v1/organizations/${organizationId}/offboardings`,
          params: {
            active_only: activeOnly,
          },
        };
      },
      transformResponse: (
        response: OffboardingWithRequestCollectionStatsDto[]
      ) => camelize(response),
      providesTags: [TagTypes.Offboarding, TagTypes.Employee, TagTypes.Request],
    }),
  }),
});

export const {
  useGetOffboardingQuery,
  useGetLatestOffboardingByEmployeeIdQuery,
  useGetRequestsForAnOffboardingQuery,
  useLazyGetOffboardingQuery,
  useLazyGetLatestOffboardingByEmployeeIdQuery,
  useLazyGetRequestsForAnOffboardingQuery,
  useCreateOffboardingMutation,
  useGetOrganizationOffboardingsQuery,
} = offboardingApiSlice;

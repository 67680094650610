import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';

import { type CatalogCartEntry, type AssetCartEntry } from '@/types/storefront';
import { assetCartContainsWarehouseAsset, isLaptop } from '../utils';

type StorefrontCartContextType = {
  catalogCart: CatalogCartEntry[];
  setCatalogCart: (cart: CatalogCartEntry[]) => void;
  assetCart: AssetCartEntry[];
  setAssetCart: (cart: AssetCartEntry[]) => void;
  shippingCost?: number;
  setShippingCost: (value: number | undefined) => void;
  totalPrice: number;
  setTotalPrice: (value: number) => void;
};

type StorefrontCartProviderProps = {
  children: ReactNode;
};

const StorefrontCartContext = createContext<StorefrontCartContextType>({
  catalogCart: [],
  setCatalogCart: () => {},
  assetCart: [],
  setAssetCart: () => {},
  shippingCost: undefined,
  setShippingCost: () => {},
  totalPrice: 0,
  setTotalPrice: () => {},
});

export const StorefrontCartProvider = ({
  children,
}: StorefrontCartProviderProps) => {
  const [catalogCart, setCatalogCart] = useState<CatalogCartEntry[]>([]);
  const [assetCart, setAssetCart] = useState<AssetCartEntry[]>([]);
  const [totalPrice, setTotalPrice] = useState(0);

  const [shippingCost, setShippingCost] = useState<number | undefined>();

  return (
    <StorefrontCartContext.Provider
      value={{
        catalogCart,
        setCatalogCart: useCallback(cart => setCatalogCart(cart), []),
        assetCart,
        setAssetCart: useCallback(cart => setAssetCart(cart), []),
        shippingCost,
        setShippingCost: useCallback(value => setShippingCost(value), []),
        totalPrice,
        setTotalPrice: useCallback(value => setTotalPrice(value), []),
      }}>
      {children}
    </StorefrontCartContext.Provider>
  );
};

export const useStorefrontCart = () => {
  const {
    catalogCart,
    setCatalogCart,
    assetCart,
    setAssetCart,
    shippingCost,
    setShippingCost,
    totalPrice,
    setTotalPrice,
  } = useContext(StorefrontCartContext);

  const itemsInCart =
    assetCart?.length +
    catalogCart?.reduce((acc, entry) => {
      // Add warranty as item only if the item is a laptop and the warranty selected is different from the default warranty
      if (
        isLaptop(entry.item) &&
        'defaultWarranty' in entry.item &&
        entry.selectedWarranty?.id !== entry.item?.defaultWarranty?.id
      ) {
        return acc + entry.quantity * 2;
      }
      return acc + entry.quantity;
    }, 0);

  const hasOnlyAssetsInCart = assetCart.length > 0 && catalogCart.length === 0;
  const cartContainsWarehouseAsset = assetCartContainsWarehouseAsset(assetCart);

  const hasOnlyProductsInCart =
    catalogCart.length > 0 && assetCart.length === 0;

  // If we're helping with shipping, we need to know the shipping address
  // Current cases are as follows:
  // - If they are buying items from the catalog
  // - If they are selecting assets that are stored at the warehouse
  const cartRequiresShippingAddress =
    catalogCart.length > 0 || cartContainsWarehouseAsset;

  // Currently, we only allow selection of a shipping method for catalog orders
  // Eventually we'll want to allow selection for asset orders as well
  const cartRequiresShippingMethod = catalogCart.length > 0;

  const cartRequiresPayment = totalPrice > 0;

  const hasMixedCart = catalogCart.length > 0 && assetCart.length > 0;

  const subTotalPrice = catalogCart.reduce((acc, entry) => {
    const warrantyPrice = entry.selectedWarranty?.price ?? 0;
    return acc + (entry.item.price + warrantyPrice) * entry.quantity;
  }, 0);

  return {
    catalogCart,
    setCatalogCart,
    assetCart,
    setAssetCart,
    itemsInCart,
    hasOnlyAssetsInCart,
    cartRequiresShippingAddress,
    cartRequiresShippingMethod,
    cartRequiresPayment,
    shippingCost,
    setShippingCost,
    hasOnlyProductsInCart,
    hasMixedCart,
    totalPrice,
    setTotalPrice,
    subTotalPrice,
  };
};

import { sortBy } from 'lodash';
import { useMemo } from 'react';
import styled from 'styled-components';

import {
  Box,
  ColorsV2,
  Divider,
  Flex,
  Pane,
  TabPanel,
  Text,
  getToken,
  useThemeColors,
} from '@electricjs/arc';

import {
  useGetOrganizationEmployeesQuery,
  useGetRequestTypeCategoriesQuery,
} from '@/redux/slices/organizationApiSlice';

import { Link } from 'react-router-dom';

import type { RequestTypeCategory } from '@/types/requests';

import ChampSelector from './ChampSelector';

const StyledPane = styled(Pane)`
  border: 1px solid ${getToken(ColorsV2.BORDER)};
  flex-direction: column;
`;

const SupportCenterPane = ({ organizationId }: { organizationId: string }) => {
  const [borderColor] = useThemeColors([ColorsV2.BORDER]);

  const { data: categories } = useGetRequestTypeCategoriesQuery({
    organizationId: organizationId,
  });
  const sortedCategories = sortBy(categories, ['name']);

  const { data: employees } = useGetOrganizationEmployeesQuery({
    organizationId,
  });

  const sortedEmployees = useMemo(
    () =>
      sortBy(employees ?? [], e =>
        `${e.firstName} ${e.lastName}`.toLowerCase()
      ),
    [employees]
  );

  const championOptions = useMemo(
    () =>
      sortedEmployees.map(e => ({
        employeeId: e.id,
        employee: e,
        label: `${e.firstName} ${e.lastName}`,
      })),
    [sortedEmployees]
  );

  const renderCategoryContent = (category: RequestTypeCategory) => (
    <Flex vertical width="100%" key={category.id} marginBottom={6}>
      <Box marginBottom={1}>
        <Text variant="label-input">{category.name}</Text>
      </Box>
      <Box width="100%" marginBottom={2}>
        <ChampSelector
          key={category.id}
          organizationId={organizationId}
          categoryId={category.id}
          options={championOptions}
        />
      </Box>
      {category.name.toLowerCase() === 'applications' && (
        <Text>
          If you&apos;d like to customize who handles application requests,
          update the App Champions on the individual{' '}
          <Link to="/applications">application&apos;s</Link> settings page.
        </Text>
      )}
    </Flex>
  );

  const categoryContent = sortedCategories.map(renderCategoryContent);

  return (
    <TabPanel>
      <StyledPane width="100%">
        <Flex vertical rowGap="0.5rem" p="2.5rem">
          <Text variant="heading-2">Support Center</Text>
        </Flex>
        <Divider color={borderColor} />
        <Flex p="3rem" justifyContent="space-between" width="100%">
          <Box width="40%">
            <Text variant="heading-3" marginBottom={2}>
              Request Category Champions
            </Text>
            <Text variant="body">
              Choose where requests will be sent for review.
            </Text>
          </Box>
          <Flex vertical marginLeft={4} width="100%">
            {categoryContent}
          </Flex>
        </Flex>
      </StyledPane>
    </TabPanel>
  );
};

export default SupportCenterPane;

import { OnboardingResponse as OnboardingDto } from '@electricjs/core_entity-client';

import { simpleEmployee } from './employeeData';
import { simpleRequestCollection } from './requestData';
import { simpleUser } from './userData';

export const simpleOnboarding: OnboardingDto = {
  id: '002df5c0-054f-11ee-be56-0242ac120005',
  employee: simpleEmployee,
  requester: simpleUser,
  request_collection: simpleRequestCollection,
  created_at: '2021-09-30T15:18:24.000Z',
  updated_at: '2021-09-30T15:18:24.000Z',
};

export const simpleCompletedOnboarding: OnboardingDto = {
  id: '002df5c0-054f-11ee-be56-0242ac120004',
  employee: simpleEmployee,
  requester: simpleUser,
  request_collection: simpleRequestCollection,
  created_at: '2021-09-30T15:18:24.000Z',
  updated_at: '2021-09-30T15:18:24.000Z',
  completed_at: simpleRequestCollection.completed_at,
};

export const mockedOnboardings: OnboardingDto[] = [];
export const simpleMockedOnboardings: OnboardingDto[] = [
  simpleOnboarding,
  simpleCompletedOnboarding,
];

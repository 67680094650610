import { Button, Flex, Radio, RadioGroup, Text } from '@electricjs/arc';

enum YesNoOption {
  YES = 'yes',
  NO = 'no',
}

type DeviceQuestionProps = {
  hasMacDevices: boolean;
  onSelectionChange: (hasMacDevices: boolean) => void;
  onSaveInfo: () => void;
  isLoading?: boolean;
};

export const DeviceQuestion = ({
  hasMacDevices,
  onSelectionChange,
  onSaveInfo,
  isLoading = false,
}: DeviceQuestionProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSelectionChange(e.target.value === YesNoOption.YES);
  };

  return (
    <Flex
      flexDirection="column"
      p="2.5rem 3rem"
      width="100%"
      flexWrap="wrap"
      gap="2.2rem"
      data-testid="device-question">
      <Text variant="body" maxWidth="60rem">
        Before we begin, please let us know if your company uses Apple devices.
        The process for setting up MDM differs slightly for companies using only
        Windows devices.
      </Text>
      <Text variant="label-input">Does your company use Apple devices?</Text>
      <RadioGroup stack>
        <Radio
          id="yes-apple-or-mix-radio"
          value={YesNoOption.YES}
          checked={hasMacDevices}
          onChange={handleChange}>
          Yes, we use only Apple devices or a mix of Apple and Windows devices.
        </Radio>
        <Radio
          id="no-only-windows-radio"
          value={YesNoOption.NO}
          checked={!hasMacDevices}
          onChange={handleChange}>
          No, we use only Windows devices.
        </Radio>
      </RadioGroup>
      <Flex gap="2rem" minWidth="27rem">
        <Button
          id="start-mdm-setup-button"
          testId="start-mdm-setup-button"
          onClick={onSaveInfo}
          loading={isLoading}>
          Set up MDM
        </Button>
      </Flex>
    </Flex>
  );
};

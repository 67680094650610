import { useNavigate } from 'react-router-dom';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { Banner, Button, Flex, Text } from '@electricjs/arc';
import { useGetMDMInformationQuery } from '@/redux/slices/mdmManagementApiSlice';
import { differenceInDays } from 'date-fns';
import { EntitlementSlugEnum } from '@electricjs/core_entity-client';
import { useOrganizationHasEntitlement } from '@/hooks/useOrganizationHasEntitlement';
import { skipToken } from '@reduxjs/toolkit/query';

const MDMCertificateExpirationBanner = () => {
  const navigate = useNavigate();

  const organizationId = useGetOrganizationId();

  const hasMdmEntitlement = useOrganizationHasEntitlement(
    EntitlementSlugEnum.JumpcloudMdm
  );

  const { data } = useGetMDMInformationQuery(
    hasMdmEntitlement ? { organizationId } : skipToken
  );

  if (!hasMdmEntitlement) return null;

  const expirationDate =
    data?.appleCertificate?.expiresAt !== undefined
      ? new Date(data.appleCertificate.expiresAt)
      : null;

  if (!expirationDate) return null;

  const currentDate = new Date();
  const daysDifference = differenceInDays(expirationDate, currentDate);

  const isExpirationAboutToExpire = daysDifference <= 14 && daysDifference > 0;
  const isExpired = daysDifference <= 0;

  const AboutToExpireBanner = (
    <Flex mb="2rem">
      <Banner
        id="mdm-certificate-expiration-banner"
        isFlat
        isFullWidth
        title="Action needed: Renew MDM (Mobile Device Management)"
        intent="warning"
        message={() => (
          <Text>
            Your Apple Signing Certificate (CSR) will expire on{' '}
            <b>{expirationDate.toLocaleDateString()}</b>
            . If the CSR expires, MDM communication with already enrolled
            devices will cease, and new devices cannot be enrolled.
            <br />
            Renew today using the same Apple ID{' '}
            <b>{data?.appleCertificate?.creatorAppleId}</b> if possible to
            ensure continued device management and security.
          </Text>
        )}
        cta={
          <Flex mt="1rem">
            <Button
              id="mdm-renew-now-button"
              iconAfter="arrow-right"
              onClick={() =>
                navigate('/settings?tab=device', {
                  state: { renewCertificate: true },
                })
              }
              intent="warning"
              variant="outline">
              Renew now
            </Button>
          </Flex>
        }
      />
    </Flex>
  );

  const ExpiredBanner = (
    <Flex mb="2rem">
      <Banner
        id="mdm-certificate-expiration-banner"
        isFlat
        isFullWidth
        title="Action needed: Renew MDM (Mobile Device Management)"
        intent="error"
        message={() => (
          <Text>
            Your Apple Signing Certificate (CSR) expired on{' '}
            <b>{expirationDate.toLocaleDateString()}</b>. MDM communication with
            already enrolled devices has ceased, and new devices cannot be
            enrolled.
            <br />
            Renew today using the same Apple ID{' '}
            <b>{data?.appleCertificate?.creatorAppleId}</b> if possible to
            ensure continued device management and security.
          </Text>
        )}
        cta={
          <Button
            id="mdm-renew-now-button"
            iconAfter="arrow-right"
            onClick={() =>
              navigate('/settings?tab=device', {
                state: { renewCertificate: true },
              })
            }
            intent="danger"
            variant="outline">
            Renew now
          </Button>
        }
        ctaSecondary="Contact support"
        onClickCtaSecondary={() =>
          (window.location.href = 'mailto:product-support@electric.ai')
        }
      />
    </Flex>
  );

  if (isExpired) {
    return ExpiredBanner;
  }

  if (isExpirationAboutToExpire) {
    return AboutToExpireBanner;
  }

  return null;
};

export default MDMCertificateExpirationBanner;

import { useStoredState } from '../../common/useStoredState';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { DefaultTheme } from 'styled-components';

import { ThemeProvider as ArcThemeProvider, themes } from '@electricjs/arc';

import useThemeDetector from '@/hooks/useThemeDetector';

// TODO: Future Arc release: Get the enum values from the theme object names after they're exported
// https://github.com/ElectricAI/electricjs-arc/blob/a6c40635d0bc9c0743b233b431a5eb82fff45a63/src/themes/themes.ts#L11-L14
export enum Themes {
  LIGHT = 'Electric',
  AUTO = 'System',
  DARK = 'Electric Dark',
}

type ThemeName = DefaultTheme['name'];

type ThemeContextProps = {
  theme: DefaultTheme;
  themeName: ThemeName;
  setThemeValue: (_: ThemeName) => void;
};

export const ThemeContext = createContext({
  theme: themes.ELECTRIC,
  themeName: themes.ELECTRIC.name,
  setThemeValue: _ => themes.ELECTRIC.name,
} as ThemeContextProps);

export const ThemeProvider: React.FC = ({ children }) => {
  const { electrolyteDarkmode } = useFlags();
  const { ELECTRIC, ELECTRIC_DARK } = themes;
  const [themeName, setThemeName] = useState(ELECTRIC.name);

  const [electrolyteTheme, setElectrolyteTheme] = useStoredState(
    'electrolyte-theme',
    ELECTRIC.name
  );

  const setThemeValue = useCallback(
    (name: ThemeName) => {
      setThemeName(name);
      setElectrolyteTheme(name || '');
    },
    [setElectrolyteTheme]
  );

  useEffect(() => {
    if (electrolyteDarkmode) {
      setThemeName(electrolyteTheme);
    }
  }, [electrolyteDarkmode, electrolyteTheme]);

  const isDarkTheme = useThemeDetector();

  const theme = useMemo(() => {
    if (themeName === Themes.AUTO) {
      return isDarkTheme ? ELECTRIC_DARK : ELECTRIC;
    } else {
      return (
        Object.values(themes).find(
          currentTheme => currentTheme.name === themeName
        ) || ELECTRIC
      );
    }
  }, [themeName, isDarkTheme, ELECTRIC_DARK, ELECTRIC]);

  return (
    <ArcThemeProvider theme={theme}>
      <ThemeContext.Provider value={{ theme, themeName, setThemeValue }}>
        {children}
      </ThemeContext.Provider>
    </ArcThemeProvider>
  );
};

import { Button, Flex, Spacer, Text } from '@electricjs/arc';
import { Fragment } from 'react';
import ThemeSwitcher from '../ThemeSwitcher';
import { NavLink } from 'react-router-dom';
import { TopNavMenuItem } from './types';
import { Organization } from '@/types/organizations';
import { handleSwitchOrg } from '@/pages/OrgSwitcher/utils';
import { UserOrganization } from '@/types/users';
import { handleCloseNavOnMenuItemClick } from './TopNavMenu';

const filterActiveOrganization = (
  currentOrgId: string,
  organizations: Organization[]
) => organizations.filter(org => org.id !== currentOrgId);

type MenuItemProps = {
  isNewNav: boolean;
  isEndUserHub?: boolean;
  isAdminRole?: boolean;
  isDemoOrg?: boolean;
  itHubSettingsPage: boolean;
  showOrganizationSwitcher: boolean;
  hasElectricAppEntitlement: boolean;
  hasElectricPermissions: boolean;
  itHubBaseUrl?: string;
  electrolyteDarkmode: boolean;
  organizationId: string;
  myOrganizations?: Organization[];
  myOrgRoles?: {
    [key: string]: UserOrganization;
  };
  isFetchingMyOrganizations?: boolean;
  isErrorMyOrganizations?: boolean;
};

// TODO: Write a test for this - https://electricops.atlassian.net/browse/UIBB-3
const generateMenuItems = ({
  isNewNav,
  isEndUserHub = false,
  isAdminRole = true,
  isDemoOrg = false,
  itHubSettingsPage,
  showOrganizationSwitcher = false,
  hasElectricAppEntitlement,
  hasElectricPermissions = false,
  itHubBaseUrl,
  electrolyteDarkmode,
  organizationId,
  myOrganizations,
  myOrgRoles,
  isFetchingMyOrganizations,
  isErrorMyOrganizations,
}: MenuItemProps) => {
  const filteredOrganizations = filterActiveOrganization(
    organizationId,
    myOrganizations ?? []
  );
  const shouldShowOrgQuickSwitcher =
    showOrganizationSwitcher &&
    !isFetchingMyOrganizations &&
    !isErrorMyOrganizations &&
    !!myOrgRoles &&
    filteredOrganizations.length > 0;

  const newMenuItems = [
    // Note: This settings page is app-specific since both apps have their own settings page.
    // This is why the URL is the same no matter the app.
    ((isEndUserHub && isAdminRole) || (itHubSettingsPage && isAdminRole)) && {
      text: 'Settings',
      icon: 'cog',
      component: (
        <NavLink to={isEndUserHub ? `${itHubBaseUrl}/settings` : '/settings'} />
      ),
    },
    hasElectricAppEntitlement && {
      text: 'Electric for Desktop',
      icon: 'bolt',
      component: (
        <NavLink
          to={window._env_?.VITE_DESKTOP_APP_DOWNLOAD_URL}
          target="_blank"
        />
      ),
    },
    electrolyteDarkmode && {
      customItem: (
        <Fragment key="theme-switcher">
          <Spacer space="1rem" />
          <ThemeSwitcher key="theme-switcher" shouldHaveBottomDivider={false} />
        </Fragment>
      ),
    },
    isAdminRole && {
      text: 'View plans',
      icon: 'list-check',
      component: (
        <NavLink to={isEndUserHub ? `${itHubBaseUrl}/plans` : '/plans'} />
      ),
    },
    showOrganizationSwitcher && {
      text: 'Switch organization',
      icon: 'arrow-left',
      /**
       * Quick Switcher logic:
       * - If the user has more than 1 org, show the quick switcher
       * - If the user has only 1 org, show the switcher link
       */
      ...(shouldShowOrgQuickSwitcher
        ? {}
        : {
            component: <NavLink to="/switcher" />,
          }),
      subNavItems: shouldShowOrgQuickSwitcher
        ? [
            {
              customItem: (
                <Flex
                  key="switcher-header"
                  pb="8px"
                  px="8px"
                  justifyContent="space-between"
                  alignItems="center">
                  <Text mr="1rem" variant="label-small">
                    Your Organizations
                  </Text>
                  <Text variant="label-small">
                    <NavLink
                      to="/switcher"
                      onClick={() => handleCloseNavOnMenuItemClick()}>
                      View all
                    </NavLink>
                  </Text>
                </Flex>
              ),
            },
            ...filteredOrganizations.map((org: Organization) => ({
              customItem: (
                <Button
                  key={org.id}
                  id={`button-switch-org-${org.id}`}
                  onClick={handleSwitchOrg({
                    orgId: org.id,
                    myOrgRoles: myOrgRoles ?? {},
                    setError: () => console.error('Error switching org'),
                  })}
                  variant="text"
                  intent="neutral"
                  truncateLabelAt="20rem"
                  width="calc(100% - 1rem)"
                  mx="0.5rem">
                  {org.name}
                </Button>
              ),
            })),
          ]
        : undefined,
    },
    isAdminRole &&
      isDemoOrg && {
        text: 'Demo actions',
        icon: 'arrow-left',
        subNavItems: [
          {
            text: 'Create HCM Onboard',
            icon: 'add-user',
            component: <NavLink to="/onboardings/hris/new" />,
          },
          {
            text: 'Create HCM Offboard',
            icon: 'remove-circle',
            component: <NavLink to="/offboardings/hris/new" />,
          },
        ],
      },
    hasElectricPermissions && {
      text: 'Internal portal',
      icon: 'wrench',
      component: <NavLink to="/internal" />,
    },
  ].filter(Boolean) as TopNavMenuItem[];

  const menuItems = [
    electrolyteDarkmode && {
      customItem: (
        <ThemeSwitcher
          key="theme-switcher"
          shouldHaveBottomDivider={isAdminRole}
        />
      ),
    },
    // Note: This settings page is app-specific since both apps have their own settings page.
    // This is why the URL is the same no matter the app.
    ((isEndUserHub && isAdminRole) || (itHubSettingsPage && isAdminRole)) && {
      text: 'Settings',
      icon: 'cog',
      component: (
        <NavLink to={isEndUserHub ? `${itHubBaseUrl}/settings` : '/settings'} />
      ),
    },
    isAdminRole && {
      text: 'View plans',
      icon: 'list-check',
      component: (
        <NavLink to={isEndUserHub ? `${itHubBaseUrl}/plans` : '/plans'} />
      ),
    },
    showOrganizationSwitcher && {
      text: 'Switch organization',
      icon: 'building',
      component: <NavLink to="/switcher" />,
    },
  ].filter(Boolean) as TopNavMenuItem[];

  return isNewNav ? newMenuItems : menuItems;
};

export default generateMenuItems;

import {
  ColorsV2,
  Flex,
  IconInCircle,
  ModalAction,
  ModalBody,
  ModalFooter,
  ModalFooterActions,
  ModalHeader,
  ModalV2,
  Text,
} from '@electricjs/arc';

type HRISConnectedSuccessfullyModalProps = {
  onClose: () => void;
};

const HRISConnectedSuccessfullyModal = ({
  onClose,
}: HRISConnectedSuccessfullyModalProps) => {
  return (
    <ModalV2
      ariaLabelledby="hris-connected-successfully-modal"
      hide={onClose}
      visible>
      <ModalHeader py={1}>
        <Text variant="heading-2">HRIS has successfully connected</Text>
      </ModalHeader>
      <ModalBody px="5rem" py={10}>
        <Flex vertical hAlignContent="center">
          <IconInCircle
            intent="primary"
            background={ColorsV2.PRIMARY_LIGHTEST}
            icon="users-gear"
            mb="1rem"
            size="7rem"
            iconSize="large"
            smoothedBorder
          />
          <Text textAlign="center">
            We are syncing with your HRIS to populate your employees. This may
            take a while.
          </Text>
          <Text mt="2rem" textAlign="center">
            We&apos;ll alert you at the top of the page when your employees are
            populated.
          </Text>
        </Flex>
      </ModalBody>
      <ModalFooter py={16}>
        <ModalFooterActions>
          <ModalAction id="hris-connected-modal-close">Close</ModalAction>
        </ModalFooterActions>
      </ModalFooter>
    </ModalV2>
  );
};

export default HRISConnectedSuccessfullyModal;

import { ReactNode } from 'react';

import {
  Chip,
  ColorsV2,
  Flex,
  IconInCircle,
  IconNames,
  Pane,
  Text,
} from '@electricjs/arc';

type AddAssetTileProps = {
  title: string;
  subtitle: string;
  icon: IconNames;
  button: ReactNode;
  recommended?: boolean;
};

const AddAssetTile = ({
  title,
  subtitle,
  icon,
  button,
  recommended = false,
}: AddAssetTileProps) => {
  return (
    <Pane
      autoWidth
      elevated
      stack
      p="2rem"
      maxWidth="35rem"
      minHeight="25rem"
      gap="1.5rem">
      <Flex justifyContent="space-between" alignItems="center">
        <IconInCircle
          icon={icon}
          iconSize="medium"
          intent="info"
          size="4rem"
          background={ColorsV2.INFO_LIGHTEST}
        />
        {recommended && (
          <Chip
            id="recommended-asset-chip"
            backgroundColor={ColorsV2.PRIMARY_LIGHTEST}
            mt="-2rem"
            variant="small">
            <Text color={ColorsV2.PRIMARY} variant="legal">
              Recommended
            </Text>
          </Chip>
        )}
      </Flex>
      <Flex vertical flexGrow={1} justifyContent="space-between">
        <Flex vertical>
          <Text intent="primary" variant="label-large" textAlign="left">
            {title}
          </Text>
          <Text variant="body-2" textAlign="left" mt="1.5rem">
            {subtitle}
          </Text>
        </Flex>
        <Flex justifyContent="center" width="100%" mt="2rem">
          {button}
        </Flex>
      </Flex>
    </Pane>
  );
};

export default AddAssetTile;

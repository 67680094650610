import { type CustomComponents, Flex, Text } from '@electricjs/arc';

import { type SmartSearchSelectOption } from '../resultKindConfig';
import SearchResultDisplayOptionPicker from './SearchResultDisplayOptionPicker';

type CustomComponentOptionProps = {
  data: SmartSearchSelectOption;
};

export const searchResultCustomComponents: CustomComponents = {
  Option: ({ data: resultItem }: CustomComponentOptionProps) => {
    return <SearchResultDisplayOptionPicker resultItem={resultItem} />;
  },
  NoOptionsMessage: () => {
    return (
      <Flex hAlignContent="center" vertical>
        <Text variant="label-small">
          We couldn&apos;t find any results for your search.
        </Text>
        <Text mt="1rem" variant="label-small">
          Try searching for people, resources, or actions.
        </Text>
      </Flex>
    );
  },
};

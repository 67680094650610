import {
  OnboardingResponse as OnboardingDto,
  OnboardingWithRequestCollectionStats as OnboardingWithRequestCollectionStatsDto,
  RequestResponse as RequestDto,
} from '@electricjs/core_entity-client';
import { ApiListResponseDto } from '@externalTypes/general';

import { TagTypes, apiSlice } from '@/redux/slices/apiSlice';
import {
  GetLatestOnboardingArgs,
  Onboarding,
  OnboardingCreationArgs,
  OnboardingGetArgs,
  OnboardingWithRequestCollectionStats,
  OrganizationOnboardingsGetArgs,
} from '@/types/onboardings';
import { Request } from '@/types/requests';

import { transformEmployee } from './employeeApiSlice';
import {
  invalidatesList,
  providesById,
  providesListAndAllById,
} from './helpers/cacheTagHelpers';
import { camelize } from './helpers/responseCamelizer';
import { transformUser } from './userApiSlice';
import { transformRequest } from './requestApiSlice';

export function transformOnboarding(onboarding: OnboardingDto): Onboarding {
  return {
    id: onboarding.id,
    employee: transformEmployee(onboarding.employee),
    requester: transformUser(onboarding.requester),
    requestCollection: onboarding.request_collection,
    createdAt: onboarding.created_at,
    updatedAt: onboarding.updated_at,
    completedAt: onboarding?.completed_at ?? undefined,
  };
}

export const onboardingApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    createOnboarding: builder.mutation<Onboarding, OnboardingCreationArgs>({
      query: ({ employeeId, includesHardware, organizationId }) => ({
        url: `/v1/organizations/${organizationId}/onboardings`,
        method: 'POST',
        body: {
          employee_id: employeeId,
          includes_hardware: includesHardware,
        },
        transformResponse: (onboarding: OnboardingDto) => {
          return {
            id: onboarding.id,
            employee: onboarding.employee,
            requester: onboarding.requester,
            requestCollection: onboarding.request_collection,
            createdAt: onboarding.created_at,
            updatedAt: onboarding.updated_at,
            completedAt: onboarding?.completed_at ?? undefined,
          };
        },
      }),
      invalidatesTags: (_result, _error, args) => [
        ...invalidatesList(TagTypes.Onboarding),
        { type: TagTypes.EmployeeWithDevice, id: args.employeeId },
        {
          type: TagTypes.LatestOnboardingByEmployeeId,
          id: args.employeeId,
        },
        { type: TagTypes.ActiveXboardingEmployeeIds, id: args.organizationId },
        TagTypes.Employee, // to refresh all employee data (active, inactive, dropdown lists)
      ],
    }),
    getOnboarding: builder.query<Onboarding, OnboardingGetArgs>({
      query: ({ organizationId, onboardingId }) => {
        return {
          url: `/v1/organizations/${organizationId}/onboardings/${onboardingId}`,
        };
      },
      providesTags: (_result, _error, args) =>
        providesById(TagTypes.Onboarding, args.onboardingId),
    }),
    getOrganizationOnboardings: builder.query<
      OnboardingWithRequestCollectionStats[],
      OrganizationOnboardingsGetArgs
    >({
      query: ({ organizationId, activeOnly }) => {
        return {
          url: `/v1/organizations/${organizationId}/onboardings`,
          params: {
            active_only: activeOnly,
          },
        };
      },
      transformResponse: (
        response: OnboardingWithRequestCollectionStatsDto[]
      ) => camelize(response),
      providesTags: [TagTypes.Onboarding, TagTypes.Employee, TagTypes.Request],
    }),
    getLatestOnboardingByEmployeeId: builder.query<
      Onboarding | undefined,
      GetLatestOnboardingArgs
    >({
      query: ({ organizationId, employeeId }) => {
        return {
          url: `/v1/organizations/${organizationId}/employees/${employeeId}/onboarding`,
        };
      },
      providesTags: (result, _error, args) =>
        result
          ? [
              ...providesById(TagTypes.Onboarding, result.id),
              ...providesById(
                TagTypes.LatestOnboardingByEmployeeId,
                args.employeeId
              ),
            ]
          : providesById(
              TagTypes.LatestOnboardingByEmployeeId,
              args.employeeId
            ),
      transformResponse: (onboarding: OnboardingDto) => {
        return onboarding ? transformOnboarding(onboarding) : undefined;
      },
    }),
    getRequestsForAnOnboarding: builder.query<Request[], OnboardingGetArgs>({
      query: ({ organizationId, onboardingId }) => {
        return {
          url: `/v1/organizations/${organizationId}/onboardings/${onboardingId}/requests`,
        };
      },
      transformResponse: (response: ApiListResponseDto<RequestDto>) => {
        return response.results.map(transformRequest);
      },
      providesTags: providesListAndAllById(TagTypes.Request),
    }),
  }),
});

export const {
  useGetOnboardingQuery,
  useGetOrganizationOnboardingsQuery,
  useGetLatestOnboardingByEmployeeIdQuery,
  useGetRequestsForAnOnboardingQuery,
  useLazyGetOnboardingQuery,
  useLazyGetLatestOnboardingByEmployeeIdQuery,
  useLazyGetRequestsForAnOnboardingQuery,
  useCreateOnboardingMutation,
} = onboardingApiSlice;

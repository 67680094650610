import { OrderStatus } from '@electricjs/core_entity-client';

import { Order } from '@/types/orders';

export const getMostRecentDeviceOrders = (orders: Order[] | undefined) => {
  //we're only showing orders that are not cancelled
  const ordersFiltered = orders?.filter(
    order => order.status !== OrderStatus.Deleted
  );

  return ordersFiltered?.slice(0, 2);
};

import { Employee } from '@/types/employees';

export const formatAddress = (address: Employee['address']) => {
  if (!address) return '';

  const streetAddress = [address.streetAddress1, address.streetAddress2]
    .filter(Boolean)
    .join(' ');

  return [streetAddress, address.city, address.state, address.zip]
    .filter(Boolean)
    .join(', ');
};

import styled from 'styled-components';

import { ColorsV2, Link, Text, getToken } from '@electricjs/arc';

const StyledLink = styled(Link)`
  color: ${getToken(ColorsV2.PRIMARY)};
  text-decoration-color: ${getToken(ColorsV2.PRIMARY)};

  &:visited {
    color: ${getToken(ColorsV2.INFO_DARK)};
    text-decoration-color: ${getToken(ColorsV2.INFO_DARK)};
  }
`;

type MaybeLinkProps = {
  url: string;
  value: string;
  emptyValue?: string;
};

const MaybeLink = ({ url, value, emptyValue }: MaybeLinkProps) => {
  return url && value ? (
    <StyledLink variant="body-2" href={url}>
      {value}
    </StyledLink>
  ) : (
    <Text variant="body-2">{value || emptyValue}</Text>
  );
};

export default MaybeLink;

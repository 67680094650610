import styled from 'styled-components';
import { ReactNode } from 'react';
import { ColorsV2, Flex, getToken, Icon, Text } from '@electricjs/arc';
import StyledRouterLink from '@/components/StyledRouterLink';

const IconType = {
  WARNING: <Icon icon="alert-triangle" intent="warning" />,
  COMPLETED: <Icon icon="check" intent="success" />,
  ALERT: <Icon icon="alert-circle" intent="danger" />,
  COMPLETED_BY_ELECTRIC: <Icon icon="bolt" intent="primary" />,
  LAPTOP: <Icon icon="laptop" intent="neutral" />,
  PENDING: <Icon icon="clock-circle" intent="warning" />,
  WIPED: <Icon icon="broom" intent="neutral" />,
} as const;

type DescriptionColumnProps = {
  iconType: keyof typeof IconType;
  title: ReactNode;
  detail?: string;
  serialNumber?: string;
  assetId?: string;
  showIcon?: boolean;
};

const StyledLink = styled(StyledRouterLink)`
  && {
    color: ${getToken(ColorsV2.PRIMARY)};
  }
`;

export const DescriptionColumn = ({
  assetId,
  iconType,
  title,
  detail,
  serialNumber,
  showIcon = true,
}: DescriptionColumnProps) => {
  return (
    <Flex gap="1.5rem">
      {showIcon && IconType[iconType]}
      <Flex flexDirection="column">
        {typeof title === 'string' ? (
          <Text variant="label-large">{title}</Text>
        ) : (
          title
        )}
        {serialNumber && assetId && (
          <Text variant="label-small">
            Serial no:{' '}
            <StyledLink to={`/assets/${assetId}`}>{serialNumber}</StyledLink>
          </Text>
        )}
        {detail && <Text variant="body-2">{detail}</Text>}
      </Flex>
    </Flex>
  );
};

import styled from 'styled-components';

import { Flex, Skeleton } from '@electricjs/arc';

import EmployeeAppsByGroupAppItem from '@/components/People/EmployeeAppsByGroup/EmployeeAppsByGroupAppItem';
import { AppsByGroup } from '@/components/People/EmployeeAppsByGroup/useGetApplicationsForGroups';
import { Group } from '@/types/groups';

type EmployeeAppsByGroupProps = {
  employeeGroups: Group[] | undefined;
  isLoading: boolean;
  appsByGroup: AppsByGroup[] | undefined;
};

const StyledAppContainerFlex = styled(Flex)`
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem 0 1rem 2rem;
  width: 100%;
`;

const LoadingAppsByGroups = ({ groupCount }: { groupCount: number }) => {
  const loadingSkeleton = [...Array(groupCount)].map((_, i) => (
    <Skeleton key={i} width="27rem" height="3rem" />
  ));
  return <StyledAppContainerFlex>{loadingSkeleton}</StyledAppContainerFlex>;
};

const EmployeeAppsByGroup = ({
  employeeGroups,
  isLoading,
  appsByGroup,
}: EmployeeAppsByGroupProps) => {
  return (
    <>
      {employeeGroups && isLoading && (
        <LoadingAppsByGroups groupCount={employeeGroups.length} />
      )}
      {!isLoading && appsByGroup && (
        <StyledAppContainerFlex>
          {(appsByGroup ?? []).map(({ group, applications }) => {
            return (
              <EmployeeAppsByGroupAppItem
                key={group.id}
                group={group}
                applications={applications}
              />
            );
          })}
        </StyledAppContainerFlex>
      )}{' '}
    </>
  );
};

export default EmployeeAppsByGroup;

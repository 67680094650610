import { SideNavItemProps } from './SideNavItem';

type NavItemsProps = {
  isAdminOrSupportRole?: boolean;
  flags: Record<string, boolean>;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  NavLink?: React.ComponentType<any>;
  /* eslint-enable @typescript-eslint/no-explicit-any */
  entitlements: {
    hasApplicationsEntitlement?: boolean;
    hasEmployeeGroupsEntitlement?: boolean;
    hasAntivirusEntitlement?: boolean;
    hasEmailSecurityEntitlement?: boolean;
    hasGigawattEntitlement?: boolean;
    hasITPlanEntitlement?: boolean;
    hasElectricAppEntitlement?: boolean;
    hasEmployeeDataBreachEntitlement?: boolean;
  };
  permissions: {
    canReadAssets?: boolean;
  };
  hasHelpfulLinks?: boolean;
  showFreeTrial?: boolean;
};

function getSecurityNavItem(
  /* eslint-disable @typescript-eslint/no-explicit-any */
  NavLink: React.ComponentType<any> | undefined,
  /* eslint-enable @typescript-eslint/no-explicit-any */
  hasAntivirusEntitlement: boolean | undefined,
  hasEmailSecurityEntitlement: boolean | undefined,
  hasEmployeeDataBreachEntitlement: boolean | undefined,
  electrolyteShowSecurityPages: boolean,
  isAdminOrSupportRole: boolean | undefined
): SideNavItemProps {
  const securitySubNavItems: SideNavItemProps[] = [];

  if (hasAntivirusEntitlement) {
    securitySubNavItems.push({
      text: 'Antivirus',
      component: NavLink && <NavLink to="/security-solutions/antivirus" />,
    });
  }

  if (hasEmailSecurityEntitlement) {
    securitySubNavItems.push({
      text: 'Email security',
      component: NavLink && <NavLink to="/security-solutions/email-security" />,
    });
  }

  if (hasEmployeeDataBreachEntitlement) {
    securitySubNavItems.push({
      text: 'Password breaches',
      component: NavLink && <NavLink to="/password-breaches" />,
    });
  }

  const hasItems: boolean = securitySubNavItems.length > 0;

  // This item does not need a particular entitlement
  securitySubNavItems.push({
    text: 'Explore solutions',
    component: NavLink && <NavLink to="/security-solutions" end />,
  });

  return {
    icon: 'shield-check',
    text: 'Security',
    visible: isAdminOrSupportRole && electrolyteShowSecurityPages && hasItems,
    subNavItems: securitySubNavItems,
  };
}

// TODO: Write a test for this - https://electricops.atlassian.net/browse/UIBB-2
export const generateNavItems = ({
  isAdminOrSupportRole,
  flags,
  NavLink,
  entitlements,
  permissions,
  hasHelpfulLinks,
  showFreeTrial = false,
}: NavItemsProps): SideNavItemProps[] => {
  const {
    electrolyteAssets,
    electrolyteDeviceManagement,
    electrolyteDeviceOverview,
    electrolyteEndUserDiscover,
    electrolyteShowSecurityPages,
    electrolyteHideApplicationsPage,
    itHubDashboard,
  } = flags;
  const {
    hasApplicationsEntitlement,
    hasEmployeeGroupsEntitlement,
    hasAntivirusEntitlement,
    hasEmailSecurityEntitlement,
    hasGigawattEntitlement,
    hasITPlanEntitlement,
    hasElectricAppEntitlement,
    hasEmployeeDataBreachEntitlement,
  } = entitlements;
  const { canReadAssets } = permissions;
  const securityMenuView = getSecurityNavItem(
    NavLink,
    hasAntivirusEntitlement,
    hasEmailSecurityEntitlement,
    hasEmployeeDataBreachEntitlement,
    electrolyteShowSecurityPages,
    isAdminOrSupportRole
  );
  const devicesMenuView: SideNavItemProps = {
    icon: 'laptop',
    text: 'Devices',
    // Show if admin role & at least one of the flags is enabled
    visible:
      isAdminOrSupportRole && (electrolyteAssets || electrolyteDeviceOverview),
    subNavItems: [
      // Show if device overview FF is enabled & admin role
      {
        text: 'Device overview',
        visible: electrolyteDeviceOverview && hasElectricAppEntitlement,
        component: NavLink && <NavLink to="/device-overview" />,
      },
      // Show if device management FF is enabled & has Reading assets permission & admin role & has Electric app entitlement
      {
        text: 'Device management',
        visible:
          electrolyteDeviceManagement &&
          canReadAssets &&
          hasElectricAppEntitlement,
        component: NavLink && <NavLink to="/device-management" />,
      },
      // Show if assets FF is enabled & has Reading assets permission & admin role
      {
        text: 'Assets',
        visible: electrolyteAssets && canReadAssets,
        component: NavLink && <NavLink to="/assets" />,
      },
      // Show if admin role
      {
        text: 'Purchase hardware',
        component: NavLink && <NavLink to="/purchase-hardware" />,
      },
      // Show if admin role
      {
        text: 'Track purchase requests',
        component: NavLink && <NavLink to="/track-orders" />,
      },
      // Show if admin role
      {
        text: 'Ship a device',
        component: NavLink && <NavLink to="/ship-device" />,
      },
    ],
  };
  return [
    /**
     * Defaults:
     * - Is not EUH
     * - Internal pages are hidden
     * - Does not have employee groups entitlement
     * - Is admin role
     * - Does not have helpful links
     */
    /* ---- IT Hub Nav Items ---- */
    {
      // In ITH: Show if admin role
      // In EUH: Show if admin role
      sectionHeading: 'Admin Center',
      visible: isAdminOrSupportRole,
    },
    {
      icon: 'cards',
      text: 'Dashboard',
      visible: itHubDashboard,
      component: NavLink && <NavLink to="/dashboard" />,
    },
    {
      // In ITH: Show if admin role
      // In EUH: Show if admin role
      icon: 'list-check',
      text: 'Your IT Plan',
      visible: hasITPlanEntitlement && isAdminOrSupportRole,
      component: NavLink && <NavLink to="/your-it-plan" />,
    },
    {
      // In ITH: Show if FF enabled & admin role
      // In EUH: Show if FF enabled & admin role
      icon: 'sparkles',
      text: 'Discover',
      visible:
        electrolyteEndUserDiscover &&
        !electrolyteShowSecurityPages &&
        isAdminOrSupportRole,
      component: NavLink && <NavLink to="/discover" />,
    },
    devicesMenuView,
    securityMenuView,
    {
      // In ITH: Show if admin role
      // In EUH: Show if admin role
      icon: 'user-group',
      text: 'People',
      visible: isAdminOrSupportRole,
      subNavItems: [
        {
          // Visibility for this item is handled by parent
          text: 'Active employees',
          component: NavLink && <NavLink to="/active-employees" />,
        },
        {
          text: 'Inactive employees',
          component: NavLink && <NavLink to="/inactive-employees" />,
        },
        {
          // If user doesn't have the employee groups entitlement,
          // use "/plans" URL and show upgrade chip,
          // otherwise use the "/groups" URL and don't show the upgrade chip
          text: 'Groups',
          component: !hasEmployeeGroupsEntitlement
            ? NavLink && <NavLink to="/plans" />
            : NavLink && <NavLink to="/groups" />,
          ...(showFreeTrial && { chipText: 'Free trial' }),
          ...(!hasEmployeeGroupsEntitlement && { chipText: 'Upgrade' }),
        },
      ],
    },
    {
      // In ITH: Show if hide applications FF is disabled (default) & admin role
      // In EUH: Show if hide applications FF is disabled (default) & admin role
      icon: 'app',
      text: 'Applications',
      visible: !electrolyteHideApplicationsPage && isAdminOrSupportRole, // this is added for beta release and will be removed.
      component: NavLink && (
        <NavLink
          to={`${hasApplicationsEntitlement ? '/applications' : '/plans'}`}
        />
      ),
      ...(showFreeTrial && { chipText: 'Free trial' }),
      ...(!hasApplicationsEntitlement && { chipText: 'Upgrade' }),
    },
    {
      icon: 'shop',
      text: 'Marketplace',
      visible:
        electrolyteShowSecurityPages &&
        isAdminOrSupportRole &&
        electrolyteEndUserDiscover,
      component: NavLink && <NavLink to="/discover" />,
    },
    /* ---- End User Hub Nav Items ---- */
    {
      // In ITH: Show if admin role
      // In EUH: Show if admin role
      sectionHeading: 'Employee Center',
      visible: isAdminOrSupportRole,
    },
    {
      icon: 'home',
      text: 'Support Center',
      component: NavLink && <NavLink to="/support-center" />,
    },
    {
      icon: 'applications',
      text: 'Helpful Links',
      visible: hasHelpfulLinks || isAdminOrSupportRole,
      component: NavLink && <NavLink to="/helpful-links" />,
    },
    {
      icon: 'sparkles',
      text: 'Gigawatt',
      visible: hasGigawattEntitlement,
      component: NavLink && <NavLink to="/AIChat" />,
    },
  ];
};

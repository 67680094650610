import { useGlobalUI } from '@/components/GlobalUIProvider';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useIgnoreAssetLogMutation } from '@/redux/slices/assetApiSlice';
import { Button } from '@electricjs/arc';

type IgnoreAssetLogButtonProps = { assetLogId: string };

export const IgnoreAssetLogButton = ({
  assetLogId,
}: IgnoreAssetLogButtonProps) => {
  const organizationId = useGetOrganizationId();
  const [ignoreAssetLog, { isLoading }] = useIgnoreAssetLogMutation();
  const { showErrorToast } = useGlobalUI();

  const handleIgnore = async () => {
    try {
      await ignoreAssetLog({ organizationId, assetLogId }).unwrap();
    } catch (error) {
      window.DD_RUM?.addError(error, {
        location: 'Ignore asset log button',
      });
      showErrorToast({
        id: 'ignore-asset-log-error-toast',
        message: 'Something went wrong, please try again.',
      });
    }
  };

  return (
    <Button
      id="needs-attention-ignore-button"
      variant="text"
      onClick={handleIgnore}
      loading={isLoading}>
      Ignore
    </Button>
  );
};

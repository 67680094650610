import { difference } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { OptionTypeBase } from 'react-select/src/types';

import { Select, Skeleton } from '@electricjs/arc';
import { useGlobalUI } from '@/components/GlobalUIProvider';
import {
  useGetRequestTypeCategoryChampionsByCategoryQuery,
  useRemoveRequestTypeCategoryChampionMutation,
  useUpdateRequestTypeCategoryChampionMutation,
} from '@/redux/slices/requestApiSlice';
import { Employee } from '@/types/employees';

export type ChampSelectorProps = {
  organizationId: string;
  categoryId: string;
  options: ChampionOption[];
};

type ChampionOption = OptionTypeBase & {
  employeeId: string;
  employee?: Employee;
  label?: string;
};

const ChampOption = ({ option }: { option: ChampionOption }) => {
  const { employee } = option;

  if (!employee) {
    return <Skeleton count={1} width="10rem" />;
  }

  return (
    <>
      {employee.firstName} {employee.lastName}
    </>
  );
};

const formatOptionLabel = (option: ChampionOption) => {
  return <ChampOption option={option} />;
};

const ChampSelector = ({
  organizationId,
  categoryId,
  options,
}: ChampSelectorProps) => {
  const {
    data: categoryChampsResponse,
    isFetching: isFetchingChamps,
    isError: isErrorChamps,
  } = useGetRequestTypeCategoryChampionsByCategoryQuery({
    organizationId,
    requestTypeCategoryId: categoryId,
  });

  const [addChampion, { isLoading: isLoadingAddChampion }] =
    useUpdateRequestTypeCategoryChampionMutation();
  const [removeChampion, { isLoading: isLoadingRemoveChampion }] =
    useRemoveRequestTypeCategoryChampionMutation();

  const isLoading =
    isFetchingChamps || isLoadingAddChampion || isLoadingRemoveChampion;

  const { showSuccessToast, showErrorToast, showToast } = useGlobalUI();

  // Map employeeId => ChampionOption
  const optionsMap = useMemo(() => {
    const map = new Map<string, ChampionOption>();
    options.forEach(option => {
      if (option.employeeId) {
        map.set(option.employeeId, option);
      }
    });
    return map;
  }, [options]);

  // Map employeeId => championId
  const employeeIdToChampionIdMap = useMemo(() => {
    const map = new Map<string, string>();
    categoryChampsResponse?.forEach(champ => {
      if (champ.employeeId && champ.id) {
        map.set(champ.employeeId, champ.id);
      }
    });
    return map;
  }, [categoryChampsResponse]);

  const initialChampOptions: ChampionOption[] = useMemo(() => {
    if (!categoryChampsResponse) return [];
    return categoryChampsResponse
      .map(c => optionsMap.get(c.employeeId))
      .filter((option): option is ChampionOption => option !== undefined);
  }, [categoryChampsResponse, optionsMap]);

  const [selectedChampOptions, setSelectedChampOptions] = useState<
    ChampionOption[]
  >([]);

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (isLoading || isInitialized) return;
    setSelectedChampOptions(initialChampOptions);
    setIsInitialized(true);
  }, [isLoading, isInitialized, initialChampOptions]);

  // This callback fires after a champion is changed
  const handleChange = useCallback(
    async (values: ChampionOption[] | null) => {
      values = values ?? [];
      const previousEmployeeIds = selectedChampOptions.map(c => c.employeeId);
      const newEmployeeIds = values.map(c => c.employeeId);

      const addedIds = difference(newEmployeeIds, previousEmployeeIds);
      const removedIds = difference(previousEmployeeIds, newEmployeeIds);

      // Check if there's at least one champion left
      if (removedIds.length > 0 && previousEmployeeIds.length === 1) {
        showToast({
          id: 'champion-removal-warning-toast',
          intent: 'warning',
          title: 'Action not allowed',
          message:
            'You must have at least one champion in the list. Please add a new champion before removing this one.',
        });
        setSelectedChampOptions(selectedChampOptions);
        return;
      }

      try {
        // Remove deleted champions
        const removePromises = removedIds.map(employeeId => {
          const championId = employeeIdToChampionIdMap.get(employeeId);
          if (!championId) {
            throw new Error(
              `Champion ID not found for employee ID ${employeeId}`
            );
          }
          return removeChampion({
            organizationId,
            requestTypeCategoryId: categoryId,
            championId,
          }).unwrap();
        });

        // Add new champions
        const addPromises = addedIds.map(employeeId =>
          addChampion({
            organizationId,
            employeeId,
            requestTypeCategoryId: categoryId,
          }).unwrap()
        );

        // Run all promises in parallel
        await Promise.all([...removePromises, ...addPromises]);

        // Update selected champions state
        setSelectedChampOptions(values);

        showSuccessToast({
          id: 'champion-updated-success-toast',
          stack: true,
          message: 'Champions updated successfully!',
        });
      } catch (error) {
        console.error('Error while updating champions:', error);
        showErrorToast({
          id: 'champion-updated-error-toast',
          title: 'Unable to Update Champions',
          message:
            'There was a problem updating the champions. Please try again.',
        });
      }

      // Note that there's no need to refetch because addChampion and removeChampion
      // will trigger a refetch automatically for this org and category because they invalidate the tag
      // in the slice.
      setSelectedChampOptions(values);
    },
    [
      selectedChampOptions,
      addChampion,
      removeChampion,
      employeeIdToChampionIdMap,
      organizationId,
      categoryId,
      showSuccessToast,
      showErrorToast,
      showToast,
    ]
  );

  return (
    <Select
      id={`champion-select-${categoryId}`}
      name={`champion-select-${categoryId}`}
      placeholder="Search by name or email"
      options={options}
      isMulti
      isSearchable
      isClearable={false}
      isLoading={isLoading}
      closeMenuOnSelect
      value={selectedChampOptions}
      getOptionValue={(option: ChampionOption) => option.employeeId}
      formatOptionLabel={formatOptionLabel}
      onChange={handleChange}
      error={isErrorChamps}
    />
  );
};

export default ChampSelector;

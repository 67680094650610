import { Flex, Link, Text } from '@electricjs/arc';
import { useGetApplicationsQuery } from '@/redux/slices/applicationApiSlice';
import { ApplicationIntegrationCard } from '@/components/Settings/IntegrationsPane/ApplicationIntegrationCard';
import useIntegrationAvailabilities from '@/hooks/useIntegrationAvailabilities';

export const ApplicationsIntegration = () => {
  // Applications with internal availability should be considered as “not supported”
  // if the organization is not TEST or DEMO.
  const integrationAvailability = useIntegrationAvailabilities();

  const { data } = useGetApplicationsQuery({
    integrationAvailability,
  });

  const applications = data?.applications ?? [];

  if (applications.length === 0) {
    return null;
  }

  return (
    <Flex mt="3rem" width="100%" vertical>
      <Text variant="heading-2">Applications</Text>
      <Flex maxWidth="70rem" vertical>
        <Text variant="body" my="1rem">
          With application integrations, Electric can automatically create and
          remove accounts in these applications for onboarding and offboarding.
        </Text>
        <Text variant="body">
          Looking for an application you want automated but do not see here?
          Please request it from our team by reaching out to Product Support
          via&nbsp;
          <Link href="mailto:product-support@electric.ai">
            product-support@electric.ai
          </Link>
          .
        </Text>
      </Flex>
      <Flex gap="2.6rem" flexWrap="wrap">
        {applications.map(app => (
          <ApplicationIntegrationCard key={app.slug} application={app} />
        ))}
      </Flex>
    </Flex>
  );
};

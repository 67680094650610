import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import {
  ColorsV2,
  RadioTile as RadioTileBase,
  getToken,
} from '@electricjs/arc';

import { HiringStatus } from './types';

const StyledTile = styled(RadioTileBase)`
  min-width: auto;
  padding: 0 2rem;
  color: ${getToken(ColorsV2.PRIMARY)};
  font-weight: 700;
  border: 0.2rem solid ${getToken(ColorsV2.PRIMARY)};
  border-radius: 0.8rem;
  // workaround to remove the shadow when the tile is selected
  box-shadow: none !important;
`;

type RadioTileProps = {
  name: string;
  value: HiringStatus;
  children: string;
};

const RadioTile = ({ name, value, children }: RadioTileProps) => {
  const { setValue, getValues } = useFormContext();
  const fieldValue = getValues(name);
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(name, event.target.value, { shouldValidate: true });
  };

  return (
    <StyledTile
      id={`${name}-${value}`}
      name={name}
      value={value}
      checked={fieldValue === value}
      onChange={onChange}>
      {children}
    </StyledTile>
  );
};

export default RadioTile;

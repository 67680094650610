import { useState } from 'react';

import { useGlobalUI } from '@/components/GlobalUIProvider';
import {
  useSendDesktopAppInstructionsMutation,
  useSendMDMEnrollInstructionsMutation,
} from '@/redux/slices/employeeApiSlice';

import AppInviteModal from './AppInviteModal';
import { Employee } from '@/types/employees';
import { useOrganizationHasEntitlement } from '@/hooks/useOrganizationHasEntitlement';
import { EntitlementSlugEnum } from '@electricjs/core_entity-client';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';

type AppInviteModalWrapperProps<GenericEmployee extends Employee> = {
  employees: GenericEmployee[];
  inviteVisible: boolean;
  setShowInviteModal: (show: boolean) => void;
  showCheckList?: boolean;
  fromQuickStart?: boolean;
  includeEmail?: boolean;
  includeDevice?: boolean;
  title?: string;
  description?: string;
};

const AppInviteModalWrapper = <GenericEmployee extends Employee>({
  employees,
  inviteVisible,
  includeEmail = false,
  includeDevice = true,
  setShowInviteModal,
  showCheckList = true,
  fromQuickStart,
  title,
  description,
}: AppInviteModalWrapperProps<GenericEmployee>) => {
  const { showSuccessToast, showErrorToast } = useGlobalUI();
  const organizationId = useGetOrganizationId();
  const hasMdmEntitlement = useOrganizationHasEntitlement(
    EntitlementSlugEnum.JumpcloudMdm
  );
  const [selectedEmployees, setSelectedEmployees] = useState(employees);
  const [
    sendDesktopAppInstructions,
    { isLoading: isLoadingDesktopAppInstructions },
  ] = useSendDesktopAppInstructionsMutation();
  const [sendMDMEnrollInstructions, { isLoading: isLoadingMDMInstructions }] =
    useSendMDMEnrollInstructionsMutation();
  const resetSelection = () => {
    setSelectedEmployees(employees);
  };
  const handleCancel = () => {
    setTimeout(resetSelection, 500);
    setShowInviteModal(false);
  };

  const handleSubmit = () => {
    (hasMdmEntitlement
      ? sendMDMEnrollInstructions({
          employeeIds: selectedEmployees?.map(employee => employee.id) || [],
          organizationId,
        })
      : sendDesktopAppInstructions({
          employeeIds: selectedEmployees?.map(employee => employee.id) || [],
          organizationId,
        })
    )
      .unwrap()
      .then(() => {
        showSuccessToast({
          id: 'desktop-app-invite-sent-success-toast',
          stack: true,
          message: `Enrollment instructions have been sent to ${
            selectedEmployees?.length
          } ${
            selectedEmployees?.length && selectedEmployees?.length > 1
              ? 'people'
              : 'person'
          }`,
        });
        handleCancel();
        fromQuickStart &&
          localStorage.setItem('quickstart-app-invite-sent', 'true');
      })
      .catch(error => {
        console.error(`Unable to send desktop app invite emails for `, error);
        showErrorToast({
          id: 'desktop-app-invite-sent-error-toast',
          stack: true,
          title: 'Unable to send Emails',
          message:
            'Something went wrong while sending the emails. Please try again.',
        });
      });
  };

  const instructions = hasMdmEntitlement
    ? 'You need to enroll your work device in Mobile Device Management (MDM) to keep your device secure. This should take about 5 minutes. To begin this process, install the Electric Desktop application. If you’re unsure if you installed it, you can download it here (https://downloads.electric.ai/app). Next, follow these steps to complete your MDM enrollment: https://support.electric.ai/electricithub/electric-it-hub-installing-the-desktop-app#ElectricITHub|InstallingtheDesktopApp-HowtoEnrollinMDM. If you encounter any issues with installing the Electric app or enrolling in MDM, reach out to product-support@electric.ai for assistance. Thanks for getting this done quickly!'
    : 'You need to install the Electric application on your work device. It’s a quick and essential step to help us monitor your device’s performance and security, but rest assured—your personal data won’t be monitored. We’re only focusing on security-related analytics to keep your business safe. Here’s how to install Electric: https://support.electric.ai/electricithub/electric-it-hub-installing-the-desktop-app#ElectricITHub|InstallingtheDesktopApp-HowtoInstalltheElectricDesktopApplication. If you encounter any issues with installing the Electric app, please reach out to product-support@electric.ai for assistance. Thanks for getting this done quickly!';

  const isLoading = isLoadingDesktopAppInstructions || isLoadingMDMInstructions;

  return (
    <AppInviteModal
      hide={handleCancel}
      employees={employees}
      showCheckList={showCheckList}
      selectedEmployees={selectedEmployees}
      setSelectedEmployees={setSelectedEmployees}
      isLoading={isLoading}
      visible={inviteVisible}
      handleCancel={handleCancel}
      handleSubmit={handleSubmit}
      hasMdmEntitlement={hasMdmEntitlement}
      instructionsToCopy={instructions}
      includeEmail={includeEmail}
      includeDevice={includeDevice}
      title={title}
      description={description}
    />
  );
};

export default AppInviteModalWrapper;

import styled from 'styled-components';

import { Flex } from '@electricjs/arc';

import { ProgressStepper } from '@common';
import formatDate from '@/helpers/formatDate';

import { useStorefront } from '../StorefrontProvider';

const StyledFlex = styled(Flex)`
  padding: 2rem;
  width: 100%;
`;

const OrderProgressStepper = () => {
  const {
    hasOnlyAssetsInCart,
    hasOnlyProductsInCart,
    hasMixedCart,
    storefrontCheckoutForm,
  } = useStorefront();

  const shippingMethod = storefrontCheckoutForm?.watch('shippingMethod');
  const shippingDate = shippingMethod?.shippingDate;
  const deliveryDate = shippingMethod?.deliveryDate;

  const productsOnlyList = [
    {
      completed: true,
      title: 'Order placed',
      subTitle: formatDate(new Date().toString()),
    },
    {
      completed: true,
      title: 'Assets created and assigned',
      subTitle: formatDate(new Date().toString()),
      wrapText: true,
    },
    {
      completed: false,
      title: 'Purchase shipped',
      subTitle: `Estimated ${formatDate(shippingDate?.toString())}`,
    },
    {
      completed: false,
      title: 'Delivery received',
      subTitle: `Estimated ${formatDate(deliveryDate?.toString())}`,
    },
  ];

  const assetsOnlyList = [
    {
      completed: true,
      title: 'Order placed',
      subTitle: formatDate(new Date().toString()),
    },
    {
      completed: true,
      title: 'Assets assigned',
      subTitle: formatDate(new Date().toString()),
    },
    {
      completed: false,
      title: 'Assets retrieved',
    },
  ];

  const mixedCartList = [
    {
      completed: true,
      title: 'Order placed',
      subTitle: formatDate(new Date().toString()),
    },
    {
      completed: true,
      title: 'Assets created and assigned',
      subTitle: formatDate(new Date().toString()),
      wrapText: true,
    },
    {
      completed: false,
      title: 'Assets retrieved',
    },
    {
      completed: false,
      title: 'Purchase shipped',
      subTitle: `Estimated ${formatDate(shippingDate?.toString())}`,
    },
    {
      completed: false,
      title: 'Delivery received',
      subTitle: `Estimated ${formatDate(deliveryDate?.toString())}`,
    },
  ];

  // Assets only cart
  if (hasOnlyAssetsInCart) {
    return (
      <StyledFlex>
        <ProgressStepper stepperList={assetsOnlyList} />
      </StyledFlex>
    );
  }

  // Catalog only cart
  if (hasOnlyProductsInCart) {
    return (
      <StyledFlex>
        <ProgressStepper stepperList={productsOnlyList} />
      </StyledFlex>
    );
  }

  // Mixed cart (catalog + assets)
  if (hasMixedCart) {
    return (
      <StyledFlex>
        <ProgressStepper stepperList={mixedCartList} />
      </StyledFlex>
    );
  }

  return null;
};

export default OrderProgressStepper;

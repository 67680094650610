import { PermissionEnum } from '@electricjs/core_entity-client';
import { useNavigate } from 'react-router-dom';

import { Button, Chip, ColorsV2, Flex, Text } from '@electricjs/arc';

import AssetsCSVUploadModal from '@/components/Assets/AssetsCSVUploadModal';
import { useGlobalUI } from '@/components/GlobalUIProvider';
import { useUserHasOrganizationPermission } from '@/hooks/useUserHasOrganizationPermission';

type AssetsTableHeaderProps = {
  assetsCount: number;
  totalAssets: number;
  handleExport: () => void;
  isExportingAssets: boolean;
};

const AssetsTableHeader = ({
  assetsCount,
  totalAssets,
  handleExport,
  isExportingAssets,
}: AssetsTableHeaderProps) => {
  const { openModal, closeModal } = useGlobalUI();
  const navigate = useNavigate();

  const canCreateAssets = useUserHasOrganizationPermission(
    PermissionEnum.Assetscreate
  );

  const amountText = (amount: number) => (amount !== 1 ? 'assets' : 'asset');
  const isFilteredAmount = assetsCount !== totalAssets;
  const maybeFiltered = isFilteredAmount
    ? `${assetsCount} ${amountText(assetsCount)} | `
    : '';
  const assetsAmount = `${maybeFiltered}${totalAssets} ${
    isFilteredAmount ? 'total' : amountText(totalAssets)
  }`;

  return (
    <Flex
      width="100%"
      p="2.5rem"
      overflowX="hidden"
      vAlignContent="center"
      hAlignContent="between">
      <Flex vAlignContent="center">
        <Text variant="heading-2">Assets</Text>
        <Chip
          id="assets-count-chip"
          ml="3"
          backgroundColor={ColorsV2.PRIMARY_LIGHTEST}
          variant="small">
          <Text variant="legal" intent="brand">
            {assetsAmount}
          </Text>
        </Chip>
      </Flex>
      {canCreateAssets && (
        <Flex alignItems="flex-end" columnGap="1.5rem">
          <Button
            id="export-assets-button"
            variant="outline"
            loading={isExportingAssets}
            onClick={handleExport}>
            Download all
          </Button>
          <Button
            id="import-assets-button"
            variant="outline"
            iconBefore="cloud-upload"
            onClick={() => {
              openModal(
                <AssetsCSVUploadModal
                  onCancel={closeModal}
                  onSuccess={closeModal}
                />
              );
            }}>
            Import
          </Button>
          <Button
            id="add-assets-button"
            iconBefore="add-circle"
            onClick={() => navigate(`/assets/new`)}>
            Add asset
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

export default AssetsTableHeader;

import { useGlobalUI } from '@/components/GlobalUIProvider';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { Button } from '@electricjs/arc';
import { useGetAssetQuery } from '@/redux/slices/assetApiSlice';
import { CenteredSpinner } from '@common';
import UnlockDeviceModal from '@/components/People/EmployeeProfile/UnlockDeviceModal';
import { EmployeeWithAssets } from '@/types/employees';
import { isDeviceAsset } from '@/types/assets';

type UnlockDeviceButtonProps = {
  assetId: string;
  employee: EmployeeWithAssets;
};

export const UnlockDeviceButton = ({
  assetId,
  employee,
}: UnlockDeviceButtonProps) => {
  const { openModal, closeModal } = useGlobalUI();

  const organizationId = useGetOrganizationId();

  const { data: asset, isLoading: isLoadingAsset } = useGetAssetQuery({
    organizationId: organizationId,
    assetId: assetId,
  });

  const deviceAsset = employee.assets?.find(asset => asset.id === asset?.id);

  if (isLoadingAsset) {
    return <CenteredSpinner />;
  }

  if (!asset || !deviceAsset || !isDeviceAsset(deviceAsset) || !employee) {
    return null;
  }

  return (
    <Button
      id="button-unlock-device-health-monitor"
      variant="outline"
      iconBefore="lock"
      onClick={() => {
        openModal(
          <UnlockDeviceModal
            deviceAsset={deviceAsset}
            employeeName={employee.firstName + ' ' + employee.lastName}
            onClose={closeModal}
          />
        );
      }}>
      Unlock Device
    </Button>
  );
};

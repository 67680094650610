import {
  ProductTypeName,
  ShippingMethod,
} from '@electricjs/core_entity-client';

import {
  FilterKeyEnum,
  ProvisioningType,
  Store,
  type Product,
} from '@/types/storefront';

import {
  isAudio,
  isDock,
  isKeyboardAndMouse,
  isLaptop,
  isMonitor,
  isVideo,
} from '.';
import { formatFilterValue } from '../components';

export const storeDisplayName = {
  [Store.Catalog]: 'Hardware Store',
  [Store.Asset]: 'Company assets',
};

export const shippingMethodDisplayName: Record<ShippingMethod, string> = {
  [ShippingMethod.ElectronicDelivery]: 'Electronic Delivery',
  [ShippingMethod.UpsGround]: 'UPS Ground',
  [ShippingMethod.UpsNextDayAir]: 'UPS Next Day Air',
};

export const productsAttributesDisplayName = {
  brand: 'Brand',
  productType: 'Item Type',
  productDescription: 'Item Description',
  price: 'Price',
  processor: 'Processor',
  memory: 'Memory',
  storage: 'Storage',
  screenSize: 'Screen Size',
  quality: 'Quality',
  connectivity: 'Connectivity',
  portTypes: 'Port Types',
  numberOfDisplays: 'Number of Displays Supported',
};

export const provisioningTypeData = {
  [ProvisioningType.Remote]: {
    label: 'Employee-initiated enrollment (recommended)',
    value: ProvisioningType.Remote,
    isDisabled: false,
  },
  [ProvisioningType.DeviceHasAutoEnrollment]: {
    label: 'This device has auto-enrollment',
    value: ProvisioningType.DeviceHasAutoEnrollment,
    isDisabled: false,
  },
  [ProvisioningType.AtElectric]: {
    label: 'Provision at Electric',
    value: ProvisioningType.AtElectric,
    isDisabled: false,
  },
  [ProvisioningType.NoProvisioningSupport]: {
    label: "I don't need provisioning support",
    value: ProvisioningType.NoProvisioningSupport,
    isDisabled: false,
  },
};

export const provisioningSelectionOptions = [
  provisioningTypeData.REMOTE,
  provisioningTypeData.DEVICE_HAS_AUTO_ENROLLMENT,
  provisioningTypeData.AT_ELECTRIC,
  provisioningTypeData.NO_PROVISIONING_SUPPORT,
];

export const formatMemory = (memoryInGB: number) => {
  if (memoryInGB <= 0) return 'Unknown';
  const oneTB = 1000;
  return memoryInGB >= oneTB ? `${memoryInGB / oneTB} TB` : `${memoryInGB} GB`;
};

export enum SpecLabel {
  diskSpace = 'Disk space',
  ram = 'RAM',
  processor = 'Processor',
  warranty = 'Warranty',
  screenSize = 'Screen size',
  quality = 'Quality',
  connection = 'Connection',
  ports = 'Ports',
  numberOfDisplays = 'Number of displays',
  condition = 'Condition',
  status = 'Status',
  chargerIncluded = 'Charger included',
}

const specItem = {
  diskSpace: (product: Product) =>
    `${SpecLabel.diskSpace}: ${'storage' in product ? product.storage : ''}`,
  ram: (product: Product) =>
    `${SpecLabel.ram}: ${'memory' in product ? formatMemory(product.memory) : ''}`,
  processor: (product: Product) =>
    `${SpecLabel.processor}: ${
      'processor' in product && product.processor?.length > 0
        ? product.processor
        : 'Unknown'
    }`,
  warranty: (product: Product) =>
    `${SpecLabel.warranty}: ${
      'defaultWarranty' in product ? product.defaultWarranty?.name : ''
    }`,
  screenSize: (product: Product) =>
    'screenSize' in product
      ? `${SpecLabel.screenSize}: ${product.screenSize}`
      : '',
  quality: (product: Product) =>
    `${SpecLabel.quality}: ${'quality' in product ? product.quality : ''}`,
  connection: (product: Product) => {
    const connectivity =
      'connectivity' in product ? product.connectivity?.join(', ') : 'Unknown';
    return `${SpecLabel.connection}: ${connectivity}`;
  },
  ports: (product: Product) => {
    const portTypes =
      'portTypes' in product ? product.portTypes?.join(', ') : 'Unknown';
    return `${SpecLabel.ports}: ${portTypes}`;
  },
  numberOfDisplays: (product: Product) =>
    `${SpecLabel.numberOfDisplays}: ${
      'numberOfDisplays' in product ? product.numberOfDisplays : 'Unknown'
    }`,
};

const laptopSpecs = (product: Product) => [
  specItem.diskSpace(product),
  specItem.ram(product),
  specItem.processor(product),
  specItem.warranty(product),
];

const monitorSpecs = (product: Product) => [
  specItem.screenSize(product),
  specItem.quality(product),
];

const keyboardAndMouseSpecs = (product: Product) => [
  specItem.connection(product),
  specItem.ports(product),
];

const audioSpecs = (product: Product) => [specItem.connection(product)];

const videoSpecs = (product: Product) => [
  specItem.quality(product),
  specItem.connection(product),
];

const dockSpecs = (product: Product) => [specItem.numberOfDisplays(product)];

export function buildSpecs(product: Product) {
  if (isLaptop(product)) {
    return laptopSpecs(product);
  } else if (isMonitor(product)) {
    return monitorSpecs(product);
  } else if (isKeyboardAndMouse(product)) {
    return keyboardAndMouseSpecs(product);
  } else if (isAudio(product)) {
    return audioSpecs(product);
  } else if (isVideo(product)) {
    return videoSpecs(product);
  } else if (isDock(product)) {
    return dockSpecs(product);
  } else {
    return [];
  }
}

export const filterKeyTranslation = {
  [FilterKeyEnum.PRICE]: 'Price',
  [FilterKeyEnum.RAM]: 'RAM',
  [FilterKeyEnum.SIZE]: 'Size',
  [FilterKeyEnum.DISPLAY]: 'Displays',
  [FilterKeyEnum.BRAND]: 'Brand',
  [FilterKeyEnum.TYPE]: 'Type',
};

enum PriceRangeEnum {
  PRICE39LESS = '$39.99_AND_LESS',
  PRICE40UP = '$40_AND_UP',
  PRICE49LESS = '$49.99_AND_LESS',
  PRICE50UP = '$50_AND_UP',
  PRICE199LESS = '$199.99_AND_LESS',
  PRICE200UP = '$200_AND_UP',
  PRICE999LESS = '$999.99_AND_LESS',
  PRICE1000RANGE = '$1,000_$1,999.99',
  PRICE2000RANGE = '$2,000_$2,999.99',
  PRICE3000UP = '$3,000_AND_UP',
}

const priceRangeTranslation = {
  [PriceRangeEnum.PRICE39LESS]: '$39.99 and less',
  [PriceRangeEnum.PRICE40UP]: '$40 and up',
  [PriceRangeEnum.PRICE49LESS]: '$49.99 and less',
  [PriceRangeEnum.PRICE50UP]: '$50 and up',
  [PriceRangeEnum.PRICE199LESS]: '$199.99 and less',
  [PriceRangeEnum.PRICE200UP]: '$200 and up',
  [PriceRangeEnum.PRICE999LESS]: '$999.99 and less',
  [PriceRangeEnum.PRICE1000RANGE]: '$1,000 - $1,999.99',
  [PriceRangeEnum.PRICE2000RANGE]: '$2,000 - $2,999.99',
  [PriceRangeEnum.PRICE3000UP]: '$3,000 and up',
};

enum RamRangeEnum {
  RAM16GB = '16_GB_AND_LESS',
  RAM17GB = '17_35_GB',
  RAM36GB = '36_GB_AND_UP',
}

const ramRangeTranslation = {
  [RamRangeEnum.RAM16GB]: '16 GB and less',
  [RamRangeEnum.RAM17GB]: '17 - 35 GB',
  [RamRangeEnum.RAM36GB]: '36 GB and up',
};

enum SizeRangeEnum {
  SIZE13LESS = '13.9_AND_LESS',
  SIZE14RANGE = '14_15.9',
  SIZE16UP = '16_AND_UP',
  SIZE27LESS = 'LESS_THAN_27',
  SIZE27UP = '27_AND_UP',
}

const sizeRangeTranslation = {
  [SizeRangeEnum.SIZE13LESS]: '13.9" and less',
  [SizeRangeEnum.SIZE14RANGE]: '14" - 15.9"',
  [SizeRangeEnum.SIZE16UP]: '16" and up',
  [SizeRangeEnum.SIZE27LESS]: 'Less than 27"',
  [SizeRangeEnum.SIZE27UP]: '27" and up',
};

enum DisplayRangeEnum {
  ONE = 'ONE',
  MORE = '2_OR_MORE',
}

const displayRangeTranslation = {
  [DisplayRangeEnum.ONE]: 'One',
  [DisplayRangeEnum.MORE]: '2 or more',
};

const typeKeyboardMouseEnumTranslation = {
  [ProductTypeName.Keyboard]: 'Keyboards',
  [ProductTypeName.Mouse]: 'Mice',
  [ProductTypeName.KeyboardMouse]: 'Combos & sets',
};

export const translateProductsFilters = (
  filterKey: FilterKeyEnum,
  filter: string
) => {
  switch (filterKey) {
    case FilterKeyEnum.DISPLAY:
      return displayRangeTranslation[filter as DisplayRangeEnum];
    case FilterKeyEnum.SIZE:
      return sizeRangeTranslation[filter as SizeRangeEnum];
    case FilterKeyEnum.RAM:
      return ramRangeTranslation[filter as RamRangeEnum];
    case FilterKeyEnum.PRICE:
      return priceRangeTranslation[filter as PriceRangeEnum];
    case FilterKeyEnum.TYPE:
      return typeKeyboardMouseEnumTranslation[
        filter as 'keyboard' | 'mouse' | 'keyboard_mouse'
      ];
    case FilterKeyEnum.BRAND:
    default:
      return formatFilterValue(filter);
  }
};

import { rest } from 'msw';

import { organizationApplicationChampionsList } from '@/mocks/data/organizationApplicationChampionData';

export const organizationApplicationChampionHandlers = [
  rest.get(
    `*/v1/organizations/:organizationId/organization_applications/:applicationKey/champions`,
    (_req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.delay(200),
        ctx.json({ total: 3, results: organizationApplicationChampionsList })
      );
    }
  ),
  rest.post(
    `*/v1/organizations/:organizationId/organization_applications/:applicationKey/champions`,
    async (_req, res, ctx) => {
      const orgAppChampion = {
        id: 'champion-1',
        employee_id: 'employee-1',
      };
      organizationApplicationChampionsList.push(orgAppChampion);
      return res(ctx.status(201), ctx.delay(200), ctx.json(orgAppChampion));
    }
  ),
  rest.delete(
    `*/v1/organizations/:id/organization_applications/:applicationKey/champions/:employeeId`,
    async (req, res, ctx) => {
      const { employeeId } = req.params;
      organizationApplicationChampionsList.splice(
        organizationApplicationChampionsList.findIndex(
          a => a.employee_id === employeeId
        ),
        1
      );
      return res(ctx.status(204), ctx.delay(200));
    }
  ),
];

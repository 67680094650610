import { AssetHistoryWithChildren, AssetLogDeviceIssue } from '@/types/assets';
import { AssetLogRowContainer } from '@/components/DeviceOverview/DeviceHealthMonitor/ColumnsAndContainers/AssetLogRowContainer';
import { EmployeeColumn } from '@/components/DeviceOverview/DeviceHealthMonitor/ColumnsAndContainers/EmployeeColumn';
import { AssetActionEnum } from '@electricjs/core_entity-client';
import { IssueTypeDescriptionColumnMap as DeviceIssueResolvedMap } from '@/components/DeviceOverview/DeviceHealthMonitor/Resolved/DescriptionColumnMap';
import { isDeviceIssueType } from '@/components/DeviceOverview/DeviceHealthMonitor/types';
import { DeviceIssueCreatedMap } from '@/components/DeviceOverview/DeviceHealthMonitor/ActivityLog/DeviceIssueCreatedMap';
import { DeviceIssueReminderMap } from '@/components/DeviceOverview/DeviceHealthMonitor/ActivityLog/DeviceIssueReminderMap';
import { IgnoredIssueDescriptionColumn } from '@/components/DeviceOverview/DeviceHealthMonitor/ActivityLog/IgnoredIssueDescriptionColumn';
import { DescriptionColumn } from '@/components/DeviceOverview/DeviceHealthMonitor/ColumnsAndContainers/DescriptionColumn';
import { useGetAssetQuery } from '@/redux/slices/assetApiSlice';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetEmployeeByUserIdQuery } from '@/redux/slices/userApiSlice';
import { EmployeeStatus } from '@/types/employees';
import { PaneProps } from '@electricjs/arc';
import { useGetEmployeesWithAssets } from '@/hooks/useGetEmployeesWithAssets';

type ActivityLogRowProps = {
  assetLog: AssetHistoryWithChildren<AssetLogDeviceIssue>;
  showEmployeeColumn?: boolean;
} & Partial<PaneProps>;

export const ActivityLogRow = ({
  assetLog,
  showEmployeeColumn = false,
  ...otherProps
}: ActivityLogRowProps) => {
  const showIcon = showEmployeeColumn;

  const {
    employee_id: employeeIdFromData,
    device_id: deviceId,
    issue_type: issueType,
  } = assetLog.data;

  const organizationId = useGetOrganizationId();

  const { data: asset, isLoading: isLoadingAsset } = useGetAssetQuery(
    employeeIdFromData
      ? {
          organizationId: organizationId,
          assetId: assetLog.assetId,
        }
      : skipToken
  );

  const employeeId = employeeIdFromData ?? (asset && asset.assignedTo);

  // Get the employee who performed the action
  const requesterQueryParams = assetLog.performedBy
    ? { userId: assetLog.performedBy, organizationId }
    : skipToken;
  const { data: requester, isLoading: isLoadingRequester } =
    useGetEmployeeByUserIdQuery(requesterQueryParams);

  // We will need the entire list of employees to render each row and may have already
  // requested it, so we will use the useGetEmployeesWithAssets hook to get the list
  // and filet reh result to find the individual employee.
  // This prevents us from having to make a request to core entity for each unique user
  // in the list.
  const { employeesWithAssets, isFetching } = useGetEmployeesWithAssets({
    devicesOnly: true,
    listEmployeesQueryProps: {
      status: [EmployeeStatus.Active, EmployeeStatus.Inactive],
    },
  });

  if (
    isLoadingAsset ||
    isFetching ||
    isLoadingRequester ||
    !employeesWithAssets
  ) {
    return <AssetLogRowContainer isLoading mt="0" />;
  }

  const employee = employeesWithAssets.find(e => e.id === employeeId);

  const hasMultipleDevices =
    employee && employee.assets && employee.assets?.length > 1;
  const serialNumber = hasMultipleDevices
    ? employee.assets?.find(asset => asset.device?.id === deviceId)
        ?.serialNumber || asset?.serialNumber
    : '';

  const getColumnDescriptionByStatus = (): React.ReactNode => {
    // Device wipe actions
    switch (assetLog.action) {
      case AssetActionEnum.DeviceWipeRequested: {
        const performedByText = requester
          ? `Device wipe requested by ${requester.firstName} ${requester.lastName}`
          : 'Device wipe requested';
        return (
          <DescriptionColumn
            iconType="PENDING"
            title={performedByText}
            serialNumber={serialNumber}
            detail="When the device is online, we'll wipe the device."
            showIcon={showIcon}
          />
        );
      }
      case AssetActionEnum.DeviceWipeDone:
        return (
          <DescriptionColumn
            iconType="WIPED"
            title="Device has been wiped"
            serialNumber={serialNumber}
            detail="The device has been restored to its factory settings."
            showIcon={showIcon}
          />
        );
      default:
        return null;
    }

    return null;
  };

  const getColumnDescriptionByAction = () => {
    if (!isDeviceIssueType(issueType)) {
      return null;
    }
    switch (assetLog.action) {
      case AssetActionEnum.DeviceIssueCreated:
        return DeviceIssueCreatedMap[issueType]?.({
          serialNumber,
          createdAt: assetLog.createdAt,
          showIcon,
        });
      case AssetActionEnum.DeviceIssueReminder:
        return DeviceIssueReminderMap[issueType]?.(serialNumber, showIcon);
      case AssetActionEnum.DeviceIssueResolved:
        return DeviceIssueResolvedMap[issueType]?.(serialNumber, showIcon);
      case AssetActionEnum.DeviceIssueIgnored:
        return (
          <IgnoredIssueDescriptionColumn
            performedByEmail={assetLog.performedByUser}
            createdAt={assetLog.createdAt}
            issue={issueType}
            serialNumber={serialNumber}
            showIcon={showIcon}
          />
        );
      default:
        return null;
    }
  };

  const columnDescription =
    getColumnDescriptionByStatus() || getColumnDescriptionByAction();

  // We don't want to render the row if there is no employee and no column description
  // We can have a case where we don't have an employee but we have a column description
  // The EmployeeColumn will render an Unknown Employee, but we always want a description
  if (!employee && !columnDescription) {
    return null;
  }

  return (
    <AssetLogRowContainer mt="0" {...otherProps}>
      {showEmployeeColumn && <EmployeeColumn employee={employee} />}
      {columnDescription}
    </AssetLogRowContainer>
  );
};

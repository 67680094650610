import { useLocation } from 'react-router-dom';

import {
  IntakeFormSupportedEmailProvider,
  SUPPORTED_EMAIL_PROVIDERS,
} from '@/constants/intakeQuestions';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useLazyGetApplicationQuery } from '@/redux/slices/applicationApiSlice';
import { useAddOrganizationApplicationMutation } from '@/redux/slices/organizationApiSlice';
import { OrganizationApplicationCreationArgs } from '@/types/organizations';

/**
 * Typeguard that'll narrow the type of a selected email provider
 */
function isEmailKnownEmailProvider(
  value: unknown
): value is IntakeFormSupportedEmailProvider {
  return SUPPORTED_EMAIL_PROVIDERS.includes(
    value as IntakeFormSupportedEmailProvider
  );
}

export const useAddEmailProviderAsOrgApp = () => {
  const organizationId = useGetOrganizationId();
  const { pathname: location } = useLocation();

  const [fetchApplication] = useLazyGetApplicationQuery();
  const [addOrganizationApplication] = useAddOrganizationApplicationMutation();

  const addSelectedEmailProvider = async (
    selectedEmailApplicationSlug: string
  ) => {
    if (!isEmailKnownEmailProvider(selectedEmailApplicationSlug)) {
      // Do nothing if it is not a known email provider
      return;
    }

    try {
      const { data: applicationInfo } = await fetchApplication({
        applicationKey: selectedEmailApplicationSlug,
      });

      // If we failed to get the application info, log an error and return.
      if (!applicationInfo) {
        window?.DD_RUM?.addError(
          `Failed to get application info for ${selectedEmailApplicationSlug}`,
          {
            location,
          }
        );
        return;
      }

      const applicationCreationParams: OrganizationApplicationCreationArgs = {
        organizationId: organizationId,
        application: {
          applicationId: applicationInfo.id,
        },
      };

      // Add to the organizations applications.
      await addOrganizationApplication(applicationCreationParams);
    } catch (error) {
      // Catch any errors thrown from the above requests. A catch-all works fine
      // here since we want to handle the error the same way regardless of which
      // request failed.
      window?.DD_RUM?.addError(error, {
        location,
      });
    }
  };

  return [addSelectedEmailProvider];
};

import { ReactNode } from 'react';

import {
  ColorsV2,
  Divider,
  Flex,
  IconInCircle,
  IconNames,
  Pane,
  Text,
  useThemeColors,
} from '@electricjs/arc';

export const DeviceManagementCard = ({
  title,
  icon,
  children,
  actions,
}: {
  title: string;
  icon: IconNames;
  children: ReactNode;
  actions?: ReactNode;
}) => {
  const [iconColor, iconBackground] = useThemeColors([
    ColorsV2.INFO,
    ColorsV2.INFO_LIGHTEST,
  ]);

  return (
    <Pane
      elevated
      flexDirection="column"
      mt="2rem"
      date-testid="start-setup-card">
      {/* Header */}
      <Flex
        p="2.5rem 3rem"
        width="100%"
        flexWrap="wrap"
        justifyContent="space-between"
        gap="1rem">
        <Flex>
          <IconInCircle
            color={iconColor}
            background={iconBackground}
            icon={icon}
            customIconSize="2rem"
            size="3.2rem"
          />
          <Text variant="heading-3" ml="1rem">
            {title}
          </Text>
        </Flex>
        <Flex pl="0.4rem">{actions}</Flex>
      </Flex>
      <Divider />
      {/* Body */}
      {children}
    </Pane>
  );
};

import { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { createColumnHelper, SortingState } from '@tanstack/react-table';

import {
  ColorsV2,
  Divider,
  Flex,
  Pagination,
  Pane,
  Table,
  TabButtonGroup,
  TableContainer,
  Text,
  getToken,
} from '@electricjs/arc';

import { EmployeeNameColumn, SearchBox } from '@common';
import getProductTypeLabel from '@/components/Assets/helpers/getProductTypeLabel';
import EmployeeOffboardedWarningIcon from '@/components/People/EmployeeOffboardedWarningIcon';
import TableHeader from '@/components/TableHeader';
import { Asset, AssetWithEnrollmentStatus } from '@/types/assets';
import TableCellFlexContainer from 'common/Table/TableCellFlexContainer';
import { AssetStatusColumn } from './AssetStatus';
import AssetsTableHeader from './AssetsTableHeader';
import {
  getAssignedToSortingValue,
  getSiteSortingValue,
  handleCustomSorting,
} from './helpers';
import AssetsTableFiltersGroup from './TableFilters/AssetsTableFiltersGroup';
import { AssetStatus, ProductTypeName } from '@electricjs/core_entity-client';
import { Warranty } from './types';

const StyledTableContainer = styled(TableContainer)`
  overflow: visible; // Override the default overflow
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${getToken(ColorsV2.PRIMARY)};

  &:hover {
    text-decoration: underline;
  }

  & > * {
    color: ${getToken(ColorsV2.PRIMARY)};
  }
`;

export enum AssetStatusValue {
  Active = 'active',
  Archived = 'archived',
}

const ASSET_STATUS_TOGGLE_OPTIONS = [
  { label: 'Active', value: AssetStatusValue.Active },
  { label: 'Archived', value: AssetStatusValue.Archived },
];

type AssetsTableProps = {
  assets: Asset[];
  totalAssets: number;
  assetsCount: number;
  isFetchingAssets: boolean;
  handleExport: () => void;
  isExportingAssets: boolean;
  setCurrentPage: (page: number) => void;
  currentPage: number;
  handleSearch: (searchTerm: string) => void;
  searchTerm: string;
  handleStatusFilter: (status: AssetStatusValue) => void;
  selectedStatus: AssetStatusValue;
  handleAssetStatusFilter: (
    assetStatus: AssetStatus[] | ((prev: AssetStatus[]) => AssetStatus[])
  ) => void;
  assetStatus: AssetStatus[];
  handleProductTypeFilter: (
    productType:
      | ProductTypeName[]
      | ((prev: ProductTypeName[]) => ProductTypeName[])
  ) => void;
  productType: ProductTypeName[];
  handleWarrantyFilter: (
    warranty: Warranty[] | ((prev: Warranty[]) => Warranty[])
  ) => void;
  warranty: Warranty[];
  sorting: SortingState;
  handleSorting: Dispatch<SetStateAction<SortingState>>;
  pageSize: number;
};

export enum SortableColumns {
  AssetName = 'name',
  AssetId = 'customAssetId',
  AssetStatus = 'status',
  ProductTypeName = 'productType.name',
  EmployeeName = 'employee.name',
  SiteName = 'site.name',
}

const columnHelper = createColumnHelper<AssetWithEnrollmentStatus>();

const AssetsTable = ({
  assets,
  totalAssets,
  assetsCount,
  isFetchingAssets,
  handleExport,
  isExportingAssets,
  setCurrentPage,
  currentPage,
  handleSearch,
  searchTerm,
  handleStatusFilter,
  selectedStatus,
  handleAssetStatusFilter,
  assetStatus,
  handleProductTypeFilter,
  productType,
  handleWarrantyFilter,
  warranty,
  sorting,
  handleSorting,
  pageSize,
}: AssetsTableProps) => {
  const tableColumns = [
    columnHelper.accessor('name', {
      header: () => <TableHeader title="Name" />,
      id: 'assetName',
      cell: data => {
        return (
          <StyledLink to={`/assets/${data.row.original.id}`}>
            <Text variant="label-small">{data.getValue()}</Text>
          </StyledLink>
        );
      },
    }),
    columnHelper.accessor('customAssetId', {
      header: () => <TableHeader title="Asset ID" />,
      id: 'customAssetId',
      cell: data => {
        return <Text variant="body-2">{data.getValue()}</Text>;
      },
    }),
    columnHelper.accessor('productType.name', {
      header: () => <TableHeader title="Type" />,
      id: 'productType',
      cell: data => {
        return (
          <Text variant="body-2">{getProductTypeLabel(data.getValue())}</Text>
        );
      },
    }),
    columnHelper.accessor('site', {
      header: () => <TableHeader title="Site" />,
      sortingFn: handleCustomSorting(getSiteSortingValue),
      id: 'siteName',
      cell: data => {
        const site = data.getValue();

        if (!site) {
          return null;
        }

        return <Text variant="body-2">{site.name}</Text>;
      },
    }),
    columnHelper.accessor('assignedToEmployee', {
      header: () => <TableHeader title="Assigned To" />,
      sortingFn: handleCustomSorting(getAssignedToSortingValue),
      id: 'assignedToEmployee',
      cell: data => {
        const employee = data.getValue();

        if (!employee) {
          return null;
        }

        return (
          <TableCellFlexContainer>
            <EmployeeOffboardedWarningIcon employeeId={employee.id} />
            <EmployeeNameColumn
              firstName={employee.firstName}
              lastName={employee.lastName}
              email={null}
              employeeId={employee.id}
              hideAvatar
            />
          </TableCellFlexContainer>
        );
      },
    }),
    columnHelper.accessor('status', {
      header: () => <TableHeader title="Status" />,
      id: 'assetStatus',
      cell: data => {
        const status = data.getValue();

        if (!status) {
          return null;
        }

        return <AssetStatusColumn status={status} />;
      },
    }),
  ];

  return (
    <StyledTableContainer flexDirection="column" width="100%">
      <AssetsTableHeader
        assetsCount={assetsCount}
        totalAssets={totalAssets}
        handleExport={handleExport}
        isExportingAssets={isExportingAssets}
      />
      <Divider />
      <Flex
        p="2.5rem"
        width="100%"
        justifyContent="space-between"
        alignItems="center">
        <Flex flexWrap="wrap" gap="1.6rem" alignItems="center">
          <SearchBox
            placeholder="Search here"
            searchTerm={searchTerm}
            onChange={handleSearch}
          />

          <AssetsTableFiltersGroup
            showTypeFilter
            showStatusFilter
            showWarrantyFilter
            active={selectedStatus === AssetStatusValue.Active}
            assetStatus={assetStatus}
            setAssetStatus={handleAssetStatusFilter}
            productType={productType}
            setProductType={handleProductTypeFilter}
            setWarranty={handleWarrantyFilter}
            warranty={warranty}
            isMultiSelect
          />
        </Flex>

        <TabButtonGroup
          tabs={ASSET_STATUS_TOGGLE_OPTIONS}
          activeTabValue={selectedStatus}
          onTabChange={selectedOption =>
            handleStatusFilter(selectedOption as AssetStatusValue)
          }
        />
      </Flex>

      <Flex width="100%">
        <Table
          columns={tableColumns}
          data={assets}
          loading={isFetchingAssets}
          state={{
            pagination: {
              pageIndex: isFetchingAssets ? 0 : currentPage,
              pageSize,
            },
            sorting,
          }}
          onSortingChange={handleSorting}
        />
      </Flex>
      <Pane horizontal="center">
        <Pagination
          id="assets-table-pagination"
          pageChangeCallback={(_, nextPage) => setCurrentPage(nextPage - 1)}
          currentPage={currentPage + 1}
          totalPageCount={assetsCount ? Math.ceil(assetsCount / pageSize) : 1}
        />
      </Pane>
    </StyledTableContainer>
  );
};

export default AssetsTable;

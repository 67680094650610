import { useState } from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  DatePickerField,
  Flex,
  Header,
  TextInputField,
} from '@electricjs/arc';
import { PermissionEnum } from '@electricjs/core_entity-client';

import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useUserHasOrganizationPermission } from '@/hooks/useUserHasOrganizationPermission';
import { useLazyGetEmployeeByEmailQuery } from '@/redux/slices/employeeApiSlice';
import { isFetchBaseQueryError } from '@/redux/slices/helpers/errorHandlersHelpers';
import { EmployeeStatus } from '@/types/employees';
import { getFullName } from 'common/utils/getFullName';
import { FormActionsContainer } from './common';
import { HiringStatus, OnboardingFormData } from './types';

type EmployeeInformationProps = {
  addEmployee: (onSuccess: (employeeId: string) => void) => void;
};

const EmployeeInformation = ({ addEmployee }: EmployeeInformationProps) => {
  const canOnboard = useUserHasOrganizationPermission(
    PermissionEnum.Onboardingswrite
  );
  const [isValidatingEmail, setIsValidatingEmail] = useState(false);
  const navigate = useNavigate();
  const { clearErrors, control, getValues, reset, setError, setValue } =
    useFormContext<OnboardingFormData>();
  const { isValid, isSubmitting } = useFormState();
  const [hiringStatus, firstName, lastName] = useWatch({
    name: ['hiringStatus', 'demographics.firstName', 'demographics.lastName'],
    control,
  });
  const fullName = getFullName(firstName, lastName);
  const [getEmployeeByEmail] = useLazyGetEmployeeByEmailQuery();
  const organizationId = useGetOrganizationId();
  const isNewHire = hiringStatus === HiringStatus.New;
  const nextButtonLabel = isNewHire && canOnboard ? 'Next' : 'Finish';

  const validateEmail = async () => {
    const email = getValues('employeeInformation.email');
    setIsValidatingEmail(true);

    try {
      const employee = await getEmployeeByEmail({
        organizationId,
        email,
      }).unwrap();
      const message =
        employee.status === EmployeeStatus.Inactive
          ? 'This email belongs to an inactive employee. Please enter a new email to proceed.'
          : 'This email already exists for an employee! Please check your active employees if you are making changes to an existing employee.';

      setError('employeeInformation.email', { message });
      return false;
    } catch (error) {
      if (isFetchBaseQueryError(error) && error.status === 404) {
        clearErrors('employeeInformation.email');
        return true;
      }
      throw error;
    } finally {
      setIsValidatingEmail(false);
    }
  };

  const handlePrevious = () => {
    const currentStep = getValues('currentStep');
    setValue('currentStep', currentStep - 1, {
      shouldValidate: true,
    });
  };

  // If the employee is a new hire and the user has permission to create an onboarding, go to the next step.
  // Otherwise, add them and go to their profile.
  const handleNext = async () => {
    const isValidEmail = await validateEmail();
    if (!isValidEmail) return;

    if (isNewHire && canOnboard) {
      const currentStep = getValues('currentStep');
      setValue('currentStep', currentStep + 1, {
        shouldValidate: true,
      });
    } else {
      addEmployee((employeeId: string) => {
        if (employeeId) {
          navigate(`/employees/${employeeId}`);
        }
      });
    }
  };

  // Add the current employee and return to the form start to add more employees.
  const handleAddMore = async () => {
    const isValidEmail = await validateEmail();
    if (!isValidEmail) return;

    addEmployee((employeeId: string) => {
      if (employeeId) {
        reset();
        setValue('currentStep', 1);
      }
    });
  };

  const isLoading = isValidatingEmail || isSubmitting;

  return (
    <>
      <Header
        title="Employee Information"
        subtitle={`Where is ${fullName} assigned within the company?`}
      />
      <Flex width="100%" vertical>
        <DatePickerField
          id="startDate"
          name="employeeInformation.startDate"
          control={control}
          label="Start Date"
          placeholder=""
          minDate=""
          required={isNewHire}
        />
        <TextInputField
          id="jobTitle"
          name="employeeInformation.jobTitle"
          control={control}
          label="Title"
          required={isNewHire}
        />
        <TextInputField
          id="email"
          name="employeeInformation.email"
          control={control}
          label="Work Email"
          required
        />
      </Flex>
      <FormActionsContainer>
        <Button
          id="onboarding-employee-info-previous-button"
          variant="outline"
          onClick={handlePrevious}
          disabled={isLoading}>
          Previous
        </Button>
        <Flex gap="2rem">
          {!(isNewHire && canOnboard) && (
            <Button
              id="onboarding-employee-info-finish-and-add-button"
              variant="text"
              onClick={handleAddMore}
              disabled={!isValid || isLoading}>
              Finish, and add another employee
            </Button>
          )}
          <Button
            id="onboarding-employee-info-next-button"
            onClick={handleNext}
            disabled={!isValid}
            loading={isLoading}>
            {nextButtonLabel}
          </Button>
        </Flex>
      </FormActionsContainer>
    </>
  );
};

export default EmployeeInformation;

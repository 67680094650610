import {
  Card,
  Text,
  Divider,
  ColorsV2,
  Flex,
  Box,
  Chip,
  useThemeColors,
  Button,
} from '@electricjs/arc';

import formatCurrency from '@/helpers/formatCurrency';
import { StorefrontSteps } from '@/types/storefront';

import { useStorefront } from '../../StorefrontProvider';
import { isLaptop, isWarehouseAsset } from '../../utils';
import { capitalize } from '../ProductSpecsFilter';

import { shouldShowSelfShip } from '../../utils';

const CartSummary = () => {
  const {
    itemsInCart,
    catalogCart,
    assetCart,
    storefrontStep,
    setStorefrontStep,
  } = useStorefront();

  const [textChipColor, backgroundChipColor] = useThemeColors([
    ColorsV2.INFO,
    ColorsV2.INFO_LIGHTEST,
  ]);

  const showSelfShip = shouldShowSelfShip(assetCart);

  return (
    <Card elevated id="cart-summary-card">
      <Flex columnGap="1.5rem" vAlignContent="center" mb="1rem">
        {/* Title */}
        <Text variant="heading-1" ml="1rem">
          {storefrontStep === StorefrontSteps.ORDER_PLACED
            ? 'Ordered items'
            : 'Cart'}
        </Text>
        <Text mt="0.5rem">
          ({itemsInCart} {itemsInCart === 1 ? 'Item' : 'Items'})
        </Text>
        {/* Edit Button */}
        {storefrontStep !== StorefrontSteps.ORDER_PLACED && (
          <Button
            id="edit-cart-button"
            variant="link"
            onClick={() => setStorefrontStep(StorefrontSteps.CART)}
            ml="auto"
            mr="1rem"
            mt="0.5rem">
            Edit
          </Button>
        )}
      </Flex>
      <Box p="1rem" width="35rem">
        {/* Assets */}
        {assetCart.length > 0 && (
          <Flex vertical rowGap="1rem">
            {assetCart.some(isWarehouseAsset) && (
              <Flex vertical rowGap="1rem">
                <Text variant="label-large">Company assets</Text>
                <Chip
                  id="delivery-method-chip"
                  textColor={textChipColor}
                  backgroundColor={backgroundChipColor}
                  variant="small">
                  Delivery Method: Warehouse ship
                </Chip>
                {assetCart.filter(isWarehouseAsset).map(entry => {
                  return (
                    <Box key={entry.item.id}>
                      <Text>{entry.item.name}</Text>
                      <Text>QTY 1</Text>
                    </Box>
                  );
                })}
                {/* Divider */}
                {assetCart.some(entry => !isWarehouseAsset(entry)) && (
                  <Divider color={ColorsV2.GRAY_LIGHT} />
                )}
              </Flex>
            )}
            {assetCart.some(entry => !isWarehouseAsset(entry)) && (
              <Flex vertical rowGap="1rem">
                {showSelfShip && (
                  <Chip
                    id="delivery-method-chip"
                    textColor={textChipColor}
                    backgroundColor={backgroundChipColor}
                    variant="small">
                    Delivery Method: Self ship
                  </Chip>
                )}
                {assetCart
                  .filter(entry => !isWarehouseAsset(entry))
                  .map(entry => {
                    return (
                      <Box key={entry.item.id}>
                        <Text>{entry.item.name}</Text>
                        <Text>QTY 1</Text>
                      </Box>
                    );
                  })}
              </Flex>
            )}
            {catalogCart.length > 0 && (
              <Divider
                leadingSpace="1rem"
                trailingSpace="1rem"
                color={ColorsV2.GRAY_LIGHT}
              />
            )}
          </Flex>
        )}
        {/* Catalog */}
        {catalogCart.length > 0 && (
          <Flex vertical rowGap="1rem">
            <Text variant="label-large">Purchases</Text>
            {catalogCart.map(entry => {
              return (
                <Box key={entry.item.id}>
                  <Text>{entry.item.name}</Text>
                  <Text>
                    QTY {entry.quantity},{' '}
                    {formatCurrency(entry.item.price * entry.quantity)}
                  </Text>
                  {/* Show warranty as item only if the item is a laptop and the warranty selected is different from the default warranty */}
                  {isLaptop(entry.item) &&
                    'defaultWarranty' in entry.item &&
                    entry.selectedWarranty?.id !==
                      entry.item?.defaultWarranty?.id && (
                      <Box mt="1rem">
                        <Text>
                          {capitalize(entry.selectedWarranty?.name ?? '')}{' '}
                          {capitalize(entry.selectedWarranty?.brand ?? '')}{' '}
                          Warranty
                        </Text>
                        <Text>
                          QTY {entry.quantity},{' '}
                          {formatCurrency(
                            (entry.selectedWarranty?.price ?? 0) *
                              entry.quantity
                          )}
                        </Text>
                      </Box>
                    )}
                </Box>
              );
            })}
          </Flex>
        )}
      </Box>
    </Card>
  );
};

export default CartSummary;

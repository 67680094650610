import { useEffect } from 'react';

import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useLazyGetOrganizationEmployeesQuery } from '@/redux/slices/organizationApiSlice';
import { Employee } from '@/types/employees';

export const useOrganizationEmployees = (): Employee[] => {
  const currentOrganizationId = useGetOrganizationId();

  const [getOrganizationEmployees, { data: employees }] =
    useLazyGetOrganizationEmployeesQuery();

  useEffect(() => {
    if (currentOrganizationId) {
      getOrganizationEmployees({ organizationId: currentOrganizationId }, true);
    }
  }, [currentOrganizationId, getOrganizationEmployees]);

  return employees ?? [];
};

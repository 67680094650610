import { Device } from '@electricjs/core_entity-client';

export const singleDevice: Device[] = [
  {
    serial_number: 'serial-number-1',
    hardware_model: 'hardware-model-1',
    os_type: 'darwin',
    organization_id: 'org-id-1',
    assigned_to: '',
    id: 'device-id-1',
  },
];

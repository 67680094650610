import { Flex, useBreakpoint } from '@electricjs/arc';
import { ReactNode } from 'react';

type ActionsColumnProps = {
  children?: ReactNode;
};

export const ActionsColumn = ({ children }: ActionsColumnProps) => {
  const { md } = useBreakpoint();

  return (
    <Flex
      flexDirection={md ? 'row' : 'column'}
      minWidth="26rem"
      width={md ? 'auto' : '100%'}
      alignItems="center"
      marginLeft={md ? 'auto' : 'unset'}
      gap="1rem">
      {children}
    </Flex>
  );
};

import { ReactNode } from 'react';

import {
  ColorsV2,
  Divider,
  Flex,
  IconInCircle,
  Pane,
  Text,
  useThemeColors,
  KebabMenu,
} from '@electricjs/arc';

import { CenteredSpinner } from '@common';

type ABMManagementCardProps = {
  hasSettingsMenu?: boolean;
  onEdit?: () => void;
  onRemove?: () => void;
  children: ReactNode;
};

export const ABMManagementCard = ({
  hasSettingsMenu,
  onEdit,
  onRemove,
  children,
}: ABMManagementCardProps) => {
  const [borderColor, iconColor, iconBackgroundColor] = useThemeColors([
    ColorsV2.BORDER,
    ColorsV2.INFO,
    ColorsV2.INFO_LIGHTEST,
  ]);

  return (
    <Pane elevated flexDirection="column" mt="2rem">
      {/* Header */}
      <Flex
        p="2.5rem 3rem"
        width="100%"
        flexWrap="wrap"
        justifyContent="space-between"
        gap="1rem">
        <Flex width="100%">
          <IconInCircle
            color={iconColor}
            background={iconBackgroundColor}
            icon="logo-apple"
            customIconSize="2rem"
            size="3.2rem"
          />
          <Text variant="heading-3" ml="1rem">
            Apple Business Manager (ABM)
          </Text>
          {hasSettingsMenu && (
            <Flex ml="auto">
              <KebabMenu
                id="abm-settings-menu"
                items={[
                  {
                    label: 'Edit ABM',
                    iconBefore: 'pencil',
                    onClick: () => (onEdit ? onEdit() : null),
                  },
                  {
                    label: 'Remove ABM',
                    iconBefore: 'trashcan',
                    onClick: () => (onRemove ? onRemove() : null),
                  },
                ]}
              />
            </Flex>
          )}
        </Flex>
      </Flex>
      <Divider color={borderColor} />
      {/* Body */}
      {children}
    </Pane>
  );
};

export const StepContainer = ({
  children,
  step,
}: {
  children: ReactNode;
  step?: number;
}) => {
  const borderColor = ColorsV2.BORDER;

  return (
    <>
      <Flex width="100%">
        <Flex p="2rem" width="8rem" flexDirection="column" alignItems="end">
          {step && (
            <>
              <Text variant="label-small">STEP</Text>
              <Text variant="heading-2">{step}</Text>
            </>
          )}
        </Flex>
        <Divider vertical color={borderColor} />
        <Flex width="100%" flexWrap="wrap" justifyContent="space-between">
          <Flex gap="1.2rem" p="2rem" flexDirection="column" maxWidth="65rem">
            {children}
          </Flex>
        </Flex>
      </Flex>
      <Divider color={borderColor} />
    </>
  );
};

export const ABMLoadingContainer = () => {
  return (
    <ABMManagementCard>
      <CenteredSpinner height="20rem" alignItems="center" />
    </ABMManagementCard>
  );
};

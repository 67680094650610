import {
  InternalType,
  CoreEntityDomainsOrganizationsEntrypointsApiV1SchemasResponseOrganization as OrganizationDto,
  OrganizationStatusEnum,
} from '@electricjs/core_entity-client';
import { Employee as EmployeeDto } from '@electricjs/core_entity-client';
import { Group as GroupDto } from '@electricjs/core_entity-client';

import {
  simpleEmployee,
  unknownEmployee1,
  unknownEmployee2,
} from '@/mocks/data/employeeData';

import { simpleGroup } from './groupData';

// Simple Org
export const simpleOrganization: OrganizationDto = {
  id: '67d26876-05a6-11ee-be56-0242ac120002',
  name: 'Simple Org',
  hris_integrations: [
    {
      id: '6695cb87-899d-4d3d-8a44-b872b01872e6',
      organization_id: '67d26876-05a6-11ee-be56-0242ac120002',
      hris_provider: {
        id: '6695cb87-899d-4d3d-8a44-b872b01872e6',
        name: 'BambooHR',
        slug: 'bamboohr',
      },
    },
  ],
  entitlements: [],
  package: { id: 'package-beta-id', sku: 'package-beta-sku', name: 'beta' },
  internal_type: InternalType.Test,
  license_count: 50,
  salesforce_id: '626bf336-7f18-4e70-ac3a-da2322c7907d',
  status: OrganizationStatusEnum.Active,
};

export const simpleOrganizationEmployees: EmployeeDto[] = [simpleEmployee];

export const simpleOrganizationGroups: GroupDto[] = [simpleGroup];

// Connected Org (connected to HRIS)
export const connectedOrganization: OrganizationDto = {
  id: 'connected-org-id',
  name: 'Connected Org',
  hris_integrations: [
    {
      id: 'hris-id',
      organization_id: '67d26876-05a6-11ee-be56-0242ac120013',
      last_fetched_at: '2023-08-30T14:00:00.000Z',
      hris_provider: {
        id: '6695cb87-899d-4d3d-8a44-b872b01872e6',
        name: 'BambooHR',
        slug: 'bamboohr',
      },
    },
  ],
  entitlements: [
    {
      id: '67d26876-05a6-11ee-be56-0242ac120013',
      name: 'HRIS Integration',
      slug: 'hris_integration',
    },
  ],
  package: {
    id: 'package-essentials-id',
    sku: 'package-essentials-sku',
    name: 'essentials',
  },
  internal_type: InternalType.Test,
  license_count: 100,
  salesforce_id: '626bf336-7f18-4e70-ac3a-da2322c7907d',
  status: OrganizationStatusEnum.Active,
};

export const connectedOrganizationEmployees: EmployeeDto[] = [
  simpleEmployee,
  unknownEmployee1,
  unknownEmployee2,
];

import { Button, Chip, ColorsV2, Flex, Text } from '@electricjs/arc';

const MAX_IMAGES = 10;

type AssetImagesGridHeaderProps = {
  assetImagesCount: number;
  onOpenModal: () => void;
};

const AssetImagesGridHeader = ({
  assetImagesCount,
  onOpenModal,
}: AssetImagesGridHeaderProps) => {
  const enableImageUploadButton = assetImagesCount < MAX_IMAGES;

  return (
    <Flex
      width="100%"
      p="2.5rem"
      overflowX="hidden"
      vAlignContent="center"
      hAlignContent="between">
      <Flex vAlignContent="center">
        <Text variant="heading-2">Images</Text>
        <Chip
          id="asset-history-count-chip"
          ml="3"
          backgroundColor={ColorsV2.PRIMARY_LIGHTEST}
          variant="small">
          <Text variant="legal" intent="brand">
            {assetImagesCount}/{MAX_IMAGES} images
          </Text>
        </Chip>
      </Flex>
      <Flex alignItems="flex-end" columnGap="1.5rem">
        <Button
          id="import-assets-button"
          variant="outline"
          iconBefore="add-circle"
          onClick={onOpenModal}
          disabled={!enableImageUploadButton}>
          Add image
        </Button>
      </Flex>
    </Flex>
  );
};

export default AssetImagesGridHeader;

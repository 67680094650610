import { useEffect, useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGlobalUI } from '@/components/GlobalUIProvider';
import { useStorefront } from '../StorefrontProvider';
import { type Asset } from '@/types/assets';
import { ItemDetailsModal } from './ItemDetailsModal';
import { SelectionParamKeys } from '@/types/queryParamKeys';
import { Store, type Product } from '@/types/storefront';
import { type CatalogList, type AssetList } from './ItemList';

type CatalogDetails = CatalogList & {
  onItemDetailsAsyncRequest: (id: string) => Promise<Product>;
};

type AssetDetails = AssetList & {
  onItemDetailsAsyncRequest: (id: string) => Promise<Asset>;
};

type useItemDetailsModalParams = (CatalogDetails | AssetDetails) & {
  loadingItemsList: boolean;
  loadingItemDetails: boolean;
  hasItemDetailsError: boolean;
  itemDetailsErrorMessage: string;
  queryParam: SelectionParamKeys;
  readOnly?: boolean;
};

export const useItemDetailsModal = ({
  items,
  store,
  queryParam,
  loadingItemsList,
  loadingItemDetails,
  hasItemDetailsError,
  itemDetailsErrorMessage,
  onAddItem,
  onRemoveItem,
  onItemDetailsAsyncRequest,
  readOnly = false,
}: useItemDetailsModalParams) => {
  const [queryParams, writeQueryParams] = useSearchParams();

  const [itemDetails, setItemDetails] = useState<Product | Asset>();

  const { assetCart, catalogCart } = useStorefront();

  const { openModal, closeModal } = useGlobalUI();

  const itemId = queryParams.get(queryParam) ?? '';

  const handleCloseModal = useCallback(() => {
    // remove query param
    queryParams.delete(queryParam);
    writeQueryParams(queryParams, {
      // Each change in the query params will replace the current history state, meaning the "back button" will go back to the previous page.
      replace: true,
    });

    // clear state
    setItemDetails(undefined);

    closeModal();
  }, [queryParams, queryParam, writeQueryParams, closeModal]);

  const getItemDetails = useCallback(
    async itemId => {
      // try to get the item details from the current item list (it exists when the item is in the same page)
      const itemFound = items.find(item => item.id === itemId);

      if (itemFound) {
        setItemDetails(itemFound);
      } else {
        // when item is not in the list, try to get its details from backend
        try {
          const item = await onItemDetailsAsyncRequest(itemId);
          setItemDetails(item);
        } catch (error) {
          console.error('Error while getting items list', error);
        }
      }
    },
    [onItemDetailsAsyncRequest, items]
  );

  useEffect(() => {
    if (itemId && (loadingItemsList || loadingItemDetails)) {
      // open a modal with loading state
      openModal(
        <ItemDetailsModal
          store={store}
          onAddItem={() => {}}
          onRemoveItem={() => {}}
          onClose={handleCloseModal}
          selected={false}
          isLoading
        />
      );
    }
  }, [
    handleCloseModal,
    itemId,
    loadingItemDetails,
    loadingItemsList,
    openModal,
    store,
  ]);

  const hasItemLoaded = itemId && !loadingItemsList && !loadingItemDetails;

  useEffect(() => {
    if (hasItemLoaded && hasItemDetailsError) {
      // open a modal with error state
      openModal(
        <ItemDetailsModal
          store={store}
          onAddItem={() => {}}
          onRemoveItem={() => {}}
          onClose={handleCloseModal}
          selected={false}
          errorMessage={itemDetailsErrorMessage}
          isLoading={false}
        />
      );
    }
  }, [
    handleCloseModal,
    hasItemLoaded,
    itemDetailsErrorMessage,
    hasItemDetailsError,
    openModal,
    store,
  ]);

  useEffect(() => {
    if (hasItemLoaded && !itemDetails && !hasItemDetailsError) {
      getItemDetails(itemId);
    }
  }, [getItemDetails, hasItemLoaded, itemDetails, hasItemDetailsError, itemId]);

  useEffect(() => {
    if (hasItemLoaded && itemDetails) {
      // open a modal with the item details
      if (store === Store.Asset) {
        const assetItem = itemDetails as Asset;
        openModal(
          <ItemDetailsModal
            item={assetItem}
            store={Store.Asset}
            onAddItem={() => onAddItem(assetItem)}
            onRemoveItem={() => onRemoveItem(assetItem)}
            onClose={handleCloseModal}
            selected={assetCart.some(entry => entry.item.id === assetItem.id)}
            readOnly={readOnly}
          />
        );
      }
      if (store === Store.Catalog) {
        const catalogItem = itemDetails as Product;
        openModal(
          <ItemDetailsModal
            item={catalogItem}
            store={Store.Catalog}
            onAddItem={() => onAddItem(catalogItem)}
            onRemoveItem={() => onRemoveItem(catalogItem)}
            onClose={handleCloseModal}
            selected={catalogCart.some(
              entry => entry.item.id === catalogItem.id
            )}
            readOnly={readOnly}
          />
        );
      }
    }
  }, [
    assetCart,
    catalogCart,
    handleCloseModal,
    hasItemLoaded,
    itemDetails,
    onAddItem,
    onRemoveItem,
    openModal,
    readOnly,
    store,
  ]);
};

import { EmployeeBreachResult } from '@/types/employeeBreach';
import { EmployeeNameColumn, StatusChip } from '@common';
import {
  Button,
  Chip,
  ColorsV2,
  Flex,
  Text,
  Tooltip,
  UseToastParams,
} from '@electricjs/arc';
import { createColumnHelper } from '@tanstack/react-table';
import formatDate from '@/helpers/formatDate';
import {
  MarkEmployeeBreachResolvedResponse,
  ResolveEmployeeBreachArgs,
} from '@/redux/slices/employeeBreachApiSlice';
import BreachDescriptionModal from './BreachDescriptionModal';

export const sortDataClasses = (classA: string, classB: string) => {
  const sortOrder = [
    BreachClasses.PASSWORD,
    BreachClasses.PASSWORD_HINT,
    BreachClasses.PASSWORD_STRENGTHS,
  ];

  for (const dataClass of sortOrder) {
    if (classA === dataClass) return -1;
    if (classB === dataClass) return 1;
  }
  return 0;
};

const BreachClasses = {
  PASSWORD: 'Passwords',
  PASSWORD_HINT: 'Password hints',
  PASSWORD_STRENGTHS: 'Password strengths',
} as const;

const columnHelper = createColumnHelper<EmployeeBreachResult>();

const generateTableColumns = ({
  organizationId,
  markResolved,
  activeTab,
  openModal,
  closeModal,
  submittingBreachId,
  setSubmittingBreachId,
  showErrorToast,
  showSuccessToast,
}: {
  organizationId: string;
  markResolved: (
    args: ResolveEmployeeBreachArgs
  ) => MarkEmployeeBreachResolvedResponse;
  activeTab: 'Unresolved' | 'Resolved';
  openModal: (component: React.ReactNode) => void;
  closeModal: () => void;
  submittingBreachId: string | null;
  setSubmittingBreachId: (breachId: string | null) => void;
  showErrorToast: (overrideParams: UseToastParams) => void;
  showSuccessToast: (overrideParams: UseToastParams) => void;
}) => [
  columnHelper.accessor('employee', {
    header: () => <Text variant="label-small">Employee</Text>,
    id: 'employee',
    meta: {
      minWidth: '250px',
    },
    cell: ({ row }) => {
      return (
        <Flex width="100%">
          <EmployeeNameColumn
            firstName={row.original.employee.firstName}
            lastName={row.original.employee.lastName}
            email={row.original.employee.email}
            employeeId={row.original.employee.id}
            hideAvatar
          />
        </Flex>
      );
    },
  }),
  columnHelper.accessor('breach.displayName', {
    header: () => <Text variant="label-small">Breach Name</Text>,
    id: 'displayName',
    meta: {
      minWidth: '200px',
    },
    cell: data => {
      const breach = data.row.original.breach;
      const employeeId = data.row.original.employee.id;
      return (
        <Flex
          width="100%"
          vAlignContent="center"
          justifyContent="space-between">
          {breach.domainUrl ? (
            <Button
              id={`breach-description-button-${breach.domainUrl}`}
              variant="text"
              onClick={() =>
                openModal(
                  <BreachDescriptionModal
                    breachName={breach.name}
                    breachDate={breach?.breachedAt ?? ''}
                    breachDomain={breach?.domainUrl ?? ''}
                    closeModal={closeModal}
                    markResolved={() => {
                      setSubmittingBreachId(breach.id);
                      markResolved({
                        organizationId,
                        employeeId,
                        breachId: breach.id,
                      })
                        .then(() => {
                          showSuccessToast({
                            id: 'breach-mark-resolved-success-toast',
                            message:
                              'Breach was successfully marked as resolved.',
                            stack: true,
                          });
                        })
                        .catch(() => {
                          showErrorToast({
                            id: 'breach-mark-resolved-error-toast',
                            message:
                              'Failed to mark breach as resolved, please try again.',
                            stack: true,
                          });
                        })
                        .finally(() => {
                          setSubmittingBreachId(null);
                          closeModal();
                        });
                    }}
                  />
                )
              }>
              {data.getValue()}
            </Button>
          ) : (
            <Text variant="body-2">{data.getValue()}</Text>
          )}
        </Flex>
      );
    },
  }),
  columnHelper.accessor('breach.breachedAt', {
    header: () => <Text variant="label-small">Breach Date</Text>,
    id: 'createdAt',
    meta: {
      minWidth: '80px',
      maxWidth: '150px',
    },
    cell: data => {
      const formattedDate = formatDate(data.getValue(), 'MM/dd/yyyy');
      return (
        <Flex pt="10px" vAlignContent="center">
          <Text variant="body-2">{formattedDate}</Text>
        </Flex>
      );
    },
  }),
  columnHelper.accessor('breach.dataClasses', {
    header: () => <Text variant="label-small">Breach Type</Text>,
    id: 'dataClasses',
    meta: {
      maxWidth: '200px',
    },
    cell: data => {
      const dataClasses = data.getValue() ?? [];
      const sortedDataClasses = dataClasses.toSorted(sortDataClasses);
      const display =
        sortedDataClasses?.map(dc => (
          <Flex mt="0.3rem" key={dc}>
            {dc === BreachClasses.PASSWORD ? (
              <StatusChip
                id={`dataclasses-chip-${data.row.original.breach.id}`}
                text={dc}
                variant="error"
              />
            ) : (
              <StatusChip
                id={`dataclasses-chip-${data.row.original.breach.id}`}
                text={dc}
                variant="warning"
              />
            )}
          </Flex>
        )) ?? null;

      return (
        <Flex justifyContent="flex-start" flexDirection="column">
          {!!display && display[0]}
          {!!display && sortedDataClasses.length > 1 ? (
            <Tooltip
              placement="right"
              content={
                <Flex flexDirection="column" justifyContent="space-between">
                  {/* Display remaining chips in tooltip */}
                  {display.slice(1, display.length)}
                </Flex>
              }>
              <Chip
                id={`dataclasses-chip-more-${data.row.original.breach.id}`}
                variant="small"
                backgroundColor={ColorsV2.GRAY_LIGHTER}
                color={ColorsV2.TEXT}
                mt="0.5rem">
                + {display.length - 1} more
              </Chip>
            </Tooltip>
          ) : null}
        </Flex>
      );
    },
  }),
  columnHelper.accessor('resolvedAt', {
    header: () => (
      <Text variant="label-small">
        {activeTab === 'Unresolved' ? 'Actions' : 'Date Resolved'}
      </Text>
    ),
    id: 'resolvedAt',
    meta: {
      minWidth: '130px',
    },
    enableSorting: activeTab === 'Resolved',
    sortingFn: (a, b) => {
      const aResolvedAt: Date = a.getValue('resolvedAt');
      const bResolvedAt: Date = b.getValue('resolvedAt');
      if (aResolvedAt < bResolvedAt) {
        return -1;
      }
      if (aResolvedAt > bResolvedAt) {
        return 1;
      }
      return 0;
    },
    cell: data => {
      const resolvedAt = data.getValue();
      const employeeId = data.row.original.employee.id;
      const breachId = data.row.original.breach.id;
      return resolvedAt ? (
        <Flex>
          <Chip
            id={`resolved-chip-${breachId}`}
            variant="small"
            backgroundColor={ColorsV2.SUCCESS_LIGHTEST}
            color={ColorsV2.SUCCESS}>
            Resolved {formatDate(data.getValue(), 'MM/dd/yyyy')}
          </Chip>
        </Flex>
      ) : (
        <Button
          id={`mark-resolved-button-${breachId}`}
          loading={submittingBreachId === breachId}
          onClick={() => {
            setSubmittingBreachId(breachId);
            markResolved({ organizationId, employeeId, breachId })
              .then(() => {
                showSuccessToast({
                  id: 'breach-mark-resolved-success-toast',
                  message: 'Breach was successfully marked as resolved.',
                  stack: true,
                });
              })
              .catch(() => {
                showErrorToast({
                  id: 'breach-mark-resolved-error-toast',
                  message:
                    'Failed to mark breach as resolved, please try again.',
                  stack: true,
                });
              })
              .finally(() => {
                setSubmittingBreachId(null);
              });
          }}
          variant="outline">
          Mark resolved
        </Button>
      );
    },
  }),
];

export default generateTableColumns;

import { rest } from 'msw';

import { singleDevice } from '@/mocks/data/deviceData';
import { simpleEmployee } from '@/mocks/data/employeeData';
import { employeeGroups } from '@/mocks/data/groupData';

export const employeeHandlers = [
  rest.get('*/v1/employees/:id', (_req, res, ctx) => {
    return res(ctx.status(200), ctx.delay(), ctx.json(simpleEmployee));
  }),
  rest.get('*/v1/organizations/:id/employees/me', (_req, res, ctx) => {
    return res(ctx.status(200), ctx.delay(), ctx.json(simpleEmployee));
  }),
  rest.get('*/v1/organizations/:id/employees/:id', (_req, res, ctx) => {
    return res(ctx.status(200), ctx.delay(), ctx.json(simpleEmployee));
  }),
  rest.get(
    '*/v1/organizations/:id/employees/:employeeId/devices',
    (_req, res, ctx) => {
      if (_req.url.searchParams.get('employeeId') === simpleEmployee.id) {
        return res(ctx.status(200), ctx.delay(), ctx.json(singleDevice));
      } else {
        return res(ctx.status(200), ctx.delay(), ctx.json([]));
      }
    }
  ),
  rest.patch('*/v1/organizations/:id/employees/:id', (_req, res, ctx) => {
    return res(ctx.status(200), ctx.delay());
  }),
  rest.get(
    '*/v1/organizations/:organizationId/employees/:employeeId/groups',
    (_req, res, ctx) => {
      return res(ctx.status(200), ctx.delay(), ctx.json(employeeGroups));
    }
  ),
];

import { useState } from 'react';
import styled from 'styled-components';

import {
  ModalAction,
  ModalBody,
  ModalFooter,
  ModalFooterActions,
  ModalHeader,
  ModalV2,
  Text,
} from '@electricjs/arc';

import { useGlobalUI } from '@/components/GlobalUIProvider';
import { getDeviceIssueEmailSuccessToastMessage } from '@/components/People/EmployeeProfile/helpers/DevicePropertyHelpers';
import { useSendIssueEmailMutation } from '@/redux/slices/deviceApiSlice';

import { useAppSelector } from '@/redux/store';
import { DeviceIssueType, EmployeeDeviceOsGrouping } from '@/types/devices';
import { EmployeeWithAssets } from '@/types/employees';

import EmployeeCheckboxList from '@/components/DeviceOverview/EmployeeCheckboxList';

const StyledModalBody = styled(ModalBody)`
  overflow-y: scroll;
`;

type EmailModalProps = {
  hide: () => void;
  issueType: DeviceIssueType;
  employees: EmployeeWithAssets[];
  title: string;
  children: JSX.Element | JSX.Element[];
  showDevice?: boolean;
  showOS?: boolean;
  showDiskSpace?: boolean;
};

const EmailModal = ({
  hide,
  issueType,
  employees,
  title,
  children,
  showDevice,
  showOS,
  showDiskSpace,
}: EmailModalProps) => {
  const { showSuccessToast, showErrorToast } = useGlobalUI();
  const [selectedEmployees, setSelectedEmployees] = useState(employees);
  const [sendIssueEmail, { isLoading }] = useSendIssueEmailMutation();
  const organizationId = useAppSelector(
    state => state.loggedUser?.organizationId
  );
  const resetSelection = () => {
    setSelectedEmployees(employees);
  };
  const handleCancel = () => {
    setTimeout(resetSelection, 500);
    hide();
  };

  const issueTypeToast = getDeviceIssueEmailSuccessToastMessage(issueType);
  const handleEmailSendError = (error?: Error) => {
    const toastStack = !!error;
    console.error(`Unable to send ${issueTypeToast} instructions: `, error);
    showErrorToast({
      id: 'email-send-error-toast',
      stack: toastStack,
      title: 'Unable to send Emails',
      message:
        'Something went wrong while sending the emails. Please try again.',
    });
  };

  const handleSubmit = () => {
    if (organizationId) {
      // To support multiple devices per employee here, we send an mail per each OS type
      const employees_with_device_and_os: EmployeeDeviceOsGrouping[] = [];

      selectedEmployees.forEach(employee => {
        const employeeDevices = employee.assets || [];

        employeeDevices.forEach(asset => {
          const osType = asset.device?.osType || 'darwin'; // Default to 'darwin' if osType is undefined or falsy
          employees_with_device_and_os.push({
            employee_id: employee.id,
            os_type: osType,
            device_id: asset.device?.id || '',
          });
        });
      });

      sendIssueEmail({
        employeeDeviceOsGrouping: employees_with_device_and_os,
        issueType,
        organizationId,
      })
        .unwrap()
        .then(() => {
          showSuccessToast({
            id: 'email-send-success-toast',
            stack: true,
            message: `${issueTypeToast} instructions have been sent to ${
              selectedEmployees.length
            } ${selectedEmployees.length > 1 ? 'people' : 'person'}`,
          });
          handleCancel();
        })
        .catch(handleEmailSendError);
    } else {
      handleEmailSendError();
    }
  };

  return (
    <ModalV2 visible hide={hide} ariaLabelledby="email-employees">
      <ModalHeader>
        <Text variant="heading-2">{title}</Text>
      </ModalHeader>
      <StyledModalBody>
        {children}
        <EmployeeCheckboxList
          employees={employees}
          selectedEmployees={selectedEmployees}
          setSelectedEmployees={setSelectedEmployees}
          showDevice={showDevice}
          showOS={showOS}
          showDiskSpace={showDiskSpace}
        />
      </StyledModalBody>
      <ModalFooter>
        <ModalFooterActions>
          <ModalAction
            id="send-email-cancel-button"
            variant="text"
            onClick={handleCancel}>
            Cancel
          </ModalAction>
          <ModalAction
            id="send-email-button"
            loading={isLoading}
            disabled={selectedEmployees.length === 0}
            onClick={handleSubmit}>
            Send email
          </ModalAction>
        </ModalFooterActions>
      </ModalFooter>
    </ModalV2>
  );
};

export default EmailModal;

export type JumpCloudProvisionArgs = {
  organizationId: string;
  name: string;
  hasMacDevices: boolean;
};

export type LockArgs = {
  organizationId: string;
  deviceId: string;
  serialNumber: string;
};

export type WipeArgs = {
  organizationId: string;
  deviceId: string;
};

export type DeleteSystemBySerialNumberArgs = {
  organizationId: string;
  serialNumber: string;
};

export enum ProvisionStatusEnum {
  PROVISIONING_STATUS = 'provisioning',
  PENDING_CERTIFICATE_STATUS = 'pending_certificate',
  COMPLETED_STATUS = 'completed',
}

export type JumpCloudAppleMdmProvisionArgs = {
  organizationId: string;
  appleCertCreatorAppleId: string;
  appleSignedCert: string;
  dep?: object;
  encryptedDepServerToken?: string;
  allowMobileUserEnrollment?: boolean;
  defaultIosUserEnrollmentDeviceGroupId?: string;
  defaultSystemGroupId?: string;
  name?: string;
};

export type AppleCertificate = {
  creatorAppleId: string;
  serialNumber: string;
  // String because our redux store is not able to handle Date objects
  expiresAt: string;
};

export type MDMInformation = {
  mdmOrganizationId?: string;
  appleCertificate?: AppleCertificate;
};

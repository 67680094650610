import { SortOrder } from '@electricjs/core_entity-client';
import { useCallback } from 'react';

import { NotificationsComponentProps } from '@common';

import {
  useGetUnseenNotificationsCountQuery,
  useLazyGetNotificationsQuery,
  useMarkNotificationsAsSeenMutation,
} from '@/redux/slices/notificationsApiSlice';

export const useNotificationsProps = (
  organizationId?: string
): NotificationsComponentProps => {
  const { data: unseenNotificationsCount } =
    useGetUnseenNotificationsCountQuery({ organizationId });

  const [fetchNotifications, { data: notificationsData, isFetching }] =
    useLazyGetNotificationsQuery();

  const [markNotificationsAsSeen] = useMarkNotificationsAsSeenMutation();

  const onMarkSeenNotifications = useCallback(
    (notificationIds: string[]) => {
      if (notificationIds.length > 0) {
        markNotificationsAsSeen({ notificationIds });
      }
    },
    [markNotificationsAsSeen]
  );

  const onOpenedHandler = useCallback(
    (limit, offset) => {
      if (organizationId) {
        fetchNotifications({
          organizationId,
          limit,
          offset,
          sortOrder: SortOrder.Desc,
        });
      }
    },
    [fetchNotifications, organizationId]
  );

  return {
    notifications: notificationsData?.notifications ?? [],
    totalNotifications: notificationsData?.total ?? 0,
    notificationsCount: unseenNotificationsCount ?? 0,
    loadingNotifications: isFetching,
    onFetchNotifications: onOpenedHandler,
    onMarkSeenNotifications: onMarkSeenNotifications,
  };
};

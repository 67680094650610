import styled from 'styled-components';

import {
  Button,
  Box,
  Flex,
  Icon,
  Text,
  Tooltip,
  Skeleton,
  type Address,
} from '@electricjs/arc';

import { InlineError, useResponsiveness } from '@common';

import { useGetBillingInfoQuery } from '@/redux/slices/billingApiSlice';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useGlobalUI } from '@/components/GlobalUIProvider';
import { BillingInfoModal } from '@/components/Billing/BillingInfoModal';

const StyledText = styled(Text)`
  word-break: break-word;
  hyphens: auto;
`;

const BillingInfoEmailMessage = () => {
  return (
    <Flex vertical rowGap="1rem" p="1rem">
      <Text variant="heading-2">Billing Email</Text>
      <Text>
        An invoice will be sent to the designated billing email address once the
        purchase is processed.
      </Text>
    </Flex>
  );
};

type BillingInfoBaseProps = {
  isFetching: boolean;
  isError: boolean;
};

type BillingInfoEmailProps = BillingInfoBaseProps & {
  billingEmail?: string;
};

const BillingInfoEmail = ({
  isFetching,
  isError,
  billingEmail,
}: BillingInfoEmailProps) => {
  if (isFetching) {
    return <Skeleton width="15rem" />;
  }

  if (!isFetching && isError) {
    return <InlineError message="Billing email unavailable" />;
  }

  if (!isFetching && !isError && billingEmail) {
    return <StyledText>{billingEmail}</StyledText>;
  }

  return null;
};

type BillingInfoAddressProps = BillingInfoBaseProps & {
  billingAddress?: Address;
};

const BillingInfoAddress = ({
  isFetching,
  isError,
  billingAddress,
}: BillingInfoAddressProps) => {
  if (isFetching) {
    return (
      <Flex vertical width="100%" rowGap="1rem">
        <Skeleton width="15rem" />
        <Skeleton width="15rem" />
      </Flex>
    );
  }

  if (!isFetching && isError) {
    return <InlineError message="Billing address unavailable" />;
  }

  if (!isFetching && !isError && billingAddress) {
    const { streetAddress1, streetAddress2, city, state, zip } = billingAddress;
    return (
      <Flex vertical width="100%">
        <StyledText>{streetAddress1}</StyledText>
        <StyledText>{streetAddress2}</StyledText>
        <StyledText>
          {city}, {state} {zip}
        </StyledText>
      </Flex>
    );
  }

  return null;
};

type BillingInfoReadOnlyProps = BillingInfoAddressProps &
  BillingInfoEmailProps & {
    hasBillingAddress?: boolean;
    hasBillingEmail?: boolean;
  };

export const BillingInfoReadOnly = ({
  isFetching,
  isError,
  billingAddress,
  billingEmail,
  hasBillingAddress = true,
  hasBillingEmail = true,
}: BillingInfoReadOnlyProps) => {
  const { hasNoHorizontalSpace } = useResponsiveness();

  return (
    <Flex
      vertical={hasNoHorizontalSpace}
      columnGap="2rem"
      rowGap={hasNoHorizontalSpace ? '2rem' : '0rem'}
      width="100%">
      {/* Billing Address */}
      {hasBillingAddress && (
        <Flex
          columnGap="0.5rem"
          width={hasNoHorizontalSpace || !hasBillingEmail ? '100%' : '50%'}>
          <Text variant="label-large">Billing Address</Text>
          <BillingInfoAddress
            isFetching={isFetching}
            isError={isError}
            billingAddress={billingAddress}
          />
        </Flex>
      )}
      {/* Billing Email */}
      {hasBillingEmail && (
        <Flex
          columnGap="0.5rem"
          width={hasNoHorizontalSpace || !hasBillingAddress ? '100%' : '50%'}>
          <Text variant="label-large">Billing Email</Text>
          <Tooltip
            maxWidth="40rem"
            content={() => <BillingInfoEmailMessage />}
            placement="top-start">
            <Icon icon="information-circle" />
          </Tooltip>
          <Box ml="0.5rem">
            <BillingInfoEmail
              isFetching={isFetching}
              isError={isError}
              billingEmail={billingEmail}
            />
          </Box>
        </Flex>
      )}
    </Flex>
  );
};

const BillingInfoDetails = () => {
  const { openModal, closeModal } = useGlobalUI();

  const organizationId = useGetOrganizationId();

  const {
    data: billingInfo,
    isFetching,
    isError,
  } = useGetBillingInfoQuery(organizationId);

  return (
    <Flex vertical rowGap="2rem">
      <Flex columnGap="1rem" alignItems="center">
        <Text variant="heading-3">Company billing information</Text>
        <Button
          id="edit-billing-info-button"
          variant="text"
          onClick={() =>
            openModal(
              <BillingInfoModal
                onClose={closeModal}
                billingInfo={billingInfo}
              />
            )
          }>
          Edit
        </Button>
      </Flex>
      <BillingInfoReadOnly
        isFetching={isFetching}
        isError={isError}
        billingAddress={billingInfo?.address}
        billingEmail={billingInfo?.email}
      />
    </Flex>
  );
};

export default BillingInfoDetails;

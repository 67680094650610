import { useRef, useEffect, useState } from 'react';

import styled from 'styled-components';

import { StyleProps } from '@electricjs/arc/common/utils';
import {
  Box,
  ColorsV2,
  Flex,
  IconInCircle,
  Text,
  useThemeColors,
} from '@electricjs/arc';
import { IconSize } from '@electricjs/arc/components/Icon';

import ProgressBar from './ProgressBar';

const VerticalProgressBar = styled(Box)`
  transform: rotate(90deg);
  overflow: hidden;
  width: 3rem;
  margin-left: 0.5rem;
  align-self: flex-start;
`;

const HorizontalProgressBar = styled(Box)`
  margin-top: 1.5rem;
  width: 10rem;
`;

type StepperItem = {
  completed: boolean;
  title?: string;
  subTitle?: string;
  wrapText?: boolean;
};

export type ProgressStepperProps = StyleProps & {
  stepperList: StepperItem[];
  iconSize?: IconSize;
  iconContainerSize?: string;
  customIconSize?: string | number;
};

const ProgressStepper = ({
  iconContainerSize,
  iconSize = 'medium',
  customIconSize,
  stepperList,
}: ProgressStepperProps) => {
  const [successColor, iconColor, disabledColor] = useThemeColors([
    ColorsV2.SUCCESS,
    ColorsV2.WHITE,
    ColorsV2.GRAY,
  ]);

  const stepperRef = useRef<HTMLDivElement>(null);
  const stepperDivElement = stepperRef.current; // this object reference will never change among rerenders

  const [renderingVertical, setRenderingVertical] = useState(false);

  const horizontalTypeMinWidth = useRef(0); // creates a simple "variable" to keep the "min width" needed for the horizontal Stepper

  const defaultIconContainerSize = renderingVertical ? '3.6rem' : '4.6rem';

  useEffect(() => {
    let observer = null;

    if (stepperDivElement) {
      observer = new ResizeObserver(() => {
        const { scrollWidth, offsetWidth } = stepperDivElement;

        // check if the component has overflowed when it was in the horizontal (check if it would trigger scroll)
        // adding 10px margin to avoid screen flickering
        if (scrollWidth - offsetWidth > 10 && !renderingVertical) {
          horizontalTypeMinWidth.current = offsetWidth; // save the "min width" needed to fit the horizontal Stepper
          setRenderingVertical(true);
        }

        // check if the horizontal type would fit again, when it is in the vertical
        if (
          offsetWidth - horizontalTypeMinWidth.current > 10 &&
          renderingVertical
        ) {
          setRenderingVertical(false);
        }
      });
    }

    if (stepperDivElement && observer) {
      observer.observe(stepperDivElement);
    }

    return () => {
      if (stepperDivElement && observer) {
        observer.unobserve(stepperDivElement);
      }
    };
  }, [stepperDivElement, renderingVertical]);

  return (
    <Flex
      ref={stepperRef}
      vertical={renderingVertical}
      rowGap={renderingVertical ? '0.5rem' : '4rem'}
      width="100%"
      justifyContent="center">
      {stepperList.map((stepItem, index) => {
        const isCompleted =
          stepItem.completed && stepperList[index + 1]?.completed;
        const isInProgress =
          stepItem.completed && !stepperList[index + 1]?.completed;

        return (
          <Flex
            vertical={renderingVertical}
            {...(renderingVertical && {
              alignItems: 'center',
              // Sets each row with a fixed height, but removes the height of the last element
              height: `${index !== stepperList.length - 1 ? '8rem' : 'auto'}`,
            })}
            key={`progress-stepper-${index}`}>
            {/* Icon + Title + Subtitle */}
            <Flex
              vertical={!renderingVertical}
              rowGap="1rem"
              {...(renderingVertical
                ? {
                    columnGap: '2rem',
                    height: '5rem',
                    justifyContent: 'center',
                  }
                : {
                    alignItems: 'center',
                    width: '12rem',
                  })}>
              <IconInCircle
                key={`icon-in-circle-${index}`}
                icon={stepItem.completed ? 'check' : undefined}
                iconSize={iconSize}
                color={iconColor}
                size={iconContainerSize ?? defaultIconContainerSize}
                background={stepItem.completed ? successColor : disabledColor}
                backgroundColor={
                  stepItem.completed ? successColor : disabledColor
                }
                customIconSize={customIconSize}
              />
              <Flex
                vertical
                {...(renderingVertical
                  ? {
                      // To center align the text when there is no subTitle available
                      alignSelf: 'center',
                      marginBottom: '1rem',
                    }
                  : {
                      marginTop: '1rem',
                      rowGap: '1rem',
                      alignItems: 'center',
                    })}>
                <Text
                  variant="body"
                  textAlign={renderingVertical ? 'left' : 'center'}
                  {...(!stepItem?.wrapText && {
                    whiteSpace: 'nowrap',
                  })}>
                  {stepItem?.title}
                </Text>
                <Text
                  variant="label-large"
                  textAlign={renderingVertical ? 'left' : 'center'}
                  {...(!stepItem?.wrapText && {
                    whiteSpace: 'nowrap',
                  })}>
                  {stepItem?.subTitle}
                </Text>
              </Flex>
            </Flex>
            {/* Progress Bar */}
            {index !== stepperList.length - 1 && (
              <>
                {renderingVertical ? (
                  <VerticalProgressBar>
                    <ProgressBar
                      isCompleted={isCompleted}
                      isInProgress={isInProgress}
                    />
                  </VerticalProgressBar>
                ) : (
                  <HorizontalProgressBar>
                    <ProgressBar
                      isCompleted={isCompleted}
                      isInProgress={isInProgress}
                    />
                  </HorizontalProgressBar>
                )}
              </>
            )}
          </Flex>
        );
      })}
    </Flex>
  );
};

export default ProgressStepper;

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  CustomComponents,
  ModalAction,
  ModalBody,
  ModalFooter,
  ModalFooterActions,
  ModalHeader,
  ModalV2,
  Pane,
  SearchableMultiSelectField,
  Text,
  TextInputField,
} from '@electricjs/arc';

import { useGlobalUI } from '@/components/GlobalUIProvider';
import AddOnsError from '@/components/Organization/AddOnsError';
import {
  useAddOrganizationAddOnMutation,
  useListAllAddOnsQuery,
} from '@/redux/slices/addonApiSlice';
import { AddOn } from '@/types/addons';

type AddOnOption = {
  value: string;
  label: string;
  vendor: string;
};

type AddPlanModalProps = {
  onCancel: () => void;
  onSuccess: () => void;
  organizationId: string;
  currentAddOnsIds: string[];
};

type AddPlanModalBodyProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any;
  addOnsOptions: AddOnOption[];
  isAddOnsLoading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trigger: any;
};

type AddPlanModalFooterActionsProps = {
  isLoading: boolean;
  isValid: boolean;
  onCancel: () => void;
  onSubmit: () => void;
};

type FormData = {
  addOnId: string;
  licenseCount: number;
};

const addOnOptionCustomComponents: CustomComponents = {
  Option: option => {
    const labelTextIntent = option.isSelected ? 'disabled' : 'primary';
    const bodyTextIntent = option.isSelected ? 'disabled' : 'secondary';

    return (
      <Pane py="0.2rem" flexWrap="nowrap">
        <Pane stack>
          <Text variant="label-large" intent={labelTextIntent}>
            {option.data.label}
          </Text>
          <Text variant="body-2" intent={bodyTextIntent}>
            {option.data.vendor}
          </Text>
        </Pane>
      </Pane>
    );
  },
};

const getAddOnsOptions = (
  addOnsData: AddOn[] | undefined,
  currentAddOnsIds: string[]
): AddOnOption[] => {
  return (
    addOnsData
      ?.filter(addOn => !currentAddOnsIds.includes(addOn.id))
      .map((addOn: AddOn) => {
        return {
          value: addOn.id,
          label: addOn.name,
          vendor: addOn.vendor,
        };
      }) || []
  );
};

const addPlanSchema = yup.object().shape({
  addOnId: yup.string().required('Please select at least one add-on'),
  licenseCount: yup
    .number()
    .required('License Count is required')
    .typeError('Please add at least 1 license')
    .min(1, 'Please add at least 1 license'),
});

const AddPlanModalHeader = () => (
  <ModalHeader py={1}>
    <Text variant="heading-2">Add a plan and license count</Text>
  </ModalHeader>
);

const AddPlanModalBody = ({
  control,
  addOnsOptions,
  isAddOnsLoading,
  trigger,
}: AddPlanModalBodyProps) => (
  <ModalBody py={24}>
    <SearchableMultiSelectField
      id="add-on-plan-search-select"
      name="addOnId"
      control={control}
      options={addOnsOptions}
      label="Add-on"
      required
      isMulti={false}
      placeholder="Search by name or vendor"
      searchableFields={['label', 'vendor']}
      isLoading={isAddOnsLoading}
      customComponents={addOnOptionCustomComponents}
      trigger={trigger}
    />
    <TextInputField
      id="license-count-input"
      name="licenseCount"
      control={control}
      label="License Count"
      required
    />
  </ModalBody>
);

const AddPlanModalFooterActions = ({
  isLoading,
  isValid,
  onCancel,
  onSubmit,
}: AddPlanModalFooterActionsProps) => (
  <ModalFooter py={16}>
    <ModalFooterActions>
      <ModalAction
        id="add-plan-modal-cancel-button"
        variant="text"
        disabled={isLoading}
        onClick={onCancel}>
        Cancel
      </ModalAction>
      <ModalAction
        id="add-plan-button"
        type="button"
        disabled={!isValid}
        loading={isLoading}
        onClick={onSubmit}>
        Add plan
      </ModalAction>
    </ModalFooterActions>
  </ModalFooter>
);

const AddPlanModal = ({
  onCancel,
  onSuccess,
  organizationId,
  currentAddOnsIds,
}: AddPlanModalProps) => {
  const { showErrorToast, showSuccessToast } = useGlobalUI();
  const {
    isFetching: isAddOnsLoading,
    isError: isAddOnsError,
    data: addOnsData,
  } = useListAllAddOnsQuery();

  const addOnsOptions = getAddOnsOptions(addOnsData, currentAddOnsIds);
  const [addOrganizationAddOn, { isLoading: addOrganizationAddOnLoading }] =
    useAddOrganizationAddOnMutation();

  const defaultValues: FormData = {
    addOnId: '',
    licenseCount: 0,
  };

  const {
    handleSubmit,
    control,
    formState: { isValid },
    trigger,
  } = useForm<FormData>({
    resolver: yupResolver(addPlanSchema),
    mode: 'onTouched',
    defaultValues,
  });

  const onSubmit = (data: FormData) => {
    const { addOnId, licenseCount } = data;
    const selectedAddOn = addOnsData?.find(addOn => addOn.id === addOnId);

    addOrganizationAddOn({ organizationId, addOnId, licenseCount })
      .unwrap()
      .then(() => {
        showSuccessToast({
          id: 'add-on-added-success-toast',
          stack: true,
          title: 'Add on added',
          message: `${selectedAddOn?.name} add on was added successfully.`,
        });
        onSuccess();
      })
      .catch(() => {
        showErrorToast({
          id: 'add-on-added-error-toast',
          stack: true,
          message:
            'Something went wrong while adding the add on. Please try again.',
        });
      });
  };

  if (isAddOnsError) {
    return <AddOnsError />;
  }

  return (
    <ModalV2 ariaLabelledby="add-plan" hide={onCancel} visible>
      <AddPlanModalHeader />
      <AddPlanModalBody
        control={control}
        addOnsOptions={addOnsOptions}
        isAddOnsLoading={isAddOnsLoading}
        trigger={trigger}
      />
      <AddPlanModalFooterActions
        isLoading={addOrganizationAddOnLoading}
        isValid={isValid}
        onCancel={onCancel}
        onSubmit={handleSubmit(onSubmit)}
      />
    </ModalV2>
  );
};

export default AddPlanModal;

import { Auth0ContextInterface } from '@auth0/auth0-react';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

import { Banner, Button, Flex, Spacer } from '@electricjs/arc';

import { useAppDispatch } from '@/redux/store';

type Auth0ErrorStateProps = {
  auth0Error: Error;
  logout: Auth0ContextInterface['logout'];
  loggedOut: ActionCreatorWithPayload<void, string>;
};

export const Auth0ErrorState = ({
  auth0Error,
  logout,
  loggedOut,
}: Auth0ErrorStateProps) => {
  const dispatch = useAppDispatch();

  const errorMap: Record<string, string> = {
    login_failure:
      'Sorry, we could not log you in. Please contact your administrator.',
    user_not_found:
      'Your login is not associated with a user in our system, please try to login again or contact your administrator.',
    user_has_no_organizations:
      'Your user is not associated with any organizations in our system, please contact your administrator to resolve this.',
  };

  const message = errorMap[auth0Error.message]
    ? errorMap[auth0Error.message]
    : errorMap.login_failure;

  const handleLogOut = () => {
    // log out from auth0
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

    dispatch(loggedOut()); // clear states on redux
  };

  return (
    <Flex
      width="100%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      mt="10rem">
      <Banner
        id="login-error-banner"
        isFlat
        intent="error"
        title="Login Error"
        message={message}
      />
      <Spacer space={6} />
      <Button
        id="button-log-out-error-page"
        variant="fill"
        intent="danger"
        onClick={handleLogOut}>
        Log Out
      </Button>
    </Flex>
  );
};

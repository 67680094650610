import { SessionStorageItems } from '@/types/sessionStorageItems';
import {
  CallerIdentityWithOrganizations,
  UserOrganization,
} from '@/types/users';

export const checkIfOrgIsAlreadyActive = (
  myOrgRoles: CallerIdentityWithOrganizations['organizations'],
  orgId: string
) => {
  return !!myOrgRoles[orgId];
};

export const handleSwitchOrg =
  ({
    orgId,
    myOrgRoles,
    setError,
  }: {
    orgId: string;
    myOrgRoles: {
      [key: string]: UserOrganization;
    };
    setError: (value: React.SetStateAction<boolean>) => void;
  }) =>
  () => {
    const selectedOrg = myOrgRoles[orgId];

    if (!selectedOrg) {
      setError(true);
      return;
    }

    sessionStorage.setItem(
      SessionStorageItems.SELECTED_ORG_DATA,
      JSON.stringify({
        selectedOrg: selectedOrg,
        selectedOrgId: orgId,
      })
    );

    // Reload the page to trigger the initialization flow
    window.location.replace('/');
  };

import { DeviceAsset } from '@/types/assets';

// This is the logic we use to determine if the App was ever installed.
export function getWasElectricAppInstalledOnDevice(
  deviceAsset: DeviceAsset
): boolean {
  return !!deviceAsset.device.reportingData?.lastOsqueryEnrollment;
}

// Similar to the above, this is how we determine whether MDM is on a device.
// NOTE: Throughout our application, we only count MDM as "really installed" if
// there's an employee association.

export function getWasMDMInstalledOnDevice(deviceAsset: DeviceAsset): boolean {
  return !!deviceAsset.device.mdmEnrolledAt;
}

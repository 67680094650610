import { Employee } from '@/types/employees';

type FormatPhoneNumberProps = Employee['phone'] | null;

export const formatPhoneNumber = (phoneNumber: FormatPhoneNumberProps) => {
  if (!phoneNumber || phoneNumber.length !== 10 || isNaN(Number(phoneNumber))) {
    return '';
  }

  const areaCode = phoneNumber.slice(0, 3);
  const centralOfficeCode = phoneNumber.slice(3, 6);
  const lineNumber = phoneNumber.slice(6, 10);

  return `(${areaCode}) ${centralOfficeCode}-${lineNumber}`;
};

import React from 'react';
import styled from 'styled-components';

const StyledNavLogo = styled.a`
  .nav-logo__img {
    height: 3rem;
    vertical-align: middle;
  }
`;

export type LogoLinkProps = {
  href: string;
  target?: string;
};

export type NavLogoProps = {
  logoElement: React.ReactNode;
  logoLinkProps: LogoLinkProps;
};

const NavLogo = ({
  logoElement,
  logoLinkProps,
}: NavLogoProps): React.ReactElement => (
  <StyledNavLogo id="arc-nav-logo" className="nav-logo" {...logoLinkProps}>
    {logoElement}
  </StyledNavLogo>
);

export default NavLogo;

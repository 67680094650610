import { addOns } from './discover';
import { AddOnVendors } from '@/types/addons';

export type AddonInformation = {
  icon: string;
  email: string;
  supportCenterDisplayName: string;
};

const addonInformation: Record<string, AddonInformation> = {
  [addOns[AddOnVendors.ThreatDown].name]: {
    icon: addOns[AddOnVendors.ThreatDown].icon,
    email: 'mwbsupport@electric.ai',
    supportCenterDisplayName: 'ThreatDown',
  },
  [addOns[AddOnVendors.Perimeter81].name]: {
    icon: addOns[AddOnVendors.Perimeter81].icon,
    email: 'p81support@electric.ai',
    supportCenterDisplayName: 'Perimeter81',
  },
  [addOns[AddOnVendors.HEC].name]: {
    icon: addOns[AddOnVendors.HEC].icon,
    email: 'avanansupport@electric.ai',
    supportCenterDisplayName: 'Harmony Email & Collaboration',
  },
};

export default addonInformation;

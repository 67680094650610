import Fuse from 'fuse.js';

import {
  type ResultKind,
  resultKindDisplayLabels,
  type SmartSearchSelectOption,
  FUSE_SEARCH_KEYS,
} from '../resultKindConfig';

export const search = async (
  searchableData: SmartSearchSelectOption[],
  searchTerm: string
) => {
  return await Promise.resolve().then(async () => {
    const fuse = new Fuse<SmartSearchSelectOption>(searchableData, {
      keys: FUSE_SEARCH_KEYS,
      threshold: 0.5,
    });

    const searchResults = fuse.search(searchTerm);

    // Store the search results in a map with the result kind as the key
    const resultMap: { [key in ResultKind]?: SmartSearchSelectOption[] } = {};

    searchResults.forEach(result => {
      const resultItem = result.item;
      const resultItemKind = resultItem.kind;

      // Get the existing array of result items of the same kind or initialize an empty array to store the result items
      const searchResultsOfKind = resultMap[resultItemKind] ?? [];

      if (searchResultsOfKind) {
        // Push the result item to the corresponding array in the map
        searchResultsOfKind.push(resultItem);
      } else {
        console.error(`cannot render unknown result kind: ${resultItemKind}`);
      }

      // Update the map with the new array of result items
      // This is done dynamically so the groups will be in the order they appear in the search results
      // e.g. if the first result is of ResultKind.EMPLOYEE, the first group will be the Employees group
      resultMap[resultItemKind] = searchResultsOfKind;
    });

    // Map each ResultKind in the resultMap to its display label
    return Object.entries(resultMap).map(([kind, options]) => ({
      label: resultKindDisplayLabels[kind as ResultKind],
      options,
    }));
  });
};

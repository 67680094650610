import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  Box,
  SelectField,
  customComponents,
  Button,
  Flex,
  Text,
} from '@electricjs/arc';

import { ActionConfirmationModal } from '@common';

import { useGlobalUI } from '@/components/GlobalUIProvider';
import { EmployeeOption } from '@/components/People/EmployeeGroups/NewEmployeeGroupForm';
import { transformEmployeesToOptions } from '@/components/People/transformOptions';

import { SelectionParamKeys } from '@/types/queryParamKeys';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useGetOrganizationEmployeesQuery } from '@/redux/slices/organizationApiSlice';

import { useStorefront } from '../../StorefrontProvider';
import { emailSeparator } from '../StorefrontCheckoutFormProvider';

export const EmployeeSection = () => {
  const { storefrontCheckoutForm, resetStorefront } = useStorefront();

  const { openModal, closeModal, showSuccessToast } = useGlobalUI();

  const organizationId = useGetOrganizationId();

  const { data: employees, isFetching: isFetchingEmployees } =
    useGetOrganizationEmployeesQuery({
      organizationId,
    });

  const [queryParams] = useSearchParams();

  const employeeId = queryParams.get(SelectionParamKeys.EmployeeId);

  // Populate shipping details based on employee information
  const populateShippingDetails = useCallback(
    (employee: EmployeeOption) => {
      // Resets all the shipping details (in case the user selects other employee)
      storefrontCheckoutForm?.resetField('shippingName');
      storefrontCheckoutForm?.resetField('shippingPhone');
      storefrontCheckoutForm?.resetField('shippingAddress');
      storefrontCheckoutForm?.resetField('shippingEmail');
      storefrontCheckoutForm?.resetField('shippingMethod');
      storefrontCheckoutForm?.resetField('notes');

      if (employee) {
        const { name, address, email, personalEmail, phone } = employee;

        storefrontCheckoutForm?.setValue('shippingName', name);
        storefrontCheckoutForm?.setValue('shippingPhone', phone);

        const emails = [email, personalEmail].filter(item => item); // creates the array of emails and remove the undefined or blank ones;
        storefrontCheckoutForm?.setValue(
          'shippingEmail',
          emails.join(`${emailSeparator} `)
        );

        if (address) {
          storefrontCheckoutForm?.setValue('shippingAddress', { ...address });
        }
      }
    },
    [storefrontCheckoutForm]
  );

  // If employeeId param is passed in the URL and the form values for employee is empty, preloads employee in the dropdown and fill in the shipping details
  useEffect(() => {
    if (
      employees &&
      employeeId &&
      !storefrontCheckoutForm?.getValues('employee')?.value
    ) {
      const employeeOptionFound = transformEmployeesToOptions(employees).find(
        employeeOption => employeeOption.value === employeeId
      );

      if (employeeOptionFound) {
        storefrontCheckoutForm?.setValue('employee', employeeOptionFound);
        populateShippingDetails(employeeOptionFound);
      }
    }
  }, [employeeId, employees, populateShippingDetails, storefrontCheckoutForm]);

  const handleClearEmployeeCart = async () => {
    resetStorefront();
    closeModal();
    showSuccessToast({
      id: 'employee-cart-removed-success-toast',
      message: 'Employee and cart removed successfully.',
    });
  };

  const openClearEmployeeCartModal = () => {
    openModal(
      <ActionConfirmationModal
        title="Clear employee and cart"
        cancelButtonLabel="Continue shopping"
        confirmButtonLabel="Yes, clear cart"
        onCancel={closeModal}
        onConfirm={handleClearEmployeeCart}
        message={
          <>
            <Text>
              You are about to clear the employee and their cart, all items
              previously added will be removed. You can re-select an employee
              and add items at any time to complete a purchase.
            </Text>
            <Text mt="2rem">
              Confirm below if you&apos;d like to clear the employee and their
              cart.
            </Text>
          </>
        }
      />
    );
  };

  return (
    <Flex width="100%">
      <Box width="44.8rem">
        {storefrontCheckoutForm && (
          <SelectField
            id="storefront-employee-select"
            key={storefrontCheckoutForm?.getValues('employee')?.value}
            name="employee"
            control={storefrontCheckoutForm?.control}
            value={storefrontCheckoutForm?.getValues('employee')}
            customComponents={customComponents}
            label="Search employee"
            placeholder="Enter name"
            options={
              employees ? transformEmployeesToOptions(employees) : undefined
            }
            isLoading={isFetchingEmployees}
            searchableFields={['name', 'email']}
            isSearchable
            isClearable
            required
            disabled={Boolean(employeeId)}
            onChange={(employee, meta) => {
              populateShippingDetails(employee);
              if (meta.action === 'clear') {
                storefrontCheckoutForm.resetField('employee');
              }
            }}
          />
        )}
      </Box>
      <Button
        id="clear-employee-cart-button"
        onClick={openClearEmployeeCartModal}
        variant="text"
        mt="2rem" // need to use margin-top instead of "align-items:center" to align vertically with Search employee component (specially when the input error message appears)
        ml="1rem">
        Clear employee and cart
      </Button>
    </Flex>
  );
};

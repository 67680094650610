import { ProductTypeName, SortOrder } from '@electricjs/core_entity-client';
import { Box, Flex } from '@electricjs/arc';

import { SortingParams } from '@/types/common';
import {
  type DynamicFilters,
  StorefrontProductTypeGroup,
  FilterKeyEnum,
} from '@/types/storefront';

import ProductMenuFilter from './ProductMenuFilter';
import ProductChipFilterGroup from './ProductChipFilterGroup';

import ProductSortingSelect from './ProductSortingSelect';
import { updateAllSelectedFilters } from './filterUtils';
import LoadingMenuFilter from './LoadingMenuFilter';

type ProductMenuFilterGroupProps = {
  isAvailableDynamicFiltersLoading?: boolean;
  availableDynamicFilters?: DynamicFilters;
  dynamicFiltersSelectedValues?: DynamicFilters;
  onDynamicFilterSelect: (filters: DynamicFilters) => void;
  selectedSorting: SortingParams;
  onSortingSelect: (sorting: SortingParams) => void;
  selectedProductTypeGroup: StorefrontProductTypeGroup;
  /** when a product type is changed as a dynamic filter (e.g, for keyboard/mouse/combo) */
  onProductTypesSelect: (types: ProductTypeName[]) => void;
};

const ProductMenuFilterGroup = ({
  isAvailableDynamicFiltersLoading,
  availableDynamicFilters,
  dynamicFiltersSelectedValues,
  onDynamicFilterSelect,
  selectedSorting,
  onSortingSelect,
  selectedProductTypeGroup,
  onProductTypesSelect,
}: ProductMenuFilterGroupProps) => {
  const handleSelectedFilters = (value: string[], key: FilterKeyEnum) => {
    const updatedSelectedFilters = updateAllSelectedFilters(
      dynamicFiltersSelectedValues,
      { [key]: value },
      key
    );

    onDynamicFilterSelect(updatedSelectedFilters);
  };

  const sortNamePriceOptions = [
    {
      label: 'Name (A-Z)',
      value: { orderBy: 'name', sortOrder: SortOrder.Asc },
    },
    {
      label: 'Name (Z-A)',
      value: { orderBy: 'name', sortOrder: SortOrder.Desc },
    },
    {
      label: 'Price (High-Low)',
      value: { orderBy: 'price', sortOrder: SortOrder.Desc },
    },
    {
      label: 'Price (Low-High)',
      value: { orderBy: 'price', sortOrder: SortOrder.Asc },
    },
  ];

  const isKeyboardMouseGroup =
    selectedProductTypeGroup === StorefrontProductTypeGroup.KeyboardMouse;

  const handleClearAllFilters = () => {
    onDynamicFilterSelect({});

    onSortingSelect(sortNamePriceOptions[0].value);

    if (isKeyboardMouseGroup) {
      onProductTypesSelect([
        ProductTypeName.Keyboard,
        ProductTypeName.Mouse,
        ProductTypeName.KeyboardMouse,
      ]);
    }
  };

  const updateAvailableFilters = (filters?: DynamicFilters) => {
    // when the selected ProductType is related to mouse/keyboard, we add the special "TYPE" filter in the end
    if (isKeyboardMouseGroup && filters) {
      return {
        ...filters,
        [FilterKeyEnum.TYPE]: [
          ProductTypeName.Keyboard,
          ProductTypeName.Mouse,
          ProductTypeName.KeyboardMouse,
        ],
      };
    }

    return filters;
  };

  const allAvailableFilters = updateAvailableFilters(availableDynamicFilters);

  return (
    <Flex vertical rowGap="2rem" width="100%" testId="dynamic-filters">
      {isAvailableDynamicFiltersLoading && <LoadingMenuFilter />}
      {!isAvailableDynamicFiltersLoading && allAvailableFilters && (
        <Flex columnGap="2rem">
          {(Object.keys(allAvailableFilters) as FilterKeyEnum[]).map(
            filterKey => {
              const options = allAvailableFilters[filterKey];
              return (
                <ProductMenuFilter
                  key={filterKey}
                  filterKey={filterKey}
                  options={options ?? []}
                  currentFilters={dynamicFiltersSelectedValues}
                  onFilterSelect={handleSelectedFilters}
                  isKeyboardMouseGroup={isKeyboardMouseGroup}
                  onProductTypesSelect={onProductTypesSelect}
                />
              );
            }
          )}
        </Flex>
      )}
      <ProductChipFilterGroup
        currentFilters={dynamicFiltersSelectedValues}
        onFilterSelect={handleSelectedFilters}
        onClearFilters={handleClearAllFilters}
        isKeyboardMouseGroup={isKeyboardMouseGroup}
        onProductTypesSelect={onProductTypesSelect}
      />
      <Box alignSelf="flex-end">
        <ProductSortingSelect
          options={sortNamePriceOptions}
          width="18rem"
          selectedSorting={selectedSorting}
          onSortingSelect={onSortingSelect}
        />
      </Box>
    </Flex>
  );
};

export default ProductMenuFilterGroup;

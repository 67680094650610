import {
  ModalV2,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalFooterActions,
  ModalAction,
  Text,
  useBreakpoint,
  Skeleton,
  Flex,
} from '@electricjs/arc';

import { Store, Product } from '@/types/storefront';
import { Asset } from '@/types/assets';

import { ProductDescription } from './Catalog/ProductDescription';
import { ErrorState } from './ErrorState';
import AssetDescription from './Asset/AssetDescription';

type CatalogItem = {
  store: Store.Catalog;
  item?: Product;
};

type AssetItem = {
  store: Store.Asset;
  item?: Asset;
};

type ItemDetailsModalProps = (CatalogItem | AssetItem) & {
  onAddItem: () => void;
  onRemoveItem: () => void;
  onClose: () => void;
  selected: boolean;
  isLoading?: boolean;
  errorMessage?: string;
  readOnly?: boolean;
};

export const ItemDetailsModal = ({
  store,
  item,
  onAddItem,
  onRemoveItem,
  onClose,
  selected,
  isLoading,
  errorMessage,
  readOnly = false,
}: ItemDetailsModalProps) => {
  const { md } = useBreakpoint();

  if (isLoading && !errorMessage) {
    return (
      <ModalV2
        ariaLabelledby="specifications"
        visible
        hide={onClose}
        minHeight="30rem"
        minWidth={md ? '80rem' : undefined}>
        <ModalHeader>
          <Text id="specifications" variant="heading-2">
            Specifications
          </Text>
        </ModalHeader>
        <ModalBody overflowY="auto">
          {/* loading state resembling the product description */}
          <Flex
            vertical
            alignItems="center"
            width="100%"
            p="2.4rem 0"
            rowGap="2.4rem"
            data-testid="loading-item-detail">
            <Flex columnGap="3.2rem">
              <Skeleton width="25rem" height="20rem" />
              <Flex
                vertical
                rowGap="1.6rem"
                justifyContent="center"
                mt="1.6rem">
                <Skeleton width="25rem" height="4rem" />
                <Skeleton width="25rem" height="2rem" />
              </Flex>
            </Flex>
            <Flex>
              <Skeleton height="30rem" width="53.2rem" />
            </Flex>
          </Flex>
        </ModalBody>
      </ModalV2>
    );
  }

  if (!isLoading && errorMessage) {
    return (
      <ModalV2
        ariaLabelledby="specifications"
        visible
        hide={onClose}
        minHeight="30rem"
        minWidth={md ? '80rem' : undefined}>
        <ModalHeader>
          <Text id="specifications" variant="heading-2">
            Specifications
          </Text>
        </ModalHeader>
        <ModalBody overflowY="auto">
          <ErrorState title="Error" message={errorMessage} />
        </ModalBody>
      </ModalV2>
    );
  }

  return (
    <ModalV2
      ariaLabelledby="specifications"
      visible
      hide={onClose}
      minWidth={md ? '80rem' : undefined}>
      <ModalHeader>
        <Text id="specifications" variant="heading-2">
          Specifications
        </Text>
      </ModalHeader>
      <ModalBody overflowY="auto">
        {store === Store.Catalog && item && (
          <ProductDescription
            image={item.image}
            price={item.price}
            name={item.name}
            withPriceLabel
            shouldBreak={!md}
            isTextTruncated={false}
            isTextBold={false}
            additionalDescription={item.description}
          />
        )}
        {store === Store.Asset && item && (
          <AssetDescription
            customAssetId={item.customAssetId}
            name={item.name}
            brand={item.brand}
            condition={item.condition}
            model={item.model}
            serialNumber={item.serialNumber}
            notes={item.notes}
            purchaseDate={item.purchaseDate}
            purchasePrice={item.purchasePrice}
            purchasedFromUrl={item.purchasedFromUrl}
            site={item.site}
            productType={item.productType}
            fullDescription
          />
        )}
      </ModalBody>
      {!readOnly && (
        <ModalFooter>
          <ModalFooterActions justifyContent={md ? 'end' : 'center'}>
            <ModalAction
              id="modal-cancel-item-button"
              onClick={() => onClose()}
              py="1.4rem"
              px="2rem"
              testId="modal-cancel-item-button"
              variant="outline"
              border="none">
              Cancel
            </ModalAction>
            {!selected ? (
              <ModalAction
                id="modal-add-item-button"
                onClick={() => {
                  onAddItem();
                  onClose();
                }}
                py="1.4rem"
                px="2rem"
                testId="modal-add-item-button">
                Add to cart
              </ModalAction>
            ) : (
              <ModalAction
                id="modal-remove-item-button"
                onClick={() => {
                  onRemoveItem();
                  onClose();
                }}
                py="1.4rem"
                px="2rem"
                variant="outline"
                iconAfter="trashcan"
                testId="modal-remove-item-button">
                Remove
              </ModalAction>
            )}
          </ModalFooterActions>
        </ModalFooter>
      )}
    </ModalV2>
  );
};

import React from 'react';

import {
  Collapse,
  CollapseContent,
  CollapseHeader,
  ColorsV2,
  Flex,
  getToken,
  Text,
  useBreakpoint,
  useThemeColors,
} from '@electricjs/arc';
import { AssetLogTab } from '@/components/DeviceOverview/DeviceHealthMonitor/ColumnsAndContainers/AssetLogTab';
import { CenteredSpinner } from '@common';
import { EmptyState } from '@/components/DeviceOverview/DeviceHealthMonitor/EmptyState';
import { AssetHistoryWithChildren } from '@/types/assets';
import { ActivityLogRow } from '@/components/DeviceOverview/DeviceHealthMonitor/ActivityLog/ActivityLogRow';
import styled from 'styled-components';
import { TimeLine } from 'common/TimeLine/TimeLine';
import { format } from 'date-fns';
import { DeviceIssueType } from '@/types/devices';
import { AssetActionEnum } from '@electricjs/core_entity-client';
import { NeedsAttentionRow } from '@/components/DeviceOverview/DeviceHealthMonitor/NeedsAttention/NeedsAttentionRow';
import { usePaginationControl } from '@/hooks/usePaginationControl';
import { ResolvedRow } from './Resolved/ResolvedRow';

const CollapseStyled = styled(Collapse)`
  background-color: ${getToken(ColorsV2.GRAY_LIGHTER)};
  border-radius: 1.6rem;
  width: 100%;
  margin-bottom: 0;
`;

const CollapseContentStyled = styled(CollapseContent)`
  padding: 0;
  margin-bottom: 1rem;
`;

type DeviceIssuesEmptyStateSections = {
  buttonText: string;
  title: string;
  description: string;
};

export const DeviceIssuesEmptyState: {
  [key in DeviceIssueType]: DeviceIssuesEmptyStateSections;
} = {
  [DeviceIssueType.Firewall]: {
    buttonText: "Learn about Electric's Firewall policy",
    title: 'All devices have firewall.',
    description:
      'A firewall can protect your device from unwanted contact initiated by other computers when you’re connected to the internet or network. ',
  },
  [DeviceIssueType.Gatekeeper]: {
    buttonText: "Learn about Electric's Gatekeeper policy",
    title: 'All devices have Gatekeeper.',
    description:
      'Gatekeeper is designed to ensure that only trusted software runs on your Mac.',
  },
  [DeviceIssueType.OS]: {
    buttonText: "Learn about Electric's OS update policy",
    title: 'All devices have an up-to-date OS.',
    description:
      'Operating system upgrades and periodic patches introduce enhanced security and prevent against newly discovered vulnerabilities.',
  },
  [DeviceIssueType.Encryption]: {
    buttonText: "Learn about Electric's encryption policy",
    title: 'All devices have encryption.',
    description:
      'BitLocker for Windows and FileVault for Macs, both help prevent unauthorized access to the information on your startup disk by encrypting your disk.',
  },
  [DeviceIssueType.Storage]: {
    buttonText: 'Learn about disk space & troubleshooting tips',
    title: 'All devices have sufficient disk space.',
    description:
      'Disk space helps computers perform system functions, run applications, and manage memory use with optimum speed and efficiency.',
  },
  [DeviceIssueType.RAM]: {
    buttonText: 'Learn about RAM',
    title: 'All devices have RAM higher than 8GB.',
    description:
      'If an employee doesn’t have enough RAM (Random Access Memory), their device can be slow and sluggish.',
  },
  // MdmNotReporting and OsqueryNotReporting are "MDM issues" tab and have the same text
  [DeviceIssueType.MdmNotReporting]: {
    buttonText: 'Learn about MDM and the Electric app',
    title: 'All devices are connected to MDM without issues.',
    description:
      'Your team’s devices are securely connected to MDM, ensuring seamless management and updates.',
  },
  [DeviceIssueType.OsqueryNotReporting]: {
    buttonText: 'Learn about MDM and the Electric app',
    title: 'All devices are connected to MDM without issues.',
    description:
      'Your team’s devices are securely connected to MDM, ensuring seamless management and updates.',
  },
  [DeviceIssueType.DeviceLock]: {
    buttonText: 'Learn about MDM actions',
    title: 'No devices are locked or wiped.',
    description:
      'All managed devices are secure and operational. No lock or wipe actions are currently in progress.',
  },
};

const DateTimeLabel = ({ dateTime }: { dateTime: string }) => {
  const { md, xl } = useBreakpoint();

  const [textLightColor] = useThemeColors([
    ColorsV2.TEXT_LIGHT,
    ColorsV2.BACKGROUND_CONTENT,
  ]);

  const getWidth = () => {
    if (!md) return '12rem';
    if (xl) return '25rem';
    return '22rem';
  };

  return (
    <Flex
      flexDirection="column"
      alignItems="flex-end"
      width={getWidth()}
      ml="2rem"
      mt="2.8rem">
      <Text
        mr="1rem"
        ml={md ? '-0.5rem' : '-4rem'}
        py="auto"
        preventWidows={true}
        color={textLightColor}
        variant="legal">
        {format(dateTime, xl ? 'EEEE, MMMM d' : 'EEE, MMM d')}
      </Text>
      <Text
        mr="1rem"
        ml={md ? '-0.5rem' : '-4rem'}
        py="auto"
        preventWidows={true}
        color={textLightColor}
        variant="legal">
        {format(dateTime, 'hh:mm aaa')}
      </Text>
    </Flex>
  );
};

const renderAssetLogRow = (assetLog: AssetHistoryWithChildren) => {
  // If the log is resolved or ignored, render ActivityLogRow
  if (
    assetLog.children?.some(
      child =>
        child.action === AssetActionEnum.DeviceIssueResolved ||
        child.action === AssetActionEnum.DeviceIssueIgnored
    )
  ) {
    return <ResolvedRow key={assetLog.id} data={assetLog.data} />;
  }

  // If the log is not resolved or ignored, render NeedsAttentionRow
  return <NeedsAttentionRow key={assetLog.id} assetLog={assetLog} />;
};

type DeviceLogTabProps = {
  logs: AssetHistoryWithChildren[];
  isLoading?: boolean;
  selectedTab: DeviceIssueType;
};

export const DeviceLogTab = ({
  logs,
  selectedTab,
  isLoading = false,
}: DeviceLogTabProps) => {
  const logsFilteredByIssueType = logs.filter(
    log => log.data.issue_type === selectedTab
  );

  const { items, hasMore, loadNextPage } =
    usePaginationControl<AssetHistoryWithChildren>({
      list: logsFilteredByIssueType,
      pageSize: 10,
    });

  if (isLoading) {
    return (
      <AssetLogTab hideLoadMore>
        <Flex width="100%" minHeight="40rem" alignItems="center">
          <CenteredSpinner />
        </Flex>
      </AssetLogTab>
    );
  }

  if (logsFilteredByIssueType.length === 0) {
    return (
      <AssetLogTab hideLoadMore>
        <EmptyState
          title={DeviceIssuesEmptyState[selectedTab].title}
          details={[DeviceIssuesEmptyState[selectedTab].description]}
          icon="check-circle"
          selectedTab={selectedTab as DeviceIssueType}
        />
      </AssetLogTab>
    );
  }

  return (
    <AssetLogTab onLoadMore={() => loadNextPage()} hasMoreData={hasMore}>
      <>
        {items.map(assetLog =>
          assetLog.children && assetLog.children.length >= 1 ? (
            // Show the log with children in a collapse
            <CollapseStyled key={assetLog.id}>
              <CollapseHeader py="0" pl="0" pr="2.4rem">
                {renderAssetLogRow(assetLog)}
              </CollapseHeader>
              <CollapseContentStyled>
                <TimeLine>
                  {assetLog.children.map(child => (
                    <React.Fragment key={child.id}>
                      {/* Show time of the log */}
                      <DateTimeLabel dateTime={child.createdAt} />
                      <ActivityLogRow
                        assetLog={child}
                        pl="0"
                        py="0"
                        pt="2.5rem"
                      />
                    </React.Fragment>
                  ))}
                </TimeLine>
              </CollapseContentStyled>
            </CollapseStyled>
          ) : (
            // Show the log without children
            <Flex key={assetLog.id} width="100%">
              {renderAssetLogRow(assetLog)}
            </Flex>
          )
        )}
      </>
    </AssetLogTab>
  );
};

import { useAppSelector } from '@/redux/store';

/**
 * Hook to retrieve the organization id from the redux state.
 *
 * This hook will throw an error if the organization id is not available.
 */
export function useGetOrganizationId(): string {
  const organizationId =
    useAppSelector(state => state.loggedUser?.organizationId) ?? null;

  if (organizationId === null) {
    window.DD_RUM?.addError('OrganizationId unavailable', {
      location: 'useGetOrganizationId',
    });

    throw Error('Error: OrganizationId unavailable');
  }

  return organizationId as string;
}

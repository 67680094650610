import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Intercepts every route location change, checks for a new app version, and reloads if it exists.
 * **Note: This hook must be used within a `<Route>` component child.**
 */
export const useRouteReloader = () => {
  const location = useLocation();
  useEffect(() => {
    if (window._env_?.NEW_VERSION) window.location.reload();
  }, [location]);
};

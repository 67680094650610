import { useNavigate } from 'react-router-dom';

import { Box, Button, Flex, OneColumnLayout, Text } from '@electricjs/arc';

import NewAssetForm from './NewAssetForm';

const AddAssetHeader = () => {
  return (
    <Flex vertical alignItems="left" mt="3.2rem">
      <Text variant="heading-1">Add an asset</Text>
    </Flex>
  );
};

const NewAsset = () => {
  const navigate = useNavigate();

  return (
    <OneColumnLayout>
      <Box>
        <Button
          id="back-to-inventory"
          type="button"
          variant="outline"
          iconBefore="arrow-left"
          onClick={() => navigate('/assets')}>
          Back to Inventory
        </Button>
      </Box>
      <Flex vertical alignItems="left" maxWidth="80rem" width="100%">
        <AddAssetHeader />
        <NewAssetForm />
      </Flex>
    </OneColumnLayout>
  );
};

export default NewAsset;

import { useGlobalUI } from '@/components/GlobalUIProvider';
import { useAppSelector } from '@/redux/store';
import { LoggedUser } from '@/types/users';

/**
 * Hook to retrieve the logged in user from the redux state.
 *
 * This hook will throw an error if the logged in user is not available.
 */
export function useGetLoggedUser(): LoggedUser {
  const loggedUser = useAppSelector(state => state.loggedUser);

  const { showErrorToast } = useGlobalUI();

  if (loggedUser === null) {
    showErrorToast({
      id: 'logged-user-unavailable-error-toast',
      message: `There was an error, please reload the page or contact the support team.`,
    });

    throw Error('Error: LoggedUser unavailable');
  }

  return loggedUser;
}

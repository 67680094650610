import { PaginatedResponseNotification } from '@electricjs/core_entity-client';
import { UnseenNotificationsCountResponse } from '@externalTypes/general';

import { TagTypes, apiSlice } from '@/redux/slices/apiSlice';
import {
  GetNotificationsArgs,
  ListNotificationResponse,
  MarkNotificationsAsSeenArgs,
  NotificationsArgs,
} from '@/types/notifications';

export const notificationsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getUnseenNotificationsCount: builder.query<number, NotificationsArgs>({
      query: arg => {
        return {
          url: `/v1/notifications/count/unseen`,
          params: {
            organization_id: arg.organizationId,
          },
        };
      },
      transformResponse: (response: UnseenNotificationsCountResponse) => {
        return response.unseen_notifications_count;
      },
      providesTags: [TagTypes.Notification],
    }),
    getNotifications: builder.query<
      ListNotificationResponse,
      GetNotificationsArgs
    >({
      query: arg => {
        return {
          url: `/v1/notifications`,
          params: {
            organization_id: arg.organizationId,
            limit: arg.limit,
            offset: arg.offset,
            sortOrder: arg.sortOrder,
          },
        };
      },
      transformResponse: (response: PaginatedResponseNotification) => {
        const notifications =
          response.results.map(n => {
            return {
              id: n.id,
              type: n.type,
              data: { ...n.data },
              seenAt: n.seen_at,
              createdAt: n.created_at,
              organizationId: n.organization?.id,
            };
          }) ?? [];
        return { notifications, total: response.total };
      },
    }),
    markNotificationsAsSeen: builder.mutation<
      void,
      MarkNotificationsAsSeenArgs
    >({
      query: ({ notificationIds }) => {
        return {
          url: `/v1/notifications`,
          method: 'PATCH',
          body: {
            notification_ids: notificationIds,
          },
        };
      },
      invalidatesTags: [TagTypes.Notification],
    }),
  }),
});

export const {
  useGetUnseenNotificationsCountQuery,
  useLazyGetNotificationsQuery,
  useMarkNotificationsAsSeenMutation,
} = notificationsApiSlice;

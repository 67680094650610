import { DeviceIssueType, DeviceIssueTypeOld } from '@/types/devices';

type DeviceContent = {
  title: string;
  description: string;
  emailPrompt: string;
  policyDescription?: string;
};

export const DeviceOverviewContentText: {
  [key in DeviceIssueTypeOld]: DeviceContent;
} = {
  [DeviceIssueType.Firewall]: {
    title: 'Firewall',
    description:
      "A firewall can protect your device from unwanted contact initiated by other computers when you're connected to the internet or network. ",
    emailPrompt:
      'Electric can email your employee instructions on how to enable firewall on their devices',
  },
  [DeviceIssueType.Gatekeeper]: {
    title: 'Gatekeeper',
    description:
      'Gatekeeper is designed to ensure that only trusted software runs on your Mac.',
    emailPrompt:
      'Electric can email your employee instructions on how to enable Gatekeeper on their devices',
  },
  [DeviceIssueType.OS]: {
    title: 'OS update',
    description:
      'Operating system upgrades and periodic patches introduce enhanced security and prevent against newly discovered vulnerabilities.',
    emailPrompt:
      'Electric can email your employee instructions on how to update the OS on their devices.',
  },
  [DeviceIssueType.Encryption]: {
    title: 'Encryption',
    description:
      'BitLocker for Windows and FileVault for Macs: both help prevent unauthorized access to the information on your startup disk by encrypting your disk.',
    emailPrompt:
      'Electric can email your employee instructions on how to enable encryption on their devices.',
  },
  [DeviceIssueType.Storage]: {
    title: 'Storage',
    description:
      'Disk space helps computers perform system functions, run applications, and manage memory use with optimum speed and efficiency.',
    emailPrompt:
      'Electric can email your employee instructions on how to lower their disk space usage.',
  },
  [DeviceIssueType.RAM]: {
    title: 'RAM',
    description:
      'If a device doesn’t have enough RAM (Random Access Memory), it can be slow and sluggish.',
    emailPrompt: 'Electric recommends to replace these employees’ devices.',
  },
  /* TODO HY-218 We're temporarily disabling Battery health while we improve the logic behind it */
  // [DeviceIssueType.BatteryHealth]: {
  //   title: 'Battery health',
  //   description:
  //     'Poor battery health can lead to lengthy charge times, lost productivity, and potential security risks in the long run.',
  //   emailPrompt: 'Electric recommends to replace these employees’ devices.',
  // },
};

export const SecurityEnforcedDeviceOverviewContentText: {
  [key in DeviceIssueTypeOld]: DeviceContent;
} = {
  [DeviceIssueType.Firewall]: {
    title: 'Electric’s firewall policy',
    description:
      'A firewall can protect your device from unwanted contact initiated by other computers when you’re connected to the internet or network.',
    emailPrompt:
      'Electric can email your employee instructions on how to enable firewall on their devices',
    policyDescription:
      "With our firewall policy, you're able to shield devices from unwanted contacts online — and also secure company data, ensure regulatory compliance, and enhance productivity by minimizing downtime",
  },
  [DeviceIssueType.Gatekeeper]: {
    title: 'Electric’s Gatekeeper policy',
    description:
      'Gatekeeper is designed to ensure that only trusted software runs on your Mac.',
    emailPrompt:
      'Electric can email your employee instructions on how to enable Gatekeeper on their devices',
    policyDescription:
      "Electric's Gatekeeper Policy enforces strong access controls, defends against cyber threats, and minimizes data breach risks for a secure and compliant digital environment.",
  },
  [DeviceIssueType.OS]: {
    title: 'Electric’s OS update policy',
    description:
      'Operating system upgrades and periodic patches introduce enhanced security and prevent against newly discovered vulnerabilities.',
    emailPrompt:
      'Electric can email your employee instructions on how to update the OS on their devices.',
    policyDescription:
      "Electric's OS Update Policy enhances security by protecting against vulnerabilities, improves device performance, and ensures regulatory compliance.",
  },
  [DeviceIssueType.Encryption]: {
    title: 'Electric’s Encryption policy',
    description:
      'BitLocker for Windows and FileVault for Macs: both help prevent unauthorized access to the information on your startup disk by encrypting your disk.',
    emailPrompt:
      'Electric can email your employee instructions on how to enable encryption on their devices.',
    policyDescription:
      "Electric's Encryption Policy securely protects sensitive data on your devices, maintains compliance with industry regulations, and upholds the confidentiality of critical business information",
  },
  [DeviceIssueType.Storage]: {
    title: 'Storage',
    description:
      'Disk space helps computers perform system functions, run applications, and manage memory use with optimum speed and efficiency.',
    emailPrompt:
      'Electric can email your employee instructions on how to lower their disk space usage.',
  },
  [DeviceIssueType.RAM]: {
    title: 'RAM',
    description:
      'If a device doesn’t have enough RAM (Random Access Memory), it can be slow and sluggish.',
    emailPrompt: 'Electric recommends to replace these employees’ devices.',
  },
  /* TODO HY-218 We're temporarily disabling Battery health while we improve the logic behind it */
  // [DeviceIssueType.BatteryHealth]: {
  //   title: 'Battery Health',
  //   description:
  //     'Poor battery health can lead to lengthy charge times, lost productivity, and potential security risks in the long run.',
  //   emailPrompt: 'Electric recommends to replace these employees’ devices.',
  // },
};

import { Asset, isDeviceAsset } from '@/types/assets';
import { getDeviceStatus } from '@/components/People/EmployeeProfile/helpers/DevicePropertyHelpers';
import { useGetAssetHistoryQuery } from '@/redux/slices/assetApiSlice';
import { skipToken } from '@reduxjs/toolkit/query';
import { MDMStatus } from '@/constants/mdmStatus';
import { Banner, Flex } from '@electricjs/arc';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useState } from 'react';
import MDMInviteModalWrapper from '@/components/Organization/MDMInviteModal/MDMInviteModalWrapper';
import { Employee } from '@/types/employees';
import { useOrganizationHasEntitlement } from '@/hooks/useOrganizationHasEntitlement';
import { EntitlementSlugEnum } from '@electricjs/core_entity-client';
import { useCopyTextToClipboard } from '@common';

type OptionalMDMBannerProps = {
  assetData?: Asset;
  employee?: Employee;
};

/**
 * Banner to show when an employee's device does not have MDM installed.
 * If the asset is not a device asset, or the employee already has MDM installed, the banner will not be shown.
 */
const OptionalMDMBanner = ({ assetData, employee }: OptionalMDMBannerProps) => {
  const organizationId = useGetOrganizationId();
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [isDismissed, setIsDismissed] = useState(false);
  const hasMdmEntitlement = useOrganizationHasEntitlement(
    EntitlementSlugEnum.JumpcloudMdm
  );
  const { data: assetHistoryData } = useGetAssetHistoryQuery(
    hasMdmEntitlement && assetData?.id
      ? {
          organizationId: organizationId,
          assetId: assetData.id,
          limit: 10,
        }
      : skipToken
  );

  const mdmStatus =
    assetHistoryData && assetData && isDeviceAsset(assetData)
      ? getDeviceStatus({
          assetHistoryData: assetHistoryData.assetHistory,
          deviceAsset: assetData,
        })
      : null;

  const mdmInstructions =
    'You need to enroll your work device in Mobile Device Management (MDM) to keep your device secure. This should take about 5 minutes. To begin this process, install the Electric Desktop application. If you’re unsure if you installed it, you can download it here (https://downloads.electric.ai/app). Next, follow these steps to complete your MDM enrollment: https://support.electric.ai/electricithub/electric-it-hub-installing-the-desktop-app#ElectricITHub|InstallingtheDesktopApp-HowtoEnrollinMDM. If you encounter any issues with installing the Electric app or enrolling in MDM, reach out to product-support@electric.ai for assistance. Thanks for getting this done quickly!';
  const [handleCopy, confirmationOn] = useCopyTextToClipboard(mdmInstructions);

  if (!employee || isDismissed || mdmStatus !== MDMStatus.NOT_INSTALLED) {
    return null;
  }

  return (
    <Flex my="2rem" width="100%">
      <Banner
        id="unknown-employees-banner"
        intent="error"
        width="100%"
        isFlat
        title="This device does not have Mobile Device Management (MDM)"
        message="Tired of sending emails to employees about fixing their device issues? Without MDM, this device is unprotected. MDM automatically applies our secure rules. Send instructions to the device owner to install MDM now."
        cta="Send MDM instructions"
        onClickCta={() => setShowInviteModal(true)}
        ctaSecondary={confirmationOn ? 'Copied!' : 'Copy instructions'}
        onClickCtaSecondary={() => handleCopy()}
        dismissible={true}
        onClickDismiss={() => setIsDismissed(true)}
      />
      <MDMInviteModalWrapper
        employees={employee ? [employee] : []}
        inviteVisible={showInviteModal}
        previewVisible={showPreviewModal}
        setShowInviteModal={setShowInviteModal}
        setShowPreviewModal={setShowPreviewModal}
      />
    </Flex>
  );
};

export default OptionalMDMBanner;

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Flex, Text } from '@electricjs/arc';

const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const StartSetupCard = ({
  onStartSetup,
}: {
  onStartSetup: () => void;
}) => {
  return (
    <Flex
      flexDirection="row"
      p="2.5rem 3rem"
      width="100%"
      alignItems="center"
      flexWrap="wrap"
      gap="4rem">
      <Text variant="body" maxWidth="60rem">
        Mobile Device Management (MDM) enables IT administrators to secure,
        control, and enforce policies on workplace devices. With your Pro plan,
        you have full access to MDM features. View all enforceable policies
        &nbsp;
        <Link
          to="https://support.electric.ai/electricithub/electric-it-hub-jumpcloud-mdm-default-policies"
          target="_blank">
          here
        </Link>
        .
      </Text>
      <Flex marginLeft="auto" gap="2rem" minWidth="27rem">
        <StyledLink
          id="learn-more-mdm-setup-button"
          data-testid="learn-more-mdm-setup-button"
          to="https://support.electric.ai/electricithub/electric-it-hub-value-of-mdm"
          target="_blank">
          <Button id="learn-more-button" variant="outline">
            Learn more
          </Button>
        </StyledLink>
        <Button
          id="start-mdm-setup-button"
          testId="start-mdm-setup-button"
          onClick={onStartSetup}>
          Start MDM setup
        </Button>
      </Flex>
    </Flex>
  );
};

import { ReactNode, useState } from 'react';

import {
  IconButtonIntent,
  ModalAction,
  ModalBody,
  ModalFooter,
  ModalFooterActions,
  ModalHeader,
  ModalV2,
  Skeleton,
  Text,
} from '@electricjs/arc';

type ActionConfirmationModalProps = {
  title: string;
  message: ReactNode;
  cancelButtonLabel: string;
  confirmButtonLabel: string;
  confirmButtonIntent?: IconButtonIntent;
  submitDisabled?: boolean;
  submitDisabledLoading?: boolean;
  onCancel: () => void;
  onConfirm: () => Promise<void>;
};

const ActionConfirmationModal = ({
  title,
  message,
  cancelButtonLabel,
  confirmButtonLabel,
  confirmButtonIntent = 'danger',
  submitDisabled = false,
  submitDisabledLoading = false,
  onCancel,
  onConfirm,
}: ActionConfirmationModalProps) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <ModalV2
      ariaLabelledby="action-confirmation-header-text"
      hide={onCancel}
      visible>
      <ModalHeader py={1}>
        <Text id="action-confirmation-header-text" variant="heading-2">
          {title}
        </Text>
      </ModalHeader>
      <ModalBody py={24}>
        {typeof message === 'string' ? <Text>{message}</Text> : message}
      </ModalBody>
      <ModalFooter py={16}>
        <ModalFooterActions>
          <ModalAction
            id="action-modal-cancel-button"
            variant="text"
            disabled={isLoading}
            onClick={onCancel}>
            {cancelButtonLabel}
          </ModalAction>
          {submitDisabledLoading ? (
            <Skeleton width="10rem" height="4rem" />
          ) : (
            <ModalAction
              id="action-modal-confirm-button"
              intent={confirmButtonIntent}
              loading={isLoading}
              disabled={submitDisabled}
              onClick={async () => {
                setIsLoading(true);
                await onConfirm();
                setIsLoading(false);
              }}>
              {confirmButtonLabel}
            </ModalAction>
          )}
        </ModalFooterActions>
      </ModalFooter>
    </ModalV2>
  );
};

export default ActionConfirmationModal;

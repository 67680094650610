import { ModalBody, ModalHeader, ModalV2, Text } from '@electricjs/arc';

import { useOrganizationSites } from '@/hooks/useOrganizationSites';
import NewSiteForm from '@/pages/Assets/NewSiteForm';

import SitesTable from './SitesTable';

type SiteModalProps = {
  hideModal: () => void;
};

const SiteModal = ({ hideModal }: SiteModalProps) => {
  const { sites, isFetching } = useOrganizationSites();

  const privateSites = sites.filter(site => site.organizationId);

  return (
    <ModalV2
      visible
      hide={hideModal}
      ariaLabelledby="add-a-site"
      maxWidth="90rem">
      <ModalHeader p={3} pb={1}>
        <Text id="add-a-site" variant="heading-2">
          Add a site
        </Text>
      </ModalHeader>
      <ModalBody px={5} py={4}>
        <NewSiteForm />
        <SitesTable data={privateSites} isSitesLoading={isFetching} />
      </ModalBody>
    </ModalV2>
  );
};

export default SiteModal;

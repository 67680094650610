import {
  Collapse,
  CollapseHeader,
  Flex,
  Icon,
  CollapseContent,
  Text,
  useThemeColors,
  ColorsV2,
  useCollapse,
  Box,
  BoxProps,
} from '@electricjs/arc';
import { ReactNode, useEffect, useRef } from 'react';
import styled from 'styled-components';

export const StyledCollapse = styled(Collapse)<{
  borderColor: string;
  $someOptionIsChecked: boolean;
}>`
  border: ${props => (props.$someOptionIsChecked ? 'none' : 'solid')};
  border-width: ${props => (props.$someOptionIsChecked ? '0px' : '1px')};
  border-color: ${props => props.borderColor};
  border-radius: 1rem;
`;

type FilterDropdownProps = {
  someOptionIsChecked: boolean;
  dropdownContent: ReactNode;
  dropdownContentProps?: BoxProps;
  filterName: string;
  size?: 'small' | 'medium';
};

const FilterDropdown = ({
  dropdownContent,
  dropdownContentProps,
  someOptionIsChecked,
  filterName,
  size = 'medium',
}: FilterDropdownProps) => {
  const [backgroundColor, checkedBackgroundColor, checkedColor, borderColor] =
    useThemeColors([
      ColorsV2.BACKGROUND,
      ColorsV2.PRIMARY_LIGHTEST,
      ColorsV2.PRIMARY_DARK,
      ColorsV2.BORDER,
    ]);
  const { visible, setVisible } = useCollapse({
    visible: false,
  });
  const componentRef = useRef<HTMLDivElement>(null);
  const textVariant = size === 'small' ? 'body-2' : 'body';
  const textVariantChecked = size === 'small' ? 'label-small' : 'label-large';

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        !event.target ||
        (componentRef.current &&
          !componentRef.current.contains(event.target as HTMLDivElement))
      ) {
        setVisible(false);
      }
    };
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [componentRef, setVisible]);

  const headerVerticalPadding = someOptionIsChecked
    ? size === 'small'
      ? '0.7rem'
      : '0.5rem'
    : size === 'small'
      ? '0.6rem'
      : '0.8rem';

  return (
    <Box ref={componentRef} position="relative">
      <StyledCollapse
        contained
        borderColor={borderColor}
        onToggle={() => setVisible(e => !e)}
        $someOptionIsChecked={someOptionIsChecked}
        visible={visible}>
        <CollapseHeader
          {...(someOptionIsChecked && {
            backgroundColor: checkedBackgroundColor,
          })}
          minHeight="unset"
          borderRadius="1rem"
          py={headerVerticalPadding}
          borderBottom="none">
          <Flex alignItems="center">
            {someOptionIsChecked && (
              <Icon
                icon="check"
                color={checkedColor}
                customSize="1.5rem"
                mr="1rem"
              />
            )}
            <Text
              variant={textVariant}
              {...(someOptionIsChecked && { color: checkedColor })}
              {...(someOptionIsChecked && { variant: textVariantChecked })}
              {...(!someOptionIsChecked && { lineHeight: '1.8rem' })}>
              {filterName}
            </Text>
          </Flex>
        </CollapseHeader>
        <CollapseContent
          position="absolute"
          zIndex="2"
          backgroundColor={backgroundColor}
          borderRadius="1rem"
          width="20rem"
          boxShadow={`0 0 0.5rem ${borderColor}`}
          mt="1rem"
          {...dropdownContentProps}>
          {dropdownContent}
        </CollapseContent>
      </StyledCollapse>
    </Box>
  );
};

export default FilterDropdown;

import { Auth0ContextInterface } from '@auth0/auth0-react';
import { useEffect } from 'react';

type HandleAuthRedirectProps = {
  isInMaintenanceMode?: boolean;
  auth0UserLoading: boolean;
  auth0UserAuthenticated: boolean;
  loginWithRedirect: Auth0ContextInterface['loginWithRedirect'];
  auth0Error?: Error;
};

export const useHandleAuthRedirect = ({
  isInMaintenanceMode,
  auth0UserLoading,
  auth0UserAuthenticated,
  loginWithRedirect,
  auth0Error,
}: HandleAuthRedirectProps) => {
  useEffect(() => {
    if (isInMaintenanceMode) {
      return;
    }
    if (!auth0UserLoading && !auth0UserAuthenticated && !auth0Error) {
      loginWithRedirect({
        appState: {
          returnTo: `${window.location.pathname}${window.location.search}`,
        },
      });
    }
  }, [
    auth0UserLoading,
    auth0UserAuthenticated,
    loginWithRedirect,
    auth0Error,
    isInMaintenanceMode,
  ]);
};

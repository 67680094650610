import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import {
  Box,
  ColorsV2,
  Flex,
  LinearProgress,
  Text,
  useThemeColors,
  Card,
  KebabMenuOption,
  Skeleton,
  IconInCircle,
} from '@electricjs/arc';

import { useGetEmployeesWithAssets } from '@/hooks/useGetEmployeesWithAssets';
import { EmployeeWithReminderReason } from '@/pages/Hardware/DeviceOverview/types';
import { CardFooter } from './CardFooter';

const StyledCard = styled(Card)`
  padding-bottom: 1rem;
`;
// Create a styled span for showing the value of enrolled devices
const EnrolledDevicesValue = styled.span`
  font-weight: bold;
  font-size: 1.6rem;
`;

type DeviceEnrollmentStatusType = {
  employeesThatNeedAReminder: EmployeeWithReminderReason[];
  unprotectedDevicesCount: number;
  isFetchingEmployees: boolean;
  isFetchingDevices: boolean;
  secureDevicesCount: number;
};

const DeviceEnrollmentStatusWithMdm = ({
  employeesThatNeedAReminder,
  unprotectedDevicesCount,
  isFetchingEmployees,
  isFetchingDevices,
  secureDevicesCount,
}: DeviceEnrollmentStatusType) => {
  const { electrolyteDeviceManagement } = useFlags();

  const [iconBackgroundColor] = useThemeColors([ColorsV2.GRAY_LIGHTER]);

  const { isFetching: isFetchingAssets, assets: assetsData } =
    useGetEmployeesWithAssets({
      devicesOnly: true,
      availableAssetsOnly: true,
    });

  const assignedDevices = assetsData?.filter(asset => asset.assignedTo);

  const navigate = useNavigate();

  const viewDevicesLink = electrolyteDeviceManagement
    ? '/device-management'
    : '/assets?page=0&searchTerm=laptop&orderBy=name&sortOrder=asc';

  const kebabMenuItems: KebabMenuOption[] = [
    {
      label: 'What is MDM?',
      intent: 'neutral',
      onClick: () => {
        window
          .open(
            'https://support.electric.ai/electricithub/electric-it-hub-value-of-mdm',
            '_blank'
          )
          ?.focus();
      },
    },
    {
      label: 'View devices',
      intent: 'neutral',
      onClick: () => {
        navigate(viewDevicesLink);
      },
    },
  ];

  const linearProgressValue =
    (secureDevicesCount / (secureDevicesCount + unprotectedDevicesCount)) * 100;

  return (
    <StyledCard
      id="mdm-enrollment-status-card"
      elevated
      flexDirection="column"
      headerDivider
      footerDivider
      width="100%"
      minWidth="36rem"
      height="24rem"
      kebabMenuItems={kebabMenuItems}
      footer={
        <CardFooter
          mdmEnabled={true}
          employeesThatNeedAReminder={employeesThatNeedAReminder}
          isFetchingEmployees={isFetchingEmployees}
        />
      }
      title={
        <Flex flexWrap="nowrap" justifyContent="space-between" width="100%">
          <Flex flexWrap="nowrap" gap="1rem" alignItems="center">
            <IconInCircle
              size="3.6rem"
              icon="users-gear"
              iconSize="medium"
              intent="neutral"
              background={iconBackgroundColor}
            />
            <Text whiteSpace="nowrap" variant="heading-3">
              Enrollment status
            </Text>
          </Flex>
        </Flex>
      }>
      <Flex py={4} width="100%" vertical>
        {!isFetchingAssets && assignedDevices ? (
          <Text whiteSpace="nowrap" variant="body-2" width="100%" mb={2}>
            <EnrolledDevicesValue>{secureDevicesCount}</EnrolledDevicesValue>
            {`/${assignedDevices.length} assigned devices are fully enrolled.`}
          </Text>
        ) : (
          <Skeleton width="100%" height="1.5rem" />
        )}
        <Flex vertical width="100%">
          <Flex alignItems="center" justifyContent="center" width="100%" pb={2}>
            {isFetchingEmployees || isFetchingDevices ? (
              <Box p={4} minWidth="100%">
                <Skeleton width="100%" height="2rem" />
              </Box>
            ) : (
              <LinearProgress
                width="calc(100% + 4rem)"
                height="1.5rem"
                showGap={!!linearProgressValue}
                marginRight="-1rem"
                remainingIntent="danger"
                intent="success"
                value={linearProgressValue}
              />
            )}
          </Flex>
          <Flex width="100%" justifyContent="space-between" pb={2}>
            <Flex alignItems="center">
              <Box
                mr={2}
                height="8px"
                width="8px"
                borderRadius="50%"
                backgroundColor={ColorsV2.SUCCESS}></Box>
              {isFetchingDevices || isFetchingEmployees ? (
                <Skeleton width="5rem" height="1rem" />
              ) : (
                <Text variant="legal">{`${secureDevicesCount} Enrolled`}</Text>
              )}
            </Flex>
            <Flex alignItems="center">
              <Box
                mr={2}
                height="8px"
                width="8px"
                borderRadius="50%"
                backgroundColor={ColorsV2.ERROR}></Box>
              {isFetchingDevices || isFetchingEmployees ? (
                <Skeleton width="5rem" height="1rem" />
              ) : (
                <Text variant="legal">{`${unprotectedDevicesCount} Unenrolled`}</Text>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </StyledCard>
  );
};

export default DeviceEnrollmentStatusWithMdm;

import styled from 'styled-components';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalV2,
  Text,
} from '@electricjs/arc';
import { DeviceIssuesInfoModalText } from '@/components/DeviceOverview/IssueModals/InfoModalText';
import { DeviceIssueType } from '@/types/devices';

type DeviceIssuesInfoModalProps = {
  deviceIssueType: DeviceIssueType;
  hide: () => void;
  visible: boolean;
};

const Section = styled.section`
  :not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const DeviceIssuesInfoModal = ({
  deviceIssueType,
  hide,
  visible,
}: DeviceIssuesInfoModalProps) => {
  const contactProductSupportFooter = (
    <>
      <Button
        id="device-issues-info-modal-close-btn"
        onClick={hide}
        variant="text">
        Cancel
      </Button>
      <Link to="mailto:product-support@electric.ai">
        <Button id="device-issues-modal-contact-btn" onClick={hide}>
          Contact product support
        </Button>
      </Link>
    </>
  );

  const DeviceIssuesInfoModalFooter: Partial<{
    [key in DeviceIssueType]: ReactNode;
  }> = {
    [DeviceIssueType.RAM]: (
      <>
        <Button
          id="device-issues-info-modal-close-btn"
          onClick={hide}
          variant="text">
          Cancel
        </Button>
        <Link to="/purchase-hardware">
          <Button id="device-issues-modal-hardware-store-btn" onClick={hide}>
            Go to Hardware store
          </Button>
        </Link>
      </>
    ),
    [DeviceIssueType.MdmNotReporting]: contactProductSupportFooter,
    [DeviceIssueType.OsqueryNotReporting]: contactProductSupportFooter,
    [DeviceIssueType.DeviceLock]: contactProductSupportFooter,
  };

  const { title, sections } = DeviceIssuesInfoModalText[deviceIssueType];
  const footer = DeviceIssuesInfoModalFooter[deviceIssueType];

  return (
    <ModalV2 ariaLabelledby="device-issue-info" visible={visible} hide={hide}>
      <ModalHeader>
        <Text variant="heading-2">{title}</Text>
      </ModalHeader>
      <ModalBody>
        {sections.map(section => {
          return (
            <Section key={`${deviceIssueType}-${section.header}`}>
              <Text variant="label-large">{section.header}</Text>
              <Text>{section.description}</Text>
            </Section>
          );
        })}
      </ModalBody>
      <ModalFooter>
        <ButtonGroup justifyContent="flex-end">
          {footer || (
            <Button id="device-issues-info-modal-close-btn" onClick={hide}>
              Close
            </Button>
          )}
        </ButtonGroup>
      </ModalFooter>
    </ModalV2>
  );
};

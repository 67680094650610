import { MDMStatus } from '@/constants/mdmStatus';
import { DeviceIssueType } from '@/types/devices';
import { Asset, AssetHistory, DeviceAsset } from '@/types/assets';

export const PROPERTY_EMPTY_VALUE = '-- --';

export const MACOS = 'darwin';
export const WINDOWS = 'windows';

export const getOSProperty = (asset: Asset) => {
  const assetDeviceAttributes = asset.device;
  if (!assetDeviceAttributes || !assetDeviceAttributes.osType) {
    return PROPERTY_EMPTY_VALUE;
  }
  if (
    assetDeviceAttributes.osType === MACOS &&
    assetDeviceAttributes.reportingData?.osName &&
    assetDeviceAttributes.reportingData?.osVersion
  ) {
    return `${assetDeviceAttributes.reportingData?.osVersion} ${assetDeviceAttributes.reportingData?.osName}`;
  } else if (assetDeviceAttributes.reportingData?.osName) {
    return assetDeviceAttributes.reportingData?.osName;
  } else {
    return PROPERTY_EMPTY_VALUE;
  }
};

export const getDiskUsage = (asset: Asset) => {
  const assetDeviceReportingData = asset.device?.reportingData;
  if (!assetDeviceReportingData) {
    return PROPERTY_EMPTY_VALUE;
  }
  if (
    assetDeviceReportingData.storageAvailable &&
    assetDeviceReportingData.storageCapacity &&
    assetDeviceReportingData.storageUsagePercent
  ) {
    const storageInUse =
      assetDeviceReportingData.storageCapacity -
      assetDeviceReportingData.storageAvailable;
    return `${assetDeviceReportingData.storageUsagePercent}% (${Math.round(
      storageInUse
    )}/${Math.round(assetDeviceReportingData.storageCapacity)} GB)`;
  } else {
    return PROPERTY_EMPTY_VALUE;
  }
};

export const getBatteryIcon = (asset: Asset) => {
  const assetDeviceReportingData = asset.device?.reportingData;
  if (!assetDeviceReportingData) {
    return PROPERTY_EMPTY_VALUE;
  }
  if (assetDeviceReportingData.batteryHealth) {
    switch (assetDeviceReportingData.batteryHealth.toUpperCase()) {
      case 'GOOD':
        return {
          icon: 'battery-bolt',
          intent: 'success',
        };
        break;
      case 'FAIR':
        return {
          icon: 'battery-half',
          intent: 'warning',
        };
        break;
      case 'POOR':
        return {
          icon: 'battery-half',
          intent: 'error',
        };
        break;
      case 'CHECK_BATTERY':
        return {
          icon: 'battery-exclamation',
          intent: 'error',
        };
        break;
      default:
        return {
          icon: null,
          intent: null,
        };
    }
  } else {
    return {
      icon: null,
      intent: null,
    };
  }
};

export const getDeviceIssueEmailSuccessToastMessage = (
  deviceIssueType: DeviceIssueType
) => {
  switch (deviceIssueType) {
    case DeviceIssueType.Storage:
      return 'Disk space';
      break;
    case DeviceIssueType.Encryption:
      return 'Encryption';
      break;
    case DeviceIssueType.Gatekeeper:
      return 'Gatekeeper';
      break;
    case DeviceIssueType.Firewall:
      return 'Firewall';
      break;
    case DeviceIssueType.OS:
      return 'OS update';
      break;
    default:
      return PROPERTY_EMPTY_VALUE;
  }
};

export const getSingleDeviceMDMStatus = (deviceAsset: DeviceAsset) => {
  return deviceAsset.device.mdmEnrolledAt
    ? MDMStatus.INSTALLED
    : MDMStatus.NOT_INSTALLED;
};

const RELEVANT_ACTIONS: Record<string, MDMStatus> = {
  DEVICE_WIPE_REQUESTED: MDMStatus.WIPE_REQUESTED,
  DEVICE_WIPE_DONE: MDMStatus.WIPED,
};

const DEVICE_LOCK_ISSUE_TYPE = 'device_lock';

export const getAssetDeviceStatusFromAssetData = (
  assetHistoryData: AssetHistory[]
): MDMStatus | null => {
  // Find the first relevant action in the asset history
  const lastRelevantAction = assetHistoryData.find(
    historyItem =>
      !!RELEVANT_ACTIONS[historyItem.action] ||
      historyItem?.data?.issue_type === DEVICE_LOCK_ISSUE_TYPE
  );
  // If there is no relevant action we exit early
  if (!lastRelevantAction) {
    return null;
  }
  // Check to see if the action is device_lock
  if (lastRelevantAction?.data?.issue_type === DEVICE_LOCK_ISSUE_TYPE) {
    // If the action is not resolved we can assume the device is still locked
    return lastRelevantAction.action !== 'DEVICE_ISSUE_RESOLVED'
      ? MDMStatus.LOCKED
      : null;
  }
  // Return the relevant action
  return RELEVANT_ACTIONS[lastRelevantAction.action];
};

type GetDeviceStatusArgs = {
  assetHistoryData: AssetHistory[];
  deviceAsset: DeviceAsset;
};
export const getDeviceStatus = ({
  assetHistoryData,
  deviceAsset,
}: GetDeviceStatusArgs): MDMStatus => {
  const deviceStatusFromAssetData =
    getAssetDeviceStatusFromAssetData(assetHistoryData);

  // If the device is wiped and the MDM status is INSTALLED, it means that the device was wiped and then enrolled
  // in MDM again, so we should show the MDM status tag instead of the wiped status one.
  if (
    deviceStatusFromAssetData === MDMStatus.WIPED &&
    getSingleDeviceMDMStatus(deviceAsset) === MDMStatus.INSTALLED
  ) {
    return getSingleDeviceMDMStatus(deviceAsset);
  }

  return deviceStatusFromAssetData || getSingleDeviceMDMStatus(deviceAsset);
};

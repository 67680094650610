import { rest } from 'msw';

/** This is used for Jest tests */
export const localhostHandlers = [
  // src/helpers/fetchAppVersion.spec.ts
  rest.get(`*/fetchAppVersion`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.text(req.url.searchParams.get('version') ?? '')
    );
  }),
];

import { Flex, Icon, Text } from '@electricjs/arc';

import { DeviceIssueType, Issue } from '@/types/devices';

type EmployeeDeviceStatusProps = {
  issues?: Issue;
};

const EmployeeDeviceStatus = ({ issues }: EmployeeDeviceStatusProps) => {
  const WarningMessage = {
    [DeviceIssueType.Gatekeeper]: 'Needs Gatekeeper',
    [DeviceIssueType.Firewall]: 'Needs firewall',
    [DeviceIssueType.Encryption]: 'Needs encryption',
    [DeviceIssueType.Storage]: 'Low disk space',
    [DeviceIssueType.OS]: 'Needs to update OS',
    [DeviceIssueType.RAM]: 'Low RAM',
    /* TODO HY-218 We're temporarily disabling Battery health while we improve the logic behind it */
    // [DeviceIssueType.BatteryHealth]: 'Poor battery health',
    [DeviceIssueType.OsqueryNotReporting]: 'Osquery not reporting',
    [DeviceIssueType.MdmNotReporting]: 'Mdm not reporting',
    [DeviceIssueType.DeviceLock]: 'Device locked',
  };

  // get individual issues from the object (they are the keys of the object)
  const individualIssues = issues
    ? (Object.keys(issues) as DeviceIssueType[])
    : [];

  return (
    <Flex width="fit-content" alignItems="center" columnGap="0.8rem">
      {individualIssues.length === 0 && (
        <>
          <Icon icon="check-circle" intent="success" size="medium" />
          <Text variant="label-small">No device issues</Text>
        </>
      )}
      {individualIssues.length === 1 && (
        <>
          <Icon icon="alert-triangle" intent="warning" size="medium" />
          <Text variant="label-small">
            {WarningMessage[individualIssues[0]] ?? 'Unknown issue'}
          </Text>
        </>
      )}
      {individualIssues.length > 1 && (
        <>
          <Icon icon="alert-triangle" intent="warning" size="medium" />
          <Text variant="label-small">Multiple device issues</Text>
        </>
      )}
    </Flex>
  );
};

export default EmployeeDeviceStatus;

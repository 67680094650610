import { Table, TableContainer, Text } from '@electricjs/arc';

import { SiteWithAddress } from '@/types/assets';
import { createColumnHelper } from '@tanstack/react-table';
import TableCellFlexContainer from 'common/Table/TableCellFlexContainer';
import { formatPhoneNumber } from 'common/utils/formatPhoneNumber';

type SitesTableProps = {
  data: SiteWithAddress[];
  isSitesLoading: boolean;
};

const columnHelper = createColumnHelper<SiteWithAddress>();

const SitesTable = ({ data, isSitesLoading }: SitesTableProps) => {
  const tableColumns = [
    columnHelper.accessor('name', {
      header: () => <Text variant="label-small">Name</Text>,
      id: 'name',
      meta: {
        minWidth: 30,
      },
      cell: ({ getValue }) => {
        return (
          <TableCellFlexContainer>
            <Text variant="body-2">{getValue()}</Text>
          </TableCellFlexContainer>
        );
      },
    }),
    columnHelper.accessor('address', {
      header: () => <Text variant="label-small">Address</Text>,
      id: 'address',
      meta: {
        minWidth: 80,
        flex: '2',
      },
      cell: ({ getValue }) => {
        const address = getValue();
        const addressText = `${address.streetAddress1} ${address.streetAddress2}, ${address.city}, ${address.state}, ${address.zip}, ${address.country}`;
        return (
          <TableCellFlexContainer>
            <Text variant="body-2">{addressText}</Text>
          </TableCellFlexContainer>
        );
      },
    }),
    columnHelper.accessor('phone', {
      header: () => <Text variant="label-small">Phone</Text>,
      id: 'phone',
      meta: {
        minWidth: 20,
        flex: '1.5',
      },
      cell: ({ getValue }) => {
        const formattedPhone = formatPhoneNumber(getValue());
        return (
          <TableCellFlexContainer>
            <Text variant="body-2">{formattedPhone}</Text>
          </TableCellFlexContainer>
        );
      },
    }),
  ];

  return (
    <TableContainer
      vertical
      hAlignContent="center"
      width="100%"
      alignSelf="flex-start"
      flexDirection="column"
      maxHeight="30rem">
      <Table
        id="sites-table"
        columns={tableColumns}
        data={data}
        loading={isSitesLoading}
        emptyStateText="You haven't set up any sites!"
      />
    </TableContainer>
  );
};

export default SitesTable;

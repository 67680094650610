import { useAddEmployeesToGroupMutation } from '@/redux/slices/groupApiSlice';
import { useState } from 'react';
import { useGetOrganizationId } from './useGetOrganizationId';
import { Group } from '@/types/groups';

const useAddEmployeeToGroups = () => {
  const [addEmployeesToGroup] = useAddEmployeesToGroupMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const organizationId = useGetOrganizationId();

  const addEmployeeToGroups = async (employeeId: string, groups: Group[]) => {
    setIsLoading(true);
    setErrors([]);

    if (!employeeId) {
      setIsLoading(false);
      return Promise.reject('No employee selected');
    }

    if (groups.length === 0) {
      setIsLoading(false);
      return Promise.reject('No groups selected');
    }

    const promises = groups.map(({ id: groupId }) =>
      addEmployeesToGroup({
        organizationId,
        groupId,
        employeeIds: [employeeId],
      }).then(data => ({ groupId, ...data }))
    );

    const results = await Promise.all(promises);
    const errors = results
      .filter(result => 'error' in result)
      .map(result => result.groupId);
    const addedGroups = results
      .filter(result => 'data' in result)
      .map(result => result.groupId);

    setErrors(errors);
    setIsLoading(false);
    return new Promise<string[]>((resolve, reject) => {
      if (!addedGroups.length) {
        reject(errors);
        return;
      }
      resolve(addedGroups);
    });
  };

  return { addEmployeeToGroups, isLoading, errors };
};

export default useAddEmployeeToGroups;

import { Flex, Link, Spacer, Text } from '@electricjs/arc';
import { OSType } from '@electricjs/core_entity-client';

import { NoWidowText } from '@/components/Assets/AssetDetails/common';
import { useGlobalUI } from '@/components/GlobalUIProvider';
import {
  encryptionLinks,
  firewallLinks,
  gatekeeperLinks,
  osLinks,
  storageLinks,
} from '@/constants/deviceIssueLinks';
import { AssetDeviceProperty, AssetIssue } from '@/types/assets';
import { DeviceIssueType } from '@/types/devices';
import { Employee } from '@/types/employees';
import { getFullName } from 'common/utils/getFullName';
import EmailModal from './EmailModal';

type DeviceIssueData = {
  [key in keyof AssetIssue]: {
    titleIssueType: string;
    descriptionAction: string;
    windowsInstructionsLabel: string | null;
    macInstructionsLabel: string;
    windowsLink: string | null;
    macLink: string;
    latestMacUpdate?: string;
    latestWindowsUpdate?: string;
  };
};

const DEVICE_ISSUE_MODAL_DATA: DeviceIssueData = {
  [DeviceIssueType.OS]: {
    titleIssueType: 'OS update',
    descriptionAction: 'update their OS',
    windowsInstructionsLabel: 'Windows instructions',
    macInstructionsLabel: 'MacOS instructions',
    windowsLink: osLinks.windows,
    macLink: osLinks.mac,
    latestMacUpdate: 'macOS Ventura 13.3.1',
    latestWindowsUpdate: 'Windows 10 version 22H2',
  },
  [DeviceIssueType.Encryption]: {
    titleIssueType: 'encryption',
    descriptionAction: 'encrypt their data',
    windowsInstructionsLabel:
      'Encryption instructions for Windows devices (BitLocker)',
    macInstructionsLabel: 'Encryption instructions for Mac devices (Filevault)',
    windowsLink: encryptionLinks.windows,
    macLink: encryptionLinks.mac,
  },
  [DeviceIssueType.Firewall]: {
    titleIssueType: 'firewall',
    descriptionAction: 'turn on firewall',
    windowsInstructionsLabel: 'Firewall instructions for Windows devices',
    macInstructionsLabel: 'Firewall instructions for Mac devices',
    windowsLink: firewallLinks.windows,
    macLink: firewallLinks.mac,
  },
  [DeviceIssueType.Gatekeeper]: {
    titleIssueType: 'Gatekeeper',
    descriptionAction: 'enable Gatekeeper',
    windowsInstructionsLabel: null,
    macInstructionsLabel: 'Gatekeeper instructions',
    windowsLink: null,
    macLink: gatekeeperLinks.mac,
  },
  [DeviceIssueType.Storage]: {
    titleIssueType: 'disk space',
    descriptionAction: 'free up storage on their devices',
    windowsInstructionsLabel: 'Disk space instructions for Windows devices',
    macInstructionsLabel: 'Disk space instructions for Mac devices',
    windowsLink: storageLinks.windows,
    macLink: storageLinks.mac,
  },
};

type IssueModalProps = {
  issueType: keyof AssetIssue;
  employee: Employee;
  device: AssetDeviceProperty;
};

const IssueModal = ({ issueType, employee, device }: IssueModalProps) => {
  const { closeModal } = useGlobalUI();

  const issueModalData = DEVICE_ISSUE_MODAL_DATA[issueType];

  if (!issueModalData) {
    return null;
  }

  const employeeFullname = getFullName(
    employee.firstName,
    employee.lastName,
    'the employee'
  );

  return (
    <EmailModal
      hide={closeModal}
      issueType={issueType}
      employeeId={employee.id}
      employeeFullname={employeeFullname}
      device={device}
      title={`Send employee ${issueModalData.titleIssueType} instructions`}>
      <NoWidowText>{`We’ll send instructions to ${employeeFullname} on how to ${issueModalData.descriptionAction}.`}</NoWidowText>
      <>
        {device.osType !== OSType.Darwin &&
          issueModalData.windowsLink &&
          issueModalData.windowsInstructionsLabel && (
            <>
              <Spacer y={3} />
              <Text>
                <Link
                  target="_blank"
                  rel="noopener"
                  href={issueModalData.windowsLink}>
                  {issueModalData.windowsInstructionsLabel}
                </Link>
              </Text>
              {!!issueModalData.latestWindowsUpdate && (
                <>
                  <Spacer y={3} />
                  <Flex gap={2}>
                    <Text tag="span" fontWeight="bold">
                      Latest Windows update:
                    </Text>
                    <Text>{issueModalData.latestWindowsUpdate}</Text>
                  </Flex>
                </>
              )}
            </>
          )}
        {device.osType !== OSType.Windows && (
          <>
            <Spacer y={3} />
            <Text>
              <Link
                target="_blank"
                rel="noopener"
                href={issueModalData.macLink}>
                {issueModalData.macInstructionsLabel}
              </Link>
            </Text>
            {!!issueModalData.latestMacUpdate && (
              <>
                <Spacer y={3} />
                <Flex gap={2}>
                  <Text tag="span" fontWeight="bold">
                    Latest macOS update:
                  </Text>
                  <Text>{issueModalData.latestMacUpdate}</Text>
                </Flex>
              </>
            )}
          </>
        )}
      </>
    </EmailModal>
  );
};

export default IssueModal;

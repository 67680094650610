import { useEffect, useState } from 'react';
import {
  APIProvider,
  Map as GMap,
  Marker,
  useApiLoadingStatus,
  APILoadingStatus,
} from '@vis.gl/react-google-maps';

const GOOGLE_MAPS_API_KEY = window._env_?.VITE_GOOGLE_MAPS_PUB_API_KEY;

type MapProps = {
  address: string;
  width?: string;
  height?: string;
  defaultZoom?: number;
  handleMapError?: (_: boolean) => void;
  children?: React.ReactNode;
};

const Map = (mapProps: MapProps) => {
  return (
    <APIProvider apiKey={GOOGLE_MAPS_API_KEY}>
      <MapContent {...mapProps} />
    </APIProvider>
  );
};

const MapContent = ({
  address,
  width = '30rem',
  height = '20rem',
  defaultZoom = 10,
  handleMapError = _ => {},
  children,
}: MapProps) => {
  const [result, setResult] = useState<google.maps.GeocoderResult>();
  const geocodingApiLoading = useApiLoadingStatus();
  const [geocodingService, setGeocodingService] =
    useState<google.maps.Geocoder>();

  useEffect(() => {
    if (
      geocodingApiLoading === APILoadingStatus.FAILED ||
      geocodingApiLoading === APILoadingStatus.AUTH_FAILURE
    ) {
      handleMapError(true);
    }

    if (geocodingApiLoading === APILoadingStatus.LOADED) {
      setGeocodingService(new window.google.maps.Geocoder());
      handleMapError(false);
    }
  }, [geocodingApiLoading, handleMapError]);

  useEffect(() => {
    if (!geocodingService || !address) return;

    geocodingService.geocode({ address }, (results, status) => {
      if (results?.length && status === 'OK') {
        setResult(results[0]);
      }
    });
  }, [address, geocodingService, handleMapError]);

  if (!result) {
    return null;
  }

  const lat = result?.geometry.location.lat();
  const lng = result?.geometry.location.lng();

  return (
    <>
      <GMap
        style={{ width, height }}
        defaultCenter={{ lat, lng }}
        defaultZoom={defaultZoom}
        gestureHandling="auto"
        disableDefaultUI>
        <Marker position={{ lat, lng }} />
      </GMap>
      {children}
    </>
  );
};

export default Map;

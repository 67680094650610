import Papa from 'papaparse';

export const exportCSV = (
  csvData: Array<Record<string, string | undefined>>,
  fileName: string
) => {
  const csvFile = new Blob([Papa.unparse(csvData)], { type: 'text/csv' });
  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(csvFile);
  downloadLink.download = fileName;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

import {
  AssetCondition,
  AssetStatus,
  AssetUpdateRequest,
  AssetActionEnum,
  ProductTypeName,
  OSType,
  LockStatus,
  FilevaultStatus,
  BitlockerStatus,
  BatteryHealthStatus,
  AppsEnum,
} from '@electricjs/core_entity-client';

import { type Address } from '@electricjs/arc';

import {
  AssetConditionOption,
  AssetStatusOption,
  SiteOption,
  ProductTypeOption,
} from '@/components/Assets/helpers/transformOptions';

import { PaginationParams } from './common';
import { ProductType } from './products';
import { DeviceIssueType } from './devices';

export type AssetBase = {
  id: string;
  name: string;
  productType: ProductType;
};

// The camelCase version of the Asset from Core Entity
export type Asset = AssetBase & {
  customAssetId: string;
  purchaseDate?: string;
  purchasePrice?: number;
  purchasedFromUrl: string;
  brand: string;
  model: string;
  serialNumber: string;
  notes: string;
  internalNotes: string;
  site: Site | null;
  status: AssetStatus;
  condition?: AssetCondition;
  imageUrl: string;
  assignedTo?: string;
  assignedToEmployee?: AssetAssignmentEmployee;
  device: AssetDeviceProperty | null;
  warrantyExpirationDate?: string;
  locationId: string | null;
  createdAt: string;
  updatedAt: string;
};

export type AssetWithEnrollmentStatus = Asset & {
  wasElectricAppInstalled?: boolean;
  wasMDMInstalled?: boolean;
};

// The camelCase version of the AssetDevice from Core Entity
// This represents the device property of an asset
export type AssetDeviceProperty = {
  id: string;
  osType: OSType;
  hardwareModel: string;
  lockStatus: LockStatus;
  lockPin?: string;
  mdmEnrolledAt?: string;
  reportingData?: AssetDeviceReportingData;
  createdAt: string;
  updatedAt: string;
};

export type Issue = {
  category?: string;
  actual?: boolean | number | string;
  expected?: boolean | number | string;
  extra?: {
    disabled?: boolean;
    reason?: string;
    [key: string]: unknown;
  };
  [key: string]: unknown;
};

// The camelCase version of the AssetDeviceReportingData from Core Entity.
// This represents the data we get from device-reporting when we fetch an asset.
export type AssetDeviceReportingData = {
  hostname?: string;
  osVersion?: string;
  osName?: string;
  ramCapacity?: number;
  storageCapacity?: number;
  storageAvailable?: number;
  storageUsagePercent?: number;
  filevault?: boolean;
  filevaultStatus?: FilevaultStatus;
  filevaultEncryptionPercent?: number;
  bitlocker?: boolean;
  bitlockerStatus?: BitlockerStatus;
  bitlockerEncryptionPercent?: number;
  encryption?: boolean;
  encryptionPercent?: number;
  batteryHealth?: BatteryHealthStatus;
  firewall?: boolean;
  gatekeeper?: boolean;
  issues?: { [key: string]: Issue };
  installedApps?: Array<AppsEnum>;
  editionId?: string;
  lastOsqueryReport?: string;
  storageInUse?: number;
  hasOsqueryIssue?: boolean;
  lastOsqueryEnrollment?: string;
  hasMdmIssue?: boolean;
};

export type AssetIssue = {
  [DeviceIssueType.Encryption]?: AssetIssueDetails;
  [DeviceIssueType.Firewall]?: AssetIssueDetails;
  [DeviceIssueType.Gatekeeper]?: AssetIssueDetails;
  [DeviceIssueType.OS]?: AssetIssueDetails;
  [DeviceIssueType.Storage]?: AssetIssueDetails;
};

export type AssetIssueExtra = {
  eolDate: string;
  link: string;
};

export type AssetIssueDetails = {
  category: string;
  actual: string;
  expected: string;
  extra?: AssetIssueExtra | null;
};

// Typeguard for checking if an Asset is a DeviceAsset
export const isDeviceAsset = (asset: Asset): asset is DeviceAsset => {
  return !!asset.device;
};

export const isDeviceAssetProductType = (asset: Asset): boolean => {
  return (
    asset.productType.name === ProductTypeName.Laptop ||
    asset.productType.name === ProductTypeName.Desktop
  );
};

// This represents an Asset that is a device as indicated by the presence of the device property.
export type DeviceAsset = Asset & { device: NonNullable<Asset['device']> };

export type AssetAssignmentEmployee = {
  id: string;
  firstName: string;
  lastName: string;
};

export type AssetLogDeviceIssue = {
  employee_id?: string;
  issue_type?: DeviceIssueType;
  device_id?: string;
};

export type AssetHistory<T = Record<string, unknown>> = {
  id: string;
  assetId: string;
  action: string;
  data: T;
  description: string;
  performedBy: string | undefined;
  performedByUser: string | undefined;
  performedAt: string;
  parentAssetLogId: string | undefined;
  createdAt: string;
  updatedAt: string | undefined;
};

export type AssetHistoryWithChildren<T = Record<string, unknown>> =
  AssetHistory<T> & {
    children?: AssetHistory<T>[];
  };

export type AssetListArgs = {
  organizationId: string;
  orderBy: string;
  sortOrder: string;
  searchTerm?: string;
  statuses?: AssetStatus[];
  productTypes?: ProductTypeName[];
} & PaginationParams;

export type AssetListResponse = {
  total: number;
  assets: Asset[];
};

export type AssetHistoryArgs = {
  organizationId: string;
  assetId: string;
} & PaginationParams;

type OrganizationAssetHistoryArgsParams = {
  organizationId: string;
  assetActions?: AssetActionEnum[];
  ignoreStatuses?: AssetStatus[];
  days?: number;
  ignoreDaysForUnresolvedIssues?: boolean;
};

export type OrganizationAssetHistoryArgs = OrganizationAssetHistoryArgsParams &
  PaginationParams;

export type AssetHistoryResponse = {
  total: number;
  assetHistory: AssetHistory[];
};

export type AssetHistoryWithChildrenResponse = {
  total: number;
  assetHistory: AssetHistoryWithChildren<AssetLogDeviceIssue>[];
};

export type AssetCreationArgs = {
  name: string;
  productTypeId: string;
  customAssetId: string | undefined;
  assignedTo: string | undefined;
  purchaseDate: Date | undefined;
  purchasePrice: number | undefined;
  purchasedFromUrl: string | undefined;
  brand: string | undefined;
  model: string | undefined;
  serialNumber: string | undefined;
  notes: string | undefined;
  internalNotes: string | undefined;
  siteId: string | undefined;
  locationId?: string | undefined;
  status: AssetStatus | undefined;
  condition: AssetCondition | undefined;
  imageUrl: string | undefined;
  warrantyExpirationDate: Date | undefined;
};

export type AssetsCreationArgs = {
  organizationId: string;
  assets: AssetCreationArgs[];
};

export type AssetUpdateRequestDto = Omit<
  AssetUpdateRequest,
  'assigned_to' | 'site_id' | 'purchase_price' | 'warranty_expiration_date'
> & {
  assigned_to: AssetUpdateRequest['assigned_to'] | null;
  site_id: AssetUpdateRequest['site_id'] | null;
  purchase_price: AssetUpdateRequest['purchase_price'] | null;
  warranty_expiration_date:
    | AssetUpdateRequest['warranty_expiration_date']
    | null;
};

export type AssetUpdateArgs = Partial<
  Omit<AssetCreationArgs, 'assignedTo' | 'siteId' | 'purchasePrice'>
> & {
  organizationId: string;
  assetId: string;
  assignedTo?: AssetCreationArgs['assignedTo'] | null;
  siteId?: AssetCreationArgs['siteId'] | null;
  purchasePrice?: AssetCreationArgs['purchasePrice'] | null;
  warrantyExpirationDate?: AssetCreationArgs['warrantyExpirationDate'] | null;
};

export type Site = {
  id: string;
  name: string;
  phone?: string;
  address?: Address;
  organizationId: string | null;
  createdAt?: string;
};

export type SiteWithAddress = Site & {
  address: Address;
};

export type SiteCreationArgs = {
  name: string;
  phone?: string;
  address: Address;
  organizationId: string;
};

export type SiteListArgs = {
  organizationId?: string;
} & PaginationParams;

export type CSVImportAsset = {
  name: string;
  assetID: string;
  productType: ProductTypeOption;
  assignedTo: { name: string; value: string; label: string } | undefined;
  purchaseDate: string | undefined;
  purchasePrice: number | undefined;
  purchasedFrom: string | undefined;
  brand: string | undefined;
  model: string | undefined;
  serialNumber: string | undefined;
  notes: string | undefined;
  site: SiteOption | undefined;
  status: AssetStatusOption | undefined;
  condition: AssetConditionOption | undefined;
  warrantyExpirationDate: string | undefined;
};

export type IgnoreAssetLogArgs = {
  organizationId: string;
  assetLogId: string;
};

export type Location = {
  id: string;
  name: string;
  siteId: string;
};

export type LocationCreationArgs = {
  siteId: string;
  name: string;
};

export type AddFileToAssetArgs = {
  organizationId: string;
  assetId: string;
  formData: FormData;
};

export type AssetFileWithURL = {
  fileId: string;
  url: string;
  fileName: string;
  assetId: string;
  fileSizeBytes: number;
  fileType: string;
  uploadStatus: string;
  createdAt?: string;
  updatedAt?: string;
};

export type WarehouseMetrics = {
  assetCounts: AssetCountTableRow[];
};

export type AssetCountTableRow = {
  month: string;
  count: number;
};

import { useBreakpoint } from '@electricjs/arc';

/** Hook to check when screen should change its layout to be responsive */
export const useResponsiveness = () => {
  const { xl, lg, md } = useBreakpoint();

  // HD Resolution (not mobile or full HD)
  const isInHD = !((xl && lg) || (!xl && !lg));

  // Checks whether the screen should change its layout when there is no horizontal space available
  const hasNoHorizontalSpace = isInHD || !md;

  return {
    isInHD,
    hasNoHorizontalSpace,
  };
};

export default useResponsiveness;

import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

import {
  AddressGroupField,
  Button,
  Flex,
  DatePickerField,
  TextInputField,
  SelectField,
  type Address,
  optionalAddressValidationSchema,
  Header,
  OneColumnLayout,
} from '@electricjs/arc';

import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useGlobalUI } from '@/components/GlobalUIProvider';
import { FormActionsContainer } from '@/components/Onboardings/common';
import {
  useListHrisProvidersQuery,
  useDemoSyncHRISEmployeeMutation,
} from '@/redux/slices/hrisIntegrationApiSlice';
import { useNavigate } from 'react-router-dom';
const GOOGLE_MAPS_API_KEY = window._env_?.VITE_GOOGLE_MAPS_PUB_API_KEY;
import * as yup from 'yup';
import { HRISEmployeeStatus } from '@electricjs/core_entity-client/dist/models/hrisemployee-status';
import {
  transformHrisProvidersToOptions,
  HrisProviderOption,
} from '@/components/HRISIntegrations/transformOptions';

export type FormData = {
  hrisProvider: HrisProviderOption | null;
  firstName: string;
  lastName: string;
  personalEmail?: string | null;
  address?: Address | null;
  startDate?: Date | null;
  jobTitle?: string | null;
  workEmail?: string | null;
};

const formSchema = yup.object().shape({
  hrisProvider: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required(),
      name: yup.string().required(),
      label: yup.string().required(),
    })
    .required('HRIS Provider is required'),
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  personalEmail: yup.string().email('Invalid email').nullable(),
  address: optionalAddressValidationSchema,
  startDate: yup.date().nullable(),
  jobTitle: yup.string().nullable(),
  workEmail: yup.string().email('Invalid email').nullable(),
});

const NewHRISOnboarding = () => {
  const navigate = useNavigate();
  const organizationId = useGetOrganizationId();
  const { data: providers, isLoading } = useListHrisProvidersQuery();
  const providerOptions = transformHrisProvidersToOptions(providers);

  const [demoSyncHRISEmployee] = useDemoSyncHRISEmployeeMutation();

  const { showSuccessToast, showErrorToast } = useGlobalUI();

  const defaultValues: FormData = {
    hrisProvider: null,
    firstName: '',
    lastName: '',
    personalEmail: null,
    address: {
      streetAddress1: '',
      streetAddress2: '',
      city: '',
      state: '',
      country: '',
      zip: '',
    },
    startDate: null,
    jobTitle: null,
    workEmail: null,
  };

  const methods = useForm<FormData>({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(formSchema),
  });
  const {
    handleSubmit,
    setValue,
    control,
    formState: { isValid },
  } = methods;

  const handleErrorState = (logError?: string) => {
    showErrorToast({
      id: 'create-onboard-request-error-toast',
      message:
        'There was a problem creating the onboard request. Please try again.',
      stack: true,
    });

    if (logError) {
      window?.DD_RUM?.addError(logError, {
        location: 'Create New Onboard Request',
      });

      console.error(logError);
    }
  };

  const generateSourceId = () => {
    const timestamp = Date.now();
    const randomNum = Math.floor(Math.random() * 1000); // Generate a random number between 0 and 999
    return `hris-demo-${organizationId}-${timestamp}-${randomNum}`;
  };

  const submitOnboarding = async (data: FormData) => {
    try {
      const employeeData = {
        sourceId: generateSourceId(), // Generate a random source ID for demo purposes
        firstName: data.firstName,
        lastName: data.lastName,
        personalEmail: data.personalEmail,
        address: data.address,
        startDate: data.startDate?.toISOString(),
        jobTitle: data.jobTitle,
        workEmail: data.workEmail,
        status: HRISEmployeeStatus.Active,
      };

      const hrisProvider = data.hrisProvider as HrisProviderOption;

      await demoSyncHRISEmployee({
        organizationId,
        hrisProviderSlug: hrisProvider.value,
        hrisEmployee: employeeData,
        isOnboarding: true,
      }).unwrap();

      showSuccessToast({
        id: 'create-onboard-request-success-toast',
        message: `The onboard request was created successfully.`,
        stack: true,
      });

      navigate(`/employees`);
    } catch (error) {
      handleErrorState(
        `Unable to submit onboard request for HRIS employee:  ${JSON.stringify(error)}`
      );
    }
  };

  return (
    <OneColumnLayout>
      <Flex maxWidth="72rem" width="100%" margin="0 auto" vertical>
        <FormProvider {...methods}>
          <Header title="We’ll need some information" />
          <Flex width="100%" vertical>
            <SelectField
              id="hris-providers-search-selector"
              name="hrisProvider"
              control={control}
              label="HRIS Provider"
              placeholder={'Search provider'}
              required
              isSearchable
              isClearable
              getOptionValue={provider => provider.id}
              getOptionLabel={provider => provider.name}
              options={providerOptions}
              isLoading={isLoading}
            />
            <TextInputField
              id="firstName"
              name="firstName"
              control={control}
              label="First Name"
              required
            />
            <TextInputField
              id="lastName"
              name="lastName"
              control={control}
              label="Last Name"
              required
            />
            <TextInputField
              id="personalEmail"
              name="personalEmail"
              control={control}
              label="Personal Email"
            />
          </Flex>
          <Flex width="100%" pt="3.2rem" vertical>
            <Flex width="100%" maxWidth="72rem" vertical>
              <AddressGroupField
                name="address"
                control={control}
                onChange={address =>
                  setValue(
                    'address',
                    {
                      ...address,
                      streetAddress2: address.streetAddress2 ?? '',
                    },
                    {
                      shouldValidate: true,
                    }
                  )
                }
                googleMapsApiKey={GOOGLE_MAPS_API_KEY}
                columnGap={'1rem'}
              />
            </Flex>
          </Flex>
          <Flex width="100%" vertical>
            <DatePickerField
              id="startDate"
              name="startDate"
              control={control}
              label="Start Date"
              placeholder=""
              minDate=""
            />
            <TextInputField
              id="jobTitle"
              name="jobTitle"
              control={control}
              label="Title"
            />
            <TextInputField
              id="workEmail"
              name="workEmail"
              control={control}
              label="Work Email"
            />
          </Flex>
          <FormActionsContainer>
            <Button
              id="hris-onboard-request-cancel-button"
              variant="outline"
              onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <Button
              id="hris_onboard-request-next-button"
              onClick={handleSubmit(submitOnboarding)}
              disabled={!isValid}>
              Submit
            </Button>
          </FormActionsContainer>
        </FormProvider>
      </Flex>
    </OneColumnLayout>
  );
};

export default NewHRISOnboarding;

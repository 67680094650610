import { Flex, Icon, Text } from '@electricjs/arc';

export type InlineErrorProps = {
  message?: string;
};

const InlineError = ({ message = 'Error' }: InlineErrorProps) => (
  <Flex alignItems="center" columnGap="0.2rem">
    <Icon size="medium" icon="alert-circle" intent="error" />
    <Text intent="error">{message}</Text>
  </Flex>
);

export default InlineError;

import {
  Box,
  Button,
  ColorsV2,
  Flex,
  Header,
  Tooltip,
  getToken,
} from '@electricjs/arc';
import { FormActionsContainer, TooltipReference } from './common';
import { useFormContext, useWatch } from 'react-hook-form';
import { OnboardingFormData } from './types';
import OnboardEmployeeModalApps from './OnboardEmployeeModalApps';
import EmployeeGroupSelector from '../People/EmployeeProfile/EmployeeGroupSelectorField';
import { useGetOrganizationGroupsQuery } from '@/redux/slices/organizationApiSlice';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import styled from 'styled-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Group } from '@/types/groups';
import { SelectionParamKeys } from '@/types/queryParamKeys';
import { RequestTypeCategoryNameEnum } from '@/hooks/useGetRequestTypeCategoryChampionEmployee';
import useIsRequestTypeCategoryChampion from './hooks/useIsRequestTypeCategoryChampion';
import { CenteredSpinner } from '@common';

const GroupsContainer = styled(Flex)`
  width: 100%;
  margin-top: 2.6rem;
  border-radius: 1.6rem;
  border: 1px solid ${getToken(ColorsV2.GRAY_LIGHT)};
`;

type ApplicationsProps = {
  addToGroups: (employeeGroups: Group[], onSuccess: () => void) => void;
  employeeId: string;
  isAddingEmployee: boolean;
  submitOnboarding: (
    employeeId: string,
    onSuccess: () => void,
    needsHardware?: boolean
  ) => void;
};

const Applications = ({
  addToGroups,
  employeeId,
  isAddingEmployee,
  submitOnboarding,
}: ApplicationsProps) => {
  const navigate = useNavigate();
  const [queryParams, writeQueryParams] = useSearchParams();
  const { control, reset, setValue, watch } =
    useFormContext<OnboardingFormData>();
  const [employeeGroups, needsHardware] = useWatch({
    control,
    name: ['groups', 'needsHardware'],
  });
  const organizationId = useGetOrganizationId();
  const { data: groupsData = [], isLoading: isLoadingGroups } =
    useGetOrganizationGroupsQuery({ organizationId });
  const isCurrentUserHardwareChampion = useIsRequestTypeCategoryChampion(
    RequestTypeCategoryNameEnum.DEVICE
  );

  // Add the employee to the groups selected, create the onboarding and restart the onboarding form
  const handleFinishAndRestart = () => {
    // Remove the existingEmployeeId param from the URL before starting a new onboarding
    queryParams.delete('existingEmployeeId');
    writeQueryParams(queryParams, {
      // Each change in the query params will replace the current history state, meaning the "back button" will go back to the previous page.
      replace: true,
    });
    addToGroups(employeeGroups, () => {
      submitOnboarding(employeeId, () => {
        reset();
      });
    });
  };

  // Add the employee to the selected groups.
  // Create the onboarding process and navigate to the next page.
  //  - If hardware is needed, go to the hardware procurement page.
  //  - Otherwise, go to the employee's profile page.
  const handleNext = () => {
    addToGroups(employeeGroups, () => {
      submitOnboarding(
        employeeId,
        () => {
          const newUrl =
            needsHardware && isCurrentUserHardwareChampion
              ? `/purchase-hardware?${SelectionParamKeys.EmployeeId}=${employeeId}`
              : `/employees/${employeeId}`;
          navigate(newUrl);
        },
        needsHardware
      );
    });
  };

  const orgHasNoGroups = !groupsData.length;
  const nextButtonLabel =
    needsHardware && isCurrentUserHardwareChampion ? 'Next' : 'Finish';

  const nonHrisGroups = groupsData.filter(group => !group.hrisGroupIntegration);

  return (
    <>
      <Header
        title="Applications"
        subtitle="Which groups should they be assigned to?"
      />
      <Box width="100%">
        <EmployeeGroupSelector
          setValue={setValue}
          watch={watch}
          label="Employee groups"
          required
          groups={nonHrisGroups}
          disabled={isLoadingGroups}
          placeholder={
            groupsData?.length > 0 ? 'Assign groups' : 'No groups available'
          }
        />
      </Box>
      <Tooltip
        pr="1rem"
        maxWidth="58rem"
        content="We use groups to simplify the onboarding process and to make sure your new employees have the access they need. Please select a group with at least one application to begin onboarding.">
        <TooltipReference>Why do we need this information?</TooltipReference>
      </Tooltip>
      {isLoadingGroups ? (
        <CenteredSpinner py="4.3rem" />
      ) : (
        <GroupsContainer>
          <OnboardEmployeeModalApps
            orgHasNoGroups={orgHasNoGroups}
            employeeGroups={employeeGroups}
            maxHeight="auto"
          />
        </GroupsContainer>
      )}
      <FormActionsContainer>
        <Flex width="100%" gap="2rem" justifyContent="flex-end">
          <Button
            id="finish-and-restart-button"
            variant="text"
            onClick={handleFinishAndRestart}
            disabled={isLoadingGroups || isAddingEmployee || orgHasNoGroups}>
            Finish, and start another onboard
          </Button>
          <Button
            id="next-button"
            onClick={handleNext}
            disabled={isLoadingGroups || isAddingEmployee || orgHasNoGroups}
            loading={isAddingEmployee}>
            {nextButtonLabel}
          </Button>
        </Flex>
      </FormActionsContainer>
    </>
  );
};

export default Applications;

import { Skeleton, Button } from '@electricjs/arc';

import { useGlobalUI } from '@/components/GlobalUIProvider';
import { Employee } from '@/types/employees';
import IssueModal from './IssueModals/IssueModal';
import { AssetDeviceProperty, AssetIssue } from '@/types/assets';

type EmailEmployeeButtonProps = {
  issueType: keyof AssetIssue;
  employee: Employee | undefined;
  isLoading: boolean;
  device: AssetDeviceProperty;
};

const EmailEmployeeButton = ({
  issueType,
  employee,
  device,
  isLoading,
}: EmailEmployeeButtonProps) => {
  const { openModal } = useGlobalUI();

  if (isLoading) {
    return <Skeleton height="3.8rem" width="14rem" />;
  }

  if (!employee?.id) {
    return null;
  }

  return (
    <Button
      id="email-employee-button"
      onClick={() =>
        openModal(
          <IssueModal
            issueType={issueType}
            employee={employee}
            device={device}
          />
        )
      }
      variant="outline">
      Email employee
    </Button>
  );
};

export default EmailEmployeeButton;

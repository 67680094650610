import { EmployeeWithAssets } from '@/types/employees';
import { EmployeeWithReminderReason } from './types';
import { DeviceIssueType } from '@/types/devices';
import { EnrollmentReminderReason } from './types';
import {
  Asset,
  AssetHistory,
  AssetHistoryWithChildren,
  AssetLogDeviceIssue,
} from '@/types/assets';

/**
 * Returns a list of employees without any devices assigned to them
 *
 * @param employees
 * @returns list of Employees without any devices
 */
export const getEmployeesWithoutDevices = (
  employees: EmployeeWithAssets[]
): EmployeeWithAssets[] =>
  employees?.filter(
    employee => !employee.assets || employee?.assets?.length === 0
  ) || [];

export const getUnenrolledDevices = ({
  employeesWithAssets,
  hasMdmEntitlement,
}: {
  employeesWithAssets: EmployeeWithAssets[];
  hasMdmEntitlement: boolean;
}): Asset[] => {
  return employeesWithAssets.flatMap(employee =>
    employee.assets
      ? employee.assets.filter(
          asset =>
            (hasMdmEntitlement && !asset.device?.mdmEnrolledAt) ||
            !asset.device?.reportingData?.lastOsqueryEnrollment
        )
      : []
  );
};

/**
 * Calculates devices at risk for the dashboard based on organization's MDM status.
 * For MDM-enabled organizations, a device is at risk if not MDM enrolled.
 * For non-MDM enabled organizations, a device is at risk if not OSQuery enrolled.
 *
 * @param employeesWithAssets - List of employees with their assigned assets
 * @param hasMdmEntitlement - Whether the organization has MDM enabled
 * @returns Array of assets that are considered at risk
 */
export const getUnenrolledDevicesForDashboard = ({
  employeesWithAssets,
  hasMdmEntitlement,
}: {
  employeesWithAssets: EmployeeWithAssets[];
  hasMdmEntitlement: boolean;
}): Asset[] => {
  return employeesWithAssets.flatMap(employee =>
    employee.assets
      ? employee.assets.filter(asset =>
          hasMdmEntitlement
            ? !asset.device?.mdmEnrolledAt
            : !asset.device?.reportingData?.lastOsqueryEnrollment
        )
      : []
  );
};

/**
 * Calculates enrolled devices for the dashboard based on organization's MDM status.
 * For MDM-enabled organizations, a device is enrolled if MDM enrolled.
 * For non-MDM enabled organizations, a device is enrolled if OSQuery enrolled.
 *
 * @param employeesWithAssets - List of employees with their assigned assets
 * @param hasMdmEntitlement - Whether the organization has MDM enabled
 * @returns Array of assets that are considered enrolled
 */
export const getEnrolledDevicesForDashboard = ({
  employeesWithAssets,
  hasMdmEntitlement,
}: {
  employeesWithAssets: EmployeeWithAssets[];
  hasMdmEntitlement: boolean;
}): Asset[] => {
  return employeesWithAssets.flatMap(employee =>
    employee.assets
      ? employee.assets.filter(asset =>
          hasMdmEntitlement
            ? asset.device?.mdmEnrolledAt
            : asset.device?.reportingData?.lastOsqueryEnrollment
        )
      : []
  );
};

export const getEnrolledDevices = ({
  employeesWithAssets,
  hasMdmEntitlement,
}: {
  employeesWithAssets: EmployeeWithAssets[];
  hasMdmEntitlement: boolean;
}): Asset[] => {
  return employeesWithAssets.flatMap(employee =>
    employee.assets
      ? employee.assets?.filter(asset => {
          if (hasMdmEntitlement) {
            return (
              asset.device?.mdmEnrolledAt &&
              asset.device?.reportingData?.lastOsqueryEnrollment
            );
          } else {
            return asset.device?.reportingData?.lastOsqueryEnrollment;
          }
        })
      : []
  );
};

/**
 * Determines which employees need to have email reminders sent to them. This can be for a few reasons:
 * 1. No device assigned at all
 * 2. No Electric app installed on any of the devices assigned to them
 * 3. Electric app installed but no MDM enrolled (Pro only)
 *
 * @param employees - list of Employees
 * @param hasMdmEntitlement - whether the organization has the MDM entitlement
 * @returns list of Employees with a reminder reason
 */
export const getEmployeesThatNeedEnrollmentReminder = ({
  employees,
  hasMdmEntitlement,
}: {
  employees: EmployeeWithAssets[];
  hasMdmEntitlement: boolean;
}): EmployeeWithReminderReason[] => {
  return employees.flatMap(employee => {
    const assets = employee.assets || [];
    const reminders: EmployeeWithReminderReason[] = [];

    // Check each device and generate reminders as needed
    assets.forEach(asset => {
      if (!asset.device?.reportingData?.lastOsqueryEnrollment) {
        reminders.push({
          ...employee,
          reminderReason: EnrollmentReminderReason.ELECTRIC_NOT_INSTALLED,
          serialIfMultipleDevices:
            assets?.length > 1 ? asset.serialNumber : undefined,
          asset,
        });
      } else if (hasMdmEntitlement && !asset.device?.mdmEnrolledAt) {
        reminders.push({
          ...employee,
          reminderReason: EnrollmentReminderReason.MDM_NOT_INSTALLED,
          serialIfMultipleDevices:
            assets?.length > 1 ? asset.serialNumber : undefined,
          asset,
        });
      }
    });

    // Remove duplicates if any (same employee and reminder reason)
    const uniqueReminders = Array.from(
      new Set(reminders.map(r => JSON.stringify(r)))
    ).map(e => JSON.parse(e));

    return uniqueReminders;
  });
};

/**
 * Returns a Map of resolved device issues as an array keyed on the issue name
 * for easy lookup
 *
 * @param resolvedDeviceIssues
 * @returns Map of resolved devices issues keyed on the issue name
 */
export const getResolvedIssuesByTypeMap = (
  resolvedDeviceIssues: AssetHistory<Record<string, unknown>>[]
): Record<string, AssetHistory[]> =>
  resolvedDeviceIssues.reduce(
    (acc: Record<string, AssetHistory[]>, currAssetLog: AssetHistory) => {
      const issueType = currAssetLog?.data?.issue_type as string;
      const existingLogs = acc[issueType] || [];

      return {
        ...acc,
        [issueType]: [...existingLogs, currAssetLog],
      };
    },
    {}
  );

/**
 * Returns a Map of unresolved device issues as an array keyed on the issue name
 * for easy lookup
 *
 * @param unresolvedDeviceIssues
 * @returns Map of unresolved devices issues keyed on the issue name
 */
export const getUnresolvedIssuesByTypeMap = (
  unresolvedDeviceIssues: AssetHistoryWithChildren<AssetLogDeviceIssue>[]
): Record<string, AssetHistory[]> =>
  unresolvedDeviceIssues.reduce(
    (acc: Record<string, AssetHistory[]>, currIssue: AssetHistory) => {
      const issueType = currIssue?.data?.issue_type as DeviceIssueType;
      const existingLogs = acc[issueType] || [];
      return {
        ...acc,
        [issueType]: [...existingLogs, currIssue],
      };
    },
    {
      [DeviceIssueType.OS]: [],
      [DeviceIssueType.Storage]: [],
      [DeviceIssueType.Gatekeeper]: [],
      [DeviceIssueType.Encryption]: [],
      [DeviceIssueType.Firewall]: [],
      [DeviceIssueType.RAM]: [],
      [DeviceIssueType.OsqueryNotReporting]: [],
      [DeviceIssueType.DeviceLock]: [],
    }
  );

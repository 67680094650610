import { Button } from '@electricjs/arc';
import { useNavigate } from 'react-router-dom';

export const RenewMdmCertButton = () => {
  const navigate = useNavigate();
  const url = `/settings?tab=device`;

  return (
    <Button
      id="renew-mdm-cert-onboarding-button"
      onClick={() => navigate(url, { state: { renewCertificate: true } })}>
      Renew now
    </Button>
  );
};

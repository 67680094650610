import { FilterOption } from 'common/TableFilters/types';
import { Chip, ColorsV2, Flex, Text, useThemeColors } from '@electricjs/arc';

type SelectChipProps<T> = {
  selectedValues: T[];
  options: FilterOption<T>[];
  handleRemoveFilter: (value: T) => void;
};

const SelectChip = <T extends string>({
  selectedValues = [],
  options,
  handleRemoveFilter,
}: SelectChipProps<T>) => {
  const primaryColor = useThemeColors(ColorsV2.PRIMARY_LIGHTEST);

  if (selectedValues.length === 0) return null;

  return (
    <Flex gap="0.5rem" flexWrap="wrap">
      {selectedValues.map(value => {
        const option = options.find(opt => opt.value === value);
        if (!option) return null;
        return (
          <Chip
            key={option.id}
            id={`filter-chip-${option.id}`}
            removeIcon="close"
            onRemove={() => handleRemoveFilter(value)}
            backgroundColor={primaryColor}>
            <Text variant="label-small">{option.text}</Text>
          </Chip>
        );
      })}
    </Flex>
  );
};

export default SelectChip;

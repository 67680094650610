import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';

import { StorefrontSteps } from '@/types/storefront';
import { ConfirmedOrder } from '@/types/orders';

type StorefrontStepContextType = {
  storefrontStep: StorefrontSteps;
  setStorefrontStep: (step: StorefrontSteps) => void;
  confirmedOrder?: ConfirmedOrder;
  setConfirmedOrder: (order: ConfirmedOrder | undefined) => void;
};

type StorefrontStepProviderProps = {
  children: ReactNode;
};

const StorefrontStepContext = createContext<StorefrontStepContextType>({
  storefrontStep: StorefrontSteps.SHOPPING,
  setStorefrontStep: () => {},
  confirmedOrder: undefined,
  setConfirmedOrder: () => {},
});

export const StorefrontStepProvider = ({
  children,
}: StorefrontStepProviderProps) => {
  const [storefrontStep, setStorefrontStep] = useState<StorefrontSteps>(
    StorefrontSteps.SHOPPING
  );

  const [confirmedOrder, setConfirmedOrder] = useState<ConfirmedOrder>();

  return (
    <StorefrontStepContext.Provider
      value={{
        storefrontStep,
        setStorefrontStep: useCallback(step => setStorefrontStep(step), []),
        confirmedOrder,
        setConfirmedOrder: useCallback(order => setConfirmedOrder(order), []),
      }}>
      {children}
    </StorefrontStepContext.Provider>
  );
};

export const useStorefrontStep = () => {
  const {
    storefrontStep,
    setStorefrontStep,
    confirmedOrder,
    setConfirmedOrder,
  } = useContext(StorefrontStepContext);

  return {
    storefrontStep,
    setStorefrontStep,
    confirmedOrder,
    setConfirmedOrder,
  };
};

import styled from 'styled-components';
import { flexbox, space, FlexboxProps, SpaceProps } from 'styled-system';

const StyledSpan = styled.span<FlexboxProps & SpaceProps>`
  height: 100%;
  display: flex;
  align-items: center;
  ${flexbox}
  ${space}
`;

/**
 * Table cells are not meant to use divs as direct children. This component uses spans to allow for flex styling within a table cell.
 */
const TableCellFlexContainer = ({
  children,
  ...props
}: {
  children: React.ReactNode | React.ReactNode[];
  [key: string]: unknown;
}) => {
  return <StyledSpan {...props}>{children}</StyledSpan>;
};

export default TableCellFlexContainer;

import {
  ModalV2,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalFooterActions,
  ModalAction,
  Text,
  Flex,
  useBreakpoint,
  useThemeColors,
  ColorsV2,
  IconInCircle,
} from '@electricjs/arc';

import { useUpdateABMOrganizationInfoMutation } from '@/redux/slices/organizationApiSlice';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { ABMEnrollmentStatusEnum } from '@electricjs/core_entity-client';

type ABMRemoveModalProps = {
  onCloseAbm: () => void;
  onRemoveAbm: () => void;
  onErrorAbm: () => void;
};

const ABMRemoveModal = ({
  onCloseAbm,
  onRemoveAbm,
  onErrorAbm,
}: ABMRemoveModalProps) => {
  const { md } = useBreakpoint();
  const [iconColor, iconBackgroundColor] = useThemeColors([
    ColorsV2.INFO,
    ColorsV2.INFO_LIGHTEST,
  ]);
  const organizationId = useGetOrganizationId();
  const [updateABMOrganizationInfo] = useUpdateABMOrganizationInfoMutation();

  const handleRemove = () => {
    updateABMOrganizationInfo({
      organizationId,
      abmStatus: ABMEnrollmentStatusEnum.NotApplied,
    })
      .unwrap()
      .then(() => {
        onRemoveAbm();
      })
      .catch(() => {
        onErrorAbm();
      });
  };

  return (
    <ModalV2
      ariaLabelledby="apple-business-manager-remove-modal"
      testId="apple-business-manager-remove-modal"
      visible
      hide={onCloseAbm}
      minWidth={md ? '80rem' : undefined}>
      <ModalHeader>
        <Flex
          p="1.5rem 1rem"
          width="100%"
          flexWrap="wrap"
          justifyContent="space-between"
          gap="1rem">
          <Flex>
            <IconInCircle
              color={iconColor}
              background={iconBackgroundColor}
              icon="logo-apple"
              customIconSize="2rem"
              size="3.2rem"
            />
            <Text variant="heading-3" ml="1rem">
              Remove Apple Business Manager
            </Text>
          </Flex>
        </Flex>
      </ModalHeader>
      <ModalBody p="3rem">
        <Text>
          You are about to remove your Apple Business Manager (ABM) connection.
          Apple devices purchased from Electric, in the future, will no longer
          be linked to your ABM account. You can reconnect ABM at anytime within
          “settings.”
        </Text>
        <Text mt="1rem">
          Please confirm below if you&apos;d like to remove ABM.
        </Text>
      </ModalBody>
      <ModalFooter>
        <ModalFooterActions justifyContent={md ? 'end' : 'center'}>
          <ModalAction
            onClick={onCloseAbm}
            py="1.4rem"
            px="2rem"
            id="modal-abm-cancel-button"
            testId="modal-abm-cancel-button"
            variant="outline"
            border="none">
            Cancel
          </ModalAction>
          <ModalAction
            py="1rem"
            px="1.5rem"
            id="modal-abm-remove-button"
            testId="modal-abm-remove-button"
            onClick={handleRemove}
            intent="danger">
            Yes, remove ABM
          </ModalAction>
        </ModalFooterActions>
      </ModalFooter>
    </ModalV2>
  );
};

export default ABMRemoveModal;

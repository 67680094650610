import styled from 'styled-components';

import { Flex, Text } from '@electricjs/arc';

import { useResponsiveness } from '@common';

import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useGetBillingInfoQuery } from '@/redux/slices/billingApiSlice';

import { useStorefront } from '../../StorefrontProvider';
import { BillingInfoReadOnly } from '../BillingInfo/BillingInfoDetails';

const StyledText = styled(Text)`
  word-break: break-word;
  hyphens: auto;
`;

const PaymentReadOnly = () => {
  const { storefrontCheckoutForm } = useStorefront();

  const paymentMethod = storefrontCheckoutForm?.watch('paymentMethod');

  const { hasNoHorizontalSpace } = useResponsiveness();

  const organizationId = useGetOrganizationId();

  const {
    data: billingInfo,
    isFetching,
    isError,
  } = useGetBillingInfoQuery(organizationId);

  return (
    <Flex
      vertical={hasNoHorizontalSpace}
      columnGap="2rem"
      rowGap={hasNoHorizontalSpace ? '2rem' : '0rem'}>
      {/* Billing email */}
      <Flex width={hasNoHorizontalSpace ? '100%' : '50%'}>
        <BillingInfoReadOnly
          isError={isError}
          isFetching={isFetching}
          billingEmail={billingInfo?.email}
          hasBillingAddress={false}
        />
      </Flex>
      {/*  Payment Method */}
      <Flex
        width={hasNoHorizontalSpace ? '100%' : '50%'}
        columnGap={hasNoHorizontalSpace ? '2rem' : '0rem'}
        {...(hasNoHorizontalSpace && { alignItems: 'center' })}>
        <Text variant="label-small" intent="primary">
          Payment Method
        </Text>
        <Flex vertical {...(!hasNoHorizontalSpace && { width: '100%' })}>
          <StyledText>{paymentMethod?.label}</StyledText>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PaymentReadOnly;

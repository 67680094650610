import electricLogo from '@/assets/electric_logo_red.svg';
import Nav from './SideNavBase';
import { generateNavItems } from './generateNavItems';
import NavLogo from '../Logo';
import { SideNavItem } from './SideNavItem';
import { useBreakpoint } from '@electricjs/arc';
import isVisible from '@/helpers/isVisible';
import { NavLink as RouterNavLink, NavLinkProps } from 'react-router-dom';
import { useAppSelector } from '@/redux/store';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { OrganizationUserRoleSlugs } from '@/types/users';
import { useOrganizationHasEntitlement } from '@/hooks/useOrganizationHasEntitlement';
import {
  EntitlementSlugEnum,
  PermissionEnum,
} from '@electricjs/core_entity-client';
import { useUserHasOrganizationPermission } from '@/hooks/useUserHasOrganizationPermission';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useGetHelpfulLinksQuery } from '@/redux/slices/helpfulLinksApiSlice';
import { useGetOrganizationApplicationsQuery } from '@/redux/slices/organizationApiSlice';
import { useMemo } from 'react';

/**
 * Creates a wrapper when an onClick method is provided.
 * This is primarily used to close the mobile menu when a menu item is clicked.
 */
function NavLinkWithOnClick(onClick?: () => void) {
  return function NavLink(props: NavLinkProps) {
    return <RouterNavLink {...(onClick ? { onClick } : {})} {...props} />;
  };
}

const SideNavWrapper = ({
  mobileOnMenuClick,
}: {
  mobileOnMenuClick?: () => void;
}) => {
  const { md } = useBreakpoint();
  const flags = useFlags();
  const loggedUser = useAppSelector(state => state.loggedUser);
  const isAdminOrSupportRole = loggedUser?.organizationUserRoles?.some(
    role =>
      role.slug === OrganizationUserRoleSlugs.SUPER_ADMIN ||
      role.slug === OrganizationUserRoleSlugs.PRODUCT_SUPPORT
  );

  const hasApplicationsEntitlement = useOrganizationHasEntitlement(
    EntitlementSlugEnum.Applications
  );
  const hasEmployeeGroupsEntitlement = useOrganizationHasEntitlement(
    EntitlementSlugEnum.EmployeeGroups
  );
  const hasAntivirusEntitlement = useOrganizationHasEntitlement(
    EntitlementSlugEnum.ThreatdownSecurityDashboard
  );
  const hasEmailSecurityEntitlement = useOrganizationHasEntitlement(
    EntitlementSlugEnum.HarmonyEmailAndCollaborationSecurityDashboard
  );
  const hasGigawattEntitlement = useOrganizationHasEntitlement(
    EntitlementSlugEnum.Gigawatt
  );
  const hasITPlanEntitlement = useOrganizationHasEntitlement(
    EntitlementSlugEnum.ItPlan
  );
  const hasElectricAppEntitlement = useOrganizationHasEntitlement(
    EntitlementSlugEnum.DesktopApp
  );
  const hasEmployeeDataBreachEntitlement = useOrganizationHasEntitlement(
    EntitlementSlugEnum.EmployeeDataBreaches
  );

  const canReadAssets = useUserHasOrganizationPermission(
    PermissionEnum.Assetsread
  );

  const organizationId = useGetOrganizationId();
  const { data: helpfulLinks } = useGetHelpfulLinksQuery(organizationId);
  const { data: appsData } = useGetOrganizationApplicationsQuery({
    organizationId,
  });

  const hasHelpfulLinks = useMemo(() => {
    const helpfulLinksExist = helpfulLinks && helpfulLinks.length > 0;
    const appsExist =
      appsData?.applications && appsData?.applications.length > 0;
    return helpfulLinksExist || appsExist;
  }, [helpfulLinks, appsData]);

  // If the mobile menu is open, we want to close it when a menu item is clicked
  const NavLink = mobileOnMenuClick
    ? NavLinkWithOnClick(mobileOnMenuClick)
    : RouterNavLink;

  const logoProps = {
    logoElement: (
      <img
        alt="Electric"
        className="nav-logo__img"
        data-testid="nav-logo"
        height="30"
        src={electricLogo}
        width="102"
      />
    ),
    logoLinkProps: {
      href: '/',
    },
  };

  const navItems = generateNavItems({
    isAdminOrSupportRole,
    flags,
    NavLink,
    entitlements: {
      hasApplicationsEntitlement,
      hasEmployeeGroupsEntitlement,
      hasAntivirusEntitlement,
      hasEmailSecurityEntitlement,
      hasGigawattEntitlement,
      hasITPlanEntitlement,
      hasElectricAppEntitlement,
      hasEmployeeDataBreachEntitlement,
    },
    permissions: {
      canReadAssets,
    },
    hasHelpfulLinks,
  });

  return (
    <Nav mobilePositionTop="auto">
      {md ? <NavLogo {...logoProps} /> : null}
      {navItems
        ? navItems
            .filter(isVisible)
            .map(item => (
              <SideNavItem key={item.text ?? item.sectionHeading} {...item} />
            ))
        : null}
    </Nav>
  );
};

export default SideNavWrapper;

import { LockStatus, OSType } from '@electricjs/core_entity-client';

export enum DeviceIssueType {
  Encryption = 'encryption',
  Firewall = 'firewall',
  Gatekeeper = 'gatekeeper',
  OS = 'os',
  Storage = 'storage',
  RAM = 'ram',
  /* TODO HY-218 We're temporarily disabling Battery health while we improve the logic behind it */
  // BatteryHealth = 'battery_health',
  OsqueryNotReporting = 'osquery_not_reporting',
  MdmNotReporting = 'mdm_not_reporting',
  DeviceLock = 'device_lock',
}

export type DeviceIssueTypeOld = Exclude<
  DeviceIssueType,
  | typeof DeviceIssueType.OsqueryNotReporting
  | typeof DeviceIssueType.MdmNotReporting
  | typeof DeviceIssueType.DeviceLock
>;

export type IssueExtra = {
  eolDate: string;
  link: string;
};

export type IssueDetails = {
  category: string;
  actual: string;
  expected: string;
  extra?: IssueExtra | null;
};

export type Issue = {
  [DeviceIssueType.Encryption]?: IssueDetails;
  [DeviceIssueType.Firewall]?: IssueDetails;
  [DeviceIssueType.Gatekeeper]?: IssueDetails;
  [DeviceIssueType.OS]?: IssueDetails;
  [DeviceIssueType.Storage]?: IssueDetails;
};

export type UpdateDeviceArgs = {
  organizationId: string;
  deviceId: string;
  lockStatus?: string;
  lockPin?: string;
  mdmEnrolledAt?: string;
  assignedTo?: string | null;
  employeeId: string;
};

export type Device = {
  serialNumber: string;
  hardwareModel: string;
  osType: string;
  organizationId: string;
  id: string;
  lockStatus?: LockStatus;
  lockPin?: string;
  mdmEnrolledAt?: string;
  assetId?: string;
  electricAppInstalledAt?: string;
};

export type DeviceReportingDevice = {
  id?: string;
  organization_id?: string;
  serial_number?: string;
  hardware_model?: string;
  hostname?: string;
  os_type?: OSType;
  os_version?: string;
  os_name?: string;
  ram_capacity?: number;
  storage_capacity?: number;
  storage_available?: number;
  storage_usage_percent?: number;
  filevault?: boolean;
  filevault_status?: string;
  filevault_encryption_percent?: number;
  bitlocker?: boolean;
  bitlocker_status?: string;
  bitlocker_encryption_percent?: number;
  encryption?: boolean;
  encryption_percent?: number;
  battery_health?: string;
  firewall?: boolean;
  gatekeeper?: boolean;
  issues?: Issue;
  mdm_enrolled_at?: string;
  created_at?: string;
  updated_at?: string;
  lock_status?: LockStatus;
  lock_pin?: string;
  asset_id?: string;
  installed_apps?: string[];
  last_osquery_enrollment?: string;
  last_osquery_report?: string;
};

export type EmployeeDeviceOsGrouping = {
  employee_id: string;
  os_type: string;
  device_id: string;
};

export type IssueEmailArgs = {
  employeeDeviceOsGrouping: EmployeeDeviceOsGrouping[];
  issueType: string;
  organizationId: string;
};

export type CreateDeviceArgs = {
  organizationId: string;
  employeeId: string;
  serialNumber: string;
  hardwareModel: string;
  osType: 'darwin' | 'windows';
  assignedTo?: string;
};

export type DeleteDeviceArgs = {
  organizationId: string;
  deviceId: string;
  employeeId: string;
};

export type DeleteTestDeviceArgs = {
  organizationId: string;
  deviceId: string;
  employeeId: string;
};

export type CreateFakeDeviceArgs = {
  organizationId: string;
  employeeId: string;
  deviceId: string;
  serialNumber: string;
  hardwareModel: string;
  osType: 'darwin' | 'windows';
  issues: string[];
};

export type DeleteDeviceReportingArgs = {
  organizationId: string;
  deviceId: string;
};

import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Text } from '@electricjs/arc';

const VPNCheckDiv = styled.div`
  margin-top: 8rem;
  text-align: center;
`;

/**
 * This component shows a reminder to turn on their VPN to users in a dev or
 * staging environment. Use alongside a spinner component.
 */
const VPNCheck = () => {
  const disableCheck = window._env_?.VITE_ENV_NAME === 'production';
  const [timerExpired, setTimerExpired] = useState<boolean>(false);

  useEffect(() => {
    if (disableCheck) {
      return;
    }
    const timeoutId = setTimeout(() => setTimerExpired(true), 5000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [disableCheck, setTimerExpired]);

  if (disableCheck || !timerExpired) {
    return null;
  }

  return (
    <VPNCheckDiv>
      <img
        src="https://media1.giphy.com/media/QBd2kLB5qDmysEXre9/giphy.gif"
        alt="Waiting"
      />
      <Text>Did you forget to turn on your VPN?</Text>
    </VPNCheckDiv>
  );
};

export default VPNCheck;

import { MDMStatus } from '@/constants/mdmStatus';
import { Chip, ColorsV2, Text, Flex, Icon } from '@electricjs/arc';
import styled from 'styled-components';

type MDMStatusChipProps = {
  mdmStatus: MDMStatus;
};

const ChipContentContainer = styled(Flex)`
  padding-top: 0.1rem;
  align-items: center;
`;

const MDMStatusChip = ({ mdmStatus }: MDMStatusChipProps) => {
  switch (mdmStatus) {
    case MDMStatus.INSTALLED:
      return (
        <Chip
          id="mdm-installed-chip"
          variant="small"
          backgroundColor={ColorsV2.SUCCESS_LIGHTEST}>
          <ChipContentContainer>
            <Icon
              color={ColorsV2.SUCCESS_DARK}
              mr={1}
              size="small"
              icon="check-circle"
            />
            <Text
              color={ColorsV2.SUCCESS_DARK}
              mt="0.1rem"
              intent="success"
              variant="legal">
              MDM
            </Text>
          </ChipContentContainer>
        </Chip>
      );
      break;
    case MDMStatus.NOT_INSTALLED:
      return (
        <Chip
          id="mdm-not-installed-chip"
          variant="small"
          textColor={ColorsV2.ERROR_DARK}
          backgroundColor={ColorsV2.ERROR_LIGHTEST}>
          <ChipContentContainer>
            <Icon
              color={ColorsV2.ERROR_DARK}
              mr={1}
              size="small"
              icon="alert-circle"
            />
            <Text color={ColorsV2.ERROR_DARK} variant="legal">
              MDM not installed
            </Text>
          </ChipContentContainer>
        </Chip>
      );
      break;
    case MDMStatus.LOCKED:
      return (
        <Chip
          id="mdm-not-installed-chip"
          variant="small"
          backgroundColor={ColorsV2.INFO_LIGHTEST}>
          <ChipContentContainer>
            <Icon
              color={ColorsV2.INFO_DARKEST}
              mr={1}
              size="small"
              icon="lock"
            />
            <Text color={ColorsV2.INFO_DARKEST} intent="info" variant="legal">
              Locked
            </Text>
          </ChipContentContainer>
        </Chip>
      );
    case MDMStatus.WIPE_REQUESTED:
      return (
        <Chip
          id="mdm-not-installed-chip"
          variant="small"
          textColor={ColorsV2.WARNING_DARKEST}
          backgroundColor={ColorsV2.WARNING_LIGHTEST}>
          <ChipContentContainer>
            <Icon
              color={ColorsV2.WARNING_DARKEST}
              mr={1}
              size="small"
              icon="clock-circle"
            />
            <Text color={ColorsV2.WARNING_DARKEST} variant="legal">
              Wipe requested
            </Text>
          </ChipContentContainer>
        </Chip>
      );
      break;
    case MDMStatus.WIPED:
      return (
        <Chip
          id="mdm-not-installed-chip"
          variant="small"
          backgroundColor={ColorsV2.GRAY_LIGHT}>
          <ChipContentContainer>
            <Icon mr={1} size="small" intent="neutral" icon="broom" />
            <Text intent="primary" variant="legal">
              Wiped
            </Text>
          </ChipContentContainer>
        </Chip>
      );
      break;
    default:
      return null;
  }
};

export default MDMStatusChip;

{
  /* <Chip {...args} id="chip-with-children">
<Flex alignItems="center">
  <Text variant="legal">With:</Text>
  <Icon intent="neutral" mx={1} size="small" icon="broom" />
  <Text intent="primary" variant="legal">
    Children
  </Text>
</Flex>
</Chip> */
}

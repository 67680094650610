import {
  ModalAction,
  ModalBody,
  ModalFooter,
  ModalFooterActions,
  ModalHeader,
  ModalV2,
  Text,
} from '@electricjs/arc';

import { useGlobalUI } from '@/components/GlobalUIProvider';
import { getDeviceIssueEmailSuccessToastMessage } from '@/components/People/EmployeeProfile/helpers/DevicePropertyHelpers';
import { useSendIssueEmailMutation } from '@/redux/slices/deviceApiSlice';

import { DeviceIssueType } from '@/types/devices';

import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { AssetDeviceProperty } from '@/types/assets';

type EmailModalProps = {
  hide: () => void;
  issueType: DeviceIssueType;
  employeeId: string;
  employeeFullname: string;
  title: string;
  children: JSX.Element | JSX.Element[];
  device: AssetDeviceProperty;
};

const EmailModal = ({
  hide,
  issueType,
  employeeId,
  employeeFullname,
  title,
  children,
  device,
}: EmailModalProps) => {
  const { showSuccessToast, showErrorToast } = useGlobalUI();

  const [sendIssueEmail, { isLoading }] = useSendIssueEmailMutation();
  const organizationId = useGetOrganizationId();

  const issueTypeToast = getDeviceIssueEmailSuccessToastMessage(issueType);

  const handleEmailSendError = (error?: Error) => {
    console.error(`Unable to send ${issueTypeToast} instructions: `, error);
    showErrorToast({
      id: 'email-send-error-toast',
      stack: !!error,
      title: 'Unable to send Emails',
      message:
        'Something went wrong while sending the emails. Please try again.',
    });
  };

  const handleSubmit = () => {
    if (organizationId) {
      const employeeDeviceOsGrouping = [
        {
          employee_id: employeeId,
          os_type: device.osType || 'darwin',
          device_id: device.id || '',
        },
      ];

      sendIssueEmail({
        employeeDeviceOsGrouping,
        issueType,
        organizationId,
      })
        .unwrap()
        .then(() => {
          showSuccessToast({
            id: 'email-send-success-toast',
            stack: true,
            message: `${issueTypeToast} instructions have been sent to ${employeeFullname}`,
          });
          hide();
        })
        .catch(handleEmailSendError);
    } else {
      handleEmailSendError();
    }
  };

  return (
    <ModalV2 visible hide={hide} ariaLabelledby="email-employees">
      <ModalHeader>
        <Text variant="heading-2">{title}</Text>
      </ModalHeader>
      <ModalBody padding="3.2rem 1.6rem">{children}</ModalBody>
      <ModalFooter>
        <ModalFooterActions>
          <ModalAction
            id="send-email-cancel-button"
            variant="text"
            onClick={hide}
            disabled={isLoading}>
            Cancel
          </ModalAction>
          <ModalAction
            id="send-email-button"
            loading={isLoading}
            onClick={handleSubmit}>
            Send email
          </ModalAction>
        </ModalFooterActions>
      </ModalFooter>
    </ModalV2>
  );
};

export default EmailModal;

import {
  OrderTracking as OrderDto,
  ProductTypeName,
} from '@electricjs/core_entity-client';

export const orders: OrderDto[] = [
  {
    id: 'order-id-1',
    friendly_id: 'friendly-id-1',
    order_origin: 'ITH',
    organization_id: 'org-id-1',
    created_at: '2024-02-05',
    requester_employee_id: 'requester-employee-id-1',
    for_employee_id: 'employee-id-1',
    for_employee_full_name:
      'Hubert Blaine Wolfeschlegelsteinhausenbergerdorfair Sr.',
    status: 'DELIVERED',
    emails_for_notifications: ['email@email.com'],
    recipient_name: 'Hubert',
    recipient_phone_number: '11111111111111',
    final_destination_address_id: '',
    ship_to_address_id: '',
    ship_to_address: {
      street_1: '915 broadway',
      street_2: 'Suite 802',
      city: 'New York',
      state: 'NY',
      country: 'US',
      zip_code: '10010',
    },
    line_items: [
      {
        id: '1',
        order_id: 'order-id-1',
        product_id: 'product-id-1',
        product_name: 'Dell Latitude 15in - 16GB RAM - 512GB SSD',
        product_type_name: ProductTypeName.Laptop,
        charged_price: 2000,
        serial_number: '1P260L3',
        tracking_number: '950123940123',
        status: 'DELIVERED',
        provisioning_type: 'NO_PROVISIONING',
      },
    ],
  },
  {
    id: 'order-id-2',
    friendly_id: 'friendly-id-2',
    order_origin: 'ITH',
    organization_id: 'org-id-1',
    created_at: '2024-02-05',
    requester_employee_id: 'requester-employee-id-1',
    for_employee_id: 'employee-id-2',
    for_employee_full_name: 'John Doe',
    status: 'DELIVERED',
    emails_for_notifications: ['email@email.com'],
    recipient_name: 'John',
    recipient_phone_number: '11111111111111',
    final_destination_address_id: '',
    ship_to_address_id: '',
    ship_to_address: {
      street_1: '915 broadway',
      street_2: 'Suite 802',
      city: 'New York',
      state: 'NY',
      country: 'US',
      zip_code: '10010',
    },
    line_items: [
      {
        id: '1',
        order_id: 'order-id-2',
        product_id: 'product-id-1',
        product_name: 'Dell Latitude 15in - 16GB RAM - 512GB SSD',
        product_type_name: ProductTypeName.Laptop,
        charged_price: 2000,
        serial_number: '1P260L3',
        tracking_number: '950123940123',
        status: 'DELIVERED',
        provisioning_type: 'NO_PROVISIONING',
      },
    ],
  },
];

import { Link, Text } from '@electricjs/arc';

import { DeviceIssueType } from '@/types/devices';
import { EmployeeWithAssets } from '@/types/employees';

import EmailModal from './EmailModal';
import { osLinks } from '@/constants/deviceIssueLinks';

type OSUpdateEmailModalProps = {
  hide: () => void;
  employees: EmployeeWithAssets[];
};

const OSUpdateEmailModal = ({ hide, employees }: OSUpdateEmailModalProps) => {
  return (
    <EmailModal
      hide={hide}
      issueType={DeviceIssueType.OS}
      employees={employees}
      title="Send employees OS update instructions"
      showDevice={true}
      showOS={true}>
      <Text>
        We’ll send instructions to your employees on how to update their OS.
      </Text>
      <Text mt={3}>
        <Link target="_blank" rel="noopener" href={osLinks.mac}>
          MacOS instructions
        </Link>
      </Text>
      <Text mt={3}>
        <Link target="_blank" rel="noopener" href={osLinks.windows}>
          Windows instructions
        </Link>
      </Text>
      <Text mt={3}>
        <Text tag="span" fontWeight="bold" mr={2}>
          Latest macOS update:
        </Text>
        macOS Ventura 13.3.1
      </Text>
      <Text mt={3}>
        <Text tag="span" fontWeight="bold" mr={2}>
          Latest Windows update:
        </Text>
        Windows 10 version 22H2
      </Text>
      <Text mt={5}>Employees that need to update their OS:</Text>
    </EmailModal>
  );
};

export default OSUpdateEmailModal;

import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Box, TabButtonGroup } from '@electricjs/arc';

import { Store, StorefrontProductTypeGroup } from '@/types/storefront';
import { SelectionParamKeys } from '@/types/queryParamKeys';

import { storeDisplayName } from '../../utils';
import { CatalogStore, CatalogStoreProps } from '../Catalog/CatalogStore';
import { AssetStore, AssetStoreProps } from '../Asset/AssetStore';

export type StoreSectionProps = Omit<
  CatalogStoreProps & AssetStoreProps,
  'selectedProductTypeGroup' | 'onProductTypeGroupSelect'
>;

export const StoreSection = ({
  laptopRecommendationUrl,
}: StoreSectionProps) => {
  const [queryParams] = useSearchParams();
  const assetId = queryParams.get(SelectionParamKeys.AssetId);
  const [selectedStore, setSelectedStore] = useState<Store>(Store.Catalog);

  useEffect(() => {
    if (assetId) {
      setSelectedStore(Store.Asset);
    }
  }, [assetId]);

  const [selectedProductTypeGroup, setSelectedProductTypeGroup] =
    useState<StorefrontProductTypeGroup>(StorefrontProductTypeGroup.Computers);

  const handleProductTypeGroupSelection = (
    group: StorefrontProductTypeGroup
  ) => {
    setSelectedProductTypeGroup(group);
  };

  return (
    <>
      {/* Store Selector */}
      <TabButtonGroup
        tabs={[
          { value: Store.Catalog, label: storeDisplayName[Store.Catalog] },
          { value: Store.Asset, label: storeDisplayName[Store.Asset] },
        ]}
        activeTabValue={selectedStore}
        onTabChange={tabValue => setSelectedStore(tabValue as Store)}
      />

      {/* Stores (Catalog, Assets) */}
      <Box mt="2.4rem">
        {selectedStore === Store.Catalog && (
          <CatalogStore
            selectedProductTypeGroup={selectedProductTypeGroup}
            onProductTypeGroupSelect={handleProductTypeGroupSelection}
            laptopRecommendationUrl={laptopRecommendationUrl}
          />
        )}
        {selectedStore === Store.Asset && (
          <AssetStore
            selectedProductTypeGroup={selectedProductTypeGroup}
            onProductTypeGroupSelect={handleProductTypeGroupSelection}
            laptopRecommendationUrl={laptopRecommendationUrl}
          />
        )}
      </Box>
    </>
  );
};

import { Button, Menu, MenuItem, Text } from '@electricjs/arc';
import { PermissionEnum, AssetStatus } from '@electricjs/core_entity-client';
import ArchiveAssetMenuItem from './ArchiveAssetMenuItem';
import DeleteAssetMenuItem from './DeleteAssetMenuItem';
import { useUserHasOrganizationPermission } from '@/hooks/useUserHasOrganizationPermission';
import { useState } from 'react';
import WipeDeviceMenuItem from './WipeDeviceMenuItem';
import LockDeviceMenuItem from './LockDeviceMenuItem';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useGetAssetQuery } from '@/redux/slices/assetApiSlice';
import { getFullName } from 'common/utils/getFullName';
import useShowDeviceActions from './useShowDeviceActions';
import { isDeviceAsset } from '@/types/assets';
import { useAppSelector } from '@/redux/store';
import { OrganizationUserRoleSlugs } from '@/types/users';

const ARCHIVED_STATUSES = new Set<AssetStatus>([
  AssetStatus.Archived,
  AssetStatus.Donated,
  AssetStatus.Sold,
  AssetStatus.Disposed,
  AssetStatus.Missing,
]);

const isAssetArchived = (status?: AssetStatus) => {
  return status ? ARCHIVED_STATUSES.has(status) : false;
};

type AssetActionsButtonMenuProps = {
  editProfile: () => void;
  assetId: string;
};

const AssetDetailsButtonMenu = ({
  editProfile,
  assetId,
}: AssetActionsButtonMenuProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [disableLock, setDisableLock] = useState(false);
  const canModifyAssets = useUserHasOrganizationPermission(
    PermissionEnum.Assetsupdate
  );
  const organizationId = useGetOrganizationId();
  const loggedUser = useAppSelector(state => state.loggedUser);
  const isProdSupport =
    loggedUser?.organizationUserRoles?.some(
      role => role.slug === OrganizationUserRoleSlugs.PRODUCT_SUPPORT
    ) || false;

  const { employeeName, asset, isFetchingAsset } = useGetAssetQuery(
    {
      organizationId,
      assetId,
    },
    {
      skip: !assetId,
      selectFromResult: ({ data, isFetching }) => ({
        deviceId: data?.device?.id,
        employeeName: getFullName(
          data?.assignedToEmployee?.firstName,
          data?.assignedToEmployee?.lastName
        ),
        asset: data,
        isFetchingAsset: isFetching,
      }),
    }
  );
  const showDeviceActions =
    useShowDeviceActions({
      assetId,
    }) && !!asset?.device;

  const lockEnabled = useUserHasOrganizationPermission(
    PermissionEnum.Deviceslock
  );
  const showLockOption = lockEnabled || isProdSupport;

  const showWipeOption =
    useUserHasOrganizationPermission(PermissionEnum.Deviceswipe) ||
    isProdSupport;

  const showDeleteOption =
    useUserHasOrganizationPermission(PermissionEnum.Assetsdelete) &&
    isProdSupport &&
    isAssetArchived(asset?.status);

  if (
    !canModifyAssets &&
    (!showDeviceActions || (!showLockOption && !showWipeOption)) &&
    !showDeleteOption
  ) {
    return null;
  }

  return (
    <Menu
      trigger={
        <Button
          id="asset-details-button-menu"
          iconAfter="chevron-down"
          loading={isLoading}>
          Actions
        </Button>
      }
      placement="bottom-end">
      {canModifyAssets && (
        <MenuItem id="edit-asset-menu-item" onClick={editProfile}>
          <Text>Edit Asset</Text>
        </MenuItem>
      )}
      {showDeviceActions && (
        <>
          {showWipeOption && isDeviceAsset(asset) && (
            <WipeDeviceMenuItem
              deviceAsset={asset}
              employeeName={employeeName}
              isLoading={isFetchingAsset}
              isProdSupport={isProdSupport}
            />
          )}
          {showLockOption && isDeviceAsset(asset) && (
            <LockDeviceMenuItem
              deviceAsset={asset}
              employeeName={employeeName}
              isLoading={isFetchingAsset}
              disableLock={disableLock}
              setDisableLock={() => setDisableLock(true)}
              isProdSupport={isProdSupport}
            />
          )}
        </>
      )}
      {canModifyAssets && (
        <ArchiveAssetMenuItem assetId={assetId} setIsloading={setIsLoading} />
      )}
      {showDeleteOption && (
        <DeleteAssetMenuItem assetId={assetId} setIsloading={setIsLoading} />
      )}
    </Menu>
  );
};

export default AssetDetailsButtonMenu;

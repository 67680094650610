import { Avatar, InitialsInCircle } from '@electricjs/arc';

type ApplicationIconProps = {
  name: string;
  icon?: string;
  size?: string;
  borderRadius?: string;
  backgroundColor?: string;
};

const ApplicationIcon = ({
  name,
  icon,
  size = '4rem',
  borderRadius = '0.5rem',
  backgroundColor = 'transparent',
}: ApplicationIconProps) => {
  return icon ? (
    <Avatar
      src={icon}
      size={size}
      alt={name}
      borderRadius={borderRadius}
      backgroundColor={backgroundColor}
    />
  ) : (
    <InitialsInCircle text={name} size={size} borderRadius={borderRadius} />
  );
};

export default ApplicationIcon;

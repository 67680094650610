import { Banner, BannerProps, Box, BoxProps, Text } from '@electricjs/arc';
import { useState } from 'react';
import styled, { StyledProps } from 'styled-components';

const OrderedList = styled(Box)`
  padding-left: 2rem;
`;

type ShippingInstructionsProps = {
  listProps?: Partial<StyledProps<BoxProps>>;
  bannerProps?: Partial<BannerProps>;
};

const ShippingInstructions = ({
  listProps,
  bannerProps,
}: ShippingInstructionsProps) => {
  const [showBanner, setShowBanner] = useState(true);

  return (
    <>
      <Text variant="label-large">How It Works:</Text>
      <Text>
        <OrderedList {...listProps} as="ol">
          <li>
            The shipper brings the emailed QR code and device to a UPS Store for
            a seamless drop-off.
          </li>
          <li>
            UPS will handle the inspection, packaging, insurance, and shipping
            at no cost to the shipper.
          </li>
          <li>
            Your organization will be invoiced $95 per shipment, billed at the
            end of any month when shipments occur.
          </li>
        </OrderedList>
      </Text>
      {showBanner && (
        <Banner
          id="limitations-banner"
          title="Limitations apply"
          message="Drop-off service is only available at The UPS Store locations in the contiguous United States. We do not offer shipments to non-US locations. Monitors, key cards or other accessories are not eligible for this program."
          intent="info"
          isFlat
          onClickDismiss={() => setShowBanner(false)}
          width="fit-content"
          {...bannerProps}
        />
      )}
    </>
  );
};

export default ShippingInstructions;

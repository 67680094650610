import { useEffect, type ChangeEvent } from 'react';

import { ProductTypeName } from '@electricjs/core_entity-client';
import { Box, RadioGroup, Link } from '@electricjs/arc';

import {
  StorefrontProductTypeGroup,
  productTypesByTypeGroup,
} from '@/types/storefront';

import { TypeGroupIcon } from './TypeGroupIcon';
import { TypeGroupRadioTile } from './TypeGroupRadioTile';

export type ProductTypeGroupSelectorProps = {
  selectedProductTypeGroup: StorefrontProductTypeGroup;
  onProductTypeGroupSelect: (group: StorefrontProductTypeGroup) => void;
  onProductTypesSelectFromGroup: (types: ProductTypeName[]) => void;
  laptopRecommendationUrl?: string;
};

export const ProductTypeGroupSelector = ({
  selectedProductTypeGroup,
  onProductTypeGroupSelect,
  onProductTypesSelectFromGroup,
  laptopRecommendationUrl,
}: ProductTypeGroupSelectorProps) => {
  useEffect(() => {
    // override all selected types (using the types got from the group)
    onProductTypesSelectFromGroup(
      productTypesByTypeGroup[selectedProductTypeGroup]
    );
  }, [selectedProductTypeGroup, onProductTypesSelectFromGroup]);

  return (
    <Box>
      <RadioGroup
        id="storefront-product-type-group-selector"
        aria-label="type group selector"
        state={selectedProductTypeGroup}
        radioTilesGap="1.6rem"
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          onProductTypeGroupSelect(e.target.value as StorefrontProductTypeGroup)
        }>
        <TypeGroupRadioTile
          value={StorefrontProductTypeGroup.Computers}
          checked={
            selectedProductTypeGroup === StorefrontProductTypeGroup.Computers
          }>
          <TypeGroupIcon icons={['laptop']} />
          Computers
        </TypeGroupRadioTile>
        <TypeGroupRadioTile
          value={StorefrontProductTypeGroup.Monitors}
          checked={
            selectedProductTypeGroup === StorefrontProductTypeGroup.Monitors
          }>
          <TypeGroupIcon icons={['monitor']} />
          Monitors
        </TypeGroupRadioTile>
        <TypeGroupRadioTile
          value={StorefrontProductTypeGroup.KeyboardMouse}
          checked={
            selectedProductTypeGroup ===
            StorefrontProductTypeGroup.KeyboardMouse
          }>
          <TypeGroupIcon icons={['keyboard', 'mouse']} />
          Keyboards and Mice
        </TypeGroupRadioTile>
        <TypeGroupRadioTile
          value={StorefrontProductTypeGroup.Docks}
          checked={
            selectedProductTypeGroup === StorefrontProductTypeGroup.Docks
          }>
          <TypeGroupIcon icons={['dongle']} />
          Docks
        </TypeGroupRadioTile>
        <TypeGroupRadioTile
          value={StorefrontProductTypeGroup.AudioVideo}
          checked={
            selectedProductTypeGroup === StorefrontProductTypeGroup.AudioVideo
          }>
          <TypeGroupIcon icons={['audio', 'webcam']} />
          Audio and Video
        </TypeGroupRadioTile>
        <TypeGroupRadioTile
          value={StorefrontProductTypeGroup.Misc}
          checked={
            selectedProductTypeGroup === StorefrontProductTypeGroup.Misc
          }>
          Chargers, Cables & Misc
        </TypeGroupRadioTile>
      </RadioGroup>
      {selectedProductTypeGroup === StorefrontProductTypeGroup.Computers &&
        laptopRecommendationUrl && (
          <Box mt="1.6rem">
            <Link
              href={laptopRecommendationUrl}
              rel="noopener noreferrer"
              target="_blank">
              Need a laptop recommendation?
            </Link>
          </Box>
        )}
    </Box>
  );
};

import { Flex, FlexProps, Spinner, SpinnerProps } from '@electricjs/arc';

export type CenteredSpinnerProps = FlexProps & {
  spinnerProps?: SpinnerProps;
  mt?: string;
};

const CenteredSpinner = ({
  spinnerProps,
  mt = '4rem',
  ...props
}: CenteredSpinnerProps) => (
  <Flex width="100%" hAlignContent="center" mt={mt} {...props}>
    <Spinner {...spinnerProps} />
  </Flex>
);

export default CenteredSpinner;

import { ColorsV2, Text, useThemeColors } from '@electricjs/arc';

import { getCreatedAtFormatted } from '../helpers/notificationHelpers';

type NotificationLabelProps = {
  notificationType: string;
  createdAt?: string;
};

export const NotificationLabel = ({
  notificationType,
  createdAt,
}: NotificationLabelProps) => {
  const [textColor] = useThemeColors([ColorsV2.GRAY_DARK]);

  return (
    <Text variant="legal" color={textColor} fontSize="1rem">
      {getCreatedAtFormatted(createdAt).toLocaleUpperCase()} {' - '}
      {notificationType}
    </Text>
  );
};

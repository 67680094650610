import { Group } from '@/types/groups';

export enum OnboardingStep {
  Demographics = 1,
  EmployeeInformation = 2,
  Onboarding = 3,
  Applications = 4,
}

export enum OffboardingStep {
  Applications = 1,
  Hardware = 2,
}

export enum HiringStatus {
  New = 'new',
  Existing = 'existing',
  Hris = 'hris',
}

export type OnboardingFormData = {
  demographics: {
    firstName: string;
    lastName: string;
    personalEmail: string;
    address: {
      streetAddress1: string;
      streetAddress2: string;
      city: string;
      state: string;
      country: string;
      zip: string;
    };
    phone: string;
  };
  employeeInformation: {
    startDate: Date | null;
    jobTitle: string;
    email: string;
  };
  groups: Group[];
  currentStep: OnboardingStep;
  hiringStatus: HiringStatus;
  needsApplications: boolean;
  needsHardware: boolean;
};

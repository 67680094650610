import { Asset } from '@/types/assets';
import { Employee, EmployeeWithAssets } from '@/types/employees';

const convertEmployeesToEmployeeWithAssets = <GenericAsset extends Asset>({
  employees,
  assets,
}: {
  employees: Employee[];
  assets: GenericAsset[];
}): EmployeeWithAssets[] => {
  const assetsEmployeeMap: Record<string, Asset[]> = assets.reduce(
    (acc: Record<string, Asset[]>, asset: Asset) => {
      if (!asset.assignedTo) {
        return acc;
      }
      const employeesAssets = acc[asset.assignedTo] || [];

      return {
        ...acc,
        [asset.assignedTo]: [...employeesAssets, asset],
      };
    },
    {}
  );

  return employees.map(employee => {
    const assets = assetsEmployeeMap[employee.id];
    return {
      ...employee,
      assets,
    };
  });
};

export default convertEmployeesToEmployeeWithAssets;

import { InternalOrderDto } from '@/redux/slices/orderApiSlice';
import {
  OrderStatus,
  OrderLineItemStatus,
} from '@electricjs/core_entity-client';

export const internalOrders: InternalOrderDto[] = [
  {
    id: '65645067-d10d-49ae-b91f-00005258c6fb',
    friendly_id: 'EE9D976D-0042',
    organization_id: 'f25ff964-5668-51e7-9e7e-faa83b0d99c5',
    status: OrderStatus.Processing,
    order_origin: 'ITH',
    requester_employee_id: 'd9085787-ec58-47b4-8b17-af0e52c18821',
    for_employee_id: 'abfe3238-d32d-43ca-9312-1a2cb90b22fa',
    requester_employee_full_name: 'Steve Rogers',
    organization_name: 'Friends International',
    payment_method_last_four_digits: '0004',
    payment_method_brand_name: 'VISA',
    shipping_method: 'AIR',
    shipping_method_cost: 60,
    tax_cost: 88,
    order_refund_status: 'PARTIAL REFUND',
    line_items: [
      {
        id: '7229a4f8-233b-46d8-9ebe-30889d24d19f',
        order_id: '65645067-d10d-49ae-b91f-00005258c6fb',
        status: OrderLineItemStatus.Processing,
        refund_status: 'AVAILABLE',
        charged_price: 299.99,
        provisioning_type: 'NO_PROVISIONING',
        order_number: 123456789,
        tracking_number: '123456789',
        serial_number: '987456123',
        product_id: '7bcaed98-7dc1-4aaa-8545-2e3b2ec61ffa',
        product_name: 'Dell E2723H - LED Monitor',
        ship_method: 'UPS_GROUND',
      },
      {
        id: '21c468c5-bed5-4fec-ae1d-62c6416550a4',
        order_id: '65645067-d10d-49ae-b91f-00005258c6fb',
        status: OrderLineItemStatus.Deleted,
        refund_status: 'REFUNDED',
        charged_price: 129.0,
        tracking_number: '789456123',
        serial_number: '753159456',
        provisioning_type: 'NO_PROVISIONING',
        product_id: '368a6cd2-d41b-4a0d-ae20-f4bb9c3c227a',
        product_name: 'Apple Magic Trackpad',
        ship_method: 'UPS_GROUND',
      },
      {
        id: '2bf1b35a-e0a9-4db5-b9f4-94fe0c14c4ae',
        order_id: '65645067-d10d-49ae-b91f-00005258c6fb',
        status: OrderLineItemStatus.Processing,
        refund_status: 'AVAILABLE',
        charged_price: 99.0,
        tracking_number: '741258963',
        provisioning_type: 'NO_PROVISIONING',
        product_id: 'b5534413-685e-4fd3-9bc1-15bb90d6a366',
        product_name: 'Apple Magic keyboard - US',
        ship_method: 'UPS_GROUND',
      },
      {
        id: 'd4bddd1c-57e1-42c2-b52a-fc063f1dc2ae',
        order_id: '65645067-d10d-49ae-b91f-00005258c6fb',
        status: OrderLineItemStatus.Deleted,
        refund_status: 'REFUNDED',
        charged_price: 279.0,
        tracking_number: '963852741',
        provisioning_type: 'NO_PROVISIONING',
        product_id: 'ec51e85c-5e1f-443b-bd13-334888725a58',
        product_name: '3 Years',
        ship_method: 'UPS_GROUND',
        related_line_item_id: 'c77e0373-6e54-467a-92b9-8cfdbe3129a4',
      },
      {
        id: 'c77e0373-6e54-467a-92b9-8cfdbe3129a4',
        order_id: '65645067-d10d-49ae-b91f-00005258c6fb',
        status: OrderLineItemStatus.Processing,
        refund_status: 'AVAILABLE',
        charged_price: 2699.0,
        tracking_number: '134679852',
        provisioning_type: 'NO_PROVISIONING',
        product_id: '457b38a9-8e04-475d-accc-f42b3b15041f',
        product_name: 'Apple MacBook Pro',
        ship_method: 'UPS_GROUND',
        related_line_item_id: 'd4bddd1c-57e1-42c2-b52a-fc063f1dc2ae',
      },
    ],
    recipient_name: 'John Doe',
    recipient_phone_number: '(555) 666-7777',
    emails_for_notifications: [
      'john.doe@electric.ai',
      'james.person@electric.ai',
    ],
    ship_to_address_id: '90bfcffa-8a54-4dcb-8a7f-b1f737ab0747',
    final_destination_address_id: '52c1d209-1490-4c1f-8e91-540c287f7e77',
    ship_to_address: {
      id: '90bfcffa-8a54-4dcb-8a7f-b1f737ab0747',
      street_1: '1600 Pennsylvania Avenue Northwest',
      street_2: 'Apartment 802',
      city: 'Washington',
      state: 'DC',
      zip_code: '20500',
      country: 'United States',
    },
    billing_name: 'James John',
    billing_address: {
      id: '90bfcffa-8a54-4dcb-8a7f-b1f737ab0747',
      street_1: '1600 Pennsylvania Avenue Northwest',
      street_2: 'Apartment 101',
      city: 'Washington',
      state: 'DC',
      zip_code: '20500',
      country: 'United States',
    },
    delivery_instructions: '',
    invoice_pdf_url:
      'https://pay.stripe.com/invoice/acct_1Pn5NUJ3HL2D6vde/test_YWNjdF8xUG41TlVKM0hMMkQ2dmRlLF9SQTZwdDNaclFtQld3MHZxcGgyYTFtd3NwOTY2OEFZLDEyMTM1MzM1NQ0200EykpIokX/pdf?s=ap',
    invoice_page_url:
      'https://invoice.stripe.com/i/acct_1Pn5NUJ3HL2D6vde/test_YWNjdF8xUG41TlVKM0hMMkQ2dmRlLF9SQTZwdDNaclFtQld3MHZxcGgyYTFtd3NwOTY2OEFZLDEyMTM1MzM1NQ0200EykpIokX?s=ap',
    invoice_total: 3817.05,
    invoice_subtotal: 3663.05,
    available_to_refund: 2017.05,
    previously_refunded: 1800.05,
    created_at: '2024-11-05T13:15:51.602643+00:00',
    for_employee_full_name: 'John Doe',
  },
  {
    id: 'cb5d5cdf-bb9d-4ca0-8714-0f4448695b25',
    friendly_id: 'EE9D976D-0041',
    organization_id: 'f25ff964-5668-51e7-9e7e-faa83b0d99c5',
    status: OrderStatus.Created,
    order_origin: 'ITH',
    requester_employee_id: 'a79d260f-4a37-48ed-9667-a15fb031c3f9',
    for_employee_id: 'a79d260f-4a37-48ed-9667-a15fb031c3f9',
    requester_employee_full_name: 'Tony Stark',
    organization_name: 'Wise Guys EDU',
    payment_method_last_four_digits: '0009',
    payment_method_brand_name: 'MASTERCARD',
    shipping_method: 'GROUND',
    shipping_method_cost: 20,
    tax_cost: 25,
    order_refund_status: 'REFUNDED',
    line_items: [
      {
        id: '03806409-9f29-406c-9cfd-f712a048dba4',
        order_id: 'cb5d5cdf-bb9d-4ca0-8714-0f4448695b25',
        status: OrderLineItemStatus.Deleted,
        refund_status: 'REFUNDED',
        charged_price: 999.0,
        provisioning_type: 'NO_PROVISIONING',
        order_number: 145806988,
        asset_id: '453f3a88-9e68-4092-aab4-429a91680d19',
        product_id: 'fc8ee7ed-01fe-49ef-afe2-f8ebc7048ae9',
        product_name: 'Lenovo ThinkPad E14 Gen 4 14”',
        ship_method: 'UPS_GROUND',
        serial_number: '0000',
        tracking_number: '145806988',
        related_line_item_id: '11111',
        submission_fail_notes: 'Failed purchase',
      },
    ],
    recipient_name: 'James Person',
    recipient_phone_number: '(111) 111-1111',
    emails_for_notifications: ['james.person@electric.ai'],
    ship_to_address_id: '0048ae4b-90dd-4e6b-8f89-17ed95008ddf',
    final_destination_address_id: '983318d4-dd0e-402b-8a87-3f0d313bb7cd',
    ship_to_address: {
      id: '0048ae4b-90dd-4e6b-8f89-17ed95008ddf',
      street_1: '100 Centre Street',
      street_2: '',
      city: 'New York',
      state: 'NY',
      zip_code: '10013',
      country: 'United States',
    },
    billing_name: 'James John',
    billing_address: {
      id: '0048ae4b-90dd-4e6b-8f89-17ed95008ddf',
      street_1: '100 Centre Street',
      street_2: '',
      city: 'New York',
      state: 'NY',
      zip_code: '10013',
      country: 'United States',
    },
    delivery_instructions: '',
    invoice_pdf_url:
      'https://pay.stripe.com/invoice/acct_1Pn5NUJ3HL2D6vde/test_YWNjdF8xUG41TlVKM0hMMkQ2dmRlLF9SOWt4TG5td1Y5cTVDTXRhUVgxdXdVTGM0U3doOWNVLDEyMTI3MjAxMA02001nhoFsUv/pdf?s=ap',
    invoice_page_url:
      'https://invoice.stripe.com/i/acct_1Pn5NUJ3HL2D6vde/test_YWNjdF8xUG41TlVKM0hMMkQ2dmRlLF9SOWt4TG5td1Y5cTVDTXRhUVgxdXdVTGM0U3doOWNVLDEyMTI3MjAxMA02001nhoFsUv?s=ap',
    invoice_total: 1125.78,
    invoice_subtotal: 1080.78,
    available_to_refund: 1000.05,
    previously_refunded: 80.05,
    created_at: '2024-11-04T14:40:06.297561+00:00',
    for_employee_full_name: 'James Person',
  },
];

import { rest } from 'msw';

import {
  getNotificationsResponse,
  getUnseenNotificationsCountResponse,
} from '../data/notificationsData';

export const notificationsHandlers = [
  rest.get(`*/v1/notifications/count/unseen`, (_req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(200),
      ctx.json(
        getUnseenNotificationsCountResponse(getNotificationsResponse.results)
      )
    );
  }),
  rest.get(`*/v1/notifications`, (req, res, ctx) => {
    const limitParam = req.url.searchParams.get('limit');
    const limit = limitParam
      ? parseInt(limitParam)
      : (getNotificationsResponse.total ?? 0);

    const offsetParam = req.url.searchParams.get('offset');
    const offset = offsetParam ? parseInt(offsetParam) : 0;

    return res(
      ctx.status(200),
      ctx.delay(200),
      ctx.json({
        ...getNotificationsResponse,
        results: getNotificationsResponse.results.slice(offset, offset + limit),
      })
    );
  }),
  rest.patch(`*/v1/notifications`, async (req, res, ctx) => {
    const body = await req.json();
    const notificationIds = body?.notification_ids ?? [];

    notificationIds.forEach((id: string) => {
      const index = getNotificationsResponse.results.findIndex(
        n => n.id === id
      );

      if (index >= 0) {
        getNotificationsResponse.results[index].seen_at =
          new Date().toDateString();
      }
    });

    return res(ctx.status(200), ctx.delay(200));
  }),
];

import { yupResolver } from '@hookform/resolvers/yup';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';

import {
  CustomComponents,
  Flex,
  ModalAction,
  ModalBody,
  ModalFooter,
  ModalFooterActions,
  ModalHeader,
  ModalV2,
  Pane,
  SelectField,
  Text,
  TextInputField,
} from '@electricjs/arc';
import { PackageNameEnum } from '@electricjs/core_entity-client';

import { useGlobalUI } from '@/components/GlobalUIProvider';
import {
  PACKAGE_DISPLAY_NAME_MAP,
  plansResponse,
} from '@/constants/plans-data';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import {
  useListAllPackagesQuery,
  useUpdatePackageMutation,
} from '@/redux/slices/packageApiSlice';
import { PackageInternal, PackageOption } from '@/types/packages';

export type PlanUpdateArgs = {
  basePlan: PackageOption;
  licenseCount: number;
};

type ChangePlanModalProps = {
  currentPlan?: PackageInternal;
  currentLicenseCount: number;
  onClose: () => void;
};

const PlanOptionComponents: CustomComponents = {
  Option: option => {
    const labelTextIntent = option.isSelected ? 'disabled' : 'primary';

    return (
      <Pane py="0.2rem" flexWrap="nowrap">
        <Pane stack>
          <Text variant="label-large" intent={labelTextIntent}>
            {option.data.label}
          </Text>
        </Pane>
      </Pane>
    );
  },
};

const ChangePlanModal = ({
  currentPlan: currentPlan,
  currentLicenseCount,
  onClose,
}: ChangePlanModalProps) => {
  const { electrolyteLightPlan } = useFlags();

  const SelectablePlans: Partial<PackageNameEnum>[] = [
    PackageNameEnum.Quickstart,
    PackageNameEnum.Pro,
    PackageNameEnum.Premier,
    PackageNameEnum.Beta,
    ...(electrolyteLightPlan ? [PackageNameEnum.Lyte] : []),
  ];

  const updatePlanSchema = yup.object().shape({
    licenseCount: yup
      .number()
      .positive()
      .nullable()
      .typeError('Please add at least 1 license')
      .transform((_, val) => (val ? Number(val) : null)),
  });
  const { showSuccessToast, showErrorToast } = useGlobalUI();
  const currentOrganizationId = useGetOrganizationId();

  const [updateBasePlan] = useUpdatePackageMutation();
  const { data: packagesData, isLoading: loadingPackages } =
    useListAllPackagesQuery();

  const updatePlan = (planUpdateArgs: PlanUpdateArgs) => {
    const packageId = packagesData?.find(
      pkg => pkg.name === planUpdateArgs.basePlan.value
    )?.id;
    updateBasePlan({
      organizationId: currentOrganizationId ?? '',
      packageId: packageId ?? '',
      licenseCount: planUpdateArgs.licenseCount,
    })
      .unwrap()
      .then(() => {
        showSuccessToast({
          id: 'base-plan-update-success-toast',
          message: 'The base plan was successfully updated.',
        });
        onClose();
      })
      .catch(error => {
        console.error('Error updating base plan: ', error);
        showErrorToast({
          id: 'base-plan-update-error-toast',
          message:
            'There was an error updating the base plan. Please try again.',
        });
      });
  };

  const getMatchingPlan = (slug?: PackageNameEnum) => {
    return (
      plansResponse.availablePlans.find(plan => plan.name === slug) ?? undefined
    );
  };

  const planOptions = packagesData
    ?.filter(pkg => SelectablePlans.includes(pkg.name as PackageNameEnum))
    ?.map(pkg => {
      const matchingPlan = getMatchingPlan(pkg.name as PackageNameEnum);
      if (!matchingPlan) return;

      return {
        label: matchingPlan?.displayName ?? '',
        value: pkg.name as PackageNameEnum,
        packageId: pkg.id,
      };
    })
    .filter(Boolean);

  const isADPProPlan = currentPlan?.slug === PackageNameEnum.ProAdp;
  const planDisplayName = isADPProPlan
    ? PACKAGE_DISPLAY_NAME_MAP.pro_adp
    : getMatchingPlan(currentPlan?.slug)?.displayName;

  const defaultValues = {
    basePlan: {
      value: currentPlan?.slug || undefined,
      label: planDisplayName,
      packageId: currentPlan?.packageId || '',
    },
    licenseCount: currentLicenseCount,
  };

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<PlanUpdateArgs>({
    resolver: yupResolver(updatePlanSchema),
    mode: 'onTouched',
    defaultValues,
  });
  const selectedPlan = useWatch({ control, name: 'basePlan' });
  const licenseCount = useWatch({ control, name: 'licenseCount' });

  const disabledLicenseCountField =
    selectedPlan.value === PackageNameEnum.ProAdp;
  const disableUpdateButton =
    !isValid ||
    (currentPlan?.slug === selectedPlan.value &&
      Number(currentLicenseCount) === Number(licenseCount));

  return (
    <ModalV2 visible hide={onClose} ariaLabelledby="org-change-plan">
      <ModalHeader>
        <Text variant="heading-2">Change your Electric Base Plan</Text>
      </ModalHeader>
      <ModalBody>
        <form>
          <Flex vertical rowGap="1.5rem">
            <SelectField
              id="base-plan-selector"
              name="basePlan"
              label="Base Plan"
              isMulti={false}
              control={control}
              customComponents={PlanOptionComponents}
              options={planOptions}
              isLoading={loadingPackages}
            />

            <TextInputField
              id="license-count-input"
              name="licenseCount"
              control={control}
              label="License Count"
              width="100%"
              disabled={disabledLicenseCountField}
            />
          </Flex>
        </form>
      </ModalBody>
      <ModalFooter>
        <ModalFooterActions>
          <ModalAction
            id="org-change-plan-cancel"
            onClick={onClose}
            variant="text"
            loading={loadingPackages}>
            Cancel
          </ModalAction>
          <ModalAction
            id="org-change-plan-submit"
            disabled={disableUpdateButton}
            onClick={handleSubmit(updatePlan)}
            loading={loadingPackages}>
            Update plan
          </ModalAction>
        </ModalFooterActions>
      </ModalFooter>
    </ModalV2>
  );
};

export default ChangePlanModal;

import { groupBy } from 'lodash';

import { AssetHistory, AssetHistoryWithChildren } from '@/types/assets';
import { formatCustomDate } from '@/helpers/DateHelpers';
import { DeviceIssueType } from '@/types/devices';
import { AssetActionEnum } from '@electricjs/core_entity-client';

type AssetHistoryGroupedByDate = {
  [key: string]: (AssetHistory & { createdAtFormatted: string })[];
};

/**
 * Groups asset history entries by their formatted creation date.
 *
 * @param {AssetHistory[]} assetHistory - Array of asset history entries.
 * @returns {AssetHistoryGroupedByDate} An object where keys are formatted dates and values are arrays of asset history entries grouped by those dates.
 */
export const getAssetHistoryGroupedByDate = (
  assetHistory: AssetHistory[]
): AssetHistoryGroupedByDate => {
  const historyAssetWithDateFormatted =
    assetHistory.map(assetLog => ({
      ...assetLog,
      createdAtFormatted: formatCustomDate(assetLog.createdAt ?? ''),
    })) ?? [];

  const groupedHistoryAsset = groupBy(
    historyAssetWithDateFormatted,
    'createdAtFormatted'
  );

  return groupedHistoryAsset;
};

export const groupByParentAndChildrenSorted = (
  assetLogs: AssetHistory[]
): AssetHistoryWithChildren[] => {
  const logsById = new Map<string, AssetHistoryWithChildren>();
  const parentLogs: AssetHistoryWithChildren[] = [];
  const logs = assetLogs.map(log => ({ ...log, children: [] }));

  // Step 1: Map all logs by ID for quick lookup
  logs.forEach(log => {
    logsById.set(log.id, log);
  });

  // Step 2: Iterate over logs and organize parents and children
  logs.forEach(log => {
    if (log.parentAssetLogId) {
      // Find the parent and add this log as a child
      const parentLog: AssetHistoryWithChildren | undefined = logsById.get(
        log.parentAssetLogId
      );

      if (parentLog) {
        if (!parentLog.children) {
          parentLog.children = [];
        }
        parentLog.children.push(log);
      }
    } else {
      // If no parent, it's a top-level parent log
      parentLogs.push(log);
    }
  });

  // Step 3: Sort parent logs and their children by created_at (descending)
  parentLogs.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );
  parentLogs.forEach(parent => {
    if (parent.children) {
      parent.children.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
    }
  });

  return parentLogs;
};

export const getUnresolvedLogsByTypeCount = (
  logs: AssetHistoryWithChildren[]
): Record<string, number> => {
  return (
    logs
      ?.filter(log => {
        // Filter out logs that have a resolved or ignored child
        return !(log.children ?? []).some(
          child =>
            child.action === AssetActionEnum.DeviceIssueResolved ||
            child.action === AssetActionEnum.DeviceIssueIgnored
        );
      })
      .reduce(
        (acc: Record<string, number>, currLog: AssetHistoryWithChildren) => {
          const issueType = currLog.data.issue_type as DeviceIssueType;

          if (issueType) {
            acc[issueType] = acc[issueType] ? acc[issueType] + 1 : 1;
          }

          return acc;
        },
        {}
      ) || {}
  );
};

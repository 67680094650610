import { useEffect } from 'react';

import { organizationSelected } from '@/redux/slices/loggedUserSlice';
import { useAppDispatch } from '@/redux/store';
import { SessionStorageItems } from '@/types/sessionStorageItems';
import { LoggedUser, UserOrganization } from '@/types/users';

type SetOrgFromStorageProps = {
  loggedUser: LoggedUser | null;
  selectedOrgIdFromState?: string | null;
};

export const useSetOrgFromStorage = ({
  loggedUser,
  selectedOrgIdFromState,
}: SetOrgFromStorageProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    try {
      // If the redux state doesn't already contain a selected org, and it wasn't set to null, check the session storage
      // Note: the logged user must exist in the redux state before this step, otherwise the dispatch won't store the data.
      if (loggedUser && selectedOrgIdFromState === undefined) {
        const orgDataJsonFromSessionStorage = sessionStorage.getItem(
          SessionStorageItems.SELECTED_ORG_DATA
        );

        if (
          orgDataJsonFromSessionStorage &&
          typeof orgDataJsonFromSessionStorage === 'string'
        ) {
          // Pull the org data from the sessionStorage if it exists, and set it in the redux state
          const {
            selectedOrg,
            selectedOrgId,
          }: {
            selectedOrg: UserOrganization;
            selectedOrgId: string;
          } = JSON.parse(orgDataJsonFromSessionStorage);

          dispatch(
            organizationSelected({
              organizationId: selectedOrgId,
              organizationUserRoles: selectedOrg.roles,
              employeeId: selectedOrg.employeeId,
            })
          );
        } else {
          // Set the org values to null in the redux state if the sessionStorage doesn't contain any org data,
          // this way we know we have attempted to check sessionStorage and we can instead use the default org selection logic.
          dispatch(
            organizationSelected({
              organizationId: null,
              organizationUserRoles: null,
              employeeId: null,
            })
          );
        }
      }
    } catch (error) {
      console.error(
        'Error checking or setting a previously selected org from session storage:',
        error
      );
    }
  }, [dispatch, loggedUser, selectedOrgIdFromState]);
};

import {
  EntitlementSlugEnum,
  PermissionEnum,
} from '@electricjs/core_entity-client';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  Button,
  ColorsV2,
  Flex,
  getToken,
  IconInCircle,
  Text,
  useBreakpoint,
} from '@electricjs/arc';

import {
  AssetConditionOption,
  AssetStatusOption,
  ProductTypeOption,
} from '@/components/Assets/helpers/transformOptions';
import { useGlobalUI } from '@/components/GlobalUIProvider';
import { EmployeeOption } from '@/components/People/EmployeeGroups/NewEmployeeGroupForm';
import { useUserHasOrganizationPermission } from '@/hooks/useUserHasOrganizationPermission';
import { AssetDetailsContext } from '@/pages/Assets/AssetDetails';
import { AssetStatusChip } from '@/pages/Assets/AssetStatus';
import SiteModal from '@/pages/Assets/SiteModal';
import { Asset, isDeviceAsset } from '@/types/assets';
import formatCurrency from '@/helpers/formatCurrency';

import getProductTypeIcon from '../helpers/getProductTypeIcon';
import getProductTypeLabel from '../helpers/getProductTypeLabel';
import getDomainFromUrl from '../helpers/getDomainFromUrl';
import AssetDetailsButtonMenu from './AssetDetailsButtonMenu/AssetDetailsButtonMenu';
import AssetFormField, { AssetFieldTypes } from './AssetFormField';
import AssetHeaderItem from './AssetHeaderItem';
import formatDate from '@/helpers/formatDate';
import { useGetSiteLocationsQuery } from '@/redux/slices/assetApiSlice';
import Map from './Map';
import useAssetAddress from './useAssetAddress';
import MDMStatusChip from '@/components/People/EmployeeProfile/MDMStatusChip';
import useGetMdmStatus from '@/hooks/useGetMdmStatus';
import { useOrganizationHasEntitlement } from '@/hooks/useOrganizationHasEntitlement';
import MaybeLink from '@/components/Assets/AssetDetails/MaybeLink';
import EmployeeOffboardedWarningIcon from '@/components/People/EmployeeOffboardedWarningIcon';

export const StyledHeaderContainer = styled(Flex)`
  border-bottom: 1px solid ${getToken(ColorsV2.GRAY_LIGHT)};
  padding-bottom: 2.4rem;
`;

type AssetDetailsHeaderProps = {
  asset: Asset;
  productTypeOptions: ProductTypeOption[];
  employeeOptions: EmployeeOption[];
  siteOptions: { label: string; value: string }[];
  locationOptions: { label: string; value: string }[];
  assetStatusOptions: AssetStatusOption[];
  assetConditionOptions: AssetConditionOption[];
  isFetchingProductTypes: boolean;
  isFetchingEmployees: boolean;
  isFetchingSites: boolean;
  isUpdatingAsset: boolean;
  isFetchingLocations: boolean;
};

function getAssetInformation(asset: Asset) {
  return {
    name: asset.name,
    productType: asset.productType,
    assignedTo: [
      asset.assignedToEmployee?.firstName,
      asset.assignedToEmployee?.lastName,
    ]
      .filter(Boolean)
      .join(' '),
    assignedToEmployeeId: asset.assignedTo,
    purchaseDate: asset.purchaseDate,
    purchasePrice: asset.purchasePrice?.toString(),
    purchasedFrom: asset.purchasedFromUrl,
    brand: asset.brand,
    model: asset.model,
    serialNumber: asset.serialNumber,
    warrantyExpirationDate: asset.warrantyExpirationDate,
    site: asset.site,
    locationId: asset.locationId,
  };
}

const AssetDetailsHeader = ({
  asset,
  productTypeOptions,
  employeeOptions,
  siteOptions,
  locationOptions,
  assetStatusOptions,
  assetConditionOptions,
  isFetchingProductTypes,
  isFetchingEmployees,
  isFetchingSites,
  isUpdatingAsset,
  isFetchingLocations,
}: AssetDetailsHeaderProps) => {
  const { md, lg, xl } = useBreakpoint();
  const navigate = useNavigate();
  const { isEditing, setIsEditing } = useContext(AssetDetailsContext);
  const { openModal, closeModal } = useGlobalUI();
  const [isMapError, setIsMapError] = useState(false);

  const canReadAssetLocation = useUserHasOrganizationPermission(
    PermissionEnum.Assetslocationread
  );

  const canUpdateAssetLocation = useUserHasOrganizationPermission(
    PermissionEnum.Assetslocationupdate
  );

  const canUpdateInternalNotes = useUserHasOrganizationPermission(
    PermissionEnum.Warehousingupdate
  );

  const hasMdmEntitlement = useOrganizationHasEntitlement(
    EntitlementSlugEnum.JumpcloudMdm
  );

  const { address, isEmployeeAddress } = useAssetAddress(asset);

  const { mdmStatus } = useGetMdmStatus({
    deviceAsset: hasMdmEntitlement && isDeviceAsset(asset) ? asset : null,
  });

  const {
    name: assetName,
    productType,
    assignedTo,
    assignedToEmployeeId,
    purchaseDate,
    purchasePrice,
    purchasedFrom,
    brand,
    model,
    serialNumber,
    warrantyExpirationDate,
    site,
    locationId,
  } = getAssetInformation(asset);
  const hasComeFromNavigation = history.state.idx > 0;

  const isAssetAtGlobalSite = !!(asset.site && !asset.site?.organizationId);

  const canUpdateAssetAssigneeAndSite =
    !isAssetAtGlobalSite || canUpdateAssetLocation;

  const { data: siteLocations } = useGetSiteLocationsQuery(site?.id ?? '', {
    skip: !site?.id || !canReadAssetLocation,
  });

  const assetLocation = siteLocations?.find(
    location => location.id === locationId
  );

  const employeeAssociationExists = !!asset.assignedToEmployee;

  return (
    <StyledHeaderContainer vertical>
      <Flex
        justifyContent={hasComeFromNavigation ? 'space-between' : 'flex-end'}
        width="100%"
        mb={6}>
        {hasComeFromNavigation && (
          <Button
            id="button-asset-details-back"
            variant="outline"
            iconBefore="arrow-left"
            onClick={() => navigate(-1)}>
            Back
          </Button>
        )}

        {isEditing ? (
          <Flex columnGap={5}>
            <Button
              id="button-asset-details-cancel"
              variant="text"
              onClick={() => setIsEditing(false)}
              disabled={isUpdatingAsset}>
              Cancel
            </Button>
            <Button
              id="button-asset-details-save"
              type="submit"
              disabled={isUpdatingAsset}>
              Save information
            </Button>
          </Flex>
        ) : (
          <AssetDetailsButtonMenu
            editProfile={() => setIsEditing(true)}
            assetId={asset.id}
          />
        )}
      </Flex>
      <Flex
        width="100%"
        justifyContent="flex-start"
        columnGap="1.6rem"
        vertical={!xl}>
        <Flex columnGap="4.2rem">
          <Flex flexShrink={0}>
            <IconInCircle
              size={md ? '9.6rem' : '7rem'}
              icon={getProductTypeIcon(productType.name)}
              customIconSize={md ? '4.8rem' : '3.5rem'}
              background={ColorsV2.INFO_LIGHTEST}
              intent="neutral"
            />
          </Flex>
          <Flex vertical>
            <Flex vAlignContent="center" width="100%">
              <Text variant="heading-1" mr="3rem">
                {assetName}
              </Text>
            </Flex>
            <Flex gap="1rem">
              {!isEditing && <AssetStatusChip status={asset.status} />}
              {!isEditing &&
                hasMdmEntitlement &&
                employeeAssociationExists &&
                mdmStatus && <MDMStatusChip mdmStatus={mdmStatus} />}
            </Flex>
            <Flex vertical={!xl} justifyContent="space-between">
              <Flex maxWidth="70rem" mt="1.8rem" flexWrap="wrap" gap="1.2rem">
                <AssetHeaderItem label="Name" name="name" value={assetName} />
                <AssetHeaderItem
                  label="Asset Type"
                  name="productType"
                  value={productType.id}
                  formattedValue={getProductTypeLabel(productType.name)}
                  fieldProps={{
                    fieldType: AssetFieldTypes.Select,
                    options: productTypeOptions,
                    isLoading: isFetchingProductTypes,
                    placeholder: 'Select an asset type',
                  }}
                />
                <AssetHeaderItem
                  label="Assigned to"
                  name="assignedTo"
                  value={assignedTo}
                  fieldProps={{
                    fieldType: AssetFieldTypes.Select,
                    options: employeeOptions,
                    isLoading: isFetchingEmployees,
                    placeholder: 'Select an employee',
                    isDisabled: !canUpdateAssetAssigneeAndSite,
                  }}>
                  <Flex alignItems="center">
                    <EmployeeOffboardedWarningIcon
                      employeeId={assignedToEmployeeId}
                    />
                    <MaybeLink
                      url={`/employees/${assignedToEmployeeId}`}
                      value={assignedTo}
                      emptyValue="Unassigned"
                    />
                  </Flex>
                </AssetHeaderItem>
                <AssetHeaderItem
                  label="Purchase Date"
                  name="purchaseDate"
                  value={purchaseDate}
                  formattedValue={formatDate(purchaseDate)}
                  fieldProps={{
                    fieldType: AssetFieldTypes.DatePicker,
                  }}
                />
                <AssetHeaderItem
                  label="Purchase Price"
                  name="purchasePrice"
                  value={purchasePrice}
                  formattedValue={formatCurrency(purchasePrice)}
                />
                <AssetHeaderItem
                  label="Purchased From"
                  name="purchasedFrom"
                  value={purchasedFrom}
                  url={purchasedFrom}
                  formattedValue={getDomainFromUrl(purchasedFrom)}
                  fieldProps={{
                    placeholder: 'Enter asset purchase URL',
                  }}
                />
                <AssetHeaderItem label="Brand" name="brand" value={brand} />
                <AssetHeaderItem label="Model" name="model" value={model} />
                <AssetHeaderItem
                  label="Serial"
                  name="serialNumber"
                  value={serialNumber}
                />
                {/* Show the site and location items when the map is not displayed or it shows the employee address. */}
                {(isMapError || isEmployeeAddress) && site?.name && (
                  <>
                    <AssetHeaderItem
                      label="Site"
                      name="site"
                      value={site?.name}
                      readonly
                    />
                    {canReadAssetLocation && (
                      <AssetHeaderItem
                        label="Location"
                        name="location"
                        value={assetLocation?.name}
                        readonly
                      />
                    )}
                  </>
                )}
                {isEditing && (
                  <Flex
                    grow={1}
                    columnGap="1.2rem"
                    alignItems={md ? 'center' : 'flex-start'}
                    width="100%"
                    vertical={!md}>
                    <Flex width={lg ? '66%' : '100%'}>
                      <AssetFormField
                        label="Site"
                        name="site"
                        fieldType={AssetFieldTypes.Select}
                        options={siteOptions}
                        isDisabled={!canUpdateAssetAssigneeAndSite}
                        isLoading={isFetchingSites}
                        placeholder="Select a site"
                      />
                    </Flex>
                    <Flex grow={1} shrink={0}>
                      <Button
                        id="button-asset-details-add-site"
                        variant="outline"
                        iconBefore="add-circle"
                        onClick={() =>
                          openModal(
                            <SiteModal hideModal={() => closeModal()} />
                          )
                        }
                        mb="0.4rem">
                        Add site
                      </Button>
                    </Flex>
                  </Flex>
                )}
                {isEditing && canUpdateAssetLocation && (
                  <Flex
                    grow={1}
                    columnGap="1.2rem"
                    alignItems={md ? 'center' : 'flex-start'}
                    width="100%"
                    vertical={!md}>
                    <Flex width={lg ? '66%' : '100%'}>
                      <AssetFormField
                        label="Location"
                        name="location"
                        fieldType={AssetFieldTypes.Select}
                        options={locationOptions}
                        isLoading={isFetchingLocations}
                        placeholder="Select a location"
                        isClearable={true}
                      />
                    </Flex>
                  </Flex>
                )}
                {isEditing && (
                  <>
                    <Flex
                      columnGap="1.6rem"
                      width={md ? '22rem' : '20rem'}
                      grow={1}>
                      <AssetFormField
                        name="customAssetId"
                        fieldType={AssetFieldTypes.TextInput}
                        label="Asset ID"
                      />
                    </Flex>
                    <Flex
                      columnGap="1.6rem"
                      width={md ? '22rem' : '20rem'}
                      grow={1}>
                      <AssetFormField
                        name="status"
                        fieldType={AssetFieldTypes.Select}
                        label="Status"
                        options={assetStatusOptions}
                      />
                    </Flex>
                    <Flex
                      columnGap="1.6rem"
                      width={md ? '22rem' : '20rem'}
                      grow={1}>
                      <AssetFormField
                        name="condition"
                        fieldType={AssetFieldTypes.Select}
                        label="Condition"
                        options={assetConditionOptions}
                      />
                    </Flex>
                    <Flex columnGap="1.6rem" width={md ? '44rem' : '40rem'}>
                      <AssetHeaderItem
                        label="Warranty Expiration"
                        name="warrantyExpirationDate"
                        value={warrantyExpirationDate}
                        formattedValue={formatDate(warrantyExpirationDate)}
                        fieldProps={{
                          fieldType: AssetFieldTypes.DatePicker,
                          isClearable: true,
                        }}
                      />
                    </Flex>
                    <Flex columnGap="1.6rem" width={md ? '44rem' : '40rem'}>
                      <AssetFormField
                        label="Notes"
                        name="notes"
                        fieldType={AssetFieldTypes.TextArea}
                        placeholder="Additional information about the asset"
                      />
                    </Flex>
                    {canUpdateInternalNotes && (
                      <Flex columnGap="1.6rem" width={md ? '44rem' : '40rem'}>
                        <AssetFormField
                          label="Internal Notes"
                          name="internalNotes"
                          fieldType={AssetFieldTypes.TextArea}
                          placeholder="Internal information about the asset"
                        />
                      </Flex>
                    )}
                  </>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          vertical
          ml={xl ? 0 : !md ? '11.2rem' : '13.8rem'}
          mt={xl ? 0 : '2.4rem'}
          gap="0.4rem">
          {/* Show the map only when the asset has an address (either from the employee it is assigned to or the site where it is located) and display the items below accordingly. */}
          {address && !isEditing && (
            <Map address={address} width="30rem" handleMapError={setIsMapError}>
              {isEmployeeAddress ? (
                <AssetHeaderItem
                  label="Assigned to"
                  name="assignedTo"
                  value={assignedTo}
                  url={`/employees/${assignedToEmployeeId}`}
                  readonly
                />
              ) : (
                <Flex
                  flexWrap="wrap"
                  width="100%"
                  justifyContent="space-between"
                  gap="0.4rem">
                  <AssetHeaderItem
                    containerProps={{ width: 'auto', flexGrow: 0 }}
                    label="Site"
                    name="site"
                    value={site?.name}
                    readonly
                  />
                  {canReadAssetLocation && (
                    <AssetHeaderItem
                      containerProps={{ width: 'auto', flexGrow: 0 }}
                      label="Location"
                      name="location"
                      value={assetLocation?.name}
                      readonly
                    />
                  )}
                </Flex>
              )}
            </Map>
          )}
        </Flex>
      </Flex>
    </StyledHeaderContainer>
  );
};

export default AssetDetailsHeader;

import { AssetDeviceReportingData } from '@/types/assets';
import { Banner, Flex, Text } from '@electricjs/arc';

type IssueCheckDisabledBannerProps = {
  reportingData?: AssetDeviceReportingData;
};

export const IssueCheckDisabledBanner = ({
  reportingData,
}: IssueCheckDisabledBannerProps) => {
  if (!reportingData) {
    return null;
  }

  const issues = reportingData.issues;

  if (!issues) {
    return null;
  }

  // Get disabled reason messages.
  const disabledReasons = Object.values(issues)
    // Filter issues that are disabled.
    .filter(issue => {
      return issue.extra?.disabled;
    })
    // Extract only the reason text.
    .map(issue => issue.extra?.reason);

  if (disabledReasons.length === 0) {
    return null;
  }

  const message =
    disabledReasons.length > 1
      ? // Set generic message when multiple issues disabled
        'This device is running a system version that does not fully support all data reports.'
      : disabledReasons[0];

  return (
    <Flex pt="20px" width="100%">
      <Banner
        id="issue-check-disabled-banner"
        width="100%"
        isFlat
        intent="info"
        title="Device information unavailable"
        dismissible
        message={() => (
          <>
            <Text variant="body-2">{message}</Text>
            <Text variant="body-2">
              Data accuracy may be temporarily affected while we adapt to the
              latest changes.
            </Text>
          </>
        )}
      />
    </Flex>
  );
};

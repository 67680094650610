import styled from 'styled-components';

import NavLogo, { NavLogoProps } from '../Logo';
import { NAV_HEIGHT } from '../sizes';
import { TopNavMenu } from './TopNavMenu';
import {
  Badge,
  Button,
  ColorsV2,
  Flex,
  getToken,
  IconButton,
  IconNames,
  Pane,
  Skeleton,
  Tooltip,
  useBreakpoint,
} from '@electricjs/arc';
import { TopNavMenuItem } from './types';
import { NavLink } from 'react-router-dom';

const StyledTopNav = styled(Pane)`
  z-index: 2;
  height: ${NAV_HEIGHT};
  min-height: ${NAV_HEIGHT};
  padding: ${props => (props.$md ? '0 2.5rem' : '0 1rem')};
  border-radius: unset;
  flex-wrap: nowrap;

  justify-content: ${props =>
    props.$md &&
    !props.$shouldShowLogo &&
    !props.$infoElement &&
    (props.$isNewVersion || !props.$searchElement)
      ? 'flex-end'
      : 'space-between'};

  background: ${getToken(ColorsV2.BACKGROUND)};
  border-bottom: solid 1px ${getToken(ColorsV2.GRAY_LIGHT)};
`;

export type TopNavProps = {
  /** Shows the skeleton loading state if true */
  isNavLoading?: boolean;
  /** Requires a logoElement and logoLinkProps (href & optional target) */
  logoProps: NavLogoProps;
  /** Callback for when you click the mobile menu */
  onMobileMenuClick?: () => void;
  /** Hides the mobile menu icon in the event that you do not have a side nav */
  hideMobileMenu?: boolean;
  /** Top right nav "name", usually the user's name */
  navMenuName: string;
  /** Icon to replace the default building icon */
  navMenuIcon?: IconNames;
  /** Menu items for the top right nav menu */
  menuItems: TopNavMenuItem[];
  /** Hides the knowledge base link */
  hideKnowledgeBaseLink?: boolean;
  /** Custom knowledge base URL */
  knowledgeBaseURL?: string;
  /** Manually show/hide the logo, useful for "full page" views */
  shouldShowLogo?: boolean;
  /** Link for logout button, use either this or the "logoutOnClick" */
  logoutLink?: string;
  /** On click callback for logout button, use either this or the "logoutLink" */
  logoutOnClick?: () => void;
  /** Optional element to display on the left side of the Top Nav */
  infoElement?: JSX.Element;
  /** Optional notifications component to display on the Right side of the Top Nav */
  notificationsElement?: JSX.Element;
  // Optional search component to display on the left side of the Top Nav
  searchElement?: JSX.Element;
  // Optional count of tasks to display on the tasks icon
  taskCount?: number;
  // Optional flag to show the new version, dependent on a FF
  isNewVersion?: boolean;
  // Menu detail section for the new version- usually the organization name and other relevant details
  menuDetailSection?: JSX.Element;
};

const TopNav = ({
  isNavLoading,
  logoProps,
  onMobileMenuClick,
  hideMobileMenu = false,
  navMenuName,
  navMenuIcon = 'building',
  menuItems,
  hideKnowledgeBaseLink = false,
  knowledgeBaseURL,
  shouldShowLogo = false,
  logoutLink,
  logoutOnClick,
  infoElement,
  notificationsElement,
  searchElement,
  taskCount,
  isNewVersion = false,
  menuDetailSection,
}: TopNavProps) => {
  const { md } = useBreakpoint();

  const onKnowledgeBaseClick = () => {
    const url = knowledgeBaseURL ?? 'https://support.electric.ai'; // defaults to main support page

    const newWindow = window.open();
    if (newWindow) {
      newWindow.opener = null; // works like the rel="noreferrer noopener" of the anchor tag
      newWindow.location = url; // loads the page after the "opener" reference is cleared
    }
  };

  const mobileHamburgerMenuAndLogo = !md ? (
    hideMobileMenu ? (
      <NavLogo {...logoProps} />
    ) : (
      <>
        <IconButton
          id="topnav-hamburger-button"
          icon="menu"
          onClick={onMobileMenuClick}
          intent="neutral"
        />
        <NavLogo {...logoProps} />
      </>
    )
  ) : null;

  /* Show basic nav while nav states are loading */
  if (isNavLoading) {
    return (
      <nav aria-label="Admin" style={{ width: '100%' }}>
        <StyledTopNav
          withPadding
          vertical="center"
          id="topnav"
          testId="topnav"
          $md={md}
          $shouldShowLogo={shouldShowLogo}>
          {/* Mobile hamburger menu + logo */}
          {mobileHamburgerMenuAndLogo}
          {/* Logo */}
          {shouldShowLogo && md ? <NavLogo {...logoProps} /> : null}
          <Flex>
            {!hideKnowledgeBaseLink ? (
              <span style={{ marginRight: '0.8rem' }}>
                <Skeleton
                  circle={!md}
                  count={1}
                  width={!md ? '4rem' : '14.5rem'}
                  height={!md ? '4rem' : '3.4rem'}
                />
              </span>
            ) : null}
            <Skeleton
              circle={!md}
              count={1}
              width={!md ? '4rem' : '14.5rem'}
              height={!md ? '4rem' : '3.4rem'}
            />
          </Flex>
        </StyledTopNav>
      </nav>
    );
  }

  return (
    <nav aria-label="Admin" style={{ width: '100%' }}>
      <StyledTopNav
        withPadding
        vertical="center"
        id="topnav"
        testId="topnav"
        $md={md}
        $shouldShowLogo={shouldShowLogo}
        $infoElement={infoElement}
        $searchElement={searchElement}
        $isNewVersion={isNewVersion}>
        {/* Mobile hamburger menu + logo */}
        {mobileHamburgerMenuAndLogo}

        {/* Logo */}
        {shouldShowLogo && md ? <NavLogo {...logoProps} /> : null}

        {/* Right side top nav buttons */}
        {/* New version of the nav */}
        {isNewVersion ? (
          <>
            {/* Info element for site wide warnings - Beta chip, outages, etc */}
            {infoElement ? infoElement : null}

            <Flex
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              maxWidth="24rem">
              {/* Search Icon */}
              <div style={{ marginRight: '1rem' }}>{searchElement}</div>

              {/* Knowledge Base Link */}
              {!hideKnowledgeBaseLink && md ? (
                <Tooltip content="Knowledge Base" placement="bottom">
                  <div style={{ marginRight: '1rem' }}>
                    <IconButton
                      icon="question-circle"
                      onClick={onKnowledgeBaseClick}
                      id="user-menu-knowledge"
                      intent="primary"
                      iconSize="medium"
                    />
                  </div>
                </Tooltip>
              ) : null}

              <Tooltip content="Tasks" placement="bottom">
                <div style={{ marginRight: '1rem', position: 'relative' }}>
                  <NavLink to="/tasks">
                    <IconButton
                      icon="check-circle"
                      id="user-menu-tasks"
                      testId="user-menu-tasks"
                      intent="primary"
                      iconSize="medium"
                    />
                    <Badge
                      size="1.8rem"
                      variant="legal-bold"
                      position="absolute"
                      top="0px"
                      left="2.2rem"
                      count={taskCount ?? 0}
                    />
                  </NavLink>
                </div>
              </Tooltip>

              {/* Notifications */}
              {!!notificationsElement && (
                <Flex shrink={0} mr="1rem">
                  {notificationsElement}
                </Flex>
              )}

              {/* Right-most menu */}
              <TopNavMenu
                md={Boolean(md)}
                navMenuName={navMenuName}
                navMenuIcon={navMenuIcon}
                menuItems={
                  // When in mobile, move the Knowledge Base link to the end of the menu instead of an icon in the top bar.
                  md
                    ? menuItems
                    : [
                        ...menuItems,
                        {
                          text: 'Knowledge Base',
                          icon: 'question-circle',
                          callbackFn: onKnowledgeBaseClick,
                        },
                      ]
                }
                logoutLink={logoutLink}
                logoutOnClick={logoutOnClick}
                isNewVersion={isNewVersion}
                menuDetailSection={menuDetailSection}
              />
            </Flex>
          </>
        ) : (
          /* Old version of the top nav */
          <>
            {/* Info element for site wide warnings - Beta chip, outages, etc */}
            {infoElement ? infoElement : null}

            {/* Search Box */}
            {searchElement && md ? (
              <Flex width="45%" minWidth="30%" shrink={1}>
                {searchElement}
              </Flex>
            ) : null}

            <Flex flexDirection="row" alignItems="center">
              {/* Notifications */}
              {!!notificationsElement && (
                <Flex shrink={0}>{notificationsElement}</Flex>
              )}

              {/* Knowledge Base Link */}
              {/* In mobile, only show the knowledge base icon button */}
              {!hideKnowledgeBaseLink ? (
                md ? (
                  <Flex shrink={0}>
                    <Button
                      id="user-menu-knowledge"
                      onClick={onKnowledgeBaseClick}
                      variant="text"
                      iconBefore="question-circle">
                      Knowledge Base
                    </Button>
                  </Flex>
                ) : (
                  <div style={{ marginRight: '1rem' }}>
                    <IconButton
                      icon="question-circle"
                      onClick={onKnowledgeBaseClick}
                      id="user-menu-knowledge"
                      intent="primary"
                    />
                  </div>
                )
              ) : null}

              {/* Right-most menu */}
              <TopNavMenu
                md={Boolean(md)}
                navMenuName={navMenuName}
                navMenuIcon={navMenuIcon}
                menuItems={menuItems}
                logoutLink={logoutLink}
                logoutOnClick={logoutOnClick}
              />
            </Flex>
          </>
        )}
      </StyledTopNav>
    </nav>
  );
};

export { TopNav };

import * as yup from 'yup';

import { requiredAddressValidationSchema } from '@electricjs/arc';

import { phoneValidationSchema } from '@common';
import { emailRegex } from '@/constants/email';

export const deviceShippingSchema = yup.object().shape({
  receiverSite: yup
    .object()
    .shape({
      name: yup.string().required(),
      value: yup.string().required(),
    })
    .required('Site is required when shipping the device'),
  senderAddress: requiredAddressValidationSchema,
  personalEmail: yup.string().matches(emailRegex, 'Invalid email address'),
  senderPhone: phoneValidationSchema({ required: true }),
  receiverPhone: phoneValidationSchema({ required: true }),
});

import {
  useThemeColors,
  ColorsV2,
  IconInCircle,
  Flex,
  Text,
} from '@electricjs/arc';

export const RequestCompleted = () => {
  const [iconColor, iconBackground] = useThemeColors([
    ColorsV2.WHITE,
    ColorsV2.SUCCESS,
  ]);

  return (
    <Flex
      alignItems="center"
      width="10.8rem"
      justifyContent="center"
      alignSelf="center">
      <IconInCircle
        m="0.2rem"
        size="1.4rem"
        color={iconColor}
        background={iconBackground}
        icon="check"
        iconSize="xsmall"
      />
      <Text color={iconBackground} variant="legal" ml="2">
        Done
      </Text>
    </Flex>
  );
};

import { NotificationType } from '@electricjs/core_entity-client';
import styled from 'styled-components';

import { Divider } from '@electricjs/arc';

import { Notification } from '@/types/notifications';

import ApplicationRequestNotificationItem, {
  ApplicationRequestActionsColumn,
  ApplicationRequestDetailsColumns,
} from './ItemsComponents/ApplicationRequestNotificationItem';
import MdmNotificationItem, {
  MdmActionButton,
} from './ItemsComponents/MdmNotificationItem';
import XboardingCompleteNotificationItem, {
  XboardingCompleteAction,
  XboardingDetailsColumn,
} from './ItemsComponents/XboardingCompleteNotificationItem';
import { NotificationItemProps } from './types';
import RequestNotificationItem, {
  RequestActionsColumn,
  RequestDetailsColumns,
} from './ItemsComponents/RequestNotificationItem';
import {
  BreachActionButton,
  BreachDetailsColumn,
  BreachNotificationItem,
} from './ItemsComponents/BreachNotificationItem';
import MdmCertificateAboutToExpireNotificationItem from './ItemsComponents/MDMCertiifcateAboutToExpireNotificationItem';
import { RenewMdmCertButton } from '@/components/Tasks/Actions/RenewMdmCertButton';
import MdmCertificateHasExpiredNotificationItem from './ItemsComponents/MDMCertificateHasExpiredNotificationItem';

const ItemStyled = styled.li`
  display: list-item;
  min-height: 5.5rem;
  margin-top: 1rem;
`;

type NotificationItemComponent = {
  ItemComponent:
    | typeof XboardingCompleteNotificationItem
    | typeof ApplicationRequestNotificationItem
    | typeof MdmNotificationItem
    | typeof BreachNotificationItem
    | typeof MdmCertificateAboutToExpireNotificationItem
    | typeof MdmCertificateHasExpiredNotificationItem;
  ActionColumn:
    | typeof XboardingCompleteAction
    | typeof ApplicationRequestActionsColumn
    | typeof MdmActionButton
    | typeof BreachActionButton
    | typeof RenewMdmCertButton;
  DetailsColumn:
    | typeof XboardingDetailsColumn
    | typeof ApplicationRequestDetailsColumns
    | typeof BreachDetailsColumn
    | null;
};
export const NOTIFICATION_COMPONENTS_MAP: {
  [key: string]: NotificationItemComponent;
} = {
  [NotificationType.OnboardingCompleted]: {
    ItemComponent: XboardingCompleteNotificationItem,
    ActionColumn: XboardingCompleteAction,
    DetailsColumn: XboardingDetailsColumn,
  },
  [NotificationType.OffboardingCompleted]: {
    ItemComponent: XboardingCompleteNotificationItem,
    ActionColumn: XboardingCompleteAction,
    DetailsColumn: XboardingDetailsColumn,
  },
  [NotificationType.RequestCompleted]: {
    ItemComponent: RequestNotificationItem,
    ActionColumn: RequestActionsColumn,
    DetailsColumn: RequestDetailsColumns,
  },
  [NotificationType.RequestAssigned]: {
    ItemComponent: RequestNotificationItem,
    ActionColumn: RequestActionsColumn,
    DetailsColumn: RequestDetailsColumns,
  },
  [NotificationType.RequestAutomationFailed]: {
    ItemComponent: RequestNotificationItem,
    ActionColumn: RequestActionsColumn,
    DetailsColumn: RequestDetailsColumns,
  },
  [NotificationType.MdmCertAboutToExpire]: {
    ItemComponent: MdmCertificateAboutToExpireNotificationItem,
    ActionColumn: RenewMdmCertButton,
    DetailsColumn: null,
  },
  [NotificationType.MdmCertExpired]: {
    ItemComponent: MdmCertificateHasExpiredNotificationItem,
    ActionColumn: RenewMdmCertButton,
    DetailsColumn: null,
  },
  [NotificationType.MdmSetupCompleted]: {
    ItemComponent: MdmNotificationItem,
    ActionColumn: MdmActionButton,
    DetailsColumn: null,
  },
  [NotificationType.DataBreach]: {
    ItemComponent: BreachNotificationItem,
    ActionColumn: BreachActionButton,
    DetailsColumn: BreachDetailsColumn,
  },
};

const isNotificationTypeSupported = (notification: Notification) => {
  const isTypeSupported = notification.type in NOTIFICATION_COMPONENTS_MAP;
  if (!isTypeSupported) {
    window?.DD_RUM?.addError(
      `Notification: ${notification.id} has unsupported type: ${notification.type}`,
      {
        location: 'Render notifications',
      }
    );
  }

  return isTypeSupported;
};

export const getSupportedNotifications = (notifications: Notification[]) => {
  return notifications.filter(isNotificationTypeSupported);
};

export const NotificationItem = ({ notification }: NotificationItemProps) => {
  const { ItemComponent } = NOTIFICATION_COMPONENTS_MAP[notification.type];
  return (
    <>
      <ItemStyled>
        <ItemComponent notification={notification} />
      </ItemStyled>
      <Divider />
    </>
  );
};

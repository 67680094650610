import { Asset } from '@/types/assets';
import { Employee } from '@/types/employees';

export const EnrollmentReminderReason = {
  ELECTRIC_NOT_INSTALLED: 'Electric not installed',
  ELECTRIC_INSTALLATION_ISSUE: 'Electric installation issue',
  MDM_NOT_INSTALLED: 'MDM not installed',
  NO_DEVICE_ASSIGNED: 'No device assigned',
} as const;

export type EnrollmentReminderReason =
  (typeof EnrollmentReminderReason)[keyof typeof EnrollmentReminderReason];

export type EmployeeWithReminderReason = {
  reminderReason: EnrollmentReminderReason;
  serialIfMultipleDevices?: string;
  asset?: Asset;
} & Employee;

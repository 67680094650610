import '@stripe/stripe-js'; // Adds Stripe to the global scope so it's fraud detection can work
import { Auth0Provider } from '@auth0/auth0-react';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';

import { BreakpointProvider, GlobalStyles, log } from '@electricjs/arc';

import { StorefrontProvider } from '@/components/Storefront';
import AuthenticatedApp from '@/AuthenticatedApp';
import { GlobalUIProvider } from '@/components/GlobalUIProvider';
import QUERIES from '@/constants/queries';
import { worker } from '@/mocks/browser';
import { store } from '@/redux/store';
import { initDatadog } from '@/services/datadog';

import { IntakeFormProvider } from './components/IntakeFormProvider';
import { ThemeProvider } from './components/ThemeProvider';
import { TicketFlowProvider } from './components/TicketFlowContext';
import { startFetchIntervals } from './helpers/fetchAppVersion';
import stripe from './services/stripe';

/**
 * Development mode with mock service
 *   - When running in development mode with mock enabled (`npm run dev:mock`)
 *   - The webworker will intercept all API requests and return the mock result if there is a handler for that request
 *   - Requests without handler will be automatically bypassed to the real backend
 */
if (import.meta.env.DEV && import.meta.env.VITE_MOCK === 'true') {
  worker.start({ onUnhandledRequest: 'bypass' });
}

if (
  window._env_?.VITE_DATADOG_APPLICATION_ID &&
  window._env_?.VITE_DATADOG_CLIENT_TOKEN
) {
  initDatadog({
    applicationId: window._env_.VITE_DATADOG_APPLICATION_ID,
    clientToken: window._env_.VITE_DATADOG_CLIENT_TOKEN,
    version: window._env_?.VITE_APP_VERSION,
    env: window._env_?.VITE_ENV_NAME || 'local',
  });
} else {
  console.warn('DataDog not initialized, missing environment variables');
}
/** Fetch App Version Interval Setup */
startFetchIntervals();

try {
  const stripeService = await stripe;
  if (!stripeService) {
    log.warn({
      origin: 'main.tsx',
      message: 'Stripe not initialized, missing public key',
      appName: 'IT Hub',
    });
  }
} catch (error) {
  console.error('Stripe initialization failed', error);
  window.DD_RUM?.addError(error, {
    location: 'main.tsx',
  });
}

ReactDOM.render(
  <StrictMode>
    <Auth0Provider
      clientId={window._env_?.VITE_AUTH0_CLIENT_ID}
      domain={window._env_?.VITE_AUTH0_DOMAIN}
      authorizationParams={{
        redirect_uri: window._env_?.VITE_AUTH0_CALLBACK_URL,
        audience: window._env_?.VITE_AUTH0_AUDIENCE,
        scope:
          'openid email profile read:current_user update:current_user_identities',
        // We use this to pass the redirect URL to Auth0 for Turbine users
        custom_redirect: `${window.location.pathname}${window.location.search}`,
      }}
      skipRedirectCallback={window.location.pathname.includes(
        '/integrations/oauth/callback'
      )}
      cacheLocation="localstorage"
      useRefreshTokens={true}>
      <LDProvider clientSideID={window._env_?.VITE_LAUNCH_DARKLY_CLIENTSIDE_ID}>
        <BreakpointProvider queries={QUERIES}>
          <GlobalStyles />
          <ThemeProvider>
            <IntakeFormProvider>
              <StorefrontProvider>
                <TicketFlowProvider>
                  {/* Authenticated Store & app */}
                  <ReduxProvider store={store}>
                    <GlobalUIProvider>
                      <AuthenticatedApp />
                    </GlobalUIProvider>
                  </ReduxProvider>
                  {/**
                   * When we build an unauthenticated app, we will need some logic to determine which app to route to.
                   * All of the Auth0 logic currently lives in the `AuthenticatedApp`, so we will need to move that
                   * logic to this file in order to determine if the user is authenticated before deciding which app to route to.
                   *
                   * Example: `isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />`
                   *
                   * An alternative would be to initially always route to the `UnauthenticatedApp` and then redirect
                   * to the `AuthenticatedApp` if the user is authenticated, in which case we would need to move the
                   * Auth0 logic to the `UnauthenticatedApp`.
                   *
                   * The `UnauthenticatedApp` will not contain a store, as it is not needed for unauthenticated users.
                   * If we need to use GlobalUIProvider for unauthenticated users, we will need to duplicate it
                   * to surround the `UnauthenticatedApp`. All of the other providers will be available to both apps.
                   *
                   * We should consider if the storefront and ticketflow providers are needed for unauthenticated users,
                   * and if not we should move them to only surround the `AuthenticatedApp`.
                   */}
                </TicketFlowProvider>
              </StorefrontProvider>
            </IntakeFormProvider>
          </ThemeProvider>
        </BreakpointProvider>
      </LDProvider>
    </Auth0Provider>
  </StrictMode>,
  document.getElementById('root') as HTMLElement
);

import type { MouseEvent } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Card, Flex, Button } from '@electricjs/arc';

import { Asset } from '@/types/assets';
import { SelectionParamKeys } from '@/types/queryParamKeys';
import { Store, Product } from '@/types/storefront';

import { buildSpecs } from '../utils';
import { ProductDescription } from './Catalog/ProductDescription';
import AssetDescription from './Asset/AssetDescription';

type CatalogCard = {
  store: Store.Catalog;
  item: Product;
};

type AssetCard = {
  store: Store.Asset;
  item: Asset;
};

type ItemCardProps = (CatalogCard | AssetCard) & {
  selected: boolean;
  onAddItem: () => void;
  onRemoveItem: () => void;
};

export const ItemCard = ({
  store,
  item,
  selected,
  onAddItem,
  onRemoveItem,
}: ItemCardProps) => {
  const [queryParams, writeQueryParams] = useSearchParams();

  const openItemDetailsModal = () => {
    // just need to set a query param, because the application will automatically open the modal when the param (productId or AssetId) exists in the URL
    if (store === Store.Catalog) {
      queryParams.set(SelectionParamKeys.ProductId, item.id);
    } else if (store === Store.Asset) {
      queryParams.set(SelectionParamKeys.AssetId, item.id);
    }

    writeQueryParams(queryParams, {
      // Each change in the query params will replace the current history state, meaning the "back button" will go back to the previous page.
      replace: true,
    });
  };

  return (
    <Card
      id="storefront-item-card"
      width="31rem"
      cardPadding="2.4rem 2.4rem 3.8rem 2.4rem"
      onClick={openItemDetailsModal}
      selected={selected}>
      {store === Store.Catalog && (
        <ProductDescription
          image={item.image}
          price={item.price}
          name={item.name}
          specs={buildSpecs(item)}
          withPriceLabel
          showImage
        />
      )}
      {store === Store.Asset && (
        <AssetDescription
          customAssetId={item.customAssetId}
          name={item.name}
          brand={item.brand}
          condition={item.condition}
          model={item.model}
          serialNumber={item.serialNumber}
          site={item.site}
        />
      )}
      <Flex hAlignContent="center">
        {!selected ? (
          <Button
            id="storefront-add-to-cart-button"
            onClick={(event: MouseEvent<HTMLButtonElement>) => {
              // To avoid opening the modal
              event.stopPropagation();
              onAddItem();
            }}
            py="1.4rem"
            px="2rem"
            testId="add-button">
            Add to cart
          </Button>
        ) : (
          <Button
            id="storefront-remove-from-cart-button"
            onClick={(event: MouseEvent<HTMLButtonElement>) => {
              // To avoid opening the modal
              event.stopPropagation();
              onRemoveItem();
            }}
            py="1.4rem"
            px="2rem"
            testId="remove-button"
            variant="outline"
            iconAfter="trashcan">
            Remove
          </Button>
        )}
      </Flex>
    </Card>
  );
};

import { AdditionalInfoEmailProviderEnum } from '@electricjs/core_entity-client/models/additional-info-email-provider-enum';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  Banner,
  Box,
  Button,
  ColorsV2,
  Divider,
  Flex,
  Pane,
  Select,
  TabPanel,
  Text,
  TextInput,
  getToken,
  useThemeColors,
} from '@electricjs/arc';

import { useGlobalUI } from '@/components/GlobalUIProvider';
import { useAddEmailProviderAsOrgApp } from '@/hooks/useAddEmailProviderAsOrgApp';
import { useSendAddonInterestEmailMutation } from '@/redux/slices/addonApiSlice';
import {
  useGetOrganizationAdditionalInfoQuery,
  useUpsertOrganizationAdditionalInfoMutation,
} from '@/redux/slices/organizationApiSlice';
import { CopyButton } from '@common';

const StyledPane = styled(Pane)`
  border: 1px solid ${getToken(ColorsV2.BORDER)};
  flex-direction: column;
`;

type EmailProviderOption = {
  value: AdditionalInfoEmailProviderEnum;
  label: string;
};

const SupportCenterPane = ({ organizationId }: { organizationId: string }) => {
  const [borderColor] = useThemeColors([ColorsV2.BORDER]);
  const { showSuccessToast, showErrorToast } = useGlobalUI();

  const { data: organizationAdditionalInfoResponse, isLoading } =
    useGetOrganizationAdditionalInfoQuery({ organizationId });

  const [upsertOrganizationAdditionalInfo] =
    useUpsertOrganizationAdditionalInfoMutation();

  const [sendAddonInterestEmail, { isLoading: isSendingEmail }] =
    useSendAddonInterestEmailMutation();

  const currentEmailProvider =
    organizationAdditionalInfoResponse?.emailProvider;

  const [emailProvider, setEmailProvider] = useState(currentEmailProvider);
  const [addSelectedEmailProvider] = useAddEmailProviderAsOrgApp();

  const [isWarningBannerVisible, setIsWarningBannerVisible] = useState(
    currentEmailProvider === AdditionalInfoEmailProviderEnum.Other
  );

  const handleSelectedEmailProvider = (
    selectedEmailProvider: AdditionalInfoEmailProviderEnum
  ) => {
    setEmailProvider(selectedEmailProvider);
    if (selectedEmailProvider === AdditionalInfoEmailProviderEnum.Other) {
      setIsWarningBannerVisible(true);
    } else {
      setIsWarningBannerVisible(false);
    }
  };

  // Set the initial state of the email provider and warning banner visibility while the response is being fetched
  useEffect(() => {
    if (!isLoading && currentEmailProvider) {
      handleSelectedEmailProvider(currentEmailProvider);
    }
  }, [isLoading, currentEmailProvider]);

  const emailProviderOptions: EmailProviderOption[] = [
    { value: 'google-workspace', label: 'Google' },
    { value: 'microsoft-365', label: 'Microsoft' },
    { value: 'other', label: 'Other' },
  ];

  const handleOnChange = (option: EmailProviderOption) => {
    handleSelectedEmailProvider(option.value);
  };

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleSaveInfo = () => {
    if (emailProvider && emailProvider !== currentEmailProvider) {
      setIsSaving(true);
      upsertOrganizationAdditionalInfo({
        organizationId,
        emailProvider,
      })
        .unwrap()
        .then(() => {
          showSuccessToast({
            id: 'save-company-profile-success-toast',
            message: 'Company profile updated successfully',
          });
          addSelectedEmailProvider(emailProvider);
        })
        .catch(() =>
          showErrorToast({
            id: 'save-company-profile-error-toast',
            message: 'Failed to update company profile',
          })
        )
        .finally(() => {
          setIsSaving(false);
        });
    }
  };

  const handleSendEmail = () => {
    sendAddonInterestEmail({
      organizationId,
      addOn: 'Email Migration to MSFT365',
    })
      .unwrap()
      .then(() => {
        showSuccessToast({
          id: 'email-migration-msft365-success-toast',
          title: 'Thanks!',
          message: "We'll be getting back to you!",
        });
      })
      .catch(() => {
        showErrorToast({
          id: 'email-migration-msft365-error-toast',
          title: 'Sorry, something went wrong.',
          message: 'Please try again.',
        });
      });
  };

  return (
    <TabPanel>
      <StyledPane width="100%">
        <Flex vertical rowGap="0.5rem" p="2.5rem">
          <Text variant="heading-2">Company profile</Text>
        </Flex>
        <Divider color={borderColor} />
        <Flex
          pt="3rem"
          pl="3rem"
          pr="3rem"
          justifyContent="space-between"
          width="100%">
          <Box width="40%">
            <Text variant="heading-3" marginBottom={2}>
              Customer ID
            </Text>
            <Text variant="body">
              Your Electric identifier code used to connect with different
              systems or applications.
            </Text>
          </Box>
          <Flex marginLeft={20} width="50%">
            <Flex width="100%" marginBottom={6}>
              <Flex vertical grow>
                <Box marginBottom={1}>
                  <Text variant="label-input">Customer ID</Text>
                </Box>
                <Box width="100%" marginBottom={2}>
                  <TextInput
                    id="integrationOrganizationId"
                    name="integrationOrganizationId"
                    value={organizationId}
                    disabled={true}
                  />
                </Box>
              </Flex>
              <Flex shrink={1}>
                <Box marginTop="2.0rem" marginLeft={1}>
                  <CopyButton
                    id="button-copy-integration-organization-id"
                    textToCopy={organizationId}>
                    Copy
                  </CopyButton>
                </Box>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          pt="3rem"
          pl="3rem"
          pr="3rem"
          justifyContent="space-between"
          width="100%">
          <Box width="40%">
            <Text variant="heading-3" marginBottom={2}>
              Email provider
            </Text>
            <Text variant="body">
              Select the email provider used for work communication in your
              company.
            </Text>
          </Box>
          <Flex vertical marginLeft={20} width="50%">
            <Flex vertical width="100%" marginBottom={6}>
              <Box marginBottom={1}>
                <Text variant="label-input">Email provider</Text>
              </Box>
              <Box width="100%" marginBottom={2}>
                <Select
                  id="select-email-provider"
                  options={emailProviderOptions}
                  value={emailProviderOptions.find(
                    option => option.value === emailProvider
                  )}
                  onChange={handleOnChange}
                />
              </Box>
              {isWarningBannerVisible && (
                <Flex pt="1rem">
                  <Banner
                    id="other-email-provider-warning-banner"
                    title="Consider upgrading to Microsoft for the best IT experience"
                    message="Electric's team can help you migrate to Microsoft 365 from a legacy or on-prem email instance."
                    intent="warning"
                    cta={
                      <Flex pt="1rem">
                        <Button
                          id="contact-electric-button"
                          intent="warning"
                          variant="outline"
                          color={ColorsV2.TEXT}
                          loading={isSendingEmail}
                          onClick={() => {
                            handleSendEmail();
                          }}>
                          Contact Electric
                        </Button>
                      </Flex>
                    }
                    onClickDismiss={() => {
                      setIsWarningBannerVisible(false);
                    }}
                    dismissible={true}
                    isFlat></Banner>
                </Flex>
              )}
              <Flex p="18px 14px" alignSelf="flex-end">
                <Button
                  id="button-save-company-profile"
                  onClick={handleSaveInfo}
                  disabled={isSaving}>
                  Save information
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </StyledPane>
    </TabPanel>
  );
};

export default SupportCenterPane;

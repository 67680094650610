import {
  Button,
  ColorsV2,
  Flex,
  IconInCircle,
  Pane,
  Text,
} from '@electricjs/arc';

import { ExternalLink } from '@common';

type ABMTileProps = {
  onConnectAbm: () => void;
};

const ABMTile = ({ onConnectAbm }: ABMTileProps) => {
  return (
    <Pane elevated>
      <Flex vertical hAlignContent="center" rowGap="2rem" p="4rem">
        <IconInCircle
          intent="primary"
          background={ColorsV2.PRIMARY_LIGHTEST}
          icon="logo-apple"
          iconSize="large"
          size="6rem"
        />
        <Text variant="heading-2" textAlign="center">
          Apple Business Manager (ABM)
        </Text>
        <Text textAlign="center">
          Integrate your Apple Business Manager (ABM) account seamlessly with
          Electric! Whether you&apos;re planning to purchase new Apple devices
          or already have them in your inventory, linking your free ABM account
          allows for effortless streamlining of Apple device management.
        </Text>
        <Flex gap="2rem" alignItems="center" justifyContent="center">
          <ExternalLink
            id="abm-learn-more-apple-link"
            href="https://support.apple.com/guide/apple-business-manager/manage-device-suppliers-axmef1c47493/web">
            Learn more
          </ExternalLink>
          <Button
            buttonSize="small"
            id="connect-abm-button"
            intent="primary"
            variant="fill"
            p="1rem 2rem"
            onClick={onConnectAbm}>
            Connect ABM
          </Button>
        </Flex>
      </Flex>
    </Pane>
  );
};

export default ABMTile;

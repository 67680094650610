import { userHandlers } from '@/mocks/handlers/userHandlers';

import { applicationHandlers } from './applicationHandlers';
import { billingHandlers } from './billingHandlers';
import { employeeHandlers } from './employeeHandlers';
import { groupHandlers } from './groupHandlers';
import { hrisIntegrationHandlers } from './hrisIntegrationHandlers';
import { notificationsHandlers } from './notificationsHandlers';
import { onboardingHandlers } from './onboardingHandlers';
import { organizationApplicationChampionHandlers } from './organizationApplicationChampionHandlers';
import { organizationApplicationHandlers } from './organizationApplicationHandlers';
import { organizationHandlers } from './organizationHandlers';
import { packageHandlers } from './packageHandlers';
import { orderHandlers } from './orderHandlers';
import { requestsHandlers } from './requestsHandlers';
import { localhostHandlers } from './localhost';
import { productHandlers } from './productHandlers';

export const handlers = [
  ...userHandlers,
  ...organizationHandlers,
  ...billingHandlers,
  ...groupHandlers,
  ...employeeHandlers,
  ...applicationHandlers,
  ...organizationApplicationHandlers,
  ...organizationApplicationChampionHandlers,
  ...notificationsHandlers,
  ...hrisIntegrationHandlers,
  ...requestsHandlers,
  ...onboardingHandlers,
  ...packageHandlers,
  ...orderHandlers,
  ...localhostHandlers,
  ...productHandlers,
];

import {
  IntegrationAvailabilityEnum,
  OrganizationApplication as OrganizationApplicationDto,
} from '@electricjs/core_entity-client';

import { simpleOrganization } from '@/mocks/data/organizationData';

export const organizationApplicationsList: OrganizationApplicationDto[] = [
  {
    id: '5ea82950-9a81-4e19-92d8-3f2ec4ced28a',
    organization: simpleOrganization,
    created_at: '2021-10-01T00:00:00.000Z',
    updated_at: '2021-10-01T00:00:00.000Z',
    application: {
      id: '002df5c0-054f-11ee-be56-0242ac120003',
      name: '99 Designs',
      slug: '99_Designs',
      icon: 'https://software-applications-logos.s3.amazonaws.com/supported-applications/99-Designs.png',
      url: 'https://en.99designs.com.br/',
      integration_availability: 'not_available',
    },
    onboarding_automation_on: true,
    offboarding_automation_on: true,
    integration_connected: false,
    disable_create_account_requests: false,
    disable_delete_account_requests: false,
  },
  {
    id: '5ea82950-9a81-4e19-92d8-3f2ec4ced28a',
    organization: simpleOrganization,
    created_at: '2021-10-01T00:00:00.000Z',
    updated_at: '2021-10-01T00:00:00.000Z',
    application: {
      id: '002df5c0-054f-11ee-be56-0242ac120003',
      name: '99 Designs',
      slug: '99_Designs',
      icon: 'https://software-applications-logos.s3.amazonaws.com/supported-applications/99-Designs.png',
      url: 'https://en.99designs.com.br/',
      integration_availability: 'not_available',
    },
    onboarding_automation_on: false,
    offboarding_automation_on: false,
    integration_connected: false,
    disable_create_account_requests: false,
    disable_delete_account_requests: false,
  },
  {
    id: '5ea82950-9a81-4e19-92d8-3f2ec4ced28a',
    organization: simpleOrganization,
    created_at: '2021-10-01T00:00:00.000Z',
    updated_at: '2021-10-01T00:00:00.000Z',
    application: {
      id: '002df5c0-054f-11ee-be56-0242ac120003',
      name: '99 Designs',
      slug: '99_Designs',
      icon: 'https://software-applications-logos.s3.amazonaws.com/supported-applications/99-Designs.png',
      url: 'https://en.99designs.com.br/',
      integration_availability: 'not_available',
    },
    onboarding_automation_on: false,
    offboarding_automation_on: false,
    integration_connected: false,
    disable_create_account_requests: false,
    disable_delete_account_requests: false,
  },
  {
    id: '5ea82950-9a81-4e19-92d8-3f2ec4ced28a',
    organization: simpleOrganization,
    created_at: '2021-10-01T00:00:00.000Z',
    updated_at: '2021-10-01T00:00:00.000Z',
    application: {
      id: '002df5c0-054f-11ee-be56-0242ac120003',
      name: '99 Designs',
      slug: '99_Designs',
      icon: 'https://software-applications-logos.s3.amazonaws.com/supported-applications/99-Designs.png',
      url: 'https://en.99designs.com.br/',
      integration_availability: 'not_available',
    },
    onboarding_automation_on: false,
    offboarding_automation_on: false,
    integration_connected: false,
    disable_create_account_requests: false,
    disable_delete_account_requests: false,
  },
  {
    id: 'a8cd1c0f-42f3-4a80-9fb3-82b31f1af76a',
    organization: simpleOrganization,
    created_at: '2021-10-01T00:00:00.000Z',
    updated_at: '2021-10-01T00:00:00.000Z',
    application: {
      id: '13e20646-1322-4493-8417-8993e77332cc',
      name: 'Teams',
      slug: 'teams',
      icon: 'https://logo.clearbit.com/teams.microsoft.com',
      url: 'https://teams.microsoft.com',
      integration_availability: 'not_available',
    },
    onboarding_automation_on: false,
    offboarding_automation_on: false,
    integration_connected: false,
    disable_create_account_requests: false,
    disable_delete_account_requests: false,
  },
  {
    id: 'a8cd1c0f-42f3-4a80-9fb3-82b31f1af76a',
    organization: simpleOrganization,
    created_at: '2021-10-01T00:00:00.000Z',
    updated_at: '2021-10-01T00:00:00.000Z',
    application: {
      id: '13e20646-1322-4493-8417-8993e77332cc',
      name: 'Teams',
      slug: 'teams',
      icon: 'https://logo.clearbit.com/teams.microsoft.com',
      url: 'https://teams.microsoft.com',
      integration_availability: 'not_available',
    },
    onboarding_automation_on: false,
    offboarding_automation_on: false,
    integration_connected: false,
    disable_create_account_requests: false,
    disable_delete_account_requests: false,
  },
  {
    id: 'a8cd1c0f-42f3-4a80-9fb3-82b31f1af76a',
    organization: simpleOrganization,
    created_at: '2021-10-01T00:00:00.000Z',
    updated_at: '2021-10-01T00:00:00.000Z',
    application: {
      id: '13e20646-1322-4493-8417-8993e77332cc',
      name: 'Teams',
      slug: 'teams',
      icon: 'https://logo.clearbit.com/teams.microsoft.com',
      url: 'https://teams.microsoft.com',
      integration_availability: 'not_available',
    },
    onboarding_automation_on: false,
    offboarding_automation_on: false,
    integration_connected: false,
    disable_create_account_requests: false,
    disable_delete_account_requests: false,
  },
  {
    id: 'a8cd1c0f-42f3-4a80-9fb3-82b31f1af76a',
    organization: simpleOrganization,
    created_at: '2021-10-01T00:00:00.000Z',
    updated_at: '2021-10-01T00:00:00.000Z',
    application: {
      id: '13e20646-1322-4493-8417-8993e77332cc',
      name: 'Teams',
      slug: 'teams',
      icon: 'https://logo.clearbit.com/teams.microsoft.com',
      url: 'https://teams.microsoft.com',
      integration_availability: 'not_available',
    },
    onboarding_automation_on: false,
    offboarding_automation_on: false,
    integration_connected: false,
    disable_create_account_requests: false,
    disable_delete_account_requests: false,
  },
  {
    id: 'f2831e4d-e0f3-4118-8bf7-35d2f5af8163',
    organization: simpleOrganization,
    created_at: '2021-10-01T00:00:00.000Z',
    updated_at: '2021-10-01T00:00:00.000Z',
    application: {
      id: 'aaeea7bc-054e-11ee-be56-0242ac120002',
      name: 'Zoom',
      slug: 'zoom',
      icon: 'https://logo.clearbit.com/zoom.us',
      url: 'https://zoom.us',
      integration_availability: 'not_available',
    },
    onboarding_automation_on: false,
    offboarding_automation_on: false,
    integration_connected: false,
    disable_create_account_requests: false,
    disable_delete_account_requests: false,
  },
  {
    id: 'f2831e4d-e0f3-4118-8bf7-35d2f5af8163',
    organization: simpleOrganization,
    created_at: '2021-10-01T00:00:00.000Z',
    updated_at: '2021-10-01T00:00:00.000Z',
    application: {
      id: 'aaeea7bc-054e-11ee-be56-0242ac120002',
      name: 'Zoom',
      slug: 'zoom',
      icon: 'https://logo.clearbit.com/zoom.us',
      url: 'https://zoom.us',
      integration_availability: 'not_available',
    },
    onboarding_automation_on: false,
    offboarding_automation_on: false,
    integration_connected: false,
    disable_create_account_requests: false,
    disable_delete_account_requests: false,
  },
  {
    id: 'f2831e4d-e0f3-4118-8bf7-35d2f5af8163',
    organization: simpleOrganization,
    created_at: '2021-10-01T00:00:00.000Z',
    updated_at: '2021-10-01T00:00:00.000Z',
    application: {
      id: 'aaeea7bc-054e-11ee-be56-0242ac120002',
      name: 'Zoom',
      slug: 'zoom',
      icon: 'https://logo.clearbit.com/zoom.us',
      url: 'https://zoom.us',
      integration_availability: 'not_available',
    },
    onboarding_automation_on: false,
    offboarding_automation_on: false,
    integration_connected: false,
    disable_create_account_requests: false,
    disable_delete_account_requests: false,
  },
  {
    id: 'f2831e4d-e0f3-4118-8bf7-35d2f5af8163',
    organization: simpleOrganization,
    created_at: '2021-10-01T00:00:00.000Z',
    updated_at: '2021-10-01T00:00:00.000Z',
    application: {
      id: 'aaeea7bc-054e-11ee-be56-0242ac120002',
      name: 'Zoom',
      slug: 'zoom',
      icon: 'https://logo.clearbit.com/zoom.us',
      url: 'https://zoom.us',
      integration_availability: 'not_available',
    },
    onboarding_automation_on: false,
    offboarding_automation_on: false,
    integration_connected: false,
    disable_create_account_requests: false,
    disable_delete_account_requests: false,
  },
];

export const createOrganizationApplication = {
  id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  application: {
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    name: 'string',
    slug: 'string',
    icon: 'string',
    url: 'string',
    organization_id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    integration_availability: 'string' as IntegrationAvailabilityEnum,
  },
  organization: {
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    name: 'string',
  },
  url: 'string',
  created_at: '2023-06-09T20:41:08.128Z',
  updated_at: '2023-06-09T20:41:08.128Z',
  onboarding_automation_on: false,
  offboarding_automation_on: false,
  integration_connected: false,
  disable_create_account_requests: false,
  disable_delete_account_requests: false,
};

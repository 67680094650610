import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { StepContainer } from '@/components/MDMEnrollment/MDMAppleForm/StepComponents';
import { Button, Text } from '@electricjs/arc';
import { useLazyGetJumpCloudAppleCsrQuery } from '@/redux/slices/mdmManagementApiSlice';
import { useGlobalUI } from '@/components/GlobalUIProvider';

export const DownloadYourCsrFileStep = () => {
  const { showErrorToast } = useGlobalUI();

  const { showSuccessToast } = useGlobalUI();

  const organizationId = useGetOrganizationId();

  const [downloadCsr, { isLoading }] = useLazyGetJumpCloudAppleCsrQuery();

  const handleDownloadCsr = async () => {
    try {
      await downloadCsr({
        organizationId,
        fileName: 'download-mdm.csr',
      }).unwrap();

      showSuccessToast({
        id: 'download-csr-success-toast',
        message: 'Download Successful! Please check your download folder.',
        stack: true,
      });
    } catch (error) {
      showErrorToast({
        id: 'download-csr-error-toast',
        title: 'Unable to download your CSR',
        message:
          'Something went wrong while downloading your CSR. Please try again.',
      });
      window?.DD_RUM?.addError(
        `Unable to download your CSR ${JSON.stringify(error)}`,
        {
          location: 'DownloadYourCsrFileStep',
        }
      );
    }
  };

  return (
    <StepContainer step={1}>
      <Text variant="label-large">Download your CSR</Text>
      <Text variant="body">
        Use the Certificate Signing Request (CSR) to establish trust between
        Apple and your organization using our MDM credentials. You need this for
        the next step 3.
      </Text>
      <Button
        iconBefore="download"
        variant="outline"
        id="download-csr-button"
        testId="download-csr-button"
        onClick={handleDownloadCsr}
        loading={isLoading}>
        Download CSR
      </Button>
    </StepContainer>
  );
};

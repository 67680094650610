import { ColorsV2, Flex, IconInCircle, Text } from '@electricjs/arc';

export type ActionResultProps = {
  title: string;
};

export const ActionResult = ({ title }: ActionResultProps) => {
  return (
    <Flex vAlignContent="center">
      <Flex>
        <IconInCircle
          iconSize="small"
          size="3rem"
          icon="bolt"
          color={ColorsV2.SECONDARY}
          background={ColorsV2.SECONDARY_LIGHTEST}
        />
      </Flex>
      <Text
        textAlign="left"
        whiteSpace="nowrap"
        ml="2rem"
        variant="label-small">
        {title}
      </Text>
    </Flex>
  );
};

import AssetDetail from '@/components/Assets/AssetDetails/AssetDetail';
import { Asset } from '@/types/assets';
import { Card, Flex, IconInCircle, Text } from '@electricjs/arc';
import { DetailsContainer } from '@/components/Assets/AssetDetails/common';
import { useGetSiteLocationsQuery } from '@/redux/slices/assetApiSlice';
import { assetConditionDisplayNameMap } from '@/components/Assets/helpers/transformOptions';
import formatDate from '@/helpers/formatDate';

const AssetDetailsCard = ({ asset }: { asset: Asset }) => {
  const siteId = asset?.site?.id ?? '';

  // We may want to replace this with a dedicated endpoint to get a location by its id
  const { locationName } = useGetSiteLocationsQuery(siteId, {
    skip: !siteId,
    selectFromResult: ({ data }) => ({
      locationName: data?.find(location => location.id === asset?.locationId)
        ?.name,
    }),
  });
  const assetCondition = asset?.condition
    ? assetConditionDisplayNameMap[asset?.condition]
    : 'N/A';

  const hardwareModel = asset.device?.hardwareModel || asset.model;

  return (
    <Card
      id="asset-details-card"
      width="100%"
      height="auto"
      headerDivider
      title={
        <Flex alignItems="center" gap="2rem">
          <IconInCircle icon="toolbox" intent="info" />
          <Text variant="subheading">Asset information</Text>
        </Flex>
      }>
      <DetailsContainer mt="2.2rem" mb="1rem">
        <AssetDetail label="Asset ID" value={asset?.customAssetId || 'N/A'} />
        {hardwareModel && (
          <AssetDetail label="Hardware" value={hardwareModel} />
        )}
        <AssetDetail label="Condition" value={assetCondition} />
        <AssetDetail
          label="Warranty Expiration"
          value={formatDate(asset?.warrantyExpirationDate) || 'N/A'}
        />
        <AssetDetail label="Site" value={asset?.site?.name || 'N/A'} />
        {locationName && <AssetDetail label="Location" value={locationName} />}
      </DetailsContainer>
    </Card>
  );
};

export default AssetDetailsCard;

import { Banner, Flex } from '@electricjs/arc';

type ErrorStateProps = {
  title: string;
  message: string;
};

export const ErrorState = ({ title, message }: ErrorStateProps) => {
  return (
    <Flex
      hAlignContent="center"
      width="100%"
      py="2.4rem"
      data-testid="error-state">
      <Banner
        id="storefront-error-state-banner"
        isFlat
        intent="error"
        title={title}
        message={message}
      />
    </Flex>
  );
};

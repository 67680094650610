import { ElectricPermission } from '@/types/users';
import { useGetLoggedUser } from '@/hooks/useGetLoggedUser';

export const useUserHasElectricPermission = (
  permission: ElectricPermission
): boolean => {
  const loggedUser = useGetLoggedUser();

  return loggedUser.electricPermissions?.includes(permission) ?? false;
};

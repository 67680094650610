// A custom hook that builds on useLocation to parse
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * This hook parses the query string from the URL query params.
 *
 * @returns {URLSearchParams} An instance of URLSearchParams which can be used
 * to access query parameters from the URL.
 */
export function useQueryString() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

/**
 * This hook parses the query string from the URL hash.
 *
 * @returns {object} An object containing an instance of URLSearchParams to access
 * query parameters from the URL hash and a function to update hash parameters.
 */
export function useHashParams() {
  const { pathname, hash } = useLocation();
  const search = hash.replace('#', '');
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(search);

  /**
   * Updates the specified hash parameter and navigates to the updated URL.
   *
   * @param {string} paramType - The key of the parameter to update.
   * @param {string} value - The value to set for the parameter.
   */
  const updateHashParams = (paramType: string, value: string) => {
    if (queryParams.get(paramType) !== value) {
      queryParams.set(paramType, value);
      navigate(`${pathname}#${queryParams}`);
    }
  };

  return { queryParams, updateHashParams };
}

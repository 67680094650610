import { Link, Text } from '@electricjs/arc';

import { DeviceIssueType } from '@/types/devices';
import { EmployeeWithAssets } from '@/types/employees';

import EmailModal from './EmailModal';
import { firewallLinks } from '@/constants/deviceIssueLinks';

type FirewallEmailModalProps = {
  hide: () => void;
  employees: EmployeeWithAssets[];
};

const FirewallEmailModal = ({ hide, employees }: FirewallEmailModalProps) => {
  return (
    <EmailModal
      hide={hide}
      issueType={DeviceIssueType.Firewall}
      employees={employees}
      showDevice={true}
      title="Send employees firewall instructions">
      <Text>
        We’ll send instructions to your employees on how to turn on firewall.
      </Text>
      <Text mt={3}>
        <Link target="_blank" rel="noopener" href={firewallLinks.mac}>
          Firewall instructions for Mac devices
        </Link>
      </Text>
      <Text mt={3}>
        <Link target="_blank" rel="noopener" href={firewallLinks.windows}>
          Firewall instructions for Windows devices
        </Link>
      </Text>
      <Text mt={5}>Employees that don’t have firewall enabled:</Text>
    </EmailModal>
  );
};

export default FirewallEmailModal;

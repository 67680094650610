import styled from 'styled-components';

import {
  Flex,
  Icon as ArcIcon,
  type IconProps as ArcIconProps,
} from '@electricjs/arc';

const StyledWrapper = styled(Flex)`
  gap: 0.8rem;
`;

type TypeGroupIconProps = {
  icons: ArcIconProps['icon'][];
  checked?: boolean;
  disabled?: boolean;
};

export const TypeGroupIcon = ({
  icons,
  checked,
  disabled,
}: TypeGroupIconProps) => {
  return (
    <StyledWrapper>
      {icons.map(iconName => {
        return (
          <ArcIcon
            icon={iconName}
            key={iconName}
            intent={disabled ? 'disabled' : checked ? 'primary' : undefined}
            size="medium"
          />
        );
      })}
    </StyledWrapper>
  );
};

import { AssetStatus } from '@electricjs/core_entity-client';

import { Chip, ColorsV2, Flex, Text } from '@electricjs/arc';

type AssetStatusChipColor = {
  textColor: string;
  backgroundColor: string;
};

const assetStatusColorMap: Record<AssetStatus, AssetStatusChipColor> = {
  [AssetStatus.InUse]: {
    backgroundColor: ColorsV2.INFO_LIGHTEST,
    textColor: ColorsV2.INFO_DARKEST,
  },
  [AssetStatus.Available]: {
    backgroundColor: ColorsV2.SUCCESS_LIGHTEST,
    textColor: ColorsV2.SUCCESS_DARKEST,
  },
  [AssetStatus.UnderRepair]: {
    backgroundColor: ColorsV2.WARNING_LIGHTEST,
    textColor: ColorsV2.WARNING_DARKEST,
  },
  [AssetStatus.Donated]: {
    backgroundColor: ColorsV2.INFO_LIGHTEST,
    textColor: ColorsV2.INFO_DARKEST,
  },
  [AssetStatus.Sold]: {
    backgroundColor: ColorsV2.INFO_LIGHTEST,
    textColor: ColorsV2.INFO_DARKEST,
  },
  [AssetStatus.Disposed]: {
    backgroundColor: ColorsV2.INFO_LIGHTEST,
    textColor: ColorsV2.INFO_DARKEST,
  },
  [AssetStatus.Missing]: {
    backgroundColor: ColorsV2.ERROR_LIGHTEST,
    textColor: ColorsV2.ERROR_DARKEST,
  },
  [AssetStatus.Archived]: {
    backgroundColor: ColorsV2.GRAY_LIGHTER,
    textColor: ColorsV2.GRAY_DARKEST,
  },
  [AssetStatus.InTransit]: {
    backgroundColor: ColorsV2.INFO_LIGHTEST,
    textColor: ColorsV2.INFO_DARKEST,
  },
};

export const assetStatusDisplayNameMap: Record<AssetStatus, string> = {
  [AssetStatus.InUse]: 'In Use',
  [AssetStatus.Available]: 'Available',
  [AssetStatus.UnderRepair]: 'Under Repair',
  [AssetStatus.Donated]: 'Donated',
  [AssetStatus.Sold]: 'Sold',
  [AssetStatus.Disposed]: 'Disposed',
  [AssetStatus.Missing]: 'Missing',
  [AssetStatus.Archived]: 'Archived',
  [AssetStatus.InTransit]: 'In Transit',
};

type AssetStatusChipProps = {
  status: AssetStatus;
  chipSize?: 'small' | 'medium';
};

export const AssetStatusChip = ({
  status,
  chipSize = 'small',
}: AssetStatusChipProps) => {
  return (
    <Chip
      id="asset-status-chip"
      variant={chipSize}
      backgroundColor={assetStatusColorMap[status]?.backgroundColor}>
      <Text variant="legal" color={assetStatusColorMap[status]?.textColor}>
        {assetStatusDisplayNameMap[status]}
      </Text>
    </Chip>
  );
};

type AssetStatusColumnProps = {
  status: AssetStatus;
};

export const AssetStatusColumn = ({ status }: AssetStatusColumnProps) => {
  return (
    <Flex hAlignContent="left">
      <AssetStatusChip status={status} />
    </Flex>
  );
};

import { Flex, Spinner, Text, SpinnerProps, FlexProps } from '@electricjs/arc';

type SpinnerWithMessageProps = {
  message?: string;
  spinnerSize?: SpinnerProps['size'];
} & FlexProps;

const SpinnerWithMessage = ({
  spinnerSize,
  message = 'Loading...',
  ...containerProps
}: SpinnerWithMessageProps) => {
  return (
    <Flex
      hAlignContent="center"
      vAlignContent="center"
      vertical
      height="100%"
      {...containerProps}>
      <Spinner size={spinnerSize} />
      <Text variant="body-2" mt={5}>
        {message}
      </Text>
    </Flex>
  );
};

export default SpinnerWithMessage;

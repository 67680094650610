import { ReactElement } from 'react';
import styled from 'styled-components';

import {
  Box,
  ColorsV2,
  IconInCircle,
  IconNames,
  Skeleton,
  getToken,
  useThemeColors,
} from '@electricjs/arc';
import { IconIntent } from '@electricjs/arc/components/Icon';

const BadgeUnseen = styled.span`
  background-color: ${getToken(ColorsV2.ERROR)};
  color: ${getToken(ColorsV2.WHITE)};
  padding: 0.3rem;
  text-align: center;
  border-radius: 50%;
  min-width: 0.1rem;
  min-height: 0.1rem;

  position: absolute;
  top: 0.3rem;
  left: 2.2rem;
  pointer-events: none;
`;

const BadgeContainer = styled.div`
  position: relative;
`;

type NotificationIconProps = {
  isNotificationUnseen: boolean;
  icon?: IconNames | ReactElement;
  loading?: boolean;
  intent?: IconIntent;
};

export const NotificationIcon = ({
  isNotificationUnseen,
  icon = 'app',
  loading = false,
  intent = 'primary',
}: NotificationIconProps) => {
  const iconSize = '2.6rem';
  const [
    primaryIconColor,
    primaryIconBackground,
    warningIconColor,
    warningIconBackground,
    dangerIconColor,
    dangerIconBackground,
  ] = useThemeColors([
    ColorsV2.PRIMARY,
    ColorsV2.PRIMARY_LIGHTEST,
    ColorsV2.WARNING,
    ColorsV2.WARNING_LIGHTEST,
    ColorsV2.ERROR,
    ColorsV2.ERROR_LIGHTEST,
  ]);

  const iconColor =
    intent === 'danger'
      ? dangerIconColor
      : intent === 'warning'
        ? warningIconColor
        : primaryIconColor;

  const iconBackground =
    intent === 'danger'
      ? dangerIconBackground
      : intent === 'warning'
        ? warningIconBackground
        : primaryIconBackground;

  if (loading) {
    return (
      <Box p="1">
        <Skeleton width={iconSize} height={iconSize}></Skeleton>
      </Box>
    );
  }

  const isIconName = typeof icon === 'string';

  return (
    <BadgeContainer>
      <Box p="1">
        {isIconName ? (
          <IconInCircle
            flexShrink="0"
            size={iconSize}
            background={iconBackground}
            color={iconColor}
            icon={icon}
            iconSize="small"
            borderRadius="0.5rem"
            intent={intent}
          />
        ) : (
          icon
        )}
        {isNotificationUnseen && <BadgeUnseen />}
      </Box>
    </BadgeContainer>
  );
};

import { getRelativeTime, isWithinLastWeek } from '@/helpers/DateHelpers';
import formatDate from '@/helpers/formatDate';
import { Flex, Icon, Text, Tooltip, useBreakpoint } from '@electricjs/arc';

type AssetLastCheckProps = {
  lastCheck: string | undefined | null;
};

export const AssetLastCheck = ({ lastCheck }: AssetLastCheckProps) => {
  const { xl } = useBreakpoint();

  if (!lastCheck) {
    return (
      <Flex
        alignItems="center"
        flexDirection="row"
        justifyContent={xl ? 'flex-start' : 'flex-end'}>
        <Tooltip
          maxWidth="40rem"
          content="The device must have the Electric app installed to view this information."
          placement="top-start"
          display="flex">
          <Icon icon="information-circle" size="small" mr="0.5rem" />
        </Tooltip>
        <Text variant="body">Info unavailable</Text>
      </Flex>
    );
  }

  return (
    <Flex flexDirection="column">
      <Flex
        width="100%"
        flexDirection="row"
        justifyContent={xl ? 'flex-start' : 'flex-end'}>
        {!isWithinLastWeek(lastCheck) && (
          <Tooltip
            maxWidth="40rem"
            content="Data is stale. Ask employee to reboot device for the most up-to-date data."
            placement="top-start">
            <Icon
              icon="alert-triangle"
              size="small"
              mr="0.5rem"
              mt="0.2rem"
              intent="warning"
            />
          </Tooltip>
        )}
        <Text variant="body">{getRelativeTime(lastCheck)}</Text>
      </Flex>

      <Text variant="body">{formatDate(lastCheck, 'MMM d, yyyy h:mm a')}</Text>
    </Flex>
  );
};

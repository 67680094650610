import { useCallback, useMemo } from 'react';

import { Spacer, Pane, TabPanel, Table } from '@electricjs/arc';

import { AssetDeviceProperty } from '@/types/assets';
import { DeviceIssueType } from '@/types/devices';
import { Employee } from '@/types/employees';
import EmailEmployeeButton from '@/components/Assets/AssetDetails/DeviceSecurityPanel/EmailEmployeeButton';
import getDeviceSecurityColumns from '@/components/Assets/AssetDetails/DeviceSecurityPanel/getDeviceSecurityColumns';
import { IssueCheckDisabledBanner } from '@/components/Assets/AssetDetails/IssueCheckDisabledBanner';

export const DeviceIssueNameMap = {
  [DeviceIssueType.Firewall]: 'Firewall',
  [DeviceIssueType.Gatekeeper]: 'Gatekeeper',
  [DeviceIssueType.OS]: 'OS update',
  [DeviceIssueType.Encryption]: 'Disk encryption',
};

export type DeviceIssuesData = {
  name: string;
  // Indicates whether data is available in the device reporting.
  hasData: boolean;
  // Specifies if an issue has been detected.
  hasIssue: boolean;
  // Determines if the "Check issue" feature is disabled.
  disabled: boolean;
  action?: () => React.ReactNode;
};

type DeviceSecurityPanelProps = {
  device: AssetDeviceProperty;
  employee: Employee | undefined;
  isLoading: boolean;
};

const DeviceSecurityPanel = ({
  device,
  employee,
  isLoading,
}: DeviceSecurityPanelProps) => {
  const deviceSecurityColumns = getDeviceSecurityColumns();
  const deviceIssues = useMemo<{
    [key in DeviceIssueType]?: { extra?: { disabled: boolean } };
  }>(() => {
    return device?.reportingData?.issues || {};
  }, [device]);

  const isDisabled = useCallback(
    (key: DeviceIssueType) => {
      const issue = deviceIssues[key];
      if (typeof issue === 'object' && issue !== null && 'extra' in issue) {
        return !!issue.extra?.disabled;
      }
      return false;
    },
    [deviceIssues]
  );

  const deviceIssuesData: DeviceIssuesData[] = useMemo(
    () => [
      {
        name: DeviceIssueNameMap[DeviceIssueType.Firewall],
        hasData: !!device.reportingData?.firewall,
        hasIssue: !!deviceIssues && DeviceIssueType.Firewall in deviceIssues,
        disabled: isDisabled(DeviceIssueType.Firewall),
        action: () => (
          <EmailEmployeeButton
            issueType={DeviceIssueType.Firewall}
            employee={employee}
            device={device}
            isLoading={isLoading}
          />
        ),
      },
      {
        name: DeviceIssueNameMap[DeviceIssueType.Gatekeeper],
        hasData: !!device.reportingData?.gatekeeper,
        hasIssue: !!deviceIssues && DeviceIssueType.Gatekeeper in deviceIssues,
        disabled: isDisabled(DeviceIssueType.Gatekeeper),
        action: () => (
          <EmailEmployeeButton
            issueType={DeviceIssueType.Gatekeeper}
            employee={employee}
            isLoading={isLoading}
            device={device}
          />
        ),
      },
      {
        name: DeviceIssueNameMap[DeviceIssueType.OS],
        hasData: !!device.osType && !!device.reportingData?.osName,
        hasIssue: !!deviceIssues && DeviceIssueType.OS in deviceIssues,
        disabled: isDisabled(DeviceIssueType.OS),
        action: () => (
          <EmailEmployeeButton
            issueType={DeviceIssueType.OS}
            employee={employee}
            isLoading={isLoading}
            device={device}
          />
        ),
      },
      {
        name: DeviceIssueNameMap[DeviceIssueType.Encryption],
        hasData: !!device.reportingData?.encryptionPercent,
        hasIssue: !!deviceIssues && DeviceIssueType.Encryption in deviceIssues,
        disabled: isDisabled(DeviceIssueType.Encryption),
        action: () => (
          <EmailEmployeeButton
            issueType={DeviceIssueType.Encryption}
            employee={employee}
            isLoading={isLoading}
            device={device}
          />
        ),
      },
    ],
    [device, deviceIssues, employee, isDisabled, isLoading]
  );

  return (
    <TabPanel>
      <IssueCheckDisabledBanner reportingData={device.reportingData} />
      <Spacer y="2rem" />
      <Pane flexDirection="column" width="100%">
        <Table
          id="device-security-table"
          data={deviceIssuesData}
          columns={deviceSecurityColumns}
          loading={false}
        />
      </Pane>
    </TabPanel>
  );
};

export default DeviceSecurityPanel;

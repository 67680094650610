import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import styled from 'styled-components';

import { Button, Flex } from '@electricjs/arc';

import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import {
  FormData,
  mdmAppleSetupFormSchema,
} from '@/components/MDMEnrollment/MDMAppleForm/MdmAppleSetupFormSchema';
import { useJumpCloudAppleMdmProvisionMutation } from '@/redux/slices/mdmManagementApiSlice';
import { useGlobalUI } from '@/components/GlobalUIProvider';
import { DownloadYourCsrFileStep } from '@/components/MDMEnrollment/MDMAppleForm/DownloadYourCsrFileStep';
import { ApplePushCertificatePortalStep } from '@/components/MDMEnrollment/MDMAppleForm/ApplePushCertificatePortalStep';
import { UploadCsrCertificateStep } from '@/components/MDMEnrollment/MDMAppleForm/UploadCsrCertificateStep';
import { ProvideAppleIdStep } from '@/components/MDMEnrollment/MDMAppleForm/ProvideAppleIdStep';
import { UploadMdmPushCertificateStep } from '@/components/MDMEnrollment/MDMAppleForm/UploadMdmPushCertificateStep';
import { MDMInformation } from '@/types/mdmManagement';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
`;

type MdmAppleSetupFormProps = {
  onMdmAppleSetupSuccess: () => void;
  onCancel: () => void;
  existingCertIfRenewal?: MDMInformation;
};

export const MdmAppleSetupForm = ({
  onMdmAppleSetupSuccess,
  onCancel,
  existingCertIfRenewal,
}: MdmAppleSetupFormProps) => {
  const { showErrorToast } = useGlobalUI();

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { isValid },
  } = useForm<FormData>({
    resolver: yupResolver(mdmAppleSetupFormSchema),
    mode: 'onTouched',
  });

  const resetCertificateField = () => {
    reset(
      {
        appleSignedCert: undefined,
        appleId: getValues()?.appleId ?? undefined,
      },
      { keepDefaultValues: true, keepErrors: true }
    );
  };

  const [setUpAppleMdm, { isLoading }] =
    useJumpCloudAppleMdmProvisionMutation();

  const organizationId = useGetOrganizationId();

  const onSubmit = (data: FormData) => {
    setUpAppleMdm({
      organizationId,
      appleCertCreatorAppleId: data.appleId,
      appleSignedCert: data.appleSignedCert,
    })
      .unwrap()
      .then(() => {
        onMdmAppleSetupSuccess();
      })
      .catch(error => {
        window?.DD_RUM?.addError(
          `Unable to provision your MDM Apple Certificate: ${JSON.stringify(error)}`,
          {
            location: 'MdmAppleSetupForm',
          }
        );
        showErrorToast({
          id: 'jumpcloud-apple-setup-error-toast',
          title: 'An error occurred while setting up your MDM account',
          message:
            'Our team has been alerted of this error and will resolve it as soon as possible.',
        });
      });
  };

  const isRenewing = existingCertIfRenewal !== undefined;

  return (
    <Form data-testid="mdm-apple-set-up-form" onSubmit={handleSubmit(onSubmit)}>
      {/* Step 1 */}
      <DownloadYourCsrFileStep />

      {/* Step 2 */}
      <ApplePushCertificatePortalStep isRenewing={isRenewing} />

      {/* Step 3 */}
      <UploadCsrCertificateStep isRenewing={isRenewing} />

      {/* Step 4 */}
      <ProvideAppleIdStep
        control={control}
        setValue={setValue}
        existingCertIfRenewal={existingCertIfRenewal}
      />

      {/* Step 5 */}
      <UploadMdmPushCertificateStep
        setValue={setValue}
        reset={resetCertificateField}
      />

      {/* form actions buttons */}
      <Flex p="2rem" marginLeft="auto" gap="1rem">
        <Button
          variant="text"
          onClick={onCancel}
          id="cancel-mdm-apple-enrollment-button"
          testId="cancel-mdm-apple-enrollment-button">
          Cancel
        </Button>

        <Button
          type="submit"
          id="submit-mdm-apple-enrollment-button"
          testId="submit-mdm-apple-enrollment-button"
          disabled={!isValid}
          loading={isLoading}>
          Save MDM setup
        </Button>
      </Flex>
    </Form>
  );
};

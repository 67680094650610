import { ProductTypeName } from '@electricjs/core_entity-client';

const productTypeNameMap: Record<ProductTypeName, string> = {
  laptop: 'Laptop',
  desktop: 'Desktop',
  mouse: 'Mouse',
  keyboard: 'Keyboard',
  keyboard_mouse: 'Keyboard/Mouse combo',
  monitor: 'Monitor',
  dock: 'Dock',
  audio: 'Audio',
  video: 'Video',
  warranty: 'Warranty',
  miscellaneous: 'Miscellaneous',
  phone: 'Phone',
  tablets: 'Tablet',
  headphones: 'Headphones',
  microphone: 'Microphone',
  power_adapter: 'Power Adapter',
  cable: 'Cable',
  accessory: 'Accessory',
};

export default function getProductTypeLabel(
  productType: ProductTypeName
): string {
  return productTypeNameMap[productType] ?? productType; // default to the enum value
}

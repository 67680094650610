import styled from 'styled-components';

import {
  ColorsV2,
  Flex,
  ModalAction,
  ModalBody,
  ModalFooter,
  ModalFooterActions,
  ModalHeader,
  ModalV2,
  Text,
  getToken,
} from '@electricjs/arc';

import electricLogo from '@/assets/electric_logo_white.svg';

type MDMInvitePreviewModalProps = {
  hide: () => void;
  visible: boolean;
  handleBack: () => void;
  handleCancel: () => void;
  handleSubmit: () => void;
  isLoading: boolean;
};

const StyledFlex = styled(Flex)`
  border: 1px solid ${getToken(ColorsV2.GRAY)};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${getToken(ColorsV2.GRADIENT_HEADING)};
  width: 100%;
  height: 60px;
`;

const StyledOL = styled.ol`
  font-size: 14px;
  color: ${getToken(ColorsV2.TEXT)};
  padding-inline-start: 1.5rem;
`;

const StyledLi = styled.li`
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledMockButton = styled.div`
  border: 2px solid ${getToken(ColorsV2.PRIMARY)};
  font-size: 14px;
  font-weight: 700;
  color: ${getToken(ColorsV2.PRIMARY)};
  border-radius: 0.6rem;
  width: 200px;
  text-align: center;
  align-self: center;
  padding: 8px 5px;
  margin: 1rem 0;
`;

const MockLink = styled.span`
  text-decoration: underline;
  color: ${getToken(ColorsV2.PRIMARY)};
`;

const MDMInvitePreviewModal = ({
  hide,
  visible,
  handleBack,
  handleCancel,
  handleSubmit,
  isLoading,
}: MDMInvitePreviewModalProps) => {
  return (
    <ModalV2
      visible={visible}
      hide={hide}
      ariaLabelledby="org-mdm-invite-preview">
      <ModalHeader>
        <Text variant="heading-2">
          Preview Email for Enrollment Instructions
        </Text>
      </ModalHeader>
      <ModalBody overflowY="scroll">
        <StyledFlex flexDirection="column" pb={2}>
          <Header>
            <img alt="Electric" height="42" src={electricLogo} />
          </Header>
          <Flex flexDirection="column" px={6} py={4}>
            <Text fontSize="14px" mb={2} mt={3}>
              Dear [Recipient&apos;s Name],
            </Text>
            <Text fontSize="14px" lineHeight="18px">
              Your company wants to make sure your work device is safe and
              secure, and that&apos;s why we&apos;re introducing Mobile Device
              Management (MDM). Think of it as your device&apos;s trustworthy
              guardian, keeping everything in order and up to our company&apos;s
              high standards. Here&apos;s how to get started:
            </Text>
            <Text fontSize="14px" lineHeight="18px" fontWeight={700} mt={5}>
              If you&apos;ve already installed the Electric application:
            </Text>
            <StyledOL>
              <StyledLi>
                Simply click the &quot;Open Electric Application&quot; button
                below.
              </StyledLi>
              <StyledLi>
                We&apos;ll guide you through the MDM setup from there.
              </StyledLi>
            </StyledOL>
            <StyledMockButton>Open Electric Application</StyledMockButton>
            <Text fontSize="14px" lineHeight="18px" fontWeight={700} mt={5}>
              If you haven&apos;t downloaded the Electric application yet:
            </Text>
            <StyledOL>
              <StyledLi>
                No worries! Just click this link:{' '}
                <MockLink>Download Electric</MockLink> to get started.
              </StyledLi>
              <StyledLi>
                MDM setup will be the third step in the process.
              </StyledLi>
            </StyledOL>
            <Text fontSize="14px" lineHeight="18px">
              You&apos;ll find detailed instructions inside the application to
              help you install MDM on your specific device and operating system.
              It&apos;s a breeze!
            </Text>
            <Text fontSize="14px" lineHeight="18px" mt={3}>
              Thanks for taking this important step towards ensuring the safety
              of your work device,
            </Text>
            <Text fontSize="14px" mt={3}>
              Electric
            </Text>
          </Flex>
        </StyledFlex>
      </ModalBody>
      <ModalFooter>
        <ModalFooterActions justify="start">
          <ModalAction
            id="employee-profile-invite-modal-back-button"
            onClick={handleBack}
            variant="outline">
            Back
          </ModalAction>
        </ModalFooterActions>
        <ModalFooterActions>
          <ModalAction
            id="employee-profile-invite-modal-cancel"
            variant="text"
            disabled={isLoading}
            onClick={handleCancel}>
            Cancel
          </ModalAction>
          <ModalAction
            id="employee-profile-invite-modal-submit"
            loading={isLoading}
            disabled={false}
            onClick={handleSubmit}>
            Send email
          </ModalAction>
        </ModalFooterActions>
      </ModalFooter>
    </ModalV2>
  );
};

export default MDMInvitePreviewModal;

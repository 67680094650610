import { Group } from '@/types/groups';

import { NotificationDetail } from './NotificationDetail';

type GroupDetailProps = {
  isLoading: boolean;
  groups?: Group[];
};
export const GroupDetail = ({ isLoading, groups }: GroupDetailProps) => {
  return (
    <NotificationDetail
      loading={isLoading}
      icon="user-group"
      value={
        groups && groups.length > 0
          ? groups.map(group => group.name).join(', ')
          : 'N/A'
      }
    />
  );
};

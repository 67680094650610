import { NotificationDetail } from './NotificationDetail';

type EmailDetailProps = {
  isLoading: boolean;
  email?: string | null;
};

export const EmailDetail = ({ email, isLoading }: EmailDetailProps) => {
  return (
    <NotificationDetail
      icon="email-unread"
      value={email || 'N/A'}
      loading={isLoading}
    />
  );
};

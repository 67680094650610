import { useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import {
  Banner,
  Button,
  Flex,
  Header,
  OneColumnLayout,
  Radio,
  RadioGroup,
  Spacer,
  Text,
} from '@electricjs/arc';
import {
  EntitlementSlugEnum,
  LockStatus,
  OSType,
} from '@electricjs/core_entity-client';

import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useOrganizationHasEntitlement } from '@/hooks/useOrganizationHasEntitlement';
import { useLockDeviceMutation } from '@/redux/slices/mdmManagementApiSlice';
import { Employee } from '@/types/employees';
import { useGlobalUI } from '../GlobalUIProvider';
import WipeDeviceModal from '../People/EmployeeProfile/WipeDeviceModal';
import DeviceCard from './DeviceCard';
import LockDeviceRadioOption from './DeviceData/LockDeviceRadioOption';
import DeviceRadioTile from './DeviceRadioTile';
import { DeviceDataAction, DeviceRetrieveAction } from './types';
import { Asset, DeviceAsset } from '@/types/assets';

type DeviceDataProps = {
  asset: Asset;
  employee: Employee;
  onNext: () => void;
  onPrevious: () => void;
};

const DeviceData = ({
  asset,
  employee,
  onNext,
  onPrevious,
}: DeviceDataProps) => {
  const { itHubWarehousingWithStripe } = useFlags();
  const organizationId = useGetOrganizationId();
  const { showErrorToast, openModal, closeModal } = useGlobalUI();
  const { control, setValue } = useFormContext();

  const deviceDataAction = useWatch({ name: 'deviceDataAction' });
  const deviceRetrievalAction = useWatch({ name: 'deviceRetrieveAction' });

  const organizationHasMdmEntitlement = useOrganizationHasEntitlement(
    EntitlementSlugEnum.JumpcloudMdm
  );

  const [lockDevice, { isLoading: isLockingDevice }] = useLockDeviceMutation();

  const isShippingToWarehouse =
    deviceRetrievalAction === DeviceRetrieveAction.Warehouse;

  const showLockOption =
    asset.device &&
    asset.device.osType !== OSType.Windows &&
    organizationHasMdmEntitlement &&
    asset.device.mdmEnrolledAt;
  const disableLockOption = isLockingDevice || isShippingToWarehouse;

  const showWipeOption =
    isShippingToWarehouse ||
    (organizationHasMdmEntitlement && asset.device?.mdmEnrolledAt);
  const disableWipeOption = isLockingDevice;
  const skipWipeModal = !(
    organizationHasMdmEntitlement && asset.device?.mdmEnrolledAt
  );

  const disableKeepOption = isLockingDevice || isShippingToWarehouse;

  useEffect(() => {
    if (!organizationHasMdmEntitlement) {
      setValue('deviceDataAction', DeviceDataAction.Keep);
    }
  }, [organizationHasMdmEntitlement, setValue]);

  useEffect(() => {
    if (disableKeepOption && (!showLockOption || disableLockOption)) {
      setValue('deviceDataAction', DeviceDataAction.Remove);
    }

    return () => {
      setValue('deviceDataAction', null);
    };
  }, [disableKeepOption, disableLockOption, setValue, showLockOption]);

  useEffect(() => {
    if (
      (!showWipeOption || disableWipeOption) &&
      (!showLockOption || disableLockOption)
    ) {
      setValue('deviceDataAction', DeviceDataAction.Keep);
    }

    return () => {
      setValue('deviceDataAction', null);
    };
  }, [
    disableLockOption,
    disableWipeOption,
    setValue,
    showLockOption,
    showWipeOption,
  ]);

  const deviceId = asset.device?.id || '';
  const deviceSerialNumber = asset.serialNumber;

  if (!deviceSerialNumber) {
    throw new Error(
      'Device serial number missing. Please contact product-support@electric.ai for assistance.'
    );
  }
  const handleLockDevice = async (onSuccess: () => void) => {
    try {
      await lockDevice({
        organizationId,
        deviceId: deviceId,
        serialNumber: deviceSerialNumber,
      });
      onSuccess();
    } catch (error) {
      window?.DD_RUM?.addError(`Error sending lock command: ${error}`, {
        location: 'OffboardingLockDevice',
      });
      showErrorToast({
        id: 'lock-device-error-toast',
        message: 'There was an error sending lock command. Please try again.',
      });
    }
  };

  const handleNext = () => {
    if (deviceDataAction === DeviceDataAction.Keep) {
      onNext();
      return;
    }
    if (deviceDataAction === DeviceDataAction.Lock) {
      handleLockDevice(onNext);
    }

    if (deviceDataAction === DeviceDataAction.Remove) {
      if (skipWipeModal) {
        onNext();
        return;
      }

      openModal(
        <WipeDeviceModal
          deviceAsset={asset as DeviceAsset}
          onCompleteWipe={() => {
            closeModal();
            onNext();
          }}
          onCancel={closeModal}
          employeeName={`${employee.firstName} ${employee.lastName}`}
        />
      );
    }
  };

  return (
    <OneColumnLayout>
      <Header title="What should we do with the data on this device?" />
      <DeviceCard asset={asset} />
      <Spacer y="2rem" />
      {itHubWarehousingWithStripe && isShippingToWarehouse && (
        <Banner
          id="warehoused-devices-data-banner"
          intent="warning"
          isFlat
          title="All warehoused devices will be wiped."
          message="Warehoused devices will be wiped once they are received at the warehouse."
        />
      )}
      {itHubWarehousingWithStripe ? (
        <Controller
          name="deviceDataAction"
          control={control}
          render={({ field }) => (
            <RadioGroup
              {...field}
              mt="4.2rem"
              radioTilesGap="2.2rem"
              selectTilesBorderWidth="2px">
              <>
                {showWipeOption && (
                  <DeviceRadioTile
                    checked={deviceDataAction === DeviceDataAction.Remove}
                    value={DeviceDataAction.Remove}
                    optionText="Wipe the device"
                    icon="broom"
                    disabled={disableWipeOption}
                  />
                )}
              </>
              <>
                {showLockOption && (
                  <LockDeviceRadioOption
                    lockStatus={asset.device?.lockStatus ?? LockStatus.Unlocked}
                    value={deviceDataAction}
                    disabled={disableLockOption}
                  />
                )}
              </>
              <DeviceRadioTile
                value={DeviceDataAction.Keep}
                optionText="Leave as is"
                secondaryText="(Not recommended)"
                icon="laptop"
                checked={deviceDataAction === DeviceDataAction.Keep}
                disabled={disableKeepOption}
              />
            </RadioGroup>
          )}
        />
      ) : (
        <Controller
          name="deviceDataAction"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field} mt="4.2rem" gap="0.8rem" stack>
              <>
                {showWipeOption && (
                  <Radio
                    id="radio-option-remove"
                    value={DeviceDataAction.Remove}
                    checked={deviceDataAction === DeviceDataAction.Remove}
                    disabled={disableWipeOption}>
                    <Text variant="subheading">
                      Remove user accounts and user data for reuse
                    </Text>
                  </Radio>
                )}
              </>
              <>
                {showLockOption && (
                  <LockDeviceRadioOption
                    lockStatus={asset.device?.lockStatus ?? LockStatus.Unlocked}
                    value={deviceDataAction}
                    disabled={disableLockOption}
                  />
                )}
              </>
              <Radio
                id="radio-option-keep"
                value={DeviceDataAction.Keep}
                checked={deviceDataAction === DeviceDataAction.Keep}
                disabled={disableKeepOption}>
                <Text variant="subheading">Leave computer as is</Text>
              </Radio>
            </RadioGroup>
          )}
        />
      )}
      <Flex mt="9rem" hAlignContent="between" gap="3.4rem">
        <Button
          id="devices-data-previous-button"
          variant="outline"
          disabled={isLockingDevice}
          onClick={onPrevious}>
          Previous
        </Button>
        <Button
          id="devices-data-next-button"
          disabled={!deviceDataAction}
          loading={isLockingDevice}
          onClick={handleNext}>
          Next
        </Button>
      </Flex>
    </OneColumnLayout>
  );
};

export default DeviceData;

import { Flex, Text } from '@electricjs/arc';

type HaveIBeenPwnedAttributionProps = {
  fontSize: string;
};

export const HaveIBeenPwnedAttribution = ({
  fontSize = '1rem',
}: HaveIBeenPwnedAttributionProps) => {
  return (
    <Flex>
      <Text fontSize={fontSize} variant="body-2">
        Breach data provided by{' '}
        <a target="_blank" href="https://haveibeenpwned.com" rel="noreferrer">
          haveibeenpwned.com
        </a>
      </Text>
    </Flex>
  );
};

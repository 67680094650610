import { IconNames } from '@electricjs/arc';
import {
  EntitlementSlugEnum,
  HRISProviderSlugEnum,
} from '@electricjs/core_entity-client';
import { IntegrationCard } from '../IntegrationCard/IntegrationCard';
import { useOrganizationHasEntitlement } from '@/hooks/useOrganizationHasEntitlement';
import {
  getIsProviderConnected,
  getCustomStatusLabel,
} from '../IntegrationCard/helpers';
import { HRISProvider } from '@/types/hrisIntegration';

type HRISIntegrationCardProps = {
  slug: HRISProviderSlugEnum | undefined;
  name: string;
  customIcon?: string;
  icon?: IconNames;
  homepage?: string;
  description: string;
  handleConnectionRequest: () => void;
  handleDisconnectionRequest?: () => void;
  currentProvider: HRISProvider | undefined;
};

const HRISIntegrationCard = ({
  slug,
  name,
  customIcon,
  icon,
  homepage,
  description,
  handleConnectionRequest,
  handleDisconnectionRequest,
  currentProvider,
}: HRISIntegrationCardProps) => {
  const hasHRISEntitlement = useOrganizationHasEntitlement(
    EntitlementSlugEnum.HrisIntegration
  );
  const isConnected = getIsProviderConnected(
    !hasHRISEntitlement,
    currentProvider?.slug,
    slug
  );
  const customStatusLabel = getCustomStatusLabel(
    isConnected,
    currentProvider?.slug,
    currentProvider?.name
  );

  return (
    <IntegrationCard
      slug={slug}
      name={name}
      customIcon={customIcon}
      icon={icon}
      homepage={homepage}
      description={description}
      handleConnectionRequest={handleConnectionRequest}
      handleDisconnectionRequest={handleDisconnectionRequest}
      currentIntegrationSlug={currentProvider?.slug}
      customStatusLabel={customStatusLabel}
      needsUpgrade={!hasHRISEntitlement}
      isConnected={isConnected}
    />
  );
};

export default HRISIntegrationCard;

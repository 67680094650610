import { TextVariant } from '@electricjs/arc/components/Text';
import { AdditionalInfoEmailProviderEnum } from '@electricjs/core_entity-client';

import finance from '@/assets/intakeFormOptions/finance.svg';
import google_workspace from '@/assets/google_workspace.svg';
import healthcare from '@/assets/intakeFormOptions/healthcare.svg';
import laptop from '@/assets/intakeFormOptions/laptop.svg';
import apple_business_manager from '@/assets/intakeFormOptions/logo-apple.svg';
import mdm from '@/assets/intakeFormOptions/mdm.svg';
import media_and_publishing from '@/assets/intakeFormOptions/media_and_publishing.svg';
import messages_question from '@/assets/intakeFormOptions/messages-question.svg';
import non_profit from '@/assets/intakeFormOptions/non_profit.svg';
import microsoft from '@/assets/intakeFormOptions/office-365-icon.svg';
import other from '@/assets/intakeFormOptions/pencil-duotone 1.svg';
import hr_professional from '@/assets/intakeFormOptions/people-line-duotone 2.svg';
import not_sure from '@/assets/intakeFormOptions/question-duotone 1.svg';
import real_estate_and_construction from '@/assets/intakeFormOptions/real_estate_and_construction.svg';
import retail_and_hospitality from '@/assets/intakeFormOptions/retail_and_hospitality.svg';
import security from '@/assets/intakeFormOptions/shield-check.svg';
import mfa from '@/assets/intakeFormOptions/shield-keyhole.svg';
import antivirus_software from '@/assets/intakeFormOptions/shield-virus.svg';
import software_and_technology from '@/assets/intakeFormOptions/software-and-technology.svg';
import employee_cybersecurity_training from '@/assets/intakeFormOptions/user-shield.svg';
import administrative_professional from '@/assets/intakeFormOptions/user-tie-hair-duotone 1.svg';
import it_professional from '@/assets/intakeFormOptions/users-gear-duotone 2.svg';
import microsoft_defender from '@/assets/intakeFormOptions/windows-defender.svg';

export const SUPPORTED_EMAIL_PROVIDERS = [
  AdditionalInfoEmailProviderEnum.GoogleWorkspace,
  AdditionalInfoEmailProviderEnum.Microsoft365,
];

export type IntakeFormSupportedEmailProvider =
  (typeof SUPPORTED_EMAIL_PROVIDERS)[number];

export type AnswerOption = {
  slug: string;
  text: string;
  subText?: string;
  icon: string | null;
  isSingleSelection?: boolean;
};

const stepQuestionId = [
  'current-role',
  'industry-organization',
  'number-of-employees',
  'track-and-manage-devices',
  'email-provider',
  'security-solutions',
  'it-goal',
] as const;

type StepQuestionId = (typeof stepQuestionId)[number];

export type CustomOptionStyle = {
  customFont?: TextVariant;
  customSize?: { width: string; height: string };
};

export type StepQuestionProps = {
  id: StepQuestionId;
  question: string;
  isMultiple: boolean;
  answerOptions: AnswerOption[];
  customOptionStyle?: CustomOptionStyle;
};

export enum EMAIL_PROVIDER_OPTIONS_KEYS {
  MICROSOFT = 'microsoft-365',
  GOOGLE = 'google-workspace',
  REQUIRES_HELP = 'requires-help',
  OTHER = 'other',
}

export enum IT_GOAL_OPTIONS_KEYS {
  SECURITY = 'security',
  IMPROVE_HARDWARE_PURCHASES = 'improve-hardware-purchases',
  STREAMLINE_EMPLOYEE_SUPPORT_TASKS = 'streamline-employee-support-tasks',
  NO_SPECIFIC_GOAL = 'no-specific-goal',
}

export const INTAKE_QUESTIONS: StepQuestionProps[] = [
  {
    id: 'current-role',
    question: 'Describe your current role at a high level',
    isMultiple: false,
    answerOptions: [
      {
        slug: 'it-professional',
        text: 'IT professional',
        icon: it_professional,
      },
      {
        slug: 'hr-professional',
        text: 'HR professional',
        icon: hr_professional,
      },
      {
        slug: 'administrative-professional',
        text: 'Administrative professional',
        icon: administrative_professional,
      },
      {
        slug: 'other',
        text: 'Other',
        icon: other,
      },
    ],
  },
  {
    id: 'industry-organization',
    question: 'What industry is your organization in?',
    isMultiple: false,
    answerOptions: [
      {
        slug: 'software-and-technology',
        text: 'Software & Technology',
        icon: software_and_technology,
      },
      {
        slug: 'retail-and-hospitality',
        text: 'Retail & Hospitality',
        icon: retail_and_hospitality,
      },
      {
        slug: 'real-estate-and-construction',
        text: 'Real Estate & Construction',
        icon: real_estate_and_construction,
      },
      {
        slug: 'finance',
        text: 'Finance',
        icon: finance,
      },
      {
        slug: 'media-and-publishing',
        text: 'Media & Publishing',
        icon: media_and_publishing,
      },
      {
        slug: 'non-profit',
        text: 'Non-profit',
        icon: non_profit,
      },
      {
        slug: 'healthcare',
        text: 'Healthcare',
        icon: healthcare,
      },
      {
        slug: 'other',
        text: 'Other',
        icon: other,
      },
    ],
    customOptionStyle: {
      customSize: {
        width: '22.6rem',
        height: '14.3rem',
      },
    },
  },
  {
    id: 'number-of-employees',
    question: 'How many employees do you have at your organization?',
    isMultiple: false,
    answerOptions: [
      {
        slug: '1-19',
        text: '1-20',
        icon: null,
      },
      {
        slug: '20-49',
        text: '20-50',
        icon: null,
      },
      {
        slug: '50-99',
        text: '50-100',
        icon: null,
      },
      {
        slug: '100+',
        text: '100+',
        icon: null,
      },
    ],
  },
  {
    id: 'track-and-manage-devices',
    question:
      'How do you keep track of and manage the devices your employees use?',
    isMultiple: true,
    answerOptions: [
      {
        slug: 'mdm',
        text: 'Mobile Device Management (MDM)',
        subText: 'JAMF, Kaseya, or Jumpcloud',
        icon: mdm,
      },
      {
        slug: 'apple-business-manager',
        text: 'Apple Business Manager',
        icon: apple_business_manager,
      },
      {
        slug: 'microsoft-defender-or-intune',
        text: 'Microsoft Defender or Intune',
        icon: microsoft_defender,
      },
      {
        slug: 'not-sure',
        text: "I'm not sure",
        icon: not_sure,
        isSingleSelection: true,
      },
      {
        slug: 'none-of-the-above',
        text: "I'm not using anything to track and manage devices",
        icon: laptop,
        isSingleSelection: true,
      },
    ],
  },
  {
    id: 'email-provider',
    question: 'Which email provider do you utilize for employees?',
    isMultiple: false,
    answerOptions: [
      {
        slug: EMAIL_PROVIDER_OPTIONS_KEYS.MICROSOFT,
        text: 'Microsoft',
        icon: microsoft,
      },
      {
        slug: EMAIL_PROVIDER_OPTIONS_KEYS.GOOGLE,
        text: 'Google Workspace ',
        icon: google_workspace,
      },
      {
        slug: EMAIL_PROVIDER_OPTIONS_KEYS.REQUIRES_HELP,
        text: 'I want help moving to a new cloud email provider such as Microsoft365',
        icon: null,
      },
      {
        slug: EMAIL_PROVIDER_OPTIONS_KEYS.OTHER,
        text: 'Other',
        icon: other,
      },
    ],
  },
  {
    id: 'security-solutions',
    question:
      'Which of the following security solutions are being used in your organization?',
    isMultiple: true,
    answerOptions: [
      {
        slug: 'antivirus-software',
        text: 'Anti-Virus software',
        icon: antivirus_software,
      },
      {
        slug: 'mfa',
        text: 'Multi-Factor Authentication (MFA) per application',
        icon: mfa,
      },
      {
        slug: 'employee-cybersecurity-training',
        text: 'Employee cybersecurity training',
        icon: employee_cybersecurity_training,
      },
      {
        slug: 'identity-and-access-management-tooling',
        text: 'Identity and access management tooling',
        subText: 'Okta, Google Cloud IAM, OneLogin',
        icon: null,
      },
      {
        slug: 'not-sure',
        text: "I'm not sure",
        icon: not_sure,
        isSingleSelection: true,
      },
    ],
  },
  {
    id: 'it-goal',
    question: 'What is your top IT goal right now?',
    isMultiple: false,
    answerOptions: [
      {
        slug: IT_GOAL_OPTIONS_KEYS.SECURITY,
        text: 'Keep my employees and my business as secure as possible',
        icon: security,
      },
      {
        slug: IT_GOAL_OPTIONS_KEYS.IMPROVE_HARDWARE_PURCHASES,
        text: 'Improve hardware purchases and reduce spend on devices',
        icon: laptop,
      },
      {
        slug: IT_GOAL_OPTIONS_KEYS.STREAMLINE_EMPLOYEE_SUPPORT_TASKS,
        text: 'Streamline day-to-day employee support tasks, such as onboarding and password reset reminders',
        icon: messages_question,
      },
      {
        slug: IT_GOAL_OPTIONS_KEYS.NO_SPECIFIC_GOAL,
        text: 'No specific goal at this time, I’m looking for recommendations.',
        icon: not_sure,
      },
    ],
    customOptionStyle: {
      customFont: 'body',
    },
  },
];

import {
  ColorsV2,
  Flex,
  Icon,
  IconNames,
  RadioTile,
  Text,
  useThemeColors,
} from '@electricjs/arc';
import { DeviceRetrieveAction } from './types';

type DeviceRetrievalTileProps = {
  deviceRetrieveAction: DeviceRetrieveAction;
  value: DeviceRetrieveAction;
  optionText: string;
  priceText: string;
  additionalPriceText?: string;
  icon: IconNames;
  promotionalChip?: React.ReactNode;
};

const DeviceRetrievalTile = ({
  deviceRetrieveAction,
  value,
  optionText,
  priceText,
  additionalPriceText,
  icon,
  promotionalChip,
}: DeviceRetrievalTileProps) => {
  const [grayLight, primary] = useThemeColors([
    ColorsV2.GRAY_LIGHT,
    ColorsV2.PRIMARY,
  ]);

  return (
    <RadioTile
      id={`device-retrieval-tile-${value}`}
      height="15rem"
      width="17rem"
      border={`1px solid ${grayLight}`}
      borderRadius="8px"
      boxShadow={`0px 4px 8px -2px ${primary}1f`}
      value={value}
      checked={value === deviceRetrieveAction}>
      <Flex vertical hAlignContent="center">
        <Flex height="4rem" alignItems="center">
          <Icon height="3rem" icon={icon} size="large" mb="1rem" />
        </Flex>
        <Flex height="4rem" alignItems="center" mb="0.5rem">
          <Text
            textAlign="center"
            variant="body"
            color="inherit"
            fontWeight="inherit">
            {optionText}
          </Text>
        </Flex>
        <Flex vAlignContent="center">
          <Text variant="label-small" color="inherit">
            {priceText}
          </Text>
          {additionalPriceText && (
            <Text variant="body-2" color="inherit">
              {additionalPriceText}
            </Text>
          )}
        </Flex>
        {promotionalChip && promotionalChip}
      </Flex>
    </RadioTile>
  );
};

export default DeviceRetrievalTile;

import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useGetSiteByIdQuery } from '@/redux/slices/assetApiSlice';
import { useGetEmployeeQuery } from '@/redux/slices/employeeApiSlice';
import { Asset } from '@/types/assets';

/**
 *
 * @param {Asset} asset - The asset object containing site and the assigned employee information.
 * @returns {Object} - An object containing:
 *  - isLoading: A boolean indicating if the data is still being fetched.
 *  - address: A string representing the address of the asset.
 *  - isEmployeeAddress: A boolean indicating if the address is from the assigned employee.
 */

const useAssetAddress = (asset: Asset) => {
  const siteId = asset?.site?.id;
  const assignedTo = asset?.assignedTo;
  const organizationId = useGetOrganizationId();
  const { siteAddress, isFetching: isFetchingSiteData } = useGetSiteByIdQuery(
    {
      organizationId,
      siteId: siteId ?? '',
    },
    {
      skip: !siteId,
      selectFromResult: ({ data, isFetching }) => ({
        siteAddress: data?.address,
        isFetching,
      }),
    }
  );
  const { employeeAddress, isFetching: isFetchingEmployeeData } =
    useGetEmployeeQuery(
      {
        organizationId,
        employeeId: assignedTo ?? '',
      },
      {
        skip: !assignedTo,
        selectFromResult: ({ data, isFetching }) => ({
          employeeAddress: data?.address,
          isFetching,
        }),
      }
    );

  const employeeHasAddress = Object.values(employeeAddress ?? {}).some(Boolean);

  const address = Object.values(
    (employeeHasAddress ? employeeAddress : siteAddress) ?? {}
  )
    .filter(Boolean)
    .join(', ');

  return {
    isLoading: isFetchingEmployeeData || isFetchingSiteData,
    address,
    isEmployeeAddress: employeeHasAddress,
  };
};

export default useAssetAddress;

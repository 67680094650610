import { AssetHistoryWithChildren, AssetLogDeviceIssue } from '@/types/assets';
import { EmployeeColumn } from '@/components/DeviceOverview/DeviceHealthMonitor/ColumnsAndContainers/EmployeeColumn';
import { AssetLogRowContainer } from '@/components/DeviceOverview/DeviceHealthMonitor/ColumnsAndContainers/AssetLogRowContainer';
import { IssueTypeDescriptionColumnMap } from '@/components/DeviceOverview/DeviceHealthMonitor/NeedsAttention/DescriptionColumnMap';
import { ActionsColumnMap } from '@/components/DeviceOverview/DeviceHealthMonitor/NeedsAttention/ActionsColumnMap';
import { isDeviceIssueType } from '@/components/DeviceOverview/DeviceHealthMonitor/types';
import { useGetEmployeesWithAssets } from '@/hooks/useGetEmployeesWithAssets';
import { EmployeeStatus } from '@/types/employees';

// Function to check if an issue type is supported
// This function checks if the issueType is a valid DeviceIssueType and if it exists in IssueTypeDescriptionColumnMap
export const isSupportedType = (issueType?: string): boolean =>
  !!issueType &&
  isDeviceIssueType(issueType) &&
  !!IssueTypeDescriptionColumnMap[issueType];

export const NeedsAttentionRow = ({
  assetLog,
}: {
  assetLog: AssetHistoryWithChildren<AssetLogDeviceIssue>;
}) => {
  const { employee_id: employeeId, issue_type: issueType } = assetLog.data;

  const { assetId } = assetLog;

  // We will need the entire list of employees to render each row and may have already
  // requested it, so we will use the useGetEmployeesWithAssets hook to get the list
  // and select from the results to find the individual employee.
  // This prevents us from having to make a request to core entity for each unique user
  // in the list.
  const { employeesWithAssets, isFetching } = useGetEmployeesWithAssets({
    devicesOnly: true,
    listEmployeesQueryProps: {
      status: [EmployeeStatus.Active, EmployeeStatus.Inactive],
    },
  });

  if (isFetching) {
    return <AssetLogRowContainer isLoading />;
  }
  const employee = employeesWithAssets?.find(e => e.id === employeeId);

  if (!isSupportedType(issueType) || !employee) {
    return null;
  }

  const deviceInfo = employee.assets?.find(asset => asset.id === assetId);

  const { children } = assetLog;
  const lastEmailSentAt =
    children && children?.length > 0
      ? children[0].createdAt
      : assetLog.createdAt;

  return (
    <AssetLogRowContainer>
      <EmployeeColumn employee={employee} />
      {isDeviceIssueType(issueType) &&
        IssueTypeDescriptionColumnMap[issueType]?.({
          assetId: deviceInfo?.id,
          serialNumber: deviceInfo?.serialNumber,
          lastEmailSentAt,
        })}
      {employee && isDeviceIssueType(issueType)
        ? ActionsColumnMap[issueType]?.(assetLog, employee)
        : null}
    </AssetLogRowContainer>
  );
};

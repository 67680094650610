import { emailRegex } from '@/constants/email';
import * as yup from 'yup';

export const mdmAppleSetupFormSchema = yup.object().shape({
  appleId: yup
    .string()
    .matches(
      emailRegex,
      'Invalid email address. The email format must be johndoe@example.com'
    )
    .required('This field is required'),
  appleSignedCert: yup.string().required('This field is required'),
});

export type FormData = {
  appleId: string;
  appleSignedCert: string;
};

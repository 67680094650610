import { Auth0User, Auth0UserKey } from '@externalTypes/auth0';
import { createSlice } from '@reduxjs/toolkit';

import { employeeApiSlice } from '@/redux/slices/employeeApiSlice';
import { organizationApiSlice } from '@/redux/slices/organizationApiSlice';
import { userApiSlice } from '@/redux/slices/userApiSlice';
import { ElectricPermission, LoggedUser } from '@/types/users';

const loggedUserSlice = createSlice({
  name: 'loggedUser',
  initialState: null as LoggedUser | null,
  reducers: {
    loggedIn: (state, action) => {
      const auth0User = action.payload.auth0User as Auth0User; // auth0User contains the JWT decoded data
      const accessToken = action.payload.accessToken;

      // initialize the loggedUser with the basic data (from Auth0 hook)
      if (state) {
        state.accessToken = accessToken;
        state.name = auth0User.name; // The auth0user's name contains the "email" instead of the name. We don't have the user's name (only the employee's name).
        state.email = auth0User.email;
        state.electricPermissions = auth0User[
          Auth0UserKey.ELECTRIC_PERMISSIONS
        ] as ElectricPermission[];
        state.adp_sso_login = auth0User[Auth0UserKey.ADP_SSO_LOGIN];
      } else {
        state = {
          accessToken,
          email: auth0User.email,
          name: auth0User.name,
          electricPermissions: auth0User[
            Auth0UserKey.ELECTRIC_PERMISSIONS
          ] as ElectricPermission[],
          adp_sso_login: auth0User[Auth0UserKey.ADP_SSO_LOGIN],
        };
      }

      return state;
    },
    loggedOut: () => {
      return null;
    },
    organizationSelected: (state, action) => {
      if (state) {
        state.organizationId = action.payload.organizationId;
        state.organizationUserRoles = action.payload.organizationUserRoles;
        state.employeeId = action.payload.employeeId;
      }
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        userApiSlice.endpoints.getMyUserData.matchFulfilled,
        (state, action) => {
          // Fill up the loggedUser with extra data (received from API)
          if (state) {
            // We retrieve some information from the Auth0 JWT already (e.g., inside "loggedIn" action), so check the actions is this slice before adding more data.
            // Add any extra data from the "/users/me" endpoint here
            state.id = action.payload.id;
            state.allUserOrganizationRoles = action.payload.organizations;
          }
        }
      )
      .addMatcher(
        organizationApiSlice.endpoints.getOrganization.matchFulfilled,
        (state, action) => {
          // Fill up the loggedUser with extra data (received from Organizations endpoint)
          // Use only the "getOrganization" that was called for the loggedUser's organizationId
          if (state && state.organizationId === action.meta.arg.originalArgs) {
            state.organizationName = action.payload.name;
            state.organizationPlanName = action.payload.plan?.slug;
            state.organizationLicenseCount = action.payload.licenseCount;
            state.organizationSalesforceId = action.payload.salesforceId;
            state.organizationEntitlementSlugs =
              action.payload.entitlements.map(entitlement => entitlement.slug);
            state.organizationHrisIntegration = action.payload.hrisIntegration;
            state.organizationInternalType = action.payload.internalType;
          }
        }
      )
      .addMatcher(
        employeeApiSlice.endpoints.getMyEmployee.matchFulfilled,
        (state, action) => {
          // Fill up the loggedUser with extra data (received from MyEmployee endpoint)
          if (state && state.organizationId) {
            state.employeeId = action.payload.id;
            state.employeeFirstName = action.payload.firstName;
            state.employeeLastName = action.payload.lastName;
            state.employeeJobTitle = action.payload.jobTitle;
          }
        }
      );
  },
});

export const { loggedIn, loggedOut, organizationSelected } =
  loggedUserSlice.actions;

export default loggedUserSlice;

import { useFetchADPCredentialsQuery } from '@/redux/slices/hrisIntegrationApiSlice';
import { useAppSelector } from '@/redux/store';

import ADPConsentRequiredBanner from './ADPConsentRequiredBanner';
import { HRISProviderSlugEnum } from '@electricjs/core_entity-client';

const getADPConsentRedirectURL = (
  successUri: string,
  activeADPIntegration: HRISProviderSlugEnum
) => {
  const ADP_CONSUMER_APPLICATION_ID =
    activeADPIntegration === HRISProviderSlugEnum.AdpR
      ? window._env_?.VITE_ADP_RUN_CONSUMER_APPLICATION_ID
      : activeADPIntegration === HRISProviderSlugEnum.AdpWfn // Just making it explicit
        ? window._env_?.VITE_ADP_WFN_CONSUMER_APPLICATION_ID
        : window._env_?.VITE_ADP_WFN_CONSUMER_APPLICATION_ID; // Fallback to WFN

  return `https://adpapps.adp.com/consent-manager/pending/direct?consumerApplicationID=${ADP_CONSUMER_APPLICATION_ID}&successUri=${successUri}`;
};

const ADPConsentRequiredBannerWrapper = () => {
  const loggedUser = useAppSelector(state => state.loggedUser);
  const organizationHrisIntegration = loggedUser?.organizationHrisIntegration;

  const isADPWFNConnectedOrganization =
    organizationHrisIntegration?.hrisProvider.slug ===
    HRISProviderSlugEnum.AdpWfn;
  const isADPRUNConnectedOrganization =
    organizationHrisIntegration?.hrisProvider.slug ===
    HRISProviderSlugEnum.AdpR;

  const isADPConnectedOrganization =
    isADPWFNConnectedOrganization || isADPRUNConnectedOrganization;
  const isADPPurchaser =
    loggedUser?.id === organizationHrisIntegration?.createdBy;

  // Check to see if the organization's ADP credentials exist in our database
  // If they don't exist, we'll try to fetch them with this call
  const { data: adpCredentialsExist } = useFetchADPCredentialsQuery(
    {
      organizationId: loggedUser?.organizationId ?? '',
      hrisIntegrationId: organizationHrisIntegration?.id ?? '',
    },
    { skip: !isADPConnectedOrganization || !isADPPurchaser }
  );

  const adpConsentRedirectURL = getADPConsentRedirectURL(
    // Encode the URL to prevent XSS attacks
    encodeURI(window.location.href),
    isADPRUNConnectedOrganization
      ? HRISProviderSlugEnum.AdpR
      : isADPWFNConnectedOrganization // Just making it explicit
        ? HRISProviderSlugEnum.AdpWfn
        : HRISProviderSlugEnum.AdpWfn // Fallback to WFN
  );

  // Don't render the banner until we know the credentials don't exist
  return isADPConnectedOrganization &&
    isADPPurchaser &&
    adpCredentialsExist === false ? (
    <ADPConsentRequiredBanner adpConsentRedirectURL={adpConsentRedirectURL} />
  ) : null;
};

export default ADPConsentRequiredBannerWrapper;

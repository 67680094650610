export default function formatName({
  firstName,
  lastName,
}: {
  firstName?: string;
  lastName?: string;
}): string {
  if (!firstName && !lastName) {
    return '';
  }

  return `${firstName ?? ''} ${lastName ?? ''}`.trim();
}

import { Control, UseFormSetValue } from 'react-hook-form';
import { StepContainer } from '@/components/MDMEnrollment/MDMAppleForm/StepComponents';
import {
  Field,
  Flex,
  Text,
  TextInputField,
  Button,
  useThemeColors,
  ColorsV2,
  IconInCircle,
  IconButton,
} from '@electricjs/arc';
import { FormData } from '@/components/MDMEnrollment/MDMAppleForm/MdmAppleSetupFormSchema';
import { MDMInformation } from '@/types/mdmManagement';
import { useState } from 'react';

export const ProvideAppleIdStep = ({
  control,
  setValue,
  existingCertIfRenewal,
}: {
  control: Control<FormData, unknown>;
  setValue: UseFormSetValue<FormData>;
  existingCertIfRenewal?: MDMInformation;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [hasConfirmed, setHasConfirmed] = useState(false);
  const [iconColor, backgroundCompletedColor] = useThemeColors([
    ColorsV2.WHITE,
    ColorsV2.SUCCESS,
  ]);

  const enterAppleIdContainer = () => {
    return (
      <StepContainer step={4}>
        <Text variant="label-large">Provide your Apple ID</Text>
        <Text variant="body">
          Provide your company&apos;s Apple ID email address that you will use
          to manage all Apple devices for your organization.
        </Text>
        <Field maxWidth="45rem">
          <TextInputField
            id={'apple-id'}
            name="appleId"
            label="Apple ID email address"
            control={control}
            required={true}
          />
        </Field>
      </StepContainer>
    );
  };

  if (!existingCertIfRenewal) {
    return enterAppleIdContainer();
  }

  const editAppleIdContainer = () => {
    return (
      <StepContainer step={4}>
        <Text variant="label-large">Provide your Apple ID</Text>
        <Text variant="body">
          Provide your company&apos;s Apple ID email address that you will use
          to manage all Apple devices for your organization.
        </Text>
        <Field maxWidth="45rem">
          <TextInputField
            id={'apple-id'}
            name="appleId"
            label="Apple ID email address"
            helperText={`Please use the same email address as your original certificate ${existingCertIfRenewal.appleCertificate?.creatorAppleId} if possible or you will need to re-enroll devices in MDM.`}
            control={control}
            required={true}
          />
        </Field>
      </StepContainer>
    );
  };

  if (isEditing) {
    return editAppleIdContainer();
  }

  const onConfirmAppleId = () => {
    setValue(
      'appleId',
      existingCertIfRenewal.appleCertificate?.creatorAppleId ?? ''
    );
    setIsEditing(false);
    setHasConfirmed(true);
  };

  const existingAppleIdContainer = () => {
    return (
      <StepContainer step={4}>
        <Text variant="label-large">Confirm your Apple ID</Text>
        <Text variant="body">
          Your company&apos;s Apple ID email address:
          <br />
          <b>{existingCertIfRenewal.appleCertificate?.creatorAppleId}</b>
          <br />
          <br />
          Please use the same email address as your original certificate if
          possible or you will need to re-enroll devices in MDM.
        </Text>
        <Flex>
          <Button
            mr="2rem"
            id="confirm-apple-id"
            variant="outline"
            onClick={onConfirmAppleId}>
            Yes, this is correct
          </Button>
          <Button
            id="update-apple-id"
            variant="text"
            onClick={() => setIsEditing(true)}>
            No, update Apple ID
          </Button>
        </Flex>
      </StepContainer>
    );
  };

  const confirmedAppleIdContainer = () => {
    return (
      <StepContainer step={4}>
        <Text variant="label-large">Confirm your Apple ID</Text>
        <Flex>
          <Text variant="body">
            Your company&apos;s Apple ID email address:
            <br />
            <b>{existingCertIfRenewal.appleCertificate?.creatorAppleId}</b>
          </Text>
          <IconButton
            id="edit-apple-id"
            alt="edit"
            icon="pencil"
            onClick={() => setIsEditing(true)}
          />
        </Flex>
        <Flex>
          <IconInCircle
            icon="check"
            iconSize="small"
            color={iconColor}
            size="2.4rem"
            background={backgroundCompletedColor}
          />
          <Text alignSelf={'center'} ml="1rem">
            <i>Apple ID confirmed</i>
          </Text>
        </Flex>
      </StepContainer>
    );
  };

  if (hasConfirmed) {
    return confirmedAppleIdContainer();
  }

  return existingAppleIdContainer();
};

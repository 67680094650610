import { ReactNode } from 'react';
import { DescriptionColumn } from '@/components/DeviceOverview/DeviceHealthMonitor/ColumnsAndContainers/DescriptionColumn';
import { DeviceIssueType } from '@/types/devices';
import { formatTimestamp } from '@/helpers/DateHelpers';

type mapFunctionProps = {
  assetId?: string;
  lastEmailSentAt?: string;
  serialNumber?: string;
};

type IssueTypeDescriptionColumnMapType = {
  [key in DeviceIssueType]?: (props: mapFunctionProps) => ReactNode;
};

export const IssueTypeDescriptionColumnMap: IssueTypeDescriptionColumnMapType =
  {
    [DeviceIssueType.Firewall]: ({
      assetId,
      lastEmailSentAt,
      serialNumber,
    }: mapFunctionProps) => (
      <DescriptionColumn
        assetId={assetId}
        iconType="WARNING"
        title="Missing firewall"
        serialNumber={serialNumber}
        detail={`Electric emailed employee on ${formatTimestamp(lastEmailSentAt)}.`}
      />
    ),
    [DeviceIssueType.Gatekeeper]: ({
      assetId,
      lastEmailSentAt,
      serialNumber,
    }: mapFunctionProps) => (
      <DescriptionColumn
        assetId={assetId}
        iconType="WARNING"
        title="Missing gatekeeper"
        serialNumber={serialNumber}
        detail={`Electric emailed employee on ${formatTimestamp(lastEmailSentAt)}.`}
      />
    ),
    [DeviceIssueType.OS]: ({
      assetId,
      lastEmailSentAt,
      serialNumber,
    }: mapFunctionProps) => (
      <DescriptionColumn
        assetId={assetId}
        iconType="WARNING"
        title="OS out of date"
        serialNumber={serialNumber}
        detail={`Electric emailed employee on ${formatTimestamp(lastEmailSentAt)}.`}
      />
    ),
    [DeviceIssueType.Encryption]: ({
      assetId,
      lastEmailSentAt,
      serialNumber,
    }: mapFunctionProps) => (
      <DescriptionColumn
        assetId={assetId}
        iconType="WARNING"
        title="Missing encryption"
        serialNumber={serialNumber}
        detail={`Electric emailed employee on ${formatTimestamp(lastEmailSentAt)}.`}
      />
    ),
    [DeviceIssueType.Storage]: ({
      assetId,
      lastEmailSentAt,
      serialNumber,
    }: mapFunctionProps) => (
      <DescriptionColumn
        assetId={assetId}
        iconType="WARNING"
        title="Low disk space alert"
        serialNumber={serialNumber}
        detail={`Electric emailed employee on ${formatTimestamp(lastEmailSentAt)}.`}
      />
    ),
    [DeviceIssueType.RAM]: ({ serialNumber, assetId }: mapFunctionProps) => (
      <DescriptionColumn
        assetId={assetId}
        iconType="WARNING"
        title="Low RAM"
        serialNumber={serialNumber}
        detail="We recommend replacing their device."
      />
    ),
    /* TODO HY-218 We're temporarily disabling Battery health while we improve the logic behind it */
    // [DeviceIssueType.BatteryHealth]: serialNumber?: string => (
    //   <DescriptionColumn
    //     iconType="ALERT"
    //     title="Poor battery health"
    //     serialNumber={serialNumber}
    //     detail={`This device has poor battery performance. We recommend a replacement.`}
    //   />
    // ),
    [DeviceIssueType.OsqueryNotReporting]: ({
      assetId,
      lastEmailSentAt,
      serialNumber,
    }: mapFunctionProps) => (
      <DescriptionColumn
        assetId={assetId}
        iconType="WARNING"
        title="Lost Electric app connection"
        serialNumber={serialNumber}
        detail={`Electric emailed employee requesting them to reboot on ${formatTimestamp(lastEmailSentAt)}. If problems persist, try reinstalling the Electric application.`}
      />
    ),
    [DeviceIssueType.MdmNotReporting]: ({
      assetId,
      lastEmailSentAt,
      serialNumber,
    }: mapFunctionProps) => (
      <DescriptionColumn
        assetId={assetId}
        iconType="WARNING"
        title="Lost MDM connection"
        serialNumber={serialNumber}
        detail={`Electric emailed employee requesting them to reboot on ${formatTimestamp(lastEmailSentAt)}.`}
      />
    ),
    [DeviceIssueType.DeviceLock]: ({
      assetId,
      lastEmailSentAt,
      serialNumber,
    }: mapFunctionProps) => (
      <DescriptionColumn
        assetId={assetId}
        iconType="ALERT"
        title="Lost device is locked"
        serialNumber={serialNumber}
        detail={`This device was locked on ${formatTimestamp(lastEmailSentAt)}. When the device is found, you can unlock the device from the employee's profile.`}
      />
    ),
  } as const;

export type IssueTypeColumnMapType = keyof typeof IssueTypeDescriptionColumnMap;

import {
  type ReactNode,
  createContext,
  useContext,
  useState,
  useCallback,
} from 'react';
import { useForm, type UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { v4 as uuid } from 'uuid';

import { requiredAddressValidationSchema, type Address } from '@electricjs/arc';

import { phoneValidationSchema } from '@common';

import { emailRegex } from '@/constants/email';
import { type PaymentMethodOption } from '@/types/billing';
import {
  ProvisioningType,
  type ProvisioningOption,
  type ShippingDestinationOption,
  type ShippingMethodOption,
} from '@/types/storefront';

import { type EmployeeOption } from '@/components/People/EmployeeGroups/NewEmployeeGroupForm';

import { provisioningSelectionOptions } from '../utils/formatters';

export enum SchemaOption {
  DEFAULT = 'DEFAULT',
  EMPLOYEE = 'EMPLOYEE',
  SHIPPING = 'SHIPPING',
  PAYMENT = 'PAYMENT',
}

type StorefrontCheckoutFormContextType = {
  storefrontCheckoutForm?: UseFormReturn<StorefrontCheckoutFormData>;
  orderKey: string;
  regenerateOrderKey: () => void;
  selectedShippingDestination?: ShippingDestinationOption;
  setSelectedShippingDestination: (option?: ShippingDestinationOption) => void;
  schemaOption: SchemaOption;
  setSchemaOption: (schemaOption: SchemaOption) => void;
};

type StorefrontCheckoutFormProviderProps = {
  children: ReactNode;
};

export type StorefrontCheckoutFormData = {
  employee?: Partial<EmployeeOption>;
  provisioning?: Partial<ProvisioningOption>;
  shippingName?: string;
  shippingEmail?: string;
  shippingPhone?: string;
  shippingAddress?: Address;
  shippingMethod?: ShippingMethodOption;
  notes?: string;
  paymentMethod?: PaymentMethodOption;
};

const StorefrontCheckoutFormContext =
  createContext<StorefrontCheckoutFormContextType>({
    storefrontCheckoutForm: undefined,
    orderKey: '',
    regenerateOrderKey: () => {},
    selectedShippingDestination: undefined,
    setSelectedShippingDestination: () => {},
    schemaOption: SchemaOption.EMPLOYEE,
    setSchemaOption: () => {},
  });

export const emailSeparator = ',';

export const StorefrontCheckoutFormProvider = (
  props: StorefrontCheckoutFormProviderProps
) => {
  const [orderKey, setOrderKey] = useState(uuid());
  const [selectedShippingDestination, setSelectedShippingDestination] =
    useState<ShippingDestinationOption>();
  const [schemaOption, setSchemaOption] = useState<SchemaOption>(
    SchemaOption.DEFAULT
  );

  const defaultSchema = yup.object();

  const employeeSchema = yup.object<StorefrontCheckoutFormData>().shape({
    employee: yup.object().required('This field is required'),
  });

  const isEmail = (value: string) => emailRegex.test(value);

  const shippingSchema = yup.object<StorefrontCheckoutFormData>().shape({
    provisioning: yup.object().shape({
      value: yup
        .mixed<ProvisioningType>()
        .oneOf(Object.values(ProvisioningType))
        .required('This field is required'),
    }),
    shippingName: yup.string().required('This field is required'),
    shippingEmail: yup
      .string()
      .trim()
      .required('This field is required')
      .test('emails', 'Enter a valid email', value =>
        value.split(emailSeparator).every(email => isEmail(email.trim()))
      ),
    shippingPhone: phoneValidationSchema({ required: true }),
    shippingAddress: requiredAddressValidationSchema,
    shippingMethod: yup.object().required('This field is required'),
    notes: yup.string().max(120, '120 characters max'),
  });

  const paymentSchema = yup.object<StorefrontCheckoutFormData>().shape({
    paymentMethod: yup
      .object()
      .required('Please select a valid payment method'),
  });

  const schemaTranslation = {
    [SchemaOption.DEFAULT]: defaultSchema,
    [SchemaOption.EMPLOYEE]: employeeSchema,
    [SchemaOption.SHIPPING]: shippingSchema,
    [SchemaOption.PAYMENT]: paymentSchema,
  };

  const defaultValues: StorefrontCheckoutFormData = {
    employee: undefined,
    provisioning: provisioningSelectionOptions.find(
      option => option.value === ProvisioningType.Remote
    ),
    shippingName: '',
    shippingPhone: '',
    shippingEmail: '',
    shippingAddress: {
      streetAddress1: '',
      streetAddress2: '',
      city: '',
      state: '',
      country: '',
      zip: '',
    },
    shippingMethod: undefined,
    notes: '',
    paymentMethod: undefined,
  };

  const storefrontCheckoutForm = useForm<StorefrontCheckoutFormData>({
    resolver: yupResolver(schemaTranslation[schemaOption]),
    defaultValues,
    mode: 'onTouched',
  });

  return (
    <StorefrontCheckoutFormContext.Provider
      value={{
        storefrontCheckoutForm,
        orderKey,
        regenerateOrderKey: useCallback(() => setOrderKey(uuid()), []),
        selectedShippingDestination,
        setSelectedShippingDestination: useCallback(
          option => setSelectedShippingDestination(option),
          []
        ),
        schemaOption,
        setSchemaOption,
      }}>
      {props.children}
    </StorefrontCheckoutFormContext.Provider>
  );
};

export const useStorefrontCheckoutForm = () => {
  const {
    storefrontCheckoutForm,
    orderKey,
    regenerateOrderKey,
    selectedShippingDestination,
    setSelectedShippingDestination,
    schemaOption,
    setSchemaOption,
  } = useContext(StorefrontCheckoutFormContext);

  return {
    storefrontCheckoutForm,
    /** Idempotent key of the order (must be regenerated after each order submission, even if it has failed) */
    orderKey,
    regenerateOrderKey,
    selectedShippingDestination,
    setSelectedShippingDestination,
    schemaOption,
    setSchemaOption,
  };
};

import {
  useGetRequestByIdQuery,
  useGetRequestTypeByIdQuery,
} from '@/redux/slices/requestApiSlice';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useState, useEffect } from 'react';

export function useGetRequestWithTypeById(requestId: string) {
  const [requestTypeId, setRequestTypeId] = useState<string | null>(null);
  const organizationId = useGetOrganizationId();

  const {
    data: requestData,
    isLoading: isRequestLoading,
    isError: isRequestError,
    error: requestError,
  } = useGetRequestByIdQuery({ requestId, organizationId });

  const logError = (message: string) => {
    window?.DD_RUM?.addError(message, {
      location: 'Get request application data',
    });
  };

  useEffect(() => {
    if (requestError) {
      logError(
        `Error fetching request with requestId: ${requestId} and organizationId: ${organizationId}. Error: ${requestError}`
      );
    }
  }, [organizationId, requestError, requestId]);

  useEffect(() => {
    if (requestData) {
      setRequestTypeId(requestData.requestTypeId);
    }
  }, [requestData]);

  const {
    data: requestTypeData,
    isLoading: isRequestTypeLoading,
    isError: isRequestTypeError,
    error: requestTypeError,
  } = useGetRequestTypeByIdQuery(
    { requestTypeId: requestTypeId ?? '' },
    {
      skip: !requestTypeId,
    }
  );

  useEffect(() => {
    if (requestTypeError) {
      logError(
        `Error fetching requestType with requestTypeId: ${requestTypeId}. Error: ${requestTypeError}`
      );
    }
  }, [organizationId, requestTypeError, requestTypeId]);

  const isLoading = isRequestLoading || isRequestTypeLoading;
  const isError = isRequestError || isRequestTypeError;
  const error = requestError || requestTypeError;

  return {
    data: { request: requestData, requestType: requestTypeData },
    isLoading,
    isError,
    error,
  };
}

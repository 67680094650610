import {
  Box,
  Button,
  Flex,
  Header,
  OneColumnLayout,
  Radio,
  RadioGroup,
  Text,
} from '@electricjs/arc';
import {
  useGetOrganizationSitesQuery,
  useUpdateAssetMutation,
} from '@/redux/slices/assetApiSlice';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import DeviceCard from './DeviceCard';
import { DeviceRetrieveAction } from './types';
import { AssetStatus } from '@electricjs/core_entity-client';
import SiteGroupField from './DeviceRetrieval/SiteGroupField';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ControlledCheckbox, OutlineChip } from '@common';
import { formatPhoneNumber } from 'common/utils/formatPhoneNumber';
import {
  ELECTRIC_WAREHOUSE_SITE_NAME,
  ELECTRIC_WAREHOUSE_PHONE_NUMBER,
} from '@/constants/assets';
import DeviceRetrievalPolicy from './DeviceRetrievalPolicy';
import DeviceRetrievalTile from './DeviceRetrievalTile';

import { Asset } from '@/types/assets';

type DeviceRetrievalProps = {
  asset: Asset;
  onNext: () => void;
  onPrevious: () => void;
  shouldDisablePrevious: boolean;
};

const DeviceRetrieval = ({
  asset,
  onNext,
  onPrevious,
  shouldDisablePrevious,
}: DeviceRetrievalProps) => {
  const { electrolyteWarehousing, itHubWarehousingWithStripe } = useFlags();

  const organizationId = useGetOrganizationId();
  const { control, setValue } = useFormContext();

  const deviceRetrieveAction = useWatch({ name: 'deviceRetrieveAction' });
  const siteValues = useWatch({ name: 'receiverSite' });
  const feeAccepted = useWatch({ name: 'feeAccepted' });

  const [updateAsset, { isLoading: isUpdatingAsset }] =
    useUpdateAssetMutation();

  const { warehouseSite, isFetchingOrganizationSites } =
    useGetOrganizationSitesQuery(
      { organizationId },
      {
        skip: deviceRetrieveAction !== DeviceRetrieveAction.Warehouse,
        selectFromResult: ({
          data: sites,
          isFetching: isFetchingOrganizationSites,
        }) => {
          return {
            warehouseSite: sites?.find(
              site =>
                !site.organizationId &&
                site.name === ELECTRIC_WAREHOUSE_SITE_NAME
            ),
            isFetchingOrganizationSites,
          };
        },
      }
    );

  const handleNext = async () => {
    const assetId = asset.id;
    let newAssetStatus;

    if (!assetId) {
      return;
    }

    if (deviceRetrieveAction === DeviceRetrieveAction.Ship) {
      onNext();
      return;
    }

    if (deviceRetrieveAction === DeviceRetrieveAction.Keep) {
      newAssetStatus = AssetStatus.Disposed;
    }

    if (deviceRetrieveAction === DeviceRetrieveAction.InPerson) {
      newAssetStatus = AssetStatus.InTransit;
    }

    if (deviceRetrieveAction === DeviceRetrieveAction.Warehouse) {
      if (warehouseSite) {
        setValue('receiverSite', {
          label: warehouseSite.name,
          name: warehouseSite.name,
          value: warehouseSite.id,
        });
        setValue(
          'receiverPhone',
          formatPhoneNumber(ELECTRIC_WAREHOUSE_PHONE_NUMBER)
        );
      }
      onNext();
      return;
    }

    await updateAsset({
      organizationId,
      assetId,
      status: newAssetStatus,
    });
    onNext();
  };

  const disableNext =
    !deviceRetrieveAction ||
    (deviceRetrieveAction === DeviceRetrieveAction.Ship && !siteValues) ||
    (deviceRetrieveAction === DeviceRetrieveAction.Warehouse &&
      (!feeAccepted || isFetchingOrganizationSites));

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Reset conditional fields when changing the radio option
    if (e.target.value !== DeviceRetrieveAction.Warehouse) {
      setValue('receiverSite', null);
      setValue('receiverPhone', null);
      setValue('feeAccepted', false);
    }
  };

  return (
    <OneColumnLayout>
      <Header title="How would you like this device retrieved?" />
      <DeviceCard asset={asset} />
      <Text mt="4.4rem" mb="2rem">
        Electric can assist with the process based on how you prefer the device
        be returned.
      </Text>
      {itHubWarehousingWithStripe ? (
        <Controller
          name="deviceRetrieveAction"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field} radioTilesGap="1.6rem">
              {electrolyteWarehousing ? (
                <DeviceRetrievalTile
                  deviceRetrieveAction={deviceRetrieveAction}
                  value={DeviceRetrieveAction.Warehouse}
                  optionText="Ship to Electric Warehouse"
                  priceText="$95"
                  additionalPriceText="&nbsp; + mo. fee"
                  icon="warehouse"
                  promotionalChip={
                    <OutlineChip
                      id="warehousing-promotional-discount-chip"
                      label="Promotional Discount"
                    />
                  }
                />
              ) : (
                <></>
              )}
              <DeviceRetrievalTile
                deviceRetrieveAction={deviceRetrieveAction}
                value={DeviceRetrieveAction.Ship}
                optionText="Ship to company site"
                priceText="$95"
                icon="building"
              />
              <DeviceRetrievalTile
                deviceRetrieveAction={deviceRetrieveAction}
                value={DeviceRetrieveAction.InPerson}
                optionText="Return in person"
                priceText="No charge"
                icon="person-with-box"
              />
              <DeviceRetrievalTile
                deviceRetrieveAction={deviceRetrieveAction}
                value={DeviceRetrieveAction.Keep}
                optionText="Employee keeps the device"
                priceText="No charge"
                icon="laptop"
              />
            </RadioGroup>
          )}
        />
      ) : (
        <Controller
          name="deviceRetrieveAction"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field} stack mt="2.4rem" gap="0.8rem">
              <Radio
                id="radio-option-ship"
                value={DeviceRetrieveAction.Ship}
                onChange={handleRadioChange}
                checked={deviceRetrieveAction === DeviceRetrieveAction.Ship}
                disabled={isUpdatingAsset}>
                <Text variant="subheading">
                  The employee will ship the device to a company site
                </Text>
              </Radio>
              <Radio
                id="radio-option-in-person"
                value={DeviceRetrieveAction.InPerson}
                onChange={handleRadioChange}
                checked={deviceRetrieveAction === DeviceRetrieveAction.InPerson}
                disabled={isUpdatingAsset}>
                <Text variant="subheading">
                  The employee will return the device in person
                </Text>
              </Radio>
              <Radio
                id="radio-option-keep"
                value={DeviceRetrieveAction.Keep}
                onChange={handleRadioChange}
                checked={deviceRetrieveAction === DeviceRetrieveAction.Keep}
                disabled={isUpdatingAsset}>
                <Text variant="subheading">
                  The employee will keep the device past employment
                </Text>
              </Radio>
              {electrolyteWarehousing ? (
                <Radio
                  id="radio-option-warehousing"
                  value={DeviceRetrieveAction.Warehouse}
                  onChange={handleRadioChange}
                  checked={
                    deviceRetrieveAction === DeviceRetrieveAction.Warehouse
                  }
                  disabled={isUpdatingAsset}>
                  <Text variant="subheading">
                    The employee will ship to the Electric warehouse
                  </Text>
                </Radio>
              ) : (
                <></>
              )}
            </RadioGroup>
          )}
        />
      )}
      {itHubWarehousingWithStripe && (
        <DeviceRetrievalPolicy deviceRetrieveAction={deviceRetrieveAction} />
      )}
      {deviceRetrieveAction === DeviceRetrieveAction.Warehouse && (
        <Box mt="2.4rem">
          <ControlledCheckbox
            id="no-charger-fee-checkbox"
            name="feeAccepted"
            label="I agree to cover a fee of up to $200 if my employee returns a laptop without a charger to the Electric warehouse"
            control={control}
          />
        </Box>
      )}
      {deviceRetrieveAction === DeviceRetrieveAction.Ship && (
        <Box mt="2.4rem">
          <SiteGroupField
            name="receiverSite"
            maxWidth="50rem"
            shouldShowAddress
            required
          />
        </Box>
      )}
      <Flex mt="3rem" hAlignContent="between" gap="3.4rem" width="100%">
        <Button
          id="devices-retrieval-previous-button"
          variant="outline"
          onClick={onPrevious}
          disabled={isUpdatingAsset || shouldDisablePrevious}>
          Previous
        </Button>
        <Button
          id="devices-retrieval-next-button"
          onClick={handleNext}
          loading={isUpdatingAsset}
          disabled={disableNext}>
          Next
        </Button>
      </Flex>
    </OneColumnLayout>
  );
};

export default DeviceRetrieval;

import {
  CollapseHeader,
  Box,
  useThemeColors,
  ColorsV2,
  useCollapse,
  Skeleton,
} from '@electricjs/arc';
import { StyledCollapse } from '@common';

const LoadingMenuFilter = () => {
  const [borderColor] = useThemeColors([ColorsV2.BORDER]);

  const { visible, setVisible } = useCollapse({
    visible: false,
  });

  return (
    <Box>
      <StyledCollapse
        contained
        $someOptionIsChecked={false}
        borderColor={borderColor}
        onToggle={() => setVisible(e => !e)}
        visible={visible}
        disabled>
        <CollapseHeader minHeight="unset" borderRadius="1rem" py="0.8rem">
          <Box width="4rem">
            <Skeleton />
          </Box>
        </CollapseHeader>
      </StyledCollapse>
    </Box>
  );
};

export default LoadingMenuFilter;

import { useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Box,
  Button,
  DatePickerField,
  Divider,
  Flex,
  Radio,
  RadioGroup,
  SelectField,
  Text,
  TextAreaField,
  TextInputField,
} from '@electricjs/arc';
import { PermissionEnum } from '@electricjs/core_entity-client';

import { assetSchema } from '@/components/Assets/AssetSchema';
import {
  AssetConditionOption,
  AssetStatusOption,
  getAssetStatusOptions,
  LocationOption,
  ProductTypeOption,
  SiteOption,
  transformAssetConditionsToOptions,
  transformLocationsToOptions,
  transformProductTypesToOptions,
  transformSitesToOptions,
} from '@/components/Assets/helpers/transformOptions';
import { useGlobalUI } from '@/components/GlobalUIProvider';
import { EmployeeOption } from '@/components/People/EmployeeGroups/NewEmployeeGroupForm';
import { transformEmployeesToOptions } from '@/components/People/transformOptions';
import { ELECTRIC_WAREHOUSE_SITE_NAME } from '@/constants/assets';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useOrganizationEmployees } from '@/hooks/useOrganizationEmployees';
import { useOrganizationProductTypes } from '@/hooks/useOrganizationProductTypes';
import { useUserHasOrganizationPermission } from '@/hooks/useUserHasOrganizationPermission';
import {
  useCreateAssetsMutation,
  useGetOrganizationSitesQuery,
  useGetSiteLocationsQuery,
} from '@/redux/slices/assetApiSlice';

import SiteModal from './SiteModal';

export type AssetFormData = {
  name: string;
  productType: ProductTypeOption | null;
  customAssetId: string;
  site?: SiteOption | null;
  purchaseDate?: Date;
  purchasePrice?: string | null;
  purchasedFrom: string;
  brand: string;
  model: string;
  serialNumber: string;
  notes: string;
  internalNotes: string;
  assignedTo?: EmployeeOption | null;
  status: AssetStatusOption | null;
  condition: AssetConditionOption | null;
  imageUrl: string;
  warrantyExpirationDate?: Date;
  location?: LocationOption | null;
};

export const assetDefaultValues: AssetFormData = {
  name: '',
  productType: null,
  customAssetId: '',
  assignedTo: null,
  purchaseDate: undefined,
  purchasePrice: null,
  purchasedFrom: '',
  brand: '',
  model: '',
  serialNumber: '',
  notes: '',
  internalNotes: '',
  site: null,
  status: null,
  condition: null,
  imageUrl: '',
  warrantyExpirationDate: undefined,
  location: null,
};

// @TODO: move data fetch to parent component to show an error message if the fetch fails
const NewAssetForm = () => {
  const { showErrorToast, showSuccessToast } = useGlobalUI();
  const { openModal, closeModal } = useGlobalUI();
  const navigate = useNavigate();
  const conditionOptions = transformAssetConditionsToOptions();
  const statusOptions = getAssetStatusOptions();
  const canAddInternalNotes = useUserHasOrganizationPermission(
    PermissionEnum.Warehousingcreate
  );
  const [hasAssetAssignment, setHasAssetAssignment] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { isValid },
    watch,
    reset,
    setError,
    clearErrors,
    setValue,
  } = useForm<AssetFormData>({
    resolver: yupResolver(assetSchema),
    mode: 'onTouched',
    defaultValues: assetDefaultValues,
  });
  const siteValue = useWatch({ name: 'site', control });

  const { productTypes, isFetching } = useOrganizationProductTypes();
  const productTypeOptions = transformProductTypesToOptions(productTypes);

  const organizationId = useGetOrganizationId();

  const { data: sites, isFetching: isSitesFetching } =
    useGetOrganizationSitesQuery({
      organizationId,
    });
  const siteOptions = sites ? transformSitesToOptions(sites) : [];

  const canReadAssetLocation = useUserHasOrganizationPermission(
    PermissionEnum.Assetslocationread
  );
  const canUpdateAssetLocation = useUserHasOrganizationPermission(
    PermissionEnum.Assetslocationupdate
  );

  const warehouseSite = sites?.find(
    site => site.name === ELECTRIC_WAREHOUSE_SITE_NAME && !site.organizationId
  );
  const isWarehouseSelected =
    !!siteValue && siteValue?.value === warehouseSite?.id;

  const canUseLocations =
    isWarehouseSelected && canReadAssetLocation && canUpdateAssetLocation;

  const { data: locations, isFetching: isFetchingLocations } =
    useGetSiteLocationsQuery(siteValue?.value ?? '', {
      skip: !canUseLocations,
    });

  const locationOptions = useMemo(
    () => transformLocationsToOptions(locations || []),
    [locations]
  );

  const employees = useOrganizationEmployees();
  const employeeOptions = transformEmployeesToOptions(
    employees.filter(employee => employee.status === 'ACTIVE')
  );

  const watched = watch();

  useEffect(() => {
    if (hasAssetAssignment || watched.site?.value) {
      clearErrors('assignedTo');
      clearErrors('site');
    }
  }, [clearErrors, hasAssetAssignment, watched.site?.value]);

  useEffect(() => {
    if (!hasAssetAssignment) setValue('assignedTo', null);
  }, [hasAssetAssignment, setValue]);

  // Clear location value when the Warehouse is not selected as the site
  useEffect(() => {
    if (!isWarehouseSelected) {
      setValue('location', null);
    }
  }, [isWarehouseSelected, setValue]);

  const [createAssets, { isLoading: isCreateAssetsLoading }] =
    useCreateAssetsMutation();

  const onSubmit = (data: AssetFormData) => {
    if (!watched.assignedTo?.value && hasAssetAssignment) {
      const errorText =
        'The employee to whom the asset is assigned must be provided';
      setError('assignedTo', { message: errorText });
      return;
    }
    if (!watched.site?.value && !hasAssetAssignment) {
      const errorText =
        'A site must be provided if the asset is not assigned to an employee. Select an existing one or create a new one';
      setError('site', { message: errorText });
      return;
    }

    const productTypeId = (
      productTypes?.find(
        productType => productType.id === data.productType?.value
      )?.id ?? ''
    ).toString();

    createAssets({
      organizationId: organizationId,
      assets: [
        {
          name: data.name,
          productTypeId,
          customAssetId: data.customAssetId,
          assignedTo: data.assignedTo?.value,
          purchaseDate: data.purchaseDate,
          purchasePrice: data.purchasePrice
            ? Number(data.purchasePrice)
            : undefined,
          purchasedFromUrl: data.purchasedFrom,
          brand: data.brand,
          model: data.model,
          serialNumber: data.serialNumber,
          notes: data.notes,
          internalNotes: data.internalNotes,
          siteId: data.site?.value,
          locationId: data.location?.value,
          status: data.status?.value,
          condition: data.condition?.value,
          imageUrl: data.imageUrl,
          warrantyExpirationDate: data.warrantyExpirationDate,
        },
      ],
    })
      .unwrap()
      .catch(error => {
        console.error('Error while adding asset: ', error);
        showErrorToast({
          id: 'add-asset-error-toast',
          message: 'Something went wrong. Please try again.',
        });
        return Promise.reject(error);
      })
      .then(() => {
        showSuccessToast({
          id: 'add-asset-success-toast',
          message: 'The asset was successfully added.',
        });
        reset();
        setHasAssetAssignment(false);
      });
  };
  const handleRadioClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasAssetAssignment(e.target.value === 'true');
  };

  return (
    <Box>
      <Flex vertical alignItems="left" my="1.6rem" rowGap="1rem">
        <Text variant="heading-3">Item details</Text>
      </Flex>
      <form>
        <Flex columnGap="2rem">
          <TextInputField
            id="asset-name"
            name="name"
            control={control}
            label="Name"
            required
          />
          <TextInputField
            id="asset-custom-id"
            name="customAssetId"
            control={control}
            label="Asset ID"
          />
        </Flex>
        <Flex columnGap="2rem">
          <SelectField
            id="product-type"
            name="productType"
            control={control}
            label="Asset type"
            options={productTypeOptions}
            isMulti={false}
            required
            isSearchable
            placeholder="Select an asset type"
            isLoading={isFetching}
          />
          <SelectField
            id="asset-status"
            control={control}
            name="status"
            label="Status"
            placeholder="Select a status"
            options={statusOptions}
            isClearable
            isSearchable
          />
        </Flex>
        <Flex columnGap="2rem">
          <Flex width="50%" columnGap="2rem">
            <DatePickerField
              id="asset-purchase-date"
              name="purchaseDate"
              control={control}
              label="Purchase date"
              minDate=""
              selected={watched.purchaseDate}
            />
            <TextInputField
              id="asset-purchase-price"
              name="purchasePrice"
              control={control}
              label="Purchase price ($)"
              value={watched.purchasePrice?.toString() ?? ''}
            />
          </Flex>
          <Flex width="50%">
            <TextInputField
              id="asset-purchased-from"
              name="purchasedFrom"
              control={control}
              label="Purchased from"
              placeholder="Enter the URL where the asset was purchased."
            />
          </Flex>
        </Flex>
        <Flex columnGap="2rem">
          <TextInputField
            id="asset-brand"
            name="brand"
            control={control}
            label="Brand"
          />
          <TextInputField
            id="asset-model"
            name="model"
            control={control}
            label="Model"
          />
          <TextInputField
            id="asset-serial-number"
            name="serialNumber"
            control={control}
            label="Serial #"
          />
        </Flex>
        <Flex columnGap="2rem">
          <SelectField
            id="asset-condition"
            name="condition"
            control={control}
            label="Condition"
            options={conditionOptions}
            value={watched.condition}
            isMulti={false}
            placeholder="Select a condition"
            isClearable
            isSearchable
          />
          <DatePickerField
            id="warranty-expiration-date"
            name="warrantyExpirationDate"
            control={control}
            label="Warranty Expiration"
            minDate=""
            selected={watched.warrantyExpirationDate}
            isClearable
          />
        </Flex>
        <Flex width="67%" hAlignContent="center" vertical>
          <TextAreaField
            id="asset-notes"
            name="notes"
            control={control}
            label="Notes"
            placeholder="User input"
            helperText={`${watched.notes?.length}/1000`}
            helperTextJustifyContent="flex-end"
            maxLength={1000}
          />
        </Flex>
        {canAddInternalNotes && (
          <Flex width="67%" hAlignContent="center" vertical>
            <TextAreaField
              id="asset-internal-notes"
              name="internalNotes"
              control={control}
              label="Internal Notes"
              placeholder="User input"
              helperText={`${watched.internalNotes?.length}/1000`}
              helperTextJustifyContent="flex-end"
              maxLength={1000}
            />
          </Flex>
        )}
        <Divider />
        <Text mt={4}>Is this asset assigned to an employee?</Text>
        <RadioGroup stack p={6}>
          <Radio
            id="asset-not-assigned"
            value="false"
            onChange={handleRadioClick}
            checked={!hasAssetAssignment}>
            No, this is an unassigned asset
          </Radio>
          <Radio
            id="asset-assigned"
            value="true"
            onChange={handleRadioClick}
            checked={hasAssetAssignment}>
            Yes, this asset is assigned to an existing employee
          </Radio>
        </RadioGroup>
        {hasAssetAssignment && (
          <SelectField
            id="asset-assigned-to"
            name="assignedTo"
            control={control}
            label="Assigned to"
            options={employeeOptions}
            isMulti={false}
            placeholder="Select an employee"
            isClearable
            isSearchable
            required={hasAssetAssignment}
          />
        )}
        <Flex flexDirection="column">
          <Text mb={4}>Where is this Asset located?</Text>
          <Flex vAlignContent="center" width="100%" columnGap="2rem">
            <Flex width="75%">
              <SelectField
                id="asset-site"
                name="site"
                control={control}
                label="Site"
                options={siteOptions}
                isMulti={false}
                isLoading={isSitesFetching}
                isClearable
                isSearchable
                placeholder="Select a site"
                required={!hasAssetAssignment}
                menuPlacement="auto"
              />
            </Flex>
            <Flex width="25%">
              <Button
                id="asset-add-site-button"
                variant="outline"
                iconBefore="add-circle"
                onClick={() =>
                  openModal(<SiteModal hideModal={() => closeModal()} />)
                }>
                Add site
              </Button>
            </Flex>
          </Flex>
          {canUseLocations && (
            <Flex width="calc(100% - 2rem)">
              <Flex width="75%">
                <SelectField
                  id="asset-location"
                  control={control}
                  name="location"
                  label="Location"
                  placeholder="Select a location"
                  options={locationOptions}
                  isLoading={isFetchingLocations}
                  isSearchable
                  isClearable
                  menuPlacement="auto"
                />
              </Flex>
            </Flex>
          )}
        </Flex>
        <Flex justifyContent="flex-end" alignSelf="flex-end" columnGap="2rem">
          <Button
            id="asset-button-cancel"
            variant="text"
            onClick={() => navigate('/assets')}>
            Cancel
          </Button>
          <Button
            id="asset-button-add"
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid}
            loading={isCreateAssetsLoading}>
            Add asset
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default NewAssetForm;

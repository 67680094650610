import styled from 'styled-components';

import { Box, Flex, Pagination } from '@electricjs/arc';

import { STOREFRONT_PAGE_SIZE } from '@/constants/pagination';
import { type Asset } from '@/types/assets';
import { Store, type Product } from '@/types/storefront';

import { ItemListEmptyState } from './ItemListEmptyState';
import { ItemListLoadingState } from './ItemListLoadingState';
import { ErrorState } from './ErrorState';
import { ItemCard } from './ItemCard';

export const StyledItemGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.6rem;
  width: 100%;
  @media (max-width: 1273px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export type CatalogList = {
  store: Store.Catalog;
  items: Product[];
  onAddItem: (item: Product) => void;
  onRemoveItem: (item: Product) => void;
};

export type AssetList = {
  store: Store.Asset;
  items: Asset[];
  onAddItem: (item: Asset) => void;
  onRemoveItem: (item: Asset) => void;
};

export type ItemListProps = (CatalogList | AssetList) & {
  totalItemsResult: number;
  selectedPage: number;
  onPageChange: (newPage: number) => void;
  isLoading: boolean;
  hasError: boolean;
  errorMessage: string;
  selectedItemsIds: string[];
};

export const ItemList = ({
  store,
  items,
  onAddItem,
  onRemoveItem,
  totalItemsResult,
  selectedPage,
  onPageChange,
  isLoading,
  hasError,
  errorMessage,
  selectedItemsIds,
}: ItemListProps) => {
  if (isLoading) {
    return <ItemListLoadingState />;
  }

  if (hasError) {
    return <ErrorState title="Error" message={errorMessage} />;
  }

  if (totalItemsResult === 0) {
    // show empty state for the search result
    return <ItemListEmptyState store={store} />;
  }

  return (
    <Box>
      <StyledItemGrid data-testid="item-list" id="storefront-item-list">
        {items?.map(item => {
          if (store === Store.Catalog) {
            const catalogItem = item as Product;

            return (
              <ItemCard
                key={catalogItem.id}
                store={store}
                item={catalogItem}
                selected={selectedItemsIds.includes(catalogItem.id)}
                onAddItem={() => onAddItem(catalogItem)}
                onRemoveItem={() => onRemoveItem(catalogItem)}
              />
            );
          } else if (store === Store.Asset) {
            const assetItem = item as Asset;

            return (
              <ItemCard
                key={assetItem.id}
                store={store}
                item={assetItem}
                selected={selectedItemsIds.includes(assetItem.id)}
                onAddItem={() => onAddItem(assetItem)}
                onRemoveItem={() => onRemoveItem(assetItem)}
              />
            );
          }
        })}
      </StyledItemGrid>
      <Flex hAlignContent="center" vertical mt="3.0rem">
        <Pagination
          id="item-list-pagination"
          currentPage={selectedPage + 1} // the endpoints use zero-based pagination (for both products and assets)
          totalPageCount={
            totalItemsResult
              ? Math.ceil(totalItemsResult / STOREFRONT_PAGE_SIZE)
              : 1
          }
          pageChangeCallback={(_previousPageNumber, nextPageNumber) => {
            // scroll to the top of the list
            const rootElement = document.getElementById('storefront-item-list');
            rootElement?.scrollIntoView({ behavior: 'smooth' });

            // The "pageChangeCallback" (Arc) pass 2 arguments:
            // 1- "previousPageNumber": The current page (we don't need it)
            // 2- "nextPageNumber": The new desired page (can be the next or previous page). So, this is the value that will be passed to when "onPageChange" is called.
            onPageChange(nextPageNumber - 1); // converting back to zero-based pagination
          }}
        />
      </Flex>
    </Box>
  );
};

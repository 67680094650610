import type { ReactNode } from 'react';

import { Flex, RadioTile } from '@electricjs/arc';

import { type StorefrontProductTypeGroup } from '@/types/storefront';

type TypeGroupRadioTileProps = {
  value: StorefrontProductTypeGroup;
  checked?: boolean;
  disabled?: boolean;
  children?: ReactNode;
};

export const TypeGroupRadioTile = ({
  value,
  checked,
  disabled,
  children,
}: TypeGroupRadioTileProps) => {
  return (
    <RadioTile
      id={`storefront-product-type-group-option-${value}`}
      value={value}
      checked={checked}
      disabled={disabled}
      customComponent={
        <Flex
          vertical
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          px="8px"
          py="4px"
          gap="8px"
          height="10.5rem"
          width="12rem">
          {children}
        </Flex>
      }
    />
  );
};

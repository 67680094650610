import {
  RequestCollection as RequestCollectionDto,
  RequestResponse as RequestDto,
  RequestTypeResponse as RequestTypeDto,
} from '@electricjs/core_entity-client';

export const requests: RequestDto[] = [
  {
    id: '002df5c0-054f-11ee-be56-0242ac120005',
    request_type_id: '000b2ff8-97dc-4b29-b7d1-7ed65a9ea001',
    organization_id: 'string',
    requester_user_id: '27b852f0-54dc-453e-9eb5-03e8bb437c54',
    on_behalf_of: 'a5bb2ff8-97dc-4b29-b7d1-7ed65a9ea933',
    status: 'COMPLETED',
    request_collection_id: '002df5c0-054f-11ee-be56-0242ac120005',
    data: {
      application_key: 'zoom',
    },
    automation_status: 'SUCCESS',
  },
  {
    id: '002df5c0-054f-11ee-be56-0242ac12000b',
    request_type_id: '000b2ff8-97dc-4b29-b7d1-7ed65a9ea001',
    organization_id: 'string',
    requester_user_id: '27b852f0-54dc-453e-9eb5-03e8bb437c54',
    status: 'IN_PROGRESS',
    on_behalf_of: 'a5bb2ff8-97dc-4b29-b7d1-7ed65a9ea933',
    request_collection_id: '002df5c0-054f-11ee-be56-0242ac120005',
    data: {
      application_key: 'zoom',
    },
    resolved_at: '',
    resolved_by_user_id: '',
    automation_status: 'NOT_CONNECTED',
  },
  {
    id: '002df5c0-054f-11ee-be56-0242ac12000d',
    request_type_id: '000b2ff8-97dc-4b29-b7d1-7ed65a9ea002',
    organization_id: 'string',
    requester_user_id: '27b852f0-54dc-453e-9eb5-03e8bb437c54',
    status: 'IN_PROGRESS',
    on_behalf_of: 'a5bb2ff8-97dc-4b29-b7d1-7ed65a9ea933',
    request_collection_id: '002df5c0-054f-11ee-be56-0242ac120005',
    data: {
      application_key: '1password',
    },
    resolved_at: '',
    resolved_by_user_id: '',
    automation_status: 'NOT_ENABLED',
  },
];

export const simpleRequestCollection: RequestCollectionDto = {
  id: '002df5c0-054f-11ee-be56-0242ac120005',
  created_at: '2021-09-30T15:18:24.000Z',
  completed_at: '2021-09-30T15:18:24.000Z',
};

export const requestTypes: RequestTypeDto[] = [
  {
    id: '000b2ff8-97dc-4b29-b7d1-7ed65a9ea001',
    name: 'Onboarding / Create Account',
    slug: 'onboarding_create_account',
    request_type_category_id: 'a5bb2ff8-97dc-4b29-b7d1-7ed65a9ea926',
    is_internal: true,
  },
  {
    id: '000b2ff8-97dc-4b29-b7d1-7ed65a9ea002',
    name: 'Offboarding / Remove Account',
    slug: 'offboarding_remove_account',
    request_type_category_id: 'a5bb2ff8-97dc-4b29-b7d1-7ed65a9ea926',
    is_internal: true,
  },
];

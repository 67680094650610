import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { IconNames } from '@electricjs/arc';
import { Box, Button, Flex, Icon, Text } from '@electricjs/arc';

type RequestSupportFormHeaderProps = {
  title: string;
  subtitle?: string;
  subheading?: string;
  icon?: IconNames;
  hasBackButton?: boolean;
};

const TextContainer = styled(Box)`
  margin-top: 3.2rem;
  margin-bottom: 4.8rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  white-space: pre-line;

  @media (max-width: 800px) {
    margin-top: 2.4rem;
    margin-bottom: 3.6rem;
  }
`;

const RequestSupportFormHeader = ({
  icon,
  title,
  subtitle = 'Please give us some more information.',
  subheading,
  hasBackButton = true,
}: RequestSupportFormHeaderProps) => {
  const navigate = useNavigate();

  return (
    <Box width="100%">
      {hasBackButton && (
        <Button
          id="request-support-form-back-button"
          iconBefore="arrow-left-circle"
          variant="link"
          onClick={() => navigate(-1)}>
          Back
        </Button>
      )}
      <TextContainer>
        {icon && (
          <Icon
            icon={icon}
            intent="neutral"
            size="medium"
            title="Illustrative icon for the page"
          />
        )}
        <Flex alignItems="center" mt={1}>
          <Text variant="heading-1">{title}</Text>
        </Flex>
        <Text variant="subheading">{subtitle}</Text>
        {subheading && (
          <Text variant="body" mt={2}>
            {subheading}
          </Text>
        )}
      </TextContainer>
    </Box>
  );
};

export default RequestSupportFormHeader;

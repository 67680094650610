import { AssetCondition, AssetStatus } from '@electricjs/core_entity-client';
import CenteredSpinner from 'common/CenteredSpinner';
import Papa from 'papaparse';
import { useMemo, useState } from 'react';

import {
  Button,
  FilePicker,
  Flex,
  ModalAction,
  ModalBody,
  ModalFooter,
  ModalFooterActions,
  ModalHeader,
  ModalV2,
  Text,
} from '@electricjs/arc';

import AssetError from '@/components/Assets/AssetDetails/AssetError';
import {
  transformProductTypesToOptions,
  transformSitesToOptions,
} from '@/components/Assets/helpers/transformOptions';
import { assetConditionDisplayNameMap } from '@/components/Assets/helpers/transformOptions';
import { useGlobalUI } from '@/components/GlobalUIProvider';
import { transformEmployeesToOptions } from '@/components/People/transformOptions';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useOrganizationProductTypes } from '@/hooks/useOrganizationProductTypes';
import { assetStatusDisplayNameMap } from '@/pages/Assets/AssetStatus';
import {
  useCreateAssetsMutation,
  useGetOrganizationSitesQuery,
} from '@/redux/slices/assetApiSlice';
import { useGetOrganizationEmployeesQuery } from '@/redux/slices/organizationApiSlice';
import { CSVImportAsset } from '@/types/assets';
import { EmployeeStatus } from '@/types/employees';

import { assetSchema } from './AssetSchema';
import safelyOpenExternalURL from 'common/safelyOpenExternalURL';

enum FileStatus {
  Empty = 'empty',
  InvalidContent = 'invalidContent',
  InvalidHeader = 'invalidHeader',
  Undefined = 'undefined',
  Valid = 'valid',
}

type AssetsCSVUploadModalProps = {
  onCancel: () => void;
  onSuccess: () => void;
};

const AssetsCSVUploadModal = ({
  onCancel,
  onSuccess,
}: AssetsCSVUploadModalProps) => {
  const organizationId = useGetOrganizationId();

  const [createAssets, { isLoading: isCreateAssetsLoading }] =
    useCreateAssetsMutation();

  const {
    data: sites,
    isFetching: isFetchingSites,
    isError: isSitesError,
  } = useGetOrganizationSitesQuery({ organizationId });
  const siteOptions = useMemo(
    () => transformSitesToOptions(sites || []),
    [sites]
  );

  const {
    data: employees,
    isFetching: isFetchingEmployees,
    isError: isEmployeesError,
  } = useGetOrganizationEmployeesQuery({ organizationId });
  const employeeOptions = useMemo(
    () =>
      transformEmployeesToOptions(
        employees?.filter(
          employee => employee.status === EmployeeStatus.Active
        ) ?? []
      ),
    [employees]
  );

  const {
    productTypes,
    isFetching: isFetchingProductTypes,
    isError: isProductTypesError,
  } = useOrganizationProductTypes();

  const productTypeOptions = useMemo(
    () => transformProductTypesToOptions(productTypes || []),
    [productTypes]
  );
  const statusOptions = useMemo(
    () =>
      Object.keys(assetStatusDisplayNameMap).map(status => ({
        value: status,
        label: assetStatusDisplayNameMap[status as AssetStatus],
        name: assetStatusDisplayNameMap[status as AssetStatus],
      })),
    []
  );
  const conditionOptions = useMemo(
    () =>
      Object.keys(assetConditionDisplayNameMap).map(condition => ({
        value: condition,
        label: assetConditionDisplayNameMap[condition as AssetCondition],
        name: assetConditionDisplayNameMap[condition as AssetCondition],
      })),
    []
  );

  const { showSuccessToast, showErrorToast } = useGlobalUI();
  const [fileStatus, setFileStatus] = useState<FileStatus>(
    FileStatus.Undefined
  );
  const [assetList, setAssetList] = useState<CSVImportAsset[]>([]);
  const [fileErrorMessage, setFileErrorMessage] = useState('');
  const expectedFields = [
    'Name',
    'Asset ID',
    'Asset type',
    'Assigned to',
    'Purchase date',
    'Purchase price ($)',
    'Purchased from',
    'Brand',
    'Model',
    'Serial #',
    'Site',
    'Status',
    'Condition',
    'Notes',
    'Warranty Expiration',
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const checkHeader = (fileContent: any) => {
    const parsedData = Papa.parse(fileContent, { preview: 1, header: true });
    const header = parsedData.meta.fields;
    const headerIsValid = expectedFields.every(field => header.includes(field));
    return headerIsValid;
  };

  const discardFileContent = () => {
    setAssetList([]);
    setFileStatus(FileStatus.Undefined);
    setFileErrorMessage('');
  };

  const validateAssets = (parsedAssets: CSVImportAsset[]): number => {
    console.log(parsedAssets);
    let errors = 0;

    parsedAssets.forEach(asset => {
      try {
        assetSchema.validateSync(asset);
      } catch (error: unknown) {
        console.log(error);
        errors += 1;
      }
    });

    return errors;
  };

  const handleSelectedFile = (e: ProgressEvent<FileReader>) => {
    const fileContent = e?.target?.result;

    if (!checkHeader(fileContent)) {
      setFileStatus(FileStatus.InvalidHeader);
      setAssetList([]);
      setFileErrorMessage(
        `Please check the column headers. The first row must contain ${expectedFields.join(
          ', '
        )}.`
      );
      return;
    }

    const parsedData = Papa.parse(fileContent, {
      header: true,
      skipEmptyLines: true,
    });

    const parsedAssets: CSVImportAsset[] = parsedData.data.map(
      (row: { [key: string]: string }) => ({
        name: row['Name'],
        assetID: row['Asset ID'],
        productType: productTypeOptions.find(
          option => option.name === row['Asset type']
        ),
        assignedTo: employeeOptions.find(
          option => option.email === row['Assigned to']
        ),
        purchaseDate: row['Purchase date'],
        purchasePrice: row['Purchase price ($)'],
        purchasedFrom: row['Purchased from'],
        brand: row['Brand'],
        model: row['Model'],
        serialNumber: row['Serial #'],
        site: siteOptions.find(option => option.name === row['Site']),
        notes: row['Notes'],
        status: statusOptions.find(option => option.name === row['Status']),
        condition: conditionOptions.find(
          option => option.name === row['Condition']
        ),
        warrantyExpirationDate: row['Warranty Expiration'],
      })
    );

    if (parsedAssets.length === 0) {
      setFileStatus(FileStatus.Empty);
      setAssetList([]);
      setFileErrorMessage('Asset list cannot be empty.');
      return;
    }

    const errors = validateAssets(parsedAssets);
    if (errors === 0) {
      setFileStatus(FileStatus.Valid);
    } else {
      setFileStatus(FileStatus.InvalidContent);
      setFileErrorMessage(`This document contains ${errors} errors.`);
    }
    setAssetList(parsedAssets);
  };

  const handleSubmit = () => {
    createAssets({
      organizationId: organizationId,
      assets: assetList.map(asset => ({
        name: asset.name,
        productTypeId: asset.productType?.value,
        customAssetId: asset.assetID,
        assignedTo: asset.assignedTo?.value,
        purchaseDate: asset.purchaseDate
          ? new Date(asset.purchaseDate)
          : undefined,
        purchasePrice: asset.purchasePrice,
        purchasedFromUrl: asset.purchasedFrom,
        brand: asset.brand,
        model: asset.model,
        serialNumber: asset.serialNumber,
        notes: asset.notes,
        internalNotes: '',
        siteId: asset.site?.value,
        status: asset.status?.value,
        condition: asset.condition?.value,
        imageUrl: undefined,
        warrantyExpirationDate: asset.warrantyExpirationDate
          ? new Date(asset.warrantyExpirationDate)
          : undefined,
      })),
    })
      .unwrap()
      .then(() => {
        showSuccessToast({
          id: 'asset-added-success-toast',
          message: 'The assets were successfully added.',
        });
        onSuccess();
      })
      .catch(error => {
        console.error('Error while adding assets: ', error);
        showErrorToast({
          id: 'asset-added-error-toast',
          message: 'Something went wrong. Please try again.',
        });
        return Promise.reject(error);
      });
  };

  const footerAction = (() => {
    return (
      <ModalAction
        id="csv-modal-add-assets-button"
        type="button"
        disabled={fileStatus !== FileStatus.Valid}
        loading={isCreateAssetsLoading}
        onClick={handleSubmit}>
        Add assets
      </ModalAction>
    );
  })();

  return (
    <ModalV2
      hide={onCancel}
      visible
      ariaLabelledby="import-assets-modal-header-text">
      <ModalHeader showCloseButton={true}>
        <Flex alignItems="center" columnGap="1rem">
          <Text id="import-assets-modal-header-text" variant="heading-2">
            Import assets
          </Text>
        </Flex>
      </ModalHeader>
      {isFetchingSites || isFetchingEmployees || isFetchingProductTypes ? (
        <CenteredSpinner />
      ) : isSitesError || isEmployeesError || isProductTypesError ? (
        <AssetError />
      ) : (
        <form>
          <ModalBody>
            <Text>Guidelines for uploading a file:</Text>
            <ul>
              <li>
                <Text>You can upload a CSV file only</Text>
              </li>
              <li>
                <Text>
                  Make sure your file has correct column headers:{' '}
                  {expectedFields.join(', ')}
                </Text>
              </li>
              <li>
                <Text>
                  In case of any issues, please download our CSV template
                </Text>
              </li>
            </ul>
            <Button
              id="button-csv-modal-download-template"
              variant="text"
              iconBefore="download"
              onClick={() => {
                safelyOpenExternalURL('assetsTemplate.csv');
              }}>
              Download CSV template
            </Button>
            <FilePicker
              onLoad={handleSelectedFile}
              onRemove={discardFileContent}
              acceptedExtensions=".csv"
              errorMessage={fileErrorMessage}
            />
          </ModalBody>
          <ModalFooter>
            <ModalFooterActions>
              <ModalAction id="asset-upload-csv-cancel-button" variant="text">
                Cancel
              </ModalAction>
              {footerAction}
            </ModalFooterActions>
          </ModalFooter>
        </form>
      )}
    </ModalV2>
  );
};

export default AssetsCSVUploadModal;

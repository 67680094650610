import { rest } from 'msw';

import { ProductsResponseDto } from '@externalTypes/general';

import { allProductsV2 } from '../data/catalogProductsDataV2';

export const productHandlers = [
  rest.get('*/v2/products', (req, res, ctx) => {
    const productType = req.url.searchParams.get('product_type');
    const limit = Number.parseInt(req.url.searchParams.get('limit') || '');

    // filtering only by type as example
    const results = allProductsV2.filter(
      product => product.product_type === productType
    );

    const response: ProductsResponseDto = {
      paginated_response: {
        total: results.length,
        results: results.slice(0, limit - 1), // returning only the first page (offset = 0)
      },
      filters: {
        laptop: {
          brand: ['Apple', 'HP'], // just to show the filter (we are not applying it in the mock)
        },
      },
    };

    return res(ctx.status(200), ctx.delay(), ctx.json(response));
  }),
];

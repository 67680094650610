import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import {
  ColorsV2,
  IconButton,
  Pane,
  TextInput,
  useThemeColors,
} from '@electricjs/arc';

const SearchContainer = styled(Pane)`
  position: relative;
  min-width: 32rem;
  max-width: 32rem;
  height: fit-content;

  & div {
    width: 100%;
  }
`;

const StyledTextInput = styled(TextInput)`
  padding: 0 3.4rem;
`;

export type SearchBoxProps = {
  id?: string;
  placeholder: string;
  onChange: (text: string) => void;
  searchTerm?: string;
};

const SearchBox = ({
  id = 'search-box',
  placeholder,
  onChange,
  searchTerm,
}: SearchBoxProps) => {
  const [term, setTerm] = useState('');

  const clearIconColor = useThemeColors(ColorsV2.TEXT);

  const onChangeHandler = (event: React.FormEvent) => {
    const target = event.target as HTMLInputElement;
    if (searchTerm === undefined) {
      setTerm(target.value);
    }
    onChange(target.value);
  };
  const clearHandler = useCallback(() => {
    if (searchTerm === undefined) {
      setTerm('');
    }
    onChange('');
  }, [onChange, setTerm, searchTerm]);

  const textInputValue = searchTerm || term;

  return (
    <SearchContainer data-testid={id}>
      <StyledTextInput
        id={`${id}-input`}
        onChange={onChangeHandler}
        value={textInputValue}
        placeholder={placeholder}
        icon="search"
        iconPosition="left"
      />
      <IconButton
        id={`${id}-clear-icon-button`}
        alt="close"
        icon="close"
        color={clearIconColor}
        position="absolute"
        right="0"
        variant="link"
        onClick={clearHandler}
        disabled={
          textInputValue === null ||
          textInputValue === undefined ||
          textInputValue === ''
        }
      />
    </SearchContainer>
  );
};
export default SearchBox;

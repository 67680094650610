import styled from 'styled-components';

import { Box, Text, Banner } from '@electricjs/arc';

import type { ProvisioningType } from '@/types/storefront';
import { provisioningTypeData } from '../../utils';

const StyledUl = styled.ul`
  margin: 0;
  padding-left: 24px;
`;

type ProvisioningBannerProps = {
  provisioningTypeValue?: ProvisioningType;
  inventoryOnly: boolean;
  hasAnyKnownLaptopEntry: boolean;
};

export const ProvisioningBanner = ({
  provisioningTypeValue,
  inventoryOnly,
  hasAnyKnownLaptopEntry,
}: ProvisioningBannerProps) => {
  return (
    <>
      {provisioningTypeValue ===
        provisioningTypeData.DEVICE_HAS_AUTO_ENROLLMENT.value && (
        <Banner
          id="device-has-auto-enrollment-banner"
          title="Zero-Touch Provisioning"
          message="Your company is enrolled in ZTP (zero-touch provisioning) through Electric. This means the Apple device(s) you selected will ship directly from Apple to the new employee and be automatically provisioned once the device is turned on and connected to the internet."
          intent="info"
          testId={provisioningTypeData.DEVICE_HAS_AUTO_ENROLLMENT.value}
        />
      )}

      {provisioningTypeValue === provisioningTypeData.AT_ELECTRIC.value && (
        <Banner
          id="provisioning-at-electric-banner"
          title="Provisioning at Electric"
          message={() => (
            <Box>
              <Text>
                When provisioning devices at Electric, please allow extra time
                for shipping and processing.
              </Text>
              <StyledUl>
                <li>
                  <Text>
                    Device shipments coming to Electric can take up to 3 days
                  </Text>
                </li>
                <li>
                  <Text>Provisioning can take 3 to 5 business days</Text>
                </li>
                <li>
                  <Text>
                    Shipments to the final destination can take up to 3 days*
                  </Text>
                </li>
              </StyledUl>
              <Text mt="1rem">
                If these timelines do not align with onboarding dates we
                recommend using the employee-initiated enrollment method.
              </Text>
              <Text mt="1rem">
                When shipping devices from your own inventory to Electric,
                please direct them to:
                <Box ml="1rem">
                  <Text>Electric c/o Techvera</Text>
                  <Text>625 Dallas Drive, #450</Text>
                  <Text>Denton, TX 76205</Text>
                </Box>
              </Text>
              <Text mt="1rem" fontStyle="italic">
                *Once provisioned, you will be invoiced for the shipment of the
                device(s) to the final destination. Shipping charges vary
                between $60 and $135 plus tax, depending on the shipping method
                and destination.
              </Text>
            </Box>
          )}
          intent="warning"
          testId={provisioningTypeData.AT_ELECTRIC.value}
        />
      )}

      {provisioningTypeValue ===
        provisioningTypeData.NO_PROVISIONING_SUPPORT.value &&
        hasAnyKnownLaptopEntry &&
        !inventoryOnly && (
          <Banner
            id="provisioning-recommended-selection-banner"
            title="Provisioning recommended selection"
            message="We highly recommend you choose our recommended provisioning method or a feasible alternative. Provisioning support greatly helps us manage your IT needs."
            intent="warning"
            testId={provisioningTypeData.NO_PROVISIONING_SUPPORT.value}
          />
        )}

      {provisioningTypeValue ===
        provisioningTypeData.NO_PROVISIONING_SUPPORT.value &&
        hasAnyKnownLaptopEntry &&
        inventoryOnly && (
          <Banner
            id="inventory-only-banner"
            title="Device provisioning upon assignment"
            message="Devices purchased for inventory will not be provisioned until they have been selected for use by an employee."
            intent="info"
            isFlat
            testId={`INVENTORY_ONLY_${provisioningTypeData.NO_PROVISIONING_SUPPORT.value}`}
          />
        )}
    </>
  );
};

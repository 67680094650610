import { rest } from 'msw';

import { packages } from '../data/packagesData';

export const packageHandlers = [
  rest.get('*/v1/packages', (_req, res, ctx) => {
    console.log(packages);
    return res(ctx.status(200), ctx.delay(), ctx.json(packages));
  }),
  rest.patch('*/v1/organizations/:id/packages', (_req, res, ctx) => {
    return res(ctx.status(200), ctx.delay());
  }),
  rest.post(
    '*/v1/organizations/:id/packages/send_change_license_count_interest_email',
    (_req, res, ctx) => {
      return res(ctx.status(200), ctx.delay());
    }
  ),
];

/**
 * Removes non-alphanumeric characters, replace spaces with hyphens, and converts to lowercase
 */
const formatStringForId = (str: string): string => {
  return str
    ?.replace(/\s+/g, '-')
    ?.replace(/[^-a-zA-Z0-9]+/g, '')
    ?.toLowerCase();
};

export default formatStringForId;

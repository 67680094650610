import { HRISProvider } from '@/types/hrisIntegration';

export type HrisProviderOption = {
  value: string;
  label: string;
  name: string;
};

export const transformHrisProvidersToOptions = (
  hrisProviders: HRISProvider[] | undefined
): HrisProviderOption[] => {
  if (!hrisProviders) {
    return [];
  }

  return hrisProviders.map((provider: HRISProvider) => ({
    value: provider.slug ?? '',
    name: provider.name,
    label: provider.slug,
  }));
};

import { Flex, Box, Card, Button, Skeleton } from '@electricjs/arc';

import { StyledItemGrid } from './ItemList';

type LoadingTextProps = {
  count?: number;
  height?: string | number;
  marginTop?: string | number;
};

const LoadingText = ({
  count = 1,
  height = '2rem',
  marginTop = '1rem',
}: LoadingTextProps) => {
  return (
    <>
      {Array.from({ length: count }).map((_, index) => (
        <Box key={index} mt={marginTop}>
          <Skeleton height={height} />
        </Box>
      ))}
    </>
  );
};

const LoadingItemCard = () => {
  return (
    <Card
      id="storefront-loading-card"
      width="31rem"
      cardPadding="2.4rem 2.4rem 3.8rem 2.4rem">
      <Skeleton height="20rem" />
      <LoadingText count={5} />
      <Flex hAlignContent="center" mt="2rem">
        <Button id="storefront-loading-button" disabled py="1.4rem" px="2rem">
          Add to cart
        </Button>
      </Flex>
    </Card>
  );
};

export const ItemListLoadingState = () => {
  return (
    <StyledItemGrid>
      <LoadingItemCard />
      <LoadingItemCard />
      <LoadingItemCard />
    </StyledItemGrid>
  );
};

import { Flex, MenuItem, Text, Tooltip } from '@electricjs/arc';
import { LockStatus } from '@electricjs/core_entity-client';
import { useGlobalUI } from '@/components/GlobalUIProvider';
import LockDeviceModal from '@/components/People/EmployeeProfile/LockDeviceModal';
import UnlockDeviceModal from '@/components/People/EmployeeProfile/UnlockDeviceModal';
import { DeviceAsset } from '@/types/assets';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';

type LockMenuItemProps = {
  isLoading: boolean;
  employeeName: string;
  deviceAsset: DeviceAsset;
  disableLock: boolean;
  setDisableLock: () => void;
  isProdSupport: boolean;
};

const LockDeviceMenuItem = ({
  employeeName,
  deviceAsset,
  isLoading,
  disableLock,
  setDisableLock,
  isProdSupport,
}: LockMenuItemProps) => {
  const { openModal, closeModal } = useGlobalUI();
  const organizationId = useGetOrganizationId();

  const lockStatus = disableLock
    ? LockStatus.Requested
    : deviceAsset.device.lockStatus;

  const handleLockDevice = () => {
    openModal(
      <LockDeviceModal
        organizationId={organizationId}
        deviceAsset={deviceAsset}
        employeeName={employeeName}
        onClose={closeModal}
        setDisableLock={setDisableLock}
      />
    );
  };

  const handleUnlockDevice = () => {
    openModal(
      <UnlockDeviceModal
        deviceAsset={deviceAsset}
        employeeName={employeeName}
        onClose={closeModal}
      />
    );
  };

  if (lockStatus === LockStatus.Requested) {
    return (
      <Tooltip
        placement="top"
        content={
          <Text>
            This device has been scheduled to lock. Device will lock when
            connected to the internet.
          </Text>
        }>
        <MenuItem
          id="lock-device-menu-item"
          iconBefore="ellipsis-horizontal"
          disabled>
          <Text>Lock pending</Text>
        </MenuItem>
      </Tooltip>
    );
  }

  if (lockStatus === LockStatus.Locked) {
    return (
      <Tooltip
        maxWidth="30rem"
        content="This feature is disabled for org switcher. Please contact Product Support if you need help"
        placement="left"
        disabled={!isProdSupport}>
        <Flex width={'auto'}>
          <MenuItem
            id="unlock-device-menu-item"
            onClick={handleUnlockDevice}
            disabled={isLoading || isProdSupport}>
            <Text>Unlock device</Text>
          </MenuItem>
        </Flex>
      </Tooltip>
    );
  }

  if (lockStatus === LockStatus.Unlocked) {
    return (
      <Tooltip
        maxWidth="30rem"
        content="This feature is disabled for org switcher. Please contact Product Support if you need help"
        placement="left"
        disabled={!isProdSupport}>
        <Flex width="100%">
          <MenuItem
            id="lock-device-menu-item"
            onClick={handleLockDevice}
            disabled={isLoading || isProdSupport}>
            <Text>Lock device</Text>
          </MenuItem>
        </Flex>
      </Tooltip>
    );
  }

  return null;
};

export default LockDeviceMenuItem;

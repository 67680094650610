import {
  Banner,
  ModalV2,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalFooterActions,
  Text,
  Button,
  Flex,
  TextInputField,
} from '@electricjs/arc';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

type FormData = {
  confirmText: string;
};

const schema = yup.object().shape({
  confirmText: yup
    .string()
    .required()
    .equals(['delete'], 'Please type delete to confirm'),
});

type AssetDeleteModalProps = {
  onClose: () => void;
  onDelete: () => void;
};

const AssetDeleteModal = ({ onClose, onDelete }: AssetDeleteModalProps) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      confirmText: '',
    },
  });

  const handleDelete = () => {
    onDelete();
  };

  return (
    <ModalV2
      visible
      hide={onClose}
      ariaLabelledby="delete-asset-modal-header-text">
      <ModalHeader showCloseButton width="auto">
        <Text id="delete-asset-modal-header-text" variant="heading-2">
          Delete Asset
        </Text>
      </ModalHeader>
      <form onSubmit={handleSubmit(handleDelete)}>
        <ModalBody>
          <Flex flexDirection="column" gap="1.6rem">
            <Text>
              Delete the asset permanetly? This action can&apos;t be undone.
            </Text>
            <Banner
              id="delete-asset-banner"
              intent="warning"
              width="100%"
              message={`Proceeding with this action will delete the asset, all associated logs, uploaded images and files, and any other associated data.`}
            />

            <Text>
              To avoid accidental deletion we ask you to provide additional
              written consent.
            </Text>
            <TextInputField
              id="confirm-delete-input"
              name="confirmText"
              control={control}
              label="Type delete to agree"
              placeholder="delete"
            />
          </Flex>
        </ModalBody>
        <ModalFooter>
          <ModalFooterActions>
            <Button id="cancel-delete-button" onClick={onClose} variant="text">
              Cancel
            </Button>
            <Button
              id="confirm-delete-button"
              type="submit"
              variant="fill"
              intent="danger"
              disabled={!isValid}>
              Delete Asset
            </Button>
          </ModalFooterActions>
        </ModalFooter>
      </form>
    </ModalV2>
  );
};

export default AssetDeleteModal;

import { OrganizationUserRoleSlugs } from '@/types/users';
import { useAppSelector } from '@/redux/store';

/**
 * Hook to check if the logged in user is a product support user
 */
export function useIsProdSupport(): boolean {
  const loggedUser = useAppSelector(state => state.loggedUser);

  return (
    loggedUser?.organizationUserRoles?.some(
      role => role.slug === OrganizationUserRoleSlugs.PRODUCT_SUPPORT
    ) || false
  );
}

export default useIsProdSupport;

import { PermissionEnum } from '@electricjs/core_entity-client';
import { useSearchParams } from 'react-router-dom';

import {
  Button,
  ColorsV2,
  Header,
  OneColumnLayout,
  Tab,
  TabList,
  TabPanels,
  TabProps,
  TabsContainer,
} from '@electricjs/arc';

import { useUserHasOrganizationPermission } from '@/hooks/useUserHasOrganizationPermission';
import { useAppSelector } from '@/redux/store';
import { NavigationParamKeys } from '@/types/queryParamKeys';
import { OrganizationUserRoleSlugs } from '@/types/users';

import BillingPane from './BillingPane';
import CompanyProfilePane from './CompanyProfilePane';
import DevicePane from './DevicePane';
import SupportCenterPane from './SupportCenterPane';
import IntegrationsPane from './IntegrationsPane';
import { useEffect, useState } from 'react';

export enum SettingsPageTabs {
  CompanyProfile = 'company-profile',
  Device = 'device',
  Billing = 'billing',
  SupportCenter = 'support-center',
  Integrations = 'integrations',
}

const StyledTab = ({ children, id, ...otherProps }: TabProps) => {
  return (
    <Tab
      id={id}
      {...otherProps}
      selectedColor={ColorsV2.PRIMARY_LIGHT}
      minWidth="0px">
      <Button id={`button-tab-${id}`} variant="text">
        {children}
      </Button>
    </Tab>
  );
};

const Settings = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const organizationId =
    useAppSelector(state => state.loggedUser?.organizationId) ?? '';

  const [selectedTab, setSelectedTab] = useState<SettingsPageTabs>(
    searchParams.get(NavigationParamKeys.Tab) as SettingsPageTabs
  );

  const canReadOrganizationPackage = useUserHasOrganizationPermission(
    PermissionEnum.OrganizationPackageread
  );

  const canReadOrganizationAddOns = useUserHasOrganizationPermission(
    PermissionEnum.OrganizationAddOnsread
  );

  const canViewBillingPane =
    canReadOrganizationPackage || canReadOrganizationAddOns;

  const canViewSupportCenterPane =
    useAppSelector(state =>
      state.loggedUser?.organizationUserRoles?.some(
        role =>
          role.slug === OrganizationUserRoleSlugs.SUPER_ADMIN ||
          role.slug === OrganizationUserRoleSlugs.PRODUCT_SUPPORT
      )
    ) ?? false;

  // Base the available tabs on the user's permissions and entitlements
  const settingsPageTabs = new Set([
    ...[SettingsPageTabs.CompanyProfile],
    ...[SettingsPageTabs.Device],
    ...(canViewBillingPane ? [SettingsPageTabs.Billing] : []),
    ...(canViewSupportCenterPane ? [SettingsPageTabs.SupportCenter] : []),
    ...[SettingsPageTabs.Integrations],
  ]);

  useEffect(() => {
    if (!searchParams.has(NavigationParamKeys.Tab)) {
      setSearchParams(
        { tab: SettingsPageTabs.CompanyProfile },
        { replace: true }
      );
    }
    if (
      searchParams.has(NavigationParamKeys.Tab) &&
      searchParams.get(NavigationParamKeys.Tab) !== selectedTab
    ) {
      setSelectedTab(
        searchParams.get(NavigationParamKeys.Tab) as SettingsPageTabs
      );
    }
  }, [searchParams, setSearchParams, selectedTab]);

  const selectedTabId =
    selectedTab && settingsPageTabs.has(selectedTab as SettingsPageTabs)
      ? selectedTab
      : SettingsPageTabs.CompanyProfile;

  return (
    <OneColumnLayout>
      <Header
        title="Settings"
        subtitle="Manage your company's settings and billing information."
      />
      {/* Key forces a re-render when using the back button */}
      <TabsContainer key={selectedTabId} selectedId={selectedTabId}>
        <TabList mb="1.6rem">
          <StyledTab
            id={SettingsPageTabs.CompanyProfile}
            onClick={() =>
              setSearchParams({ tab: SettingsPageTabs.CompanyProfile })
            }>
            Company profile
          </StyledTab>
          <StyledTab
            id={SettingsPageTabs.Device}
            onClick={() => setSearchParams({ tab: SettingsPageTabs.Device })}>
            Device management
          </StyledTab>
          {canViewBillingPane && (
            <StyledTab
              id={SettingsPageTabs.Billing}
              onClick={() =>
                setSearchParams({ tab: SettingsPageTabs.Billing })
              }>
              Billing
            </StyledTab>
          )}
          {canViewSupportCenterPane && (
            <StyledTab
              id={SettingsPageTabs.SupportCenter}
              onClick={() =>
                setSearchParams({ tab: SettingsPageTabs.SupportCenter })
              }>
              Support center
            </StyledTab>
          )}
          <StyledTab
            id={SettingsPageTabs.Integrations}
            onClick={() =>
              setSearchParams({ tab: SettingsPageTabs.Integrations })
            }>
            Integrations
          </StyledTab>
        </TabList>
        <TabPanels>
          <>
            <CompanyProfilePane organizationId={organizationId} />
            <DevicePane />
            {canViewBillingPane && <BillingPane orgId={organizationId} />}
            {canViewSupportCenterPane && (
              <SupportCenterPane organizationId={organizationId} />
            )}
            <IntegrationsPane />
          </>
        </TabPanels>
      </TabsContainer>
    </OneColumnLayout>
  );
};

export default Settings;

import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

import {
  Button,
  Flex,
  Header,
  OneColumnLayout,
  SearchableMultiSelectField,
  SelectField,
} from '@electricjs/arc';

import { useGlobalUI } from '@/components/GlobalUIProvider';
import { FormActionsContainer } from '@/components/Onboardings/common';
import {
  useDemoSyncHRISEmployeeMutation,
  useListHrisProvidersQuery,
} from '@/redux/slices/hrisIntegrationApiSlice';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useOrganizationEmployees } from '@/hooks/useOrganizationEmployees';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { transformEmployeesToOptions } from '@/components/People/transformOptions';
import { useShouldDisableOffboardingButton } from '@/components/Offboardings/helpers';
import { Employee } from '@/types/employees';
import {
  HrisProviderOption,
  transformHrisProvidersToOptions,
} from '@/components/HRISIntegrations/transformOptions';

export type FormData = {
  hrisProvider: HrisProviderOption | null;
  employee: string;
};

const formSchema = yup.object().shape({
  hrisProvider: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required(),
      name: yup.string().required(),
      label: yup.string().required(),
    })
    .required('HRIS Provider is required'),
  employee: yup.string().required('An employee must be selected'),
});

const NewHRISOffboarding = () => {
  const navigate = useNavigate();
  const organizationId = useGetOrganizationId();
  const { data: providers, isLoading } = useListHrisProvidersQuery();
  const providerOptions = transformHrisProvidersToOptions(providers);

  const employees = useOrganizationEmployees();
  const employeeOptions = transformEmployeesToOptions(
    employees.filter(employee => employee.status === 'ACTIVE')
  );

  const [demoSyncHRISEmployee] = useDemoSyncHRISEmployeeMutation();

  const { showSuccessToast, showErrorToast } = useGlobalUI();

  const defaultValues = {
    employee: '',
  };

  const methods = useForm<FormData>({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(formSchema),
  });
  const {
    handleSubmit,
    control,
    trigger,
    watch,
    formState: { isValid },
  } = methods;
  // Watch the 'employee' form field to track the currently selected employee
  const selectedEmployeeId = watch('employee');

  const selectedEmployee = employees.find(emp => emp.id === selectedEmployeeId);
  const isOffboardingDisabled = useShouldDisableOffboardingButton(
    selectedEmployee || ({} as Employee)
  );

  const handleErrorState = (logError?: string) => {
    showErrorToast({
      id: 'demo-reate-offboarding-error-toast',
      message:
        'There was a problem offboarding the employee. Please try again.',
      stack: true,
    });

    if (logError) {
      window?.DD_RUM?.addError(logError, {
        location: 'Create Offboarding',
      });

      console.error(logError);
    }
  };

  const submitOffboarding = async (data: FormData) => {
    try {
      const selectedEmployee = employees.find(
        employee => employee.id === data.employee
      );

      if (!selectedEmployee) {
        handleErrorState('Employee not found');
        return;
      }

      const employeeData = {
        sourceId: selectedEmployee.id,
        firstName: selectedEmployee.firstName,
        lastName: selectedEmployee.lastName,
        jobTitle: selectedEmployee.jobTitle,
        workEmail: selectedEmployee.email,
        personalEmail: selectedEmployee.personalEmail,
        status: selectedEmployee.status,
        startDate: selectedEmployee.startDate,
        address: selectedEmployee.address,
        terminationDate: new Date().toISOString().split('T')[0],
      };

      const hrisProvider = data.hrisProvider as HrisProviderOption;

      await demoSyncHRISEmployee({
        organizationId,
        hrisProviderSlug: hrisProvider.value,
        hrisEmployee: employeeData,
        isOffboarding: true,
      }).unwrap();

      showSuccessToast({
        id: 'demo-offboard-employee-success-toast',
        message: `The employee was offboarded successfully.`,
        stack: true,
      });

      navigate(`/employees`);
    } catch (error) {
      handleErrorState(
        `Unable to submit offboarding HRIS employee:  ${JSON.stringify(error)}`
      );
    }
  };

  return (
    <OneColumnLayout>
      <Flex maxWidth="72rem" width="100%" margin="0 auto" vertical>
        <FormProvider {...methods}>
          <Header title="Select Employee to offboard" />
          <Flex width="100%" vertical>
            <SelectField
              id="hris-providers-search-selector"
              name="hrisProvider"
              control={control}
              label="HRIS Provider"
              placeholder={'Search provider'}
              required
              isSearchable
              isClearable
              getOptionValue={provider => provider.id}
              getOptionLabel={provider => provider.name}
              options={providerOptions}
              isLoading={isLoading}
            />
            <SearchableMultiSelectField
              id="employee-search-select"
              name="employee"
              label="Employee"
              placeholder="Select an employee"
              required
              isMulti={false}
              options={employeeOptions}
              searchableFields={['name', 'email']}
              control={control}
              trigger={trigger}
            />
          </Flex>
          <FormActionsContainer>
            <Button
              id="demo-offboarding-cancel-button"
              variant="outline"
              onClick={() => navigate(-1)}>
              Cancel
            </Button>

            <Button
              id="demo-offboarding-confirm-button"
              onClick={handleSubmit(submitOffboarding)}
              disabled={!isValid || isOffboardingDisabled}>
              Submit
            </Button>
          </FormActionsContainer>
        </FormProvider>
      </Flex>
    </OneColumnLayout>
  );
};

export default NewHRISOffboarding;

import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useListAssetsV2Query } from '@/redux/slices/assetApiSlice';
import { SortOrder } from '@electricjs/core_entity-client';
import { AssetListArgs, Asset } from '@/types/assets';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

export type UseListAssetsV2Response = {
  assets?: Asset[];
  total?: number;
  isFetching: boolean;
  isLoading: boolean;
  error?: SerializedError | FetchBaseQueryError;
};

export const useListAssetsV2 = (
  props: Partial<Omit<AssetListArgs, 'organizationId'>> = {}
): UseListAssetsV2Response => {
  const organizationId = useGetOrganizationId();

  const {
    data: assetsData,
    isFetching,
    isLoading,
    error,
  } = useListAssetsV2Query({
    organizationId,
    offset: 0,
    limit: 1000,
    orderBy: 'name',
    sortOrder: SortOrder.Asc,
    ...props,
  });

  return {
    assets: assetsData?.results,
    total: assetsData?.total,
    isFetching,
    isLoading,
    error,
  };
};

import { OSType } from '@electricjs/core_entity-client';

import {
  CopyButton,
  Flex,
  ModalAction,
  ModalBody,
  ModalFooter,
  ModalFooterActions,
  ModalHeader,
  ModalV2,
  Spacer,
  Text,
} from '@electricjs/arc';
import { DeviceAsset } from '@/types/assets';

type UnlockDeviceModalProps = {
  deviceAsset: DeviceAsset;
  employeeName: string;
  onClose: () => void;
};

const UnlockDeviceModal = ({
  deviceAsset,
  employeeName,
  onClose,
}: UnlockDeviceModalProps) => {
  const lockPin = deviceAsset.device?.lockPin;
  const osType = deviceAsset.device?.osType;

  return (
    <ModalV2 visible hide={onClose} ariaLabelledby="unlock-device">
      <ModalHeader>
        <Text variant="heading-2">Unlock {employeeName}&apos;s device</Text>
      </ModalHeader>
      <ModalBody>
        {osType === OSType.Darwin ? (
          <>
            <Text>
              Share the PIN provided below with your employee to unlock their
              Mac device:
            </Text>
            <Spacer />
            <Flex vAlignContent="center">
              <Text>{lockPin}</Text>
              <CopyButton
                id="lock-pin-copy"
                alt="copy"
                textToCopy={lockPin || ''}
                successMsg="Copied"
                iconSize="medium"
              />
            </Flex>
          </>
        ) : (
          <Text>
            For Window devices, any device&apos;s account user will be able to
            unlock the device by logging in.{' '}
          </Text>
        )}
      </ModalBody>
      <ModalFooter>
        <ModalFooterActions>
          <ModalAction
            id="unlock-device-cancel"
            onClick={onClose}
            variant="text">
            Close
          </ModalAction>
        </ModalFooterActions>
      </ModalFooter>
    </ModalV2>
  );
};

export default UnlockDeviceModal;

import { useGlobalUI } from '@/components/GlobalUIProvider';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import {
  useGetAssetQuery,
  useUpdateAssetMutation,
} from '@/redux/slices/assetApiSlice';
import { MenuItem, Text } from '@electricjs/arc';
import { AssetStatus } from '@electricjs/core_entity-client';

type ArchiveMenuItemProps = {
  assetId: string;
  setIsloading: (_: boolean) => void;
};

const ArchiveAssetMenuItem = ({
  assetId,
  setIsloading,
}: ArchiveMenuItemProps) => {
  const { showSuccessToast, showErrorToast } = useGlobalUI();
  const organizationId = useGetOrganizationId();
  const { assetStatus, isFetching } = useGetAssetQuery(
    {
      organizationId,
      assetId,
    },
    {
      skip: !assetId,
      selectFromResult: ({ data, isFetching }) => ({
        assetStatus: data?.status,
        isFetching,
      }),
    }
  );
  const [updateAsset, { isLoading: isUpdating }] = useUpdateAssetMutation();

  const disableAction = isUpdating || isFetching;

  const handleClick = async () => {
    const newStatus =
      assetStatus === AssetStatus.Archived
        ? AssetStatus.Available
        : AssetStatus.Archived;
    const assetAction =
      assetStatus === AssetStatus.Archived ? 'unarchive' : 'archive';

    setIsloading(true);

    updateAsset({
      assetId,
      organizationId,
      status: newStatus,
    })
      .then(() => {
        showSuccessToast({
          id: `${assetAction}-asset-success-toast`,
          message: `Asset ${
            newStatus === AssetStatus.Archived ? 'archived' : 'unarchived'
          } successfully.`,
        });
      })
      .catch(() => {
        showErrorToast({
          id: `${assetAction}-asset-error-toast`,
          message: `Failed to ${assetAction} the asset. Please try again.`,
        });
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  if (assetStatus === AssetStatus.Archived) {
    return (
      <MenuItem
        id="unarchive-asset-menu-item"
        onClick={handleClick}
        disabled={disableAction}>
        <Text>Unarchive</Text>
      </MenuItem>
    );
  }

  return (
    <MenuItem
      id="archive-asset-menu-item"
      onClick={handleClick}
      disabled={disableAction}>
      <Text>Archive</Text>
    </MenuItem>
  );
};

export default ArchiveAssetMenuItem;

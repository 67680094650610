import {
  ActionResult,
  ActionResultProps,
  EmployeeResult,
  EmployeeResultProps,
  PageNavigationResult,
  PageNavigationResultProps,
} from './components/ResultKinds';

export enum ResultKind {
  PAGE_NAVIGATION = 'pageNavigation',
  EMPLOYEE = 'employee',
  ACTION = 'action',
}

// Mapping object for ResultKind to label that is shown in the UI
export const resultKindDisplayLabels: Record<ResultKind, string> = {
  [ResultKind.PAGE_NAVIGATION]: 'Pages',
  [ResultKind.EMPLOYEE]: 'Employees',
  [ResultKind.ACTION]: 'Actions',
};

type ResultComponentMap = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key in ResultKind]: React.ComponentType<any>;
};

// Mapping object for ResultKind to component that is rendered in the UI
export const resultKindComponentMap: ResultComponentMap = {
  [ResultKind.PAGE_NAVIGATION]:
    PageNavigationResult as React.ComponentType<PageNavigationResultProps>,
  [ResultKind.EMPLOYEE]:
    EmployeeResult as React.ComponentType<EmployeeResultProps>,
  [ResultKind.ACTION]: ActionResult as React.ComponentType<ActionResultProps>,
};

// Search result types
export type SmartSearchPageNavigationOption = {
  kind: ResultKind.PAGE_NAVIGATION;
} & {
  text: string;
  url: string;
  description: string;
  keywords: string[];
};

export type SmartSearchEmployeeOption = {
  kind: ResultKind.EMPLOYEE;
} & {
  firstName: string;
  lastName: string;
  email: string | null;
  url: string;
  personalEmail?: string;
  jobTitle?: string;
};

export type SmartSearchActionOption = {
  kind: ResultKind.ACTION;
} & {
  title: string;
  keywords?: string[];
};

export type SmartSearchSelectOptionKind =
  | SmartSearchPageNavigationOption
  | SmartSearchEmployeeOption
  | SmartSearchActionOption;

export type SmartSearchSelectOption = {
  isDefaultOption?: boolean;
  url: string;
  isVisibleToEndUser?: boolean;
} & SmartSearchSelectOptionKind;

type KeysOfUnion<T> = T extends T ? keyof T : never;

// Fuse search key can be any property of any search result type
type FuseSearchKey = KeysOfUnion<SmartSearchSelectOptionKind>;

// Keys to search on for each result kind
export const FUSE_SEARCH_KEYS: FuseSearchKey[] = [
  'text',
  'url',
  'description',
  'keywords',
  'firstName',
  'lastName',
  'email',
  'personalEmail',
  'jobTitle',
  'title',
];

import {
  Box,
  ColorsV2,
  Icon,
  Text,
  Tooltip,
  useThemeColors,
} from '@electricjs/arc';
import styled from 'styled-components';

const StyledBoxTooltip = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const infoText = (
  <StyledBoxTooltip>
    <Text fontSize="1.25rem" lineHeight="1.6rem">
      Provisioning is a crucial part of Electric’s services that allows us to
      directly manage all of your IT needs through your device. We automatically
      recommend the best provisioning method based on the device you selected,
      and offer several types:
    </Text>
    <Text fontSize="1.25rem" lineHeight="1.6rem" mt={12}>
      Employee-initiated enrollment: your employee will receive an email from
      Electric with a link and instructions on how to easily enroll their device
      in your pre-configured environment.
    </Text>
    <Text fontSize="1.25rem" lineHeight="1.6rem" mt={12}>
      Provision at Electric: device is shipped to Electric HQ to be set up by a
      provisioning specialist prior to being shipped to your destination.
    </Text>
    <Text fontSize="1.25rem" lineHeight="1.6rem" mt={12}>
      Zero-touch provisioning: for Apple devices only, the device will be
      shipped directly from Apple and remotely provisioned once activated and
      connected to the internet.
    </Text>
  </StyledBoxTooltip>
);

export const ProvisioningTooltip = () => {
  const infoColor = useThemeColors(ColorsV2.GRAY_DARK);

  return (
    <Tooltip
      content={infoText}
      maxWidth="35rem"
      placement="right-end"
      testId="provisioning-selection-tooltip">
      <Icon icon="information-circle" color={infoColor} ml={8} />
    </Tooltip>
  );
};

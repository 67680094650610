import { Chip, ColorsV2, Flex, Text, useThemeColors } from '@electricjs/arc';

import { ABMManagementCard } from '@/components/ABMManagement/ABMComponents';

type ABMCompletedProps = {
  abmID?: number;
  onEdit: () => void;
  onRemove: () => void;
};

const ABMCompleted = ({ abmID, onEdit, onRemove }: ABMCompletedProps) => {
  const [chipBackgroundColor, chipTextColor] = useThemeColors([
    ColorsV2.SUCCESS_LIGHTEST,
    ColorsV2.SUCCESS_DARK,
  ]);

  return (
    <ABMManagementCard hasSettingsMenu onEdit={onEdit} onRemove={onRemove}>
      <Flex vertical p="4rem" rowGap="2rem">
        <Flex alignItems="center" columnGap="2rem">
          <Text variant="label-large">Status</Text>
          <Chip
            id="abm-connected-chip"
            backgroundColor={chipBackgroundColor}
            textColor={chipTextColor}>
            Connected
          </Chip>
        </Flex>
        <Flex alignItems="center" columnGap="2rem">
          <Text variant="label-large">ABM Organizational ID</Text>
          <Text>{abmID}</Text>
        </Flex>
      </Flex>
    </ABMManagementCard>
  );
};

export default ABMCompleted;

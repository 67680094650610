import { VictoryLabel } from 'victory-core';
import { VictoryPie } from 'victory-pie';

import { Box, ColorsV2, useThemeColors } from '@electricjs/arc';

export type DonutChartDatum = {
  color?: string;
  key: string;
  value: number;
};

export type DonutChartData = Array<DonutChartDatum>;

export type DonughtGraphProps = {
  data: DonutChartData;
  innerLabel?: string;
  innerLabelSize?: number;
} & React.ComponentProps<typeof VictoryPie>;

export type DotProps = {
  color: string;
  width?: string;
  height?: string;
};

export const Dot = ({ color, width, height }: DotProps) => {
  return (
    <Box
      borderRadius="50%"
      width={width || '1rem'}
      height={height || '1rem'}
      backgroundColor={color}></Box>
  );
};

const DonutGraph = ({
  data,
  innerLabel,
  innerLabelSize,
  ...victoryPieProps
}: DonughtGraphProps) => {
  // Text color to match dark/light mode
  const [textColor] = useThemeColors([ColorsV2.TEXT]);

  return (
    <svg viewBox="0 0 400 400">
      <VictoryPie
        standalone={false}
        data={data}
        x="key"
        y="value"
        innerRadius={150}
        padding={0}
        width={400}
        height={400}
        labelRadius={100}
        colorScale={data.map(d => d.color || 'black')}
        style={{ labels: { display: 'None' } }}
        {...victoryPieProps}
      />
      {innerLabel && (
        <VictoryLabel
          textAnchor="middle"
          style={{
            fontFamily: 'Roboto',
            fontSize: innerLabelSize || 150,
            fontWeight: 700,
            fill: textColor,
          }}
          x={200}
          y={200}
          text={innerLabel}
        />
      )}
    </svg>
  );
};

export default DonutGraph;

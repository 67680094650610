import { Select, Box, Text } from '@electricjs/arc';

import { SortingParams } from '@/types/common';

type SortingOption = {
  label: string;
  value: SortingParams;
};

type ProductSortingSelectProps = {
  options: SortingOption[];
  width?: string;
  selectedSorting: SortingParams;
  onSortingSelect: (sorting: SortingParams) => void;
};

const ProductSortingSelect = ({
  options,
  width,
  selectedSorting,
  onSortingSelect,
}: ProductSortingSelectProps) => {
  const handleSelection = (option: SortingOption) => {
    onSortingSelect({ ...option.value });
  };

  return (
    <Box width={width}>
      <Text variant="label-large">Sort</Text>
      <Select
        id="storefront-sorting-select"
        options={options}
        onChange={handleSelection}
        value={options.find(
          option =>
            option.value.orderBy === selectedSorting.orderBy &&
            option.value.sortOrder === selectedSorting.sortOrder
        )}
      />
    </Box>
  );
};

export default ProductSortingSelect;

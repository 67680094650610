import { BREAKPOINTS } from '@electricjs/arc';

const { sm, md, lg, xl, xxl } = BREAKPOINTS;
const QUERIES = {
  sm: `(min-width: ${sm})`,
  md: `(min-width: ${md})`,
  lg: `(min-width: ${lg})`,
  xl: `(min-width: ${xl})`,
  xxl: `(min-width: ${xxl})`,
};

export default QUERIES;

import { yupResolver } from '@hookform/resolvers/yup';
import CenteredSpinner from 'common/CenteredSpinner';
import { createContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { PermissionEnum } from '@electricjs/core_entity-client';
import { OneColumnLayout } from '@electricjs/arc';

import AssetDetailsHeader from '@/components/Assets/AssetDetails/AssetDetailsHeader';
import AssetError from '@/components/Assets/AssetDetails/AssetError';
import { assetSchema } from '@/components/Assets/AssetSchema';
import {
  getAssetStatusOptions,
  transformAssetConditionsToOptions,
  transformProductTypesToOptions,
  transformSitesToOptions,
  transformLocationsToOptions,
} from '@/components/Assets/helpers/transformOptions';
import { useGlobalUI } from '@/components/GlobalUIProvider';
import { EmployeeOption } from '@/components/People/EmployeeGroups/NewEmployeeGroupForm';
import { transformEmployeesToOptions } from '@/components/People/transformOptions';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useOrganizationProductTypes } from '@/hooks/useOrganizationProductTypes';
import {
  useGetAssetQuery,
  useGetOrganizationSitesQuery,
  useGetSiteLocationsQuery,
  useUpdateAssetLocationMutation,
  useUpdateAssetMutation,
} from '@/redux/slices/assetApiSlice';
import { useGetOrganizationEmployeesQuery } from '@/redux/slices/organizationApiSlice';
import { EmployeeStatus } from '@/types/employees';

import { AssetFormData, assetDefaultValues } from './NewAssetForm';
import { useUserHasOrganizationPermission } from '@/hooks/useUserHasOrganizationPermission';
import AssetDetailsContent from './AssetDetailsContent/AssetDetailsContent';

type AssetDetailContextArgs = {
  isEditing: boolean;
  setIsEditing: (_arg0: boolean) => void;
};

export const AssetDetailsContext = createContext<AssetDetailContextArgs>({
  isEditing: false,
  setIsEditing: () => null,
});

type AssetDetailsProps = {
  isEditing?: boolean;
};

const AssetDetails = ({
  isEditing: isEditingAsset = false,
}: AssetDetailsProps) => {
  const [isEditing, setIsEditing] = useState(isEditingAsset);

  const organizationId = useGetOrganizationId();

  const { assetId = '' } = useParams();

  const { showSuccessToast, showErrorToast } = useGlobalUI();

  const {
    isFetching: isFetchingAsset,
    isError: isAssetError,
    data: assetData,
  } = useGetAssetQuery(
    {
      organizationId,
      assetId,
    },
    { skip: !assetId }
  );
  const {
    data: employees,
    isFetching: isFetchingEmployees,
    isError: isEmployeesError,
  } = useGetOrganizationEmployeesQuery({
    organizationId,
  });

  const employeeOptions = useMemo(
    () =>
      transformEmployeesToOptions(
        employees?.filter(
          employee => employee.status === EmployeeStatus.Active
        ) ?? []
      ),
    [employees]
  );

  const {
    productTypes,
    isFetching: isFetchingProductTypes,
    isError: isProductTypesError,
  } = useOrganizationProductTypes({ skip: !isEditing });

  const productTypeOptions = useMemo(
    () => transformProductTypesToOptions(productTypes || []),
    [productTypes]
  );

  const canUpdateAssetLocation = useUserHasOrganizationPermission(
    PermissionEnum.Assetslocationupdate
  );

  const canReadAssetLocation = useUserHasOrganizationPermission(
    PermissionEnum.Assetslocationread
  );

  const {
    data: sites,
    isFetching: isFetchingSites,
    isError: isSitesError,
  } = useGetOrganizationSitesQuery({ organizationId }, { skip: !isEditing });

  const siteOptions = useMemo(() => {
    // This is meant to prevent non-product support users from moving an asset to a global site.
    // They'll be able to see a global site in the drop-down only if the asset is already assigned to it.
    // We might eventually want more specific permissions around the ability to see / set global sites.
    const filteredSites = sites?.filter(
      site =>
        site?.organizationId ||
        assetData?.site?.id === site?.id ||
        canUpdateAssetLocation
    );
    return transformSitesToOptions(filteredSites || []);
  }, [sites, assetData, canUpdateAssetLocation]);

  const assetStatusOptions = useMemo(() => getAssetStatusOptions(), []);
  const assetConditionOptions = useMemo(
    () => transformAssetConditionsToOptions(),
    []
  );

  const [updateAsset, { isLoading: isUpdatingAsset }] =
    useUpdateAssetMutation();

  const [updateAssetLocation, { isLoading: isUpdatingAssetLocation }] =
    useUpdateAssetLocationMutation();

  const methods = useForm<AssetFormData>({
    defaultValues: assetDefaultValues,
    resolver: yupResolver(assetSchema),
    criteriaMode: 'all',
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });
  const { handleSubmit, reset, clearErrors, watch, setError, setValue } =
    methods;
  const [assignedToValue, siteValue] = watch(['assignedTo', 'site']);

  const {
    data: locations,
    isFetching: isFetchingLocations,
    isError: isLocationsError,
  } = useGetSiteLocationsQuery(siteValue?.value ?? '', {
    skip: !siteValue?.value || !canReadAssetLocation,
  });

  const locationOptions = useMemo(
    () => transformLocationsToOptions(locations || []),
    [locations]
  );

  useEffect(() => {
    if (isEditing) {
      reset({
        name: assetData?.name,
        customAssetId: assetData?.customAssetId,
        purchaseDate: assetData?.purchaseDate
          ? new Date(assetData?.purchaseDate)
          : undefined,
        purchasePrice: assetData?.purchasePrice?.toString(),
        purchasedFrom: assetData?.purchasedFromUrl,
        brand: assetData?.brand,
        model: assetData?.model,
        serialNumber: assetData?.serialNumber,
        notes: assetData?.notes,
        internalNotes: assetData?.internalNotes,
        status: assetStatusOptions.find(
          option => option.value === assetData?.status
        ),
        condition: assetConditionOptions.find(
          option => option.value === assetData?.condition
        ),
        warrantyExpirationDate: assetData?.warrantyExpirationDate
          ? new Date(assetData?.warrantyExpirationDate)
          : undefined,
      });
    }
  }, [assetData, isEditing, reset, assetStatusOptions, assetConditionOptions]);

  useEffect(() => {
    if (isEditing && !isFetchingProductTypes) {
      const productType = productTypeOptions.find(
        option => option.value === assetData?.productType.id
      );
      if (productType) {
        setValue('productType', productType);
      }
    }
  }, [
    assetData,
    productTypeOptions,
    isEditing,
    isFetchingProductTypes,
    setValue,
  ]);

  useEffect(() => {
    if (isEditing && !isFetchingEmployees) {
      const assignedTo = employeeOptions.find(
        (option: EmployeeOption) => option.value === assetData?.assignedTo
      );
      if (assignedTo) {
        setValue('assignedTo', assignedTo);
      }
    }
  }, [assetData, employeeOptions, isEditing, isFetchingEmployees, setValue]);

  useEffect(() => {
    if (isEditing && !isFetchingSites) {
      const site = siteOptions.find(
        option => option.value === assetData?.site?.id
      );
      setValue('site', site);
    }
  }, [assetData, isEditing, isFetchingSites, setValue, siteOptions]);

  useEffect(() => {
    if (isEditing && !isFetchingLocations) {
      const location =
        locationOptions.find(
          option => option.value === assetData?.locationId
        ) ?? null;
      setValue('location', location);
    }

    if (siteValue?.value !== assetData?.site?.id) {
      setValue('location', null);
    }
  }, [
    assetData,
    isEditing,
    isFetchingLocations,
    setValue,
    locationOptions,
    siteValue,
  ]);

  useEffect(() => {
    if (assignedToValue || siteValue) {
      clearErrors('assignedTo');
      clearErrors('site');
    }
  }, [assignedToValue, clearErrors, siteValue]);

  const onSubmit = (data: AssetFormData) => {
    if (!assignedToValue && !siteValue) {
      const errorText =
        'Either the site or who the asset is assigned to must be provided';
      setError('assignedTo', {
        message: errorText,
      });
      setError('site', { message: errorText });
      return false;
    }

    updateAsset({
      organizationId,
      assetId,
      name: data.name,
      productTypeId: data.productType?.value,
      customAssetId: data.customAssetId,
      // Only update assignedTo if it has changed
      assignedTo:
        data.assignedTo?.value !== assetData?.assignedTo
          ? data.assignedTo?.value || null
          : undefined,
      purchaseDate: data.purchaseDate,
      purchasePrice: data.purchasePrice ? Number(data.purchasePrice) : null,
      purchasedFromUrl: data.purchasedFrom,
      brand: data.brand,
      model: data.model,
      serialNumber: data.serialNumber,
      notes: data.notes,
      internalNotes: data.internalNotes,
      siteId: data.site?.value || null,
      // Only update status if it has changed
      status:
        data.status?.value !== assetData?.status
          ? data.status?.value
          : undefined,
      condition: data.condition?.value,
      imageUrl: data.imageUrl,
      warrantyExpirationDate: data.warrantyExpirationDate,
    })
      .unwrap()
      .catch(error => {
        console.error('Error while updating asset: ', error);
        showErrorToast({
          id: 'update-asset-error-toast',
          message: 'Something went wrong. Please try again.',
        });
        return Promise.reject(error);
      })
      .then(() => {
        const newLocationId = data.location?.value || null;

        if (canUpdateAssetLocation && newLocationId !== assetData?.locationId) {
          updateAssetLocation({
            organizationId,
            assetId,
            locationId: newLocationId,
          })
            .unwrap()
            .then(() => {
              showSuccessToast({
                id: 'update-asset-success-toast',
                message: 'The asset was successfully updated.',
              });
            })
            .catch(error => {
              console.error('Error while updating asset location: ', error);
              showErrorToast({
                id: 'update-asset-location-error-toast',
                message: 'Something went wrong. Please try again.',
              });
              return Promise.reject(error);
            });
        }
        setIsEditing(false);
      });
  };
  if (isFetchingAsset || isUpdatingAsset || isUpdatingAssetLocation) {
    return <CenteredSpinner />;
  }

  return !assetData ||
    isAssetError ||
    (isEditing &&
      (isEmployeesError ||
        isProductTypesError ||
        isSitesError ||
        isLocationsError)) ? (
    <AssetError />
  ) : (
    <OneColumnLayout>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <AssetDetailsContext.Provider value={{ isEditing, setIsEditing }}>
            <AssetDetailsHeader
              asset={assetData}
              productTypeOptions={productTypeOptions}
              employeeOptions={employeeOptions}
              siteOptions={siteOptions}
              locationOptions={locationOptions}
              assetStatusOptions={assetStatusOptions}
              assetConditionOptions={assetConditionOptions}
              isFetchingProductTypes={isFetchingProductTypes}
              isFetchingEmployees={isFetchingEmployees}
              isFetchingSites={isFetchingSites}
              isUpdatingAsset={isUpdatingAsset || isUpdatingAssetLocation}
              isFetchingLocations={isFetchingLocations}
            />
            {!isEditing && <AssetDetailsContent />}
          </AssetDetailsContext.Provider>
        </form>
      </FormProvider>
    </OneColumnLayout>
  );
};

export default AssetDetails;

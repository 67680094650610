import styled from 'styled-components';

import {
  Box,
  Button,
  ColorsV2,
  Flex,
  IconInCircle,
  Spacer,
  Text,
  getToken,
  useThemeColors,
} from '@electricjs/arc';

import {
  DeviceOverviewContentText,
  SecurityEnforcedDeviceOverviewContentText,
} from '@/components/DeviceOverview/DeviceIssues/DeviceIssuesContentText';
import DiskSpaceEmailModal from '@/components/DeviceOverview/IssueModals/DiskSpaceEmailModal';
import EncryptionEmailModal from '@/components/DeviceOverview/IssueModals/EncryptionEmailModal';
import FirewallEmailModal from '@/components/DeviceOverview/IssueModals/FirewallEmailModal';
import GatekeeperEmailModal from '@/components/DeviceOverview/IssueModals/GatekeeperEmailModal';
import OSUpdateEmailModal from '@/components/DeviceOverview/IssueModals/OSUpdateEmailModal';
import { useGlobalUI } from '@/components/GlobalUIProvider';
import { DeviceIssueType, DeviceIssueTypeOld } from '@/types/devices';
import { EmployeeWithAssets } from '@/types/employees';

const StyledBox = styled(Box)`
  border-top: 1px solid ${getToken(ColorsV2.GRAY_LIGHT)};
  width: 100%;
`;

type DeviceOverviewContentProps = {
  selectedTab: DeviceIssueTypeOld;
  employeesWithDeviceIssues: EmployeeWithAssets[];
  isMDMSetup: boolean;
  isDemo?: boolean;
};

const DEVICE_ISSUE_MODAL_COMPONENT = {
  [DeviceIssueType.OS]: OSUpdateEmailModal,
  [DeviceIssueType.Encryption]: EncryptionEmailModal,
  [DeviceIssueType.Firewall]: FirewallEmailModal,
  [DeviceIssueType.Gatekeeper]: GatekeeperEmailModal,
  [DeviceIssueType.Storage]: DiskSpaceEmailModal,
  [DeviceIssueType.RAM]: null,
  /* TODO HY-218 We're temporarily disabling Battery health while we improve the logic behind it */
  // [DeviceIssueType.BatteryHealth]: null,
} as const;

const DeviceOverviewContent = ({
  selectedTab = DeviceIssueType.OS,
  employeesWithDeviceIssues,
  isMDMSetup,
  isDemo = false,
}: DeviceOverviewContentProps) => {
  const [iconColor, iconBackground] = useThemeColors([
    ColorsV2.SECONDARY,
    ColorsV2.SECONDARY_LIGHTEST,
  ]);
  const { openModal, closeModal } = useGlobalUI();
  // Wanted to do something with Typescript to omit these from the DeviceIssueType enum,
  // but this was way more straightforward. No email for these issues.
  const showEmailButton = selectedTab !== DeviceIssueType.RAM;
  /* TODO HY-218 We're temporarily disabling Battery health while we improve the logic behind it */
  // &&
  // selectedTab !== DeviceIssueType.BatteryHealth;

  const content = isMDMSetup
    ? SecurityEnforcedDeviceOverviewContentText
    : DeviceOverviewContentText;

  const modal = () => {
    const ModalComponent = DEVICE_ISSUE_MODAL_COMPONENT[selectedTab];
    if (ModalComponent) {
      return (
        <ModalComponent
          hide={closeModal}
          employees={employeesWithDeviceIssues}
        />
      );
    }
    return null;
  };

  const handleButtonClick = () => {
    if (isDemo) return;
    openModal(modal);
  };

  return (
    <StyledBox mt={4} pt={5}>
      <Flex justifyContent="space-between">
        <Flex flexDirection="column">
          <Flex vAlignContent="center">
            <Text variant="heading-3">{content[selectedTab].title}</Text>
            {content[selectedTab].policyDescription && (
              <IconInCircle
                m="0.5rem"
                size="3rem"
                color={iconColor}
                background={iconBackground}
                icon="bolt"
                iconSize="medium"
              />
            )}
          </Flex>
          <Text variant="body">{content[selectedTab].description}</Text>
          {content[selectedTab].policyDescription && (
            <>
              <Spacer />
              <Text variant="body">
                {content[selectedTab].policyDescription}
              </Text>
            </>
          )}
          {employeesWithDeviceIssues.length > 0 && (
            <Text mt={2}>{content[selectedTab].emailPrompt}</Text>
          )}
        </Flex>
        {employeesWithDeviceIssues.length > 0 && showEmailButton && (
          <Button
            whiteSpace="pre"
            onClick={handleButtonClick}
            id={`${selectedTab}-button`}
            px="6rem">
            Email employees
          </Button>
        )}
      </Flex>
    </StyledBox>
  );
};

export default DeviceOverviewContent;

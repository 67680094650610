import { useEffect, useState } from 'react';
import { ProductTypeName } from '@electricjs/core_entity-client';
import { Checkbox } from '@electricjs/arc';

import formatStringForId from '@/helpers/formatStringForId';
import { FilterDropdown } from '@common';
import { type DynamicFilters, FilterKeyEnum } from '@/types/storefront';
import {
  translateProductsFilters,
  filterKeyTranslation,
} from '@/components/Storefront/utils';
import {
  updateSelectedFilters,
  formatFilterValue,
} from '@/components/Storefront/components/ProductSpecsFilter/filterUtils';

type ProductMenuFilterProps = {
  filterKey: FilterKeyEnum;
  options: string[];
  currentFilters?: DynamicFilters;
  onFilterSelect: (value: string[], key: FilterKeyEnum) => void;
  isKeyboardMouseGroup: boolean;
  onProductTypesSelect: (types: ProductTypeName[]) => void;
};

const ProductMenuFilter = ({
  filterKey,
  options,
  currentFilters,
  onFilterSelect,
  isKeyboardMouseGroup,
  onProductTypesSelect,
}: ProductMenuFilterProps) => {
  const [selectedMenuFilters, setSelectedMenuFilters] = useState<string[]>([]);
  const someOptionIsChecked =
    selectedMenuFilters?.filter(value => options?.includes(value)).length > 0;

  const handleOnClick = (key: FilterKeyEnum, value: string) => {
    const updatedSelectedFilters = updateSelectedFilters(
      value,
      selectedMenuFilters
    );
    onFilterSelect(updatedSelectedFilters, key);
    setSelectedMenuFilters(updatedSelectedFilters);

    if (isKeyboardMouseGroup && key === FilterKeyEnum.TYPE) {
      if (updatedSelectedFilters.length === 0) {
        // when no type is selected, return all types of this group
        onProductTypesSelect([
          ProductTypeName.Keyboard,
          ProductTypeName.Mouse,
          ProductTypeName.KeyboardMouse,
        ]);
      } else {
        onProductTypesSelect([...updatedSelectedFilters] as ProductTypeName[]);
      }
    }
  };

  // Change the selected options when closing the chip filter
  useEffect(() => {
    if (currentFilters && selectedMenuFilters?.length > 0) {
      setSelectedMenuFilters(currentFilters[filterKey] ?? []);
    }
  }, [currentFilters, filterKey, selectedMenuFilters?.length]);

  return (
    <FilterDropdown
      someOptionIsChecked={someOptionIsChecked}
      dropdownContent={options.map(option => {
        return (
          <Checkbox
            id={`product-menu-filter-${formatStringForId(option)}`}
            key={option}
            checked={selectedMenuFilters?.includes(option)}
            onChange={() => handleOnClick(filterKey, option)}>
            {translateProductsFilters(filterKey, option)}
          </Checkbox>
        );
      })}
      filterName={
        filterKeyTranslation[filterKey] ?? formatFilterValue(filterKey)
      }
    />
  );
};

export default ProductMenuFilter;

import { ResultKind, SmartSearchSelectOption } from '@/components/SmartSearch';

import { getElectrolyteBaseUrl } from '../urls';

const electrolyteBaseUrl = getElectrolyteBaseUrl();

export const internalPages: SmartSearchSelectOption[] = [
  {
    kind: ResultKind.PAGE_NAVIGATION,
    text: 'Your IT Plan',
    url: `${electrolyteBaseUrl}/your-it-plan`,
    description: "High-level overview of your company's IT plan",
    keywords: ['device', 'security'],
    isDefaultOption: true,
    isVisibleToEndUser: false,
  },
  {
    kind: ResultKind.PAGE_NAVIGATION,
    text: 'Devices',
    url: `${electrolyteBaseUrl}/device-overview`,
    description: "View a summary of your company's devices",
    keywords: ['device', 'security'],
    isDefaultOption: true,
    isVisibleToEndUser: false,
  },
  {
    kind: ResultKind.PAGE_NAVIGATION,
    text: 'Active Employees',
    url: `${electrolyteBaseUrl}/active-employees`,
    description: 'Manage your active employees',
    keywords: ['onboard', 'offboard', 'info', 'license', 'hris', 'device'],
    isDefaultOption: true,
    isVisibleToEndUser: false,
  },
  {
    kind: ResultKind.PAGE_NAVIGATION,
    text: 'Discover',
    url: `${electrolyteBaseUrl}/discover`,
    description:
      'Explore options for improving security in your IT environment',
    keywords: ['partner', 'marketplace', 'learn'],
    isDefaultOption: true,
    isVisibleToEndUser: false,
  },
  {
    kind: ResultKind.PAGE_NAVIGATION,
    text: 'Billing',
    url: `${electrolyteBaseUrl}/settings?tab=billing`,
    description: "View your company's plan and add-ons",
    keywords: ['payment', 'invoice', 'subscription', 'license'],
    isVisibleToEndUser: false,
  },
  {
    kind: ResultKind.PAGE_NAVIGATION,
    text: 'MDM Setup',
    url: `${electrolyteBaseUrl}/settings?tab=device`,
    description: "Set up MDM for your employees' devices",
    keywords: ['download', 'install'],
    isVisibleToEndUser: false,
  },
  {
    kind: ResultKind.PAGE_NAVIGATION,
    text: 'Applications',
    url: `${electrolyteBaseUrl}/applications`,
    description: 'Manage the applications your employees use',
    keywords: ['app', 'application', 'champion'],
    isDefaultOption: true,
    isVisibleToEndUser: false,
  },
  {
    kind: ResultKind.PAGE_NAVIGATION,
    text: 'Employee Groups',
    url: `${electrolyteBaseUrl}/groups`,
    description: 'Configure your employee groups and settings',
    keywords: [],
    isDefaultOption: true,
    isVisibleToEndUser: false,
  },
  {
    kind: ResultKind.PAGE_NAVIGATION,
    text: 'Hardware',
    url: `${electrolyteBaseUrl}/hardware`,
    description: 'Purchase hardware for your employees',
    keywords: ['buy', 'device', 'procurement'],
    isDefaultOption: true,
    isVisibleToEndUser: false,
  },
  {
    kind: ResultKind.PAGE_NAVIGATION,
    text: 'Notifications',
    url: `${electrolyteBaseUrl}/notification-center`,
    description: 'View your notifications',
    keywords: [],
    isVisibleToEndUser: true,
  },
  {
    kind: ResultKind.PAGE_NAVIGATION,
    text: 'Support Ticket Routing',
    url: `${electrolyteBaseUrl}/settings?tab=support-center`,
    description: 'Manage the employees that will receive support tickets',
    keywords: ['champion', 'help desk'],
    isVisibleToEndUser: false,
  },
  {
    kind: ResultKind.PAGE_NAVIGATION,
    text: 'Electric Plans',
    url: `${electrolyteBaseUrl}/plans`,
    description: "View Electric's available plans",
    keywords: ['packages', 'upgrade', 'features'],
    isVisibleToEndUser: false,
  },
  {
    kind: ResultKind.PAGE_NAVIGATION,
    text: 'Requests',
    url: `${electrolyteBaseUrl}/support-center`,
    description: 'Submit a request for help',
    keywords: ['ask a question', 'get help'],
    isDefaultOption: true,
    isVisibleToEndUser: true,
  },
  {
    kind: ResultKind.PAGE_NAVIGATION,
    text: 'Helpful Links',
    url: `${electrolyteBaseUrl}/helpful-links`,
    description: 'Manage your links to helpful resources',
    keywords: [],
    isDefaultOption: true,
    isVisibleToEndUser: true,
  },
  {
    kind: ResultKind.PAGE_NAVIGATION,
    text: 'Gigawatt',
    url: `${electrolyteBaseUrl}/AIChat`,
    description: 'Chat with Gigawatt, our AI service desk agent',
    keywords: ['chat', 'bot', 'ask a question', 'get help'],
    isDefaultOption: true,
    isVisibleToEndUser: true,
  },
];

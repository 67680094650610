import { Flex, ColorsV2, Text, getToken } from '@electricjs/arc';
import styled from 'styled-components';
import { ifProp } from 'styled-tools';

export const FormActionsContainer = styled(Flex)<{
  hidePreviousButton?: boolean;
}>`
  width: 100%;
  justify-content: ${ifProp('hidePreviousButton', 'flex-end', 'space-between')};
  margin-top: 6.5rem;
`;

export const TooltipReference = styled(Text)`
  color: ${getToken(ColorsV2.PRIMARY)};
  text-decoration: underline;
  text-decoration-color: ${getToken(ColorsV2.PRIMARY)};
  cursor: default;
`;

import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { ElectricPermission } from '@/types/users';
import { useUserHasElectricPermission } from '@/hooks/useUserHasElectricPermission';
import PageNotFound from '@/pages/PageNotFound';

import { ErrorPage } from './ErrorPage';
import { InternalPageWrapper } from './InternalPageWrapper';

const InternalHome = lazy(() => import('@/pages/Internal/Home'));
const InternalApplications = lazy(
  () => import('@/pages/Applications/InternalApplications')
);
const InternalApplicationsDetails = lazy(
  () =>
    import(
      '@/pages/Applications/InternalApplications/InternalApplicationDetails'
    )
);

const GlobalSites = lazy(() => import('@/pages/GlobalSites/GlobalSites'));
const SiteLocations = lazy(() => import('@/pages/Locations/SiteLocations'));

const OrderManagement = lazy(
  () => import('@/pages/Internal/OrderManagement/OrderManagement')
);
const OrderDetailsPage = lazy(
  () => import('@/pages/Internal/OrderManagement/OrderDetailsPage')
);
const OrderRefundPage = lazy(
  () => import('@/pages/Internal/OrderManagement/OrderRefundPage')
);

const AllProducts = lazy(() => import('@/pages/Internal/Catalog/AllProducts'));
const ProductProfilePage = lazy(
  () => import('@/pages/Internal/Catalog/ProductProfilePage')
);

const InternalBilling = lazy(() => import('@/pages/Internal/Billing/Billing'));
const NewQuote = lazy(() => import('@/pages/Internal/Billing/Quotes/NewQuote'));

export const InternalRoutes = () => {
  const { storefrontCatalogManager, itHubInternalBillingFeature } = useFlags();

  const canReadInternalOrders = useUserHasElectricPermission(
    ElectricPermission.ORDERS_READ
  );

  const canIssueOrderRefund = useUserHasElectricPermission(
    ElectricPermission.ORDERS_ISSUE_REFUND
  );

  const canReadProductProfiles = useUserHasElectricPermission(
    ElectricPermission.PRODUCTS_READ
  );
  const canUpdateProductProfiles = useUserHasElectricPermission(
    ElectricPermission.PRODUCTS_UPDATE
  );

  // Base route is /internal/...
  return (
    <Routes>
      <Route element={<InternalPageWrapper />} errorElement={<ErrorPage />}>
        {/* Default route - redirects based on permissions */}
        <Route path="/" element={<InternalHome />} />

        {/* Applications */}
        <Route path="/applications" element={<InternalApplications />} />
        <Route
          path="/applications/new"
          element={<InternalApplicationsDetails />}
        />
        <Route
          path="/applications/:applicationSlug"
          element={<InternalApplicationsDetails edit={true} />}
        />

        {/* Warehouse */}
        <Route path="/warehouse" element={<GlobalSites />} />
        <Route
          path="/warehouse/sites/:siteId/locations"
          element={<SiteLocations />}
        />

        {/* Order Management */}
        <Route path="/order-management" element={<OrderManagement />} />
        {canReadInternalOrders && (
          <Route
            path="/order-management/:friendlyId"
            element={<OrderDetailsPage />}
          />
        )}
        {canIssueOrderRefund && (
          <Route
            path="/order-management/:friendlyId/refund"
            element={<OrderRefundPage />}
          />
        )}

        {/* Catalog */}
        {storefrontCatalogManager && canReadProductProfiles && (
          <Route path="/catalog/products" element={<AllProducts />} />
        )}
        {storefrontCatalogManager && canUpdateProductProfiles && (
          <Route
            path="/catalog/products/:productId"
            element={<ProductProfilePage />}
          />
        )}

        {/* Billing */}
        {itHubInternalBillingFeature && (
          <>
            <Route path="/billing" element={<InternalBilling />} />
            <Route path="/billing/new-quote" element={<NewQuote />} />
          </>
        )}

        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};

import { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import {
  Button,
  Divider,
  Flex,
  Popover,
  Skeleton,
  Text,
  usePopoverContext,
} from '@electricjs/arc';

import { Notification } from '@/types/notifications';

import {
  NotificationItem,
  getSupportedNotifications,
} from './NotificationItem/NotificationItem';
import {
  getUnseenNotifications,
  hasUnseenNotifications,
} from './helpers/notificationHelpers';

const NotificationPanel = styled(Popover)`
  width: 30rem;
  min-height: 30rem;
  padding: 0;
`;

const NotificationListContainer = styled.ul`
  min-width: 100%;
  padding: 0px;
  margin: 0;
  list-style: none;
  text-align: left;
  max-height: 30rem;
  overflow-y: scroll;
  /** Hide scrollbar for Chrome, Safari and Opera  **/
  -ms-overflow-style: none;

  /* Hide scrollbar for IE and Edge */
  ::-webkit-scrollbar {
    display: none;
  }
`;

const PanelHeader = () => {
  const navigate = useNavigate();
  const { toggle } = usePopoverContext();

  const handleClick = () => {
    toggle();
    navigate('/notification-center');
  };

  return (
    <>
      <Flex flexDirection="row">
        <Text variant="label-large" pl={3} pt={3} mb={1}>
          Notifications
        </Text>
        <Button
          id="button-view-all-notifications"
          variant="text"
          my="0.5rem"
          marginLeft="auto"
          onClick={handleClick}>
          View All
        </Button>
      </Flex>
      <Divider />
    </>
  );
};

const EmptyState = () => {
  return (
    <Text variant="label-small" mt={3} width="100%" textAlign="center">
      Nothing to see here!
    </Text>
  );
};

const LoadingState = () => {
  return (
    <Flex py="4" px="2" mt="1" flexDirection="row" flexWrap="wrap">
      <Flex p="1" px="1">
        <Skeleton width="2.6rem" height="2.6rem" />
      </Flex>
      <Flex ml="2">
        <Skeleton width="22rem" height="2rem" count={4} />
      </Flex>
    </Flex>
  );
};

type NotificationDropdownProps = {
  notifications: Notification[];
  onMarkNotificationsAsSeen?: (ids: string[]) => void;
  loading: boolean;
  loadMore: (limit: number, offset: number) => void;
  totalNotifications: number;
};

const NotificationDropdown = ({
  notifications,
  onMarkNotificationsAsSeen,
  loading,
  loadMore,
  totalNotifications,
}: NotificationDropdownProps) => {
  const [opened, setOpened] = useState(false);
  const [hasTimedOut, setHasTimedOut] = useState(false);

  const { visible: visible } = usePopoverContext();

  // For infinite scroll
  const [items, setItems] = useState<Notification[]>([]);
  const PAGE_SIZE = 5;
  const [page, setPage] = useState(0);
  const MAX_ITEMS_SIZE = 20;

  const handleOnMarkAsSeenNotifications = useCallback(() => {
    // trigger if notifications unseen
    const unseenIds = getUnseenNotifications(items);
    onMarkNotificationsAsSeen && onMarkNotificationsAsSeen(unseenIds);
  }, [items, onMarkNotificationsAsSeen]);

  useEffect(() => {
    /** fetch notifications when it is visible */
    if (visible && !opened) {
      loadMore(PAGE_SIZE, page);
      setOpened(true);
    } else if (!visible) {
      setOpened(false);
      /** reset items and page when it's closed */
      setItems([]);
      setPage(0);
    }
  }, [visible, opened, loadMore, page]);

  const skip = !hasUnseenNotifications(notifications); // Skipping because there's no unseen notification

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    // if visible, start timeout to set hasTimedOut to true,
    // which is used for calling handleOnMarkAsSeenNotifications
    if (visible) {
      timeout = setTimeout(() => {
        // skip if there's no unseen notifications
        if (!skip) setHasTimedOut(true);
      }, 2000);
    }

    // if not visible and hasTimedOut, mark notifications as seen
    if (!visible && hasTimedOut) {
      handleOnMarkAsSeenNotifications();
      setHasTimedOut(false); // reset the hasTimedOut value after the notifications has been marked as seen
    }
    // clear timeout when component unmounts
    return () => {
      clearTimeout(timeout);
    };
  }, [visible, handleOnMarkAsSeenNotifications, skip, hasTimedOut]);

  useEffect(() => {
    if (!loading && opened) {
      setItems(prev => [...prev, ...notifications]);
    }
  }, [loading, notifications, opened]);

  const nextPage = useCallback(() => {
    if (opened) {
      loadMore(PAGE_SIZE, PAGE_SIZE * (page + 1));
      setPage(prev => prev + 1);
    }
  }, [page, loadMore, opened]);

  const hasMore =
    items.length < totalNotifications && items.length < MAX_ITEMS_SIZE;

  const supportedNotifications = getSupportedNotifications(items);

  const isEmpty = supportedNotifications.length === 0 && !hasMore;

  // Handles edge cases where initial pages contain unsupported notifications that are not rendered.
  // Ensures the next page of infinite scroll is called when there are more notifications to load.
  useEffect(() => {
    // Calculate the total number of supported notifications before updating the items array.
    const totalSupportedNotifications = getSupportedNotifications([
      ...items,
      ...notifications,
    ]).length;

    // If the dropdown is opened, not currently loading, and the total number of supported
    // notifications is less than the page size, and there are more notifications to load,
    // call the function to fetch the next page of notifications.
    if (
      opened &&
      !loading &&
      totalSupportedNotifications < PAGE_SIZE &&
      PAGE_SIZE * (page + 1) < totalNotifications
    ) {
      nextPage();
    }
  }, [
    opened,
    loading,
    items,
    notifications,
    supportedNotifications,
    totalNotifications,
    page,
    nextPage,
  ]);

  return (
    <NotificationPanel aria-label="Notification-dropdown">
      <PanelHeader />
      <Flex width="100%" py={2} flexDirection="column">
        {!loading && isEmpty && <EmptyState />}
        {!isEmpty && (
          <NotificationListContainer id="scroll-container">
            <InfiniteScroll
              dataLength={supportedNotifications.length}
              next={nextPage}
              hasMore={hasMore}
              loader={<LoadingState />}
              scrollableTarget="scroll-container"
              endMessage={null}>
              {supportedNotifications.map(n => (
                <NotificationItem key={n.id} notification={n} />
              ))}
            </InfiniteScroll>
          </NotificationListContainer>
        )}
        {/* Display loading state for the first page or when the number of supported notifications is less than the page size */}
        {loading &&
          (page === 0 || supportedNotifications.length < PAGE_SIZE) && (
            <LoadingState />
          )}
      </Flex>
    </NotificationPanel>
  );
};

export default NotificationDropdown;

import {
  Button,
  Chip,
  ColorsV2,
  Flex,
  Icon,
  Spacer,
  Text,
} from '@electricjs/arc';

import formatDate from '@/helpers/formatDate';
import { useGlobalUI } from '@/components/GlobalUIProvider';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import {
  useListEmployeeBreachesByOrganizationIdQuery,
  useMarkEmployeeBreachResolvedMutation,
} from '@/redux/slices/employeeBreachApiSlice';

import { EmailDetail } from '../EmailDetail';
import { NotificationIcon } from '../NotificationIcon';
import { NotificationItemHeader } from '../NotificationItemHeader';
import { NotificationItemProps } from '../types';
import { HaveIBeenPwnedAttribution } from '@/pages/DataBreaches/HaveIBeenPwnedAttribution';
import { StatusChip } from '../../../StatusChip';
import BreachDescriptionModal from '@/pages/DataBreaches/BreachDescriptionModal';
import { NotificationResponseBreach } from '@/types/notifications';
import { sortDataClasses } from '@/pages/DataBreaches/columns';
import { useFlags } from 'launchdarkly-react-client-sdk';

const mapEmployeeBreach = (result: NotificationResponseBreach) => ({
  id: result.breach_id,
  name: result.name,
  displayName: result.display_name,
  domainUrl: result.domain_url,
  logoUrl: result.logo_url,
  dataClasses: result.data_classes,
  breachedAt: result.breached_at,
  email: result.email,
  employeeId: result.employee_id,
});

export const BreachNotificationItem = ({
  notification,
  hideDetails,
}: NotificationItemProps) => {
  const data = mapEmployeeBreach(
    notification.data as unknown as NotificationResponseBreach
  );
  return (
    <NotificationItemHeader
      notification={notification}
      title={
        <Flex flexDirection="column">
          <Text variant="legal" style={{ fontWeight: '700' }}>
            {data.displayName
              ? `Data Breach: ${data.displayName}`
              : 'Data breach detected'}
          </Text>
          {!!data.breachedAt && (
            <Text variant="legal">
              Date: {formatDate(data.breachedAt, 'MM/dd/yyyy')}
            </Text>
          )}
          <HaveIBeenPwnedAttribution fontSize="0.8rem" />
        </Flex>
      }
      icon={
        <NotificationIcon
          isNotificationUnseen={!notification.seenAt}
          icon="alert-triangle"
        />
      }
      label="DATA BREACH">
      {!hideDetails ? (
        <Flex flexDirection="column">
          <Spacer space="1rem" />
          <BreachActionButton notification={notification} />
        </Flex>
      ) : null}
    </NotificationItemHeader>
  );
};

export const BreachDetailsColumn = ({
  notification,
}: NotificationItemProps) => {
  const data = mapEmployeeBreach(
    notification.data as unknown as NotificationResponseBreach
  );
  const sortedDataClasses = data.dataClasses?.toSorted(sortDataClasses);
  return (
    <Flex flexDirection="column">
      {<EmailDetail isLoading={false} email={data.email} />}
      {
        <Flex my="0.5rem" width="85%" wrap>
          {sortedDataClasses?.map(dc => (
            <Flex m="0.1rem" key={dc}>
              {dc.includes('Password') ? (
                <StatusChip
                  id={`${data.id}-${dc}-error-status-chip`}
                  text={dc}
                  variant="error"
                />
              ) : (
                <StatusChip
                  id={`${data.id}-${dc}-warning-status-chip`}
                  text={dc}
                  variant="warning"
                />
              )}
            </Flex>
          )) ?? null}
        </Flex>
      }
    </Flex>
  );
};

export const BreachActionButton = ({ notification }: NotificationItemProps) => {
  const mappedData = mapEmployeeBreach(
    notification.data as unknown as NotificationResponseBreach
  );
  const { itHubEmployeeDataBreachGigawatt } = useFlags();
  const { openModal, closeModal } = useGlobalUI();
  const organizationId = useGetOrganizationId();
  const [markResolved] = useMarkEmployeeBreachResolvedMutation();

  const { data: employeeBreachesData, isLoading: isLoadingEmployeeBreaches } =
    useListEmployeeBreachesByOrganizationIdQuery({ organizationId });

  const thisEmployeeBreach = employeeBreachesData?.find(
    eb =>
      eb.breach.id === mappedData.id && eb.employee.id === mappedData.employeeId
  );

  if (isLoadingEmployeeBreaches) {
    return null;
  }
  return (
    <Flex flexWrap="nowrap" vAlignContent="center">
      {!!mappedData.domainUrl && (
        <Button
          id="button-go-breach-description"
          py="0.5rem"
          mr="1rem"
          intent="primary"
          variant="text"
          onClick={() =>
            openModal(
              <BreachDescriptionModal
                breachName={mappedData?.displayName ?? 'Data breach detected'}
                breachDate={mappedData?.breachedAt ?? ''}
                breachDomain={mappedData?.domainUrl ?? ''}
                closeModal={closeModal}
                markResolved={() =>
                  markResolved({
                    organizationId,
                    employeeId: mappedData.employeeId,
                    breachId: mappedData.id,
                  })
                }
              />
            )
          }>
          {itHubEmployeeDataBreachGigawatt ? (
            <Icon icon="sparkles" customSize="2rem" />
          ) : (
            <Icon icon="information-circle" customSize="2rem" />
          )}
        </Button>
      )}
      <Flex width="150px" justifyContent="flex-end">
        {thisEmployeeBreach?.resolvedAt ? (
          <Chip
            id={`${mappedData.id}-resolved-chip`}
            variant="small"
            backgroundColor={ColorsV2.SUCCESS_LIGHTEST}
            color={ColorsV2.SUCCESS}>
            Resolved {formatDate(thisEmployeeBreach.resolvedAt)}
          </Chip>
        ) : (
          <Button
            id="mark-as-resolved"
            onClick={() => {
              markResolved({
                organizationId,
                employeeId: mappedData.employeeId,
                breachId: mappedData.id,
              });
            }}>
            <Text variant="label-small" intent="primary-invert">
              Mark Resolved
            </Text>
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

import { Card, EmptyState } from '@electricjs/arc';

type AssetImagesEmptyStateProps = {
  handleClick: () => void;
};

const AssetImagesEmptyState = ({ handleClick }: AssetImagesEmptyStateProps) => {
  return (
    <Card
      id="asset-images-empty-state-card"
      elevated
      width="100%"
      maxWidth="65rem"
      minWidth="25rem"
      my="8rem"
      onClick={handleClick}>
      <EmptyState
        title="A picture is worth a thousand words!"
        subTitle="Upload photographs of your asset to record condition and appearance."
        icon="add-circle"
      />
    </Card>
  );
};

export default AssetImagesEmptyState;

import styled from 'styled-components';
import {
  navIconStyles,
  navTextStyles,
  sharedNavItemRootStyles,
} from '../styles';
import { DisclosureContent } from 'reakit';
import {
  ColorsV2,
  getToken,
  Icon,
  IconNames,
  useCollapse,
} from '@electricjs/arc';

const StyledCollapsibleSubNav = styled.div`
  ${sharedNavItemRootStyles}

  .collapsible-subnav__section {
    cursor: pointer;
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-appearance: none;
    /* stylelint-enable property-no-vendor-prefix */
    outline: 0;
    display: flex;
    align-items: center;

    /* Collapsible nav icon */
    ${navIconStyles}

    /* Collapsible nav arrow indicator icon */
    div[data-testid='nav-collapsible-icon-arrow'] {
      margin-left: 2.6rem;
      margin-right: 0;
    }

    .collapsible-subnav__text {
      ${navTextStyles}
      flex-grow: 1;
      height: 4rem;
      line-height: 4rem;
      color: ${getToken(ColorsV2.TEXT)};

      &.expanded {
        color: ${getToken(ColorsV2.TEXT_LIGHT)};
      }
    }
  }

  .collapsible-subnav__items {
    & > div:first-child {
      transition:
        opacity 100ms ease-in-out,
        transform 100ms ease-in-out,
        height 100ms ease-in-out;
      opacity: 0;
      transform: translate3d(0, -5%, 0);
      height: 0;

      &[data-enter] {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        height: 100%;
      }
    }

    .nav-item {
      margin-top: 0;
      margin-bottom: 1rem;

      a:first-child {
        margin-top: 0.5rem;
      }

      span {
        font-size: 1.4rem;
        font-weight: 400;
      }
    }
  }
`;

type SubNavToggleProps = {
  icon?: IconNames;
  text?: string;
  toggle: () => void;
  isVisible: boolean;
};

const SubNavToggle = ({
  icon,
  text,
  toggle,
  isVisible,
}: SubNavToggleProps): React.ReactElement => (
  <div
    data-testid="subnav-toggle"
    className="collapsible-subnav__section"
    role="menu"
    onKeyDown={toggle}
    id="collapsed-toggle-nav"
    aria-controls="collapsed-panel-nav"
    tabIndex={0}
    onClick={toggle}>
    {icon && (
      <Icon
        icon={icon}
        color={isVisible ? ColorsV2.TEXT_LIGHT : ColorsV2.TEXT}
      />
    )}
    <span className={`collapsible-subnav__text${isVisible ? ' expanded' : ''}`}>
      {text}
    </span>
    <Icon
      icon={isVisible ? 'chevron-up' : 'chevron-down'}
      size="small"
      color={ColorsV2.TEXT}
      testId="nav-collapsible-icon-arrow"
    />
  </div>
);

type CollapsibleSubNavProps = {
  children: React.ReactNode;
  icon?: IconNames;
  text?: string;
  hasActiveSubNav?: boolean;
};

export const CollapsibleSubNav = ({
  children,
  hasActiveSubNav = false,
  ...otherProps
}: CollapsibleSubNavProps): React.ReactElement => {
  const disclosure = useCollapse({ visible: hasActiveSubNav });
  const { toggle, visible } = disclosure;
  // This avoids passing the `Collapsible` component to the DOM, which is unused and throws an error
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { Collapsible, ...disclosureProps } = disclosure;

  return (
    <StyledCollapsibleSubNav
      className="collapsible-subnav"
      data-testid={'collapsible-subnav'}>
      <SubNavToggle {...otherProps} toggle={toggle} isVisible={visible} />
      <div
        data-testid={'subnav-items'}
        className="collapsible-subnav__items"
        id="collapsed-panel-nav">
        <DisclosureContent {...disclosureProps}>
          {visible && children}
        </DisclosureContent>
      </div>
    </StyledCollapsibleSubNav>
  );
};

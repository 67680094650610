import styled from 'styled-components';

import {
  ColorsV2,
  Divider,
  Flex,
  Pane,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  TabsContainer,
  Text,
  getToken,
  useBreakpoint,
} from '@electricjs/arc';

import { EmployeeWithAssets } from '@/types/employees';

import { OsBlockTab } from './OSBlockTab';
import { Asset } from '@/types/assets';

const TabListStyled = styled(TabList)`
  border-bottom: 1px solid ${getToken(ColorsV2.GRAY_DARK)};
`;

type OSBlockProps = {
  deviceAssets: Asset[];
  employeesWithDeviceIssues: EmployeeWithAssets[];
};

type OsCount = {
  [key: string]: {
    [key: string]: number;
  };
};

type VersionCounts = {
  [key: string]: {
    [version: string]: number;
  };
};

export const OSBlock = ({
  deviceAssets,
  employeesWithDeviceIssues,
}: OSBlockProps) => {
  // For each OS type, count the number of devices with each OS name and version
  const { md } = useBreakpoint();

  const versionCounts = deviceAssets.reduce<VersionCounts>(
    (acc: OsCount, asset) => {
      const device = asset.device;
      const osType = asset.device?.osType;
      if (!device || !osType) {
        return acc;
      }
      const osName = asset.device?.reportingData?.osName;
      const osVersion = asset.device?.reportingData?.osVersion;
      // The key for the OS version count object is the OS name and version
      let osKey;
      if (!osType || (!osName && !osVersion)) {
        osKey = 'Unknown';
      } else {
        osKey = `${osName ?? ''} ${osVersion ?? ''}`;
      }

      if (!acc[osType]) {
        // Initialize the OS type key if not already present
        acc[osType] = {};
      }
      if (!acc[osType][osKey]) {
        // Initialize the OS version key if not already present
        acc[osType][osKey] = 0;
      }
      acc[osType][osKey]++; // Increment the count for the OS version
      return acc;
    },
    {}
  );

  return (
    <Pane>
      <Flex flexDirection="column" width="100%">
        <Flex width="100%">
          <Text variant="heading-3" pt="3rem" pb="0.75rem">
            Electric&apos;s OS update policy
          </Text>
        </Flex>
        <Flex flexDirection="row" justifyContent="space-between" width="100%">
          <Flex
            vAlignContent="center"
            alignContent="left"
            minWidth="50%"
            pr="4rem">
            <Text variant="body">
              Operating system upgrades and periodic patches introduce enhanced
              security and prevent against newly discovered vulnerabilities.
            </Text>
          </Flex>
          <Flex
            vAlignContent="center"
            alignContent="left"
            minWidth="50%"
            pr="4rem">
            <Flex vAlignContent="center">
              <Text variant="body">
                Electric&apos;s OS Update Policy enhances security by protecting
                against vulnerabilities, improves device performance, and
                ensures regulatory compliance.
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Divider space="2rem" />
        <Flex width="100%" vertical>
          <Text variant="heading-3" pb="1rem" width="30rem">
            OS distribution by fleet
          </Text>
          <Flex vertical width="100%">
            <TabsContainer selectedId="macos">
              <Flex mt={md ? '-5rem' : '0rem'} ml={md ? '25rem' : '0rem'}>
                <TabListStyled>
                  <Tab id="windows">Windows</Tab>
                  <Tab id="macos">macOS</Tab>
                </TabListStyled>
              </Flex>
              <Flex width="100%">
                <TabPanels width="100%">
                  <TabPanel>
                    <Pane flexDirection="column">
                      {versionCounts.windows ? (
                        <OsBlockTab
                          osVersions={versionCounts.windows}
                          allDeviceAssets={deviceAssets}
                          osType="windows"
                          employeesWithDeviceIssues={employeesWithDeviceIssues}
                        />
                      ) : (
                        <Flex justifyContent="center" width="100%" my="5rem">
                          <Text variant="body">No Windows devices found</Text>
                        </Flex>
                      )}
                    </Pane>
                  </TabPanel>
                  <TabPanel>
                    <Pane flexDirection="column">
                      {versionCounts.darwin ? (
                        <OsBlockTab
                          osVersions={versionCounts.darwin}
                          allDeviceAssets={deviceAssets}
                          osType="darwin"
                          employeesWithDeviceIssues={employeesWithDeviceIssues}
                        />
                      ) : (
                        <Flex justifyContent="center" width="100%" my="5rem">
                          <Text variant="body">No macOS devices found</Text>
                        </Flex>
                      )}
                    </Pane>
                  </TabPanel>
                </TabPanels>
              </Flex>
            </TabsContainer>
          </Flex>
        </Flex>
      </Flex>
    </Pane>
  );
};

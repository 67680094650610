import {
  getDiskUsage,
  getOSProperty,
} from 'src/components/People/EmployeeProfile/helpers/DevicePropertyHelpers';
import styled from 'styled-components';

import { Checkbox, Flex, Text } from '@electricjs/arc';

import { Employee, EmployeeWithAssets } from '@/types/employees';

const StyledSelectAll = styled(Flex)`
  display: grid;
  grid-template-columns: 3.5rem 1fr;
  align-items: center;
`;

const StyledFlex = styled(Flex)`
  display: grid;
  grid-template-columns: 3.5rem minmax(10px, 1fr) minmax(10px, 1fr) minmax(
      10px,
      1fr
    );
  align-items: top;
`;

type EmployeeCheckboxProps<GenericEmployee extends Employee> = {
  employee: GenericEmployee;
  onChange: (currEmployee: GenericEmployee, isSelected: boolean) => void;
  isSelected: boolean;
  showDevice?: boolean;
  showOS?: boolean;
  showDiskSpace?: boolean;
};
const EmployeeCheckbox = <GenericEmployee extends Employee>({
  employee,
  onChange,
  isSelected,
  showDevice = false,
  showOS = false,
  showDiskSpace = false,
}: EmployeeCheckboxProps<GenericEmployee>) => {
  const handleChange = () => {
    onChange(employee, isSelected);
  };

  let osList;
  let diskSpaceList;
  let devicesList;

  if ('assets' in employee) {
    const employeeWithAssets = employee as EmployeeWithAssets;
    devicesList = showDevice
      ? employeeWithAssets.assets &&
        employeeWithAssets.assets.map(asset => asset.model).join(', ')
      : employee.email;
    osList = showOS
      ? employeeWithAssets.assets &&
        employeeWithAssets.assets.map(asset => getOSProperty(asset)).join(', ')
      : '';
    diskSpaceList = showDiskSpace
      ? employeeWithAssets.assets &&
        employeeWithAssets.assets.map(asset => getDiskUsage(asset)).join(', ')
      : '';
  }

  return (
    <StyledFlex key={employee.id} mt={2}>
      <Checkbox
        id={`${employee.id}-employee-checkbox`}
        onChange={handleChange}
        checked={isSelected}
      />
      <Text mt={2}>
        {employee.firstName} {employee.lastName}
      </Text>
      {devicesList && <Text mt={2}>{devicesList}</Text>}
      {osList && <Text mt={2}>{osList}</Text>}
      {diskSpaceList && <Text mt={2}>{diskSpaceList}</Text>}
    </StyledFlex>
  );
};

const EmployeeCheckboxList = <GenericEmployee extends Employee>({
  employees,
  selectedEmployees,
  setSelectedEmployees,
  showDevice,
  showOS,
  showDiskSpace,
}: {
  employees: GenericEmployee[];
  selectedEmployees: GenericEmployee[];
  setSelectedEmployees: React.Dispatch<React.SetStateAction<GenericEmployee[]>>;
  showDevice?: boolean;
  showOS?: boolean;
  showDiskSpace?: boolean;
}) => {
  const allEmployeesSelected = employees.length === selectedEmployees.length;
  const selectedEmployeeMap: { [key: string]: boolean } =
    selectedEmployees.reduce((acc, currEmployee) => {
      return { ...acc, [currEmployee.id]: true };
    }, {});

  const handleChange = (currEmployee: GenericEmployee, isSelected: boolean) => {
    isSelected
      ? setSelectedEmployees(
          selectedEmployees.filter(employee => employee.id !== currEmployee.id)
        )
      : setSelectedEmployees([...selectedEmployees, currEmployee]);
  };
  const handleSelectAll = () => {
    allEmployeesSelected
      ? setSelectedEmployees([])
      : setSelectedEmployees(employees);
  };

  return (
    <>
      <StyledSelectAll mt={3}>
        <Checkbox
          id="select-all-employees-checkbox"
          checked={allEmployeesSelected}
          onClick={handleSelectAll}
        />
        <Text variant="label-large">
          Select {employees.length > 1 ? 'all' : ''} {employees.length} employee
          {employees.length > 1 ? 's' : ''}
        </Text>
      </StyledSelectAll>
      {employees.map(employee => (
        <EmployeeCheckbox
          key={employee.id}
          employee={employee}
          onChange={handleChange}
          isSelected={!!selectedEmployeeMap[employee.id]}
          showDevice={showDevice}
          showOS={showOS}
          showDiskSpace={showDiskSpace}
        />
      ))}
    </>
  );
};

export default EmployeeCheckboxList;

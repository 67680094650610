import { ColorsV2, Flex, Text, InitialsInCircle } from '@electricjs/arc';

export type EmployeeResultProps = {
  firstName: string;
  lastName: string;
  email: string;
};

export const EmployeeResult = ({
  firstName,
  lastName,
  email,
}: EmployeeResultProps) => {
  return (
    <Flex vAlignContent="center">
      <Flex>
        <InitialsInCircle
          fontSize="1.2rem"
          size="3rem"
          text={`${firstName} ${lastName}`}
        />
      </Flex>
      <Text ml="2rem" variant="label-small">
        {firstName} {lastName}
      </Text>
      <Text ml="2rem" variant="body-2" color={ColorsV2.GRAY_DARK}>
        {email}
      </Text>
    </Flex>
  );
};

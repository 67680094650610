import { useState, useEffect, useCallback } from 'react';

import { ProductTypeName, SortOrder } from '@electricjs/core_entity-client';
import { Flex } from '@electricjs/arc';

import { useGlobalUI } from '@/components/GlobalUIProvider';
import { STOREFRONT_PAGE_SIZE } from '@/constants/pagination';
import { SortingParams } from '@/types/common';
import { SelectionParamKeys } from '@/types/queryParamKeys';
import {
  useLazyGetPaginatedProductsQuery,
  useLazyGetProductQuery,
} from '@/redux/slices/productApiSlice';

import { useStorefront } from '../../StorefrontProvider';

import {
  type Product,
  type CatalogCartEntry,
  DynamicFilters,
  StoreFiltersSelections,
  productTypesByTypeGroup,
  Store,
} from '@/types/storefront';

import { useItemDetailsModal } from '../useItemDetailsModal';
import ProductMenuFilterGroup from '../ProductSpecsFilter/ProductMenuFilterGroup';
import {
  ItemList,
  ProductTypeGroupSelector,
  ProductTypeGroupSelectorProps,
} from '..';

export type CatalogStoreProps = Omit<
  ProductTypeGroupSelectorProps,
  'onProductTypesSelectFromGroup'
>;

export const CatalogStore = ({
  selectedProductTypeGroup,
  onProductTypeGroupSelect,
  laptopRecommendationUrl,
}: CatalogStoreProps) => {
  const { showSuccessToast } = useGlobalUI();

  const { catalogCart, setCatalogCart } = useStorefront();

  const [selectedProductTypes, setSelectedProductTypes] = useState<
    ProductTypeName[]
  >(productTypesByTypeGroup[selectedProductTypeGroup]); // initializing with the types from the selected group of types

  const [
    isAvailableDynamicFiltersLoading,
    setIsAvailableDynamicFiltersLoading,
  ] = useState(false);

  const [dynamicFiltersSelectedValues, setDynamicFiltersSelectedValues] =
    useState<DynamicFilters>();

  const [selectedSorting, setSelectedSorting] = useState<SortingParams>({
    orderBy: 'name',
    sortOrder: SortOrder.Asc,
  });

  const [selectedPage, setSelectedPage] = useState<number>(0);

  const [
    getPaginatedProducts,
    {
      data: productsResponse,
      isFetching: isProductListLoading,
      isError: hasProductListError,
    },
  ] = useLazyGetPaginatedProductsQuery();

  const productList = productsResponse?.paginatedResponse.results || [];

  const availableDynamicFilters = productsResponse?.filters
    ? Object.values(productsResponse?.filters)[0]
    : {};

  const applyFiltersSelections = useCallback(
    ({
      selectedProductTypes,
      dynamicFiltersSelectedValues,
      selectedSorting,
      selectedPage,
    }: StoreFiltersSelections) => {
      // fetch the products based on the selections
      getPaginatedProducts(
        {
          productTypes: selectedProductTypes,
          dynamicFilters: dynamicFiltersSelectedValues,
          orderBy: selectedSorting.orderBy,
          sortOrder: selectedSorting.sortOrder,
          offset: selectedPage * STOREFRONT_PAGE_SIZE,
          limit: STOREFRONT_PAGE_SIZE,
        },
        true
      )
        .unwrap()
        .catch(error => console.error('Error while fetching products', error))
        .finally(() => setIsAvailableDynamicFiltersLoading(false));
    },
    [getPaginatedProducts]
  );

  /**
   * When product types are selected from a group selector (using the big buttons in the top).
   * For example: Computers, Monitors, Keyboards and Mice, Docks, etc.
   * The "Keyboards and Mice" group is, in fact, 3 ProductTypes selected at once (keyboard, mouse, combo).
   *
   * This selection is handled separately, because it resets all other filters and impacts dynamic filters loading.
   */
  const handleTypesSelectionFromGroup = useCallback(
    (types: ProductTypeName[]) => {
      setSelectedProductTypes([...types]);

      // reset all other filters, sorting, pagination
      setDynamicFiltersSelectedValues(undefined);

      setSelectedSorting({
        orderBy: 'name',
        sortOrder: SortOrder.Asc,
      });

      setSelectedPage(0);

      // When product type group is changed, the available dynamic filters will change, so we need to set them as "loading".
      // The value will be cleared as soon as the "getPaginatedProducts" ends.
      setIsAvailableDynamicFiltersLoading(true);
    },
    []
  );

  /**
   * When a user selects product types from a dynamic filter (not from groups of types).
   * In this case, the product types are changed without resetting the other filters or sorting.
   * Example: In the case of keyboard/mouse/combo types (when inside of "Keyboards and Mice" group).
   */
  const handleProductTypeInnerChange = (productType: ProductTypeName[]) => {
    setSelectedProductTypes(productType);

    // only reset pagination (keep other filters and sorting)
    setSelectedPage(0);
  };

  const handleDynamicFiltersSelection = (filters: DynamicFilters) => {
    setDynamicFiltersSelectedValues(filters);

    // only reset pagination
    setSelectedPage(0);
  };

  const handleSortingSelection = (sorting: SortingParams) => {
    setSelectedSorting({
      orderBy: sorting.orderBy,
      sortOrder: sorting.sortOrder,
    });

    // only reset pagination
    setSelectedPage(0);
  };

  const handlePageChange = (newPage: number) => {
    // only update the page (keep all filters and sorting)
    setSelectedPage(newPage);
  };

  // Applying filters/sorting/pagination selections
  useEffect(() => {
    applyFiltersSelections({
      selectedProductTypes,
      dynamicFiltersSelectedValues,
      selectedSorting,
      selectedPage,
    });
  }, [
    applyFiltersSelections,
    selectedProductTypes,
    dynamicFiltersSelectedValues,
    selectedSorting,
    selectedPage,
  ]);

  const handleAddEntry = useCallback(
    (product: Product) => {
      // add the entry to the end of the cart
      const cart: CatalogCartEntry[] = [
        ...catalogCart,
        {
          item: { ...product },
          quantity: 1,
          selectedWarranty:
            'defaultWarranty' in product ? product.defaultWarranty : undefined, // only laptops have warranty
        },
      ];

      setCatalogCart(cart);

      showSuccessToast({
        id: 'added-catalog-product-toast',
        title: 'Item added',
        message: `${product.name} has been added to your cart`,
        testId: 'added-catalog-product-toast',
      });
    },
    [catalogCart, setCatalogCart, showSuccessToast]
  );

  const handleRemoveEntry = useCallback(
    (product: Product) => {
      // remove the entry from the cart, independent of its amount
      const cart: CatalogCartEntry[] = catalogCart.filter(
        entry => entry.item.id !== product.id
      );

      setCatalogCart(cart);

      showSuccessToast({
        id: 'removed-catalog-product-toast',
        title: 'Item removed',
        message: `${product.name} has been removed from your cart`,
        testId: 'removed-catalog-product-toast',
      });
    },
    [catalogCart, setCatalogCart, showSuccessToast]
  );

  const [
    getProduct,
    { isFetching: isLoadingProductDetails, isError: hasProductDetailsError },
  ] = useLazyGetProductQuery();

  const getProductDetails = useCallback(
    async (itemId: string) => {
      const product = await getProduct(itemId, true).unwrap();
      return product;
    },
    [getProduct]
  );

  useItemDetailsModal({
    items: productList,
    queryParam: SelectionParamKeys.ProductId,
    store: Store.Catalog,
    loadingItemsList: isProductListLoading,
    loadingItemDetails: isLoadingProductDetails,
    onAddItem: handleAddEntry,
    onRemoveItem: handleRemoveEntry,
    hasItemDetailsError: hasProductDetailsError,
    itemDetailsErrorMessage:
      'Could not get product details. Please try again later',
    onItemDetailsAsyncRequest: getProductDetails,
  });

  return (
    <Flex vertical rowGap="2.4rem" data-testid="catalog-store">
      <ProductTypeGroupSelector
        selectedProductTypeGroup={selectedProductTypeGroup}
        onProductTypeGroupSelect={onProductTypeGroupSelect}
        onProductTypesSelectFromGroup={handleTypesSelectionFromGroup}
        laptopRecommendationUrl={laptopRecommendationUrl}
      />
      {!hasProductListError && (
        <ProductMenuFilterGroup
          isAvailableDynamicFiltersLoading={isAvailableDynamicFiltersLoading}
          availableDynamicFilters={availableDynamicFilters}
          dynamicFiltersSelectedValues={dynamicFiltersSelectedValues}
          onDynamicFilterSelect={handleDynamicFiltersSelection}
          selectedSorting={selectedSorting}
          onSortingSelect={handleSortingSelection}
          selectedProductTypeGroup={selectedProductTypeGroup}
          onProductTypesSelect={handleProductTypeInnerChange}
        />
      )}
      <ItemList
        store={Store.Catalog}
        items={productList}
        onAddItem={handleAddEntry}
        onRemoveItem={handleRemoveEntry}
        totalItemsResult={productsResponse?.paginatedResponse.total ?? 0}
        selectedPage={selectedPage}
        onPageChange={handlePageChange}
        isLoading={isProductListLoading}
        hasError={hasProductListError}
        errorMessage="Could not get the list of products. Please try again later."
        selectedItemsIds={catalogCart.map(entry => entry.item.id)}
      />
    </Flex>
  );
};

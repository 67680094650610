import { Asset } from '@/types/assets';
import * as yup from 'yup';

export const assetSchema = yup.object<Asset>().shape({
  name: yup.string().required('The name is required'),
  productType: yup.object().shape({
    value: yup.string().required('The asset type is required'),
  }),
  assignedTo: yup.object().nullable().shape({
    value: yup.string().nullable(),
  }),
  site: yup.object().nullable().shape({
    value: yup.string().nullable(),
  }),
  purchasePrice: yup
    .string()
    .nullable()
    .test('isValidPrice', 'Invalid price format', value => {
      if (!value) return true;
      // Remove all commas, dollar signs and leading/trailing white spaces from the input
      const sanitizedValue = value.replace(/[,|$]/g, '').trim();
      const regex = /^\d+(\.\d{2})?$/;
      return regex.test(sanitizedValue);
    }),
  purchasedFrom: yup
    .string()
    .nullable()
    .test('isValidUrl', 'Invalid URL format', value => {
      if (!value) return true;
      try {
        new URL(value);
        return true;
      } catch (error) {
        return false;
      }
    }),
  condition: yup.object().nullable().shape({
    value: yup.string().nullable(),
  }),
  status: yup.object().nullable().shape({
    value: yup.string().nullable(),
  }),
});

// Adapted from https://gist.github.com/Shrugsy/6b6af02aef1f783df9d636526c1e05fa
import { TagTypes } from '../apiSlice';

/**
 * An individual cache item
 */
export type CacheItem<T, ID> = { type: T; id: ID };

/**
 * A list of cache items, including a LIST entity cache
 */
export type CacheList<T, ID> = (CacheItem<T, 'LIST'> | CacheItem<T, ID>)[];

/**
 * Inner function returned by `providesList` to be passed to the `provides` property of a query
 */
type InnerProvidesList<T> = <Results extends { id: unknown }[]>(
  results: Results | undefined
) => CacheList<T, Results[number]['id']>;

/**
 * HOF to create an entity cache to provide a LIST,
 * depending on the results being in a common format.
 *
 * Will not provide individual items without a result.
 *
 * @example
 * ```ts
 * const results = [
 *   { id: 1, message: 'foo' },
 *   { id: 2, message: 'bar' }
 * ]
 * providesList('Todo')(results)
 * // [
 * //   { type: 'Todo', id: 'List'},
 * //   { type: 'Todo', id: 1 },
 * //   { type: 'Todo', id: 2 },
 * // ]
 * ```
 */
export const providesListAndAllById =
  <T extends string>(type: T): InnerProvidesList<T> =>
  results => {
    // is result available?
    if (results) {
      // successful query
      return [
        { type, id: 'LIST' },
        ...results.map(({ id }) => ({ type, id }) as const),
      ];
    }
    return [];
  };

// Provides a cache tag of this form:
//
// {
//   <type>,
//   <entity_id>
// }

export const providesById = <ID extends string>(
  type: TagTypes,
  id: ID
): CacheList<TagTypes, ID> => {
  return [
    {
      type,
      id,
    },
  ];
};

/**
 * HOF to create an entity cache to invalidate a LIST.
 *
 * Invalidates regardless of result.
 *
 * @example
 * ```ts
 * invalidatesList('Todo')()
 * // [{ type: 'Todo', id: 'List' }]
 * ```
 */
export const invalidatesList = (
  type: TagTypes
): [CacheItem<TagTypes, 'LIST'>] => [{ type, id: 'LIST' }];

// Invalidates cache tags of this form:
//
// [
//   {
//     <type>,
//     <entity_id>
//   },
//   {
//     <type>,
//     'LIST'
//   }
// ]

export const invalidatesListAndById = <ID extends string>(
  type: TagTypes,
  id: ID
): CacheList<TagTypes, string> => {
  return [
    {
      type,
      id,
    },
    {
      type,
      id: 'LIST',
    },
  ];
};

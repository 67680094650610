export const firewallLinks = {
  mac: 'https://support.apple.com/guide/mac-help/block-connections-to-your-mac-with-a-firewall-mh34041/13.0/mac/13.0',
  windows:
    'https://support.microsoft.com/en-us/windows/turn-microsoft-defender-firewall-on-or-off-ec0844f7-aebd-0583-67fe-601ecf5d774f',
};

export const encryptionLinks = {
  mac: 'https://support.apple.com/guide/mac-help/encrypt-mac-data-with-filevault-mh11785/mac',
  windows:
    'https://support.microsoft.com/en-us/windows/device-encryption-in-windows-ad5dcf4b-dbe0-2331-228f-7925c2a3012d#:~:text=To%20turn%20on%20Windows%20device%20encryption&text=Select%20the%20Start%20button%2C%20then,turned%20off%2C%20select%20Turn%20on.',
};

export const gatekeeperLinks = {
  mac: 'https://support.apple.com/en-us/HT202491',
};

export const osLinks = {
  mac: 'https://support.apple.com/en-us/HT201541',
  windows:
    'https://support.microsoft.com/en-us/windows/update-windows-3c5ae7fc-9fb6-9af1-1984-b5e0412c556a',
};

export const storageLinks = {
  mac: 'https://support.apple.com/en-us/HT206996',
  windows:
    'https://support.microsoft.com/en-us/windows/disk-cleanup-in-windows-8a96ff42-5751-39ad-23d6-434b4d5b9a68',
};

import { useEffect } from 'react';

import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useLazyGetOrganizationSitesQuery } from '@/redux/slices/assetApiSlice';

export const useOrganizationSites = () => {
  const organizationId = useGetOrganizationId();

  const [getOrganizationSites, { data: sites, isFetching, error }] =
    useLazyGetOrganizationSitesQuery();

  useEffect(() => {
    if (organizationId) {
      getOrganizationSites({ organizationId: organizationId });
    }
  }, [organizationId, getOrganizationSites]);

  return {
    sites: sites ?? [],
    isFetching,
    error,
  };
};

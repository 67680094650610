import { Flex, Icon, IconButton, IconNames, Text } from '@electricjs/arc';
import { TextVariant } from '@electricjs/arc/components/Text';
import { SortOrder } from '@electricjs/core_entity-client';

type TableHeaderProps = {
  title: string | React.JSX.Element;
  sortFn?: () => void;
  sortOrder?: SortOrder;
  variant?: TextVariant;
};

const TableHeader = ({ title, sortOrder, sortFn }: TableHeaderProps) => {
  const icon: IconNames | null = sortOrder
    ? sortOrder === SortOrder.Asc
      ? 'arrow-up'
      : 'arrow-down'
    : null;

  return (
    <Flex vAlignContent="center" minHeight="3.4rem">
      <Text variant="body-2">{title}</Text>
      {icon && sortFn ? (
        <IconButton
          id="sort-btn"
          ml="1"
          size="xsmall"
          icon={icon}
          intent="neutral"
          onClick={sortFn}
        />
      ) : (
        icon && <Icon ml="1" size="xsmall" icon={icon} intent="neutral" />
      )}
    </Flex>
  );
};

export default TableHeader;

import {
  Flex,
  ModalBody,
  ModalHeader,
  ModalV2,
  Spinner,
  Text,
} from '@electricjs/arc';

const OrderSubmitModal = () => {
  return (
    <ModalV2
      ariaLabelledby="submit-order-modal"
      borderRadius="1.6rem"
      maxWidth="50rem"
      visible
      hide={() => {}}>
      <ModalHeader showCloseButton={false}>
        <Text id="submit-order-modal" variant="heading-2">
          Order processing
        </Text>
      </ModalHeader>
      <ModalBody p="2.4rem">
        <Text mt="2.5rem" intent="primary" variant="label-large">
          Do not leave or refresh this page.
        </Text>
        <Flex width="100%" justifyContent="center" py="5rem">
          <Spinner />
        </Flex>
      </ModalBody>
    </ModalV2>
  );
};

export default OrderSubmitModal;

import {
  AddEmployeesToGroupRequest as AddEmployeesToGroupRequestDto,
  Application as ApplicationDto,
  Employee as EmployeeDto,
  Group as GroupDto,
} from '@electricjs/core_entity-client';

import { TagTypes, apiSlice } from '@/redux/slices/apiSlice';
import { Employee } from '@/types/employees';
import {
  AddEmployeesToGroupArgs,
  GetGroupApplicationsArgs,
  Group,
  GroupApplicationManagementArgs,
  RemoveEmployeeFromGroupArgs,
  RenameGroupArgs,
} from '@/types/groups';

import { transformEmployee } from '@/redux/slices/employeeApiSlice';
import {
  invalidatesListAndById,
  providesListAndAllById,
} from './helpers/cacheTagHelpers';
import { transformApplication } from './applicationApiSlice';
import { Application } from '@/types/applications';

export const groupApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    removeEmployeeFromGroup: builder.mutation<
      void,
      RemoveEmployeeFromGroupArgs
    >({
      query: ({ organizationId, groupId, employeeId }) => {
        return {
          url: `/v1/organizations/${organizationId}/groups/${groupId}/employees/${employeeId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (_result, _error, args) =>
        invalidatesListAndById(TagTypes.Group, args.groupId),
    }),
    addEmployeesToGroup: builder.mutation<Employee[], AddEmployeesToGroupArgs>({
      query: ({ organizationId, groupId, employeeIds }) => {
        const body: AddEmployeesToGroupRequestDto = {
          employee_ids: employeeIds,
        };

        return {
          url: `/v1/organizations/${organizationId}/groups/${groupId}/employees`,
          method: 'POST',
          body,
        };
      },
      transformResponse: (response: EmployeeDto[]) => {
        return response?.map(employee => {
          return transformEmployee(employee);
        });
      },
      invalidatesTags: (_result, _error, args) =>
        invalidatesListAndById(TagTypes.Group, args.groupId),
    }),
    addApplicationsToGroup: builder.mutation<
      void,
      GroupApplicationManagementArgs
    >({
      query: ({ organizationId, groupId, applicationKey }) => {
        return {
          url: `/v1/organizations/${organizationId}/groups/${groupId}/applications/${applicationKey}`,
          method: 'POST',
        };
      },
      invalidatesTags: [TagTypes.Application, TagTypes.Group],
    }),
    getGroupApplications: builder.query<
      Application[],
      GetGroupApplicationsArgs
    >({
      query: ({ organizationId, groupId }) => {
        return {
          url: `/v1/organizations/${organizationId}/groups/${groupId}/applications`,
        };
      },
      providesTags: providesListAndAllById(TagTypes.Application),
      transformResponse: (response: Application[]) => {
        return response;
      },
    }),
    getGroupAncestorsApplications: builder.query<
      Application[],
      GetGroupApplicationsArgs
    >({
      query: ({ organizationId, groupId }) => {
        return {
          url: `/v1/organizations/${organizationId}/groups/${groupId}/ancestors_applications`,
        };
      },
      providesTags: providesListAndAllById(TagTypes.Application),
      transformResponse: (applications: ApplicationDto[]) => {
        return applications.map(application =>
          transformApplication(application)
        );
      },
    }),
    removeApplicationFromGroup: builder.mutation<
      void,
      GroupApplicationManagementArgs
    >({
      query: ({ organizationId, groupId, applicationKey }) => {
        return {
          url: `/v1/organizations/${organizationId}/groups/${groupId}/applications/${applicationKey}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: [TagTypes.Application, TagTypes.Group],
    }),
    renameGroup: builder.mutation<void, RenameGroupArgs>({
      query: ({ organizationId, groupId, newName }) => {
        return {
          url: `/v1/organizations/${organizationId}/groups/${groupId}`,
          method: 'PATCH',
          body: { name: newName },
        };
      },
      invalidatesTags: (_result, _error, args) =>
        invalidatesListAndById(TagTypes.Group, args.groupId),
    }),
  }),
});
export const transformGroup = (group: GroupDto): Group => {
  return {
    id: group.id,
    name: group.name,
    employees: group.employees.map((e: EmployeeDto) => transformEmployee(e)),
    createdAt: group.created_at,
    updatedAt: group.updated_at,
    parentGroupId: group.parent_group_id ?? '',
    hrisGroupIntegration: group.hris_group_integration
      ? {
          sourceId: group.hris_group_integration.source_id,
          hrisIntegrationId: group.hris_group_integration.hris_integration_id,
        }
      : undefined,
    applicationsCount: group.applications_count,
  };
};
export const {
  useRemoveEmployeeFromGroupMutation,
  useRenameGroupMutation,
  useAddEmployeesToGroupMutation,
  useAddApplicationsToGroupMutation,
  useGetGroupApplicationsQuery,
  useGetGroupAncestorsApplicationsQuery,
  useLazyGetGroupApplicationsQuery,
  useLazyGetGroupAncestorsApplicationsQuery,
  useRemoveApplicationFromGroupMutation,
} = groupApiSlice;

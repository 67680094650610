import { getDeviceStatus } from '@/components/People/EmployeeProfile/helpers/DevicePropertyHelpers';
import { MDMStatus } from '@/constants/mdmStatus';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useOrganizationHasEntitlement } from '@/hooks/useOrganizationHasEntitlement';
import {
  useGetAssetHistoryQuery,
  useGetAssetQuery,
} from '@/redux/slices/assetApiSlice';
import { isDeviceAsset } from '@/types/assets';
import { EntitlementSlugEnum } from '@electricjs/core_entity-client';

type UseShowDeviceActionsProps = {
  assetId: string;
};

const useShowDeviceActions = ({ assetId }: UseShowDeviceActionsProps) => {
  const organizationId = useGetOrganizationId();
  const { data: deviceAsset } = useGetAssetQuery(
    {
      organizationId,
      assetId,
    },
    { skip: !assetId }
  );

  const { data: assetHistoryData } = useGetAssetHistoryQuery(
    {
      organizationId,
      assetId,
      limit: 10,
    },
    { skip: !assetId }
  );

  const mdmStatus =
    assetHistoryData && deviceAsset && isDeviceAsset(deviceAsset)
      ? getDeviceStatus({
          assetHistoryData: assetHistoryData.assetHistory,
          deviceAsset: deviceAsset,
        })
      : null;

  const hasMdmEntitlement = useOrganizationHasEntitlement(
    EntitlementSlugEnum.JumpcloudMdm
  );

  const showDeviceActions =
    !!deviceAsset?.device?.osType &&
    (mdmStatus === MDMStatus.INSTALLED ||
      mdmStatus === MDMStatus.LOCKED ||
      mdmStatus === MDMStatus.REQUIRES_DESKTOP_APP) &&
    hasMdmEntitlement;

  return showDeviceActions;
};

export default useShowDeviceActions;

import { differenceInMonths } from 'date-fns';

/** Receives an `MM/YYYY` format date and check if it's one or more months past */
export const isPaymentMethodExpired = (expirationDate: string) => {
  const [expirationMonth, expirationYear] = expirationDate
    .split('/')
    .map(Number);

  const monthsExpired =
    expirationMonth && expirationYear
      ? differenceInMonths(
          new Date(),
          new Date(expirationYear, expirationMonth - 1) // needs to subtract 1 in the "month", because the "new Date()" uses a zero-based month system (0 to 11)
        )
      : 0;

  const isExpired = monthsExpired > 0;

  return isExpired;
};

import { Chip, ColorsV2, Flex, Text, useThemeColors } from '@electricjs/arc';

type StatusChipProps = {
  isConnected: boolean;
  customLabel?: string;
};

export const StatusChip = ({ isConnected, customLabel }: StatusChipProps) => {
  const [connectedColor, inactiveColor] = useThemeColors([
    ColorsV2.SUCCESS_LIGHTEST,
    ColorsV2.GRAY_LIGHTER,
  ]);

  const statusLabel = customLabel || (isConnected ? 'Connected' : 'Inactive');

  return (
    <Chip
      id="hris-connection-status-chip"
      variant="small"
      backgroundColor={isConnected ? connectedColor : inactiveColor}>
      <Flex vAlignContent="center" gap="1rem">
        <Text variant="legal" intent={isConnected ? 'success' : 'primary'}>
          {statusLabel}
        </Text>
      </Flex>
    </Chip>
  );
};

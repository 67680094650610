import {
  Box,
  Card,
  ColorsV2,
  Flex,
  Icon,
  IconInCircle,
  KebabMenuOption,
  Text,
  Tooltip,
  useThemeColors,
} from '@electricjs/arc';

import MacBook from '@/assets/macbook.png';
import WindowsNotebook from '@/assets/windows.png';
import EmployeeDeviceStatus from '@/components/People/EmployeeDeviceStatus';
import { MACOS } from '@/components/People/EmployeeProfile/helpers/DevicePropertyHelpers';
import { Issue } from '@/types/devices';
import { Asset } from '@/types/assets';

const AssetPreview = ({
  asset,
  justifyContent = 'center',
}: {
  asset: Asset;
  justifyContent?: 'center' | 'flex-start' | 'flex-end';
}) => {
  return (
    <Flex alignItems="center" justifyContent={justifyContent}>
      <img
        width="40rem"
        src={asset.device?.osType === MACOS ? MacBook : WindowsNotebook}
        alt="Laptop computer"
      />
      <Text
        variant="body-2"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis">
        {asset.device?.hardwareModel}
      </Text>
    </Flex>
  );
};

type EmployeeCardProps = {
  firstName: string;
  lastName: string;
  jobTitle: string;
  assets: Asset[];
  deviceIssues?: Issue;
  onClick?: () => void;
  kebabMenuItems?: KebabMenuOption[];
};

const EmployeeCard = ({
  firstName,
  lastName,
  jobTitle,
  assets,
  deviceIssues,
  onClick,
  kebabMenuItems,
}: EmployeeCardProps) => {
  const deviceIssueAsKeys =
    deviceIssues !== undefined
      ? Object.keys(JSON.parse(JSON.stringify(deviceIssues)))
      : [];

  const assetsWithCurrentIssue = assets.filter(asset => {
    return (
      asset.device?.reportingData?.issues !== undefined &&
      deviceIssueAsKeys.length > 0 &&
      deviceIssueAsKeys[0] in
        JSON.parse(JSON.stringify(asset.device.reportingData.issues))
    );
  });
  const hasMultipleAssetsWithSelectedIssue = assetsWithCurrentIssue.length > 1;

  const [iconColor, iconBackground] = useThemeColors([
    ColorsV2.GRAY_DARK,
    ColorsV2.GRAY_LIGHTER,
  ]);

  const fullName = `${firstName} ${lastName}`;

  // Apply ellipsis when text would be longer than 2 lines
  // Based on characters length estimation (it's not a hard requirement, so it's simple solution that fits our time)
  const fullNameDisplayValue =
    fullName.length > 28 ? fullName.slice(0, 25).concat('...') : fullName;

  const jobTitleDisplayValue =
    jobTitle.length > 35 ? jobTitle.slice(0, 32).concat('...') : jobTitle;

  const multipleAssets = () => {
    if (assetsWithCurrentIssue.length === 2) {
      return (
        <Flex width="100%" justifyContent="center">
          <Flex
            flexDirection="column"
            alignItems="stretch"
            justifyContent="center"
            gap="1rem">
            {assetsWithCurrentIssue.map(asset => (
              <AssetPreview
                asset={asset}
                key={asset.id ?? ''}
                justifyContent="flex-start"
              />
            ))}
          </Flex>
        </Flex>
      );
    }

    // when there are more than 2 devices with the selected issue
    // display the number of devices with the issue
    return (
      <Flex width="100%" alignItems="center" justifyContent="center">
        <Text
          variant="body-2"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis">
          {assets.length} devices
        </Text>
        <Tooltip
          content={
            <Flex flexDirection="column" justifyContent="space-between">
              {assetsWithCurrentIssue.map(asset => {
                return (
                  <Text
                    key={asset.id}
                    variant="body-2"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis">
                    {asset.device?.hardwareModel}
                  </Text>
                );
              })}
            </Flex>
          }>
          <Icon
            pl="1"
            icon="information-circle"
            size="small"
            intent="neutral"
          />
        </Tooltip>
      </Flex>
    );
  };

  return (
    <Card
      id="employee-card"
      justifySelf="center"
      width="100%" // fills its parent space (usually a grid column)
      height="22rem" // fixed height
      cardPadding="1.6rem"
      titleBodyDistance="0"
      onClick={onClick}
      kebabMenuItems={kebabMenuItems}
      title={
        /* TODO: once employees have an avatar property, check to see if employee has one */
        <Flex columnGap="1.2rem">
          <IconInCircle
            flexShrink="0"
            size="4rem"
            background={iconBackground}
            color={iconColor}
            icon="user"
            iconSize="medium"
          />
          <Box>
            <Text
              variant="label-large"
              title={
                fullNameDisplayValue.includes('...') ? fullName : undefined
              }>
              {fullNameDisplayValue}
            </Text>
            <Text
              variant="body-2"
              title={
                jobTitleDisplayValue.includes('...') ? jobTitle : undefined
              }>
              {jobTitleDisplayValue}
            </Text>
          </Box>
        </Flex>
      }>
      <Flex
        mt="1rem"
        justifyContent="space-evenly"
        height="100%"
        flexDirection="column">
        {hasMultipleAssetsWithSelectedIssue ? (
          multipleAssets()
        ) : (
          <Flex width="100%" justifyContent="center">
            <AssetPreview
              asset={assetsWithCurrentIssue[0]}
              justifyContent="center"
            />
          </Flex>
        )}
        {/* Card body */}
        <Flex
          width="100%"
          height="50%"
          alignItems="center"
          justifyContent="center">
          {assets.length > 0 ? (
            // Display the device issues
            <EmployeeDeviceStatus issues={deviceIssues} />
          ) : (
            // Display a warning about not having device data
            <Flex gap="0.8rem" alignItems="flex-start">
              <Icon icon="alert-circle" intent="error" size="medium" />
              <Text variant="label-small">
                Requires Electric application for device information
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Card>
  );
};

export default EmployeeCard;

import { Flex } from '@electricjs/arc';

import { Request, RequestType } from '@/types/requests';
import { PrimaryAction } from './PrimaryAction';
import { SecondaryAction } from './SecondaryAction';

type NotificationActionsProps = {
  request?: Request;
  requestType?: RequestType;
  loading?: boolean;
  isDropDownMenu?: boolean;
};

export const RequestActions = ({
  request,
  requestType,
  loading = false,
  isDropDownMenu = true,
}: NotificationActionsProps) => {
  if (!loading && !request) {
    return null;
  }

  return (
    <Flex
      gap="0.8rem"
      my="0.5rem"
      flexDirection={isDropDownMenu ? 'row' : 'row-reverse'}>
      <PrimaryAction
        status={request?.status}
        requestType={requestType}
        requestId={request?.id}
        loading={loading}
        isDropDownMenu={isDropDownMenu}
        onBehalfOf={request?.onBehalfOf}
      />
      <SecondaryAction
        requestId={request?.id}
        isDropDownMenu={isDropDownMenu}
        loading={loading}
      />
    </Flex>
  );
};

import convertEmployeesToEmployeeWithAssets from '@/helpers/convertEmployeesToEmployeeWithAssets';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useListAssetsV2Query } from '@/redux/slices/assetApiSlice';
import { useGetOrganizationEmployeesPaginatedQuery } from '@/redux/slices/organizationApiSlice';
import {
  Asset,
  AssetListArgs,
  DeviceAsset,
  isDeviceAsset,
} from '@/types/assets';
import { Employee, EmployeeWithAssets } from '@/types/employees';
import { GetOrganizationEmployeesArgs } from '@/types/organizations';
import {
  AssetStatus,
  ProductTypeName,
  SortOrder,
} from '@electricjs/core_entity-client';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

export const findEmployeeWithAssets = (
  employees?: EmployeeWithAssets[],
  employeeId?: string
): EmployeeWithAssets | undefined => {
  return employees && employeeId
    ? employees.find(employee => employee.id === employeeId)
    : undefined;
};

export const filterOutNonDeviceAssets = (assets: Asset[]): DeviceAsset[] => {
  return assets.filter(asset => !!isDeviceAsset(asset));
};

export const ASSET_UNAVAILABLE_STATUSES: Set<AssetStatus> = new Set([
  AssetStatus.Archived,
  AssetStatus.Donated,
  AssetStatus.Sold,
  AssetStatus.Disposed,
  AssetStatus.Missing,
]);

const filterOutUnavailableAssets = (assets: Asset[]): Asset[] => {
  return assets.filter(asset => !ASSET_UNAVAILABLE_STATUSES.has(asset.status));
};

export type employeesWithAssetsResponse = {
  isFetching: boolean;
  isLoading: boolean;
  employeesWithAssets?: EmployeeWithAssets[];
  employees?: Employee[];
  total?: number;
  assets?: Asset[];
  error?: SerializedError | FetchBaseQueryError;
};

export const useGetEmployeesWithAssets = ({
  devicesOnly = false,
  availableAssetsOnly = false,
  listAssetsQueryProps = {},
  listEmployeesQueryProps = {},
}: {
  devicesOnly?: boolean;
  availableAssetsOnly?: boolean;
  listAssetsQueryProps?: Partial<Omit<AssetListArgs, 'organizationId'>>;
  listEmployeesQueryProps?: Partial<
    Omit<GetOrganizationEmployeesArgs, 'organizationId'>
  >;
}): employeesWithAssetsResponse => {
  const organizationId = useGetOrganizationId();

  const skip = !organizationId;

  const {
    data: employeesData,
    isFetching: isFetchingEmployees,
    isLoading: isLoadingEmployees,
    error: employeesError,
  } = useGetOrganizationEmployeesPaginatedQuery(
    {
      organizationId,
      ...listEmployeesQueryProps,
    },
    {
      skip,
    }
  );

  const DEVICE_PRODUCT_TYPES = [
    ProductTypeName.Laptop,
    ProductTypeName.Desktop,
  ];

  const {
    data: assetsData,
    isFetching: isFetchingAssets,
    isLoading: isLoadingAssets,
    error: assetsError,
  } = useListAssetsV2Query(
    {
      organizationId,
      offset: 0,
      limit: 2_000,
      orderBy: 'name',
      sortOrder: SortOrder.Asc,
      ...(devicesOnly ? { productTypes: DEVICE_PRODUCT_TYPES } : {}),
      ...listAssetsQueryProps,
    },
    { skip }
  );

  const assetsByStatus = availableAssetsOnly
    ? filterOutUnavailableAssets(assetsData?.results || [])
    : assetsData?.results;

  const isFetching = isFetchingEmployees || isFetchingAssets;
  const isLoading = isLoadingAssets || isLoadingEmployees;
  const error = employeesError || assetsError;

  let employeesWithAssets = undefined;

  if (!isFetching && !error && assetsByStatus && employeesData?.results) {
    employeesWithAssets = convertEmployeesToEmployeeWithAssets({
      employees: employeesData.results,
      assets: assetsByStatus,
    });
  }

  return {
    employeesWithAssets,
    employees: employeesData?.results,
    total: employeesData?.total,
    assets: assetsByStatus,
    isFetching,
    isLoading,
    error,
  };
};

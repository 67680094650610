import {
  type GroupTypeBase,
  type ValueType,
  type ActionMeta,
} from 'react-select';

import { ColorsV2, SearchAsyncSelect, Text } from '@electricjs/arc';

import { searchResultCustomComponents } from './components/SearchResultCustomComponents';
import { type SmartSearchSelectOption } from './resultKindConfig';

import { search } from './utils/search';
import styled from 'styled-components';

const StyledSearchAsyncSelect = styled(SearchAsyncSelect)`
  [class$='-control'] {
    z-index: 2;
    height: 5rem;
    /* Pulls the menu in close to the search bar */
    margin-bottom: -0.3rem;
  }
  [class$='-menu'] {
    /* Makes the menu appear to sit under the search */
    z-index: 1;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: calc(100% - 4px);
    margin-left: 2px;
  }
`;

const formatGroupLabel = (
  optionGroup: GroupTypeBase<SmartSearchSelectOption>
) => {
  return (
    <Text variant="legal" color={ColorsV2.GRAY_DARK}>
      {optionGroup.label}
    </Text>
  );
};

type SmartSearchProps = {
  searchableData: SmartSearchSelectOption[];
  isInsideModal?: boolean;
};

// Handles navigating to the URL for the focused option when the user presses enter or clicks on an option
const onSelectOption = (
  selectedOption: ValueType<SmartSearchSelectOption, false>,
  { action }: ActionMeta<SmartSearchSelectOption>
) => {
  if (action === 'select-option' && selectedOption) {
    window.location.href = selectedOption.url;
  }
};

const SmartSearch = ({ searchableData, isInsideModal }: SmartSearchProps) => {
  const defaultOptions = searchableData.filter(item => item.isDefaultOption);

  const defaultGroup = {
    label: 'Suggested Pages',
    options: defaultOptions,
  };

  return (
    <StyledSearchAsyncSelect
      id="global-smart-search-select"
      defaultOptions={[defaultGroup]}
      fetchOptions={async (searchTerm: string) =>
        await search(searchableData, searchTerm)
      }
      loadingText="Looking..."
      placeholder="Search"
      customDropdownIndicator="search"
      isSearchable
      maxMenuHeight={700}
      customComponents={searchResultCustomComponents}
      controlShouldRenderValue={false} // Prevents the selected value from being rendered in the search box
      isOptionSelected={(_: SmartSearchSelectOption) => false} // Prevents options from being "selected"
      formatGroupLabel={formatGroupLabel}
      onChange={onSelectOption}
      defaultMenuIsOpen={isInsideModal}
      menuIsOpen={isInsideModal}
    />
  );
};

export default SmartSearch;

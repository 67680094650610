import styled from 'styled-components';

import { Box, Flex, Link } from '@electricjs/arc';

import noImagePicture from '@/assets/products/no-image.png';

const ImageArea = styled(Flex)`
  height: 6.9rem;
  width: 9.6rem;
  justify-content: center;
  align-items: center;

  /* setting this area's background as transparent to work with light/dark modes */
  /* background-color: transparent; --TODO: will be back for Catalog 2.0, when all images will come with transparent background */

  /** UIBB-21: forcing white background for now (before Catalog 2.0) */
  background-color: white;
`;

type ProductBasicInfoProps = {
  name: string;
  image?: string;
  shouldBreak?: boolean;
  onLinkClick: () => void;
};

export const ProductBasicInfo = ({
  name,
  image,
  shouldBreak,
  onLinkClick,
}: ProductBasicInfoProps) => {
  return (
    <Flex columnGap="2rem" vertical={shouldBreak} alignItems="center">
      <Box flexBasis={shouldBreak ? '10rem' : '12rem'}>
        <ImageArea>
          <img src={image ?? noImagePicture} alt={name} height="100%" />
        </ImageArea>
      </Box>
      <Link
        id={`product-details-link-${name}`}
        flexGrow="1"
        href=""
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          onLinkClick();
        }}>
        {name}
      </Link>
    </Flex>
  );
};

import { DeviceAsset } from './assets';

export type ChatCompletionArgs = {
  organizationId: string;
  currentQuestion: string;
  deviceInfo?: DeviceAsset;
  history?: ChatCompletionPayload[];
};

export type ChatCompletionPayload = {
  role: string;
  content: string;
};

export type ChatCompletionResponse = {
  chat: ChatCompletionPayload[];
  id: string;
};

export enum OpenAIChatRoles {
  User = 'user',
  Assistant = 'assistant',
}

export enum ChatVote {
  Down = -1,
  Neutral = 0,
  Up = 1,
}

export type ChatVotePayload = {
  organizationId: string;
  id: string;
  like: ChatVote;
};

import styled from 'styled-components';

import { Box } from '@electricjs/arc';

import addonInformation, {
  AddonInformation,
} from '@/constants/addonInformation';
import { knowledgeBaseUrl } from '@/constants/urls';
import { OrganizationAddOn } from '@/types/organizations';
import { RequestTypeCategory } from '@/types/requests';
import { LoggedUser } from '@/types/users';

import { getCategoryIcon } from '../utils/getCategoryIcon';
import { MenuCard, MenuCardSkeleton } from './MenuCard';
import SendAddonEmailModal from './SendAddonEmailModal';

import { useGlobalUI } from '@/components/GlobalUIProvider';

const CardsGrip = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 1.6rem;
  column-gap: 1.6rem;
  max-width: 125rem;
  width: 100%;
  margin: 1.6rem auto;
  /* Change the cards grid to adapt on mobile or small screens */
  @media (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

function getAddonInformation(
  addonVendorName: string
): AddonInformation | undefined {
  // These addons have been renamed, but the data in the backend is still stored under the old name
  const renameMap = {
    Malwarebytes: 'ThreatDown',
    Avanan: 'Harmony Email & Collaboration',
  };
  const vendorName =
    renameMap[addonVendorName as keyof typeof renameMap] ?? addonVendorName;
  return addonInformation[vendorName];
}

type SupportCenterCardsProps = {
  isLoading: boolean;
  loggedUser: LoggedUser;
  isAdmin: boolean;
  organizationAddOns: OrganizationAddOn[];
  requestTypeCategories: RequestTypeCategory[];
};

const SupportCenterCards = ({
  isLoading,
  loggedUser,
  isAdmin,
  organizationAddOns,
  requestTypeCategories,
}: SupportCenterCardsProps) => {
  const { openModal, closeModal } = useGlobalUI();

  const hasAddOns = !!organizationAddOns.length;

  const addonsByVendor: Record<string, Array<{ name: string }>> = {};

  organizationAddOns.forEach(({ name, vendor }) => {
    if (!addonsByVendor[vendor]) {
      addonsByVendor[vendor] = [];
    }

    addonsByVendor[vendor].push({
      name,
    });
  });

  const openISVModal = (currentAddon: string) => {
    openModal(
      <SendAddonEmailModal
        closeModalAction={() => closeModal()}
        companyName={loggedUser.organizationName ?? ''}
        userEmail={loggedUser.email ?? ''}
        vendorName={
          String(getAddonInformation(currentAddon)?.supportCenterDisplayName) ??
          ''
        }
        vendorEmail={String(getAddonInformation(currentAddon)?.email) ?? ''}
      />
    );
  };

  return isLoading ? (
    <CardsGrip>
      {Array.from({ length: 3 }, (_, index) => (
        <MenuCardSkeleton key={index} />
      ))}
    </CardsGrip>
  ) : (
    <>
      <CardsGrip>
        {hasAddOns && (
          <>
            {Object.entries(addonsByVendor).map(([vendor, vendorAddons]) =>
              vendorAddons.map(({ name }) => {
                const addon = getAddonInformation(vendor);
                if (!addon) return;

                return (
                  <MenuCard
                    key={vendor}
                    onClick={() => {
                      openISVModal(vendor);
                    }}
                    customIcon={addon.icon}
                    icon="app"
                    title={addon.supportCenterDisplayName}
                    description={name}
                  />
                );
              })
            )}
          </>
        )}
        {requestTypeCategories
          .filter(rtc => rtc.isSupportCenterTile && !!rtc.champions?.length)
          .map(({ id, name, description }) => (
            <MenuCard
              key={id}
              href={`/request-support?category=${name}`}
              icon={getCategoryIcon(name)}
              title={name}
              description={description}
            />
          ))}
        {isAdmin && (
          <MenuCard
            newTab
            href={knowledgeBaseUrl}
            icon="question-message"
            title="Knowledge Base"
            description="Find answers to your most common questions about Electric's services"
          />
        )}
      </CardsGrip>
    </>
  );
};

export default SupportCenterCards;

/* eslint-disable jsx-a11y/no-autofocus */
import { KeyboardEvent, useState } from 'react';
import styled from 'styled-components';

import {
  Box,
  Flex,
  IconButton,
  Spinner,
  Text,
  TextInput,
} from '@electricjs/arc';

const SpinnerSmall = styled(Spinner)`
  width: 1.2rem;
  height: 1.2rem;
`;

const TitleTextInput = styled(TextInput)`
  font-size: 3rem;
  line-height: 4.5rem;
  font-weight: 700;
  letter-spacing: 0.25px;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
`;

export enum EditableTextType {
  REGULAR = 'regular',
  TITLE = 'title',
}

export type EditableTextProps = {
  value: string;
  onChange: (name: string) => void;
  onSave: () => void;
  variant: EditableTextType;
  isSaving: boolean;
};

const EditableText = ({
  value,
  onChange,
  onSave,
  variant,
  isSaving,
}: EditableTextProps) => {
  const [isEditing, setEditing] = useState(false);

  const renderInput = () => {
    return variant === EditableTextType.REGULAR ? (
      <TextInput
        id="regular-editable-text-input"
        value={value}
        onChange={event => {
          onChange(event.target.value);
        }}
        onBlur={save}
        onKeyDown={handleKeyDown}
        autoFocus
      />
    ) : (
      <TitleTextInput
        id="editable-text-input"
        value={value}
        onChange={event => {
          onChange(event.target.value);
        }}
        onBlur={save}
        onKeyDown={handleKeyDown}
        autoFocus
      />
    );
  };

  const toggleEditing = () => {
    setEditing(!isEditing);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      save();
    }
  };

  const save = () => {
    onSave();
    toggleEditing();
  };

  return (
    <Flex alignItems="center">
      {isEditing ? renderInput() : <Text variant="heading-1">{value}</Text>}
      <Box ml="0.8rem">
        {isSaving ? (
          <SpinnerSmall />
        ) : (
          !isEditing && (
            <IconButton
              id="edit-text-icon-button"
              icon="pencil"
              onClick={toggleEditing}
            />
          )
        )}
      </Box>
    </Flex>
  );
};

export default EditableText;

import { Application as ApplicationDto } from '@electricjs/core_entity-client';

export const applicationsList: ApplicationDto[] = [
  {
    id: '002df5c0-054f-11ee-be56-0242ac120002',
    name: '1Password',
    slug: '1password',
    icon: 'https://software-applications-logos.s3.amazonaws.com/supported-applications/1Password.png',
    integration_availability: 'not_available',
  },
  {
    id: '002df5c0-054f-11ee-be56-0242ac120003',
    name: '99 Designs',
    slug: '99_Designs',
    icon: 'https://software-applications-logos.s3.amazonaws.com/supported-applications/99-Designs.png',
    integration_availability: 'not_available',
  },
  {
    id: '002df5c0-054f-11ee-be56-0242ac120004',
    name: 'Abstract',
    slug: 'Abstract',
    icon: 'https://software-applications-logos.s3.amazonaws.com/supported-applications/Abstract.png',
    integration_availability: 'not_available',
  },
  {
    id: '002df5c0-054f-11ee-be56-0242ac120005',
    name: 'Adobe Acrobat',
    slug: 'Adobe_Acrobat',
    icon: 'https://software-applications-logos.s3.amazonaws.com/supported-applications/Adobe-Acrobat.png',
    integration_availability: 'not_available',
  },
  {
    id: '002df5c0-054f-11ee-be56-0242ac120006',
    name: 'Adobe Illustrator',
    slug: 'Adobe_Illustrator',
    icon: 'https://software-applications-logos.s3.amazonaws.com/supported-applications/Adobe-Illustrator-Cloud.png',
    integration_availability: 'not_available',
  },
  {
    id: '002df5c0-054f-11ee-be56-0242ac120007',
    name: 'Adobe InDesign',
    slug: 'Adobe_InDesign',
    icon: 'https://software-applications-logos.s3.amazonaws.com/supported-applications/Adobe-InDesign.png',
    integration_availability: 'not_available',
  },
  {
    id: '002df5c0-054f-11ee-be56-0242ac120008',
    name: 'Adobe Photoshop',
    slug: 'Adobe_Photoshop',
    icon: 'https://software-applications-logos.s3.amazonaws.com/supported-applications/Adobe-Photoshop.png',
    integration_availability: 'not_available',
  },
  {
    id: 'aaeea7bc-054e-11ee-be56-0242ac120002',
    name: 'Zoom',
    slug: 'zoom',
    icon: 'https://logo.clearbit.com/zoom.us',
    url: 'https://zoom.us',
    integration_availability: 'not_available',
  },
];

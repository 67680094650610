import { useRef } from 'react';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import SVG from 'react-inlinesvg';
import {
  ColorsV2,
  Flex,
  Text,
  getToken,
  useThemeColors,
} from '@electricjs/arc';
import { useController, useFormContext, useWatch } from 'react-hook-form';

const CheckBoxContainer = styled(Flex)`
  width: 24.8rem;
  height: 16rem;
  background-color: ${getToken(ColorsV2.BACKGROUND_ALT)};
  border-radius: 0.8rem;
  flex-direction: column;
`;

const HiddenCheckboxInput = styled.input`
  opacity: 0;
  position: fixed;
  width: 0;
`;

const OptionContainer = styled(Flex)<{ checked?: boolean }>`
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: inherit;
  gap: 1.2rem;
  cursor: pointer;

  :hover {
    color: ${getToken(ColorsV2.PRIMARY)};
    border: 0.2rem solid ${getToken(ColorsV2.PRIMARY)};
  }

  ${ifProp(
    'checked',
    css`
      background-color: ${getToken(ColorsV2.PRIMARY_LIGHTEST)};
      color: ${getToken(ColorsV2.PRIMARY)};
      border: 0.2rem solid ${getToken(ColorsV2.PRIMARY)};
      p {
        font-weight: 700;
      }
    `
  )};
`;

const Icon = styled(SVG)<{ checked?: boolean }>`
  path {
    fill: ${ifProp(
      'checked',
      getToken(ColorsV2.PRIMARY),
      getToken(ColorsV2.TEXT)
    )};
  }
`;

type CheckBoxTileProps = {
  text: string;
  icon: string;
  name: string;
};

const CheckBoxTile = ({ text, icon, name }: CheckBoxTileProps) => {
  const { control } = useFormContext();
  const { field } = useController({
    name,
    control: control,
  });
  const checked = useWatch({ name, control });
  const [checkedColor, textColor] = useThemeColors([
    ColorsV2.PRIMARY,
    ColorsV2.TEXT,
  ]);

  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <CheckBoxContainer id={`checkbox-tile-${field.name}`}>
      <HiddenCheckboxInput
        onChange={field.onChange}
        type="checkbox"
        id={`checkbox-tile-input-${field.name}`}
        ref={inputRef}
      />
      <OptionContainer
        checked={checked}
        onClick={() => inputRef.current?.click()}>
        <Icon src={icon} checked={checked} />
        <Text variant="subheading" color={checked ? checkedColor : textColor}>
          {text}
        </Text>
      </OptionContainer>
    </CheckBoxContainer>
  );
};

export default CheckBoxTile;

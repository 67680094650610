import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  EntitlementSlugEnum,
  InternalType,
} from '@electricjs/core_entity-client';

import { NotificationComponent, NotificationsComponentProps } from '@common';
import { SmartSearchPageNavigationOption } from '@/components/SmartSearch';

import { internalPages } from '@/constants/smartSearch/internalPages';
import { searchableActions } from '@/constants/smartSearch/searchableActions';
import { getElectrolyteBaseUrl } from '@/constants/urls';
import { knowledgeBaseUrl } from '@/constants/urls';
import { useGetOrganizationId } from '@/hooks/useGetOrganizationId';
import { useNotificationsProps } from '@/hooks/useNotificationsProps';
import { useOrganizationEmployees } from '@/hooks/useOrganizationEmployees';
import { loggedOut } from '@/redux/slices/loggedUserSlice';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { Employee } from '@/types/employees';
import { OrganizationUserRoleSlugs } from '@/types/users';

import {
  Flex,
  IconButton,
  Text,
  Tooltip,
  useBreakpoint,
} from '@electricjs/arc';
import formatName from '@/helpers/formatName';
import {
  ResultKind,
  SmartSearch,
  SmartSearchSelectOption,
} from '../../SmartSearch';
import { useGlobalUI } from '@/components/GlobalUIProvider';
import generateMenuItems from './generateMenuItems';
import { useEffect } from 'react';
import GlobalSearch from '../GlobalSearch';
import { TopNav } from './TopNavBase';

import electricLogo from '@/assets/electric_logo_red.svg';
import electricMobileLogo from '@/assets/electric_bolt_only_logo_red.svg';
import { useGetMyRequestsQuery } from '@/redux/slices/requestApiSlice';
import { RequestStatus } from '@/types/requests';
import { useOrganizationHasEntitlement } from '@/hooks/useOrganizationHasEntitlement';
import { useGetOrganizationsQuery } from '@/redux/slices/organizationApiSlice';

type TopBarWrapperProps = {
  onMenuClick?: () => void;
};

const getSearchableData = (
  employees: Employee[],
  isAdmin: boolean,
  hasITPlanEntitlement: boolean,
  hasElectricAppEntitlement: boolean
): SmartSearchSelectOption[] => {
  // If the org hasn't the IT Plan entitlement, hide the "Your IT Plan" option
  const filteredPages = internalPages.filter(page => {
    if ((page as SmartSearchPageNavigationOption).text === 'Your IT Plan') {
      return hasITPlanEntitlement;
    }
    if ((page as SmartSearchPageNavigationOption).text === 'Devices') {
      return hasElectricAppEntitlement;
    }
    return true;
  }) as SmartSearchSelectOption[];

  if (isAdmin) {
    const electrolyteBaseUrl = getElectrolyteBaseUrl();

    const searchableEmployees: SmartSearchSelectOption[] = employees.map(
      employee => ({
        kind: ResultKind.EMPLOYEE,
        url: `${electrolyteBaseUrl}/employees/${employee.id}`,
        ...employee,
      })
    );

    return [...searchableEmployees, ...filteredPages, ...searchableActions];
  } else {
    return filteredPages.filter(page => page.isVisibleToEndUser === true);
  }
};

const MenuDetailSection = ({
  organizationName,
  employeeName,
  employeeEmail,
}: {
  organizationName: string;
  employeeName: string;
  employeeEmail: string;
}) => (
  <Flex p="0 1.6rem" flexDirection="column">
    <Text variant="label-large">{organizationName}</Text>
    <Text variant="body-2">{employeeName}</Text>
    <Text variant="legal">{employeeEmail}</Text>
  </Flex>
);

const TopBarWrapper = ({ onMenuClick }: TopBarWrapperProps) => {
  const dispatch = useAppDispatch();
  const { logout } = useAuth0();
  const { openModal, closeModal } = useGlobalUI();
  const { md } = useBreakpoint();
  const loggedUser = useAppSelector(state => state.loggedUser);
  const hasElectricPermissions = !!loggedUser?.electricPermissions;
  const organizationName = loggedUser?.organizationName ?? '';
  const employeeName = formatName({
    firstName: loggedUser?.employeeFirstName,
    lastName: loggedUser?.employeeLastName,
  });
  const employeeEmail = loggedUser?.email ?? '';
  const allUserOrganizationRoles = loggedUser?.allUserOrganizationRoles;
  const isAdminOrSupportRole = loggedUser?.organizationUserRoles?.some(
    role =>
      role.slug === OrganizationUserRoleSlugs.SUPER_ADMIN ||
      role.slug === OrganizationUserRoleSlugs.PRODUCT_SUPPORT
  );
  const isDemoOrg = loggedUser?.organizationInternalType === InternalType.Demo;
  const hasITPlanEntitlement = useOrganizationHasEntitlement(
    EntitlementSlugEnum.ItPlan
  );
  const hasElectricAppEntitlement = useOrganizationHasEntitlement(
    EntitlementSlugEnum.DesktopApp
  );

  const {
    itHubSettingsPage,
    smartSearch: isSmartSearchEnabled,
    electrolyteDarkmode,
    electrolyteNewTopNav,
    electrolyteNotifications: isNotificationsEnabled,
    itHubMyOrganizationsSwitcher,
  } = useFlags();

  // Internal organization switcher should only show if:
  // 1. User has internal electric permissions
  // 2. User has the FF and roles in more than 1 organization
  const showOrganizationSwitcher =
    hasElectricPermissions ||
    (itHubMyOrganizationsSwitcher &&
      allUserOrganizationRoles !== null &&
      allUserOrganizationRoles !== undefined &&
      Object.keys(allUserOrganizationRoles)?.length > 1);

  const organizationId = useGetOrganizationId();

  const handleLogout = () => {
    dispatch(loggedOut());

    const encodedRedirectUri = encodeURIComponent(window.location.origin);
    const adpLogoutUrl = `https://accounts.adp.com/auth/oauth/v2/logout?post_logout_redirect_uri=${encodedRedirectUri}`;

    const adpSSOLoggedIn = loggedUser?.adp_sso_login ?? false;

    logout({
      logoutParams: {
        returnTo: adpSSOLoggedIn ? adpLogoutUrl : window.location.origin,
      },
    });
  };

  // We're fetching a limit of 0 queries because we only care about the total
  const { data: myRequestsData } = useGetMyRequestsQuery({
    organizationId,
    limit: 0,
    status: RequestStatus.InProgress,
  });
  const requestCount = myRequestsData?.total ?? 0;

  const notificationsProps: NotificationsComponentProps =
    useNotificationsProps(organizationId);

  const activeEmployees = useOrganizationEmployees();

  const searchableData = getSearchableData(
    activeEmployees,
    !!isAdminOrSupportRole,
    hasITPlanEntitlement,
    hasElectricAppEntitlement
  );

  const {
    data: myFirstTenOrganizations,
    isError: isGetOrganizationsError,
    isFetching: isGetOrganizationsFetching,
  } = useGetOrganizationsQuery({ limit: 10 });

  const menuItems = generateMenuItems({
    isNewNav: electrolyteNewTopNav,
    isEndUserHub: false, // TODO: I think we can remove this?
    isAdminRole: isAdminOrSupportRole,
    isDemoOrg: isDemoOrg,
    showOrganizationSwitcher,
    hasElectricAppEntitlement,
    hasElectricPermissions,
    electrolyteDarkmode,
    itHubSettingsPage,
    organizationId,
    myOrganizations: myFirstTenOrganizations,
    myOrgRoles: allUserOrganizationRoles,
    isFetchingMyOrganizations: isGetOrganizationsFetching,
    isErrorMyOrganizations: isGetOrganizationsError,
  });

  // Monitor "cmd/ctrl + k" key press to open the global search modal
  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      if (electrolyteNewTopNav) {
        if (searchableData && e.code === 'KeyK' && (e.metaKey || e.ctrlKey)) {
          openModal(
            <GlobalSearch
              searchableData={searchableData}
              onCloseModal={closeModal}
            />
          );
        }

        // Close modal on escape key press
        // NOTE: this will affect all modals, not just the global search modal
        if (e.code === 'Escape') {
          closeModal();
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [searchableData, openModal, closeModal, electrolyteNewTopNav]);

  return (
    <TopNav
      logoProps={{
        logoElement: md ? (
          <img alt="Electric" height="42" src={electricLogo} />
        ) : (
          <img alt="Electric" height="42" src={electricMobileLogo} />
        ),
        logoLinkProps: {
          href: '/',
        },
      }}
      logoutOnClick={handleLogout}
      menuItems={menuItems}
      navMenuIcon="building"
      navMenuName={
        electrolyteNewTopNav && employeeName?.length
          ? employeeName
          : organizationName
      }
      onMobileMenuClick={onMenuClick}
      knowledgeBaseURL={knowledgeBaseUrl}
      notificationsElement={
        isNotificationsEnabled ? (
          <NotificationComponent {...notificationsProps} />
        ) : undefined
      }
      {...(isSmartSearchEnabled && searchableData
        ? electrolyteNewTopNav
          ? {
              searchElement: (
                // TODO: use os detection to determine the correct key for display
                <Tooltip content="Search (cmd/ctrl + k)" placement="bottom">
                  <IconButton
                    id="launch-global-search-button"
                    icon="search"
                    iconSize="medium"
                    onClick={() =>
                      openModal(
                        <GlobalSearch
                          searchableData={searchableData}
                          onCloseModal={closeModal}
                        />
                      )
                    }
                  />
                </Tooltip>
              ),
            }
          : { searchElement: <SmartSearch searchableData={searchableData} /> }
        : {})}
      isNewVersion={electrolyteNewTopNav}
      menuDetailSection={
        <MenuDetailSection
          organizationName={organizationName}
          employeeName={employeeName}
          employeeEmail={employeeEmail}
        />
      }
      taskCount={requestCount}
    />
  );
};

export default TopBarWrapper;

import { User as Auth0User } from '@auth0/auth0-react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useEffect, useState } from 'react';

import { organizationSelected } from '@/redux/slices/loggedUserSlice';
import { useLazyGetMyUserDataQuery } from '@/redux/slices/userApiSlice';
import { useAppDispatch } from '@/redux/store';
import {
  CallerIdentityWithOrganizations,
  LoggedUser,
  OrganizationUserRoleSlugs,
} from '@/types/users';

type RequestUserDataProps = {
  auth0UserAuthenticated: boolean;
  auth0User?: Auth0User;
  loggedUser: LoggedUser | null;
  selectedOrgId?: string | null;
};

/**
 * Removes any orgs from the list where the user has a Product Support role.
 */
const filterProdSupportOrgs = (
  orgs: CallerIdentityWithOrganizations['organizations']
) => {
  const filteredOrgs: CallerIdentityWithOrganizations['organizations'] = {};
  Object.keys(orgs).forEach(orgId => {
    const org = orgs[orgId];
    if (
      org &&
      org.roles &&
      org.roles.some(
        role => role.slug !== OrganizationUserRoleSlugs.PRODUCT_SUPPORT
      )
    ) {
      filteredOrgs[orgId] = org;
    }
  });
  return filteredOrgs;
};

export const useRequestUserData = ({
  auth0UserAuthenticated,
  auth0User,
  loggedUser,
  selectedOrgId,
}: RequestUserDataProps) => {
  const dispatch = useAppDispatch();
  const [isRequestingUserData, setIsRequestingUserData] = useState(false);
  const [userDataRequestCompleted, setUserDataRequestCompleted] =
    useState(false);

  const [
    getMyUserData,
    { isError: myUserDataError, error: myUserDataErrorDetails },
  ] = useLazyGetMyUserDataQuery();

  const isUnauthorizedError: boolean =
    (myUserDataErrorDetails as FetchBaseQueryError)?.status === 401;

  useEffect(() => {
    const requestUserData = async () => {
      if (
        !userDataRequestCompleted &&
        !isRequestingUserData &&
        auth0UserAuthenticated &&
        auth0User &&
        loggedUser?.email &&
        selectedOrgId !== undefined // null would be an intentional value set after sessionStorage check
      ) {
        // Start requests
        setIsRequestingUserData(true);

        // Note: getMyUserData is always refetched to avoid stale role/permission data.
        const { data: userData } = await getMyUserData();

        // Skip setting the org if it's already set, we still need to make the above API call either way
        // If the orgId is null, this means we've finished checking the sessionStorage but nothing was found.
        if (userData && selectedOrgId === null) {
          const orgs = filterProdSupportOrgs(userData.organizations);
          const orgIds = orgs ? Object.keys(orgs) : [];
          // In the future this can be the last selected org saved in the /me endpoint
          // For now, we are automatically assuming the first org in the list.
          const organizationId = orgIds[0];

          if (orgs && orgIds.length > 0 && organizationId) {
            dispatch(
              organizationSelected({
                organizationId: organizationId,
                organizationUserRoles: orgs[organizationId].roles,
                employeeId: orgs[organizationId].employeeId,
              })
            );
          }
        }

        // This order is important to avoid a double call to the endpoint
        // set userDataRequestCompleted completed first, then set isRequestingUserData to false
        setUserDataRequestCompleted(true);
        setIsRequestingUserData(false);
      }
    };

    requestUserData();
  }, [
    dispatch,
    userDataRequestCompleted,
    isRequestingUserData,
    auth0UserAuthenticated,
    auth0User,
    loggedUser,
    getMyUserData,
    selectedOrgId,
  ]);

  return {
    isRequestingUserData,
    userDataRequestCompleted,
    myUserDataError,
    isUnauthorizedError,
  };
};
